import { JourneyNode, JourneyNodeDetails } from "src/pages/journeys/types";
import { JourneyNodeType, JourneyNodeConfig } from "src/types/journeys";

export const getJourneyNodeDetailsByType = (
  nodes: JourneyNode[],
  type: JourneyNodeType,
): JourneyNodeDetails<JourneyNodeConfig>[] => {
  return nodes
    .filter((node) => node.data.config.type === type)
    .map((node) => node.data);
};
