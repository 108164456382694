import { useState } from "react";

import {
  Box,
  CloseIcon,
  Column,
  ExternalLinkIcon,
  IconButton,
  Row,
  SparkleIcon,
  Text,
} from "@hightouchio/ui";

import { Link } from "src/router";

export const GeneratedChartsBlurb = () => {
  const [showBlurb, setShowBlurb] = useState(true);

  if (!showBlurb) return null;

  return (
    <Row
      p={4}
      gap={4}
      bg="linear-gradient(90deg, var(--color-electric-background, #F1E7FC) 0.05%, var(--color-link-background, #EBFCFF) 99.66%)"
      borderRadius="md"
    >
      <Box as={SparkleIcon} boxSize={6} alignSelf="flex-start" />
      <Column gap={4} flex={1}>
        <Column>
          <Text fontWeight="medium">These charts are sorted by AI</Text>
          <Text color="text.secondary">
            Hightouch automatically identifies the most interesting charts and
            shows those first
          </Text>
        </Column>
        <Link href="">
          <Row gap={2} alignItems="center">
            Learn more
            <Box as={ExternalLinkIcon} boxSize={5} />
          </Row>
        </Link>
      </Column>
      <IconButton
        aria-label="Close generated charts blurb"
        icon={CloseIcon}
        onClick={() => setShowBlurb(false)}
        size="sm"
      />
    </Row>
  );
};
