import React from "react";

import { Box, Switch, Alert, Column, Row, Text } from "@hightouchio/ui";
import { Link } from "src/router";
import { Controller } from "react-hook-form";

import { usePermissionContext } from "src/components/permission/permission-context";
import { GitCredentialsQuery } from "src/graphql";

import { githubCredentialsNeedUpdating } from "./git-credentials-fields";

interface GitChecksToggleProps {
  credentials: NonNullable<GitCredentialsQuery["git_credentials"][0]>;
  description: string;
}

export const GitChecksToggle = (
  props: GitChecksToggleProps,
): React.ReactElement => {
  const blocked =
    !props.credentials || !(props.credentials.type === "github_app");
  const issue = githubCredentialsNeedUpdating(props.credentials)
    ? "Your GitHub App permissions need to be updated. Please update your Git credentials."
    : 'To enable commit checks, please update your Git credentials to use the Hightouch "GitHub app".';

  const permission = usePermissionContext();

  return (
    <>
      <Row justifyContent="space-between" alignItems="center" pb={1}>
        <Row alignItems="center">
          <Text>GitHub Pull Request checks</Text>
        </Row>
        <Controller
          name="checks_enabled"
          render={({ field }) => {
            return (
              <Box alignItems="center" display="flex" gap={2}>
                <Text
                  textTransform="uppercase"
                  fontWeight="semibold"
                  size="sm"
                  color="text.tertiary"
                >
                  {field.value ? "Enabled" : "Disabled"}
                </Text>

                <Switch
                  aria-label="Enable GitHub pull request checks."
                  isChecked={field.value}
                  isDisabled={Boolean(blocked ?? permission.unauthorized)}
                  onChange={field.onChange}
                />
              </Box>
            );
          }}
        />
      </Row>
      <Column maxWidth="70%">
        <Text size="sm" color="text.tertiary">
          {props.description}{" "}
        </Text>
        <Link href="https://docs.github.com/en/pull-requests/collaborating-with-pull-requests/collaborating-on-repositories-with-code-quality-features/about-status-checks">
          Learn more about PR checks here.
        </Link>
        {blocked && (
          <Alert
            variant="inline"
            type="warning"
            title="Update Git credentials"
            message={issue}
            mt={2}
          />
        )}
      </Column>
    </>
  );
};
