import { FC, useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import JSON5 from "json5";
import * as Sentry from "@sentry/react";
import { v4 as uuidv4 } from "uuid";

import {
  Alert,
  Badge,
  Column,
  PlayIcon,
  Row,
  SectionHeading,
  Spinner,
  Text,
  ToggleButton,
  ToggleButtonGroup,
  useToast,
} from "@hightouchio/ui";

import { Editor } from "src/components/editor";
import { PermissionedButton } from "src/components/permission";
import { FunctionCodeFormState } from "src/events/functions/types";
import { useRunFunctionTestMutation } from "src/graphql";
import { testInputValidator } from "src/events/functions/utils";

const prettyStringify = (obj: any): string => {
  try {
    return JSON.stringify(obj, null, 2);
  } catch (_err) {
    return "";
  }
};

type TestResult =
  | {
      type: "success";
      value: string;
      logs: string[];
    }
  | {
      type: "error";
      value: string;
      logs: string[];
    }
  | {
      type: "creating";
    };

const DEFAULT_TEST_EVENT = () => ({
  event: "Purchase completed",
  messageId: uuidv4(),
  originalTimestamp: new Date(),
  properties: {},
  receivedAt: new Date(),
  sentAt: new Date(),
  timestamp: new Date(),
  type: "track",
  userId: "123",
});

const DEFAULT_TEST_INPUT = prettyStringify([
  DEFAULT_TEST_EVENT(),
  DEFAULT_TEST_EVENT(),
]);

export const FunctionCodeEditor: FC = () => {
  const { toast } = useToast();
  const { control, getValues } = useFormContext<FunctionCodeFormState>();

  const [testInput, setTestInput] = useState(DEFAULT_TEST_INPUT);

  const [testResult, setTestResult] = useState<TestResult | null>(null);
  const [testResultView, setTestResultView] = useState<"payload" | "logs">(
    "payload",
  );

  const runFunctionTest = useRunFunctionTestMutation({
    onSuccess: () => {
      // set `onSuccess` as a noop so running the mutation does not invalidate cache
    },
  });

  useEffect(() => {
    // Poll until the workspace function is deployed
    // This should take ~5-10 seconds, and only happen very rarely
    if (testResult?.type === "creating") {
      const timeoutId = setTimeout(() => handleTestFunction(), 1000);

      return () => clearTimeout(timeoutId);
    }

    return () => undefined;
  }, [testResult]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Run tests on cmd+enter, but only if the test isn't already running
      if (
        (event.metaKey || event.ctrlKey) &&
        event.key === "Enter" &&
        !runFunctionTest.isLoading
      ) {
        handleTestFunction();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [runFunctionTest]);

  // TODO: it might be nice to store test data in local storage
  // 1. so that nav from /fn/new to /fn/:id doesn't lose test data
  // 2. so that you can leave and come back to the same test data
  const handleTestFunction = async () => {
    const code = getValues("code");

    let inputJson: any = null;
    try {
      inputJson = JSON5.parse(testInput);
      testInputValidator.validateSync(inputJson, { strict: true });
    } catch (e) {
      setTestResult({
        type: "error",
        value: `Invalid test input:\n\t${e.message}`,
        logs: [],
      });
      return;
    }

    try {
      const { invokeWorkspaceFunctionTester: res } =
        await runFunctionTest.mutateAsync({
          args: {
            code,
            // cast to object[] since we validate the input above
            data: inputJson as object[],
            metadata: {},
          },
        });

      switch (res.__typename) {
        case "InvokeWorkspaceFunctionTesterError":
          setTestResult({
            type: "error",
            value:
              res.stack ||
              res.message ||
              "An unknown error occurred during function execution.",
            logs: res.logs ? res.logs.split("\n") : [],
          });
          return;
        case "WorkspaceTesterFunctionCreating":
          setTestResult({
            type: "creating",
          });
          return;
        case "InvokeWorkspaceFunctionTesterSuccess":
          setTestResult({
            type: "success",
            value: prettyStringify(res.data),
            logs: res.logs ? res.logs.split("\n") : [],
          });
          return;
      }
    } catch (e) {
      Sentry.captureException(e);
      toast({
        id: "test-function-error",
        title: "Error running function test",
        message: e.message,
        variant: "error",
      });
    }
  };

  return (
    <Row gap={6} height="100%">
      <Column
        borderRight="1px"
        borderColor="base.border"
        overflow="hidden"
        resize="horizontal"
        pt={8}
        pl={6}
        backgroundColor="base.lightBackground"
        minWidth="150px"
        width="60%"
      >
        <Controller
          control={control}
          name="code"
          render={({ field, fieldState }) => (
            <>
              <Editor
                value={field.value}
                language="javascript"
                onChange={field.onChange}
                bg="base.lightBackground"
              />
              {fieldState?.error?.message && (
                <Alert
                  variant="inline"
                  type="error"
                  title="Error"
                  message={fieldState.error.message}
                />
              )}
            </>
          )}
        />
      </Column>
      <Column
        gap={2}
        flex={1}
        overflow="hidden"
        pt={8}
        pr={6}
        pb={2}
        minWidth="150px"
      >
        <Row justifyContent="space-between" alignItems="center">
          <SectionHeading>Test</SectionHeading>
          <PermissionedButton
            permission={{
              v1: { resource: "workspace", grant: "update" },
              v2: {
                resource: "workspace",
                grant: "can_update",
              },
            }}
            variant="primary"
            onClick={() => handleTestFunction()}
            isLoading={
              runFunctionTest.isLoading || testResult?.type === "creating"
            }
            icon={PlayIcon}
          >
            Test
          </PermissionedButton>
        </Row>
        <Column>
          <Text>Input</Text>
          <Text color="text.secondary" size="sm">
            Enter a list of event payloads in JSON format.
          </Text>
        </Column>
        <Column
          border="1px"
          borderColor="base.border"
          borderRadius="md"
          overflow="hidden"
          minWidth={0}
          resize="vertical"
          minHeight={8}
          height="40%"
        >
          <Editor
            value={testInput}
            language="json"
            onChange={setTestInput}
            minHeight="32px"
          />
        </Column>

        <Row mt={2} gap={2} justifyContent="space-between" flexWrap="wrap">
          <Row gap={2} alignItems="center">
            <Text>Output</Text>
            <TestResultBadge result={testResult} />
          </Row>
          <ToggleButtonGroup
            size="sm"
            isDisabled={!testResult || testResult.type === "creating"}
            value={testResultView}
            onChange={(val) => setTestResultView(val as "payload" | "logs")}
          >
            <ToggleButton label="Payload" value="payload" />
            <ToggleButton
              label={
                !testResult || testResult.type === "creating"
                  ? "Logs"
                  : `Logs (${testResult.logs.length})`
              }
              value="logs"
            />
          </ToggleButtonGroup>
        </Row>
        <Column
          border="1px"
          borderColor="base.border"
          borderRadius="md"
          overflow="hidden"
          minWidth={0}
          flex={1}
          minHeight={8}
        >
          <TestResultsPanel result={testResult} view={testResultView} />
        </Column>
      </Column>
    </Row>
  );
};

const TestResultBadge = ({ result }: { result: TestResult | null }) => {
  if (!result || result.type === "creating") {
    return null;
  }

  return result.type === "success" ? (
    <Badge variant="success">Succeeded</Badge>
  ) : (
    <Badge variant="error">Failed</Badge>
  );
};

const TestResultsPanel = ({
  result,
  view,
}: {
  result: TestResult | null;
  view: "payload" | "logs";
}) => {
  if (!result) {
    return <Editor readOnly value="" language="json" minHeight="32px" />;
  }

  if (result.type === "creating") {
    return <ProvisioningFunction />;
  }

  const value = view === "payload" ? result.value : result.logs.join("\n");
  const language =
    result.type === "success" && view === "payload" ? "json" : undefined;

  return <Editor readOnly value={value} language={language} minHeight="32px" />;
};

const ProvisioningFunction: FC = () => {
  return (
    <Column
      width="100%"
      height="100%"
      backgroundColor="base.background"
      alignItems="center"
      justifyContent="center"
      gap={2}
    >
      <Text>Provisioning function resources...</Text>
      <Spinner size="md" />
    </Column>
  );
};
