import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";
import {
  Text,
  Row,
  Column,
  ErrorIcon,
  WarningIcon,
  Skeleton,
} from "@hightouchio/ui";
import { RenderedMonitorConditionStatus } from "src/graphql";
import { isArrayElement } from "src/types/utils";
import { formatFriendlyDistanceToNow } from "src/utils/time";

export const UnhealthyMonitorStatuses = [
  MonitorStatus.Unhealthy,
  MonitorStatus.Warning,
];

const alertSeverityDisplay = {
  [MonitorStatus.Warning]: {
    color: "warning.500",
    icon: <WarningIcon color="warning.500" />,
    prefix: "Warning: ",
  },
  [MonitorStatus.Unhealthy]: {
    color: "danger.500",
    icon: <ErrorIcon color="danger.500" />,
    prefix: "Critical: ",
  },
} as const;

export const SyncAlertCard = ({
  alert,
  variant = "dark",
}: {
  alert: Pick<RenderedMonitorConditionStatus, "eventTime" | "text" | "status">;
  variant?: "light" | "dark";
}) => {
  const validatedStatus = isArrayElement(UnhealthyMonitorStatuses)(alert.status)
    ? alert.status
    : null;
  if (!validatedStatus) return null;
  return (
    <Row
      borderLeft="2px solid"
      borderColor={alertSeverityDisplay[validatedStatus].color}
      borderRadius="0px 4px 4px 0px"
      backgroundColor={variant === "dark" ? "gray.800" : "base.background"}
      px={2}
      py={1}
      gap={2}
      w="full"
      textAlign="left"
      alignItems="baseline"
    >
      <Text size="lg">{alertSeverityDisplay[validatedStatus].icon}</Text>
      <Column gap={1}>
        <Text
          size="sm"
          fontWeight="medium"
          color={variant === "dark" ? "white" : "text.primary"}
        >
          {alertSeverityDisplay[validatedStatus].prefix} {alert.text}
        </Text>
        <Text
          color={variant === "dark" ? "gray.400" : "text.secondary"}
          size="sm"
        >
          Since {formatFriendlyDistanceToNow(alert.eventTime)}
        </Text>
      </Column>
    </Row>
  );
};

export const SkeletonSyncAlertCard = () => (
  <Skeleton isLoading>
    <Row
      borderLeft="2px solid"
      borderColor="base.border"
      borderRadius="0px 4px 4px 0px"
      backgroundColor="base.background"
      px={2}
      py={1}
      gap={2}
      w="full"
      textAlign="left"
      alignItems="baseline"
    >
      <Column gap={1}>
        <Text size="sm" fontWeight="medium">
          loading
        </Text>
        <Text size="sm">loading</Text>
      </Column>
    </Row>
  </Skeleton>
);
