import { FC, ReactNode } from "react";

import { DbtQuery } from "src/components/explore/preview/dbt-query";
import { SampleModelQuery } from "src/components/explore/preview/sample-model-query";
import { TableQuery } from "src/components/explore/preview/table-query";
import { CustomQueryView } from "src/components/sources/forms/custom-query";
import { SqlEditor } from "src/components/sql-editor";
import { isQueryType, QueryType } from "src/types/models";
import { LookerQuery } from "src/components/explore/preview/looker-query";
import { SigmaQuery } from "src/components/explore/preview/sigma-query";
import { ModelState } from "src/utils/models";
import { ManagedQuery } from "src/components/explore/preview/managed-query";
import { UnreachableCaseError } from "ts-essentials";

type Props = {
  actions?: ReactNode;
  model:
    | (Partial<ModelState> & { golden_record?: { idr_id: string } | null })
    | null;
  source: {
    id: string;
    type: string;
    name: string;
    definition: { name: string; icon: string; isSampleDataSource: boolean };
    config: Record<string, any>;
  } | null;
};

export const Query: FC<Readonly<Props>> = ({ model, actions, source }) => {
  if (!model) {
    return null;
  }
  const type = model?.query_type;
  const dbtModelId = model?.query_dbt_model_id;
  const lookId = model?.query_looker_look_id;

  if (source?.definition?.isSampleDataSource) {
    return (
      <SampleModelQuery
        table={model?.query_table_name ?? ""}
        source={source}
        actions={actions}
      />
    );
  }

  if (model.golden_record) {
    // Golden record parent models can have a table/sql query type but we want to still render `ManagedQuery` with the same copy as the IDRGoldenRecord query type
    return <ManagedQuery type={QueryType.IDRGoldenRecord} actions={actions} />;
  }

  if (isQueryType(type)) {
    switch (type) {
      case QueryType.Table:
        return (
          <TableQuery table={model?.query_table_name ?? ""} actions={actions} />
        );
      case QueryType.RawSql:
        return (
          <SqlEditor
            readOnly
            isBeautifyable={false}
            value={model?.query_raw_sql ?? ""}
            source={source}
            actions={actions}
          />
        );
      case QueryType.DbtModel:
        return <DbtQuery actions={actions} id={dbtModelId} />;
      case QueryType.LookerLook:
        return <LookerQuery lookId={lookId ?? ""} actions={actions} />;
      case QueryType.Sigma:
        return model?.query_integrations && source ? (
          <SigmaQuery
            sigma={model?.query_integrations}
            source={source}
            actions={actions}
          />
        ) : null;
      case QueryType.Custom:
        return model && model.custom_query && source ? (
          <CustomQueryView
            query={model.custom_query}
            source={source}
            actions={actions}
          />
        ) : null;
      case QueryType.Visual:
        // Audience queries are not handled here
        return null;
      case QueryType.JourneyNode:
      case QueryType.DecisionEngine:
      case QueryType.IDRGoldenRecord:
        return <ManagedQuery type={type} actions={actions} />;
      default:
        throw new UnreachableCaseError(type);
    }
  }

  return null;
};
