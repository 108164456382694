import { FullParentModel } from "src/components/audiences/types";
import { ColumnFragment } from "src/graphql";
import { getColumnName } from "src/utils/models";

export type ParentModelFields = {
  primaryKey: string;
  secondaryLabelKey: string | null;
  primaryLabelKey: string;
  columns: ColumnFragment[];
} | null;

export function useParentModelFields(
  parentModel: FullParentModel | undefined | null,
): ParentModelFields {
  const allColumns = parentModel?.columns.filter((col) => !col.disable) ?? [];

  function columnName(column?: string | null) {
    return getColumnName(column || "", allColumns);
  }

  const primaryKey = columnName(parentModel?.primary_key);
  const primaryLabelKey = columnName(parentModel?.visual_query_primary_label);

  if (!parentModel || !primaryKey || !primaryLabelKey) {
    return null;
  }

  const secondaryLabelKey = columnName(
    parentModel?.visual_query_secondary_label,
  );

  return {
    primaryKey,
    primaryLabelKey,
    secondaryLabelKey,
    columns: allColumns.filter((column) => {
      if (
        [
          parentModel.primary_key,
          parentModel.visual_query_primary_label,
          parentModel.visual_query_secondary_label,
        ].includes(column.name)
      ) {
        return false;
      }

      return true;
    }),
  };
}
