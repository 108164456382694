import { FC } from "react";

import { Text, Box, Column, Heading, Row } from "@hightouchio/ui";

import { Card } from "src/components/card";
import destinationImg from "src/pages/home/assets/destination.png";
import audienceImg from "src/pages/home/assets/audience.png";
import journeyImg from "src/pages/home/assets/journey.png";
import modelImg from "src/pages/home/assets/model.png";
import syncImg from "src/pages/home/assets/sync.png";
import { HTImage } from "src/components/image";
import { useEntitlements } from "src/hooks/use-entitlement";

export type ResourceType =
  | "destination"
  | "audience"
  | "journey"
  | "model"
  | "sync";

type ResourceDefinition = {
  name: string;
  color: string;
  href: string;
  description: string;
  asset: string;
  verb: string;
  gradientColor?: string;
};

type Props = {
  resources: Array<ResourceType>;
  view: "activation" | "marketing";
};
export const ResourceCards: FC<Props> = ({ resources, view }) => {
  const { data: entitlementsData } = useEntitlements(false);

  const ActivationResourceDefinitions: Record<
    "destination" | "model" | "sync",
    ResourceDefinition
  > = {
    destination: {
      name: "Destination",
      color: "electric",
      asset: destinationImg,
      href: "/destinations/new",
      verb: "Connect a",
      description: "Add your marketing and business tools",
    },
    model: {
      name: "Model",
      color: "forest",
      gradientColor: "cyan",
      asset: modelImg,
      href: "/models/new",
      verb: "Define a",
      description: "Query and transform data from your sources",
    },
    sync: {
      name: "Sync",
      color: "avocado",
      gradientColor: "grass",
      asset: syncImg,
      href: "/syncs/new",
      verb: "Create a",
      description: "Send an existing model to a destination",
    },
  };

  const MarketingResourceDefinitions: Record<
    "audience" | "journey" | "destination" | "sync",
    ResourceDefinition
  > = {
    audience: {
      name: "Audience",
      color: "forest",
      gradientColor: "cyan",
      asset: audienceImg,
      href: "/audiences/new",
      verb: "Build an",
      description: "Segment customers by attributes and behavior",
    },
    journey: {
      name: "Journey",
      color: "electric",
      asset: journeyImg,
      href: "/journeys/new",
      verb: "Design a",
      description: "Orchestrate experiences across channels",
    },
    destination: {
      name: "Destination",
      color: entitlementsData.entitlements.journeys ? "avocado" : "electric",
      gradientColor: entitlementsData.entitlements.journeys
        ? "grass"
        : "electric",
      asset: destinationImg,
      href: "/destinations/new",
      verb: "Connect a",
      description: "Add your marketing and business tools",
    },
    sync: {
      name: "Sync",
      color: "avocado",
      gradientColor: "grass",
      asset: syncImg,
      href: "/syncs/new",
      verb: "Create a",
      description: "Send an existing audience to a destination",
    },
  };

  const ResourceDefinitions =
    view === "activation"
      ? ActivationResourceDefinitions
      : MarketingResourceDefinitions;

  return (
    <Box
      display="grid"
      gap={6}
      flexWrap="wrap"
      gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
      gridAutoRows="1fr"
    >
      {resources.map((resource) => (
        <ResourceCard key={resource} {...ResourceDefinitions[resource]} />
      ))}
    </Box>
  );
};

const ResourceCard = ({
  description,
  color,
  name,
  asset,
  href,
  verb,
  gradientColor,
}: ResourceDefinition) => {
  return (
    <Card
      h="100%"
      position="relative"
      flex={1}
      overflow="hidden"
      className="group"
      href={href}
      boxShadow="md"
      transition="all 200ms ease-in-out"
      bg="white"
      _before={{
        content: '""',
        position: "absolute",
        transition: "all 300ms ease-in-out",
        top: -1,
        right: -1,
        width: "100%",
        height: "100%",
        background: `radial-gradient(circle at top right, var(--chakra-colors-${gradientColor ?? color}-400) -100%, transparent 30%)`,
        transform: [
          "scale(1.5)",
          "scale(1.5)",
          "scale(1.5)",
          "scale(1.5)",
          "scale(1)",
        ],
        transformOrigin: "top right",
      }}
      _hover={{
        _before: {
          transform: ["scale(1.7)", "scale(1.7)", "scale(1.7)", "scale(1.2)"],
          filter: "brightness(.9)",
        },
        boxShadow: "lg",
      }}
    >
      <Column mt="auto" pt={5} align="flex-start">
        <Row
          align="center"
          h="20px"
          px={2}
          color={`var(--chakra-colors-${color}-700)`}
          bg={`var(--chakra-colors-${color}-200)`}
          borderRadius="sm"
          fontWeight="medium"
        >
          {verb}
        </Row>
        <Heading size="xl" mt={2}>
          {name}
        </Heading>
        <Text color="text.secondary">{description}</Text>
      </Column>
      <HTImage
        pos="absolute"
        top={[0, 0, 0, 0, 0]}
        right={[-6, -6, -6, -6, 8]}
        src={asset}
        h="70px"
        alt={name}
        ml="auto"
        transition="all 300ms ease-in-out"
        transformOrigin="top"
        boxShadow="md"
        _groupHover={{
          transform: "scale(1.05)",
        }}
      />
    </Card>
  );
};
