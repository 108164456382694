import { FC, useMemo } from "react";
import {
  Box,
  Column,
  ExternalLinkIcon,
  Heading,
  QuestionIcon,
  Row,
  Skeleton,
  SkeletonBox,
  Spinner,
  Text,
  Tooltip,
  TraitIcon,
  TraitTemplateIcon,
} from "@hightouchio/ui";
import genericPlaceholder from "src/assets/placeholders/generic.svg";
import modelPlaceholder from "src/assets/placeholders/model.svg";

import { IconBox } from "src/components/icon-box";

import { EventColumn, FilterOption, RelationColumn } from "./constants";
import { FilterColumnOption, TraitFilterOption } from "./types";
import { ColumnType } from "src/types/visual";
import { ALL_COLUMN_OPTIONS } from "src/components/audiences/constants";
import { useFetchSuggestions } from "src/hooks/use-column-suggestions";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { Link } from "src/router";
import times from "lodash/times";
import {
  getModelIdFromColumn,
  getPropertyNameFromColumn,
} from "src/components/explore/visual/utils";
import { CalculationSummary } from "src/components/traits/calculation-summary";
import { stringify } from "src/utils/string";
import { abbreviateNumber } from "src/utils/numbers";
import { TRAIT_TYPE_LABELS } from "src/components/traits/utils";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { useMinimalSyncsForSegmentQuery } from "src/graphql";
import { createSchemaObjectLink } from "src/components/audiences/utils";
import { useHydrateTraitsWithFilterableAudienceColumns } from "src/hooks/use-fetch-traits-with-filterable-audience-columns";
import { HTImage } from "src/components/image";

type FilterPopoverOptionMetadataProps = {
  option: FilterColumnOption | null | undefined;
  sourceId: string;
};

export const FilterPopoverOptionMetadata = ({
  option,
  sourceId,
}: FilterPopoverOptionMetadataProps) => {
  const modelId = getModelIdFromColumn(option?.value);
  const columnName = getPropertyNameFromColumn(option?.value);
  const { loadingSuggestions, suggestions } = useFetchSuggestions(
    {
      modelId: modelId ?? undefined,
      columnName: columnName ?? undefined,
      traitId:
        option && option.type === FilterOption.Trait
          ? option?.traitId
          : undefined,
    },
    {
      // The hook checks that modelId and columnName are defined, so we don't need to do it here
      enabled:
        option?.type === FilterOption.Property && option.suggestionsEnabled,
    },
  );

  const suggestionFragment = useMemo(() => {
    if (
      !option ||
      (option?.type !== FilterOption.Property &&
        option?.type !== FilterOption.Trait &&
        option?.type !== FilterOption.TraitTemplate)
    ) {
      return null;
    }

    if (!option.suggestionsEnabled || suggestions.length === 0) {
      return null;
    }
    const topSuggestions = suggestions.slice(0, 5);
    return (
      <SimpleTable
        header="Most common values"
        rows={topSuggestions}
        showPlaceholder={loadingSuggestions}
      />
    );
  }, [option, suggestions, loadingSuggestions]);

  const { data: syncs = [], isLoading: isSyncsLoading } =
    useMinimalSyncsForSegmentQuery(
      {
        segmentId: option?.value?.modelId,
      },
      {
        enabled:
          option?.type === FilterOption.Audience &&
          option?.value?.modelId != null,
        select: (data) => data.syncs,
      },
    );

  if (!option) {
    return (
      <Column alignItems="center" height="100%" gap={7} justifyContent="center">
        <HTImage src={modelPlaceholder} decorative />
        <Text color="text.secondary">
          Hover on a filter to view its details
        </Text>
      </Column>
    );
  }

  if (option.type === FilterOption.Property) {
    const columnTypeMetadata = getColumnTypeMetadata(option.propertyType);
    const ColumnTypeIcon = columnTypeMetadata?.icon ?? QuestionIcon;

    return (
      <Column gap={6}>
        <OptionHeader
          label={option.label}
          labelUrl={createSchemaObjectLink(sourceId, modelId ?? "", "columns")}
          labelTooltip="Open in schema builder"
          description={option.description}
          icon={ColumnTypeIcon}
          iconTooltip={columnTypeMetadata?.label}
        />
        <UsagePercentage ratio={option.usageRatio} />
        {suggestionFragment}
      </Column>
    );
  }

  if (option.type === FilterOption.Relation) {
    return (
      <Column gap={6}>
        <OptionHeader
          label={option.label}
          labelUrl={createSchemaObjectLink(sourceId, option.toModelId, "query")}
          labelTooltip="Open in schema builder"
          description={option.description}
        />
        <UsagePercentage ratio={option.usageRatio} />
        <SimpleTable
          header="Example columns"
          rows={option.examplePropertyNames}
        />
      </Column>
    );
  }

  if (
    option.type === FilterOption.Event ||
    option.type === FilterOption.EventProperty
  ) {
    return (
      <Column gap={6}>
        <OptionHeader
          label={option.label}
          labelUrl={createSchemaObjectLink(
            sourceId,
            "eventModelId" in option.value
              ? option.value.eventModelId
              : option.value.modelId,
            "query",
          )}
          labelTooltip="Open in schema builder"
          description={option.description}
        />
        <UsagePercentage ratio={option.usageRatio} />
        <SimpleTable
          header="Example columns"
          rows={option.examplePropertyNames}
        />
      </Column>
    );
  }

  if (option.type === FilterOption.Audience) {
    return (
      <Column gap={6}>
        <OptionHeader
          label={option.label}
          labelUrl={`/audiences/${option.value.modelId}`}
          labelTooltip="Open audience details"
          description={option.description}
        />
        <UsagePercentage ratio={option.usageRatio} />

        <Column gap={2}>
          <Text
            color="text.secondary"
            fontWeight="semibold"
            textTransform="uppercase"
          >
            Sending to
          </Text>
          {isSyncsLoading ? (
            <Skeleton isLoading={isSyncsLoading}>
              <Row gap={1.5}>
                {times(3).map((i) => (
                  <SkeletonBox key={i} w="30px" h="30px" borderRadius="50%" />
                ))}
              </Row>
            </Skeleton>
          ) : syncs.length ? (
            <SimpleSyncCells syncs={syncs} />
          ) : (
            <Text color="text.secondary">No syncs</Text>
          )}
        </Column>
      </Column>
    );
  }

  if (option.type === FilterOption.InlineTrait) {
    return (
      <Column
        alignItems="center"
        justifyContent="center"
        height="100%"
        gap={7}
        p={4}
        textAlign="center"
      >
        <HTImage src={genericPlaceholder} decorative />
        <Text color="text.secondary">Create a custom trait</Text>
      </Column>
    );
  }

  if (
    option.type === FilterOption.Trait ||
    option.type === FilterOption.TraitTemplate
  ) {
    const hasRelatedModel = Boolean(option.relatedModel);
    const isEventModel =
      option.relatedModel && Boolean(option.relatedModel.event);

    return (
      <Column gap={6}>
        <OptionHeader
          label={option.label}
          labelUrl={`/traits/${option.type === FilterOption.Trait ? "active" : "templates"}/${option.traitId}`}
          labelTooltip="Open trait details"
          description={option.description}
          iconTooltip={
            option.type === FilterOption.Trait ? "Trait" : "Trait template"
          }
          icon={
            option.type === FilterOption.Trait ? TraitIcon : TraitTemplateIcon
          }
        />
        <UsagePercentage ratio={option.usageRatio} />
        {hasRelatedModel && (
          <Column gap={1}>
            <Text
              color="text.secondary"
              fontWeight="semibold"
              textTransform="uppercase"
            >
              Based on
            </Text>
            <Row alignItems="center" gap={1}>
              <IconBox
                bg={isEventModel ? EventColumn.color : RelationColumn.color}
                boxSize={4}
                icon={isEventModel ? EventColumn.icon : RelationColumn.icon}
                iconSize={3}
              />
              <Text>{option.relatedModel?.name}</Text>
            </Row>
          </Column>
        )}
        <Column gap={1}>
          <Text
            color="text.secondary"
            fontWeight="semibold"
            textTransform="uppercase"
          >
            Type
          </Text>
          <Text>{TRAIT_TYPE_LABELS[option.traitType]}</Text>
        </Column>
        {option.parentModel && (
          <Column gap={1}>
            <Text
              color="text.secondary"
              fontWeight="semibold"
              textTransform="uppercase"
            >
              Definition
            </Text>
            <FilterPopoverCalculationSummary {...option} />
          </Column>
        )}
        {suggestionFragment}
      </Column>
    );
  }

  return null;
};

const FilterPopoverCalculationSummary: FC<TraitFilterOption> = ({
  parentModel,
  relatedModel,
  traitConfig,
  traitType,
}) => {
  const { traitsWithFilterableColumns, isLoading } =
    useHydrateTraitsWithFilterableAudienceColumns(parentModel?.traits ?? []);

  const parentModelWithFilterableTraitColumns = useMemo(() => {
    if (!parentModel) {
      return undefined;
    }

    return {
      ...parentModel,
      traits: traitsWithFilterableColumns,
    };
  }, [parentModel, traitsWithFilterableColumns]);

  if (!parentModel) {
    return null;
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <CalculationSummary
      hideModel
      parentModel={parentModelWithFilterableTraitColumns}
      relatedModel={relatedModel}
      config={traitConfig}
      type={traitType}
    />
  );
};

const getColumnTypeMetadata = (type: ColumnType) => {
  return ALL_COLUMN_OPTIONS.find((option) => option.value === type);
};

const OptionHeader = ({
  label,
  labelUrl,
  labelTooltip,
  description,
  icon,
  iconTooltip,
}: {
  label: string;
  labelUrl: string;
  labelTooltip: string;
  description?: string | null;
  icon?: React.ComponentType<React.SVGAttributes<SVGElement>>;
  iconTooltip?: string;
}) => (
  <Column>
    <Box
      display="grid"
      // The second column (for the icon) is set to max-content, so that
      // a long label (first column) doesn't squish it.
      gridTemplateColumns="1fr max-content"
      justifyContent="space-between"
      gap={2}
    >
      <Tooltip message={labelTooltip}>
        <Link href={labelUrl} isExternal>
          <Box as={Heading} overflowWrap="anywhere" size="md">
            {label}{" "}
            <Box
              as={ExternalLinkIcon}
              fontSize={20}
              color="black"
              /* This is to center align the icon with the preceding text while keeping the icon inline. */
              transform="translateY(-1px)"
            />
          </Box>
        </Link>
      </Tooltip>

      {icon && (
        <Tooltip message={iconTooltip ?? ""}>
          <Row
            backgroundColor="base.background"
            justifyContent="center"
            borderRadius="50%"
            color="gray.700"
            h={6}
            w={6}
          >
            <Box as={icon} h={4} w={4} />
          </Row>
        </Tooltip>
      )}
    </Box>
    {description && <Text color="text.secondary">{description}</Text>}
  </Column>
);

const UsagePercentage = ({ ratio }: { ratio: number | undefined }) => (
  <Column>
    <Text fontWeight="semibold" size="lg">
      {formatRatioAsPercentage(ratio ?? 0)}
    </Text>
    <Text color="text.secondary" size="sm">
      of audiences using
    </Text>
  </Column>
);

const PercentFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
});

// Ratio is a number between [0, 1]
const formatRatioAsPercentage = (ratio: number): string => {
  // Special-case for really small values
  if (ratio > 0 && ratio < 0.01) {
    return "<1%";
  }

  return PercentFormatter.format(ratio);
};

const SimpleTable = ({
  header,
  rows,
  showPlaceholder,
}: {
  header: string;
  rows:
    | { value: string | number | boolean | null; count: number | undefined }[]
    | string[];
  showPlaceholder?: boolean;
}) => {
  return (
    <Column gap={2}>
      <Text
        color="text.secondary"
        fontWeight="semibold"
        textTransform="uppercase"
      >
        {header}
      </Text>
      <Column>
        <>
          {showPlaceholder && (
            <Skeleton isLoading={showPlaceholder}>
              <Column gap={2}>
                {times(5).map((i) => (
                  <SkeletonBox key={i} height={5} w="100%" />
                ))}
              </Column>
            </Skeleton>
          )}

          {rows.map((row: (typeof rows)[number], index: number) => (
            <Row
              key={typeof row === "string" ? row : row.value?.toString()}
              gap={2}
              justifyContent="space-between"
              {...(index === 0
                ? { borderY: "1px solid" }
                : { borderBottom: "1px solid" })}
              borderColor="base.border"
              py={2}
              pl={1}
            >
              {typeof row === "string" ? (
                <TextWithTooltip>{stringify(row)}</TextWithTooltip>
              ) : (
                <>
                  <TextWithTooltip>{stringify(row.value)}</TextWithTooltip>
                  {row.count != null && (
                    <Text>{abbreviateNumber(row.count)}</Text>
                  )}
                </>
              )}
            </Row>
          ))}
        </>
      </Column>
    </Column>
  );
};

const SimpleSyncCells = (props: {
  syncs: {
    id: string;
    destination: {
      name: string | null;
      definition: {
        icon: string;
        name: string;
      };
    } | null;
  }[];
}) => (
  <Row align="center" w="100%" h="100%" flexWrap="wrap" gap={1.5}>
    {props.syncs.map((sync) => (
      <Tooltip
        key={sync.id}
        message={
          sync.destination?.name ??
          sync.destination?.definition?.name ??
          "Private destination"
        }
      >
        <Link href={`/syncs/${sync.id}`} isExternal>
          <Row
            align="center"
            justify="center"
            w="30px"
            h="30px"
            borderRadius="50%"
            bg="white"
            border="1px"
            borderColor="base.border"
          >
            <IntegrationIcon
              size={4}
              name={sync.destination?.definition?.name}
              src={sync.destination?.definition?.icon}
            />
          </Row>
        </Link>
      </Tooltip>
    ))}
  </Row>
);
