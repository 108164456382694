import { FC, useMemo, useState } from "react";

import {
  Box,
  CloseIcon,
  Column,
  ConfirmationDialog,
  DeleteIcon,
  DrawerBody,
  DrawerHeader,
  ErrorIcon,
  ExternalLinkIcon,
  IconButton,
  InformationIcon,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  Paragraph,
  PlusIcon,
  Row,
  Text,
  Tooltip,
  useToast,
  WarningIcon,
} from "@hightouchio/ui";
import capitalize from "lodash/capitalize";
import { useFormContext } from "react-hook-form";
import { useNavigate, useSearchParams } from "src/router";

import { useHightouchFormContext } from "src/components/form";
import {
  PermissionedButton,
  PermissionedEditableHeading,
  PermissionedMenuItem,
} from "src/components/permission";
import { SyncRunStatusBadge } from "src/components/syncs/sync-run-status-badge";
import { SyncNodeQuery, useSyncNodeQuery } from "src/graphql";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import type {
  JourneyGraph,
  JourneyNode,
  NodeDetailFormProps,
} from "src/pages/journeys/types";
import { getCreateJourneySyncParams } from "src/pages/journeys/utils";
import { JourneyNodeType, SyncConfig } from "src/types/journeys";
import { Table } from "src/ui/table";
import { formatDatetime } from "src/utils/time";
import { openUrlInNewTab } from "src/utils/urls";

import { hasWaitNodeDownstream } from "./utils";
import { SyncRunStatus } from "src/utils/syncs";
import { keyBy, size } from "lodash";
import { useValidatedSyncs } from "src/pages/journeys/utils/use-validated-syncs";

export const SyncForm: FC<NodeDetailFormProps<SyncConfig>> = ({
  id, // id techincally lives in data too, redundant to have both :/
  data,
  onClose,
}) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [, setSearchParams] = useSearchParams();

  const { submit } = useHightouchFormContext();
  const {
    nodes,
    isEditMode,
    versionId,
    unauthorizedTooltip,
    updateJourneyPermission,
    onRemoveSyncConfigFromNode,
    onUpdateNode,
  } = useGraphContext();

  const [submitCount, setSubmitCount] = useState(0);
  const [confirmSave, showConfirmSave] = useState(false);
  const [syncToDelete, setSyncToDelete] = useState<string | null>(null);

  const parentForm = useFormContext<JourneyGraph>();

  const currentNode = nodes.find((node) => node.id === id);

  // If `is_clone` is present on the sync_config of the node, it's a clone.
  const isEphemeralClonedNode = (data.sync_configs ?? []).some(
    (config) => config?.is_clone,
  );

  const currentNodeSyncConfigs = useMemo(() => {
    return keyBy(data.sync_configs ?? [], "destination_instance_id");
  }, [data.sync_configs]);

  const clonedNodeParent = useMemo(() => {
    // We rely on the segment_id to persist between the clones (at least while changes are staged),
    // so find the original parent node (and not another adjacent clone) to the currentNode
    return isEphemeralClonedNode
      ? nodes.find(
          (node: JourneyNode) =>
            node.type === JourneyNodeType.Sync &&
            node.data.segment_id != null &&
            node.data.segment_id === currentNode?.data.segment_id &&
            node?.data?.sync_configs != null &&
            !node.data.sync_configs.some((syncConfig) => syncConfig.is_clone),
        )
      : null;
  }, [nodes, isEphemeralClonedNode, currentNode]);

  const syncNodeQuery = useSyncNodeQuery(
    {
      journeyNodeId:
        isEphemeralClonedNode && clonedNodeParent != null
          ? String(clonedNodeParent.id)
          : id,
      journeyVersionId: versionId,
    },
    {
      enabled: Boolean(versionId),
      select: (data) => data.journey_syncs,
    },
  );

  const syncs = syncNodeQuery.data
    ?.filter((syncNode) => syncNode.sync?.id in currentNodeSyncConfigs)
    .map((sync) => {
      if (!isEphemeralClonedNode) {
        return sync;
      }

      const currentNodeSyncConfig = currentNodeSyncConfigs[sync?.sync?.id];
      return {
        ...sync,
        // If we're working with a cloned node, make sure we display the staged/most recent exit_config/mode values.
        mode: currentNodeSyncConfig?.mode || sync.mode,
        exit_config: currentNodeSyncConfig?.exit_config || sync.exit_config,
      };
    });
  const { syncs: validatedSyncs, loading: validationLoading } =
    useValidatedSyncs<SyncNodeQuery["journey_syncs"][0]>(syncs ?? []);

  const segmentId = data.segment_id ?? "";

  const updateName = (newName: string) => {
    if (!newName) {
      toast({
        id: "update-error",
        title: "Name is required",
        variant: "error",
      });

      setSubmitCount(submitCount + 1);
    } else {
      onUpdateNode(id, { name: newName });

      toast({
        id: "tile-update",
        title: "Tile name updated",
        variant: "success",
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Condition types
  const edges = parentForm.watch("edges");

  const hasDownstream = useMemo(() => {
    return currentNode && hasWaitNodeDownstream(currentNode, nodes, edges);
  }, [nodes, edges, id]);

  const addNewSync = () => {
    if (parentForm.formState.isDirty) {
      // form needs to be saved first, so show confirmation dialog
      showConfirmSave(true);
    } else {
      // Node already exists so navigate to wizard
      navigate(
        `new-sync?${getCreateJourneySyncParams({
          segmentId,
          hasDownstream,
        })}`,
      );
    }
  };

  const deleteSync = async () => {
    if (!syncToDelete) return;

    // Detaches the sync from the current node.
    onRemoveSyncConfigFromNode(id, syncToDelete);

    // Remove the sync from the journey
    setSyncToDelete(null);

    toast({
      id: "tile-update",
      title: "Sync removed from tile",
      variant: "info",
    });
  };

  const goToSync = (syncId: string) => {
    openUrlInNewTab(`/syncs/${syncId}`);
  };

  return (
    <>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <PermissionedEditableHeading
            isDisabled={!isEditMode}
            // Key forces remount if submission fails
            key={submitCount}
            permission={updateJourneyPermission}
            unauthorizedTooltip={unauthorizedTooltip}
            value={data.name}
            onChange={updateName}
          />
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody>
        <Column minHeight={0} flex={1} gap={6} pb={4}>
          <Row align="center" justify="space-between">
            <Text fontWeight="medium" size="lg">
              Syncs
            </Text>
            <PermissionedButton
              isDisabled={!isEditMode}
              permission={{
                v1: { resource: "sync", grant: "create" },
              }}
              icon={PlusIcon}
              onClick={addNewSync}
            >
              Add sync
            </PermissionedButton>
          </Row>
          {isEphemeralClonedNode && size(currentNodeSyncConfigs) > 0 && (
            <Row
              width="100%"
              bg="base.background"
              px={2}
              py={4}
              borderRadius="md"
            >
              <Box
                as={InformationIcon}
                fontSize="22px"
                mx={2}
                color="text.secondary"
              />
              <Text color="text.secondary" fontWeight="semibold">
                Copy of {clonedNodeParent?.data?.name}. The below syncs will not
                be created until the journey is saved.
              </Text>
            </Row>
          )}

          <Table
            columns={[
              {
                name: "Last run",
                max: "150px",
                headerSx: { pl: "16px !important" },
                cellSx: { pl: "16px !important" },
                cell: ({ sync, isSyncMisconfigured }) => (
                  <Row gap={2}>
                    {!isEphemeralClonedNode && isSyncMisconfigured && (
                      <Tooltip message="Sync is not fully configured!">
                        <Box as={ErrorIcon} boxSize={6} color="danger.600" />
                      </Tooltip>
                    )}

                    <Column gap={2}>
                      {!isEphemeralClonedNode ? (
                        <>
                          <SyncRunStatusBadge status={sync?.status} />
                          {sync?.last_run_at && (
                            <Text color="text.secondary" size="sm">
                              {formatDatetime(sync.last_run_at)}
                            </Text>
                          )}
                        </>
                      ) : (
                        <SyncRunStatusBadge status={SyncRunStatus.PENDING} />
                      )}
                    </Column>
                  </Row>
                ),
              },
              {
                name: "Destination",
                min: "250px",
                cell: ({ sync }) => (
                  <Row align="center" gap={2}>
                    {sync?.destination?.definition.icon && (
                      <img
                        src={sync.destination.definition.icon}
                        alt={sync?.destination?.definition.name}
                        height="24px"
                        width="24px"
                      />
                    )}
                    <Text>{sync?.destination?.definition.name}</Text>
                  </Row>
                ),
              },
              {
                name: "Type",
                cell: ({ mode }) => <Text>{capitalize(mode)}</Text>,
              },
              {
                max: "60px",
                cell: ({ exit_config }) =>
                  exit_config?.remove_on_journey_exit && !hasDownstream ? (
                    <Tooltip message="Users might never be synced">
                      <Box as={WarningIcon} boxSize={6} color="warning.600" />
                    </Tooltip>
                  ) : null,
              },
              {
                max: "80px",
                cell: ({ sync }) =>
                  sync ? (
                    <Menu>
                      <MenuActionsButton
                        onClick={(event) => event.stopPropagation()}
                      />

                      <MenuList>
                        {sync && (
                          <MenuItem
                            icon={ExternalLinkIcon}
                            isDisabled={isEphemeralClonedNode}
                            onClick={(event) => {
                              event.stopPropagation();
                              goToSync(sync.id);
                            }}
                          >
                            Go to sync details
                          </MenuItem>
                        )}
                        <PermissionedMenuItem
                          isDisabled={!isEditMode}
                          permission={{
                            v1: {
                              resource: "sync",
                              grant: "delete",
                            },
                            v2: {
                              resource: "sync",
                              grant: "can_delete",
                              id: sync.id,
                            },
                          }}
                          icon={DeleteIcon}
                          variant="danger"
                          onClick={(event) => {
                            event.stopPropagation();
                            if (isEphemeralClonedNode) {
                              // Detach without confirmation as we don't have an existing sync yet.
                              onRemoveSyncConfigFromNode(
                                id,
                                sync.id.toString(),
                              );
                            } else {
                              setSyncToDelete(sync.id.toString());
                            }
                          }}
                        >
                          {isEphemeralClonedNode
                            ? "Do not copy sync"
                            : "Delete sync"}
                        </PermissionedMenuItem>
                      </MenuList>
                    </Menu>
                  ) : null,
              },
            ]}
            loading={syncNodeQuery.isLoading || validationLoading}
            data={syncs != null ? validatedSyncs : []}
            rowHeight="74px"
            placeholder={{
              title: "This tile contains no syncs",
              body: "Add a sync to send users to a destination or trigger a campaign when they reach this step in the journey.",
              error: "Audiences failed to load, please try again.",
            }}
            onRowClick={({ sync }) => setSearchParams({ sync: sync?.id })}
          />
        </Column>
      </DrawerBody>

      <ConfirmationDialog
        isOpen={confirmSave}
        title="Unsaved changes"
        onConfirm={async () => {
          await submit();
          showConfirmSave(false);
        }}
        confirmButtonText="Save changes"
        variant="warning"
        onClose={() => showConfirmSave(false)}
      >
        <Paragraph>
          To add a sync to this journey, you must first save your changes.
        </Paragraph>
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={Boolean(syncToDelete)}
        title="Delete sync"
        onConfirm={deleteSync}
        confirmButtonText="Delete sync"
        variant="danger"
        onClose={() => setSyncToDelete(null)}
      >
        <Paragraph>
          This sync will be fully deleted when the journey changes are saved.
          Are you sure you want to delete this sync?
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
