import { HTImage } from "src/components/image";
import { FC } from "react";

import { Box, Button, Checkbox, Column, Row, Text } from "@hightouchio/ui";

import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";

type Props = {
  selected: Set<string>;
  setSelected: (selected: Set<string>) => void;
  syncs: any;
  definitions: DestinationDefinition[] | undefined;
};

export const Destinations: FC<Readonly<Props>> = ({
  setSelected,
  selected,
  syncs,
  definitions,
}) => {
  return (
    <>
      <Row mb={2} justifyContent="flex-start">
        <Button
          variant="tertiary"
          onClick={() => {
            setSelected(new Set());
          }}
        >
          Deselect all
        </Button>
      </Row>
      <Column gap={2}>
        {syncs?.map(({ id, destination }) => {
          const definition = definitions?.find(
            ({ type }) => type === destination.type,
          );
          const isSelected = selected.has(String(id));

          return (
            <Row
              key={id}
              alignItems="center"
              py={1}
              px={2}
              border="1px solid"
              borderColor="base.border"
              borderRadius="sm"
              bg="base.lightBackground"
              justifyContent="space-between"
              cursor="pointer"
              sx={{
                ":hover": {
                  bg: "base.1",
                },
              }}
              onClick={(event) => {
                // Ignore clicks on checkbox's input
                if ((event.target as HTMLElement).matches("input")) {
                  return;
                }

                if (isSelected) {
                  selected.delete(String(id));
                  setSelected(new Set(selected));
                } else {
                  setSelected(new Set(selected.add(String(id))));
                }
              }}
            >
              <Row alignItems="center" minHeight="40px">
                <Box flex="none">
                  <Checkbox isChecked={isSelected} onChange={() => {}} />
                </Box>

                <HTImage
                  alt={definition?.name ?? ""}
                  src={definition?.icon ?? ""}
                  sx={{ mx: 2, flexShrink: 0 }}
                  width="32px"
                />
                <Text fontWeight="semibold">{destination.name}</Text>
              </Row>
            </Row>
          );
        })}
      </Column>
    </>
  );
};
