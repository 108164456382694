import { Column, Spinner, Box, Text, Row } from "@hightouchio/ui";
import { startOfDay, subDays, endOfDay, format } from "date-fns";
import { useMemo } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import pluralize from "pluralize";

import chartPlaceholder from "src/pages/home/assets/placeholder-chart.svg";
import { useAudienceSizeMetricsQuery } from "src/graphql";
import { TimeOptions } from "src/pages/analytics/types";
import { Circle } from "src/ui/circle";
import { abbreviateNumber } from "src/utils/numbers";
import { DEFAULT_CHART_HEIGHT } from "src/components/charts/chart";
import { HTImage } from "src/components/image";

export const AudienceSizeChart = ({
  audienceId,
  lookbackWindow,
}: {
  audienceId: string;
  lookbackWindow: TimeOptions;
}) => {
  const variables = useMemo(() => {
    const endTime = new Date();
    const startTime =
      lookbackWindow === TimeOptions.ThirtyDays
        ? subDays(endTime, 30)
        : subDays(endTime, 90);
    return {
      audienceId,
      startTime: startOfDay(startTime).getTime(),
      endTime: endOfDay(endTime).getTime(),
    };
  }, [audienceId, lookbackWindow]);

  const { data, isLoading } = useAudienceSizeMetricsQuery(variables, {
    select: (data) =>
      data.getAudienceSizeMetrics.data.map((d) => ({
        x: d.timestamp,
        y: d.value,
      })),
  });

  if (isLoading) {
    return (
      <Column
        align="center"
        justify="center"
        my="auto"
        height={DEFAULT_CHART_HEIGHT}
      >
        <Spinner size="lg" />
      </Column>
    );
  }

  if (!data || !data.length) {
    return (
      <Column pos="relative" height={DEFAULT_CHART_HEIGHT} alignItems="center">
        <Box fontWeight="medium" fontSize="xl" mb={1} pt={6}>
          No audience size data available
        </Box>
        <Text color="text.secondary">
          The audience has not had any syncs in the selected time period.
        </Text>
        <HTImage
          pos="absolute"
          left={6}
          right={6}
          bottom={6}
          src={chartPlaceholder}
          alt="Chart illustration."
          pointerEvents="none"
        />
      </Column>
    );
  }

  const latestValue = data?.[data.length - 1]?.y;
  const isLatestValue = latestValue !== undefined;

  return (
    <Column flex={1} height={DEFAULT_CHART_HEIGHT} mt={isLatestValue ? 0 : 4}>
      {isLatestValue && (
        <Box color="text.secondary" mb={4}>
          {`${abbreviateNumber(latestValue)} audience ${pluralize(
            "member",
            latestValue,
          )}`}
        </Box>
      )}
      {data && <Chart data={data} />}
    </Column>
  );
};

export const Chart = ({ data }: { data: { x: number; y: number }[] }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={data}>
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop
              stopColor="var(--chakra-colors-electric-base)"
              stopOpacity="0.3"
            />
            <stop
              offset="1"
              stopColor="var(--chakra-colors-electric-base)"
              stopOpacity="0"
            />
          </linearGradient>
        </defs>
        <CartesianGrid
          horizontal={true}
          vertical={false}
          stroke="var(--chakra-colors-base-divider)"
        />
        <Tooltip
          cursor={{ strokeWidth: 2, stroke: "#252D36" }}
          content={({ payload }) => <CustomTooltip payload={payload} />}
        />
        <YAxis
          dataKey="y"
          tickLine={false}
          axisLine={false}
          padding={{ top: 40, bottom: 20 }}
          tickFormatter={yFormatter}
        />
        <XAxis
          dataKey="x"
          minTickGap={50}
          tickFormatter={xFormatter}
          tickLine={false}
          axisLine={false}
        />
        <Area
          type="monotone"
          stroke="var(--chakra-colors-electric-600)"
          fill="url(#gradient)"
          fillOpacity={1}
          strokeWidth={3}
          dataKey="y"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const yFormatter = (value: number) => {
  return abbreviateNumber(value);
};

const xFormatter = (timestamp: string) => {
  const tick = format(new Date(timestamp), "MMM d");
  return tick;
};

const CustomTooltip = ({ payload }: { payload: any }) => {
  const data = payload[0]?.payload;
  if (!data) return null;
  return (
    <Column bg="gray.900" padding={2} borderRadius="md" gap={2} minW="200px">
      <Text color="gray.400">{format(new Date(data.x), "EEEE, LLL do")}</Text>
      <Row gap={2} align="center">
        <Circle bg="electric.base" radius={2} />
        <Text color="white" fontWeight="medium">
          {yFormatter(data.y)} users
        </Text>
      </Row>
    </Column>
  );
};
