import { useEffect, useState } from "react";
import { ButtonGroup, Column, Heading, Row, useToast } from "@hightouchio/ui";
import { isEqual, omit } from "lodash";

import {
  TestResult,
  TestUpdatedDestinationButton,
} from "src/components/destinations/test-destination";
import {
  TestDestinationStepStatus,
  useDestinationQuery,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import { useQueryString } from "src/utils/use-query-string";
import { SetupForm } from "src/components/destinations/destination-form";
import { TestConnectionTable } from "src/components/destinations/test-connection-status";
import { ActionBar } from "src/components/action-bar";
import { PermissionedButton } from "src/components/permission";
import { useParams } from "src/router";
import { DocsLink } from "src/components/docs-link";
import { Card } from "src/components/card";

type Config = Record<string, unknown>;

export const Element = () => {
  const { destination_id: id } = useParams<{ destination_id: string }>();
  const { data: destination } = useDestinationQuery(
    { id: String(id) },
    {
      enabled: Boolean(id),
      select: (data) => data.destinations_by_pk,
      suspense: true,
    },
  );

  const {
    data: { reauthorized },
  } = useQueryString();

  const { toast } = useToast();
  const [config, setConfig] = useState<Config | undefined>();
  const [credentialId, setCredentialId] = useState<string | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [testing, setTesting] = useState(false);
  const [testResult, setTestResult] = useState<TestResult>(TestResult.Unknown);
  const [testConnectionResult, setTestConnectionResult] = useState<
    TestDestinationStepStatus[] | null
  >(null);
  const [testError, setTestError] = useState<Error | null>(null);

  const { isLoading: updating, mutateAsync: updateDestination } =
    useUpdateDestinationV2Mutation();

  const isOAuth =
    config &&
    (config.methodKey === "googleOauth" || config.methodKey === "oauth");

  const definition = destination?.definition;

  const reset = () => {
    setName(destination?.name ?? undefined);
    setConfig(destination?.config ?? undefined);
    setCredentialId(destination?.credential_id ?? undefined);
    setTestResult(TestResult.Unknown);
    setTestConnectionResult(null);
  };

  const update = async () => {
    await updateDestination({
      id: id!,
      destination: {
        credential_id: credentialId,
        config: {
          ...destination?.config,
          ...config,
        },
      },
    });
    toast({
      id: "update-destination",
      title: "Destination was updated",
      variant: "success",
    });
  };

  const dirty =
    name !== destination?.name ||
    !isEqual(
      omit(config, ["methodKey"]),
      omit(destination?.config, ["methodKey"]),
    ) ||
    !isEqual(credentialId || false, destination?.credential_id || false);

  const setConfigDirty = (config: Config) => {
    setConfig(config);
    setTestResult(TestResult.Unknown);
    setTestConnectionResult(null);
  };

  useEffect(() => {
    reset();
  }, [destination]);

  useEffect(() => {
    if (reauthorized) {
      toast({
        id: "reauthorized-destination",
        title: "Destination reauthorized",
        variant: "success",
      });
    }
  }, [reauthorized]);

  return (
    <Column gap={6} flexGrow={1} minWidth={0}>
      <Row align="center" justify="space-between" gap={4}>
        <Heading>{destination!.definition?.name} configuration</Heading>
        <Row align="center" gap={4}>
          <DocsLink
            name={destination!.definition?.name}
            href={destination!.definition?.docs}
          />
          {config && !isOAuth && definition?.testConnection ? (
            <TestUpdatedDestinationButton
              credentialId={credentialId}
              destinationId={id!}
              newConfiguration={config}
              onError={setTestError}
              onLoading={setTesting}
              onResult={setTestResult}
              onTestResult={setTestConnectionResult}
            />
          ) : null}
        </Row>
      </Row>
      {definition && (
        <SetupForm
          config={config}
          credentialId={credentialId}
          definition={definition}
          destination={destination}
          error={testError}
          isSetup={false}
          setConfig={setConfigDirty}
          setCredentialId={setCredentialId}
          connectionHandlers={{
            onResult: setTestResult,
            onTestResult: setTestConnectionResult,
            onError: setTestError,
            onLoading: setTesting,
            testResult,
            testConnectionResult,
            testError,
          }}
        />
      )}
      {testConnectionResult && config && !isOAuth && (
        <Card>
          <TestConnectionTable
            result={testConnectionResult}
            testing={testing}
          />
        </Card>
      )}
      <ActionBar>
        <ButtonGroup>
          <PermissionedButton
            permission={{
              v2: {
                resource: "destination",
                grant: "can_update",
                id: id!,
              },
            }}
            size="lg"
            variant="primary"
            isDisabled={!dirty}
            isLoading={updating}
            onClick={update}
          >
            Save changes
          </PermissionedButton>
        </ButtonGroup>
      </ActionBar>
    </Column>
  );
};
