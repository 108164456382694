import { FC } from "react";

import { Column, Text } from "@hightouchio/ui";

import { QueryBuilder } from "src/components/explore";
import { QueryBuilderProps } from "src/components/explore/query-builder";

type Props = {
  parentModel: QueryBuilderProps["parentModel"];
  conditions: QueryBuilderProps["filter"];
  setConditions: QueryBuilderProps["setConditions"];
};

export const SubsetFilter: FC<Readonly<Props>> = ({
  parentModel,
  conditions,
  setConditions,
}) => {
  return (
    <>
      <Column>
        <Text fontWeight="medium">Definition</Text>
        <Text color="text.secondary">
          Add filters to specify who should be included in this subset
        </Text>
      </Column>

      <QueryBuilder
        parentModel={parentModel}
        filter={conditions}
        setConditions={(c) => {
          setConditions(c);
        }}
      />
    </>
  );
};
