import { useUser } from "src/contexts/user-context";
import {
  useSubsetAccessForModelQuery,
  useSubsetAccessForSyncQuery,
} from "src/graphql";

import { ResourceToPermission } from "./types";
import { useResourcePermission } from "./use-resource-permission";
import { useSubsetsEnabled } from "src/hooks/use-subsets-enabled";

interface Options {
  suspense: boolean;
}

export const useSubsetPermissions = <Resource extends ResourceToPermission>(
  resource: Resource,
  id?: number | string | null,
  options?: Options,
): { isLoading: boolean; permission: boolean } => {
  const { user } = useUser();
  const appSubsetsEnabled = useSubsetsEnabled();

  // Double check to see if user is an admin
  const { isPermitted: isWorkspaceAdmin } = useResourcePermission({
    v2: { resource: "workspace", grant: "can_update" },
  });

  const shouldIgnorePermissionsCheck =
    user?.is_impersonating || // User is impersonating
    user?.permissions_v2_enabled || // User has permissions v2 enabled. v2 permissions are handled at the core permissioning level
    isWorkspaceAdmin; // User is a workspace admin

  const { data: subsetSyncGranted, isLoading: syncLoading } =
    useSubsetAccessForSyncQuery(
      {
        syncId: String(id),
      },
      {
        select: (data) =>
          !data?.syncs?.[0]?.segment?.subsets?.length ||
          data.syncs[0].segment.subsets.every((s) =>
            s.subset_value.access_grants.some((g) => g.user_id === user?.id),
          ),
        enabled:
          !shouldIgnorePermissionsCheck &&
          appSubsetsEnabled &&
          resource === ResourceToPermission.Sync &&
          Boolean(user?.id && id),
        suspense: options?.suspense,
      },
    );

  const { data: subsetAudienceGranted, isLoading: audienceLoading } =
    useSubsetAccessForModelQuery(
      {
        segmentId: String(id),
      },
      {
        select: (data) => {
          return (
            !data?.segments_by_pk?.subsets?.length ||
            data?.segments_by_pk?.subsets.every((s) =>
              s.subset_value.access_grants.some((g) => g.user_id === user?.id),
            )
          );
        },
        enabled:
          !shouldIgnorePermissionsCheck &&
          appSubsetsEnabled &&
          resource === ResourceToPermission.Audience &&
          Boolean(user?.id && id),
        suspense: options?.suspense,
      },
    );

  if (shouldIgnorePermissionsCheck) {
    return {
      permission: true,
      isLoading: false,
    };
  }

  if (!appSubsetsEnabled) {
    return {
      isLoading: false,
      permission: true,
    };
  }

  if (
    resource !== ResourceToPermission.Audience &&
    resource !== ResourceToPermission.Sync
  ) {
    return {
      isLoading: false,
      permission: true,
    };
  }

  return resource === ResourceToPermission.Audience
    ? { isLoading: audienceLoading, permission: subsetAudienceGranted ?? false }
    : { isLoading: syncLoading, permission: subsetSyncGranted ?? false };
};
