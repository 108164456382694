import * as Yup from "yup";
import type {
  FormkitNode,
  FormkitSection,
  Option,
  RadioGroupOption,
} from "../../api";
import { Checkbox, Input, RadioGroup, Select, TextArea } from "../components";
import { Field, Section } from "../layouts";
import { switchOnStateKey } from "../modifiers";
import { MappingsSection } from "./common";
import { ModeSection } from "./orm";
import { keyBy } from "lodash";

export function FileModeSection(
  props: {
    options?: (RadioGroupOption | "all" | "insert" | "diff")[];
    fileType?: string;
  } = {},
): FormkitSection {
  const fileType = props.fileType ?? "CSV";
  const defaultfileModeOptions: RadioGroupOption[] = [
    {
      label: "All",
      value: "all",
      description: `Creates a ${fileType} file with all the rows in the query results every time the sync is run.`,
    },
    {
      label: "Insert",
      value: "insert",
      description: `Creates a ${fileType} file with the rows that were added since the last sync.`,
    },
    {
      label: "Diff",
      value: "diff",
      description: `Creates 3 ${fileType} files with rows (1) added, (2)
      changed, and (3) removed since the last sync. Hightouch only creates a
      file for a given operation if you specify a path for it.`,
    },
  ];

  const grouped = keyBy(defaultfileModeOptions, "value");

  const options = props.options ?? defaultfileModeOptions;

  return ModeSection({
    options: options
      .map((o): RadioGroupOption | undefined =>
        isBaseMode(o) ? grouped[o] : o,
      )
      .filter(Boolean) as RadioGroupOption[],
  });
}

const isBaseMode = (v: any): v is "all" | "insert" | "diff" => {
  return ["all", "insert", "diff"].includes(v);
};

export function FileTypeSection(props: { options: Option[] }): FormkitSection {
  return Section({
    heading: "What file format would you like to use?",
    children: [
      RadioGroup("fileType", {
        validation: Yup.string().required(),
        options: props.options,
      }),
    ],
  });
}

export function FilePathSection(
  props: {
    heading?: string;
    subheading?: string;
    fieldName?: string;
    fileType?: string;
  } = {},
): FormkitNode {
  const defaultSection = Section({
    heading: props.heading || "Which file would you like to sync data to?",
    subheading:
      props.subheading ||
      `The parent directory for the file must exist. You can use these timestamp variables in the file name: YYYY, yy, MM, DD, HH, mm, ss, \
          and ms (UTC). You can also include these sync metadata: model.name, model.id, sync.run.id, and sync.id.
          Ex: 'upload/{YYYY}-{MM}-{DD}-segment-{model.id}.${props.fileType}'.
          `,
    children: [
      Input(props.fieldName || "path", {
        placeholder: `results.${props.fileType}`,
        validation: Yup.string().required(),
      }),
    ],
  });

  return switchOnStateKey("mode", [
    {
      value: "all",
      children: [defaultSection],
    },
    {
      value: "insert",
      children: [defaultSection],
    },
    {
      value: "diff",
      children: [
        Section({
          optional: true,
          heading: "Which file would you like to sync added rows to?",
          subheading: `The parent directory for the file must exist. You can use these timestamp variables in the file name: YYYY, MM, DD, HH, mm, ss, \
              and ms (UTC). Ex: 'upload/{YYYY}-{MM}-{DD}-result-added.${props.fileType}'.`,
          children: [
            Input("diffAddedPath", {
              placeholder: `If not provided, no file for added rows will be created.`,
              validation: Yup.string().notRequired(),
            }),
          ],
        }),
        Section({
          optional: true,
          heading: "Which file would you like to sync changed rows to?",
          subheading: `The parent directory for the file must exist. You can use these timestamp variables in the file name: YYYY, MM, DD, HH, mm, ss, \
              and ms (UTC). Ex: 'upload/{YYYY}-{MM}-{DD}-result-changed.${props.fileType}'.`,
          children: [
            Input("diffChangedPath", {
              placeholder: `If not provided, no file for changed rows will be created.`,
              validation: Yup.string().notRequired(),
            }),
          ],
        }),
        Section({
          optional: true,
          heading: "Which file would you like to sync removed rows to?",
          subheading: `The parent directory for the file must exist. You can use these timestamp variables in the file name: YYYY, MM, DD, HH, mm, ss, \
              and ms (UTC). Ex: 'upload/{YYYY}-{MM}-{DD}-result-removed.${props.fileType}'.`,
          children: [
            Input("diffRemovedPath", {
              placeholder: `If not provided, no file for removed rows will be created.`,
              validation: Yup.string().notRequired(),
            }),
          ],
        }),
      ],
    },
  ]);
}

export function TimestampOffsetSection(
  props: {
    default?: any;
  } = {},
): FormkitSection {
  const defaultValue = props.default || 0;

  return Section({
    optional: true,
    heading: "Set File Name Timestamp Offset",
    subheading:
      "By default, the date/time used to generate the file name (above) is the date/time that the sync takes place. If you would like to \
            change this, set an offset in *seconds* below. Ex. to set the date in the file name to 24 hours before the sync takes place, input '-86400'. \
            The default offset is '0'.",
    children: [
      Input("timestampOffsetSeconds", {
        placeholder: "Offset (in seconds) from sync time",
        default: defaultValue,
        type: "number",
        validation: Yup.number().notRequired(),
      }),
    ],
  });
}

export function DelimiterSection(
  props: { options?: any } = {},
): FormkitSection {
  const defaultDelimiterOptions = [
    { label: ", (comma)", value: "," },
    { label: "; (semicolon)", value: ";" },
    { label: "| (pipe)", value: "|" },
    { label: "~ (tilde)", value: "~" },
    { label: "tab", value: "\t" },
  ];

  const optionsToUse = props.options || defaultDelimiterOptions;
  const defaultValue = optionsToUse[0].value;

  return Field({
    heading: "Delimiter",
    component: Select("delimiter", {
      options: props.options ? props.options : defaultDelimiterOptions,
      default: defaultValue,
      validation: Yup.string().required().default(defaultValue),
    }),
  });
}

export function RowDelimiterSection(): FormkitSection {
  const defaultDelimiterOptions = [
    { label: "Auto", value: "auto" },
    { label: "Unix (LF)", value: "unix" },
    { label: "Mac (CR)", value: "mac" },
    { label: "Windows (CR LF)", value: "windows" },
  ];

  return Field({
    heading: "Row delimiter",
    component: Select("csvRowDelimeter", {
      default: "auto",
      options: defaultDelimiterOptions,
      validation: Yup.string().optional(),
    }),
  });
}

export function CsvHeaderSection(
  props: {
    default?: boolean;
  } = {},
): FormkitSection {
  const defaultValue = props.default || false;

  return Field({
    optional: true,
    heading: "Include CSV header?",
    subheading:
      "If checked, a CSV header will be written to the resulting file.",
    component: Checkbox("includeHeader", {
      label: "Include CSV header",
      value: defaultValue,
    }),
  });
}

export function BomSection(): FormkitSection {
  return Field({
    optional: true,
    heading: "Include byte order mark (BOM)?",
    subheading:
      "If checked, a BOM will be prepended to the resulting file, and the file will be encoded as utf-8.",
    component: Checkbox("includeBom", {
      label: "Include BOM",
    }),
  });
}

export function getCommonFileTypeFormElements(props: {
  fileHeading?: string;
  fieldName?: string;
  fileType?: string;
}): FormkitNode[] {
  return [
    FilePathSection({
      heading: props.fileHeading,
      fieldName: props.fieldName,
      fileType: props.fileType,
    }),
    TimestampOffsetSection(),
  ];
}

export function ParquetMappingsSection(): FormkitSection {
  const scalarTypes = [
    { label: "BOOLEAN", value: "BOOLEAN" },
    { label: "BSON", value: "BSON" },
    { label: "BYTE_ARRAY", value: "BYTE_ARRAY" },
    { label: "DOUBLE", value: "DOUBLE" },
    { label: "FLOAT", value: "FLOAT" },
    { label: "INT_8", value: "INT_8" },
    { label: "INT_16", value: "INT_16" },
    { label: "INT_32", value: "INT32" },
    { label: "INT_64", value: "INT64" },
    { label: "INT_96", value: "INT96" },
    { label: "JSON", value: "JSON" },
    { label: "TIME_MICROS", value: "TIME_MICROS" },
    { label: "TIME_MILLIS", value: "TIME_MILLIS" },
    { label: "TIMESTAMP_MICROS", value: "TIMESTAMP_MICROS" },
    { label: "TIMESTAMP_MILLIS", value: "TIMESTAMP_MILLIS" },
    { label: "UINT_8", value: "UINT_8" },
    { label: "UINT_16", value: "UINT_16" },
    { label: "UINT_32", value: "UINT_32" },
    { label: "UINT_64", value: "UINT_64" },
    { label: "UTF8", value: "UTF8" },
  ];
  const dataTypes: Option[] = [
    {
      label: "ARRAY",
      value: "ARRAY",
      typeSpecs: Section({
        heading: "Select repeated element type",
        children: [
          Field({
            heading: "Element type",
            subheading: "What type of data does this array contain?",
            component: Select("elementType", {
              options: scalarTypes,
              validation: {
                type: "string",
                required: true,
              },
            }),
          }),
        ],
      }),
    },
    ...scalarTypes,
  ];

  return MappingsSection({
    creatable: true,
    creatableTypes: dataTypes,
    required: true,
  });
}

export function SkipEmptySection(): FormkitSection {
  return Section({
    heading: "How should Hightouch handle empty result files?",
    children: [
      RadioGroup("skipEmpty", {
        default: true,
        options: [
          {
            label: "Don't skip empty file",
            description: "File will be sent even when results are empty",
            value: undefined,
          },
          {
            label: "Skip empty file",
            description: "File will not be sent when results are empty",
            value: true,
          },
        ],
      }),
    ],
  });
}

export function concurrencySection(): FormkitSection {
  return Section({
    heading: "What concurrency should Hightouch use in the SFTP file upload?",
    subheading: `The default concurrency value is 1.
    Higher concurrency values will increase upload speeds, but can cause disruptions when 
    simultaneously running multiple Hightouch syncs to your SFTP server. 
    Check that your server supports concurrent uploads before changing this value.`,
    optional: true,
    children: [
      Input("concurrency", {
        type: "number",
        validation: Yup.number().min(1).nullable().optional(),
        default: 1,
        placeholder: "1",
      }),
    ],
  });
}

export function pgpKeyField(): FormkitSection {
  return Field({
    heading: "PGP Public Key",
    optional: true,
    component: TextArea("pgpPublicKey", {
      validation: Yup.string().nullable().notRequired(),
    }),
  });
}
