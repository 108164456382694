import { FC, Fragment, ReactNode } from "react";

import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import { Code, Column, Row, Text } from "@hightouchio/ui";

import { IconBox } from "src/components/icon-box";
import { FilterableColumn, TraitDefinition } from "src/types/visual";
import {
  EventColumn,
  RelationColumn,
} from "src/components/explore/filter-popover/constants";
import { PropertyConditions } from "src/components/explore/visual/property-conditions";
import { getPropertyNameFromColumn } from "src/components/explore/visual/utils";

import { FormulaTraitEditor } from "./formula-trait-editor";
import { parseTraitConfig, TRAIT_TYPE_LABELS } from "./utils";

type Props = {
  type: TraitType;
  config: TraitConfig;
  parentModel:
    | {
        filterable_audience_columns: FilterableColumn[];
        traits: TraitDefinition[];
      }
    | undefined;
  relatedModel:
    | {
        event: { timestamp_column: string } | null | undefined;
        name: string;
      }
    | undefined;
  hideConditions?: boolean;
  hideModel?: boolean;
};

export const CalculationSummary: FC<Readonly<Props>> = ({
  type,
  config: rawConfig,
  parentModel,
  relatedModel,
  hideConditions,
  hideModel,
}) => {
  const {
    aggregatedColumn,
    orderByColumn,
    aggregation,
    conditions = [],
  } = parseTraitConfig(type, rawConfig);

  const isEventModel = relatedModel && relatedModel.event;

  const ModelWithIcon = () => (
    <Row gap={1} alignItems="center" ml={0.5}>
      <IconBox
        bg={isEventModel ? EventColumn.color : RelationColumn.color}
        boxSize={4}
        icon={isEventModel ? EventColumn.icon : RelationColumn.icon}
        iconSize={3}
      />
      <BoldText>{relatedModel ? relatedModel.name : "--"}</BoldText>
    </Row>
  );

  const summary: {
    value: string | undefined | null;
    Component: FC<{ children: ReactNode }>;
  }[] = [];

  if (type === TraitType.Formula) {
    return (
      <FormulaTraitEditor
        columns={parentModel?.filterable_audience_columns ?? []}
        traits={parentModel?.traits ?? []}
        value={aggregation ?? ""}
        readOnly
      />
    );
  }

  if (type === TraitType.RawSql) {
    summary.push({ value: aggregation, Component: Code });
  } else {
    summary.push(
      {
        value: TRAIT_TYPE_LABELS[type],
        Component: BoldText,
      },
      {
        value: "of",
        Component: GrayText,
      },
      {
        value: getPropertyNameFromColumn(aggregatedColumn),
        Component: BoldText,
      },
      {
        value: orderByColumn ? "ordered by" : null,
        Component: orderByColumn ? GrayText : Fragment,
      },
      {
        value: orderByColumn ? getPropertyNameFromColumn(orderByColumn) : null,
        Component: orderByColumn ? BoldText : Fragment,
      },
    );
  }

  if (relatedModel && !hideModel) {
    summary.push(
      {
        value: isEventModel ? "of events in" : "of rows in",
        Component: GrayText,
      },
      {
        value: null,
        Component: ModelWithIcon,
      },
    );
  }

  return (
    <Column
      bg="base.lightBackground"
      borderRadius="md"
      borderLeft="8px solid"
      borderLeftColor="teal.300"
      gap={4}
      px={4}
      py={3}
    >
      <Row gap={1} flexWrap="wrap">
        {summary.map(({ value, Component }, index) => (
          <Component key={`${value}-${index}`}>{value}</Component>
        ))}
      </Row>
      {!hideConditions && conditions.length > 0 && (
        <Column pl={8} gap={4}>
          <PropertyConditions conditions={conditions} />
        </Column>
      )}
    </Column>
  );
};

const BoldText = ({ children }: { children: ReactNode }) => (
  <Text color="text.primary" fontWeight="medium">
    {children}
  </Text>
);

const GrayText = ({ children }: { children: ReactNode }) => (
  <Text color="text.secondary">{children}</Text>
);
