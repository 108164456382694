import { FC, useEffect } from "react";

import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { ForceReload } from "src/components/router/force-reload";
import { useUser } from "src/contexts/user-context";
import * as analytics from "src/lib/analytics";
import { Logrocket } from "src/lib/logrocket";
import { useWorkspaceActions } from "./commands";
import { LockOutModal } from "./lock-out-modal";
import { Koala } from "src/lib/koala";
import { Pylon } from "src/lib/pylon";
import { AppProviders } from "./providers";
import { useDatadog } from "src/lib/datadog";
import { useRecentlyViewed } from "src/pages/home/components/use-recently-viewed";

export const App = ({ children }: { children: React.ReactNode }) => {
  return (
    <AppProviders>
      <Content>{children}</Content>
    </AppProviders>
  );
};

const Content: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, workspace } = useUser();
  const { partnerUser } = useFlags();

  useEffect(() => {
    if (user && !user.not_member_of_current_workspace) {
      analytics.identify(String(user.id), {
        email: user.email,
        name: user.name,
      });

      if (user.current_workspace_id) {
        analytics.group(user.current_workspace_id);
      }
    }

    Sentry.setTag("user_id", String(user?.id));

    if (workspace) {
      Sentry.setTag("workspace_id", String(workspace?.id));
      Sentry.setTag("workspace_region", String(workspace?.region));
    }
  }, [user, workspace]);

  useEffect(() => {
    Sentry.setTag("partner_user", partnerUser);
  }, [partnerUser]);

  useEffect(() => {
    analytics.track("Application Opened", {
      window_height: window.innerHeight,
      window_width: window.innerWidth,
    });
  }, []);

  useDatadog();
  useRecentlyViewed();

  return (
    <>
      <ForceReload />
      <Commands />
      <Pylon />
      <Logrocket />
      <Koala />
      <div id="portalAnchor" style={{ zIndex: 1500 }}></div>
      <LockOutModal />
      {children}
    </>
  );
};

const Commands: FC = () => {
  useWorkspaceActions();

  return null;
};
