import { Column, Text } from "@hightouchio/ui";

import { AnalyticsPlaceholderImage } from "src/pages/analytics/placeholders";

export const EmptyChartsPlaceholder = () => {
  return (
    <Column flex={1} align="center" justifyContent="center" gap={4}>
      <AnalyticsPlaceholderImage />
      <Text fontWeight="medium">No charts were generated for this agent</Text>
    </Column>
  );
};
