// Define custom cache keys here, which can be provided are arguments to
// an analytics request to overriding the default cache key generation.

export const outcomeConversionRateCacheKey = ({
  workspaceId,
  flowId,
  outcomeId,
}: {
  workspaceId: string;
  flowId: string;
  outcomeId: string;
}) =>
  // Replicate format of packages/backend/lib/query/visual/analytics/redis.ts#getTimeSeriesAnalyticsCacheKey
  [
    workspaceId,
    "analytics:timeseries",
    "custom_cache_key",
    "outcome_conversion_rate",
    flowId,
    outcomeId,
  ].join(":");
