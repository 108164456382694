import { useEffect, useState, FC } from "react";

import {
  useToast,
  Menu,
  MenuList,
  MenuActionsButton,
  Spinner,
  Column,
  Row,
  Text,
  PlayIcon,
  DeleteIcon,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
  useLocation,
} from "src/router";

import { DetailPage } from "src/components/layout";
import { MetadataBar, MetadataLabel } from "src/components/metadata-bar";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Schedule } from "src/components/schedule";
import { DisplaySlug } from "src/components/slug/display-slug";
import { Warning } from "src/components/warning";
import { PermissionProvider } from "src/components/permission/permission-context";
import {
  useSequenceQuery,
  useUpdateSequenceMutation,
  useDeleteSequenceMutation,
  useUpdateSequenceRunMutation,
  SequenceQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import { TrackView, track } from "src/lib/analytics";
import { PageSpinner } from "src/components/loading";
import { SyncRunStatus, syncStatusIsTerminal } from "src/utils/syncs";

import {
  PermissionedButton,
  PermissionedEditableHeading,
  PermissionedMenuItem,
  PermissionedSwitch,
} from "src/components/permission";

export type SequenceContext = {
  sequence: NonNullable<SequenceQuery["sync_sequences_by_pk"]>;
};

export const SequenceLoader: FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();

  const { data: sequence, isLoading } = useSequenceQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      refetchInterval: location.pathname.endsWith("configuration")
        ? undefined
        : 5000,
      select: (data) => data.sync_sequences_by_pk,
    },
  );

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!id) {
    return null;
  }

  if (!sequence) {
    return (
      <Warning subtitle="It may have been deleted" title="Sequence not found" />
    );
  }

  return <Outlet context={{ sequence }} />;
};

export const Sequence: FC = () => {
  const { sequence } = useOutletContext<SequenceContext>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [showRun, setShowRun] = useState(true);

  const { mutateAsync: updateSequence } = useUpdateSequenceMutation();
  const { mutateAsync: deleteSequence } = useDeleteSequenceMutation();
  const { mutateAsync: updateSequenceRun } = useUpdateSequenceRunMutation();

  const lastRun = sequence?.runs[0];
  const status = lastRun?.status;
  const running = status === "running";

  const { data: entitlementsData } = useEntitlements(true);
  const overageLockout: boolean = entitlementsData.overage?.overageLockout;
  const overageText = entitlementsData.overage?.destinationOverageText; // @TODO: hookup once there are more than one overage.

  const toggleEnabled = async (enabled: boolean) => {
    try {
      await updateSequence({
        id: sequence.id,
        object: {
          schedule_paused: !enabled,
        },
      });

      toast({
        id: "toggle-sequence",
        title: `Sequence was ${enabled ? "enabled" : "disabled"}`,
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "toggle-sequence",
        title: `Couldn't ${enabled ? "enable" : "disable"} this sequence`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const cancelRun = async () => {
    if (!lastRun) return;

    setCancelling(true);

    try {
      await updateSequenceRun({
        id: lastRun.id,
        object: {
          trigger_cancel: true,
        },
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "cancel-sequence-run",
        title: "Couldn't cancel this sequence run",
        variant: "error",
      });
      setCancelling(false);
    }
  };

  const startRun = async () => {
    try {
      await updateSequence({ id: sequence.id, object: { force_run: true } });
      track("Sequence Manually Started", {
        sync_sequence_id: sequence.id,
        schedule_type: sequence.schedule?.type,
      });
      toast({
        id: "start-sequence-run",
        title: "Manual run will begin shortly",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "start-sequence-run",
        title: "Couldn't start this sequence run",
        variant: "error",
      });
    }
  };

  const saveName = async (name: string) => {
    try {
      await updateSequence({
        id: sequence.id,
        object: {
          name,
        },
      });
      toast({
        id: "update-sequence",
        title: "Sequence name updated",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "update-sequence",
        title: "Couldn't update this sequence",
        variant: "error",
      });
    }
  };

  useEffect(() => {
    setEnabled(!sequence?.schedule_paused);
  }, [sequence]);

  useEffect(() => {
    if (syncStatusIsTerminal(status as SyncRunStatus)) {
      setShowRun(true);
    }
    if (status === "cancelled") {
      setCancelling(false);
    }
  }, [status]);

  return (
    <>
      <TrackView name="Sequence Details Page" />

      <PermissionProvider
        permission={{
          v1: {
            resource: "sync",
            grant: "update",
          },
        }}
      >
        <DetailPage
          crumbs={[{ label: "All sequences", link: "/sequences" }]}
          title={`${sequence.name} - Sequences`}
          hasBottomPadding
          tabs={[
            {
              title: "Runs",
              path: "runs",
            },
            {
              title: "Configuration",
              path: "configuration",
            },
            {
              title: "Schedule",
              path: "schedule",
            },
          ]}
          header={
            <Column>
              <Row justifyContent="space-between">
                <PermissionedEditableHeading
                  permission={{ v1: { resource: "sync", grant: "update" } }}
                  size="lg"
                  value={sequence?.name ?? ""}
                  onChange={saveName}
                />

                <Row gap={4}>
                  <Row align="center" gap={2}>
                    <Text
                      textTransform="uppercase"
                      size="sm"
                      fontWeight="semibold"
                      color="text.tertiary"
                    >
                      {enabled ? "Enabled" : "Disabled"}
                    </Text>
                    <PermissionedSwitch
                      aria-label="Enable sequence."
                      permission={{ v1: { resource: "sync", grant: "enable" } }}
                      isChecked={enabled}
                      onChange={(value) => {
                        setEnabled(value);
                        toggleEnabled(value);
                      }}
                    />
                  </Row>

                  <Menu>
                    <MenuActionsButton variant="secondary" />
                    <MenuList>
                      <PermissionedMenuItem
                        permission={{
                          v1: { resource: "sync", grant: "delete" },
                        }}
                        icon={DeleteIcon}
                        isDisabled={running}
                        variant="danger"
                        onClick={() => {
                          setDeleting(true);
                        }}
                      >
                        Delete
                      </PermissionedMenuItem>
                    </MenuList>
                  </Menu>

                  {running || cancelling ? (
                    <PermissionedButton
                      permission={{ v1: { resource: "sync", grant: "start" } }}
                      isDisabled={cancelling}
                      onClick={cancelRun}
                    >
                      <Spinner size="sm" mr={2} />
                      {cancelling ? "Canceling..." : "Cancel run"}
                    </PermissionedButton>
                  ) : (
                    <PermissionedButton
                      permission={{ v1: { resource: "sync", grant: "start" } }}
                      tooltip={overageLockout && overageText}
                      isDisabled={!showRun || overageLockout}
                      icon={PlayIcon}
                      onClick={() => {
                        setShowRun(false);
                        startRun();
                      }}
                    >
                      Run
                    </PermissionedButton>
                  )}
                </Row>
              </Row>

              <MetadataBar>
                <Column>
                  <MetadataLabel>Schedule</MetadataLabel>
                  <Schedule schedule={sequence.schedule} />
                </Column>
                <Column>
                  <MetadataLabel mb="-2px">Slug</MetadataLabel>
                  <DisplaySlug currentSlug={sequence.slug} />
                </Column>
              </MetadataBar>
            </Column>
          }
        >
          <Column gap={6} width="100%">
            <Outlet context={{ sequence }} />
          </Column>
        </DetailPage>
      </PermissionProvider>

      <DeleteConfirmationModal
        isOpen={deleting}
        label="sequence"
        onClose={() => {
          setDeleting(false);
        }}
        onDelete={async () => {
          try {
            await deleteSequence({
              id: sequence.id,
            });
            track("Sequence Deleted", {
              sync_sequence_id: sequence.id,
            });

            navigate("/sequences");
          } catch (error) {
            captureException(error);
            toast({
              id: "delete-sequence",
              title: "Couldn't delete this sequence",
              variant: "error",
            });
          }
        }}
      />
    </>
  );
};
