import { FC, ReactNode } from "react";

import { Box, BoxProps, FlexboxProps, SpaceProps } from "@hightouchio/ui";

export type SelectableProps = SpaceProps &
  FlexboxProps & {
    width?: BoxProps["width"];
    height?: BoxProps["height"];
    bg?: BoxProps["bg"];
    overflow?: BoxProps["overflow"];
    children: ReactNode;
    selected: boolean | undefined;
    onSelect: () => void;
    disabled?: boolean;
  };

export const Selectable: FC<Readonly<SelectableProps>> = ({
  children,
  selected,
  onSelect,
  disabled,
  bg = "white",
  ...props
}) => {
  const keyPress = (key: string) => {
    if (key === "Enter") {
      onSelect();
    }
  };

  return (
    <Box
      as="button"
      display="flex"
      alignItems="center"
      {...props}
      disabled={disabled}
      position="relative"
      borderRadius="md"
      bg={selected ? "#EDF6F7" : bg}
      border="1px solid"
      borderColor={selected ? "#B3D9DB" : "gray.300"}
      pointerEvents={selected ? "none" : "auto"}
      transition="border-color 200ms"
      _hover={{
        bg: disabled ? undefined : "#EDF6F7",
      }}
      _focus={{
        outline: "none",
      }}
      cursor={disabled ? "default" : "pointer"}
      tabIndex={1}
      onClick={disabled ? undefined : onSelect}
      onKeyPress={(event: any) => {
        if (!disabled) {
          keyPress(event.key);
        }
      }}
    >
      {children}
    </Box>
  );
};
