import { FC } from "react";

import {
  Box,
  CloseIcon,
  Column,
  Combobox,
  IconButton,
  Row,
  Skeleton,
  Text,
  Tooltip,
} from "@hightouchio/ui";

import { NumericFontStyles } from "src/components/audiences/constants";
import { OverlapIcon } from "src/components/audiences/insights/placeholders";
import {
  QueryArguments,
  SharedChartQueryParameters,
} from "src/components/audiences/insights/types";
import { VennDiagram } from "src/components/audiences/venn-diagram";
import { ErrorMessage } from "src/components/explore/visual/error-message";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useCompareAudiencesQuery } from "src/graphql";
import { AnalyticsColors } from "src/pages/analytics/constants";
import { accurateCommaNumber } from "src/utils/numbers";

import { ChartCard, ChartCardProps, LoadingBar } from "./shared";

export type OverlapData = {
  type: "overlap";
  comparedAudienceId: string | null;
  queryParameters: SharedChartQueryParameters | null;
};

type OverlapChartCardProps = ChartCardProps &
  QueryArguments & {
    optionsLoading?: boolean;
    // TODO(samuel): should these types be centralized?
    options: {
      id: any;
      name: string;
      description: string | null;
    }[];
    value: OverlapData;
    onChange: (value: string | null) => void;
  };

export const OverlapChartCard: FC<OverlapChartCardProps> = ({
  options,
  optionsLoading = false,
  sourceId,
  parentModelId,
  value,
  onChange,
  onClose,
}) => {
  const compareAudiencesQuery = useCompareAudiencesQuery(
    {
      id: sourceId,
      parentModelId,
      compareToAudiences: [value.comparedAudienceId?.toString() ?? ""].filter(
        Boolean,
      ),
      filter: value.queryParameters?.filter,
      audienceId: value.queryParameters?.audienceId,
      subsetIds: value.queryParameters?.subsetIds,
      destinationId: value.queryParameters?.destinationId,
      // Audience comparison doesn't support using Fast Query
      // so we intentionally omit passing the `isFastQueryEnabled` variable to the mutation
    },
    {
      enabled: Boolean(value.comparedAudienceId),
    },
  );

  const selectedAudience = options.find(
    (option) => option.id === value.comparedAudienceId,
  );
  const title = `Overlap of ${selectedAudience?.name ?? "audience"}`;

  let content = <PlaceholderContent />;

  const comparison =
    compareAudiencesQuery.data?.audienceComparison.comparisons?.[0];
  if (compareAudiencesQuery.data && comparison && selectedAudience) {
    content = (
      <OverlapChartContent
        audienceSize={
          compareAudiencesQuery.data.audienceComparison.audienceSize
        }
        comparedAudienceSize={comparison.audienceSize}
        overlapSize={comparison.overlapSize}
        comparedAudienceName={selectedAudience.name}
      />
    );
  }

  return (
    <ChartCard>
      <Row justify="space-between" align="center">
        <TextWithTooltip fontWeight="medium" size="lg" message={title}>
          {title}
        </TextWithTooltip>
        <Tooltip message="Remove chart" placement="top-end" openSpeed="slow">
          <IconButton
            aria-label="Remove chart."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Tooltip>
      </Row>
      {compareAudiencesQuery.isLoading ? (
        <LoadingContent />
      ) : (
        <>
          {content}
          <Column>
            <Combobox
              isClearable
              isLoading={optionsLoading}
              isInvalid={Boolean(compareAudiencesQuery.error)}
              options={options}
              value={value.comparedAudienceId}
              optionLabel={(option) => option.name}
              optionValue={(option) => option.id}
              onChange={(value) => {
                onChange(value ?? null);
              }}
              width="100%"
              placeholder="Select an audience to compare"
            />
            {compareAudiencesQuery.error && (
              <ErrorMessage>{compareAudiencesQuery.error.message}</ErrorMessage>
            )}
          </Column>
        </>
      )}
    </ChartCard>
  );
};

type OverlapChartContentProps = {
  audienceSize: number;
  comparedAudienceSize: number;
  overlapSize: number;
  comparedAudienceName: string;
};

const OverlapChartContent: FC<OverlapChartContentProps> = ({
  audienceSize,
  comparedAudienceSize,
  overlapSize,
  comparedAudienceName,
}) => {
  const overlapPercentage =
    audienceSize && audienceSize > 0 ? overlapSize / audienceSize : 0;

  return (
    <>
      <Row justify="center">
        <VennDiagram
          baseCircleSize={132}
          comparedAudienceSize={comparedAudienceSize}
          currentAudienceSize={audienceSize}
          sharedMembers={overlapSize}
          comparedAudienceName={comparedAudienceName}
        />
      </Row>

      <Column gap={2}>
        <DataRow
          color={AnalyticsColors.Primary.primary}
          label="Current audience"
          value={
            typeof audienceSize === "number"
              ? accurateCommaNumber(audienceSize)
              : "unknown"
          }
        />
        <DataRow
          color={AnalyticsColors.Secondary.primary}
          label={comparedAudienceName}
          value={accurateCommaNumber(comparedAudienceSize)}
        />
        <DataRow
          color="ocean.500"
          label="Member overlap"
          value={accurateCommaNumber(overlapSize)}
          percentage={overlapPercentage}
        />
      </Column>
    </>
  );
};

type DataRowProps = {
  color: string;
  label: string;
  value: string;
  percentage?: number | undefined;
};

const DataRow: FC<DataRowProps> = ({ color, label, value, percentage }) => {
  return (
    <Row align="center" justify="space-between" gap={2} overflowX="hidden">
      <Row align="center" gap={2} overflowX="hidden">
        <Box
          flexShrink={0}
          height="12px"
          width="12px"
          borderRadius="50%"
          bg={color}
        />

        <TextWithTooltip message={label}>{label}</TextWithTooltip>
      </Row>

      <Row
        justifySelf="end"
        gap={1}
        flexWrap="nowrap"
        whiteSpace="nowrap"
        sx={{ span: NumericFontStyles }}
      >
        {percentage !== undefined && (
          <Text color="text.secondary">({(percentage * 100).toFixed(2)}%)</Text>
        )}
        <Text fontWeight="medium">{value}</Text>
      </Row>
    </Row>
  );
};

const LoadingContent = () => (
  <Skeleton isLoading>
    <Column gap={4}>
      <Row align="center" justify="center">
        <OverlapIcon opacity={0.8} />
      </Row>
      <Box display="grid" gap={3} gridTemplateColumns="repeat(12, 1fr)">
        <LoadingBar gridColumn="1 / 4" />
        <LoadingBar gridColumn="1 / 7" />
        <LoadingBar gridColumn="1 / 9" />
      </Box>
    </Column>
  </Skeleton>
);

const PlaceholderContent = () => (
  <>
    <Row align="center" justify="center">
      <OverlapIcon />
    </Row>
    <Row justify="center">
      <Text color="text.secondary">
        Identify the amount of user overlap with another audience
      </Text>
    </Row>
  </>
);
