import { FC, useMemo } from "react";

import { Column, SectionHeading } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { UnreachableCaseError } from "ts-essentials";

import {
  getSchemaModelType,
  getSchemaModelTypeNames,
} from "src/components/audiences/utils";
import { Query } from "src/components/models/query";
import { PermissionedButton } from "src/components/permission";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { AdStatsForm } from "src/pages/schema/components/ad-stats-fields";
import { AssetForm } from "src/pages/schema/components/asset";
import { CatalogForm } from "src/pages/schema/components/catalog";
import { EventPrimaryKeyForm } from "src/pages/schema/components/event-primary-key";
import {
  InteractionForm,
  MissingAssetModelWarning,
} from "src/pages/schema/components/interaction";
import { ParentForm } from "src/pages/schema/components/parent";
import { TimestampForm } from "src/pages/schema/components/timestamp-field";
import { EventTimestampModelTypes } from "src/pages/schema/graph/utils";
import { SchemaObjectType } from "src/pages/schema/types";
import { LinkButton, useNavigate } from "src/router";
import { isQueryType, QueryType } from "src/types/models";
import { SchemaModelType } from "src/types/schema";

import {
  shouldShowConfigurationSection,
  showMissingModelWarning,
} from "./utils";

export const QueryTab: FC<Readonly<{ object: SchemaObjectType }>> = ({
  object,
}) => {
  const { enableJourneysEventBasedEntry } = useFlags();
  const navigate = useNavigate();
  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "model", grant: "can_update", id: object?.id },
    v1: { resource: "audience_schema", grant: "update", id: object?.id },
  });
  const type = getSchemaModelType(object);
  const { typePath } = getSchemaModelTypeNames(type);

  const actions = useMemo(() => {
    if (object.golden_record) {
      // Golden record parent models can have a table/sql query type
      return (
        <LinkButton href={`/idr/${object.golden_record?.idr_id}/golden-record`}>
          View golden record
        </LinkButton>
      );
    }
    if (isQueryType(object.query_type)) {
      switch (object.query_type) {
        case QueryType.RawSql:
        case QueryType.Table:
        case QueryType.Custom:
        case QueryType.DbtModel:
        case QueryType.LookerLook:
        case QueryType.Sigma:
          return (
            <PermissionedButton
              permission={{
                v1: {
                  resource: "audience_schema",
                  grant: "update",
                },
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: object.id,
                },
              }}
              onClick={() =>
                navigate(`/schema/${typePath}/${object.id}/query?v2=true`)
              }
            >
              Edit
            </PermissionedButton>
          );
        case QueryType.IDRGoldenRecord:
        case QueryType.JourneyNode:
        case QueryType.DecisionEngine:
        case QueryType.Visual:
          return null;
        default:
          throw new UnreachableCaseError(object.query_type);
      }
    }
    return null;
  }, [object.query_type]);

  return (
    <Column gap={8} p={6} overflow="auto" height="100%">
      {showMissingModelWarning(object) && (
        <MissingAssetModelWarning object={object} />
      )}

      <Column maxHeight="40vh" overflow="hidden" flexShrink={0}>
        <Query model={object} source={object.connection} actions={actions} />
      </Column>

      {shouldShowConfigurationSection(type) && (
        <Column gap={6}>
          <SectionHeading>Configuration</SectionHeading>
          {EventTimestampModelTypes.includes(type) && (
            <>
              <TimestampForm
                object={object}
                hasUpdatePermission={hasUpdatePermission}
              />
              <InteractionForm
                object={object}
                hasUpdatePermission={hasUpdatePermission}
              />
              {enableJourneysEventBasedEntry && (
                <EventPrimaryKeyForm
                  object={object}
                  hasUpdatePermission={hasUpdatePermission}
                />
              )}
            </>
          )}
          {type === SchemaModelType.Catalog && (
            <CatalogForm
              object={object}
              hasUpdatePermission={hasUpdatePermission}
            />
          )}
          {type === SchemaModelType.Parent && (
            <ParentForm
              object={object}
              hasUpdatePermission={hasUpdatePermission}
            />
          )}
          {type === SchemaModelType.Asset && (
            <AssetForm
              object={object}
              hasUpdatePermission={hasUpdatePermission}
            />
          )}
          {type === SchemaModelType.AdStats && (
            <AdStatsForm
              object={object}
              hasUpdatePermission={hasUpdatePermission}
            />
          )}
        </Column>
      )}
    </Column>
  );
};
