import React from "react";

import { Box, Row, Switch, Text } from "@hightouchio/ui";
import { Link } from "src/router";
import { Controller, useFormContext } from "react-hook-form";

import { ExtensionSyncStatusBadge } from "src/components/extensions/extension-sync-status-badge";
import { usePermissionContext } from "src/components/permission/permission-context";

import { Card } from "src/components/card";

interface GitSyncableResourceToggleProps {
  ariaLabel: string;
  fieldName: string;
  last_run_at?: string;
  error: any;
  description?: string;
  title: string;
  learnMoreUrl?: string;
  icon?: React.ReactNode;
  blocked?: boolean;
  onToggle?: (value: boolean) => void;
  renderError?: (error: any) => React.ReactNode;
  stdout?: { text: string; title: string; status: "success" };
}

export const GitSyncableResourceToggle = ({
  ariaLabel,
  fieldName,
  error,
  learnMoreUrl,
  title,
  icon,
  renderError,
  blocked,
  onToggle,
  description,
  last_run_at,
  stdout,
}: GitSyncableResourceToggleProps) => {
  const syncEnabled = useFormContext().getValues(fieldName);

  const updatePermission = usePermissionContext();

  const header = (
    <Row justifyContent="space-between" alignItems="center" pb="12px">
      <Row alignItems="center">
        {icon}
        <Text ml={icon ? 2 : 0} size="lg">
          {title}
        </Text>
      </Row>
      <Controller
        name={fieldName}
        render={({ field }) => (
          <Box alignItems="center" display="flex" gap={2}>
            <Text
              textTransform="uppercase"
              size="sm"
              color="text.secondary"
              fontWeight="semibold"
            >
              {field.value ? "Enabled" : "Disabled"}
            </Text>

            <Switch
              aria-label={ariaLabel}
              isChecked={field.value}
              isDisabled={blocked ?? updatePermission.unauthorized}
              onChange={(value) => {
                field.onChange(value);

                if (typeof onToggle === "function") {
                  onToggle(value);
                }
              }}
            />
          </Box>
        )}
      />
    </Row>
  );
  const descriptionComponent = (
    <Row flexDir="column" maxWidth="80%">
      <Row pb={1}>
        <Text mb={2} size="sm" color="text.secondary">
          {description}
        </Text>
      </Row>
      {learnMoreUrl && (
        <Row>
          <Link href={learnMoreUrl}>Learn more</Link>
        </Row>
      )}
    </Row>
  );

  const errorElement = () => {
    if (!error) {
      return null;
    }
    if (renderError) {
      return renderError(error);
    }
    return (
      <Row pt="24px">
        <Box maxWidth="100%">
          {error.message && (
            <Box
              bg="danger.200"
              as="b"
              sx={{
                p: 2,
                borderRadius: "5px 5px 0 0",
                fontWeight: "semibold",
                wordBreak: "break-all",
                whiteSpace: "pre-wrap",
                fontSize: "13px",
              }}
            >
              {error.message}
            </Box>
          )}
          <Box
            as="pre"
            bg="danger.200"
            sx={{
              wordBreak: "break-all",
              whiteSpace: "pre-wrap",
              fontSize: "10px",
              p: 2,
              borderRadius: "0 0 5px 5px",
              maxHeight: "250px",
              overflowY: "auto",
            }}
          >
            {JSON.stringify(error, null, 2)}
          </Box>
        </Box>
      </Row>
    );
  };

  const stdoutElement = () => {
    if (!stdout) {
      return null;
    }
    return (
      <Row pt="24px">
        <Box maxWidth="100%">
          <Box
            sx={{
              p: 2,
              fontWeight: "semibold",
              wordBreak: "break-all",
              whiteSpace: "pre-wrap",
              fontSize: "13px",
              borderRadius: "5px 5px 0 0",
            }}
            bg="gray.100"
          >
            {stdout.title}
          </Box>
          <Box
            as="pre"
            sx={{
              wordBreak: "break-all",
              whiteSpace: "pre-wrap",
              fontSize: "10px",
              p: 2,
              maxHeight: "250px",
              overflowY: "auto",
              borderRadius: "0 0 5px 5px",
            }}
            bg="gray.100"
          >
            {stdout.text}
          </Box>
        </Box>
      </Row>
    );
  };

  const syncStatus = (
    <>
      <Row
        justifyContent="space-between"
        alignItems="center"
        pt="24px"
        userSelect="none"
      >
        <Text fontWeight="semibold" color="text.secondary">
          Sync Status
        </Text>
        <ExtensionSyncStatusBadge
          error={error}
          lastAttemptedAt={last_run_at}
          setup={!last_run_at}
        />
      </Row>
      {stdoutElement()}
      {errorElement()}
    </>
  );

  return (
    <Card>
      {header}
      {description && descriptionComponent}
      {syncEnabled && !blocked && syncStatus}
    </Card>
  );
};
