import { Tooltip, Badge, BadgeProps } from "@hightouchio/ui";

import { abbreviateNumber, commaNumber } from "src/utils/numbers";

export const NumberBadge = ({
  value,
  tooltip,
  icon,
  variant = "subtle",
  format = "comma",
  size = "md",
}: {
  value: number | null;
  variant?: "success" | "error" | "subtle";
  format?: "comma" | "abbreviate";
  tooltip?: string;
  icon?: BadgeProps["svgIcon"];
  size?: BadgeProps["size"];
}) => (
  <Tooltip isDisabled={!tooltip} message={tooltip}>
    <Badge variant={variant} svgIcon={icon} size={size}>
      {value === null
        ? "-"
        : format === "comma"
          ? commaNumber(value)
          : abbreviateNumber(value)}
    </Badge>
  </Tooltip>
);
