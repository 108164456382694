import { FC } from "react";

import {
  CloseIcon,
  Column,
  Heading,
  IconButton,
  Paragraph,
  Row,
} from "@hightouchio/ui";

import { TraitQuery } from "src/graphql";

import { EditTraitDescription } from "./edit-trait-description";
import { EditTraitName } from "./edit-trait-name";
import { Metadata } from "./metadata";

type Props = {
  id: string;
  name: string;
  description: string | null;
  parentModel: NonNullable<
    TraitQuery["trait_definitions_by_pk"]
  >["parent_model"];
  isTemplate: boolean;
  isArchived: boolean;
  isEditable?: boolean;
  onClose?: () => void;
};

export const Header: FC<Readonly<Props>> = ({
  id,
  name,
  description,
  parentModel,
  isEditable,
  isTemplate,
  isArchived,
  onClose,
}) => {
  const traitName = isEditable ? (
    <EditTraitName
      id={id}
      isTemplate={isTemplate}
      name={name}
      parentModel={parentModel}
    />
  ) : (
    <Heading>{name}</Heading>
  );

  const traitDescription = isEditable ? (
    <EditTraitDescription id={id} description={description} />
  ) : (
    description && <Paragraph>{description}</Paragraph>
  );

  return (
    <Row width="100%" justifyContent="space-between">
      <Column>
        {traitName}
        {traitDescription}
        <Metadata
          parentModel={parentModel}
          isTemplate={isTemplate}
          isArchived={isArchived}
        />
      </Column>
      {onClose && (
        <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
      )}
    </Row>
  );
};
