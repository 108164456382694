import { useState } from "react";

import { AudienceIcon, Combobox, Row, Box, Text } from "@hightouchio/ui";
import pluralize from "pluralize";

import chartPlaceholder from "src/pages/home/assets/placeholder-chart.svg";
import { Card } from "src/components/card";
import { Circle } from "src/ui/circle";
import { useAudiencesWithSizeQuery } from "src/graphql";
import { AudienceSizeChart } from "./audience-size-chart";
import { DEFAULT_CHART_HEIGHT } from "src/components/charts/chart";
import { subDays } from "date-fns";
import { useUser } from "src/contexts/user-context";
import { usePersistedState } from "src/hooks/use-persisted-state";
import { TimeOptions } from "src/pages/analytics/types";
import { HTImage } from "src/components/image";

export const FallbackMetrics = () => {
  const { user, workspace } = useUser();
  const [since] = useState(subDays(new Date(), 90).toISOString());
  const savedAudienceId = usePersistedState<string | undefined>(
    `${workspace?.id}.home.audienceId`,
    undefined,
  );

  const { data: audiences, isLoading } = useAudiencesWithSizeQuery(
    {
      since,
    },
    {
      select: (data) => {
        // order so segments most recently created_by this user are first
        return data.segments.sort((a, b) => {
          if (a.created_by === user?.id) return -1;
          if (b.created_by === user?.id) return 1;
          return 0;
        });
      },
    },
  );

  if (!savedAudienceId.value) {
    return (
      <Card
        height={DEFAULT_CHART_HEIGHT}
        boxShadow="md"
        pos="relative"
        alignItems="center"
      >
        <Box fontWeight="medium" fontSize="xl" mb={1} pt={6}>
          Choose an audience
        </Box>
        <Text mb={4}>
          Pick an audience to view the changes in its size over time.
        </Text>
        <Combobox
          placeholder="Select an audience..."
          value={savedAudienceId.value}
          isLoading={isLoading}
          onChange={(value) => savedAudienceId.set(value)}
          options={audiences ?? []}
          optionValue={(o) => o.id}
          optionLabel={(o) => o.name}
          optionAccessory={() => ({
            type: "icon",
            icon: AudienceIcon,
          })}
          optionDescription={(option) => {
            return `${option.last_run_size} ${pluralize("member", option.last_run_size)}`;
          }}
        />
        <HTImage
          pointerEvents="none"
          pos="absolute"
          left={6}
          right={6}
          bottom={6}
          src={chartPlaceholder}
          alt="Chart illustration."
        />
      </Card>
    );
  }

  const audience = audiences?.find((a) => a.id === savedAudienceId.value);

  return (
    <Card boxShadow="md" height={DEFAULT_CHART_HEIGHT}>
      <Row justify="space-between" align="center" gap={4}>
        <Row gap={2} align="center">
          <Circle radius={2} bg="electric.base" />
          <Text fontWeight="medium">{audience?.name} size</Text>
        </Row>
        <Row gap={4} align="center">
          <Combobox
            width="md"
            isLoading={isLoading}
            value={savedAudienceId.value}
            onChange={(value) => savedAudienceId.set(value)}
            options={audiences ?? []}
            optionAccessory={() => ({
              type: "icon",
              icon: AudienceIcon,
            })}
            optionValue={(o) => o.id}
            optionLabel={(o) => o.name}
          />
        </Row>
      </Row>
      <AudienceSizeChart
        audienceId={savedAudienceId.value}
        lookbackWindow={TimeOptions.NinetyDays}
      />
    </Card>
  );
};
