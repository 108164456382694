import { FC, useEffect, useState } from "react";

import {
  Column,
  FormField,
  Heading,
  SectionHeading,
  Select,
  Textarea,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { capitalize } from "lodash";
import Helmet from "react-helmet";
import { useNavigate, useParams } from "src/router";

import { RefreshIntervals } from "src/components/audiences/utils";
import {
  getSchemaModelTypeFromPath,
  getSchemaModelTypeNames,
} from "src/components/audiences/utils";
import { Explore } from "src/components/explore";
import { SourceSelect } from "src/components/sources/source-select";
import { useUser } from "src/contexts/user-context";
import { useCreateEventMutation, useCreateObjectMutation } from "src/graphql";
import { QueryableSource, QueryType } from "src/types/models";
import { SchemaModelType } from "src/types/schema";
import { ColumnType } from "src/types/visual";
import { DeprecatedWizard, WizardStep } from "src/components/wizard";
import {
  getModelInputFromState,
  isTimestampColumn,
  useModelRun,
} from "src/utils/models";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { useModelState } from "src/hooks/use-model-state";
import { QueryTypeSelect } from "src/components/explore/query-type-select";

export const CreateAudienceObject: FC = () => {
  const { object: type } = useParams<{
    object:
      | "related-models"
      | "parent-models"
      | "events"
      | "interactions"
      | "assets";
  }>();
  const navigate = useNavigate();

  if (type) {
    const schemaType = getSchemaModelTypeFromPath(type);
    return (
      <CreateSchemaModelWizard
        type={getSchemaModelTypeFromPath(type)}
        onCreated={() => {
          let params = "";
          if (schemaType === SchemaModelType.Event) {
            params = "?eventCreated=true";
          }
          navigate(`/schema/${type}${params}`);
        }}
      />
    );
  }
  return null;
};

export const CreateSchemaModelWizard: FC<
  Readonly<{
    source?: QueryableSource;
    type: SchemaModelType;
    onCreated?: (id: string) => void;
  }>
> = ({ source: initialSource, type, onCreated }) => {
  const { featureFlags } = useUser();
  const navigate = useNavigate();
  const { toast } = useToast();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const { typeName } = getSchemaModelTypeNames(type);
  const { getSlug } = useResourceSlug(SlugResourceType.Segments);
  const [source, setSource] = useState<QueryableSource | undefined>(
    initialSource,
  );
  const [step, setStep] = useState(0);
  const modelState = useModelState(source ? { connection: source } : undefined);
  const [primaryLabel, setPrimaryLabel] = useState("");
  const [secondaryLabel, setSecondaryLabel] = useState("");
  const [creating, setCreating] = useState(false);
  const [timestampColumn, setTimestampColumn] = useState("");

  const isParentModel = type === SchemaModelType.Parent;
  const isEvent = type === SchemaModelType.Event;

  const { mutateAsync: createObject } = useCreateObjectMutation();
  const { mutateAsync: createEvent } = useCreateEventMutation();

  const {
    runQuery,
    cancelQuery,
    getSchema,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
  } = useModelRun(modelState.state);

  const create = async () => {
    setCreating(true);

    const slug = await getSlug(name ?? "");

    // Check if we should try enabling suggestions for this schema model type.
    // If we're creating columns on a parent model, check the workspace flag. By the default,
    // the flag isn't set, so we'll enable top K in most cases.
    // For any other schema type, don't enable suggestions by default.
    const enableTopKForModelType = isParentModel
      ? !featureFlags.disable_top_k_by_default
      : false;

    const modelColumns = columns.map((column) => {
      return {
        ...column,
        // Suggestions are generally only useful for strings and sometimes booleans.
        // For all other column types we'll disable it by default.
        top_k_enabled: enableTopKForModelType
          ? [ColumnType.String, ColumnType.Boolean].includes(column?.type)
          : false,
        top_k_sync_interval: RefreshIntervals.Weekly,
      };
    });

    const data = await createObject({
      input: {
        ...getModelInputFromState(modelState.state),
        slug,
        is_schema: true,
        name,
        description,
        connection_id: source?.id,
        columns: { data: modelColumns },
        visual_query_primary_label: isParentModel ? primaryLabel : undefined,
        visual_query_secondary_label: isParentModel
          ? secondaryLabel
          : undefined,
      },
    });

    const id = data.insert_segments_one?.id;

    if (isEvent && id) {
      await createEvent({
        input: {
          model_id: id,
          timestamp_column: timestampColumn,
        },
      });
    }

    setCreating(false);

    toast({
      id: `create-${type}`,
      title: `${capitalize(name ?? "object")} was created`,
      variant: "success",
    });

    if (onCreated) {
      onCreated(id);
    }
  };

  const steps: WizardStep[] = [
    {
      title: "Define query",
      disabled:
        !modelState.isValid ||
        Boolean(queryError) ||
        (source?.definition?.supportsResultSchema
          ? false
          : !modelState.isPreviewFresh),
      header: modelState.state.query_type ? null : (
        <Heading>Select a modeling method</Heading>
      ),
      onContinue: async () => {
        if (
          source?.definition?.supportsResultSchema &&
          !modelState.isPreviewFresh
        ) {
          await getSchema();
        }
        setStep((step) => step + 1);
      },
      continue: modelState.state.query_type
        ? ""
        : "Click on a modeling method to continue",
      render: () => (
        <>
          {modelState.state.query_type ? (
            <Explore
              isSchema
              modelState={modelState}
              cancelQuery={cancelQuery}
              columns={columns}
              isResultTruncated={Boolean(isResultTruncated)}
              numRowsWithoutLimit={numRowsWithoutLimit}
              rows={rows}
              runQuery={runQuery}
              source={source}
              error={queryError}
              errorAtLine={queryErrorAtLine}
              loading={queryLoading}
              rowsPerPage={15}
            />
          ) : (
            <QueryTypeSelect
              selected={modelState.state.query_type as QueryType}
              source={source}
              onChange={(query_type) => {
                modelState.onChange({ query_type });
              }}
            />
          )}
        </>
      ),
    },
    {
      title: `Configure`,
      disabled:
        !name ||
        (isParentModel &&
          (!modelState.state.primary_key ||
            !primaryLabel ||
            !secondaryLabel)) ||
        (isEvent && !timestampColumn),
      submitting: creating,
      header: <Heading>Configure {typeName}</Heading>,
      render: () => {
        const filteredColumns = isEvent
          ? columns?.filter(isTimestampColumn)
          : columns;
        const columnOptions: { value: string; label: string }[] =
          filteredColumns.map(({ name }) => ({
            value: name,
            label: name,
          }));

        return (
          <Column gap={8}>
            <Column gap={6}>
              <FormField
                label="Name"
                tip="Something simple like “users”"
                isRequired
              >
                <TextInput
                  placeholder="Enter a name..."
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </FormField>
              <FormField label="Description" isOptional>
                <Textarea
                  placeholder="Enter a description..."
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormField>
              {isParentModel && (
                <>
                  <FormField
                    label="Primary key"
                    tip="Key that is unique and consistent across queries. E.g. “id” or “email”"
                    isRequired
                  >
                    <Select
                      options={columnOptions}
                      placeholder="Select an option..."
                      value={modelState.state.primary_key ?? ""}
                      onChange={(value) => {
                        modelState.onChange({ primary_key: value });
                      }}
                    />
                  </FormField>
                </>
              )}
              {isEvent && (
                <FormField label="Timestamp" isRequired>
                  <Select
                    options={columnOptions}
                    placeholder="Select an option..."
                    value={timestampColumn}
                    onChange={(value) => value && setTimestampColumn(value)}
                  />
                </FormField>
              )}
            </Column>

            {isParentModel && (
              <Column gap={6}>
                <SectionHeading>Content labels</SectionHeading>
                <FormField
                  label="Primary label"
                  tip="Used when previewing results from an audience. E.g. “full_name”"
                  isRequired
                >
                  <Select
                    options={columnOptions}
                    placeholder="Select an option..."
                    value={primaryLabel}
                    onChange={(value) =>
                      value !== undefined && setPrimaryLabel(value)
                    }
                  />
                </FormField>
                <FormField
                  label="Secondary label"
                  tip="Used when previewing results from an audience. E.g. “email”"
                  isRequired
                >
                  <Select
                    options={columnOptions}
                    placeholder="Select an option..."
                    value={secondaryLabel}
                    onChange={(value) =>
                      value !== undefined && setSecondaryLabel(value)
                    }
                  />
                </FormField>
              </Column>
            )}
          </Column>
        );
      },
    },
  ];

  if (!initialSource) {
    steps.unshift({
      title: "Select source",
      continue: "Click on a source to continue",
      header: <Heading>Select a data source</Heading>,
      render: () => (
        <SourceSelect
          queryType={QueryType.Visual}
          onSelect={(source) => {
            setSource(source);
            setStep((step) => step + 1);
          }}
        />
      ),
    });
  }

  useEffect(() => {
    if (source) {
      modelState.reset(undefined);
    }
  }, [source]);

  useEffect(() => {
    if (columns?.length && !queryError) {
      modelState.setIsPreviewFresh(true);
    }
  }, [rows, columns]);

  return (
    <>
      <Helmet>
        <title>New {typeName}</title>
      </Helmet>

      <DeprecatedWizard
        setStep={setStep}
        step={step}
        steps={steps}
        title={`New ${typeName}`}
        onCancel={() => {
          navigate(-1);
        }}
        onSubmit={create}
      />
    </>
  );
};
