import { capitalize } from "lodash";
import { FC } from "react";

import {
  DecisionEngineChannelType,
  DecisionEngineStatus,
} from "@hightouch/lib/customer-data/decision-engine/types";
import { Column, Row, Text } from "@hightouchio/ui";
import { Card } from "src/components/card";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { ChannelIcon } from "src/pages/decision-engines/components/channel-icon";
import { FlowStatus } from "src/pages/decision-engines/flows/components/flow-status";
import { OutcomeClassification } from "src/pages/decision-engines/flows/flow/outcomes/components/outcome-classification";
import { sortOutcomes } from "src/pages/decision-engines/utils";
import { Link } from "src/router";
import { Flow } from "..";
import { AudienceLink } from "./audience-link";

export const OverviewSidebar: FC<Readonly<{ flow: Flow }>> = ({ flow }) => {
  const messageChannelCounts = flow.messages.reduce((acc, { message }) => {
    const channelType = message.channel.type;
    acc[channelType] = (acc[channelType] || 0) + 1;
    return acc;
  }, {});

  return (
    <Card gap={4} p={6} w="288px" h="min-content">
      <Section>
        <SectionHeader text="Status" />
        <FlowStatus
          status={flow.status as DecisionEngineStatus}
          enabled={flow.enabled}
        />
      </Section>
      {flow.audience && (
        <Section>
          <SectionHeader text="Target Audience" />
          <Column gap={2}>
            <AudienceLink audience={flow.audience} />
            <Text color="text.secondary" size="sm">
              {flow.config.holdout * 100}% holdout
            </Text>
          </Column>
        </Section>
      )}
      <Section>
        <Row justify="space-between" align="center">
          <SectionHeader text="Actions" />
          <Link href={`/ai/flows/${flow.id}/configuration`} fontSize="12px">
            Edit actions
          </Link>
        </Row>
        <Column gap={2}>
          {Object.entries(messageChannelCounts).map(([channel, count]) => (
            <Row key={`${flow.id}-${channel}`} gap={2}>
              <ChannelIcon type={channel as DecisionEngineChannelType} />
              <Text size="md">{`${count} ${capitalize(channel)}`}</Text>
            </Row>
          ))}
        </Column>
      </Section>
      <Section>
        <Row justify="space-between" align="center">
          <SectionHeader text="Outcomes" />
          <Link href={`/ai/flows/${flow.id}/configuration`} fontSize="12px">
            Edit outcomes
          </Link>
        </Row>
        <Column gap={2}>
          {flow.outcomes
            .sort((a, b) => sortOutcomes(a.outcome.weight, b.outcome.weight))
            .map(({ outcome }) => (
              <Row key={outcome.id} gap={2}>
                <OutcomeClassification
                  weight={outcome.weight}
                  showLabel={false}
                />
                <TextWithTooltip size="md">{outcome.name}</TextWithTooltip>
              </Row>
            ))}
        </Column>
      </Section>
    </Card>
  );
};

export const Section = ({ children }: { children: React.ReactNode }) => {
  return (
    <Column
      gap={4}
      sx={{
        "&:not(:last-child)": {
          borderBottom: "1px",
          borderColor: "base.border",
          pb: 4,
        },
      }}
    >
      {children}
    </Column>
  );
};

export const SectionHeader = ({ text }: { text: string }) => {
  return (
    <Text
      fontWeight="semibold"
      color="text.secondary"
      size="sm"
      textTransform="uppercase"
    >
      {text}
    </Text>
  );
};
