import { FC, PropsWithChildren } from "react";

import { FormkitComponent } from "@hightouch/formkit";
import {
  Row,
  InformationIcon,
  Switch,
  Text,
  Tooltip,
  Column,
  ChakraUnorderedList,
  ChakraListItem,
} from "@hightouchio/ui";
import { useFormContext, Controller } from "react-hook-form";
import pluralize from "pluralize";
import { isPresent } from "ts-extras";

import { OverrideConfig } from "src/components/destinations/types";
import { useFormkitContext } from "src/formkit/components/formkit-context";

import { useSyncTemplateLockingRules } from "./use-sync-template-locking-rules";
import { OVERRIDE_CONFIG_FORM_KEY } from "./constants";

type SyncTemplateLockProps = {
  nodes: FormkitComponent[];
};

export const SyncTemplateLock: FC<PropsWithChildren<SyncTemplateLockProps>> = ({
  nodes,
}) => {
  const { showLockUI, relatedSyncsWithOverrides } = useFormkitContext();
  const { control, getValues } = useFormContext();

  // Start of Selection
  let numberOfSyncLevelOverrides = 0;
  for (const node of nodes) {
    numberOfSyncLevelOverrides += relatedSyncsWithOverrides?.[node.key] ?? 0;
  }

  const { isLocked, message: disabledMessage } = useSyncTemplateLockingRules(
    nodes,
    numberOfSyncLevelOverrides,
  );

  if (!showLockUI || nodes.length === 0) {
    return null;
  }

  return (
    <Row
      bg="base.background"
      px={4}
      py={2}
      height="min-content"
      borderRadius="md"
      sx={{
        label: {
          display: "flex",
          gap: 4,
          alignItems: "center",
          whiteSpace: "nowrap",
        },
      }}
    >
      <Column gap={2}>
        <Text as="label" fontWeight="medium">
          <Row gap={1} align="center">
            Editable
            <Tooltip
              message="Allow collaborators to edit this field when using this sync template"
              placement="top-end"
            >
              <Row as={Text} color="text.secondary">
                <InformationIcon />
              </Row>
            </Tooltip>
          </Row>
          <Controller
            name={OVERRIDE_CONFIG_FORM_KEY}
            control={control}
            render={({ field }) => (
              <Tooltip
                isDisabled={!disabledMessage}
                placement="top-end"
                message={
                  typeof disabledMessage === "string" ? (
                    disabledMessage
                  ) : isPresent(disabledMessage) ? (
                    <Column
                      gap={2}
                      sx={{ "> span": { textAlign: "left", pl: 2 } }}
                    >
                      <Text color="white">{disabledMessage.label}</Text>
                      {disabledMessage &&
                        typeof disabledMessage === "object" && (
                          <ChakraUnorderedList>
                            {disabledMessage?.children.map((child) => (
                              <ChakraListItem
                                key={child}
                                sx={{ textAlign: "left" }}
                              >
                                <Text color="white">&quot;{child}&quot;</Text>
                              </ChakraListItem>
                            ))}
                          </ChakraUnorderedList>
                        )}
                    </Column>
                  ) : (
                    ""
                  )
                }
              >
                <Switch
                  aria-label="Enable overrides."
                  size="sm"
                  isDisabled={Boolean(disabledMessage)}
                  isChecked={!isLocked}
                  onChange={(value) =>
                    field.onChange({
                      ...getValues(OVERRIDE_CONFIG_FORM_KEY),
                      ...nodes.reduce((all, node) => {
                        all[node.key] = {
                          overridable: value,
                        };

                        return all;
                      }, {} as OverrideConfig),
                    })
                  }
                />
              </Tooltip>
            )}
          />
        </Text>
        {numberOfSyncLevelOverrides > 0 && (
          <Text color="warning.base" size="sm">
            {numberOfSyncLevelOverrides} sync-level{" "}
            {pluralize("override", numberOfSyncLevelOverrides)}
          </Text>
        )}
      </Column>
    </Row>
  );
};
