import { useEffect } from "react";

import { uniqBy } from "lodash";
// eslint-disable-next-line
import { matchRoutes } from "react-router-dom";

import { useLocation } from "src/router";
import { useUser } from "src/contexts/user-context";
import { usePersistedState } from "src/hooks/use-persisted-state";
import { routes } from "src/components/router";

export const marketingPaths = ["audiences/:id", "journeys/:id"];
export const activationPaths = ["syncs/:sync_id/*"];

const MAX_RECENTLY_VIEWED = 25;

export const getPersistedKey = (
  workspaceId: string,
  view: "marketing" | "activation",
) => `${workspaceId}:${view}:recently-viewed`;

export type Meta = {
  resource: "audiences" | "journeys" | "syncs" | "models";
  id: string;
};

export const useRecentlyViewed = () => {
  const { workspace } = useUser();
  const marketing = usePersistedState(
    getPersistedKey(workspace?.id, "marketing"),
    [] as Meta[],
  );
  const activation = usePersistedState(
    getPersistedKey(workspace?.id, "activation"),
    [] as Meta[],
  );

  const location = useLocation();

  useEffect(() => {
    const matches = matchRoutes(routes, location.pathname);
    const activationMatch = matches?.find(
      (match) =>
        match.route.path && activationPaths.includes(match?.route.path),
    );
    if (
      activationMatch &&
      activationMatch.route.path &&
      activationMatch.params
    ) {
      const meta = parser(activationMatch.route.path, activationMatch.params);
      if (meta) {
        activation.set(
          uniqBy([meta, ...(activation.value ?? [])], "id").slice(
            0,
            MAX_RECENTLY_VIEWED,
          ),
        );
      }
    }
    const marketingMatch = matches?.find(
      (match) => match.route.path && marketingPaths.includes(match?.route.path),
    );
    if (marketingMatch && marketingMatch.route.path && marketingMatch.params) {
      const meta = parser(marketingMatch.route.path, marketingMatch.params);
      if (meta) {
        marketing.set(
          uniqBy([meta, ...(marketing.value ?? [])], "id").slice(
            0,
            MAX_RECENTLY_VIEWED,
          ),
        );
      }
    }
  }, [location]);
};

// Parse relevant id from path
const parser = (path: string, params: any): Meta | null => {
  switch (path) {
    case "audiences/:id":
      return {
        resource: "audiences",
        id: params.id,
      };
    case "journeys/:id":
      return {
        resource: "journeys",
        id: params.id,
      };
    case "syncs/:sync_id/*":
      return {
        resource: "syncs",
        id: params.sync_id,
      };
    case "models/:model_id/*":
      return {
        resource: "models",
        id: params.model_id,
      };
    default:
      return null;
  }
};
