import { Column } from "@hightouchio/ui";
import { Helmet } from "react-helmet";

import { Header } from "src/pages/home/components/header";
import { ResourceCards } from "src/pages/home/components/resource-cards";
import { MAX_WIDTH } from "src/pages/home";
import { RecentlyViewed } from "src/pages/home/components/recently-viewed";
import { Metrics } from "./metrics";
import { AudienceSummary } from "./audience-summary";
import { useEntitlements } from "src/hooks/use-entitlement";

export const MarketingHome = ({
  onToggleView,
}: {
  onToggleView: () => void;
}) => {
  const { data: entitlementsData } = useEntitlements(false);
  const appEnableJourneys = entitlementsData.entitlements.journeys;

  return (
    <Column flex={1} minW="500px">
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Header onToggleView={onToggleView} view="marketing">
        <ResourceCards
          view="marketing"
          resources={
            appEnableJourneys
              ? ["audience", "journey", "destination"]
              : ["audience", "destination", "sync"]
          }
        />
      </Header>
      <Column flex={1} align="center">
        <Column gap={10} maxW={MAX_WIDTH} w="100%" p={6} pt={10}>
          <RecentlyViewed view="marketing" />
          <Column gap={4}>
            <AudienceSummary />
            <Metrics />
          </Column>
        </Column>
      </Column>
    </Column>
  );
};
