import {
  ButtonGroup,
  Column,
  EmptyState,
  ExternalLinkIcon,
  PlusIcon,
} from "@hightouchio/ui";
import { FC } from "react";

import genericPlaceholder from "src/assets/placeholders/generic.svg";

import { LinkButton } from "src/router";
import { PlaceholderContent } from "src/ui/table/placeholder";

type Props = {
  docsUrl: string;
  newTemplateUrl: string;
  title: string;
  message: string;
};

export const EmptyTemplatesState: FC<Readonly<Props>> = ({
  docsUrl,
  newTemplateUrl,
  title,
  message,
}) => (
  <Column padding={8}>
    <EmptyState
      imageUrl={genericPlaceholder}
      title={title}
      message={message}
      actions={
        <ButtonGroup>
          <LinkButton href={newTemplateUrl} icon={PlusIcon} variant="primary">
            Add template
          </LinkButton>
          <LinkButton
            directionIcon={ExternalLinkIcon}
            href={`${import.meta.env.VITE_DOCS_URL}/${docsUrl}`}
            isExternal
          >
            View template docs
          </LinkButton>
        </ButtonGroup>
      }
    />
  </Column>
);

export const NO_SEARCH_RESULTS_PLACEHOLDER: PlaceholderContent = {
  title: "No templates found",
  body: "Please check your filters",
};
