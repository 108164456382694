import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import { DEFAULT_DECISION_ENGINE_ACTION_FEATURES_COLUMNS } from "@hightouch/lib/query/visual/types";
import { Column, Row, SectionHeading, Spinner, Text } from "@hightouchio/ui";
import { GroupByColumn } from "src/pages/analytics/types";
import { Navigate, useOutletContext } from "src/router";
import { FlowContext } from ".";
import {
  isFlowPaused,
  MESSAGE_GROUP_BY_COLUMN,
} from "src/pages/decision-engines/flows/utils";
import { OverviewSidebar } from "./components/overview-sidebar";
import { SendsByBreakdownChart } from "./components/sends-by-breakdown-chart";
import { SendsOverTimeChart } from "./components/sends-over-time-chart";
import { SendsSummary } from "./components/sends-summary";
import { Initializing } from "./components/set-up";
import { OutcomeOverviewTable } from "./components/outcome-overview-table";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFlowTimestamps } from "./use-flow-timestamps";
import { Card } from "src/components/card";
import { AnalyticsPlaceholderImage } from "src/pages/analytics/placeholders";

const SendsBreakdownByOptions: { label: string; value: GroupByColumn }[] =
  DEFAULT_DECISION_ENGINE_ACTION_FEATURES_COLUMNS.map((c) => ({
    label: c.alias ?? c.name,
    value: c,
  }));

export const FlowOverview = () => {
  const { flow } = useOutletContext<FlowContext>();
  const { appAidExperimentOverviewEnabled } = useFlags();

  if (flow.status === DecisionEngineStatus.PENDING || isFlowPaused(flow)) {
    return <Navigate to="../configuration" replace />;
  }

  const { flowStartDate, lastFlowRunStartedAt, isLoading } = useFlowTimestamps({
    flow,
  });

  if (isLoading) {
    return <Spinner size="lg" m="auto" />;
  }

  if (flow.status === DecisionEngineStatus.TRAINING) {
    return (
      <Row gap={6} justify="space-between">
        <Initializing />
        <OverviewSidebar flow={flow} />
      </Row>
    );
  }

  if (!flowStartDate || !lastFlowRunStartedAt) {
    return (
      <Row gap={6} justify="space-between">
        <Card flex={1} alignItems="center" justifyContent="center" gap={2}>
          <AnalyticsPlaceholderImage />
          <SectionHeading>
            This agent has not completed a run yet
          </SectionHeading>
          <Text>
            Check back later to see detailed performance data and insights
          </Text>
        </Card>
        <OverviewSidebar flow={flow} />
      </Row>
    );
  }

  return (
    <Row gap={6} justify="space-between">
      <Column gap={6} flex={1}>
        {appAidExperimentOverviewEnabled && (
          <OutcomeOverviewTable
            flow={flow}
            flowStartDate={flowStartDate}
            lastFlowRunStartedAt={lastFlowRunStartedAt}
          />
        )}
        <SendsSummary
          flowId={flow.id}
          parentModelId={flow.audience?.parent?.id}
          flowStartDate={flowStartDate}
          lastFlowRunStartedAt={lastFlowRunStartedAt}
        />
        <SendsOverTimeChart
          flowId={flow.id}
          parentModelId={flow.audience?.parent?.id}
          flowMessages={flow.messages}
          flowStartDate={flowStartDate}
          lastFlowRunStartedAt={lastFlowRunStartedAt}
        />
        <SendsByBreakdownChart
          flowId={flow.id}
          parentModelId={flow.audience?.parent?.id}
          flowMessages={flow.messages}
          lastFlowRunStartedAt={lastFlowRunStartedAt}
          flowStartDate={flowStartDate}
          breakdownOptions={SendsBreakdownByOptions}
          defaultBreakdownColumn={MESSAGE_GROUP_BY_COLUMN}
        />
      </Column>
      <OverviewSidebar flow={flow} />
    </Row>
  );
};
