import {
  Box,
  Button,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  Column,
  Combobox,
  type ComboboxProps,
  Heading,
  Row,
  Text,
} from "@hightouchio/ui";
import {
  ExtendedOption,
  FormkitNode,
  LayoutType,
  NodeType,
  processYupError,
} from "@hightouch/formkit";
import { useEffect, useMemo, useState } from "react";
import { ProcessFormNode } from "../../formkit";
import { Form, SubmitButton, useHightouchForm } from "../../../components/form";
import { FormkitProvider } from "../formkit-context";
import * as Yup from "yup";
import { NEW_ICON_MAP } from "src/utils/destinations";

type TypeSelectProps = Omit<
  ComboboxProps<ExtendedOption, string>,
  "onChange" | "value"
> & {
  onTypeCreate: (
    type: string | undefined,
    options?: Record<string, string | number | boolean>,
  ) => void;
  value?: any;
  created: boolean;
};

export const TypeSelect = (props: TypeSelectProps) => {
  const [creating, setCreating] = useState<
    ExtendedOption & Required<Pick<ExtendedOption, "typeSpecs">>
  >();

  const methods = useHightouchForm<Record<string, string | number | boolean>>({
    onSubmit: async (fieldOptions) => {
      if (!creating?.value) return;
      const fields = getFormkitFields(creating.typeSpecs);
      const validation = Yup.object().shape(
        Object.fromEntries(
          fields.map((field) => [field.key, field.validation]),
        ),
      );
      try {
        await validation.validate(fieldOptions);
      } catch (e) {
        const errors = processYupError(e);
        if (typeof errors === "object" && Object.keys(errors).length) {
          Object.entries(errors).forEach(([key, message]) => {
            methods.setError(key, { message: String(message) });
          });

          throw new Error("Couldn't save the sync configuration");
        }
      }
      props.onTypeCreate(creating.value as string, fieldOptions);
      setCreating(undefined);
    },
    values: {},
  });
  useEffect(() => {
    methods.reset();
  }, [creating?.typeSpecs]);
  const formkit = useMemo(() => {
    if (!creating?.typeSpecs) {
      return null;
    }
    const typeSpecs = { ...creating.typeSpecs };
    typeSpecs.layout = LayoutType.Form;

    return <ProcessFormNode node={typeSpecs} />;
  }, [creating?.typeSpecs]);

  return (
    <>
      <ChakraModal
        isCentered
        isOpen={Boolean(creating)}
        size="xl"
        onClose={() => setCreating(undefined)}
      >
        <ChakraModalOverlay />
        <ChakraModalContent p={0} maxHeight="85%" overflowY="visible" my="auto">
          {creating && (
            <FormkitProvider validate={() => {}}>
              <Form form={methods}>
                <ChakraModalHeader p={4} mb={0}>
                  <Row gap={3} align="center">
                    <Heading>
                      {creating.typeSpecs["heading"] ??
                        `Enter ${creating.label} values`}
                    </Heading>
                  </Row>
                </ChakraModalHeader>
                <ChakraModalBody
                  overflowY="auto"
                  p={4}
                  mt={0}
                  borderTop="1px solid"
                  borderColor="base.border"
                  gap={3}
                >
                  <Column gap={8} flex={1}>
                    {formkit}
                  </Column>
                </ChakraModalBody>
                <ChakraModalFooter
                  p={4}
                  mt={0}
                  borderTop="1px solid"
                  borderColor="base.border"
                  boxShadow="sm"
                >
                  <Button size="md" onClick={() => setCreating(undefined)}>
                    Cancel
                  </Button>
                  <SubmitButton size="md" variant="primary">
                    Apply
                  </SubmitButton>
                </ChakraModalFooter>
              </Form>
            </FormkitProvider>
          )}
        </ChakraModalContent>
      </ChakraModal>
      {!props.created && (
        <Combobox
          {...props}
          value={props.value?.fieldType}
          valueLabel={(option) => option.label}
          optionLabel={(option) =>
            option.typeSpecs && option.value !== props.value?.fieldType
              ? `${option.label}...`
              : option.label
          }
          onChange={(value) => {
            const option = props.options.find((opt) => opt.value === value);
            if (option?.typeSpecs) {
              methods.reset();
              setCreating(option as any);
            } else {
              props.onTypeCreate(value);
            }
          }}
          optionAccessory={(option) => {
            const type = option?.extendedType?.type;

            return type
              ? {
                  type: "icon",
                  icon: NEW_ICON_MAP[type],
                }
              : undefined;
          }}
        />
      )}
      {props.value.typeOptions &&
        props.options
          ?.filter((type) => props.value?.fieldType === type.value)
          ?.filter((type) => type.typeSpecs)
          ?.map((type, i) => (
            <Box key={i} position="absolute" bottom="-1.75rem">
              <Text color="text.secondary">
                {getFormkitFields(type.typeSpecs!)
                  .map(
                    (option) =>
                      `${option.label} ${props.value.typeOptions[option.key]}`,
                  )
                  .join(", ")}
                {!props.created && (
                  <>
                    {" ("}
                    <Text
                      color="link.default"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        methods.reset(props.value.typeOptions);
                        setCreating(type as any);
                      }}
                    >
                      Edit
                    </Text>
                    )
                  </>
                )}
              </Text>
            </Box>
          ))}
    </>
  );
};

type Field = {
  label: string;
  key: string;
  validation: any;
};

function getFormkitFields(parent: FormkitNode): Field[] {
  if (parent.type === NodeType.Layout) {
    return parent.children.flatMap(getFormkitFields);
  } else if (parent.type === NodeType.Component) {
    return [
      {
        label: parent["heading"] ?? parent.key,
        key: parent.key,
        validation: processValidation(parent.props?.validation),
      },
    ];
  } else {
    return [];
  }
}

type Validation =
  | {
      type: "string" | "boolean";
      required?: boolean;
    }
  | {
      type: "number";
      required?: boolean;
      integer?: boolean;
      min?: number;
      max?: number;
    };
function processValidation(validation: Validation) {
  if (!validation) return;
  let base = getBase(validation);
  if (!base) return;
  if (validation.required) base = base.nullable().required();
  return base;
}

function getBase(validation: Validation) {
  switch (validation.type) {
    case "string":
      return Yup.string();
    case "number": {
      let base = Yup.number();
      if (validation.integer) base = base.integer();
      if (validation.min !== undefined) base = base.min(validation.min);
      if (validation.max !== undefined) base = base.max(validation.max);
      return base;
    }
    case "boolean":
      return Yup.boolean();
    default:
      return;
  }
}
