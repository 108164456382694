import { FC, useMemo } from "react";

import {
  Box,
  FormField,
  GroupedCombobox,
  IconButton,
  RefreshIcon,
  Row,
  Switch,
  Text,
} from "@hightouchio/ui";

import { useDestinationForm } from "src/contexts/destination-form-context";

type ColumnOrConstantFieldProps = {
  property: string;
  error?: string;
  columnLabel: string;
  columnDescription?: string;
  constantLabel: string;
  constantDescription?: string;
  constantInput: any;
  disabled?: boolean;
};

export const ColumnOrConstantField: FC<
  Readonly<ColumnOrConstantFieldProps>
> = ({
  property,
  error,
  columnLabel,
  columnDescription,
  constantLabel,
  constantDescription,
  constantInput,
  disabled = false,
}) => {
  const {
    config,
    setConfig,
    errors,
    hightouchColumns,
    loadingModel,
    reloadModel,
  } = useDestinationForm();

  const value = config[property];
  const setValue = (value) => {
    setConfig({ ...config, [property]: value });
  };

  const valueIsColumn = value && typeof value !== "string";

  const groups = useMemo(() => {
    return hightouchColumns.map((group) => ({
      ...group,
      options: group.options ?? [],
    }));
  }, [hightouchColumns]);

  return (
    <FormField
      description={valueIsColumn ? columnDescription : constantDescription}
      error={errors?.[property] || errors?.[`${property}.column`] || error}
      label={valueIsColumn ? columnLabel : constantLabel}
    >
      <Row align="center">
        {valueIsColumn ? (
          <Row gap={2}>
            <GroupedCombobox
              isInvalid={Boolean(errors?.[`${property}.column`])}
              isLoading={loadingModel}
              optionGroups={groups}
              placeholder="Select a column..."
              value={value?.column}
              onChange={(val) => {
                setValue(val ? { column: val } : undefined);
              }}
            />
            <IconButton
              aria-label="Refresh"
              isLoading={loadingModel}
              icon={RefreshIcon}
              onClick={reloadModel}
            />
          </Row>
        ) : (
          <Row>{constantInput}</Row>
        )}
        {!disabled && (
          <Box alignItems="center" display="flex" gap={2} ml={4}>
            <Text
              textTransform="uppercase"
              size="sm"
              color="text.secondary"
              fontWeight="semibold"
            >
              Use column
            </Text>

            <Switch
              aria-label="Use column."
              isChecked={Boolean(valueIsColumn)}
              onChange={(value) => {
                if (value) {
                  setValue({ column: undefined });
                } else {
                  setValue(undefined);
                }
              }}
            />
          </Box>
        )}
      </Row>
    </FormField>
  );
};
