import { FC } from "react";
import { Controller } from "react-hook-form";
import { useNavigate } from "src/router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Column } from "@hightouchio/ui";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { DetailPage } from "src/components/layout/detail-page";
import {
  PermissionedEditableDescription,
  PermissionedEditableHeading,
} from "src/components/permission";
import { useCreateFunctionMutation } from "src/graphql";
import { FN_TEMPLATE, codeValidator } from "src/events/functions/utils";
import { ActionBar } from "src/components/action-bar";
import { FieldError } from "src/components/field-error";

import { FunctionCodeEditor } from "./code-editor";
import { CreateFunctionFormState } from "src/events/functions/types";
import { CodePageWrapper } from "./code-page-wrapper";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Function name is required"),
  code: codeValidator,
});

export const CreateFunction: FC = () => {
  const navigate = useNavigate();

  const createFunctionMutation = useCreateFunctionMutation();

  const form = useHightouchForm<CreateFunctionFormState>({
    defaultValues: {
      name: "",
      code: FN_TEMPLATE,
    },
    resolver: yupResolver(validationSchema),
    error: "Failed to create function",
    success: "Function created",
    onSubmit: async (data) => {
      const result = await createFunctionMutation.mutateAsync({
        object: {
          name: data.name,
          description: data.description,
          code: data.code,
        },
      });

      if (result.createFunction.id) {
        navigate(`/events/functions/${result.createFunction.id}`);
      }
    },
  });

  return (
    <Form form={form}>
      <DetailPage
        title="Create function"
        crumbs={[{ label: "All functions", link: "/events/functions" }]}
        tabs={[]}
        contentFullWidth
        header={
          <Column minWidth={0} gap={2} pt={1}>
            <Controller
              control={form.control}
              name="name"
              render={({ field: { value, onChange }, fieldState }) => (
                <>
                  <PermissionedEditableHeading
                    permission={{
                      v1: { resource: "workspace", grant: "update" },
                      v2: {
                        resource: "workspace",
                        grant: "can_update",
                      },
                    }}
                    placeholder="Add a name..."
                    size="lg"
                    value={value}
                    onChange={onChange}
                  />
                  {fieldState?.error?.message && (
                    <FieldError error={fieldState.error.message} />
                  )}
                </>
              )}
            />

            <Controller
              control={form.control}
              name="description"
              render={({ field: { value, onChange } }) => (
                <PermissionedEditableDescription
                  placeholder="Add a description..."
                  value={value ?? ""}
                  onChange={onChange}
                />
              )}
            />
          </Column>
        }
      >
        <CodePageWrapper>
          <FunctionCodeEditor />
        </CodePageWrapper>

        <ActionBar>
          <FormActions
            permission={{
              v1: { resource: "workspace", grant: "update" },
              v2: {
                resource: "workspace",
                grant: "can_update",
              },
            }}
          />
        </ActionBar>
      </DetailPage>
    </Form>
  );
};
