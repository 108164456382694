import { useCallback, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { useFormkitSyncValidationQuery } from "src/graphql";
import * as Sentry from "@sentry/react";

type ValidatedSyncOptions = {
  disableSentry: boolean; // Disable sending to Sentry. Default is on.
};

export function useValidatedSyncs<T extends Record<string, any>>(
  syncs: T[],
  opts?: ValidatedSyncOptions,
): {
  syncs: (T & {
    isSyncMisconfigured: boolean;
  })[];
  loading: boolean;
  error: string | undefined;
  cancelQuery: () => void;
} {
  const client = useQueryClient();
  const [validatedSyncs, setValidatedSyncs] = useState<
    Array<T & { isSyncMisconfigured: boolean }>
  >([]);
  const prevSyncsRef = useRef<T[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const activeQueryKeysRef = useRef<Set<any>>(new Set());

  const { disableSentry = false } = opts || {};

  const cancelQuery = async () => {
    await cancelRunningQueries();
    setLoading(false);
    setError(undefined);
  };

  const cancelRunningQueries = useCallback(async () => {
    if (activeQueryKeysRef.current.size > 0) {
      await Promise.all(
        Array.from(activeQueryKeysRef.current).map((queryKey) =>
          client.cancelQueries(queryKey),
        ),
      );
      activeQueryKeysRef.current.clear();
    }
  }, [client]);

  const formKitValidate = async (config, destinationType: string) => {
    const queryKey = useFormkitSyncValidationQuery.getKey(config);
    activeQueryKeysRef.current.add(queryKey);

    const response = await client.fetchQuery({
      queryFn: useFormkitSyncValidationQuery.fetcher({
        type: destinationType,
        config,
      }),
      queryKey: queryKey,
    });

    if (!activeQueryKeysRef.current.has(queryKey)) {
      return;
    }

    return response.formkitSyncValidation;
  };

  const getValidatedSyncResults = useCallback(async () => {
    if (!syncs || syncs.length === 0) {
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const validated = await Promise.all(
        syncs.map(async (sync: T) => {
          const formKitValidationErrors = async () => {
            const config = "sync" in sync ? sync.sync?.config : sync.config;
            const destinationType =
              "sync" in sync
                ? sync.sync?.destination?.type
                : sync.destination?.type;

            const errors = await formKitValidate(config, destinationType ?? "");
            return typeof errors === "object" && Object.keys(errors).length
              ? errors
              : null;
          };

          const errors = await formKitValidationErrors();
          return { ...sync, isSyncMisconfigured: errors != null };
        }),
      );

      setValidatedSyncs(validated);
    } catch (e) {
      if (!disableSentry) {
        Sentry.captureException(e);
      }

      setError(e?.message || "Error validating syncs");
    } finally {
      setLoading(false);
    }
  }, [syncs, client]);

  useEffect(() => {
    if (
      !syncs ||
      JSON.stringify(syncs) === JSON.stringify(prevSyncsRef.current)
    ) {
      return;
    }
    prevSyncsRef.current = syncs;
    setLoading(true);
    setError("");
    getValidatedSyncResults();
  }, [syncs, getValidatedSyncResults]);

  useEffect(() => {
    return () => {
      cancelRunningQueries().then(() => {
        prevSyncsRef.current = null;
      });
    };
  }, [cancelRunningQueries]);

  return { syncs: validatedSyncs, cancelQuery, loading, error };
}
