import { Box, Column, Row, Text } from "@hightouchio/ui";
import { getSyncRunOperations } from "src/utils/syncs";
import { NumberBadge } from "./number-badge";
import { SyncRun } from "src/pages/syncs/sync/utils/types";

type Props = {
  showPendingRows: boolean;
  queryRun: SyncRun["query_run"];
  syncAttempts: SyncRun["sync_attempts"];
  syncRequest: Pick<
    SyncRun,
    | "status_computed"
    | "add_executed"
    | "change_executed"
    | "remove_executed"
    | "error"
    | "planner_type"
  >;
};

export const RunOperationsColumn = ({
  showPendingRows,
  queryRun,
  syncAttempts,
  syncRequest,
}: Props) => {
  const attempt = syncAttempts?.[0];

  const { successful, rejected } = getSyncRunOperations({
    attempt,
    syncRequest,
    queryRun,
  });

  const pending = queryRun?.pending_rows;

  const noRows =
    successful.total === 0 &&
    rejected.total === 0 &&
    (pending === 0 || pending === undefined);

  if (noRows) {
    return (
      <Column align="center" flex={1}>
        <Text>--</Text>
      </Column>
    );
  }

  return (
    <Box
      display="grid"
      gridTemplateColumns={
        showPendingRows ? "repeat(3, 1fr)" : "repeat(2, 1fr)"
      }
      height="100%"
      width="100%"
      alignContent="center"
      gap={2}
    >
      <Row justifyContent="end">
        <NumberBadge
          variant="success"
          format="abbreviate"
          tooltip={`${successful.total} rows successfully synced to the destination`}
          value={successful.total}
          size="sm"
        />
      </Row>
      <Row>
        <NumberBadge
          variant="error"
          format="abbreviate"
          tooltip={`${rejected.total} rows failed to sync to the destination`}
          value={rejected.total}
          size="sm"
        />
      </Row>

      {showPendingRows && pending !== undefined && (
        <NumberBadge
          format="abbreviate"
          tooltip="Pending operations appear in Match Boosted syncs if they're added to the model but not yet enriched. Rows are only synced after they have been enriched."
          value={pending}
          size="sm"
        />
      )}
    </Box>
  );
};
