import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  ColumnType,
  ConditionType,
  SYNTHETIC_COLUMN_TYPES,
} from "@hightouch/lib/query/visual/types";
import { subSeconds } from "date-fns";
import {
  DECISION_ENGINE_INTERACTIONS_METRIC_LABEL,
  flowSyntheticColumnValuesGetter,
  MinimalDecisionEngineFlowMessage,
} from "src/pages/analytics/decision-engine-utils";
import {
  GroupByColumn,
  MeasurementScope,
  TimeOptions,
} from "src/pages/analytics/types";
import { AggregationOption } from "src/pages/metrics/constants";
import {
  AnalyticsFrequency,
  AndOrCondition,
  PropertyCondition,
  SyntheticMetricType,
} from "src/types/visual";
import { Flow } from "./flow";

export const isFlowPaused = (flow: Flow) => {
  return flow.status === DecisionEngineStatus.READY && !flow.enabled;
};

export const ACTION_FEATURES_COLUMN_TYPE = SYNTHETIC_COLUMN_TYPES[2];

/**
 * The general args for a metric series query for the sends charts
 */
export const getMetricSeriesArgsForInteractions = ({
  flowId,
  parentModelId,
  groupByColumns,
  frequency,
  customDateRange,
  conditions = [],
  flowMessages = [],
  enabled = true,
}: {
  flowId: string;
  parentModelId: string | number | undefined | null;
  groupByColumns: GroupByColumn[];
  frequency: AnalyticsFrequency;
  customDateRange: Date[];
  flowMessages: Flow["messages"];
  conditions?: AndOrCondition<PropertyCondition>[];
  enabled?: boolean;
}) => {
  return {
    enabled,
    parentModelId,
    audiences: [],
    frequency,
    groupByColumns,
    metricSelection: [
      {
        id: flowId,
        eventModelId: null,
        resourceId: flowId,
        source: SyntheticMetricType.DecisionEngineInteractions,
        name: DECISION_ENGINE_INTERACTIONS_METRIC_LABEL,
        conditions,
        description: null,
        aggregationMethod: AggregationOption.Count,
        attributionWindow: undefined,
      },
    ],
    metrics: [],
    timeValue: TimeOptions.Custom,
    customDateRange,
    cumulative: false,
    measuringMode: undefined,
    measuringSelection: {
      scope: MeasurementScope.DecisionEngineFlow,
      id: flowId,
    },
    syntheticColumnValuesGetter: flowSyntheticColumnValuesGetter(
      // This flow messages should be a superset of MinimalDecisionEngineFlowMessage
      flowMessages as unknown as MinimalDecisionEngineFlowMessage[],
    ),
  };
};

const MAX_HOURS_FROM_NOW = 48;

export type StartDateRangeInput =
  | {
      type: "custom";
      startDate: string | null | undefined;
    }
  | {
      type: "lookback";
      seconds: number;
    };

/**
 * Returns a date range tuple [startDate, endDate] based on either:
 * - A lookback period (e.g. last 7 days from end date)
 * - A specific start date
 *
 * If the provided endDate is more than 48 hours from now, uses current date instead
 * to avoid querying stale data
 */
export const getDateRangeForMetricSeries = (
  endDateString: string | null | undefined,
  start: StartDateRangeInput,
): [Date, Date] => {
  const now = new Date();
  const endDate = endDateString ? new Date(endDateString) : now;
  const hoursFromNow = (now.getTime() - endDate.getTime()) / (1000 * 60 * 60);
  const end =
    hoursFromNow > MAX_HOURS_FROM_NOW
      ? now
      : getDateWithAddedTimezoneOffset(endDate);

  if (start.type === "lookback") {
    return [subSeconds(end, start.seconds), end];
  }

  const startDate = start.startDate
    ? getDateWithAddedTimezoneOffset(new Date(start.startDate))
    : now;

  return [startDate, end];
};

export const MESSAGE_GROUP_BY_COLUMN: GroupByColumn = {
  type: ACTION_FEATURES_COLUMN_TYPE,
  name: "message",
  alias: "Action",
};

export const filterByMessageIdCondition = (
  messageId: string,
): AndOrCondition<PropertyCondition> => ({
  type: ConditionType.And,
  conditions: [
    {
      type: ConditionType.Property,
      propertyType: ColumnType.String,
      property: {
        type: ACTION_FEATURES_COLUMN_TYPE,
        name: "message",
        alias: "Action",
      },
      operator: "=",
      value: [messageId],
    },
  ],
});

/**
 * Adds the timezone offset to the date so we grab the UTC date when parsing
 * the timeWindow. (Especially important so we try to grab the cached data that
 * we queried from the latest flow run)
 */
export const getDateWithAddedTimezoneOffset = (date: Date) => {
  const timezoneOffset = date.getTimezoneOffset();
  date.setTime(date.getTime() + timezoneOffset * 60 * 1000);

  return date;
};
