import { Alert } from "@hightouchio/ui";
import { FC } from "react";
import { useUser } from "src/contexts/user-context";

export const DaylightSavingsBanner: FC = () => {
  const { region } = useUser();
  // note: currently excluded regions: "aws-ap-south-1"
  // const US_REGIONS = [
  //   "aws-us-east-1",
  //   "gcp-us-east4",
  //   "us-east-1",
  //   "azure-eastus",
  // ];
  const EU_REGIONS = ["aws-eu-west-1", "gcp-europe-west1"];
  // const US_DST_CHANGE_DATE = "Sunday, March 9";
  const EU_DST_CHANGE_DATE = "Sunday, March 30";

  let DST_CHANGE_DATE;
  // if (US_REGIONS.includes(region)) {
  //   DST_CHANGE_DATE = US_DST_CHANGE_DATE;
  if (EU_REGIONS.includes(region)) {
    DST_CHANGE_DATE = EU_DST_CHANGE_DATE;
  } else {
    // don't show banner for non-US and non-EU workspaces
    return null;
  }

  return (
    <Alert
      variant="banner"
      type="subtle"
      justify="center"
      title="Hightouch does not observe daylight savings time."
      message={`Some sync schedules may shift relative to your local time starting on ${DST_CHANGE_DATE}.`}
    />
  );
};
