import { FC } from "react";

import { BoxProps, CheckIcon, Column, Row, Text } from "@hightouchio/ui";

import { IconBox } from "src/components/icon-box";

import {
  EventColumn,
  FilterOption,
  MetadataIcons,
  MetadataType,
  RelationColumn,
} from "./constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { DataTooltip } from "./data-tooltip";

type FilterPopoverOptionProps = {
  bg: string;
  columnType: FilterOption;
  description?: string | null;
  icon: JSX.Element;
  isFocused?: boolean;
  isSelected?: boolean;
  label: string;
  metadata?: { modelName: string; type: MetadataType } | null;
  onClick: () => void;
  onFocus: () => void;
  onMouseEnter: () => void;
};

export const FilterPopoverOption: FC<
  Readonly<FilterPopoverOptionProps & BoxProps>
> = ({
  bg,
  color,
  columnType,
  description,
  icon,
  isFocused = false,
  isSelected = false,
  label,
  metadata,
  sx = {},
  ...props
}) => {
  const { audienceFilterPopoverV2 } = useFlags();

  const content = (
    <Row align="center" overflowX="hidden" width="100%" gap={4} p={2}>
      <Row
        align="center"
        gap={2}
        overflowX="hidden"
        width="100%"
        color="text.primary"
      >
        <IconBox bg={bg} color={color} icon={icon} boxSize={6} iconSize={4} />
        <Column
          justify="start"
          overflow="hidden"
          sx={{ span: { textAlign: "left" } }}
        >
          <Row gap={2}>
            <Text isTruncated color="inherit">
              {label}
            </Text>

            {!audienceFilterPopoverV2 && metadata?.modelName && (
              <Row
                align="center"
                maxWidth="150px"
                sx={{
                  "* > svg": { height: "12px", width: "12px", mr: 1 },
                }}
              >
                <Text color="text.tertiary">
                  {MetadataIcons[metadata.type]}
                </Text>
                <Text isTruncated color="text.tertiary">
                  {metadata.modelName}
                </Text>
              </Row>
            )}
          </Row>
          {description && (
            <Text isTruncated color="text.tertiary" size="sm">
              {description}
            </Text>
          )}
        </Column>
      </Row>
      <Row color="primary.pressed" fontSize="24px" width="24px">
        {isSelected && <CheckIcon />}
      </Row>
    </Row>
  );

  return (
    <Row
      as="button"
      align="center"
      justify="space-between"
      role="option"
      _focus={{ boxShadow: "inset 0 0 0 4px #E0F3F5", borderRadius: "6px" }}
      _hover={{ bg: isSelected ? "forest.200" : "base.background" }}
      _active={{
        bg: isSelected ? "forest.300" : "gray.200",
        boxShadow: "none",
      }}
      _disabled={{
        cursor: "not-allowed",
        _hover: { bg: isSelected ? "forest.100" : "transparent" },
      }}
      bg={
        isSelected
          ? "primary.background"
          : isFocused
            ? "base.background"
            : "transparent"
      }
      border="1px solid"
      borderColor="transparent"
      borderRadius="5px"
      height={12}
      outline="none !important"
      transition="border-color 80ms ease-in"
      width="100%"
      sx={sx}
      {...props}
    >
      {audienceFilterPopoverV2 ? (
        content
      ) : (
        <DataTooltip
          description={description}
          gutter={12}
          label={label}
          placement="right"
          subtitle={
            metadata?.modelName ? (
              <Row
                as={Text}
                align="center"
                color="base.border"
                flexWrap="wrap"
                columnGap={1}
                size="sm"
                textAlign="left"
                sx={{ svg: { height: "12px", width: "12px" } }}
              >
                {[FilterOption.Trait, FilterOption.TraitTemplate].includes(
                  columnType,
                )
                  ? "Based on"
                  : "Merged from"}{" "}
                {metadata.type === MetadataType.Event
                  ? EventColumn.icon
                  : RelationColumn.icon}{" "}
                <Text color="base.border" wordBreak="break-all">
                  {metadata.modelName}
                </Text>
              </Row>
            ) : null
          }
          title={columnType}
        >
          {content}
        </DataTooltip>
      )}
    </Row>
  );
};
