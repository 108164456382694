import { FC, ReactNode, useMemo, useState } from "react";
import {
  Column,
  Row,
  Text,
  SearchInput,
  ToggleButton,
  ToggleButtonGroup,
  Skeleton,
  Badge,
  ExternalLinkIcon,
  Heading,
} from "@hightouchio/ui";
import { Link, Outlet, useOutletContext } from "src/router";
import {
  EventSchemaEventType,
  useAvailableMetricsQuery,
  useContractsForSourceQuery,
  useMonitorsForEventSourceQuery,
} from "src/graphql";
import { OutletContext } from ".";
import { Table, useTableConfig } from "src/ui/table";
import { EventDisplay } from "./utils";
import { secondsToMilliseconds, subHours, roundToNearestHours } from "date-fns";
import { enumOrFallback, isEnum, isPresent } from "src/types/utils";
import { groupBy, sortBy } from "lodash";
import { createEventPath } from "src/events/contracts/utils";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import {
  serializeEventSourceResourceId,
  sourceIdLike,
} from "@hightouch/lib/resource-monitoring/composite-ids";
import { EventVolumeDisplay } from "./components/event-volume-display";
import { useActiveAlertsForSource } from "src/events/sources/use-active-alerts-for-source";
import {
  ChartGranularity,
  ChartGranularityMetadata,
} from "src/components/charts/chart-utils";
import { useUser } from "src/contexts/user-context";
import { lodashOrderBy } from "src/ui/table/use-table-config";

export type EventCatalogEvent = {
  timestamp: Date;
  volume: string;
  eventType: EventSchemaEventType;
  eventName?: string;
  eventResourceId: string;
  hasMonitors: boolean;
  activeAlertCount?: number;
  updated_at: string | null;
  updated_by_user: { name: string } | null;
  created_at: string | null;
  created_by_user: { name: string } | null;
  contractMetadata:
    | {
        type: "link";
        label: ReactNode;
        link: string;
        lastUpdated?: { timestamp: Date; by: { name: string } };
      }
    | { type: "contract-loading" };
};

enum SortKeys {
  Name = "eventName",
  Alerts = "hasMonitors",
  UpdatedAt = "updated_at",
}

export const EventCatalog: FC = () => {
  const { source } = useOutletContext<OutletContext>();
  const [search, setSearch] = useState("");
  const [granularity, setGranularity] = useState(ChartGranularity.OneDay);
  const granularityMetadata = ChartGranularityMetadata[granularity];
  const { workspace } = useUser();
  const appEventMonitoringEnabled = workspace?.alerting_v2_enabled;

  const { sortKey, sortDirection, columnSortState } = useTableConfig<{
    [key in SortKeys]: "asc" | "desc";
  }>({
    defaultSortKey: SortKeys.UpdatedAt,
    sortOptions: Object.values(SortKeys),
  });

  const endTime = roundToNearestHours(new Date(), {
    roundingMethod: "ceil",
  });
  const startTime = subHours(endTime, granularityMetadata.days * 24);

  const { data: events, isLoading } = useAvailableMetricsQuery(
    {
      input: {
        id: source.id,
        resourceType: "SOURCE",
        endTime: endTime.valueOf(),
        startTime: startTime.valueOf(),
        metrics: ["RECEIVED"],
        aggType: "SUM",
      },
    },
    {
      refetchInterval: secondsToMilliseconds(60),
      select: (data) =>
        sortBy(
          data.getAvailableEventMetrics.results.map((point) => ({
            timestamp: new Date(point.maxTimestamp),
            volume: point.value,
            eventType: enumOrFallback(
              EventSchemaEventType,
              EventSchemaEventType.Track,
              true,
            )(point.dimensions.eventType),
            eventName: point.dimensions.eventName || undefined,
          })),
          (eventRow) => eventRow.volume,
        ).reverse() || [],
    },
  );

  const { data: planForSource, isLoading: contractIsLoading } =
    useContractsForSourceQuery(
      { event_source_id: source.id },
      { select: (data) => data.event_sources_by_pk?.event_plan || undefined },
    );
  const { data: monitorsForSource } = useMonitorsForEventSourceQuery(
    {
      sourceIdLike: sourceIdLike(source.id),
    },
    { select: (data) => data.resource_monitor_conditions },
  );

  const { isLoading: alertsLoading, alerts } = useActiveAlertsForSource({
    sourceId: source.id,
  });
  const alertsByType =
    !alertsLoading && groupBy(alerts, (alert) => alert.eventTypeResourceId);

  const filteredEvents: EventCatalogEvent[] = useMemo(() => {
    if (!events) return [];

    return (
      search === ""
        ? events
        : events.filter(
            (event) =>
              event.eventType.toLowerCase().includes(search.toLowerCase()) ||
              event.eventName?.toLowerCase().includes(search.toLowerCase()),
          )
    ).map((event): EventCatalogEvent => {
      const contract = planForSource?.event_schemas.find(
        (schema) =>
          schema.event_type === event.eventType &&
          schema.event_name === (event.eventName || null),
      );
      const eventResourceId = serializeEventSourceResourceId({
        eventSourceId: source.id,
        eventName: event.eventName,
        eventType: event.eventType,
      });

      const hasMonitors = !!monitorsForSource?.find(
        (monitor) => monitor.resource_id === eventResourceId && monitor.enabled,
      );

      const activeAlertCount =
        (alertsByType && alertsByType[eventResourceId]?.length) || 0;

      return {
        ...event,
        volume: event.volume.toLocaleString(),
        eventResourceId,
        hasMonitors,
        activeAlertCount,
        ...(contract || {
          created_at: null,
          updated_at: null,
          created_by_user: null,
          updated_by_user: null,
        }),
        contractMetadata: contractIsLoading
          ? { type: "contract-loading" }
          : {
              type: "link",
              label:
                contract && planForSource ? (
                  planForSource.name
                ) : (
                  <>
                    Configure <ExternalLinkIcon />
                  </>
                ),
              link: contract
                ? `/events/contracts/${planForSource?.id}/${createEventPath({
                    eventType: event.eventType,
                    eventName: event.eventName,
                    eventVersion: contract.event_version,
                  })}`
                : planForSource
                  ? `/events/contracts/${planForSource.id}/new_event`
                  : "/events/contracts?mode=create",
            },
      };
    });
  }, [search, events]);

  const sortedEvents = useMemo(() => {
    if (!sortKey || !sortDirection || !filteredEvents) return filteredEvents;

    return lodashOrderBy(filteredEvents, sortKey, sortDirection);
  }, [filteredEvents, sortKey, sortDirection]);

  const emptyState = search ? (
    <Text whiteSpace="pre-wrap" color="text.secondary">
      No events found for search <Text fontWeight="medium">"{search}"</Text>
    </Text>
  ) : (
    <Text color="text.secondary">
      Note that new event types can take a few minutes to be added to the
      catalog after their first occurrence. Check out our{" "}
      <Link href="https://hightouch.com/docs/events/overview">
        Events documentation
      </Link>{" "}
      to learn more.
    </Text>
  );

  return (
    <Column width="100%" gap={6}>
      <Row gap={4} justifyContent="space-between" align="center">
        <Heading>Events</Heading>
        <Row gap={2} align="center">
          <SearchInput
            placeholder="Search by type or name..."
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <ToggleButtonGroup
            value={granularity}
            onChange={(value) => {
              if (isEnum(ChartGranularity)(value)) setGranularity(value);
            }}
          >
            {Object.values(ChartGranularity).map((g) => (
              <ToggleButton
                key={g}
                label={ChartGranularityMetadata[g].label}
                value={g}
              />
            ))}
          </ToggleButtonGroup>
        </Row>
      </Row>
      <Table
        loading={isLoading}
        columns={[
          {
            name: "Name",
            ...columnSortState(SortKeys.Name),
            max: "auto",
            cell: ({
              eventType,
              eventName,
            }: {
              eventType: EventSchemaEventType;
              eventName?: string;
            }) => <EventDisplay eventType={eventType} title={eventName} />,
          } as const,
          {
            name: `Volume (${granularityMetadata.label})`,
            min: "auto",
            cell: ({
              eventName,
              eventType,
            }: {
              eventType: EventSchemaEventType;
              eventName?: string;
            }) => {
              return (
                <EventVolumeDisplay
                  sourceId={source.id}
                  endTime={endTime}
                  startTime={startTime}
                  eventName={eventName}
                  eventType={eventType}
                  granularity={granularity}
                />
              );
            },
          },
          {
            name: "Contract",
            min: "auto",
            breakpoint: "md" as const,
            cell: ({ contractMetadata }) => {
              const isContractLoading =
                contractMetadata.type === "contract-loading";
              return (
                <Skeleton isLoading={isContractLoading}>
                  <Text>
                    {isContractLoading ? (
                      "----"
                    ) : (
                      <Link href={contractMetadata.link}>
                        {contractMetadata.label}
                      </Link>
                    )}
                  </Text>
                </Skeleton>
              );
            },
          },
          ...(appEventMonitoringEnabled
            ? [
                {
                  name: "Alerts",
                  ...columnSortState(SortKeys.Alerts),
                  min: "auto",
                  cell: ({
                    eventResourceId,
                    hasMonitors,
                    activeAlertCount,
                  }: {
                    eventResourceId: string;
                    hasMonitors: boolean;
                    activeAlertCount?: number;
                  }) => (
                    <Link
                      href={`configure/${encodeURIComponent(eventResourceId)}`}
                    >
                      {activeAlertCount ? (
                        <>
                          <Badge variant="error" mr={1} size="sm">
                            {activeAlertCount}
                          </Badge>{" "}
                          Critical
                        </>
                      ) : hasMonitors ? (
                        "Monitoring..."
                      ) : (
                        "Configure"
                      )}
                    </Link>
                  ),
                },
              ]
            : []),
          {
            ...columnSortState(SortKeys.UpdatedAt),
            ...LastUpdatedColumn,
          },
        ].filter(isPresent)}
        data={sortedEvents}
        placeholder={{ body: emptyState, title: "No events found" }}
      />
      <Outlet />
    </Column>
  );
};
