import { FC, useEffect, useMemo, useState } from "react";

import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  EmptyState,
  IconButton,
  PlusIcon,
  RefreshIcon,
  Row,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import isEqual from "lodash/isEqual";
import { useFieldArray } from "react-hook-form";

import analyticsPlaceholder from "src/assets/placeholders/analytics.svg";
import { Form, useHightouchForm } from "src/components/form";
import { FilterableColumnFragment } from "src/graphql";
import { ColumnReference } from "src/types/visual";
import { ChartButton } from "./chart-button";
import {
  BreakdownChartCard,
  BreakdownData,
  OverlapChartCard,
  OverlapData,
} from "./chart-cards";
import { BarChartIcon, BreakdownIcon, OverlapIcon } from "./placeholders";
import { QueryArguments, SharedChartQueryParameters } from "./types";

export type AudienceInsightsProps = {
  isOpen: boolean;
  /**
   * Whether the dropdown options are loading
   */
  isLoading?: boolean;
  audienceOptions: {
    id: any;
    name: string;
    description: string | null;
  }[];
  breakdownColumnOptions: FilterableColumnFragment[];
} & QueryArguments &
  SharedChartQueryParameters;

type InsightChartState = {
  charts: (OverlapData | BreakdownData)[];
};

export const AudienceInsights: FC<AudienceInsightsProps> = ({
  isOpen,
  audienceOptions,
  breakdownColumnOptions,
  isLoading,
  ...queryArguments
}) => {
  const [isAddMode, setIsAddMode] = useState(false);

  const form = useHightouchForm<InsightChartState>({
    onSubmit: () => Promise.resolve(),
    values: {
      charts: [
        {
          type: "overlap",
          comparedAudienceId: null,
          queryParameters: null,
        },
        {
          type: "breakdown",
          columnReference: null,
          queryParameters: null,
        },
      ],
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Circular type error for visual query filter
  const { fields, prepend, remove, update, replace } = useFieldArray({
    control: form.control,
    name: "charts",
  });

  const isDataStale = useMemo(
    () =>
      fields.some(
        (field) =>
          field.queryParameters !== null &&
          !isEqual(
            {
              filter: queryArguments.filter,
              subsetIds: queryArguments.subsetIds,
              audienceId: queryArguments.audienceId ?? "",
              destinationId: queryArguments.destinationId ?? "",
              isFastQuery: Boolean(queryArguments.isFastQuery),
            },
            field.queryParameters,
          ),
      ),
    [
      fields,
      queryArguments.audienceId,
      queryArguments.destinationId,
      queryArguments.subsetIds,
      queryArguments.isFastQuery,
      queryArguments.filter,
    ],
  );

  const addChart = (type: "overlap" | "breakdown") => {
    if (type === "overlap") {
      prepend({
        type,
        comparedAudienceId: null,
        queryParameters: null,
      });
    } else if (type === "breakdown") {
      prepend({
        type,
        columnReference: null,
        queryParameters: null,
      });
    }
    setIsAddMode(false);
  };

  const removeChartAtIndex = (index: number) => remove(index);

  const compareAudience =
    (index: number) => (comparedAudienceId: string | null) => {
      update(index, {
        type: "overlap",
        comparedAudienceId,
        queryParameters: {
          filter: queryArguments.filter,
          subsetIds: queryArguments.subsetIds,
          audienceId: queryArguments.audienceId ?? "",
          destinationId: queryArguments.destinationId ?? "",
          isFastQuery: Boolean(queryArguments.isFastQuery),
        },
      });
    };

  const breakdownColumn =
    (index: number) => (columnReference: ColumnReference | null) => {
      update(index, {
        type: "breakdown",
        columnReference,
        queryParameters: {
          filter: queryArguments.filter,
          subsetIds: queryArguments.subsetIds,
          audienceId: queryArguments.audienceId ?? "",
          destinationId: queryArguments.destinationId ?? "",
          isFastQuery: Boolean(queryArguments.isFastQuery),
        },
      });
    };

  useEffect(() => {
    if (!isOpen) {
      setIsAddMode(false);
    }
  }, [isOpen]);

  return (
    <Form form={form}>
      <Column
        bg="white"
        borderLeft="1px solid"
        borderColor="base.border"
        height="100%"
      >
        <Row
          align="center"
          justify="space-between"
          px={6}
          py={3}
          gap={2}
          borderBottom="1px solid"
          borderColor="base.border"
        >
          <Row align="center" gap={2}>
            <BarChartIcon />
            <Text fontWeight="medium" size="lg">
              Insights
            </Text>
            {isDataStale && (
              <Badge size="sm" variant="warning">
                Outdated
              </Badge>
            )}
          </Row>

          <ButtonGroup>
            {isDataStale && (
              <Tooltip message="Refresh results">
                <IconButton
                  aria-label="Refresh results"
                  icon={RefreshIcon}
                  onClick={() => {
                    replace(
                      fields.map((field) => ({
                        ...field,
                        queryParameters: {
                          filter: queryArguments.filter,
                          subsetIds: queryArguments.subsetIds,
                          audienceId: queryArguments.audienceId ?? "",
                          destinationId: queryArguments.destinationId ?? "",
                          isFastQuery: Boolean(queryArguments.isFastQuery),
                        },
                      })),
                    );
                  }}
                />
              </Tooltip>
            )}

            <Tooltip
              message={isAddMode ? "Cancel" : "Add chart"}
              openSpeed="slow"
              placement="top-end"
            >
              <IconButton
                aria-label={isAddMode ? "Cancel" : "Add chart"}
                icon={isAddMode ? CloseIcon : PlusIcon}
                onClick={() => setIsAddMode((currentValue) => !currentValue)}
              />
            </Tooltip>
          </ButtonGroup>
        </Row>

        <Column position="relative" overflow="hidden" flex={1} minHeight={0}>
          <Column
            position="relative"
            p={6}
            gap={6}
            flex={1}
            minHeight={0}
            overflowY="auto"
            display={isAddMode ? "none" : "flex"}
          >
            {fields.length === 0 && (
              <Box
                sx={{
                  ">div": { border: "none", pt: 0, color: "text.primary" },
                }}
              >
                <EmptyState
                  title="No insights"
                  message="Add a chart to get started"
                  imageUrl={analyticsPlaceholder}
                  actions={
                    <Button
                      variant="primary"
                      onClick={() => setIsAddMode(true)}
                    >
                      Add chart
                    </Button>
                  }
                />
              </Box>
            )}
            {fields.map(({ id, ...field }, index) => {
              if (field.type === "overlap") {
                return (
                  <OverlapChartCard
                    key={id}
                    {...queryArguments}
                    optionsLoading={isLoading}
                    options={audienceOptions}
                    value={field}
                    onChange={compareAudience(index)}
                    onClose={() => removeChartAtIndex(index)}
                  />
                );
              } else if (field.type === "breakdown") {
                return (
                  <BreakdownChartCard
                    key={id}
                    {...queryArguments}
                    optionsLoading={isLoading}
                    options={breakdownColumnOptions}
                    value={field}
                    onChange={breakdownColumn(index)}
                    onClose={() => removeChartAtIndex(index)}
                  />
                );
              }

              return null;
            })}
          </Column>

          {isOpen && (
            <Column
              aria-hidden={!isAddMode}
              pointerEvents={isAddMode ? "auto" : "none"}
              position="absolute"
              p={6}
              gap={4}
              bg="white"
              opacity={isAddMode ? 1 : 0}
              transition={
                isAddMode ? "all 300ms ease-in-out" : "all 150ms ease-in-out"
              }
            >
              <Text fontWeight="medium" size="lg">
                Select a chart to add to this audience
              </Text>
              <Row gap={4} flex={1} minWidth={0}>
                <ChartButton
                  icon={OverlapIcon}
                  title="Overlap of audience"
                  description="Identify the amount of user overlap with another audience"
                  onClick={() => addChart("overlap")}
                />
                <ChartButton
                  icon={BreakdownIcon}
                  title="Breakdown of property"
                  description="View the count of values on a specific property"
                  onClick={() => addChart("breakdown")}
                />
              </Row>
            </Column>
          )}
        </Column>
      </Column>
    </Form>
  );
};
