import { FC, useEffect, useState } from "react";

import {
  useToast,
  Column,
  Button,
  Text,
  Dialog,
  Heading,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useNavigate, useParams } from "src/router";
import { isPresent } from "ts-extras";

import {
  getSchemaModelType,
  getSchemaModelTypeNames,
} from "src/components/audiences/utils";
import { ExploreWithSave } from "src/components/explore/explore-with-save";
import { DetailPage } from "src/components/layout";
import { Warning } from "src/components/warning";
import { useUser } from "src/contexts/user-context";
import { useObjectQuery } from "src/graphql";
import { SchemaModelType } from "src/types/schema";
import { PageSpinner } from "src/components/loading";
import {
  DeleteColumnsError,
  useModelRun,
  useUpdateQuery,
} from "src/utils/models";
import { useQueryString } from "src/utils/use-query-string";
import { useModelState } from "src/hooks/use-model-state";

export const AudienceObjectQuery: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const {
    data: { v2 },
  } = useQueryString();
  const { toast } = useToast();
  const { featureFlags } = useUser();

  const { data: object, isLoading: loadingObject } = useObjectQuery(
    {
      id: String(id),
    },
    { enabled: Boolean(id), select: (data) => data.segments_by_pk },
  );

  const modelState = useModelState(object);

  const update = useUpdateQuery();

  const source = object?.connection;

  const {
    runQuery,
    getSchema,
    cancelQuery,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    rawColumns,
  } = useModelRun(modelState.state);

  useEffect(() => {
    if (object) {
      modelState.reset(object);
    }
  }, [object]);

  const [saveError, setSaveError] = useState<{
    deletedColumns: string[];
  } | null>(null);

  if (loadingObject) {
    return <PageSpinner />;
  }

  if (!object) {
    return (
      <Warning
        subtitle="This resource may have been deleted"
        title="Not found"
      />
    );
  }

  const type = getSchemaModelType(object);
  const { typeNameCapitalized, typePath } = getSchemaModelTypeNames(type);

  const save = async (data) => {
    try {
      const topKSyncInterval = object?.columns.find(
        (column) => column.top_k_sync_interval,
      )?.top_k_sync_interval;

      await update({
        model: modelState.state,
        columns: data?.columns ?? rawColumns,
        topKEnabled:
          type === SchemaModelType.Parent
            ? !featureFlags.disable_top_k_by_default
            : false,
        topKSyncInterval: isPresent(topKSyncInterval)
          ? topKSyncInterval
          : undefined,
      });

      toast({
        id: `save-${type}`,
        title: `${typeNameCapitalized} was updated`,
        variant: "success",
      });
    } catch (error) {
      if (error instanceof DeleteColumnsError) {
        setSaveError({ deletedColumns: error.deletedColumns });
        throw error;
      }

      toast({
        id: `save-${type}`,
        title: `${typeNameCapitalized} couldn't be updated`,
        variant: "error",
      });

      Sentry.captureException(error);
      throw error;
    }
  };

  const returnLink = v2
    ? `/schema-v2/view/query?source=${source?.id}&id=${id}`
    : `/schema/${typePath}/${id}`;

  return (
    <DetailPage
      title={`${object?.name} - ${typeNameCapitalized} - Audiences`}
      header={<Heading size="xl">{object?.name}</Heading>}
      hasBottomPadding
      crumbs={
        v2
          ? [
              {
                label: object?.name ?? "",
                link: `/schema-v2/view/query?source=${source?.id}&id=${id}`,
              },
              { label: "Query" },
            ]
          : [
              {
                label: typeNameCapitalized,
                link: `/schema/${typePath}`,
              },
              {
                label: object?.name ?? "",
                link: `/schema/${typePath}/${id}`,
              },
            ]
      }
    >
      <ExploreWithSave
        isSchema
        modelState={modelState}
        cancelQuery={cancelQuery}
        columns={columns}
        error={queryError}
        errorAtLine={queryErrorAtLine}
        getSchema={getSchema}
        isResultTruncated={Boolean(isResultTruncated)}
        loading={queryLoading}
        numRowsWithoutLimit={numRowsWithoutLimit}
        rows={rows}
        runQuery={runQuery}
        source={source}
        onSave={async (data) => {
          await save(data);
          navigate(returnLink);
        }}
        rowsPerPage={15}
      />

      {saveError ? (
        <DeleteColumnModal
          columnNames={saveError.deletedColumns}
          modelName={object?.name ?? ""}
          onReset={() => setSaveError(null)}
        />
      ) : null}
    </DetailPage>
  );
};

const DeleteColumnModal = ({
  modelName,
  columnNames,
  onReset,
}: {
  modelName: string;
  columnNames: string[];
  onReset: () => void;
}) => {
  return (
    <Dialog
      isOpen
      variant="info"
      width="lg"
      title="Error saving model"
      actions={
        <Button variant="secondary" onClick={onReset}>
          OK
        </Button>
      }
      onClose={onReset}
    >
      <Text>
        Your change to the model "<strong>{modelName}</strong>" would remove the
        following columns:
      </Text>
      <Column gap={1} my={8}>
        {columnNames.map((name) => (
          <Text key={name} fontWeight="semibold">
            {name}
          </Text>
        ))}
      </Column>
      <Text>
        At least one of those columns is used as a key in a model relationship,
        and saving this change would break that relationship. Please remove
        those columns from your model relationships before changing the model.
      </Text>
    </Dialog>
  );
};
