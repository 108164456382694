import { capitalize } from "lodash";

import { Badge } from "@hightouchio/ui";
import { TAG_FEATURE_PREFIX } from "@hightouch/lib/query/visual/types";

export const BreakdownByBadge = ({
  breakdownByDimension,
}: {
  breakdownByDimension: string | null;
}) => {
  if (!breakdownByDimension) return null;

  const dimension = isTagFeature(breakdownByDimension)
    ? capitalize(breakdownByDimension.replace(TAG_FEATURE_PREFIX, "")) +
      " (AI Decisioning message tag)"
    : capitalize(breakdownByDimension.replace(/_/g, " "));

  return <Badge variant="subtle">{dimension}</Badge>;
};

const isTagFeature = (dimension: string) => {
  return dimension.startsWith(TAG_FEATURE_PREFIX);
};
