import {
  Button,
  CheckIcon,
  FormField,
  WarningIcon,
  useToast,
} from "@hightouchio/ui";
import { useFormContext } from "react-hook-form";

import { ChannelDefinition } from "src/components/notification-channels/channel-definitions";
import {
  useTestNewNotificationChannelQuery,
  useTestNotificationChannelQuery,
} from "src/graphql";
import { invalidateQueries } from "src/lib/react-query";

export const TestConnection = () => {
  const { toast } = useToast();
  const {
    watch,
    formState: { isDirty },
  } = useFormContext();

  const channelType = watch("channelType");
  const channelId = watch("channelId");
  const config = watch("config");

  const reportTestResult = (data: {
    success: boolean;
    error: { message: string };
  }) => {
    if (data?.success) {
      toast({
        title: "Success",
        variant: "success",
        id: "workspace-notifications",
      });
    } else {
      toast({
        title: "Error",
        message: data?.error?.message,
        variant: "error",
        id: "workspace-notifications",
      });
    }
  };

  const {
    refetch: testChannel,
    isLoading: testingExistingChannel,
    data: existingChannelTestResult,
  } = useTestNotificationChannelQuery(
    {
      channelId: channelId || "",
      newConfig: isDirty ? config : undefined,
    },
    {
      enabled: false,
      select(data) {
        return data.testWorkspaceNotificationChannel;
      },
      onSettled(data) {
        if (data) reportTestResult(data);
        invalidateQueries();
      },
    },
  );

  const {
    refetch: testNewChannel,
    isLoading: testingNewChannel,
    data: newChannelTestResult,
  } = useTestNewNotificationChannelQuery(
    {
      config,
      channelType: channelType || "",
    },
    {
      enabled: false,
      select(data) {
        return data.testNewWorkspaceNotificationChannel;
      },
      onSettled(data) {
        if (data) reportTestResult(data);
      },
    },
  );

  const test = async () => {
    if (channelId) await testChannel();
    else await testNewChannel();
  };

  const isTesting = testingExistingChannel || testingNewChannel;
  const testResult = newChannelTestResult || existingChannelTestResult;

  const definition = channelType && ChannelDefinition[channelType];

  if (!definition?.testable) {
    return null;
  }

  return (
    <FormField label="Test this recipient" description={definition?.testText}>
      <Button
        isLoading={isTesting}
        onClick={() => {
          toast({
            title: `Testing ${channelType} notifications`,
            variant: "info",
            id: "workspace-notifications",
          });
          test();
        }}
        directionIcon={
          testResult?.success === true
            ? CheckIcon
            : testResult?.success === false
              ? WarningIcon
              : undefined
        }
      >
        Send a test alert now
      </Button>
    </FormField>
  );
};
