import { useOutletContext } from "src/router";
import { DecisionEngine, OutletContext } from "..";
import { Text, Column, Heading, Alert, EmptyState } from "@hightouchio/ui";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { Controller } from "react-hook-form";
import { useSetDecisionEngineCollectionConfigMutation } from "src/graphql";
import { Editor } from "src/components/editor";
import json5 from "json5";
import { Card } from "src/components/card";

export const Collections = () => {
  const { engine } = useOutletContext<OutletContext>();

  if (!engine.collections.length) {
    return <EmptyState title="No collections" message="" />;
  }

  return (
    <Column gap={6}>
      {engine.collections.sort().map((collection) => (
        <Collection key={collection.id} collection={collection} />
      ))}
    </Column>
  );
};

const Collection = ({
  collection,
}: {
  collection: DecisionEngine["collections"][0];
}) => {
  const { engine } = useOutletContext<OutletContext>();

  const mutation = useSetDecisionEngineCollectionConfigMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await mutation.mutate({
        decisionEngineId: engine.id,
        collectionId: collection.id,
        schema: json5.parse(data.schema),
      });
    },
    values: {
      schema: JSON.stringify(collection.config?.schema ?? {}, null, 2),
    },
  });

  return (
    <Form form={form}>
      <Card
        maxHeight="800px"
        overflow="hidden"
        footer={<FormActions />}
        gap={4}
      >
        <Column>
          <Heading>{collection.collection.name}</Heading>
          <Text size="sm" color="text.secondary">
            {collection.id}
          </Text>
        </Column>

        {mutation.data?.setDecisionEngineCollectionConfig.__typename ===
          "DecisionEngineError" && (
          <Alert
            type="error"
            variant="inline"
            title="Error"
            message={JSON.stringify(
              mutation.data.setDecisionEngineCollectionConfig.error,
            )}
          />
        )}

        <Column flex={1} overflow="hidden">
          <Controller
            name="schema"
            render={({ field }) => <Editor language="json" {...field} />}
          />
        </Column>
      </Card>
    </Form>
  );
};
