import { FC, useRef } from "react";

import {
  Box,
  Column,
  GroupedSelect,
  Row,
  Tab,
  TabList,
  Tabs,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { isPresent } from "ts-extras";

import { PageSidebar, SidebarKey } from "src/components/layout/page-sidebar";
import { usePermissionContext } from "src/components/permission";
import * as analytics from "src/lib/analytics";
import { useAnalyticsContext } from "src/pages/analytics/state";
import { ChartFormState, ChartTab } from "src/pages/analytics/types";

import { ChartOptions } from "../constants";
import { GroupBy } from "./group-by";
import { Metrics } from "./metrics";
import { SegmentBy } from "./segment-by";
import { Steps } from "./steps";
import { Measurements } from "./measurements";

type AnalyticsSidebarProps = {
  funnelsErrors: Record<string, string>;
  isLoading?: boolean;
  metricSeriesErrors: Record<string, string>;
  ignoreHeaderHeight?: boolean;
  supportsFunnels?: boolean;
};

const SIDEBAR_WIDTH = 346;

export const AnalyticsSidebar: FC<AnalyticsSidebarProps> = ({
  funnelsErrors,
  metricSeriesErrors,
  ignoreHeaderHeight = false,
  supportsFunnels = true,
}) => {
  const { unauthorized } = usePermissionContext();

  const {
    parentModelLoading,
    parentModelOptionsLoading,
    parentModelOptions,

    selectParentModel,
    setChartTab,
  } = useAnalyticsContext();

  const form = useFormContext<ChartFormState>();

  const parentModelId = form.watch("parentModelId");
  const chartTab = form.watch("chartTab");
  const selectedAudiences = form.watch("selectedAudiences");

  const audiencesRef = useRef(selectedAudiences);
  audiencesRef.current = selectedAudiences;

  return (
    <>
      <PageSidebar
        ignoreHeaderHeight={ignoreHeaderHeight}
        collapsible={false}
        stateKey={SidebarKey.Analytics}
        width={`${SIDEBAR_WIDTH}px`}
        header={null}
      >
        <Column gap={4} width="100%">
          <GroupedSelect
            isLoading={parentModelLoading || parentModelOptionsLoading}
            isDisabled={
              unauthorized || parentModelLoading || parentModelOptionsLoading
            }
            optionAccessory={(option) => ({
              type: "image",
              url: option.connection?.definition.icon ?? "",
            })}
            placeholder="Select a parent model..."
            onChange={(value) => {
              if (!isPresent(value)) {
                return;
              }

              const option = parentModelOptions.find(
                (option) => option.value === value,
              );

              analytics.track("Parent Model Selected", {
                parent_model_id: value,
                parent_model_name: option?.label,
                parent_model_source_name: option?.connection?.name,
                parent_model_source_type: option?.connection?.definition.name,
              });

              selectParentModel(value, option?.label);
            }}
            optionGroups={[
              { label: "Parent Models", options: parentModelOptions },
            ]}
            value={parentModelId}
          />

          <Row mx={-4}>
            <Box
              borderBottom="1px solid"
              borderColor="base.border"
              flex={1}
              minWidth="20px"
            />
            <Controller
              control={form.control}
              name="chartTab"
              render={({ field }) => (
                <Box width={`${SIDEBAR_WIDTH - 20}px`}>
                  <Tabs
                    index={ChartOptions.findIndex((val) => val === field.value)}
                    onChange={(index) => {
                      const tab = ChartOptions[index];
                      if (!tab) return;

                      setChartTab(tab);
                    }}
                  >
                    <TabList>
                      <Tab>Insights</Tab>
                      {supportsFunnels && <Tab>Funnels</Tab>}
                    </TabList>
                  </Tabs>
                </Box>
              )}
            />
          </Row>
        </Column>
        <Column gap={4} flex={1} pb={4}>
          {chartTab === ChartTab.Funnel && <Steps />}

          {chartTab !== ChartTab.Funnel && (
            <>
              <Measurements />
              <Metrics metricSeriesErrors={metricSeriesErrors} />
            </>
          )}

          <SegmentBy
            funnelsErrors={funnelsErrors}
            metricSeriesErrors={metricSeriesErrors}
          />

          <GroupBy />
        </Column>
      </PageSidebar>
    </>
  );
};
