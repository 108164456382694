import { useFlags } from "launchdarkly-react-client-sdk";
import { useUser } from "src/contexts/user-context";

// Subsets should be enabled for anyone that has:
// - LaunchDarkly flag
// - permissions v2
export const useSubsetsEnabled = (): boolean => {
  const { appSubsetsEnabled } = useFlags();
  const { user } = useUser();

  return appSubsetsEnabled || user?.permissions_v2_enabled;
};
