import {
  Box,
  Checkbox,
  CheckboxGroup,
  Column,
  FormField,
  Row,
  SectionHeading,
  Slider,
  Text,
} from "@hightouchio/ui";
import { isEqual } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import * as y from "yup";

import { FC } from "react";
import { Card } from "src/components/card";
import { FieldError } from "src/components/field-error";
import { Flow } from "..";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { useUpdateDecisionEngineFlowMutation } from "src/graphql";

// Temporary types until backend PR is merged
enum Day {
  Sunday = "sunday",
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
}

enum Time {
  Midnight = "midnight",
  LateNight = "late_night",
  EarlyMorning = "early_morning",
  Morning = "morning",
  Afternoon = "afternoon",
  MidAfternoon = "mid_afternoon",
  Evening = "evening",
  Night = "night",
}

enum Frequency {
  Weekly1x = "weekly1x",
  Weekly2x = "weekly2x",
  Weekly3x = "weekly3x",
  Weekly4x = "weekly4x",
  Weekly5x = "weekly5x",
  Weekly6x = "weekly6x",
  Weekly7x = "weekly7x",
  Every2Week = "every2week",
  Monthly = "monthly",
}

export const DEFAULT_TIMING = {
  days: [
    Day.Monday,
    Day.Tuesday,
    Day.Wednesday,
    Day.Thursday,
    Day.Friday,
    Day.Saturday,
    Day.Sunday,
  ],
  times: [
    Time.MidAfternoon,
    Time.Afternoon,
    Time.Morning,
    Time.EarlyMorning,
    Time.Evening,
    Time.Night,
    Time.Midnight,
    Time.LateNight,
  ],
  min_days_between_sends: 6, // 1x weekly
};

const STEP_TO_DAYS = [
  {
    value: 0,
    label: "Daily",
  },
  {
    value: 1,
    label: "3x Weekly",
  },
  {
    value: 3,
    label: "2x Weekly",
  },
  {
    value: 6,
    label: "1x Weekly",
  },
  {
    value: 10,
    label: "3x Monthly",
  },
  {
    value: 13,
    label: "2x Monthly",
  },
  {
    value: 30,
    label: "Monthly",
  },
];

export type TimingProps = {
  flow: Flow;
};

export const Timing: FC<Readonly<TimingProps>> = ({ flow }) => {
  const update = useUpdateDecisionEngineFlowMutation();
  const form = useHightouchForm({
    onSubmit: async (data) => {
      await update.mutateAsync({
        id: flow.id,
        input: {
          timing: data.timing,
        },
      });
    },
    values: {
      timing: Object.keys(flow.timing ?? {}).length
        ? flow.timing
        : { days: [], times: [], min_days_between_sends: 6 },
    },
  });
  return (
    <Form form={form}>
      <Card footer={<FormActions size="md" />}>
        <Column maxWidth="600px" gap={6}>
          <Column>
            <SectionHeading>Scheduling</SectionHeading>
            <Text color="text.secondary">
              Specify when Hightouch is allowed to take actions. Message timing
              will be personalized to each user within the guardrails defined
              here. It’s always better to check as many options as possible to
              give the AI room to learn and act on individual preferences.
            </Text>
          </Column>
          <TimingFields />
          <FrequencyLimit />
        </Column>
      </Card>
    </Form>
  );
};

const TimingFields = () => {
  return (
    <Row gap={4}>
      <Controller
        name="timing.days"
        render={({ field, fieldState: { error } }) => {
          return (
            <Box
              as={FormField}
              label="Days of the week"
              maxW="300px"
              w="100%"
              error={error?.message}
            >
              <CheckboxGroup>
                {dayOptions.map((option) => (
                  <Checkbox
                    key={option.value}
                    onChange={(event) => {
                      field.onChange(
                        event.target.checked
                          ? [...field.value, option.value]
                          : field.value.filter((v) => v !== option.value),
                      );
                    }}
                    isChecked={field.value.includes(option.value)}
                    label={option.label}
                  />
                ))}
              </CheckboxGroup>
            </Box>
          );
        }}
      />
      <Controller
        name="timing.times"
        render={({ field, fieldState: { error } }) => {
          return (
            <Box
              as={FormField}
              label="Times of day"
              maxW="400px"
              w="100%"
              error={error?.message}
            >
              <Box
                display="grid"
                gridAutoFlow="column"
                gridTemplateRows="repeat(4  , 1fr)"
                rowGap={2}
                columnGap={2}
              >
                {timeOptions.map((option) => (
                  <Checkbox
                    mt="0 !important"
                    key={option.value}
                    onChange={(event) => {
                      field.onChange(
                        event.target.checked
                          ? [...field.value, option.value]
                          : field.value.filter((v) => v !== option.value),
                      );
                    }}
                    description={option.description}
                    isChecked={field.value.includes(option.value)}
                    label={option.label}
                  />
                ))}
              </Box>
            </Box>
          );
        }}
      />
    </Row>
  );
};

export const timingSchema = y.object().shape({
  min_days_between_sends: y.number().required(),
  days: y
    .array()
    .of(y.mixed().oneOf(Object.values(Day)))
    .required("You must select at least one day"),
  times: y
    .array()
    .of(y.mixed().oneOf(Object.values(Time)))
    .required("You must select at least one time"),
});

export const timeOptions = [
  {
    label: "Early Morning",
    description: "6:00 AM",
    value: Time.EarlyMorning,
  },
  {
    label: "Morning",
    description: "9:00 AM",
    value: Time.Morning,
  },
  {
    label: "Noon",
    description: "12:00 PM",
    value: Time.Afternoon,
  },
  {
    label: "Afternoon",
    description: "3:00 PM",
    value: Time.MidAfternoon,
  },
  { label: "Evening", description: "6:00 PM", value: Time.Evening },
  {
    label: "Night",
    description: "9:00 PM",
    value: Time.Night,
  },

  {
    label: "Midnight",
    description: "12:00 AM",
    value: Time.Midnight,
  },
  {
    label: "Late Night",
    description: "3:00 AM",
    value: Time.LateNight,
  },
];

export const timeRangeLabel = (range) => {
  return timeOptions.find((option) => isEqual(option.value, range))!.label;
};

export const frequencyOptions = [
  {
    label: "Daily",
    value: Frequency.Weekly7x,
  },
  {
    label: "6x Weekly",
    value: Frequency.Weekly6x,
  },
  {
    label: "5x Weekly",
    value: Frequency.Weekly5x,
  },
  {
    label: "4x Weekly",
    value: Frequency.Weekly4x,
  },
  {
    label: "3x Weekly",
    value: Frequency.Weekly3x,
  },
  {
    label: "2x Weekly",
    value: Frequency.Weekly2x,
  },
  {
    label: "Weekly",
    value: Frequency.Weekly1x,
  },
  {
    label: "Every 2 Weeks",
    value: Frequency.Every2Week,
  },
  {
    label: "Monthly",
    value: Frequency.Monthly,
  },
];

export const frequencyLabel = (frequency) => {
  return frequencyOptions.find((option) => isEqual(option.value, frequency))!
    .label;
};

export const dayOptions = [
  { label: "Monday", value: Day.Monday },
  { label: "Tuesday", value: Day.Tuesday },
  { label: "Wednesday", value: Day.Wednesday },
  { label: "Thursday", value: Day.Thursday },
  { label: "Friday", value: Day.Friday },
  { label: "Saturday", value: Day.Saturday },
  { label: "Sunday", value: Day.Sunday },
];

export const FrequencyLimit = () => {
  const { watch } = useFormContext();
  const daysBetweenSends = watch("timing.min_days_between_sends");

  return (
    <FormField
      label="Frequency limit"
      description="Define the maximum frequency Hightouch is allowed to take action. Hightouch will also experiment with less frequent communications to find the optimal cadence for each customer."
    >
      <Column alignItems="center" gap={1}>
        {daysBetweenSends >= 0 ? (
          <Text fontWeight="medium">
            {STEP_TO_DAYS.find((s) => s.value === daysBetweenSends)?.label}
          </Text>
        ) : (
          <Text fontWeight="medium" color="text.secondary">
            Not set
          </Text>
        )}

        <Controller
          name="timing.min_days_between_sends"
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <Row gap={4} alignItems="center" width="100%">
                  <Text>Daily</Text>
                  <Slider
                    aria-label="Set frequency."
                    onChange={(value) => {
                      field.onChange(STEP_TO_DAYS[value]?.value);
                    }}
                    min={0}
                    step={1}
                    max={STEP_TO_DAYS.length - 1}
                    value={STEP_TO_DAYS.findIndex(
                      (s) => s.value === field.value,
                    )}
                  />
                  <Text>Monthly</Text>
                </Row>
                <FieldError error={error?.message} />
              </>
            );
          }}
        />
      </Column>
    </FormField>
  );
};
