import { forwardRef, ReactNode } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  CheckIcon,
  CloseIcon,
  ColorProps,
  ConfirmationDialog,
  Row,
  useDisclosure,
} from "@hightouchio/ui";
import { useFormContext } from "react-hook-form";

import { ResourceToPermission as V1ResourceToPermission } from "src/components/permission/types";
import { V2ResourceToPermission } from "src/components/permission/types-v2";
import { ResourcePermissionInput } from "src/components/permission/use-resource-permission";
import { OrganizationPermissionInput } from "src/components/permission/use-organization-permissions";
import { withPermission } from "src/components/permission";

import { useHightouchFormContext } from "./hooks";

type FormConfirmation = {
  message: ReactNode;
  title: string;
};

type FormBarButtonProps = {
  onClick?: () => void;
  iconColor?: ColorProps["color"];
} & Pick<ButtonProps, "children" | "isDisabled" | "isLoading" | "icon">;

const FormBarButton = forwardRef<HTMLButtonElement, FormBarButtonProps>(
  ({ children, onClick, isDisabled, icon, iconColor, ...props }, ref) => {
    return (
      <>
        <Box
          as={Button}
          ref={ref}
          size="lg"
          variant="primary"
          onClick={onClick}
          icon={() => (
            <Box
              as={icon}
              boxSize={6}
              color={isDisabled ? "text.tertiary" : iconColor}
            />
          )}
          isDisabled={isDisabled}
          color={isDisabled ? "text.tertiary" : "white"}
          // Note: important! is required here to override some of the hover/active/disabled styles that Chakra applies
          bg="forest.800!"
          _hover={{ bg: "forest.700!" }}
          _active={{ bg: "forest.600!" }}
          _disabled={{ bg: "forest.800!" }}
          _loading={{ bg: "forest.800!" }}
          {...props}
        >
          {children}
        </Box>
      </>
    );
  },
);

FormBarButton.displayName = "FormBarButton";

const PermissionedFormBarButton = withPermission(FormBarButton);

export const FormBar = <
  V2Resource extends V2ResourceToPermission,
  V1Resource extends V1ResourceToPermission,
>({
  permission,
  organizationPermission,
  confirmation,
}: {
  permission?: ResourcePermissionInput<V2Resource, V1Resource>;
  organizationPermission?: OrganizationPermissionInput;
  confirmation?: FormConfirmation;
}) => {
  const {
    formState: { isSubmitting, isDirty },
    reset,
  } = useFormContext();
  const disclosure = useDisclosure();
  const { submit } = useHightouchFormContext();

  const isLoading = isSubmitting;
  const formBarDisabled = !isDirty;
  const buttonDisabled = isLoading || !isDirty;

  return (
    <Row
      mx="auto"
      h="96px"
      width="min-content"
      position="sticky"
      bottom={0}
      flexShrink={0}
      align="center"
      zIndex={3}
      mt="auto"
      visibility={formBarDisabled ? "hidden" : "visible"}
    >
      <Box
        p={2}
        position="relative"
        bg="forest.800"
        borderRadius="lg"
        bottom={formBarDisabled ? -4 : 0}
        opacity={formBarDisabled ? 0 : 1}
        transition="all 400ms cubic-bezier(0.42, 0, 0, 1.8)"
      >
        <ButtonGroup>
          <PermissionedFormBarButton
            onClick={() => {
              if (confirmation) {
                disclosure.onOpen();
              } else {
                submit();
              }
            }}
            isDisabled={buttonDisabled}
            isLoading={isLoading}
            icon={CheckIcon}
            iconColor="grass.500"
            permission={permission}
            organizationPermission={organizationPermission}
          >
            Save changes
          </PermissionedFormBarButton>
          <FormBarButton
            onClick={() => reset()}
            isDisabled={buttonDisabled}
            icon={CloseIcon}
            iconColor="danger.500"
          >
            Discard
          </FormBarButton>
        </ButtonGroup>
      </Box>
      {confirmation && (
        <ConfirmationDialog
          isOpen={disclosure.isOpen}
          onClose={disclosure.onClose}
          onConfirm={async () => {
            await submit();
            disclosure.onClose();
          }}
          variant="warning"
          confirmButtonText="Confirm"
          title={confirmation.title}
        >
          {confirmation.message}
        </ConfirmationDialog>
      )}
    </Row>
  );
};
