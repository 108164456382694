import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  Box,
  Button,
  Column,
  Heading,
  SectionHeading,
  Text,
} from "@hightouchio/ui";
import { FC } from "react";

import { Card } from "src/components/card";
import { LoadingCircles } from "src/components/loading";
import { useUpdateDecisionEngineFlowMutation } from "src/graphql";
import { Flow } from "..";

export const Initializing = () => {
  return (
    <Box
      as={Card}
      gap={6}
      flex={1}
      py={16}
      alignItems="center"
      justifyContent="center"
    >
      <LoadingCircles />
      <Column align="center" gap={4} textAlign="center">
        <Heading>Initializing your agent...</Heading>
        <Box maxW="xl" color="text.secondary">
          A custom machine learning model is being trained based on your
          configuration. This process can take up to a few days and you'll
          receive an email when it's ready.
        </Box>
      </Column>
    </Box>
  );
};

export const DraftFlow: FC<Readonly<{ flow: Flow }>> = ({ flow }) => {
  const updateMutation = useUpdateDecisionEngineFlowMutation();

  return (
    <Card alignItems="flex-start" gap={6} p={6}>
      <Column>
        <SectionHeading>Draft agent</SectionHeading>
        <Text>
          Once you add an action and an outcome you can initialize the agent to
          start delivering optimal content to your users.
        </Text>
      </Column>

      <Button
        isDisabled={flow.messages.length === 0 || flow.outcomes.length === 0}
        isLoading={updateMutation.isLoading}
        variant="primary"
        onClick={async () => {
          await updateMutation.mutateAsync({
            id: flow.id,
            input: {
              status: DecisionEngineStatus.TRAINING,
            },
          });
        }}
      >
        Initialize agent
      </Button>
    </Card>
  );
};
