import { FC, useEffect } from "react";

import {
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  Checkbox,
  CheckboxGroup,
  CloseIcon,
  Column,
  Heading,
  IconButton,
  Paragraph,
  Row,
  Text,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { Controller, useFieldArray } from "react-hook-form";
import { useParams } from "src/router";
import partition from "lodash/partition";

import {
  useEventPlansQuery,
  useEventSourcesQuery,
  useUpdateEventPlanSourcesMutation,
} from "src/graphql";
import { eventSourceDefinitions } from "src/events/types";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { HTImage } from "src/components/image";

type UpdateEventSourcesModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type EventSourcesFormState = {
  sources: {
    id: string;
    label: string;
    isDisabled?: boolean;
    checked: boolean;
    type: string;
    contract: { id: string; name: string } | null;
  }[];
};

export const UpdateEventSourcesModal: FC<UpdateEventSourcesModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { id: contractId } = useParams<{ id: string }>();

  const eventPlansQuery = useEventPlansQuery(undefined, {
    select: (data) => {
      return data.event_plans;
    },
    notifyOnChangeProps: "tracked",
    keepPreviousData: true,
  });
  const eventSourcesQuery = useEventSourcesQuery(undefined, {
    select: (data) => data.event_sources,
  });
  const updateEventPlanSourcesMutation = useUpdateEventPlanSourcesMutation();

  const eventPlanNamesById =
    eventPlansQuery.data?.reduce(
      (acc, { id, name }) => {
        acc[id] = name;
        return acc;
      },
      {} as Record<string, string>,
    ) ?? {};

  const form = useHightouchForm({
    onSubmit: async (data: EventSourcesFormState) => {
      const sourcesToUpdate = data.sources.filter(
        ({ isDisabled }) => !isDisabled,
      );
      const [checked, notChecked] = partition(
        sourcesToUpdate,
        ({ checked }) => checked,
      );

      await Promise.all([
        updateEventPlanSourcesMutation.mutateAsync({
          ids: checked.map(({ id }) => id),
          event_plan_id: contractId,
        }),
        updateEventPlanSourcesMutation.mutateAsync({
          ids: notChecked.map(({ id }) => id),
          event_plan_id: null,
        }),
      ]);

      onClose();
    },
    success: "Contract updated",
    error: "Error updating event contract",
    defaultValues: {
      sources: [] as EventSourcesFormState["sources"],
    },
  });

  const { fields } = useFieldArray({ control: form.control, name: "sources" });

  useEffect(() => {
    const selectedOptions = eventSourcesQuery.data?.map(
      ({ id, name, type, event_plan_id }) => {
        const belongsToAnotherContract = Boolean(
          event_plan_id && event_plan_id !== contractId,
        );

        return {
          id,
          checked: event_plan_id === contractId,
          contract: belongsToAnotherContract
            ? {
                id: event_plan_id!,
                name: eventPlanNamesById[event_plan_id!],
              }
            : null,
          isDisabled: belongsToAnotherContract,
          label: name,
          type,
        };
      },
    );

    selectedOptions?.sort((a, b) => {
      if (a.contract && !b.contract) {
        return 1;
      }

      if (!a.contract && b.contract) {
        return -1;
      }

      return 0;
    });

    form.reset({ sources: selectedOptions ?? [] });
  }, [eventSourcesQuery.data]);

  return (
    <ChakraModal isCentered isOpen={isOpen} onClose={onClose}>
      <ChakraModalOverlay />
      <ChakraModalContent p={0} maxHeight="85%" my="auto">
        <Form form={form}>
          <ChakraModalHeader p={4}>
            <Row align="center" justify="space-between" width="100%">
              <Heading>Event sources</Heading>
              <IconButton
                aria-label="Close modal."
                icon={CloseIcon}
                onClick={onClose}
              />
            </Row>
          </ChakraModalHeader>
          <ChakraModalBody p={4} mt={0}>
            <Column gap={4}>
              <Paragraph>
                Hightouch will monitor events coming from selected sources for
                contract violations.
              </Paragraph>
              <CheckboxGroup>
                {fields.map(
                  ({ id, isDisabled, label, type, contract }, index) => (
                    <Controller
                      key={id}
                      control={form.control}
                      name={`sources.${index}.checked`}
                      render={({ field }) => (
                        <Row
                          key={id}
                          align="center"
                          gap={2}
                          as="label"
                          htmlFor={id}
                        >
                          <Checkbox
                            id={id}
                            isDisabled={isDisabled}
                            isChecked={field.value}
                            onChange={(event) => {
                              field.onChange(event.target.checked);
                            }}
                          />
                          <HTImage
                            src={eventSourceDefinitions[type].icon}
                            width="20px"
                            decorative
                          />
                          <Text
                            color={
                              isDisabled ? "text.disabled" : "text.primary"
                            }
                            fontWeight="medium"
                          >
                            {label}
                          </Text>
                          {contract && (
                            <Text color="text.placeholder" size="sm">
                              Used by{" "}
                              <Link
                                isExternal
                                href={`/events/contracts/${contract.id}`}
                              >
                                {contract.name}
                              </Link>
                            </Text>
                          )}
                        </Row>
                      )}
                    />
                  ),
                )}
              </CheckboxGroup>
            </Column>
          </ChakraModalBody>
          <ChakraModalFooter
            p={4}
            borderTop="1px solid"
            borderColor="base.border"
          >
            <FormActions />
          </ChakraModalFooter>
        </Form>
      </ChakraModalContent>
    </ChakraModal>
  );
};
