import { Column, FormField, Textarea, TextInput } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { AudienceExplore } from "src/components/audiences/audience-explore";
import { useHightouchForm } from "src/components/form";
import { useCreateAudienceTemplateMutation } from "src/graphql";
import { useModelState } from "src/hooks/use-model-state";
import { QueryType } from "src/types/models";
import { useWizardStepper } from "src/utils/use-wizard-stepper";
import { useNavigate, useOutletContext } from "src/router";
import { DeprecatedWizard, WizardStep } from "src/components/wizard";
import { getTagsFromLabels, LabelForm } from "src/components/labels/label-form";
import { ResourceType } from "src/components/labels/use-labels";
import { AudienceTemplatePageOutletState } from "./show";

export const validationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
  })
  .required();

export const CloneAudienceTemplate = () => {
  const { audienceTemplate, parentModel, id } =
    useOutletContext<AudienceTemplatePageOutletState>();

  const navigate = useNavigate();

  const source = parentModel?.connection;

  const modelState = useModelState({
    query_type: QueryType.Visual,
    connection: source,
    parent: parentModel,
    visual_query_filter: audienceTemplate ? audienceTemplate.definition : {},
  });

  const createAudienceTemplate = useCreateAudienceTemplateMutation();
  const form = useHightouchForm<{
    name: string;
    description: string;
    labels: { key: string; value: string }[];
  }>({
    resolver: yupResolver(validationSchema),
    success: "Audience template created",
    onSubmit: async ({ name, description, labels }) => {
      const { insert_audience_templates_one } =
        await createAudienceTemplate.mutateAsync({
          input: {
            name,
            description,
            parent_model_id: parentModel?.id,
            definition: modelState.state.visual_query_filter,
            labels: getTagsFromLabels(labels),
          },
        });

      navigate(
        `/templates/audience-templates/${insert_audience_templates_one?.id}`,
      );
    },
    defaultValues: {
      name: `Copy of ${audienceTemplate.name}`,
      labels: [{ key: "", value: "" }],
    },
  });

  const [activeStep, setActiveStep] = useWizardStepper(0);

  const steps: WizardStep[] = [
    {
      title: "Update template",
      onContinue: () => setActiveStep((s) => s + 1),
      render: () => (
        <Column minHeight={0} flex={1} background="base.lightBackground">
          <AudienceExplore
            modelState={modelState}
            parentModel={parentModel}
            source={source}
            variant="template"
          />
        </Column>
      ),
    },
    {
      title: "Finalize settings for this template",
      render: () => (
        <Column gap={6}>
          <Controller
            control={form.control}
            name="name"
            render={({ field, fieldState }) => (
              <Column>
                <FormField
                  label="Template name"
                  error={fieldState.error?.message}
                >
                  <TextInput {...field} placeholder="Enter a name..." />
                </FormField>
              </Column>
            )}
          />
          <Controller
            control={form.control}
            name="description"
            render={({ field, fieldState }) => (
              <Column>
                <FormField
                  label="Description"
                  description="How and when should this template be used?"
                  error={fieldState.error?.message}
                >
                  <Textarea {...field} placeholder="Enter a description..." />
                </FormField>
              </Column>
            )}
          />
          <LabelForm
            heading="Add labels"
            hint="Example keys: team, project, region, env."
            resourceType={ResourceType.Model}
          />
        </Column>
      ),
    },
  ];

  const onCancel = () => {
    navigate(`/templates/audience-templates/${id}`);
  };

  return (
    <FormProvider {...form}>
      <DeprecatedWizard
        fullscreen={activeStep === 0}
        setStep={setActiveStep}
        step={activeStep}
        steps={steps}
        title="Copy audience template"
        onCancel={onCancel}
        onSubmit={form.submit}
      />
    </FormProvider>
  );
};
