import { useEffect, useMemo, FC } from "react";

import {
  AddBoxIcon,
  Box,
  Column,
  NumberInput,
  Row,
  Select,
  Text,
  Tooltip,
  WarningIcon,
} from "@hightouchio/ui";
import { isPresent } from "ts-extras";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  NumberOfCondition,
  NumberOperator,
  numberOfOperatorOptions,
  initialPropertyCondition,
  ConditionType,
  PropertyCondition,
} from "src/types/visual";
import { useQueryBuilderContext } from "src/components/explore/context/hooks";

import { FilterPopover } from "src/components/explore/filter-popover";
import {
  removePropertySubcondition,
  updatePropertySubcondition,
} from "src/components/explore/utils/condition-builders";
import { formatSubconditions } from "src/components/explore/utils/format-subconditions";
import { isAndOrCondition } from "src/components/explore/utils/type-guards";
import { FilterProps } from "./condition";
import { HStack } from "./shared";
import { DetailButton } from "./condition-buttons";
import { validateNumberOfCondition } from "./condition-validation";
import { ErrorMessage } from "./error-message";
import { Filter } from "./filter";
import { NestedPropertyFilter } from "./nested-property-filter";
import { MinimalParentModel } from "src/components/audiences/types";

const RelatedTo = {
  operator: NumberOperator.GreaterThan,
  value: 1,
};

const NotRelatedTo = {
  operator: NumberOperator.Equals,
  value: 0,
};

const relatedOptions = [
  { label: "Has", value: "related" },
  { label: "Does not have", value: "not related" },
];

export const NumberOfFilter: FC<Readonly<FilterProps<NumberOfCondition>>> = (
  props,
) => {
  const {
    relationships: relationshipsOverride,
    condition: unformattedCondition,
    onChange,
  } = props;

  const { relationships: allRelationships } = useQueryBuilderContext();

  const relationships = relationshipsOverride ?? allRelationships;

  // Wrap subconditions in one and/or condition
  const condition = formatSubconditions(unformattedCondition);
  const topLevelSubcondition = condition.subconditions?.[0];
  // Assumption is that event condition will be formatted to always have one And/Or subcondition as a child (if there are subconditions)
  const subconditions = isAndOrCondition(topLevelSubcondition)
    ? topLevelSubcondition.conditions
    : [];

  const filterId = useMemo<string>(uuidv4, []);

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const modelError = filterErrors?.relationshipId;
  const valueError = filterErrors?.value;

  useEffect(() => {
    setFieldError(filterId, validateNumberOfCondition(condition));

    return () => {
      removeErrors([filterId]);
    };
  }, [condition.relationshipId, condition.value, filterId]);

  const relatedModel = relationships?.find(
    ({ id }) => id === condition.relationshipId,
  )?.to_model;

  // If the underlying related model is removed/updated, the relationId will still point to the old, invalid model.
  const invalidRelationshipState =
    condition.relationshipId != null && relatedModel?.id == null;

  const isNotRelated =
    condition.value === 0 && condition.operator === NumberOperator.Equals;
  const relatedValue = isNotRelated ? "not related" : "related";
  const relatedLabel = relatedOptions?.find(
    ({ value }) => relatedValue === value,
  )?.label;
  const operatorLabel = numberOfOperatorOptions.find(
    ({ value }) => value === condition.operator,
  )?.label;

  const updateWrappingConditionType = (
    type: ConditionType.And | ConditionType.Or,
  ) => {
    if (!isAndOrCondition(topLevelSubcondition)) {
      return;
    }

    onChange({
      ...condition,
      subconditions: [
        {
          type: type,
          conditions: topLevelSubcondition?.conditions ?? [],
        },
      ],
    });
  };

  const addSubcondition = () => {
    if (!isAndOrCondition(topLevelSubcondition)) {
      return;
    }

    onChange({
      ...condition,
      subconditions: [
        {
          type: topLevelSubcondition.type,
          conditions: [
            ...topLevelSubcondition.conditions,
            initialPropertyCondition,
          ],
        },
      ],
    });
  };

  const updateSubcondition = (
    updates: Partial<PropertyCondition>,
    index: number,
  ) => {
    onChange(updatePropertySubcondition(index, condition, updates));
  };

  const removeSubcondition = (index: number) => {
    onChange(removePropertySubcondition(index, condition));
  };

  return (
    <>
      <HStack gap={2} sx={{ alignItems: "flex-start" }}>
        <Filter
          isEmpty={false} // Field is automatically populated
          content={
            <Row align="flex-start" gap={2}>
              <Select
                removePortal
                options={relatedOptions}
                placeholder="Select relation"
                value={relatedValue}
                width="auto"
                onChange={(value) => {
                  if (value === "related") {
                    onChange(RelatedTo);
                  } else {
                    onChange(NotRelatedTo);
                  }
                }}
              />
              {isNotRelated ? null : (
                <>
                  <Select
                    removePortal
                    options={numberOfOperatorOptions}
                    placeholder="Filter on"
                    value={condition.operator}
                    width="auto"
                    onChange={(operator) => {
                      onChange({ operator });
                    }}
                  />
                  <Column>
                    <NumberInput
                      isInvalid={Boolean(valueError)}
                      placeholder="amount..."
                      value={condition.value}
                      width="auto"
                      onChange={(value) => {
                        onChange({ value });
                      }}
                    />
                    {valueError && <ErrorMessage>{valueError}</ErrorMessage>}
                  </Column>
                </>
              )}
            </Row>
          }
          error={valueError}
        >
          <Text fontWeight="medium">
            <Text color="text.secondary" fontWeight="normal">
              {relatedLabel}
            </Text>
            {!isNotRelated &&
              ` ${operatorLabel} ${
                !isPresent(condition.value) ? "___" : condition.value
              }`}
          </Text>
        </Filter>
        <Text color="text.secondary" mt={1.5}>
          record in
        </Text>
        <FilterPopover
          {...props}
          condition={condition}
          hasError={Boolean(modelError) || invalidRelationshipState}
        />
        {invalidRelationshipState && (
          <Tooltip message="The related model may have been updated or deleted">
            <Box as={WarningIcon} boxSize={6} color="warning.400" />
          </Tooltip>
        )}
      </HStack>
      {subconditions.map((subcondition, index) => {
        if (subcondition.type !== ConditionType.Property) {
          return null;
        }

        return (
          <NestedPropertyFilter
            key={index}
            {...props}
            columns={undefined}
            modelId={relatedModel?.id}
            condition={subcondition}
            isFirstCondition={index === 0}
            isWrappedWithAndCondition={
              topLevelSubcondition?.type === ConditionType.And
            }
            parent={relatedModel as unknown as MinimalParentModel}
            traits={[]}
            onChange={(updates) => updateSubcondition(updates, index)}
            onRemove={() => removeSubcondition(index)}
            onToggleWrappingConditionType={() =>
              updateWrappingConditionType(
                topLevelSubcondition?.type === ConditionType.And
                  ? ConditionType.Or
                  : ConditionType.And,
              )
            }
            containerSx={{ align: "flex-start", pl: 10, width: "100%" }}
          />
        );
      })}
      <Row pl={10}>
        <DetailButton
          icon={AddBoxIcon}
          isDisabled={!condition.relationshipId}
          size="sm"
          onClick={addSubcondition}
        >
          Where...
        </DetailButton>
      </Row>
    </>
  );
};
