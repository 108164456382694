import { FC } from "react";

import { Badge } from "@hightouchio/ui";

export enum SyncSequenceRunStatus {
  Running = "running",
  Done = "done",
  Failed = "failed",
  Cancelled = "cancelled",
}

export const SequenceStatusBadge: FC<
  Readonly<{ status: string | undefined | null }>
> = ({ status }) => {
  switch (status) {
    case SyncSequenceRunStatus.Done:
      return <Badge variant="success">Completed</Badge>;
    case SyncSequenceRunStatus.Failed:
      return <Badge variant="error">Failed</Badge>;
    case SyncSequenceRunStatus.Cancelled:
      return <Badge>Cancelled</Badge>;
    case SyncSequenceRunStatus.Running:
      return <Badge variant="info">Running</Badge>;
    default:
      return <Badge>Pending</Badge>;
  }
};
