import { CreateEventSource } from "./create";
import { EventSources } from "./sources";
import { Navigate, RouteObject } from "src/router";

import { Debugger } from "./source/debugger";
import { Setup } from "./source/setup";
import { Configuration } from "./source/configuration";
import { Overview } from "./source/overview";
import { EventCatalog } from "./source/event-catalog";
import { ConfigureEvent } from "./source/configure-event";
import { EventSourceLayout } from "./source";

export const routes: Array<RouteObject> = [
  {
    index: true,
    element: <EventSources />,
  },
  {
    path: "new",
    element: <CreateEventSource />,
  },
  {
    path: ":id",
    element: <EventSourceLayout />,
    children: [
      {
        path: "overview",
        element: <Overview />,
      },
      {
        path: "event-catalog",
        element: <EventCatalog />,
        children: [
          {
            path: "configure/:eventId",
            element: <ConfigureEvent />,
          },
        ],
      },
      {
        path: "debugger",
        element: <Debugger />,
      },
      {
        path: "setup",
        element: <Setup />,
      },
      {
        path: "configuration",
        element: <Configuration />,
      },
      {
        index: true,
        element: (
          <Navigate
            to={{ pathname: "overview", search: location.search }}
            replace
          />
        ),
      },
    ],
  },
];
