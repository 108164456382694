import {
  Box,
  IconButton,
  Select,
  Button,
  Column,
  Row,
  DeleteIcon,
  Combobox,
  PlusIcon,
  TextInput,
} from "@hightouchio/ui";
import { FC, Fragment } from "react";
import { useFormContext, useFieldArray, Controller } from "react-hook-form";
import * as yup from "yup";
import { PropertyCondition } from "@hightouch/lib/customer-data/decision-engine/types";

import { AndOrToggleButton } from "src/components/explore/visual/condition-buttons";
import { GroupIndicatorBar } from "src/components/explore/visual/group-indicator-bar";
import { useModelQuery } from "src/graphql";
import { colors } from "src/components/explore/visual/colors";

export const columnReferenceSchema = yup.object().shape({
  table: yup.string().required(),
  name: yup.string().required(),
});

export const filterSchema = yup.object().shape({
  type: yup.string().required(),
  conditions: yup.array().of(
    yup.object().shape({
      type: yup.string().required(),
      property: columnReferenceSchema,
      value: yup.lazy((value) => {
        switch (typeof value) {
          case "object":
            return columnReferenceSchema;
          default:
            return yup.string().required();
        }
      }),
      operator: yup.string().required(),
    }),
  ),
});

// Maps to Operator enum in decision_engine/decision_engine_types.py
const operatorOptions = [
  { value: "=", label: "is equal to" },
  { value: "<>", label: "is not equal to" },
  { value: ">", label: "is greater than" },
  { value: "<", label: "is less than" },
];

export const ConditionGroup: FC<{
  leftModelId: string | number;
  rightModelId?: string | number;
  leftTable: string;
  rightTable?: string;
  formKey?: string;
}> = ({
  leftModelId,
  rightModelId,
  leftTable,
  rightTable,
  formKey = "filter",
}) => {
  const { watch } = useFormContext();
  const type = watch(`${formKey}.type`);
  const { fields, remove, append } = useFieldArray({
    name: `${formKey}.conditions`,
  });

  return (
    <Column gap={4}>
      {fields.map((field, i) => (
        <Fragment key={field.id}>
          <Row>
            <GroupIndicatorBar conditionType={type} />
            <Row
              flex={1}
              px={4}
              py={2}
              align="center"
              gap={4}
              bg="white"
              border="1px"
              borderLeft="none"
              borderColor="base.border"
              borderTopRightRadius="md"
              borderBottomRightRadius="md"
            >
              <Condition
                leftModelId={leftModelId}
                rightModelId={rightModelId}
                leftTable={leftTable}
                rightTable={rightTable}
                index={i}
                formKey={formKey}
              />
              <IconButton
                variant="danger"
                aria-label="Delete condition"
                icon={DeleteIcon}
                onClick={() => remove(i)}
              />
            </Row>
          </Row>
          {fields.length > 0 && (
            <Controller
              name={`${formKey}.type`}
              render={({ field }) => (
                <AndOrToggleButton
                  conditionType={field.value}
                  onClick={() => {
                    field.onChange(field.value === "and" ? "or" : "and");
                  }}
                />
              )}
            />
          )}
        </Fragment>
      ))}

      <Controller
        name={`${formKey}.type`}
        render={({ field }) => (
          <AddConditionButton
            type={field.value}
            onClick={() => {
              append({
                type: "property",
                property: {
                  table: leftTable ?? "items",
                  name: "",
                },
                operator: "=",
                value: "",
              } as PropertyCondition);
            }}
          />
        )}
      />
    </Column>
  );
};

export const Condition = ({
  leftModelId,
  rightModelId,
  index,
  leftTable,
  rightTable,
  formKey,
}: {
  leftModelId: string | number;
  rightModelId?: string | number;
  leftTable: string;
  rightTable?: string;
  index: number;
  formKey: string;
}) => {
  const leftQuery = useModelQuery(
    { id: leftModelId?.toString() },
    { enabled: Boolean(leftModelId), select: (data) => data.segments_by_pk },
  );
  const rightQuery = useModelQuery(
    { id: String(rightModelId) },
    { enabled: Boolean(rightModelId), select: (data) => data.segments_by_pk },
  );

  return (
    <>
      <Controller
        name={`${formKey}.conditions.${index}.property`}
        render={({ field, fieldState: { error } }) => (
          <Combobox
            isInvalid={Boolean(error)}
            width="100%"
            value={field.value?.name}
            onChange={(name) => {
              field.onChange({
                table: leftTable,
                name,
              });
            }}
            isLoading={leftQuery.isLoading}
            // Use lowercase names because all features are lowercased in the recommender
            options={
              leftQuery.data?.columns?.map((col) => ({
                ...col,
                name: col.name.toLowerCase(),
              })) ?? []
            }
            optionValue={(option) => option.name}
            optionLabel={(option) => option.alias ?? option.name}
          />
        )}
      />
      <Controller
        name={`${formKey}.conditions.${index}.operator`}
        render={({ field, fieldState: { error } }) => (
          <Select
            {...field}
            width="100%"
            isInvalid={Boolean(error)}
            options={operatorOptions}
          />
        )}
      />
      <Controller
        name={`${formKey}.conditions.${index}.value`}
        render={({ field, fieldState: { error } }) => {
          if (typeof field.value !== "object") {
            return (
              <>
                {rightModelId && (
                  <Select
                    value="static value"
                    width="auto"
                    onChange={() => {
                      field.onChange({
                        type: "",
                        table: "",
                        name: "",
                      });
                    }}
                    options={["user property", "static value"]}
                    optionValue={(option) => option}
                    optionLabel={(option) => option}
                  />
                )}
                <TextInput isInvalid={Boolean(error)} width="100%" {...field} />
              </>
            );
          }
          // Use lowercase names because all features are lowercased in the recommender
          const options =
            (rightQuery.data?.parent
              ? rightQuery.data.parent.columns
              : rightQuery.data?.columns
            )?.map((col) => ({
              ...col,
              name: col.name.toLowerCase(),
            })) ?? [];
          return (
            <>
              {rightModelId && (
                <Select
                  width="auto"
                  value="user property"
                  onChange={() => {
                    field.onChange("");
                  }}
                  options={["user property", "static value"]}
                  optionValue={(option) => option}
                  optionLabel={(option) => option}
                />
              )}
              <Combobox
                width="100%"
                isInvalid={Boolean(error)}
                isLoading={rightQuery.isLoading}
                value={field.value?.name}
                onChange={(value) => {
                  field.onChange({
                    table: rightTable ?? "users",
                    name: value,
                  });
                }}
                options={field.value ? [...options, field.value] : options}
                optionValue={(option) => option.name}
                optionLabel={(option) => option.alias ?? option.name}
              />
            </>
          );
        }}
      />
    </>
  );
};

export const AddConditionButton: FC<
  Readonly<{ type: string | undefined; onClick: () => void }>
> = ({ type, onClick }) => {
  return (
    <Box
      sx={
        type
          ? {
              button: {
                bg: colors.base[type],
                border: "none",
                _hover: {
                  bg: colors.hover[type],
                },
                _active: {
                  bg: colors.hover[type],
                },
                svg: { color: "text.primary" },
              },
            }
          : {}
      }
    >
      <Button icon={PlusIcon} onClick={onClick}>
        Filter
      </Button>
    </Box>
  );
};
