import { HTImage } from "src/components/image";
import { FC, ReactNode, useEffect, useState } from "react";
import {
  Row,
  Box,
  Column,
  Button,
  Text,
  Heading,
  ArrowRightIcon,
} from "@hightouchio/ui";
import { useNavigate, useParams } from "src/router";
import { LogoHeader } from "src/components/layout";
import arrowIcon from "src/components/welcome/arrow.svg";
import arrowsIcon from "src/components/welcome/arrows.svg";
import databaseIcon from "src/components/welcome/database.svg";
import { DestinationCatalog } from "src/components/welcome/destination-catalog";
import { SourceCatalog } from "src/components/welcome/source-catalog";
import { CreateWorkspace } from "src/components/workspaces/create-workspace";
import { useUser } from "src/contexts/user-context";
import {
  DestinationDefinition,
  SourceDefinition,
  useAccessibleOrganizationsQuery,
  useUpdateUserPersonalizationMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Logo } from "src/ui/brand";
import { Card } from "src/components/card";
import { PageSpinner } from "src/components/loading";
import { cdn } from "src/utils/sanity";
import { switchWorkspace } from "src/utils/workspaces";

export const Welcome: FC = () => {
  const navigate = useNavigate();

  const { data: organizations, isLoading } = useAccessibleOrganizationsQuery(
    {},
    { select: (data) => data.getAccessibleOrganizations.organizations },
  );

  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <Column align="center" height="100vh" width="100vw">
      <LogoHeader />
      <Column align="center" bg="gray.50" flex={1} py={10} width="100%">
        <Diagram
          onNext={() => {
            if (organizations?.length) {
              navigate("/workspaces");
            } else {
              navigate("/welcome/1");
            }
          }}
        />
      </Column>
    </Column>
  );
};

export const WelcomeWizard: FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const params = useParams<{ step: string }>();
  const step = Number(params.step);
  const [source, setSource] = useState<SourceDefinition>();
  const [destination, setDestination] = useState<DestinationDefinition>();
  const updateUser = useUpdateUserPersonalizationMutation();

  useEffect(() => {
    analytics.track("Onboarding Started", {});
  }, []);

  return (
    <Column align="center" height="100vh" width="100vw">
      <LogoHeader>
        <Stepper step={step} />
      </LogoHeader>
      <Column
        align="center"
        bg="gray.50"
        flex={1}
        overflow="hidden"
        py={10}
        width="100%"
        height="100%"
      >
        {step === 1 && (
          <SelectSourceStep
            onSelect={(source) => {
              analytics.track("Source Declaration Started", {});
              setSource(source);
              updateUser.mutateAsync({
                userId: String(user?.id),
                data: { sourceType: source.type },
              });
              navigate(`/welcome/${step + 1}`);
            }}
          />
        )}
        {step === 2 && (
          <SelectDestinationStep
            onSelect={(destination) => {
              analytics.track("Destination Declaration Started", {});
              setDestination(destination);
              updateUser.mutateAsync({
                userId: String(user?.id),
                data: { destinationType: destination.type },
              });
              navigate(`/welcome/${step + 1}`);
            }}
          />
        )}
        {step === 3 && (
          <CreateWorkspaceStep
            destinationType={destination?.type}
            sourceType={source?.type}
          />
        )}
      </Column>
    </Column>
  );
};

const Step: FC<{
  title: string;
  description?: string;
  children: ReactNode;
  width?: string;
  overflow?: string;
}> = ({
  title,
  description,
  children,
  width = "666px",
  overflow = "hidden",
}) => {
  return (
    <Column align="center" flex={1} maxWidth={width} width="100%" height="100%">
      <Heading mb={4} size="2xl" textAlign="center">
        {title}
      </Heading>
      {description && (
        <Box
          color="gray.600"
          fontSize="lg"
          fontWeight="medium"
          maxWidth="56ch"
          textAlign="center"
        >
          {description}
        </Box>
      )}
      <Column
        align="center"
        flex={1}
        gap={10}
        width="100%"
        mt={10}
        overflow={overflow}
      >
        {children}
      </Column>
    </Column>
  );
};

const StepDot: FC<{ active: boolean }> = ({ active }) => {
  return (
    <Box p={2} bg={active ? "#D3F1F3" : "inherit"} borderRadius="50%">
      <Box
        bg={active ? "#1D656A" : "white"}
        border="1px solid"
        borderColor={active ? "#1D656A" : "gray.500"}
        borderRadius="50%"
        height="10px"
        width="10px"
      />
    </Box>
  );
};

const Stepper: FC<{ step: number }> = ({ step }) => {
  return (
    <Row gap={4} align="center">
      <StepDot active={step === 1} />
      <Box width="16px" height="1px" bg="gray.400" />
      <StepDot active={step === 2} />
      <Box width="16px" height="1px" bg="gray.400" />
      <StepDot active={step === 3} />
    </Row>
  );
};

const Diagram: FC<Readonly<{ onNext: () => void }>> = ({ onNext }) => {
  return (
    <Step
      description="Hightouch is the easiest way to sync data into the tools that move your business — no scripts, CSVs, or
      engineering favors required."
      title="Welcome to Hightouch"
      width="100%"
    >
      <Row
        align="center"
        gap={8}
        width="100%"
        maxWidth="1000px"
        px={4}
        justify="center"
      >
        <Column align="center" gap={4} justify="center">
          <HTImage src={databaseIcon} alt="Database icon" />
          <Heading size="md">Data warehouse</Heading>
        </Column>

        <HTImage src={arrowIcon} alt="Arrow icon" />

        <Column align="center" gap={4} justify="center">
          <Logo size="48px" theme="dark" />
          <Heading size="md">Hightouch</Heading>
        </Column>

        <Box minWidth="250px">
          <HTImage src={arrowsIcon} alt="Arrows icon" />
        </Box>

        <Column gap={5}>
          <DiagramCard label="Advertising" logos={advertistingLogos} />
          <DiagramCard label="Marketing automation" logos={marketingLogos} />
          <DiagramCard label="CRM" logos={crmLogos} />
          <DiagramCard label="Customer service" logos={customerSuccessLogos} />
        </Column>
      </Row>

      <Button
        mt={10}
        size="lg"
        variant="primary"
        onClick={onNext}
        directionIcon={ArrowRightIcon}
      >
        Get started
      </Button>
    </Step>
  );
};

const DiagramCard: FC<{ label: string; logos: Record<string, string> }> = ({
  label,
  logos,
}) => (
  <Column gap={3} justify="center" py={2}>
    <Row align="center" gap={4}>
      {Object.values(logos).map((src) => (
        <Column
          key={src}
          borderRadius="24px"
          bg="white"
          boxShadow="md"
          height="48px"
          width="48px"
          align="center"
          justify="center"
        >
          <HTImage height="25px" src={cdn(src, 50)} alt={`${label} logo`} />
        </Column>
      ))}
    </Row>
    <Text fontWeight="medium">{label}</Text>
  </Column>
);

const SelectSourceStep: FC<
  Readonly<{ onSelect: (source: SourceDefinition) => void }>
> = ({ onSelect }) => {
  const navigate = useNavigate();
  return (
    <Step
      description="Tell us where your data is stored and managed"
      title="What is your data source?"
    >
      <SourceCatalog onSelect={onSelect} />
      <SkipButton onClick={() => navigate(`/welcome/2`)} />
    </Step>
  );
};

const SelectDestinationStep: FC<
  Readonly<{ onSelect: (destination: DestinationDefinition) => void }>
> = ({ onSelect }) => {
  const navigate = useNavigate();
  return (
    <Step
      description="Tell us where you want to send your data"
      title="What is your destination?"
    >
      <DestinationCatalog onSelect={onSelect} />
      <SkipButton onClick={() => navigate(`/welcome/3`)} />
    </Step>
  );
};

const SkipButton = ({ onClick }) => (
  <Box
    as="button"
    onClick={onClick}
    _hover={{ color: "gray.600" }}
    color="gray.500"
    fontWeight="semibold"
  >
    Skip
  </Box>
);

const CreateWorkspaceStep: FC<
  Readonly<{
    sourceType: string | undefined;
    destinationType: string | undefined;
  }>
> = ({ destinationType, sourceType }) => {
  return (
    <Step title="Create your first workspace">
      <Card p={6} width="536px">
        <CreateWorkspace
          onboarding={{ sourceType, destinationType }}
          onSubmit={async ({ id, slug }) => {
            if (sourceType) {
              analytics.track("Source Declared", {
                source_type: sourceType,
              });
            }
            if (destinationType) {
              analytics.track("Destination Declared", {
                destination_type: destinationType,
              });
            }

            await switchWorkspace(id, `/${slug}/onboarding`);
          }}
        />
      </Card>
    </Step>
  );
};

const crmLogos = {
  salesforce: "e4fa4b01bbc006d21f215f7c629fb4120eaeaeb4-512x512.svg",
  salesloft: "8dcafcb6ab137e258c31b2729f222fc513d5a85b-512x512.png",
  freshsales: "d5adbaf049faf7c175c0516eb3407b3784556274-512x512.svg",
};

const advertistingLogos = {
  facebook: "a90feacc04182f95fa6daf663a2a9861b470857c-1365x1365.svg",
  twitter: "694b09db15808f6965e208a46c87bb7f27ca6770-518x518.png",
  googleAds: "f423bac1e966b640cfcbea01bfcbef9e11021f4b-1200x1089.png",
};

const customerSuccessLogos = {
  zendesk: "29158c9660b8eb9202c66c315f7a5f5bc510049f-512x512.svg",
  intercom: "ecb8820a379b770f7be348a4d598e45deac06b6d-2500x2500.svg",
  slack: "661acab40576a1acc6018d2f902e2a6186cd530e-512x512.png",
};

const marketingLogos = {
  braze: "dd757379e84ce72a0ebf486dd63659c00af4abed-512x512.svg",
  hubspot: "403a4633d60d71d0ccb5bc5be4b4ff4a7e5bfc2c-2500x2500.svg",
  iterable: "8069e1ed66b07e9047e685a6edbd1c75e433f33f-1010x1010.png",
};
