import { Box, Skeleton, SkeletonBox } from "@hightouchio/ui";
import { motion } from "framer-motion";

export const PlaceholderProgressBar = () => (
  <Skeleton isLoading={true}>
    <SkeletonBox h={1.5} width="100%" borderRadius="md" />
  </Skeleton>
);

export const MultiValueProgressBar = ({
  values,
}: {
  values: { value: number; color: string }[];
}) => (
  <Box
    backgroundColor="base.background"
    h={1.5}
    borderRadius="md"
    width="100%"
    overflow="hidden"
  >
    {values.map((value, index) => (
      <Box
        as={motion.div}
        key={index}
        width={`${value.value}%`}
        height="100%"
        backgroundColor={value.color}
        float="left"
        _first={{ borderStartRadius: "md" }}
        _last={{ borderEndRadius: "md" }}
        animate={{ width: `${value.value}%` }}
      />
    ))}
  </Box>
);
