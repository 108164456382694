import { HTImage } from "src/components/image";
import { FC, useState } from "react";

import {
  useToast,
  Column,
  Row,
  MenuActionsButton,
  MenuList,
  Menu,
  DeleteIcon,
} from "@hightouchio/ui";
import { useParams, useNavigate, Outlet } from "src/router";

import { DetailPage } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Warning } from "src/components/warning";
import { eventWarehouseDestinationDefinitions } from "src/events/types";
import {
  EventWarehouseDestinationsSetInput,
  useDeleteEventWarehouseDestinationMutation,
  useEventWarehouseDestinationQuery,
  useUpdateEventWarehouseDestinationMutation,
  useDestinationQuery,
  useDeleteDestinationMutation,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import { PageSpinner } from "src/components/loading";

import { validate as validateUUID } from "uuid";
import { OutletContext } from "./types";
import {
  PermissionedEditableDescription,
  PermissionedEditableHeading,
  PermissionedMenuItem,
} from "src/components/permission";

export const EventDestinationLayout: FC = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return null;
  }

  // Event warehouse destinations are a separate data model from other destinations, and use UUIDs,
  // while event forwarding destinations use bigint ids, and are stored in the same table as rETL destinations.
  const type = validateUUID(id) ? "warehouse" : "forwarding";

  const { data: destination, isLoading } =
    type === "forwarding"
      ? useDestinationQuery(
          { id: String(id) },
          {
            enabled: Boolean(id),
            select: (data) => data.destinations_by_pk,
          },
        )
      : useEventWarehouseDestinationQuery(
          { id: String(id) },
          {
            enabled: Boolean(id),
            select: (data) => data.event_warehouse_destinations_by_pk,
          },
        );

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!destination) {
    return (
      <Warning
        subtitle="It may have been deleted"
        title="Event destination not found"
      />
    );
  }

  return <Layout context={{ type, destination } as OutletContext} />;
};

const Layout = ({
  context: { type, destination },
}: {
  context: OutletContext;
}) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteMutationForwarding = useDeleteDestinationMutation();
  const updateMutationForwarding = useUpdateDestinationV2Mutation();

  const deleteMutationWarehouse = useDeleteEventWarehouseDestinationMutation();
  const updateMutationWarehouse = useUpdateEventWarehouseDestinationMutation();

  const update = async (input: EventWarehouseDestinationsSetInput) => {
    try {
      if (type === "forwarding") {
        await updateMutationForwarding.mutateAsync({
          id: destination.id.toString(),
          destination: input,
        });
      } else {
        await updateMutationWarehouse.mutateAsync({
          id: destination.id,
          input,
        });
      }

      toast({
        id: "update-event-destination",
        title: `Event destination was updated`,
        variant: "success",
      });
    } catch (_err) {
      toast({
        id: "update-event-destination-error",
        title: `There was a problem updating your event destination.`,
        variant: "error",
      });
    }
  };

  const iconSrc =
    type === "warehouse"
      ? eventWarehouseDestinationDefinitions[destination.type].icon
      : destination.definition.icon;

  return (
    <DetailPage
      title={destination.name || "Destination"}
      crumbs={[{ label: "All destinations", link: "/events/destinations" }]}
      hasBottomPadding
      tabDepth={5}
      tabs={[
        {
          path: "configuration",
          title: "Configuration",
          bg: "base.lightBackground",
        },
        {
          path: "sync-settings",
          title: "Schema settings",
          // Schema settings makes no sense for S3 destination, or for forwarding destinations
          isHidden: destination.type === "s3" || type === "forwarding",
        },
      ]}
      header={
        <Column minWidth={0} gap={2}>
          <Row flex={1} justify="space-between" minWidth={0} pt={1}>
            <Row gap={4} align="center">
              <HTImage
                src={iconSrc}
                width="32px"
                alt={destination?.name || ""}
              />
              <PermissionedEditableHeading
                permission={{
                  v1: { resource: "workspace", grant: "update" },
                  v2: {
                    resource: "workspace",
                    grant: "can_update",
                  },
                }}
                size="lg"
                value={destination.name ?? ""}
                onChange={(name) => {
                  update({ name });
                }}
              />
            </Row>

            <Row align="center" gap={4}>
              <Menu>
                <MenuActionsButton variant="secondary" />
                <MenuList>
                  <PermissionedMenuItem
                    permission={{
                      v1: { resource: "workspace", grant: "update" },
                      v2: {
                        resource: "workspace",
                        grant: "can_update",
                      },
                    }}
                    icon={DeleteIcon}
                    variant="danger"
                    onClick={() => {
                      setIsDeleting(true);
                    }}
                  >
                    Delete
                  </PermissionedMenuItem>
                </MenuList>
              </Menu>
            </Row>
          </Row>
          {type === "warehouse" && (
            <PermissionedEditableDescription
              permission={{
                v1: { resource: "workspace", grant: "update" },
                v2: {
                  resource: "workspace",
                  grant: "can_update",
                },
              }}
              value={destination.description || ""}
              onChange={(description) => {
                update({ description });
              }}
            />
          )}
        </Column>
      }
    >
      <Outlet context={{ type, destination }} />

      <DeleteConfirmationModal
        isOpen={isDeleting}
        label="destination"
        onClose={() => {
          setIsDeleting(false);
        }}
        onDelete={async () => {
          if (type === "warehouse") {
            await deleteMutationWarehouse.mutateAsync({ id: destination.id });
          } else {
            await deleteMutationForwarding.mutateAsync({
              id: destination.id,
              stringId: destination.id.toString(),
            });
          }
          navigate("/events/destinations");
        }}
      />
    </DetailPage>
  );
};
