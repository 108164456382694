import { V2ResourceToPermission } from "src/components/permission/types-v2";
import { ResourcePermissionInput } from "src/components/permission/use-resource-permission";
import { ResourceToPermission } from "src/graphql";

export const PERMISSION_UNAUTHORIZED_TOOLTIP =
  "You do not have permission to perform this action";

export const getCreateJourneyPermission = (
  parentModelId: string | undefined,
): ResourcePermissionInput<V2ResourceToPermission, ResourceToPermission> => ({
  v1: { resource: "audience", grant: "create" },
  v2: {
    // For now use audience can create permission to create until journeys
    // becomes a first class resource
    resource: "model",
    grant: "can_create",
    creationOptions: {
      type: "audience",
      // These are actually numbers!! bigint problem
      parentModelId: parentModelId?.toString() ?? "",
    },
  },
});

export const getUpdateJourneyPermission = (
  parentModelId: string | undefined,
): ResourcePermissionInput<V2ResourceToPermission, ResourceToPermission> => ({
  v1: { resource: "audience", grant: "create" },
  // For now use audience can create permission to update until journeys
  // becomes a first class resource
  v2: {
    resource: "model",
    grant: "can_create",
    creationOptions: {
      type: "audience",
      // These are actually numbers!! bigint problem
      parentModelId: parentModelId?.toString() ?? "",
    },
  },
});

export const getDeleteJourneyPermission = (
  parentModelId: string | undefined,
): ResourcePermissionInput<V2ResourceToPermission, ResourceToPermission> => ({
  v1: { resource: "audience", grant: "delete" },
  // For now use audience can create permission to delete until journeys
  // becomes a first class resource
  v2: {
    resource: "model",
    grant: "can_delete",
    // These are actually numbers!! bigint problem
    id: parentModelId?.toString() ?? "",
  },
});
