import { FC, useEffect, useMemo } from "react";

import {
  TraitCondition,
  TraitConfig,
  TraitType,
} from "@hightouch/lib/query/visual/types";
import {
  Column,
  Combobox,
  FormField,
  Spinner,
  Text,
  TextInput,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { traitNameValidator } from "src/components/traits/utils";
import * as Yup from "yup";

import { CalculationSummary } from "src/components/traits/calculation-summary";
import { PreviewTrait } from "src/components/traits/preview-trait";
import { TraitConditions } from "src/components/traits/trait-conditions";
import { useFilterableColumnsQuery, useTraitsQuery } from "src/graphql";
import { useHydrateTraitsWithFilterableAudienceColumns } from "src/hooks/use-fetch-traits-with-filterable-audience-columns";
import { TraitParentModel } from "src/components/audiences/types";

type Props = {
  parentModel: TraitParentModel;
  editConditions?: boolean;
};

export const traitEnrichmentValidationSchema = (
  connectionSourceType: string | undefined,
) =>
  Yup.object()
    .shape({
      name: traitNameValidator(connectionSourceType),
      type: Yup.string().required("Type is required"),
      template_id: Yup.string().required("Please select a template"),
      relationship_id: Yup.number().when("type", {
        is: (value) => value === TraitType.Formula,

        then: Yup.number().nullable(),
        else: Yup.number().required("Please select a related model or event"),
      }),
      conditions: Yup.array(),
    })
    .required();

export type TraitEnrichmentFormProps = {
  name: string;
  type: TraitType;
  template_id: string;
  relationship_id: string | undefined; // Formula traits don't have a relationship
  conditions: TraitCondition[];
};

export const TraitEnrichmentForm: FC<Readonly<Props>> = ({
  parentModel,
  editConditions,
}) => {
  const traitTemplatesQuery = useTraitsQuery({
    filter: {
      is_template: { _eq: true },
      archived_at: { _is_null: true },
      parent_model_id: { _eq: parentModel?.id },
    },
  });

  const templateOptions = (
    traitTemplatesQuery.data?.trait_definitions ?? []
  ).map((template) => ({
    label: template.name,
    description: template.description,
    value: template.id,
  }));

  const {
    traitsWithFilterableColumns,
    isLoading: areFilterableColumnsLoading,
  } = useHydrateTraitsWithFilterableAudienceColumns(parentModel.traits);

  const parentModelWithFilterableTraitColumns = useMemo(() => {
    return {
      ...parentModel,
      traits: traitsWithFilterableColumns,
    };
  }, [parentModel, traitsWithFilterableColumns]);

  const { control, watch, setValue } = useFormContext();

  const templateId = watch("template_id");
  const template = traitTemplatesQuery?.data?.trait_definitions.find(
    (template) => template.id === templateId,
  );

  const type = template?.type as TraitType;

  const relationship = parentModel?.relationships.find(
    (relationship) => relationship.id === template?.relationship?.id,
  );

  const modelFilterableColumns = useFilterableColumnsQuery(
    {
      segmentId: relationship?.to_model.id,
    },
    {
      enabled: Boolean(relationship),
      select: (data) => data.segments_by_pk?.filterable_audience_columns,
    },
  );

  const relationshipWithFilterableColumns = useMemo(() => {
    if (!relationship) {
      return null;
    }

    return {
      to_model: {
        id: relationship.to_model.id,
        filterable_audience_columns: modelFilterableColumns.data ?? [],
      },
    };
  }, [relationship, modelFilterableColumns.data]);

  const name = watch("name");

  const conditions = watch("conditions");

  useEffect(() => {
    setValue("type", type);
  }, [setValue, type]);

  useEffect(() => {
    setValue("relationship_id", relationship?.id);
  }, [setValue, relationship?.id]);

  return (
    <>
      <Column>
        <Text fontWeight="medium">Sync a trait value to a destination</Text>
        <Text>
          An audience trait enrichment allows you to craft a trait value and
          sync it scoped specifically to this audience only.
        </Text>
      </Column>

      <Column gap={2}>
        <Controller
          control={control}
          name="template_id"
          render={({ field, fieldState }) => (
            <FormField
              label="Choose a template"
              error={fieldState.error?.message}
            >
              <Combobox
                options={templateOptions}
                isLoading={modelFilterableColumns.isLoading}
                placeholder="Select a template"
                width="100%"
                {...field}
              />
            </FormField>
          )}
        />
        {template && (
          <Column
            borderRadius="6px"
            border="1px solid"
            borderColor="base.border"
            p={4}
            gap={4}
          >
            {areFilterableColumnsLoading ? (
              <Spinner />
            ) : (
              <CalculationSummary
                parentModel={parentModelWithFilterableTraitColumns}
                relatedModel={template?.relationship?.to_model}
                type={template.type as TraitType}
                config={template.config as TraitConfig}
              />
            )}
            {editConditions && relationship && (
              <TraitConditions
                isTraitEnrichment
                isLoading={areFilterableColumnsLoading}
                parentModel={parentModelWithFilterableTraitColumns}
                relationship={relationshipWithFilterableColumns}
              />
            )}
          </Column>
        )}
      </Column>

      <Controller
        control={control}
        name="name"
        render={({ field, fieldState }) => (
          <FormField label="Name" error={fieldState.error?.message}>
            <TextInput placeholder="Enter a name" width="100%" {...field} />
          </FormField>
        )}
      />

      {parentModel && (
        <PreviewTrait
          isLoading={areFilterableColumnsLoading}
          isDisabled={!parentModel || !template}
          parentModel={parentModelWithFilterableTraitColumns}
          trait={{
            name,
            type: template?.type as TraitType,
            config: template?.config as TraitConfig,
            conditions: conditions ?? [],
            relationshipId: relationship?.id,
          }}
        />
      )}
    </>
  );
};
