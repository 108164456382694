import {
  DecisionEngineFlowRunStatus,
  DecisionEngineStatus,
} from "@hightouch/lib/customer-data/decision-engine/types";
import { useDecisionEngineFlowRunQuery } from "src/graphql";
import { Flow } from ".";

export const useFlowTimestamps = ({
  flow,
}: {
  flow: Pick<Flow, "id" | "status" | "config">;
}): {
  flowStartDate: string | null;
  lastFlowRunStartedAt: string | null;
  isLoading: boolean;
} => {
  const { data: lastFlowRun, isLoading: isLastFlowRunLoading } =
    useDecisionEngineFlowRunQuery(
      {
        flowId: flow.id,
        orderBy: { started_at: "desc" },
        // Want to explicitly filter for last successful flow run since we can assume
        // that's when we last cached the recent analytics data
        filters: {
          status: { _eq: DecisionEngineFlowRunStatus.Success },
        },
      },
      {
        enabled: flow.status === DecisionEngineStatus.READY,
        select: (data) => data.decision_engine_flow_runs[0],
      },
    );

  const flowStartDate = (flow.config.flow_start_date ?? null) as string | null;

  const { data: firstFlowRun, isLoading: isFirstFlowRunLoading } =
    useDecisionEngineFlowRunQuery(
      {
        flowId: flow.id,
        orderBy: { started_at: "asc" },
      },
      {
        // If no specified flow start date, use the first flow run started_at
        enabled: flow.status === DecisionEngineStatus.READY && !flowStartDate,
        select: (data) => data.decision_engine_flow_runs[0],
      },
    );

  return {
    flowStartDate: flowStartDate ?? firstFlowRun?.started_at ?? null,
    // Want to reference the last flow run started_at as a reference point
    // since we cache analytic queries at the end of the decision engine flow run
    lastFlowRunStartedAt: lastFlowRun?.started_at ?? null,
    isLoading: isFirstFlowRunLoading || isLastFlowRunLoading,
  };
};
