import { useEffect, useState } from "react";

import { Action, useKBar, useRegisterActions } from "kbar";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useDebounce } from "use-debounce";

import { useNavigate } from "src/router";
import { useUser } from "src/contexts/user-context";
import { useSearchWorkspacesQuery } from "src/graphql";
import {
  AudienceIcon,
  DestinationIcon,
  DocsIcon,
  ModelIcon,
  SettingSolidIcon,
  SourceIcon,
  SyncIcon,
  UsersIcon,
} from "src/ui/icons";
import { switchWorkspace } from "src/utils/workspaces";
import { useCommandBar } from "src/components/commandbar/context";

const isNumeric = (value: string) => {
  return /^-?\d+$/.test(value);
};

/**
 * Add dynamic Workspace options to the CommandBar
 */
function useSwitchWorkspaceActions() {
  const { user } = useUser();
  const { setIsFetching } = useCommandBar();

  // Register root action.
  useRegisterActions(
    user
      ? [
          {
            id: "workspace.switch",
            section: {
              name: "User",
              priority: 5,
            },
            name: "Switch workspace",
            keywords: "workspace",
            icon: UsersIcon as any,
            shortcut: ["s", "w"],
          },
        ]
      : [],
    [user],
  );

  // Subscribe to search query.
  const { search, currentRootActionId } = useKBar((state) => ({
    search: state.searchQuery,
    currentRootActionId: state.currentRootActionId,
  }));

  const [_searchTerm, setSearchTerm] = useState("");

  const [searchTerm, { isPending: isSearchPending }] = useDebounce(
    _searchTerm,
    500,
  );

  useEffect(() => {
    if (currentRootActionId === "workspace.switch") {
      setSearchTerm(search);
    }
  }, [search, currentRootActionId]);

  const { data: workspaceData, isLoading } = useSearchWorkspacesQuery(
    {
      query: `%${searchTerm}%`,
      limit: 20,
      id: searchTerm,
      isIdSearch: isNumeric(searchTerm),
    },
    {
      meta: { isAdmin: user?.can_impersonate },
      enabled: Boolean(user),
      select: (data) => [
        // prioritize searches by ID since they are more specific.
        ...(data.workspacesById || []),
        ...(data.workspaces || []),
      ],
    },
  );

  const isFetching = isLoading || isSearchPending();

  useEffect(() => setIsFetching(isFetching), [isFetching]);

  // Set to empty list if we are still loading or debouncing,
  // otherwise the command bar will try to do some in-memory filtering,
  // which looks weird as the query is loading.
  const workspaces = isFetching ? [] : (workspaceData ?? []);

  // Dynamically register workspace options.
  useRegisterActions(
    user
      ? workspaces.map((w) => {
          // XXX: This doesn't address the case where users are directly in a workspace
          const cannotAccessWorkspace =
            user.can_impersonate &&
            w.organization?.allow_support_access === false;
          const subtitle = cannotAccessWorkspace
            ? "Support access disabled"
            : `${w.slug} ${
                w.organization?.name ? `(${w.organization?.name})` : ""
              }`;
          return {
            id: `workspace.switch.${w.id}`,
            section: "Workspace",
            parent: "workspace.switch",
            name: `${w.name} (${w.id}) with ${
              w.all_memberships_aggregate.aggregate?.count || 0
            } members`,
            subtitle,
            keywords: [w.id, w.name, w.slug].join(" "),
            perform: () => {
              if (cannotAccessWorkspace) {
                return;
              }
              switchWorkspace(w.id);
            },
          };
        })
      : [],
    [user, workspaces],
  );
}

function useDocsActions() {
  useRegisterActions(
    [
      {
        id: "docs",
        section: "Docs",
        name: "Docs",
        keywords: "docs documentation",
        icon: DocsIcon as any,
        perform: () =>
          (window.location.href = import.meta.env.VITE_DOCS_URL as string),
      },
    ],
    [],
  );
}

export function useWorkspaceActions() {
  // Mount routes to CommandBar
  const actions: Action[] = [];

  const navigate = useNavigate();
  const { user, workspace } = useUser();
  const { audiencesEnabled } = useFlags();

  useDocsActions();
  useSwitchWorkspaceActions();

  if (user) {
    if (workspace) {
      // Nav actions.
      actions.push(
        ...[
          /* Sync Actions */
          {
            id: "syncs",
            section: {
              name: "Workspace",
              priority: 10,
            },
            name: "Syncs",
            keywords: "syncs",
            icon: SyncIcon as any,
            perform: () => navigate("/syncs"),
          },
          {
            parent: "syncs",
            section: "Workspace",
            id: "syncs.new",
            name: "New sync",
            keywords: "syncs new add create",
            perform: () => navigate("/syncs/new"),
          },

          /* Model Actions */
          {
            id: "models",
            section: "Workspace",
            name: "Models",
            keywords: "models",
            icon: ModelIcon as any,
            perform: () => navigate("/models"),
          },
          {
            parent: "models",
            section: "Workspace",
            id: "models.new",
            name: "New model",
            keywords: "models new add create",
            perform: () => navigate("/models/new"),
          },

          ...(audiencesEnabled
            ? [
                {
                  id: "audiences",
                  section: "Workspace",
                  name: "Audiences",
                  keywords: "audiences",
                  icon: AudienceIcon as any,
                  perform: () => navigate("/audiences"),
                },
                {
                  id: "audiences.new",
                  parent: "audiences",
                  section: "Workspace",
                  name: "New audience",
                  keywords: "audiences new add create",
                  perform: () => navigate("/audiences/new"),
                },
              ]
            : []),

          /* Source Actions */
          {
            id: "sources",
            section: "Workspace",
            name: "Sources",
            keywords: "sources",
            icon: SourceIcon as any,
            perform: () => navigate("/sources"),
          },
          {
            parent: "sources",
            section: "Workspace",
            id: "sources.new",
            name: "New source",
            keywords: "sources new add create",
            perform: () => navigate("/sources/new"),
          },

          /* Destination Actions */
          {
            id: "destinations",
            section: "Workspace",
            name: "Destinations",
            keywords: "destinations",
            icon: DestinationIcon as any,
            perform: () => navigate("/destinations"),
          },
          {
            section: "Workspace",
            parent: "destinations",
            id: "destinations.new",
            name: "New destination",
            keywords: "destinations new add create",
            perform: () => navigate("/destinations/new"),
          },

          /* Settings */
          {
            id: "settings",
            section: "Workspace",
            name: "Settings",
            keywords: "settings",
            icon: SettingSolidIcon as any,
            perform: () => navigate("/settings"),
          },
          ...[
            { id: "workspace", name: "Workspace", page: "/workspace" },
            { id: "members", name: "Members", page: "/members" },
            { id: "integrations", name: "Integrations", page: "/integrations" },
            { id: "alerts", name: "Alerts", page: "/alerts" },
            { id: "api-keys", name: "API Keys", page: "/api-keys" },
            { id: "storage", name: "Storage", page: "/storage" },
            { id: "tunnels", name: "Tunnels", page: "/tunnels" },
            { id: "git-sync", name: "Git sync", page: "/git-sync" },
          ].map(({ id, name, page }) => ({
            parent: "settings",
            id: `settings.${id}`,
            section: "Workspace",
            name: name,
            keywords: `settings ${id}`,
            perform: () => navigate(`/settings${page}`),
          })),
        ].filter((a) => !!a),
      );
    }
  }
  useRegisterActions(actions, [user, workspace, actions, audiencesEnabled]);
}
