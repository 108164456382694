import { FC, useMemo, useState } from "react";

import {
  CloseIcon,
  Column,
  DragIcon,
  FilterIcon,
  IconButton,
  Badge,
  PlusIcon,
  Row,
  SectionHeading,
  Select,
  Tooltip,
} from "@hightouchio/ui";
import { Reorder as Framer, useDragControls } from "framer-motion";
import uniqBy from "lodash/uniqBy";
import { useFieldArray, useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { EventColumn } from "src/components/explore/filter-popover/constants";
import { updateConditionAtIndex } from "src/components/explore/utils/condition-builders";
import { IconBox } from "src/components/icon-box";
import * as analytics from "src/lib/analytics";
import { useAnalyticsContext } from "src/pages/analytics/state";
import { ChartFormState, FunnelStep } from "src/pages/analytics/types";
import {
  AndOrCondition,
  ConditionType,
  PropertyCondition,
  initialPropertyCondition,
} from "src/types/visual";
import { toSingleCondition } from "src/components/audiences/utils";

import { ConversionCriteria } from "./conversion-criteria";
import { FilterEvents } from "./filter-events";

type StepProps = {
  index: number;
  value: FunnelStep;
  onChange: (value: FunnelStep) => void;
  onRemove?: () => void;
  onSetMovingItem?: (id: string | null) => void;
};

const EventIcon = () => (
  <IconBox
    bg={EventColumn.color}
    boxSize="20px"
    icon={EventColumn.icon}
    iconSize="14px"
  />
);

const Step: FC<StepProps> = ({
  index,
  value,
  onChange,
  onRemove,
  onSetMovingItem,
}) => {
  const [dragging, setDragging] = useState(false);
  const [hovered, setHovered] = useState(false);
  const controls = useDragControls();

  const { events, parent, parentModelLoading } = useAnalyticsContext();
  const stepOptions = events.map(({ id, to_model }) => ({
    eventModelId: to_model.id,
    label: to_model.name,
    relationshipId: id,
  }));

  // Conditions will always be wrapped in an and/or condition.
  let propertyConditions: PropertyCondition[] = [];

  if (value.subconditions?.[0]?.type === ConditionType.And) {
    // Pulling subconditions out doesn't resolve the type, since it is recursive.
    propertyConditions = value.subconditions?.[0]
      ?.conditions as PropertyCondition[];
  }

  const event = useMemo(
    () =>
      value.eventModelId
        ? events.find(
            ({ to_model }) =>
              to_model.id.toString() === value.eventModelId!.toString(),
          )
        : null,
    [value.eventModelId, events],
  );
  const eventModelColumns = event
    ? uniqBy(event.to_model.filterable_audience_columns, "name")
    : [];

  const eventModelFilterColumns = eventModelColumns.map((col) => ({
    groupLabel: col.model_name + " properties",
    name: col.alias || col.name,
    type: col.custom_type || col.type,
    columnReference: col.column_reference,
  }));

  const changeStep = ({
    eventModelId,
    relationshipId,
  }: Pick<FunnelStep, "eventModelId" | "relationshipId">) => {
    onChange({ id: value.id, eventModelId, relationshipId, subconditions: [] });
  };

  const addCondition = () => {
    onChange({
      ...value,
      subconditions: propertyConditions.concat([initialPropertyCondition]),
    });
  };

  const updateSubconditions = (
    index: number,
    updates: Partial<PropertyCondition>,
  ) => {
    const updatedConditions = updateConditionAtIndex(
      index,
      propertyConditions,
      updates,
    );

    onChange({ ...value, subconditions: updatedConditions });
  };

  const removeCondition = (index: number) => {
    onChange({
      ...value,
      subconditions: propertyConditions.filter((_, i) => i !== index),
    });
  };

  return (
    <Framer.Item
      dragListener={false}
      dragControls={controls}
      style={{
        borderRadius: "var(--chakra-radii-md)",
        overflow: "hidden",
      }}
      value={value}
      onDragEnd={() => {
        setDragging(false);
        onSetMovingItem?.(null);
      }}
      onDragStart={() => {
        setDragging(true);
        onSetMovingItem?.(value.id);
      }}
      whileDrag={{
        backgroundColor: "rgba(255,255,255,1)",
        boxShadow:
          "0px 12px 16px rgba(16, 24, 40, 0.16), 0px 8px 16px rgba(16, 24, 40, 0.16), 0px 0px 12px rgba(16, 24, 40, 0.08)",
      }}
      // Remove animation by setting transition to 0 and
      // dampening the drag transition
      transition={{ duration: 0, ease: "linear" }}
      dragTransition={{ bounceStiffness: 2000, bounceDamping: 10000 }}
    >
      <Column
        bg="white"
        border="1px solid"
        borderColor="base.border"
        borderRadius="md"
        boxShadow="sm"
      >
        {/* TODO: update styling to not overflow box on long event names */}
        <Row
          alignItems="center"
          height="48px"
          px={2}
          gap={2}
          maxWidth="100%"
          onMouseLeave={() => setHovered(false)}
        >
          <Row
            align="center"
            justify="center"
            flexShrink={0}
            width="32px"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {hovered ? (
              <Row
                align="center"
                role="handle"
                className="handle"
                color="base.border"
                cursor={dragging ? "grabbing" : "grab"}
                fontSize="24px"
                onPointerDown={(e) => controls.start(e)}
              >
                <DragIcon />
              </Row>
            ) : (
              <Badge>{index + 1}</Badge>
            )}
          </Row>
          <Row
            overflow="hidden"
            width="100%"
            sx={{ "> button": { overflow: "hidden" } }}
          >
            <Select
              optionAccessory={() => ({
                type: "icon",
                icon: EventIcon,
              })}
              optionValue={({ relationshipId, eventModelId }) => ({
                relationshipId,
                eventModelId,
              })}
              options={stepOptions}
              placeholder="Select an event..."
              width="100%"
              value={{
                relationshipId: value.relationshipId,
                eventModelId: value.eventModelId,
              }}
              onChange={(event) => event && changeStep(event)}
            />
          </Row>
          {value.eventModelId && propertyConditions.length === 0 && (
            <Row>
              <Tooltip message="Add filter">
                <IconButton
                  aria-label="Add filter"
                  icon={FilterIcon}
                  onClick={addCondition}
                />
              </Tooltip>
            </Row>
          )}
          {onRemove && (
            <Row>
              <Tooltip message="Remove step">
                <IconButton
                  aria-label="Remove step."
                  icon={CloseIcon}
                  onClick={onRemove}
                />
              </Tooltip>
            </Row>
          )}
        </Row>
        {propertyConditions.length > 0 && (
          <FilterEvents
            isLoading={parentModelLoading}
            options={eventModelFilterColumns}
            parent={parent}
            subconditions={propertyConditions}
            onChange={(conditionIndex, value) =>
              updateSubconditions(conditionIndex, value)
            }
            onAddSubcondition={addCondition}
            onRemoveSubcondition={removeCondition}
          />
        )}
      </Column>
    </Framer.Item>
  );
};

export const Steps: FC = () => {
  const form = useFormContext<ChartFormState>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Suppress circular reference error
  const { fields, update, remove, append, move } = useFieldArray({
    control: form.control,
    name: "funnelSteps",
  });

  const [movedItemId, setMovedItemId] = useState<string | null>(null);

  const updateStep = (value: FunnelStep, index: number) => {
    analytics.track("Selected Funnel Step", {
      event_model_id: value.eventModelId,
      relationship_id: value.relationshipId,
    });

    let subconditions = value.subconditions;
    if (subconditions) {
      subconditions = toSingleCondition(
        value.subconditions,
      ) as AndOrCondition<PropertyCondition>[];
    }

    update(index, { ...value, subconditions });
  };

  const reorderSteps = (updatedMergeRules: FunnelStep[]) => {
    const startIndex = fields.findIndex((step) => step.id === movedItemId);
    const destinationIndex = updatedMergeRules.findIndex(
      (rule) => rule.id === movedItemId,
    );
    move(startIndex, destinationIndex);
  };

  return (
    <Column gap={2}>
      <Column gap={2}>
        <Row align="center" justify="space-between">
          <SectionHeading>Steps</SectionHeading>
          <Tooltip message="Add step">
            <IconButton
              icon={PlusIcon}
              aria-label="Add funnel step input"
              onClick={() => {
                append({
                  id: uuidv4(),
                  eventModelId: "",
                  relationshipId: "",
                  subconditions: [],
                });
              }}
            />
          </Tooltip>
        </Row>
      </Column>

      <Column flex={1} minWidth={0}>
        <Framer.Group
          axis="y"
          layoutScroll
          style={{
            listStyle: "none",
            paddingLeft: 0,
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
          values={fields}
          onReorder={reorderSteps}
        >
          {fields.map((value, index) => (
            <Step
              key={value.id}
              value={value}
              index={index}
              onSetMovingItem={setMovedItemId}
              onChange={(step) => updateStep(step, index)}
              onRemove={fields.length > 1 ? () => remove(index) : undefined}
            />
          ))}
        </Framer.Group>
      </Column>

      <ConversionCriteria />
    </Column>
  );
};
