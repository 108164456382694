import { Column, DrawerBody, DrawerFooter, Heading } from "@hightouchio/ui";
import { useMemo } from "react";
import { useOutletContext } from "src/router";

import { Card } from "src/components/card";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { useUpdateDecisionEngineMessageAndCollectionsMutation } from "src/graphql";
import { CollectionSelect } from "src/pages/decision-engines/flows/flow/messages/message/components/collection-select";
import { Variables } from "src/pages/decision-engines/flows/flow/messages/message/components/variables";
import { getKeyValueArray, getKeyValueObject } from "src/utils/key-value";
import { FlowMessageContext } from ".";
import { MessageCampaign } from "./components/campaign";
import { CampaignAttribution } from "./components/campaign-attribution";
import { Tags } from "./components/tags";
import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";

export const MessageConfiguration = () => {
  const { flowMessage, engine, flow } = useOutletContext<FlowMessageContext>();

  const currentCollections = useMemo(
    () =>
      flowMessage.message.collections.map((c) => {
        return {
          id: c.decision_engine_collection.id,
          enabled: true,
          item_count: c.item_count,
        };
      }),
    [flowMessage.message.collections],
  );

  const updateMutation = useUpdateDecisionEngineMessageAndCollectionsMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      const collectionsToUpsert = data.collections
        .filter((c) => c.enabled)
        .map((c) => ({
          decision_engine_message_id: flowMessage.message.id,
          decision_engine_collection_id: c.id,
          item_count: c.item_count,
        }));
      await updateMutation.mutateAsync({
        flowId: flow.id,
        messageId: flowMessage.message.id,
        flowMessage: {
          config: data.flowMessageConfig,
        },
        message: {
          config: data.config,
          variables: data.variables,
          tags: getKeyValueObject(data.tags),
        },
        collectionsToUpsertIds: collectionsToUpsert.map(
          (c) => c.decision_engine_collection_id,
        ),
        collectionsToUpsert,
      });
    },
    values: {
      tags: getKeyValueArray(flowMessage.message.tags),
      flowMessageConfig: flowMessage.config,
      config: flowMessage.message.config,
      variables: flowMessage.message.variables,
      collections:
        engine.collections?.map((collection) => {
          const existingCollection = currentCollections.find(
            (c) => c.id === collection.id,
          );
          return {
            id: collection.id,
            name: collection.collection.name,
            enabled: !!existingCollection,
            item_count: existingCollection?.item_count ?? 1,
          };
        }) ?? [],
    },
  });

  return (
    <Form form={form}>
      <DrawerBody bg="gray.50">
        <Column flex={1} gap={6} pb={6} maxW="2xl" mx="auto">
          <Heading>Dynamic content</Heading>
          {flowMessage.enabled || flowMessage.model?.syncs.length ? (
            <Card>
              <MessageCampaign channel={flowMessage.message.channel} />
            </Card>
          ) : null}
          <Card>
            <Variables />
          </Card>
          {engine.collections.length > 0 && (
            <Card>
              <CollectionSelect />
            </Card>
          )}
          <Card>
            <Tags />
          </Card>
          <Card>
            <CampaignAttribution />
          </Card>
        </Column>
      </DrawerBody>
      <DrawerFooter>
        <FormActions
          confirmation={
            flow.status === DecisionEngineStatus.READY &&
            flow.enabled &&
            flowMessage.enabled
              ? {
                  title: "Are you sure?",
                  message:
                    "This action is used as part of a currently running agent. Any changes to this configuration will likely require changes to any downstream templates using this information.",
                }
              : undefined
          }
        />
      </DrawerFooter>
    </Form>
  );
};
