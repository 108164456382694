import {
  formatDistanceStrict,
  format,
  parseISO,
  intervalToDuration,
  formatDuration as dateFnsFormatDuration,
  type Duration,
  differenceInMinutes,
  isSameDay,
  isSameYear,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";

export const diff = (
  start: string | number,
  end: string | number,
  {
    units = "long",
  }: Partial<{
    units: DurationUnits;
  }> = {},
): string | undefined => {
  if (start && end) {
    const distance = formatDistanceStrict(new Date(end), new Date(start), {
      roundingMethod: "floor",
    });

    return units === "short" ? convertToShortUnits(distance) : distance;
  }

  return undefined;
};

type DurationUnits = "short" | "long";
type DurationGranularity = "minute" | "second";

type DurationOptions = {
  units?: DurationUnits;
  granularity?: DurationGranularity;
};

// Helper for formatting durations, especially useful for durations that may be very long (eg. sync run times).
// >= 1 day: 1d 2h 3m
// >= 1 hour: 1h 2m
// >= 1 minute: 1m
// <1 minute: <1m
export const formatDuration = (
  { start, end }: { start: string | number; end: string | number },
  { units = "long", granularity = "minute" }: Partial<DurationOptions> = {},
): string | undefined => {
  if (start === "" || end === "") return undefined;

  const duration = intervalToDuration({ start, end });

  const formatted = isBelowGranularity(duration, granularity)
    ? granularity === "minute"
      ? "<1 minute"
      : "<1 second"
    : dateFnsFormatDuration(truncateLongDuration(duration, granularity), {
        zero: true,
      });

  return units === "short" ? convertToShortUnits(formatted) : formatted;
};

const isBelowGranularity = (
  duration: Duration,
  granularity: DurationGranularity,
) =>
  !duration.years &&
  !duration.months &&
  !duration.weeks &&
  !duration.days &&
  !duration.hours &&
  !duration.minutes &&
  (granularity === "minute" || !duration.seconds || duration.seconds < 0);

// Cleans up long durations to specific units depending on the biggest units.
// Also adds zeros to units we want to show even if they're 0.
const truncateLongDuration = (
  duration: Duration,
  granularity: DurationGranularity,
): Duration => {
  if (duration.days) {
    return {
      days: duration.days,
      hours: duration.hours ?? 0,
      minutes: duration.minutes ?? 0,
      seconds: granularity === "second" ? (duration.seconds ?? 0) : undefined,
    };
  }

  if (duration.hours) {
    return {
      hours: duration.hours,
      minutes: duration.minutes ?? 0,
      seconds: granularity === "second" ? (duration.seconds ?? 0) : undefined,
    };
  }

  if (duration.minutes) {
    return {
      minutes: duration.minutes,
      seconds: granularity === "second" ? (duration.seconds ?? 0) : undefined,
    };
  }

  if (duration.seconds && granularity === "second") {
    return {
      seconds: duration.seconds,
    };
  }

  // Undefined if duration doesn't fit patterns above,
  // just return the original duration.
  return duration;
};

const convertToShortUnits = (formatted: string): string =>
  formatted
    .replace(/\s+day(s?)\b/g, "d")
    .replace(/\s+hour(s?)\b/g, "h")
    .replace(/\s+minute(s?)\b/g, "m")
    .replace(/\s+second(s?)\b/g, "s");

export const formatDatetime = (
  timestamp: string,
  pattern = "MM/dd/yy 'at' p",
) => {
  try {
    return format(parseISO(timestamp), pattern);
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export const formatDate = (timestamp: string) => {
  try {
    return format(parseISO(timestamp), "MM/dd/yy");
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export const formatTime = (timestamp: string) => {
  try {
    return format(parseISO(timestamp), "p");
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export const formatDateOrDatetime = (
  timestamp: string,
  includeTime: boolean,
) => {
  return includeTime ? formatDatetime(timestamp) : formatDate(timestamp);
};

export const formatUTC = (timestamp: number) => {
  try {
    return format(toZonedTime(timestamp, "UTC"), "MM/dd/yy 'at' p");
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export const formatFriendlyDistanceToNow = (
  timestampString: string,
  nowString?: string,
) => {
  try {
    const timestamp = parseISO(timestampString);
    const now = nowString ? parseISO(nowString) : new Date();

    return differenceInMinutes(now, timestamp) < 1 &&
      now.getTime() >= timestamp.getTime()
      ? "Just now"
      : formatDistanceStrict(timestamp, now, {
          addSuffix: true,
        });
  } catch (e) {
    console.warn(e);
    return "";
  }
};

export const formatFriendlyDay = (
  timestampString: string,
  nowString?: string,
) => {
  try {
    const timestamp = parseISO(timestampString);
    const now = nowString ? parseISO(nowString) : new Date();

    if (isSameDay(now, timestamp)) {
      return "Today";
    }

    if (isSameYear(now, timestamp)) {
      return format(timestamp, "MMMM d");
    }

    return format(timestamp, "MMMM d, yyyy");
  } catch (e) {
    console.warn(e);
    return "";
  }
};
