import {
  CloseIcon,
  EditableHeading,
  IconButton,
  Row,
  Spinner,
  useToast,
  MenuActionsButton,
  MenuList,
  Menu,
  Tooltip,
  ButtonGroup,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { FC } from "react";

import {
  Navigate,
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from "src/router";
import { Drawer } from "src/components/drawer";
import {
  CollectionQuery,
  useCollectionQuery,
  useDeleteCollectionMutation,
  useUpdateCollectionNameMutation,
} from "src/graphql";
import { OutletContext } from "src/pages/decision-engines";
import catalogIcon from "src/pages/schema/assets/catalog.svg";
import { DeleteButton } from "src/components/delete-button";
import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import { LinkWithTooltip } from "src/components/link-with-tooltip";
import { TabDrawerHeader } from "src/components/drawer/tab-drawer-header";
import { HTImage } from "src/components/image";

export const Collection = () => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const navigate = useNavigate();

  const onClose = () => {
    navigate("/ai/collections");
  };

  const { data: collection, isLoading } = useCollectionQuery(
    {
      id: collectionId ?? "",
    },
    { select: (data) => data.decision_engine_collections_by_pk },
  );

  if (!isLoading && !collection) {
    return <Navigate to="/ai/collections" />;
  }

  return (
    <Drawer isOpen onClose={onClose} size="xl">
      {isLoading ? (
        <Spinner size="lg" m="auto" />
      ) : (
        <Content collection={collection!} />
      )}
    </Drawer>
  );
};

const Content: FC<
  Readonly<{
    collection: NonNullable<
      CollectionQuery["decision_engine_collections_by_pk"]
    >;
  }>
> = ({ collection }) => {
  const { toast } = useToast();
  const context = useOutletContext<OutletContext>();
  const navigate = useNavigate();

  const updateCollectionName = useUpdateCollectionNameMutation();
  const deleteMutation = useDeleteCollectionMutation();

  const onClose = () => {
    navigate("/ai/collections");
  };

  const updateName = async (name: string) => {
    try {
      await updateCollectionName.mutateAsync({
        catalogCollectionId: collection.collection.id,
        name,
      });
      toast({
        id: "update-name",
        title: "Collection name updated",
        variant: "success",
      });
    } catch (e) {
      captureException(e);
      toast({
        id: "update-name",
        title: "Collection name failed to update",
        variant: "error",
      });
    }
  };

  const isDisabled = context.engine.status !== DecisionEngineStatus.PENDING;

  return (
    <>
      <TabDrawerHeader
        depth={5}
        tabs={[
          {
            path: "eligibility",
            title: "Eligibility",
          },
          {
            path: "configuration",
            title: "Configuration",
          },
        ]}
      >
        <Row alignItems="center" justifyContent="space-between">
          <EditableHeading
            value={collection.collection.name}
            onChange={updateName}
          />
          <ButtonGroup>
            <Menu>
              <MenuActionsButton />
              <MenuList>
                <Tooltip
                  message="Please contact us to delete a collection on an initialized engine"
                  isDisabled={!isDisabled}
                >
                  <DeleteButton
                    isDisabled={isDisabled}
                    label="collection"
                    onDelete={() =>
                      deleteMutation.mutateAsync({
                        catalogCollectionId: collection.collection.id,
                      })
                    }
                    onSuccess={onClose}
                  />
                </Tooltip>
              </MenuList>
            </Menu>
            <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
          </ButtonGroup>
        </Row>
        <Row align="center" gap={2} overflow="hidden">
          <HTImage src={catalogIcon} width="20px" alt="catalog" />
          <LinkWithTooltip
            fontWeight="medium"
            href={`/schema-v2/view?source=${context.engine.segment.connection?.id}&id=${collection.collection.catalog.model.id}`}
          >
            {collection.collection.catalog.model.name}
          </LinkWithTooltip>
        </Row>
      </TabDrawerHeader>
      <Outlet context={context} />
    </>
  );
};
