import { JourneyStatus } from "src/types/journeys";
import { JourneyState } from "src/pages/journeys/types";
import { JourneysBoolExp } from "src/graphql";

type GetJourneyState = {
  reset?: boolean;
  status: JourneyStatus;
  numberOfUsersInJourney: number;
  numberOfPreviousRuns: number;
};

/**
 * Determines the visible state of the journey
 */
export const getJourneyState = ({
  reset,
  status,
  numberOfUsersInJourney,
  numberOfPreviousRuns,
}: GetJourneyState): JourneyState => {
  if (reset) {
    return "resetting";
  }

  // If enabled, it's live
  if (status === JourneyStatus.Enabled) {
    return "live";
  }

  if (status === JourneyStatus.Draining) {
    return "draining";
  }

  // No users are currently in the journey
  if (numberOfUsersInJourney === 0) {
    // No previous runs means it's a draft
    if (numberOfPreviousRuns === 0) {
      return "draft";
    }

    // If there are previous runs, it's off
    return "off";
  }

  // Otherwise, if there are users in the journey, it's paused
  return "paused";
};

/** Filter to find journeys where all nodes have zero users */
const NoUsersInJourneyFilter: JourneysBoolExp = {
  nodes_aggregate: {
    count: {
      filter: { number_users: { _gt: "0" } }, // Find nodes with non-zero users
      predicate: { _eq: 0 }, // Make sure there are no nodes with users (meaning all nodes have 0 users)
    },
  },
};

export const AllJourneyFilter: JourneysBoolExp = {
  is_latest: { _eq: true },
};

export const LiveJourneyFilter: JourneysBoolExp = {
  is_latest: { _eq: true },
  status: {
    _in: [JourneyStatus.Enabled, JourneyStatus.Draining],
  },
  _or: [{ reset: { _eq: false } }, { reset: { _is_null: true } }],
};

export const DraftJourneyFilter: JourneysBoolExp = {
  is_latest: { _eq: true },
  _or: [{ reset: { _eq: false } }, { reset: { _is_null: true } }],
  status: { _eq: JourneyStatus.Disabled },
  ...NoUsersInJourneyFilter,
  // No previous runs
  runs_aggregate: { count: { predicate: { _eq: 0 } } },
};

export const OffJourneyFilter: JourneysBoolExp = {
  is_latest: { _eq: true },
  _or: [{ reset: { _eq: false } }, { reset: { _is_null: true } }],
  status: { _eq: JourneyStatus.Disabled },
  ...NoUsersInJourneyFilter,
  // At least 1 previous run
  runs_aggregate: { count: { predicate: { _gt: 0 } } },
};

export const PausedJourneyFilter: JourneysBoolExp = {
  is_latest: { _eq: true },
  _or: [{ reset: { _eq: false } }, { reset: { _is_null: true } }],
  status: { _eq: JourneyStatus.Disabled },
  // At least 1 previous run
  runs_aggregate: { count: { predicate: { _gt: 0 } } },
  // At least 1 user in the journey
  nodes_aggregate: {
    count: {
      filter: { number_users: { _gt: "0" } },
      predicate: { _gt: 0 },
    },
  },
};
