import { DecisionEngineChannelType } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  Box,
  Column,
  Row,
  SourceIcon,
  Text,
  TextStringIcon,
  Tooltip,
  VariableIcon,
} from "@hightouchio/ui";
import { MetadataLabel } from "src/components/metadata-bar";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { DecisionEngineFlowsQuery } from "src/graphql";
import { ChannelIcon } from "src/pages/decision-engines/components/channel-icon";
import type { Flow } from "src/pages/decision-engines/flows";
import { parseMessageVariables } from "src/pages/decision-engines/utils";

export const Messages = ({ flow }: { flow: Flow }) => {
  return (
    <Column overflow="auto">
      <Row align="center" justify="space-between">
        <MetadataLabel>Actions</MetadataLabel>
      </Row>
      <Column gap={3}>
        {flow.messages.map(({ message }) => (
          <Row gap={3} align="center" key={message.id} justify="space-between">
            <Row gap={3} align="center" overflow="hidden">
              <Box flexShrink={0}>
                <ChannelIcon
                  type={message.channel.type as DecisionEngineChannelType}
                />
              </Box>
              <TextWithTooltip fontWeight="medium">
                {message.name}
              </TextWithTooltip>
            </Row>
            <MessageIcons message={message} />
          </Row>
        ))}
        {!flow.messages?.length && (
          <Text color="text.tertiary">No actions</Text>
        )}
      </Column>
    </Column>
  );
};

const MessageIcons = ({
  message,
}: {
  message: DecisionEngineFlowsQuery["decision_engine_flows"][0]["messages"][0]["message"];
}) => {
  const { subjects, titles, bodies, variables } = parseMessageVariables(
    message.variables,
  );

  return (
    <Row gap={2} align="center">
      {subjects && (
        <Tooltip message="Email subject optimization">
          <Box as={TextStringIcon} boxSize={5} color="text.secondary" />
        </Tooltip>
      )}
      {titles && (
        <Tooltip message="Notification title optimization">
          <Box as={TextStringIcon} boxSize={5} color="text.secondary" />
        </Tooltip>
      )}
      {bodies && (
        <Tooltip
          message={`${
            message.channel.type === "sms" ? "SMS body" : "Notification body"
          } optimization`}
        >
          <Box as={TextStringIcon} boxSize={5} color="text.secondary" />
        </Tooltip>
      )}
      {variables?.length ? (
        <Tooltip
          message={`${variables
            .map(({ name }) => `"${name}"`)
            .join(" and ")} optimization`}
        >
          <Box as={VariableIcon} boxSize={5} color="text.secondary" />
        </Tooltip>
      ) : null}
      {message.collections?.length ? (
        <Tooltip
          message={`Recommending items from ${message.collections
            .map(
              ({ decision_engine_collection: { collection } }) =>
                `"${collection.name}"`,
            )
            .join(" and ")}`}
        >
          <Box as={SourceIcon} boxSize={5} color="text.secondary" />
        </Tooltip>
      ) : null}
    </Row>
  );
};
