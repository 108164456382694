import { FC, useMemo } from "react";

import { Row, Text, SearchInput } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import pluralize from "pluralize";
import { useNavigate } from "src/router";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { PermissionProvider } from "src/components/permission/permission-context";
import { SyncTemplatesBoolExp, useSyncTemplatesQuery } from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { SourceBadges } from "src/utils/sources";
import { openUrl } from "src/utils/urls";
import { PermissionedLinkButton } from "src/components/permission";

export const SyncTemplates: FC = () => {
  const navigate = useNavigate();
  const { schemaV2 } = useFlags();
  const [search, setSearch] = useQueryState("search");

  const { limit, offset, page, setPage } = useTableConfig({ limit: 20 });

  const hasuraFilters = useMemo(() => {
    const hasuraFilters: SyncTemplatesBoolExp = {};

    if (search) {
      hasuraFilters.name = { _ilike: `%${search}%` };
    }
    return hasuraFilters;
  }, [search]);

  const { data: syncTemplates, isLoading: syncTemplatesLoading } =
    useSyncTemplatesQuery(
      { filters: hasuraFilters, limit, offset },
      { keepPreviousData: true },
    );

  const columns: TableColumn[] = [
    {
      name: "Name",
      cell: ({ name }) => (
        <Text isTruncated fontWeight="medium">
          {name}
        </Text>
      ),
    },
    {
      name: "Parent model",
      cell: ({ segment: { name, connection: source } }) => {
        return (
          <Row align="center" gap={2} overflow="hidden">
            <IntegrationIcon
              name={source?.definition?.name}
              src={source?.definition?.icon}
            />
            <Text isTruncated fontWeight="medium">
              {name ?? "Private model"}
            </Text>
            <SourceBadges
              isSampleDataSource={source?.definition?.isSampleDataSource}
            />
          </Row>
        );
      },
    },
    {
      name: "Usage",
      cell: ({ destination_instances_aggregate }) => (
        <Text isTruncated fontWeight="medium">
          {pluralize(
            "syncs",
            destination_instances_aggregate.aggregate.count,
            true,
          )}
        </Text>
      ),
    },
    {
      name: "Destination",
      cell: ({ destination }) => {
        return (
          <Row align="center" gap={2} overflow="hidden">
            <IntegrationIcon
              name={destination?.definition?.name}
              src={destination?.definition?.icon}
            />
            <Text isTruncated fontWeight="medium">
              {destination?.name ??
                destination?.definition?.name ??
                "Private destination"}
            </Text>
          </Row>
        );
      },
    },
    {
      ...LastUpdatedColumn,
      breakpoint: "md",
    },
  ];

  return (
    <PermissionProvider
      permission={{
        v1: {
          resource: "sync_template",
          grant: "create",
        },
      }}
    >
      <Row py={8} justify="space-between">
        <SearchInput
          placeholder="Search by name..."
          value={search ?? ""}
          onChange={(event) => setSearch(event.target.value)}
        />
        <PermissionedLinkButton
          permission={{ v1: { resource: "sync_template", grant: "create" } }}
          href={
            schemaV2
              ? `/schema-v2/settings/sync-templates/new`
              : "/schema/sync-templates/new"
          }
          variant="primary"
        >
          Add sync template
        </PermissionedLinkButton>
      </Row>
      <Table
        columns={columns}
        data={syncTemplates?.sync_templates ?? []}
        error={false}
        loading={syncTemplatesLoading}
        placeholder={{
          title: "No sync templates",
          error: "Sync templates failed to load, please try again.",
        }}
        onRowClick={({ id }, event) =>
          openUrl(
            schemaV2
              ? `/schema-v2/settings/sync-templates/${id}`
              : `/schema/sync-templates/${id}`,
            navigate,
            event,
          )
        }
      />

      <Pagination
        count={syncTemplates?.sync_templates_aggregate.aggregate?.count ?? 0}
        label="Sync templates"
        page={page}
        rowsPerPage={limit}
        setPage={setPage}
      />
    </PermissionProvider>
  );
};
