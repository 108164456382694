import { FC, ReactNode, useEffect, useState } from "react";

import { Box, Spinner, Text } from "@hightouchio/ui";
import { flattenDeep } from "lodash";
import { useFormContext, useWatch } from "react-hook-form";

import { FieldError } from "src/components/field-error";

import {
  BinaryBoolean,
  BinaryBooleanOperator,
  BOOLEAN_FUNCTIONS,
  BooleanType,
  ShowModifierNode,
  ResetModifierNode,
  AsyncReferenceModifierNode,
  isUnaryBoolean,
  ModifierType,
  UnaryBoolean,
  UnaryBooleanOperator,
  FormkitModifier,
} from "../../../../formkit";
import { getNestedKeys, processReferences } from "../component-utils";
import { useQuery } from "../hooks";
import { useFormkitContext } from "./formkit-context";
import { useFlags } from "launchdarkly-react-client-sdk";

type Props = {
  children: ReactNode;
  node: FormkitModifier;
};

type ShowProps = {
  children: ReactNode;
  node: ShowModifierNode;
};

type ResetProps = {
  children: ReactNode;
  node: ResetModifierNode;
};

type AsyncReferenceProps = {
  children: ReactNode;
  node: AsyncReferenceModifierNode;
};

const ShowModifier: FC<Readonly<ShowProps>> = ({ children, node }) => {
  const context = useFormkitContext();
  const featureFlags = useFlags();
  const { watch } = useFormContext();
  const [result, setResult] = useState<boolean>(false);

  const watcher = useWatch();
  const condition = node.condition;

  const processCondition = () => {
    if (typeof condition === "boolean") {
      setResult(condition);
    } else if (condition?.type === BooleanType.Function) {
      const valueWithProcessedRefs = processReferences(
        condition?.variables,
        { ...context, featureFlags },
        watch,
      );
      const functionResult =
        BOOLEAN_FUNCTIONS[String(condition.function)]?.(
          valueWithProcessedRefs,
        ) || false;
      setResult(functionResult);
    } else if (condition?.type === BooleanType.Unary) {
      const cond = condition as UnaryBoolean;
      if (typeof cond.operand === "object") {
        const valueWithProcessedRefs = processReferences(
          cond.operand,
          { ...context, featureFlags },
          watch,
        );
        let booleanValue: boolean;
        // Sometimes processReferences() passes the original object back. Get a boolean value from it.
        if (isUnaryBoolean(valueWithProcessedRefs)) {
          booleanValue = Boolean(valueWithProcessedRefs.operand);
        } else {
          booleanValue = Boolean(valueWithProcessedRefs);
        }
        if (cond.operator === UnaryBooleanOperator.Not) {
          setResult(!booleanValue);
        } else {
          setResult(booleanValue);
        }
      } else {
        setResult(Boolean(condition));
      }
    } else if (condition?.type === BooleanType.Binary) {
      const cond = condition as BinaryBoolean;
      const leftValue = processReferences(
        cond.leftOperand,
        { ...context, featureFlags },
        watch,
      );
      const rightValue = processReferences(
        cond.rightOperand,
        { ...context, featureFlags },
        watch,
      );

      if (cond.operator === BinaryBooleanOperator.Equals) {
        setResult(leftValue === rightValue);
      } else if (cond.operator === BinaryBooleanOperator.NotEquals) {
        setResult(leftValue !== rightValue);
      } else if (cond.operator === BinaryBooleanOperator.And) {
        setResult(Boolean(leftValue && rightValue));
      } else if (cond.operator === BinaryBooleanOperator.Or) {
        setResult(Boolean(leftValue || rightValue));
      } else if (
        cond.operator === BinaryBooleanOperator.GreaterThan &&
        typeof leftValue === "number" &&
        typeof rightValue === "number"
      ) {
        setResult(leftValue > rightValue);
      } else if (
        cond.operator === BinaryBooleanOperator.GreaterThanOrEqual &&
        typeof leftValue === "number" &&
        typeof rightValue === "number"
      ) {
        setResult(leftValue >= rightValue);
      } else if (
        cond.operator === BinaryBooleanOperator.LessThan &&
        typeof leftValue === "number" &&
        typeof rightValue === "number"
      ) {
        setResult(leftValue < rightValue);
      } else if (
        cond.operator === BinaryBooleanOperator.LessThanOrEqual &&
        typeof leftValue === "number" &&
        typeof rightValue === "number"
      ) {
        setResult(leftValue <= rightValue);
      }
    }
  };

  useEffect(() => {
    if (condition) {
      processCondition();
    }
  }, [context, condition, watcher]);

  return result ? <>{children}</> : null;
};

export const ResetModifier: FC<Readonly<ResetProps>> = ({ children, node }) => {
  const { watch, setValue } = useFormContext();

  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name && node?.keys?.includes(name)) {
        const keys = flattenDeep<string>(getNestedKeys(node));
        keys.forEach((key) => setValue(key, undefined));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return <>{children}</>;
};

export const AsyncReferenceModifier: FC<Readonly<AsyncReferenceProps>> = ({
  children,
  node,
}) => {
  const context = useFormkitContext();
  const featureFlags = useFlags();
  const { watch } = useFormContext();

  const handler = processReferences(
    node.handler,
    { ...context, featureFlags },
    watch,
  ) as {
    query: string;
    variables: Record<string, unknown>;
  };
  const {
    data,
    error: queryError,
    isFetching,
  } = useQuery(JSON.stringify({ name, variables: handler?.variables }), {
    fetchProps: {
      destinationId: context?.destination?.id,
      modelId: context?.model?.id,
      query: handler?.query,
      variables: handler?.variables,
    },
  });

  if (isFetching) {
    return <Spinner />;
  }

  if (queryError || !data) {
    return (
      <Box>
        <Text>There is a problem loading the rest of the form</Text>
        <FieldError error={queryError as any} />
      </Box>
    );
  }

  return <>{children}</>;
};

export const Modifier: FC<Readonly<Props>> = (props) => {
  if (props.node.modifier === ModifierType.AsyncReference) {
    return <AsyncReferenceModifier {...props} node={props.node} />;
  } else if (props.node.modifier === ModifierType.Show) {
    return <ShowModifier {...props} node={props.node} />;
  } else if (props.node.modifier === ModifierType.Reset) {
    return <ResetModifier {...props} node={props.node} />;
  }
  return <></>;
};
