import { FC, useState } from "react";

import {
  CloseIcon,
  Column,
  DeleteIcon,
  DrawerBody,
  DrawerHeader,
  EditIcon,
  IconButton,
  Paragraph,
  PlusIcon,
  Row,
  Text,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import differenceBy from "lodash/differenceBy";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "src/router";
import { getOutgoers } from "reactflow";

import {
  PermissionedButton,
  PermissionedEditableHeading,
} from "src/components/permission";
import { Reorder } from "src/components/reorder";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import {
  JourneyGraph,
  NodeDetailFormProps,
  SegmentPriorityList,
} from "src/pages/journeys/types";
import { isSegmentBranchNodeDetails } from "src/pages/journeys/utils";
import { SegmentsConfig } from "src/types/journeys";

import { getOrderedEditableSegments } from "./utils";

export const PriorityListForm: FC<NodeDetailFormProps<SegmentsConfig>> = ({
  id, // id techincally lives in data too, redundant to have both :/
  data,
  onClose,
}) => {
  const navigate = useNavigate();
  const { toast } = useToast();

  const parentForm = useFormContext<JourneyGraph>();
  const {
    nodes,
    isEditMode,
    unauthorizedTooltip,
    updateJourneyPermission,
    onAddSegmentBranch,
    onCleanUp,
    onRemoveNode,
    onUpdateNode,
    onUpdateSegmentPriority,
  } = useGraphContext();

  const [submitCount, setSubmitCount] = useState(0);

  const currentNode = nodes.find((node) => node.id === id);
  const edges = parentForm.watch("edges");

  const parentFormSegments = currentNode
    ? getOutgoers(currentNode, nodes, edges)
    : [];
  // Priority is determined by index
  const editableSegments = getOrderedEditableSegments(parentFormSegments);

  const updateName = (newName: string) => {
    if (!newName) {
      toast({
        id: "update-error",
        title: "Name is required",
        variant: "error",
      });

      setSubmitCount(submitCount + 1);
    } else {
      onUpdateNode(id, { name: newName });

      toast({
        id: "tile-update",
        title: "Tile name updated",
        variant: "success",
      });
    }
  };

  const addSegment = async () => {
    const segmentId = onAddSegmentBranch(id);

    // Force a reflow,
    await onCleanUp();

    navigate(`../${segmentId}`);
  };

  const reassignPriority = (items: SegmentPriorityList) => {
    // Find segments to remove
    const segmentsToRemove = differenceBy(
      parentFormSegments.map(({ data: nodeData }) => nodeData),
      items,
      "id",
    ).filter(
      (nodeData) =>
        isSegmentBranchNodeDetails(nodeData) &&
        !nodeData.config.segment_is_catch_all,
    );

    // Remove discarded segments
    segmentsToRemove.forEach((segment) => onRemoveNode(segment.id));

    // Update segment priority
    onUpdateSegmentPriority(
      id,
      items.map(({ id }) => id),
    );
  };

  const deleteSegment = (index: number) => {
    const newSegments = editableSegments.slice();

    newSegments.splice(index, 1).forEach((segment) => {
      onRemoveNode(segment.id);

      toast({
        id: "tile-update",
        title: `Segment '${segment.name}' deleted`,
        variant: "info",
      });
    });

    reassignPriority(newSegments);
  };

  return (
    <>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <PermissionedEditableHeading
            // Key forces remount if submission fails
            isDisabled={!isEditMode}
            key={submitCount}
            permission={updateJourneyPermission}
            unauthorizedTooltip={unauthorizedTooltip}
            value={data.name}
            onChange={updateName}
          />
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody bg="base.lightBackground">
        <Column minHeight={0} flex={1} gap={6} pb={4}>
          <Row align="center" justify="space-between">
            <Column>
              <Text fontWeight="medium" size="lg">
                Segments
              </Text>
              <Paragraph color="text.secondary">
                Define segments in a prioritized list. Users will only fall into
                one segment.
              </Paragraph>
            </Column>
            <PermissionedButton
              isDisabled={!isEditMode}
              permission={updateJourneyPermission}
              unauthorizedTooltip={unauthorizedTooltip}
              icon={PlusIcon}
              onClick={addSegment}
            >
              Add segment
            </PermissionedButton>
          </Row>

          <Column gap={4}>
            <Text fontWeight="medium" color="text.secondary">
              Highest priority
            </Text>
            <Reorder
              isDisabled={!isEditMode}
              items={editableSegments}
              showEdgeLabels={false}
              onChange={reassignPriority}
            >
              {editableSegments.map((segment, index) => (
                <Row
                  key={segment.id}
                  align="center"
                  justify="space-between"
                  minWidth={0}
                  flex={1}
                  px={4}
                  onClick={() => navigate(`../${segment.id}`)}
                >
                  <Text fontWeight="medium">{segment.name}</Text>
                  {isEditMode && (
                    <Row gap={4}>
                      <LinkButton
                        href={`../${segment.id}`}
                        icon={EditIcon}
                        variant="tertiary"
                      >
                        Edit
                      </LinkButton>
                      <Tooltip message="Remove segment">
                        <IconButton
                          aria-label="Remove segment."
                          icon={DeleteIcon}
                          variant="danger"
                          onClick={(event) => {
                            event.stopPropagation();
                            deleteSegment(index);
                          }}
                        />
                      </Tooltip>
                    </Row>
                  )}
                </Row>
              ))}
            </Reorder>

            <Column
              display="flex"
              border="1px solid var(--chakra-colors-base-border)"
              borderRadius="var(--chakra-radii-md)"
              overflow="hidden"
              backgroundColor="rgba(255,255,255,1)"
              userSelect="none"
            >
              <Row>
                <Column
                  bg="base.lightBackground"
                  borderRight="1px"
                  borderColor="base.border"
                  justify="center"
                  fontSize="3xl"
                  color="text.placeholder"
                  width="33px"
                />
                <Column py={4} px={8}>
                  <Text fontWeight="medium">Everyone else</Text>
                  <Text color="text.secondary">
                    All users who don’t fall into one of the above segments
                  </Text>
                </Column>
              </Row>
            </Column>
            <Text fontWeight="medium" color="text.secondary">
              Lowest priority
            </Text>
          </Column>
        </Column>
      </DrawerBody>
    </>
  );
};
