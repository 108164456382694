import { useCallback, useEffect, useMemo, useState } from "react";
import store from "store2";
import { useUser } from "src/contexts/user-context";
import { useUpdateWorkspacePersonalizationMutation } from "src/graphql";
import { get, set, unset } from "lodash";
import { captureException } from "@sentry/react";

export interface PersonalizationOptions<T> {
  /**
   * The key to use for storing the value in local storage or workspace personalization
   * Can be a nested path like "home.charts" which will be handled by lodash get/set
   */
  key: string;

  /**
   * The initial value to use if no value is found in storage
   */
  initialValue: T;

  /**
   * Whether to use workspace personalization (if available) or always use local storage
   * @default true
   */
  useWorkspace?: boolean;
}

export interface PersonalizationValue<T> {
  /**
   * The current value
   */
  value: T;

  /**
   * Whether the current value is from workspace personalization
   */
  isWorkspaceValue: boolean;

  /**
   * Whether the value is being saved to the workspace
   */
  isSaving: boolean;

  /**
   * Set the value in local storage
   */
  setLocal: (value: T) => void;

  /**
   * Set the value in workspace personalization (if available and enabled)
   */
  save: (value: T) => void;

  /**
   * Clear the value completely
   */
  clear: () => void;

  /**
   * Clear the value from workspace personalization
   */
  clearWorkspacePersonalization: () => void;
}

export const usePersonalization = <T>({
  key,
  initialValue,
  useWorkspace = true,
}: PersonalizationOptions<T>): PersonalizationValue<T> => {
  const { workspace, user } = useUser();
  const localStorageKey = `${workspace?.id}:personalization:${key}`;
  const updateWorkspaceMutation = useUpdateWorkspacePersonalizationMutation({
    onSuccess: () => {
      // Don't reload queries
    },
  });
  // Used for optimistic update
  const [workspaceValue, setWorkspaceValue] = useState<T | undefined>(
    get(workspace?.personalization, key),
  );
  const [value, setValue] = useState<T>(
    store.get(localStorageKey) ?? initialValue,
  );

  // Update the value when workspace personalization changes
  useEffect(() => {
    const localValue = store.get(localStorageKey) ?? initialValue;
    const workspaceValue = get(workspace?.personalization, key);
    if (useWorkspace && workspaceValue) {
      setWorkspaceValue(workspaceValue);
      setValue(workspaceValue);
    } else {
      setValue(localValue);
      setWorkspaceValue(undefined);
    }
  }, [useWorkspace, workspace?.personalization, key]);

  // Set value in local storage
  const setLocal = useCallback(
    (newValue: T) => {
      setValue(newValue);
      if (newValue !== undefined) {
        store.set(localStorageKey, newValue);
      }
    },
    [localStorageKey],
  );

  // Set value in workspace personalization
  const save = useCallback(
    async (newValue: T) => {
      // Update state immediately
      setValue(newValue);
      setWorkspaceValue(newValue);
      const updatedPersonalization = {
        ...(workspace!.personalization || {}),
      };
      set(updatedPersonalization, key, newValue);
      try {
        await updateWorkspaceMutation.mutateAsync({
          workspaceId: workspace!.id,
          personalization: updatedPersonalization,
        });
      } catch (e) {
        captureException(e);
      }
    },
    [workspace, key, localStorageKey, updateWorkspaceMutation],
  );

  const clear = useCallback(async () => {
    setValue(initialValue);
    setWorkspaceValue(undefined);
    store.remove(localStorageKey);

    if (useWorkspace && user?.is_admin) {
      const updatedPersonalization = {
        ...(workspace!.personalization ?? {}),
      };
      unset(updatedPersonalization, key);
      try {
        await updateWorkspaceMutation.mutateAsync({
          workspaceId: workspace!.id,
          personalization: updatedPersonalization,
        });
      } catch (e) {
        captureException(e);
      }
    }
  }, [
    useWorkspace,
    user,
    workspace,
    key,
    localStorageKey,
    initialValue,
    updateWorkspaceMutation,
  ]);

  const clearWorkspacePersonalization = useCallback(async () => {
    if (useWorkspace && user?.is_admin) {
      setWorkspaceValue(undefined);
      const updatedPersonalization = {
        ...(workspace!.personalization ?? {}),
      };
      unset(updatedPersonalization, key);
      try {
        await updateWorkspaceMutation.mutateAsync({
          workspaceId: workspace!.id,
          personalization: updatedPersonalization,
        });
      } catch (e) {
        captureException(e);
      }
    }
  }, [useWorkspace, user]);

  const isWorkspaceValue = useMemo(() => {
    if (!useWorkspace) return false;
    return workspaceValue !== undefined && value === workspaceValue;
  }, [useWorkspace, workspaceValue, value]);

  return {
    value,
    isWorkspaceValue,
    isSaving: updateWorkspaceMutation.isLoading,
    setLocal,
    save,
    clear,
    clearWorkspacePersonalization,
  };
};
