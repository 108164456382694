import { truncate } from "lodash";
import { newPylonMessage } from "src/lib/pylon";
import { ElementOf } from "ts-essentials";

import { Link } from "src/router";
import { Alert, Button, Column, Row, Text } from "@hightouchio/ui";
import { Markdown } from "src/ui/markdown";
import { SyncRequestErrorInfo } from "src/types/sync-errors";
import { CodeWithOverflow } from "src/pages/syncs/sync/components/code-with-overflow";
import {
  getErrorCode,
  getSpecialCaseErrorInfoContent,
  isSpecialCaseErrorCode,
} from "src/pages/syncs/sync/components/error-utils";
import {
  generateLinkProps,
  DocumentLink,
  getDestinationErrorInfo,
} from "src/pages/syncs/sync/components/error-origin-info-modal";
import { useUser } from "src/contexts/user-context";
import { SyncRequest, SyncRunPhase } from "./types";
import { isSyncRunStatus } from "src/utils/syncs";

const DocumentLinkButton = ({
  link,
  syncRequest,
}: {
  link: DocumentLink;
  syncRequest: SyncRequest;
}) => {
  const sync = syncRequest.sync;
  const destination = sync?.destination;
  const model = sync?.segment;
  const source = model?.connection;

  const props = generateLinkProps(
    link,
    origin,
    destination,
    model,
    source,
    sync,
  );

  if (!props) return null;

  return (
    <Link href={props.url ?? "#"} isExternal={link?.type === "external"}>
      <Button icon={props.icon} size="sm" variant="secondary">
        {props.label}
      </Button>
    </Link>
  );
};

const HelpLink = ({ errorMessage }: { errorMessage: string }) => {
  const { isEmbedded } = useUser();

  if (isEmbedded) return null;

  return (
    <Text size="sm" color="text.secondary">
      Need help troubleshooting this sync?{" "}
      <Link
        href=""
        fontSize="sm"
        onClick={() =>
          newPylonMessage(
            `<p>Hi, I need help troubleshooting this sync run.</p>
            <pre>${truncate(errorMessage, { length: 355 })}</pre>
            <p>Run details: <a href="${window.location.href}">${window.location.href}</a></p>`,
          )
        }
      >
        Chat with us
      </Link>{" "}
      &mdash; we're here to help!
    </Text>
  );
};

const getErrorProps = (
  error: ElementOf<SyncRunPhase["errors"]>,
  syncRequest: SyncRequest,
): {
  title: string;
  errorMessage?: string;
  troubleshootingMessage?: string;
  links?: DocumentLink[];
} => {
  const errorInfo = error.errorInfo as SyncRequestErrorInfo | null;

  if (!errorInfo) {
    return {
      title: "Error",
      errorMessage: error.message,
    };
  }

  const errorCode = getErrorCode(
    errorInfo,
    isSyncRunStatus(syncRequest?.status) ? syncRequest.status : undefined,
  );

  if (isSpecialCaseErrorCode(errorCode)) {
    const { title, message, links } = getSpecialCaseErrorInfoContent(
      errorCode,
      errorInfo,
    );

    return {
      title,
      troubleshootingMessage: message,
      links,
    };
  }

  const destination = syncRequest.sync?.destination;
  const source = syncRequest.sync?.segment?.connection;

  const originInfo =
    errorInfo.originInfo &&
    getDestinationErrorInfo(destination, errorInfo.originInfo, source);

  return {
    title: originInfo?.rawErrorHeader ?? "Error",
    errorMessage: errorInfo?.message ?? error.message,
    troubleshootingMessage:
      error.errorCodeDetail?.userFriendlyMessage ||
      errorInfo?.userFriendlyMessage ||
      errorInfo?.userFacingMessage,
    links: error.errorCodeDetail?.links?.map((link) => ({
      // Annoying mapping of nulls to undefined to make TS happy (one type is gql type, the other is defined in a backend lib)
      label: link.label ?? undefined,
      type: link.type ?? undefined,
      url: link.url ?? undefined,
    })),
  };
};

export const PhaseError = ({
  errors,
  syncRequest,
  variant = "compact",
}: {
  errors: SyncRunPhase["errors"];
  syncRequest: SyncRequest;
  variant?: "compact" | "card";
}) => {
  // Note: we might want to show multiple errors in the future, for now we just grab the last one
  const error = errors[errors.length - 1];

  if (!error) return null;

  const { title, errorMessage, troubleshootingMessage, links } = getErrorProps(
    error,
    syncRequest,
  );

  const content = (
    <Column gap={4} mt={2}>
      {errorMessage && (
        <CodeWithOverflow buttonSize="sm">
          <Text isMonospace>
            <Markdown>{errorMessage}</Markdown>
          </Text>
        </CodeWithOverflow>
      )}
      {troubleshootingMessage && (
        <Column gap={1}>
          {errorMessage && <Text fontWeight="medium">Explanation</Text>}
          <Column gap={2}>
            <Markdown>{troubleshootingMessage}</Markdown>
          </Column>
          {links && (
            <Row gap={2} mt={2} flexWrap="wrap">
              {links?.map((link, idx) => (
                <DocumentLinkButton
                  key={idx}
                  link={link}
                  syncRequest={syncRequest}
                />
              ))}
            </Row>
          )}
        </Column>
      )}
      <HelpLink
        errorMessage={errorMessage ?? troubleshootingMessage ?? error.message}
      />
    </Column>
  );

  if (variant === "card") {
    return <Alert type="error" title={title} message={content} />;
  }

  return (
    <Column backgroundColor="danger.background" p={4} borderRadius="md">
      <Text fontWeight="medium">{title}</Text>
      {content}
    </Column>
  );
};
