import { FC } from "react";

import {
  Alert,
  ArrowRightIcon,
  Box,
  Button,
  Column,
  EmptyState,
  EmptyStateProps,
  Heading,
  HeadingProps,
  InformationIcon,
  Row,
  StatsItemTitle,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { useNavigate, useOutletContext } from "src/router";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { Card } from "src/components/card";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { RunGraphButton } from "src/pages/identity-resolution/components";
import {
  IdentityGraph,
  IdrRunStatus,
  IdrStats,
  IDRSummaryStats,
} from "src/pages/identity-resolution/types";
import { getLegacySummaryValues } from "src/pages/identity-resolution/utils";
import {
  getv2SummaryValues,
  GraphVersion,
} from "src/pages/identity-resolution/utils/graph-utils";
import { IDRv2Stats } from "src/types/idr";
import { Table } from "src/ui/table";
import { approxNumber } from "src/utils/numbers";

import { OutletContext } from ".";
import { GraphStatusIndicator } from "./graph-status-indicator";
import { LoadingCircles } from "src/components/loading";

const EmptyNumberPlaceholder = "--";

const CellRightAlignedStyling = {
  borderBottom: undefined,
  paddingTop: "16px",
  div: { width: "100%", textAlign: "right" },
};

const minLeftColumnWidth = 360;

export const Summary: FC = () => {
  const { graph } = useOutletContext<OutletContext>();

  if (!graph.runs.length) {
    return (
      <Box bg="white">
        <EmptyState
          title="No runs"
          message="Run the identity graph to view summary and statistics about the graph"
          imageUrl={genericPlaceholder}
          actions={<RunGraphButton />}
        />
      </Box>
    );
  }

  const currentRun = graph.runs[0];
  const initialRunLoading = graph.runs.length === 1 && !currentRun?.finished_at;

  if (initialRunLoading) {
    return (
      <Card alignItems="center" justifyContent="center">
        <LoadingCircles variant="green" />
        <Row align="center" justify="center" mb={10}>
          <Text color="text.secondary" fontWeight="medium" size="lg">
            Run in progress...
          </Text>
        </Row>
      </Card>
    );
  }

  const previousRun = graph.runs[1];
  const lastRun = currentRun?.finished_at ? currentRun : previousRun;

  // If we don't have a completed run, but we have a current run, it means that we are in the
  // process of executing the first run for this graph. In that case, we draw the dashboard with a
  // bunch of placeholder values.
  return <IdrSummaryDashboard run={lastRun ?? currentRun} graph={graph} />;
};

const IdrSummaryDashboard: FC<{
  run: IdentityGraph["runs"][number] | undefined;
  graph: IdentityGraph;
}> = ({ run, graph }) => {
  const stats: IdrStats | undefined = run?.stats;

  const isIDRv2 = graph.version === GraphVersion.V2;

  const summaryValues = isIDRv2
    ? getv2SummaryValues(stats as IDRv2Stats | undefined)
    : getLegacySummaryValues(stats, graph.models);

  return (
    <Column gap={6}>
      {run?.status === IdrRunStatus.Failure && (
        <Alert
          variant="inline"
          type="error"
          message={run.error?.error ?? "Internal error"}
          title="Run failure"
        />
      )}
      <Row gap={6}>
        <Column gap={6} minWidth={`${minLeftColumnWidth}px`}>
          <StatsSummaryCard
            heading="Summary"
            statsContent={{
              primary: {
                tooltip:
                  "The approximate number of profiles generated by the identity graph",
                heading: "Hightouch IDs",
                value: summaryValues?.totalHtIds
                  ? approxNumber(summaryValues?.totalHtIds, 3)
                  : EmptyNumberPlaceholder,
              },
              secondary: {
                tooltip:
                  "The approximate number of rows contributing to the identity graph and its profiles",
                heading: "Source Rows",
                value: summaryValues?.totalSrcRows
                  ? approxNumber(summaryValues?.totalSrcRows, 3)
                  : EmptyNumberPlaceholder,
              },
            }}
          />
          <RecentRuns graph={graph} />
        </Column>
        <Column gap={6} flex={1} minWidth={0}>
          <PerModelStats modelStats={summaryValues?.models ?? []} />
          {isIDRv2 && (
            <Identifiers
              identifiers={summaryValues?.identifiers ?? []}
              totalSrcRows={summaryValues?.totalSrcRows ?? 0}
            />
          )}
        </Column>
      </Row>
    </Column>
  );
};

type RecentRunsProps = {
  graph: IdentityGraph;
};

const RecentRuns: FC<RecentRunsProps> = ({ graph }) => {
  const navigate = useNavigate();

  const { id: graphId, models, runs, version } = graph;
  const isIDRv2Enabled = version === GraphVersion.V2;

  return (
    <Card gap={4}>
      <Row gap={2} justify="space-between">
        <Row align="center" gap={2}>
          <Text color="text.primary" size="lg" fontWeight="medium">
            Recent runs
          </Text>
          <Tooltip message="The status of and approximate number of new unique profiles generated from the most recent identity graph runs">
            <InformationIcon color="text.secondary" />
          </Tooltip>
        </Row>
        <Button
          directionIcon={ArrowRightIcon}
          size="sm"
          onClick={() => navigate(`/idr/${graphId}/runs`)}
        >
          View all runs
        </Button>
      </Row>
      <Table
        columns={[
          {
            name: "Status",
            headerSx: { "&:first-of-type": { pl: 4 } },
            cellSx: { pl: "4 !important" },
            cell: (run) => (
              <GraphStatusIndicator
                showTimeOnlyOnExtraLargeScreens
                lastRun={run}
              />
            ),
          },
          {
            name: "Unique profiles",
            headerSx: { justifyContent: "end", pr: "4 !important" },
            cellSx: { div: { width: "100%", textAlign: "right" } },
            cell: ({ stats }) => {
              const summaryValues = isIDRv2Enabled
                ? getv2SummaryValues(stats as IDRv2Stats | undefined)
                : getLegacySummaryValues(stats, models);

              if (isIDRv2Enabled) {
                const uniqueProfiles = summaryValues?.diffHtIds
                  ? `${summaryValues.diffHtIds > 0 ? "+" : ""}${approxNumber(
                      summaryValues.diffHtIds,
                      3,
                    )}`
                  : EmptyNumberPlaceholder;

                return (
                  <TextWithTooltip size="sm" fontWeight="medium">
                    {uniqueProfiles}
                  </TextWithTooltip>
                );
              }

              const uniqueProfiles = summaryValues?.totalHtIds
                ? `${approxNumber(summaryValues.totalHtIds, 3)}`
                : EmptyNumberPlaceholder;

              return (
                <TextWithTooltip size="sm" fontWeight="medium">
                  {uniqueProfiles}
                </TextWithTooltip>
              );
            },
          },
        ]}
        data={runs}
        placeholder={{
          custom: (
            <Placeholder
              imageUrl={genericPlaceholder}
              message="No recent runs"
            />
          ),
        }}
      />
    </Card>
  );
};

const DashboardNumberWithHeader: FC<{
  tooltip: string;
  heading: string;
  value: string;
  numberSize: HeadingProps["size"];
}> = ({ tooltip, heading, value, numberSize }) => {
  // If the value is a placeholder, reduce visual emphasis
  const numberColor =
    value === EmptyNumberPlaceholder ? "text.secondary" : "text.primary";

  return (
    <Row gap={4}>
      <Column width="100%">
        <Row align="center" gap={1} pb={1}>
          <StatsItemTitle>{heading}</StatsItemTitle>
          <Tooltip message={tooltip}>
            <InformationIcon color="text.secondary" />
          </Tooltip>
        </Row>
        <Heading size={numberSize} color={numberColor}>
          {value}
        </Heading>
      </Column>
    </Row>
  );
};

const StatsSummaryCard: FC<{
  heading: string;
  tooltip?: string;
  statsContent: {
    primary: { tooltip: string; heading: string; value: string };
    secondary: { tooltip: string; heading: string; value: string };
  };
}> = ({ heading, statsContent, tooltip }) => {
  return (
    <Card justifyContent="space-between" gap={5}>
      <Row align="center" gap={2}>
        <Text color="text.primary" size="lg" fontWeight="medium">
          {heading}
        </Text>
        {tooltip && (
          <Tooltip message={tooltip}>
            <InformationIcon color="text.secondary" />
          </Tooltip>
        )}
      </Row>
      <DashboardNumberWithHeader {...statsContent.primary} numberSize="lg" />
      <DashboardNumberWithHeader {...statsContent.secondary} numberSize="lg" />
    </Card>
  );
};

const PerModelStats: FC<{
  modelStats: IDRSummaryStats["models"];
}> = ({ modelStats }) => {
  return (
    <Card gap={4}>
      <Row align="center" gap={2}>
        <Text size="lg" fontWeight="medium">
          Models
        </Text>
        <Tooltip message="The approximate number of input models contributing to the identity graph">
          <InformationIcon color="text.secondary" />
        </Tooltip>
      </Row>

      <Table
        columns={[
          {
            header: () => (
              <Text
                size="sm"
                fontWeight="semibold"
                color="text.secondary"
                textTransform="uppercase"
              >
                Model
              </Text>
            ),
            max: "3fr",
            min: "80px",
            headerSx: { "&:first-of-type": { pl: 4 } },
            cellSx: {
              pl: "4 !important",
              borderBottom: undefined,
              paddingTop: "16px",
            },
            cell: ({ modelName }) => {
              return (
                <TextWithTooltip fontWeight="medium" message={modelName}>
                  {modelName}
                </TextWithTooltip>
              );
            },
          },
          {
            headerSx: { justifyContent: "end" },
            header: () => (
              <Row align="center" gap={1} width="100%" justify="end">
                <Text
                  size="sm"
                  fontWeight="semibold"
                  color="text.secondary"
                  textTransform="uppercase"
                >
                  Unique profiles
                </Text>
                <Tooltip message="The approximate number of unique profiles each model contributed to">
                  <InformationIcon color="text.secondary" />
                </Tooltip>
              </Row>
            ),
            min: "160px",
            cellSx: CellRightAlignedStyling,
            cell: ({ numHtIds }) => {
              return (
                <TextWithTooltip>{approxNumber(numHtIds, 3)}</TextWithTooltip>
              );
            },
          },
          {
            headerSx: { justifyContent: "end", pr: "4 !important" },
            header: () => (
              <Row align="center" gap={1} width="100%" justify="end">
                <Text
                  size="sm"
                  fontWeight="semibold"
                  color="text.secondary"
                  textTransform="uppercase"
                >
                  Source rows
                </Text>
                <Tooltip
                  message="The approximate number of unique source rows from each model"
                  placement="top-end"
                >
                  <InformationIcon color="text.secondary" />
                </Tooltip>
              </Row>
            ),
            min: "120px",
            cellSx: CellRightAlignedStyling,
            cell: ({ sourceRows }) => {
              return (
                <TextWithTooltip>{approxNumber(sourceRows, 3)}</TextWithTooltip>
              );
            },
          },
        ]}
        data={modelStats}
        loading={false}
        error={false}
        placeholder={{
          custom: (
            <Placeholder
              imageUrl={genericPlaceholder}
              message="Run the identity graph to view models contributing to the graph"
            />
          ),
        }}
        rowHeight="36px"
      />
    </Card>
  );
};

const Identifiers: FC<{
  identifiers: IDRSummaryStats["identifiers"];
  totalSrcRows: number;
}> = ({ identifiers, totalSrcRows }) => {
  return (
    <Card gap={4}>
      <Row align="center" gap={2}>
        <Text size="lg" fontWeight="medium">
          Identifiers
        </Text>
        <Tooltip message="The identifier types contributing to the identity graph">
          <InformationIcon color="text.secondary" />
        </Tooltip>
      </Row>

      <Table
        columns={[
          {
            header: () => (
              <Text
                size="sm"
                fontWeight="semibold"
                color="text.secondary"
                textTransform="uppercase"
              >
                Model
              </Text>
            ),
            max: "3fr",
            min: "80px",
            headerSx: { "&:first-of-type": { pl: 4 } },
            cellSx: {
              pl: "4 !important",
              borderBottom: undefined,
              paddingTop: "16px",
            },
            cell: ({ name }) => {
              return (
                <TextWithTooltip fontWeight="medium" message={name}>
                  {name}
                </TextWithTooltip>
              );
            },
          },
          {
            header: () => (
              <Row align="center" gap={1} width="100%" justify="end">
                <Text
                  size="sm"
                  fontWeight="semibold"
                  color="text.secondary"
                  textTransform="uppercase"
                >
                  Unique profiles
                </Text>
                <Tooltip message="The approximate number of unique identifier values from each identifier type contributing to the identity graph">
                  <InformationIcon color="text.secondary" />
                </Tooltip>
              </Row>
            ),
            min: "160px",
            cellSx: CellRightAlignedStyling,
            cell: ({ numHtIds }) => {
              return (
                <TextWithTooltip>{approxNumber(numHtIds, 3)}</TextWithTooltip>
              );
            },
          },
          {
            headerSx: { pr: "4 !important" },
            header: () => (
              <Row align="center" gap={1} width="100%" justify="end">
                <Text
                  size="sm"
                  fontWeight="semibold"
                  color="text.secondary"
                  textTransform="uppercase"
                >
                  Source rows
                </Text>
                <Tooltip
                  message="The approximate number of identifier values from each identifier type contributing to the identity graph"
                  placement="top-end"
                >
                  <InformationIcon color="text.secondary" />
                </Tooltip>
              </Row>
            ),
            min: "min-content",
            cellSx: CellRightAlignedStyling,
            cell: ({ sourceRows }) => {
              const totalSrcRowsPercentage =
                totalSrcRows > 0
                  ? ((sourceRows / totalSrcRows) * 100).toFixed(2)
                  : 0;

              return (
                <TextWithTooltip
                  message={`${approxNumber(sourceRows, 3)}${
                    totalSrcRowsPercentage ? `(${totalSrcRowsPercentage}%)` : ""
                  }`}
                >
                  <>
                    {approxNumber(sourceRows, 3)}{" "}
                    <Text color="text.secondary">
                      ({totalSrcRowsPercentage}%)
                    </Text>
                  </>
                </TextWithTooltip>
              );
            },
          },
        ]}
        data={identifiers}
        loading={false}
        error={false}
        placeholder={{
          custom: (
            <Placeholder
              imageUrl={genericPlaceholder}
              message="Run the identity graph to view identifiers"
            />
          ),
        }}
        rowHeight="36px"
      />
    </Card>
  );
};

const Placeholder: FC<EmptyStateProps> = (props) => (
  <Column
    justify="center"
    height="100%"
    sx={{ "> div": { border: "none", display: "flex" } }}
  >
    <EmptyState {...props} />
  </Column>
);
