import { ParentResourceTypes } from "@hightouch/lib/resource-monitoring/types";

import {
  useCreateNotificationTemplateChannelMutation,
  useCreateWorkspaceNotificationChannelMutation,
  useDeleteNotificationChannelTemplatesByDestinationMutation,
} from "src/graphql";
import { useRecipients } from "src/pages/alerting/utils";
import { ChannelData } from "./create-recipient-modal";
import { Recipients } from "./recipients";
import { Channel } from "src/components/notification-channels/channel-definitions";

export const ParentRecipients = ({
  resourceId,
  resourceType,
}: {
  resourceId: string;
  resourceType: ParentResourceTypes;
}) => {
  const { getRecipientsForParentResourceIds } = useRecipients();

  const { mutateAsync: deleteParentRecipients } =
    useDeleteNotificationChannelTemplatesByDestinationMutation();

  const { mutateAsync: createRecipient } =
    useCreateNotificationTemplateChannelMutation();

  const { mutateAsync: createWorkspaceNotificationChannel } =
    useCreateWorkspaceNotificationChannelMutation();

  const recipients = getRecipientsForParentResourceIds([resourceId]);

  const onCreateRecipient = async ({ config, channelType }: ChannelData) => {
    await createWorkspaceNotificationChannel({
      object: {
        channel_type: channelType,
        config,
        notification_channel_templates: {
          data: [
            {
              status: "unhealthy",
              parent_resource_id: resourceId,
              parent_resource_type: resourceType,
            },
          ],
        },
      },
    });
  };

  const onAdd = async (channel: Channel) => {
    await createRecipient({
      object: {
        status: "unhealthy",
        parent_resource_type: resourceType,
        parent_resource_id: resourceId,
        channel_id: channel.id,
      },
    });
  };

  const onRemove = async (channel: Channel) => {
    await deleteParentRecipients({
      channelId: channel.id,
      destinationIds: [resourceId],
    });
  };

  return (
    <Recipients
      channels={recipients.map((r) => r.channel)}
      onAdd={onAdd}
      onRemove={onRemove}
      onCreate={onCreateRecipient}
    />
  );
};
