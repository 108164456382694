import { HTImage } from "src/components/image";
import {
  forwardRef,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  AudienceIcon,
  Box,
  ClickIcon,
  Column,
  CubeIcon,
  FrameStackIcon,
  MergeIcon,
  Row,
  SearchInput,
  SparkleIcon,
  SplitIcon,
  TableIcon,
  Text,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  TraitIcon,
} from "@hightouchio/ui";
import isEqual from "lodash/isEqual";

import placeholder from "src/assets/placeholders/generic.svg";
import { convertType, NEW_ICON_MAP } from "src/utils/destinations";
import { getSearchRegExp } from "src/utils/string";

import {
  ColumnModelType,
  ColumnOption,
  useFormkitContext,
} from "../formkit-context";
import { FormProps } from "../types";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { IconBox } from "src/components/icon-box";

const ROW_HEIGHT = 32;

enum ToggleOption {
  Column = "columns",
  Split = "split columns",
  Trait = "traits",
}

const getHeaderIcon = (entity: ColumnOption["entity"]) => {
  if (!entity) return undefined;

  if (entity.isSplit) {
    return SplitIcon;
  }

  if (entity.isTrait) {
    return TraitIcon;
  }

  if (entity.isMerged || entity.modelType === "journey-event") {
    return MergeIcon;
  }

  return TableIcon;
};

interface OnChangeValueProp {
  from: string | Record<string, unknown>;
  type: "standard";
}

const StandardInput = forwardRef<
  HTMLInputElement,
  Readonly<
    FormProps & {
      onChange: (onClose: () => void, value: OnChangeValueProp) => void;
      onClose: () => void;
    }
  >
>(({ onClose, onChange, value }, ref) => {
  const [search, setSearch] = useState<string>("");
  const [selectedSection, setSelectedSection] = useState<
    ToggleOption | undefined
  >(undefined);
  const { columns: allColumns } = useFormkitContext();
  const selectedColumnRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleToggleChange = (value: ToggleOption): void => {
    setSelectedSection(value);
    // Scroll the selected section into view
    if (containerRef.current) {
      const container = containerRef.current;
      let indexToScrollTo = 0;

      if (value !== ToggleOption.Column) {
        for (const optionGroup of allColumns) {
          const isSplitGroup = optionGroup.entity?.isSplit;
          const isTraitGroup = optionGroup.entity?.isTrait;

          if (value === ToggleOption.Split && isSplitGroup) {
            break;
          }

          if (value === ToggleOption.Trait && isTraitGroup) {
            break;
          }

          // 1 accounts for the header row
          indexToScrollTo += 1 + (optionGroup.options?.length ?? 0);
        }
      }

      // scrollIntoView does not work well with the ChakraPopover as it causes the entire page to scroll
      container.scrollTo({
        behavior: "smooth",
        top: indexToScrollTo * ROW_HEIGHT,
      });
    }
  };

  const options: ColumnOption[] | undefined = useMemo(() => {
    const nonMbColumns = allColumns?.filter((column) => {
      return column.label !== "boosted";
    });

    if (search) {
      const regex = getSearchRegExp(search, "i");
      const filteredColumns: ColumnOption[] = [];

      for (const column of nonMbColumns ?? []) {
        if (column.options) {
          filteredColumns.push({
            ...column,
            options: column.options.filter(
              (option) =>
                regex.test(option.entity?.name || "") ||
                regex.test(option.label),
            ),
          });
        } else if (
          regex.test(column.entity?.name || "") ||
          regex.test(column.label)
        ) {
          filteredColumns.push(column);
        }
      }
      return filteredColumns;
    }

    return nonMbColumns;
  }, [allColumns, search, value]);

  useEffect(() => {
    if (selectedColumnRef && selectedColumnRef.current) {
      selectedColumnRef.current.scrollIntoView({
        block: "center",
      });
    }
  }, []);

  const hasMultipleGroups = options.length > 1;

  const hasTraitColumns = options.some((option) => option.entity?.isTrait);
  const hasSplitColumns = options.some((option) => option.entity?.isSplit);

  return (
    <Column flex={1} pl={3} pr={3} pt={1}>
      <Row gap={2}>
        <SearchInput
          autoFocus
          placeholder="Search columns..."
          ref={ref}
          value={search}
          width="100%"
          onChange={(event) => setSearch(event.target.value)}
        />
        {hasMultipleGroups && (
          <ToggleButtonGroup
            value={selectedSection}
            onChange={(value) => handleToggleChange(value as ToggleOption)}
          >
            <ToggleButton
              aria-label="Columns"
              icon={TableIcon}
              value={ToggleOption.Column}
              tooltip={{
                message: "Model columns",
                placement:
                  !hasSplitColumns && !hasTraitColumns ? "top-end" : undefined,
              }}
            />
            {hasTraitColumns && (
              <ToggleButton
                aria-label="Trait columns"
                icon={TraitIcon}
                value={ToggleOption.Trait}
                tooltip={{
                  message: "Trait columns",
                  placement: !hasSplitColumns ? "top-end" : undefined,
                }}
              />
            )}
            {hasSplitColumns && (
              <ToggleButton
                aria-label="Split columns"
                icon={SplitIcon}
                value={ToggleOption.Split}
                tooltip={{
                  message: "Split columns",
                  placement: "top-end",
                }}
              />
            )}
          </ToggleButtonGroup>
        )}
      </Row>
      <Column
        position="relative"
        height="227px"
        pb={1}
        mt={2}
        overflow="auto"
        ref={containerRef}
      >
        {options.map((option, index) => {
          if (option.options && option.options.length) {
            const groupLabel = option.label;

            return (
              <Fragment key={index}>
                {hasMultipleGroups && (
                  <Box
                    position="sticky"
                    display="grid"
                    gridTemplateColumns="20px 2fr 1fr"
                    columnGap={2}
                    minHeight="32px"
                    px={2}
                    top={0}
                    alignItems="center"
                    bg="base.background"
                    borderY="1px"
                    color="base.border"
                  >
                    <Box
                      as={getHeaderIcon(option.entity)}
                      boxSize={5}
                      color="text.placeholder"
                      mr={3}
                    />
                    <TextWithTooltip
                      color="text.secondary"
                      fontWeight="semibold"
                      letterSpacing="wide"
                      size="sm"
                      textTransform="uppercase"
                    >
                      {groupLabel}
                    </TextWithTooltip>
                    <Text
                      isTruncated
                      color="text.secondary"
                      fontWeight="semibold"
                      letterSpacing="wide"
                      size="sm"
                      textTransform="uppercase"
                    >
                      Data source
                    </Text>
                  </Box>
                )}

                {option.options.map((groupedOption) => {
                  const selected = isEqual(groupedOption.value, value.from);
                  return (
                    <Option
                      key={JSON.stringify(groupedOption.value)}
                      showDataSourceColumn={hasMultipleGroups}
                      ref={selected ? selectedColumnRef : undefined}
                      group={option.label}
                      option={groupedOption}
                      selected={selected}
                      onClick={() =>
                        onChange(onClose, {
                          type: value.type ?? "standard",
                          from: groupedOption.value,
                        })
                      }
                    />
                  );
                })}
              </Fragment>
            );
          }
          if (option.options && !option.options.length) {
            return null;
          }
          const selected = isEqual(option.value, value);
          return (
            <Option
              key={JSON.stringify(option.value)}
              showDataSourceColumn={hasMultipleGroups}
              ref={selected ? selectedColumnRef : undefined}
              option={option}
              selected={selected}
              onClick={() =>
                onChange(onClose, {
                  type: value.type ?? "standard",
                  from: option.label,
                })
              }
            />
          );
        })}
        {options.every((option) => !option?.options?.length) && (
          <Column
            alignItems="center"
            border="1px solid"
            borderColor="gray.300"
            borderRadius="md"
            flex={1}
            gap={2}
            justifyContent="center"
            mb={3}
            py={2}
          >
            <HTImage height="92px" src={placeholder} decorative />
            <Box as={Text} color="text.secondary" fontSize="2xl">
              No results found
            </Box>
            <Text color="text.secondary" size="md" fontWeight="normal">
              Try your search again
            </Text>
          </Column>
        )}
      </Column>
    </Column>
  );
});

StandardInput.displayName = "StandardInput";
export { StandardInput };

const Option = forwardRef<
  HTMLDivElement,
  {
    group?: string;
    option: ColumnOption;
    selected: boolean;
    onClick: () => void;
    showDataSourceColumn?: boolean;
  }
>(({ group, onClick, option, selected, showDataSourceColumn = false }, ref) => {
  const { customType, description, label, type = "", value } = option;
  const isBoosted = typeof value == "object" && value.type === "boosted";
  const isAudienceTrait =
    group === ToggleOption.Trait &&
    typeof value == "object" &&
    value.type === "additionalColumnReference";
  const Icon = isBoosted
    ? SparkleIcon
    : NEW_ICON_MAP[convertType(customType || type || "")];

  return (
    <Box
      ref={ref}
      display="grid"
      gridTemplateColumns="20px 2fr 1fr"
      columnGap={2}
      px={2}
      minHeight="32px"
      _hover={{
        bg: "gray.100",
      }}
      alignItems="center"
      bg={selected ? "forest.100" : undefined}
      borderBottom="1px"
      borderColor="gray.300"
      cursor="pointer"
      pointerEvents={selected ? "none" : undefined}
      onClick={onClick}
    >
      {Icon ? (
        <Box mr={3}>
          <Box
            as={Icon}
            boxSize={5}
            color={isBoosted ? "warning.400" : "text.placeholder"}
            fontSize="20px"
          />
        </Box>
      ) : (
        <Box boxSize={5} mr={3} />
      )}
      <Row gap={2} whiteSpace="nowrap">
        <TextWithTooltip
          color={selected ? "forest.600" : "gray.900"}
          fontWeight="medium"
        >
          {label}
        </TextWithTooltip>

        {isAudienceTrait && (
          <Tooltip message="Audience specific trait enrichment">
            <Box as={AudienceIcon} boxSize={4} color="text.secondary" />
          </Tooltip>
        )}

        {description && (
          <TextWithTooltip color="text.secondary" size="sm">
            {description}
          </TextWithTooltip>
        )}
      </Row>
      {showDataSourceColumn && (
        <Row gap={2}>
          {option.entity?.modelType && <ModelIcon entity={option.entity} />}
          <TextWithTooltip color="text.secondary" fontWeight="medium">
            {option.entity?.name ?? "Model"}
          </TextWithTooltip>
        </Row>
      )}
    </Box>
  );
});

Option.displayName = "Option";

const sharedIconBoxProps = {
  boxSize: "20px",
  iconSize: "12px",
};

const ModelIcon = ({
  entity,
}: {
  entity:
    | { isSplit: boolean; isTrait: boolean; modelType: ColumnModelType | null }
    | undefined;
}) => {
  if (!entity) return null;

  if (entity.isSplit) {
    return (
      <IconBox {...sharedIconBoxProps} icon={<AudienceIcon />} bg="ocean.400" />
    );
  }

  if (entity.modelType === "model") {
    return (
      <IconBox {...sharedIconBoxProps} icon={<CubeIcon />} bg="electric.400" />
    );
  }

  if (entity.modelType === "related") {
    return (
      <IconBox
        {...sharedIconBoxProps}
        icon={<FrameStackIcon />}
        bg="grass.400"
      />
    );
  }

  if (entity.modelType === "journey-event") {
    return (
      <IconBox
        {...sharedIconBoxProps}
        icon={<ClickIcon />}
        color="cyan.400"
        bg="white"
      />
    );
  }

  if (entity.isTrait) {
    return (
      <IconBox {...sharedIconBoxProps} icon={<TraitIcon />} bg="orange.400" />
    );
  }

  return null;
};
