import { Navigate, RouteObject } from "src/router";

import { CreateIdentityResolutionGraph } from "./create";
import {
  IdentityResolutionGraph,
  IdentityResolutionGraphLoader,
} from "./graph";
import { IdentityResolutionGraphs } from "./graphs";
import { Summary } from "./graph/summary";
import { Configuration } from "./graph/configuration";
import { CreateIdentityResolutionModel } from "./graph/create-model";
import { Rules } from "./graph/rules";
import { Runs } from "./graph/runs";
import { Model } from "./graph/model";
import { GoldenRecord } from "./graph/golden-record";
import { GoldenRecordColumn } from "./graph/golden-record-column";
import { Inspector } from "./graph/inspector";
import { InspectorDetails } from "./graph/inspector-details";
import { Models } from "./graph/models";

export const routes: Array<RouteObject> = [
  { path: "idr", element: <IdentityResolutionGraphs /> },
  { path: "idr/new", element: <CreateIdentityResolutionGraph /> },
  {
    path: "idr/:id/*",
    element: <IdentityResolutionGraphLoader />,
    children: [
      {
        element: <IdentityResolutionGraph />,
        children: [
          {
            index: true,
            element: <Navigate to={`summary${location.search}`} replace />,
          },
          { path: "summary", element: <Summary /> },
          { path: "runs", element: <Runs /> },
          {
            path: "models/*",
            element: <Models />,
            children: [
              { path: ":modelId", element: <Model /> },
              { path: "new", element: <CreateIdentityResolutionModel /> },
            ],
          },
          { path: "rules", element: <Rules /> },
          { path: "configuration", element: <Configuration /> },
          {
            path: "golden-record",
            element: <GoldenRecord />,
            children: [
              { path: ":columnName", element: <GoldenRecordColumn /> },
            ],
          },
          {
            path: "inspector",
            element: <Inspector />,
            children: [{ path: ":htId", element: <InspectorDetails /> }],
          },
        ],
      },
    ],
  },
];
