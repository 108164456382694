import { useFlags } from "launchdarkly-react-client-sdk";
import { useEntitlements } from "src/hooks/use-entitlement";
import { NavSection } from "src/components/layout/nav/nav-section";

import { Icon } from "src/components/layout/nav/icons/customer-studio";
import { ActiveSectionProps } from "src/components/layout/nav/utils";
import { UseSectionHook } from "./types";

export const useCustomerStudioSection: UseSectionHook = () => {
  const { data: entitlementsData } = useEntitlements(false);
  const {
    appPerformancePage,
    appDestinationRulesEnabled,
    audienceTemplatesEnabled,
  } = useFlags();

  return {
    title: "Customer Studio",
    isEnabled: entitlementsData.entitlements.audiences,
    links: [
      {
        href: "/audiences",
        label: "Audiences",
        permission: { v1: { resource: "audience", grant: "read" } },
      },
      entitlementsData.entitlements.journeys && {
        href: "/journeys",
        label: "Journeys",
        permission: { v1: { resource: "audience", grant: "read" } },
      },
      {
        href: "/priority-lists",
        label: "Priority lists",
        permission: { v1: { resource: "audience", grant: "read" } },
      },
      appPerformancePage && {
        href: "/performance",
        label: "Performance",
        permission: { v1: { resource: "audience", grant: "read" } },
      },
      {
        href: "/traits",
        label: "Traits",
        permission: { v1: { resource: "audience", grant: "read" } },
      },
      {
        href: "/schema-v2",
        label: "Schema",
        permission: { v1: { resource: "audience_schema", grant: "read" } },
      },
      appDestinationRulesEnabled && {
        href: "/governance",
        label: "Governance",
        permission: { v1: { resource: "audience_schema", grant: "read" } },
      },
      audienceTemplatesEnabled && {
        href: "/templates",
        label: "Templates",
        permission: { v1: { resource: "audience_schema", grant: "read" } },
      },
    ],
  };
};

export const CustomerStudio = (props: ActiveSectionProps) => {
  const section = useCustomerStudioSection();
  return <NavSection icon={Icon} {...section} {...props} />;
};
