import { capitalize } from "lodash";
import pluralize from "pluralize";

import { DecisionEngineChannelType } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  Box,
  Column,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PlayIcon,
  PlusIcon,
  Row,
  SectionHeading,
  Skeleton,
  Text,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { FC } from "react";

import { Card } from "src/components/card";
import { DeleteButton } from "src/components/delete-button";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  DecisionEngineFlowQuery,
  useDeleteDecisionEngineMessageMutation,
  useEnableDecisionEngineFlowMessageMutation,
} from "src/graphql";
import { OutletContext } from "src/pages/decision-engines";
import { ChannelIcon } from "src/pages/decision-engines/components/channel-icon";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";
import { SyncsCell } from "src/pages/syncs/sync/components/syncs-cell";
import {
  LinkButton,
  useNavigate,
  useOutletContext,
  useParams,
} from "src/router";
import { Table } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";
import { DestinationResourceLink } from "./message/components/campaign-link";
import { ActionStatusBadge, useActionStatus } from "./status";

export type Message = NonNullable<
  DecisionEngineFlowQuery["decision_engine_flows_by_pk"]
>["messages"][0];

type Props = {
  messages: Array<Message>;
  agentEnabled: boolean;
};

export const Messages: FC<Readonly<Props>> = ({ messages, agentEnabled }) => {
  const { flowId } = useParams<{ flowId: string }>();
  const { engine } = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const enableMutation = useEnableDecisionEngineFlowMessageMutation();
  const deleteMutation = useDeleteDecisionEngineMessageMutation();

  const { selectedRows, onRowSelect } = useRowSelect();

  const handleEnableSelected = async () => {
    const resp = await Promise.allSettled(
      Array.from(selectedRows).map((id) =>
        enableMutation.mutateAsync({
          flowId: flowId!,
          messageId: String(id),
        }),
      ),
    );

    if (resp.every((r) => r.status === "fulfilled")) {
      toast({
        id: "enable-messages",
        title: "Actions enabled",
        variant: "success",
      });
      onRowSelect([]);
    } else {
      if (resp.every((r) => r.status === "rejected")) {
        toast({
          id: "enable-messages",
          title: "Error enabling actions",
          variant: "error",
        });
      } else {
        toast({
          id: "enable-messages",
          title: "Some actions were not enabled successfully",
          variant: "warning",
          message: `${resp.filter((r) => r.status === "fulfilled").length} of ${resp.length} actions created successfully`,
        });
      }
    }
  };

  const isSingleDestination = engine.channels.length === 1;
  const channelDefinition = isSingleDestination
    ? getChannelDefinition(engine.channels[0]!.destination.type)
    : null;

  return (
    <Card gap={4} p={6}>
      <Row justify="space-between" gap={4}>
        <Column>
          <SectionHeading>Actions</SectionHeading>
          <Text>
            Individual interactions that can be scheduled as a part of this
            agent
          </Text>
        </Column>

        <Row gap={2} align="center">
          {selectedRows.length > 0 && (
            <>
              <Text>
                {selectedRows.length} {pluralize("action", selectedRows.length)}{" "}
                selected
              </Text>
              <Menu>
                <MenuButton>Actions</MenuButton>
                <MenuList>
                  <MenuItem
                    isDisabled={!selectedRows.length}
                    icon={PlayIcon}
                    onClick={handleEnableSelected}
                  >
                    Enable
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          )}

          <Tooltip
            message="Configure a channel before adding actions"
            isDisabled={Boolean(engine.channels.length)}
          >
            <LinkButton
              href="messages/add"
              icon={PlusIcon}
              isDisabled={!engine.channels.length}
            >
              Add actions
            </LinkButton>
          </Tooltip>
        </Row>
      </Row>
      <Table
        columns={[
          {
            name: "Status",
            max: "max-content",
            cell: ({ model, enabled, config, message: { channel } }) => {
              // There should only be one sync per action created by the decision engine
              const sync = model?.syncs[0];

              const { status, error, isLoading } = useActionStatus({
                sync,
                channel,
                resourceId: config.campaignId ?? config.resourceId,
                enabled,
                agentEnabled,
              });

              const channelLabel = capitalize(channel.destination.type);
              const resourceType = getChannelDefinition(
                channel.destination.type,
              ).getResourceType(channel);

              return (
                <Skeleton isLoading={isLoading}>
                  <ActionStatusBadge
                    status={status}
                    error={error}
                    channelLabel={channelLabel}
                    resourceType={resourceType}
                  />
                </Skeleton>
              );
            },
          },
          {
            name: "Name",
            cell: ({ message: { name, channel } }) => (
              <Row gap={2} align="center" overflow="hidden">
                <Box flexShrink={0}>
                  <ChannelIcon
                    type={channel.type as DecisionEngineChannelType}
                  />
                </Box>
                <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
              </Row>
            ),
          },
          {
            name: "Syncs",
            min: "150px",
            max: "min-content",
            disabled: ({ model }) => Boolean(model?.syncs.length),
            cell: ({ model }) => {
              if (!model?.syncs[0]) {
                return <Text color="text.secondary">None</Text>;
              }
              return (
                <Box onClick={(event) => event.stopPropagation()}>
                  <SyncsCell syncs={model.syncs} />
                </Box>
              );
            },
          },
          {
            // Use specific resource name if there is only one destination
            name: channelDefinition
              ? channelDefinition.getResourceName(engine.channels[0]!.config)
              : "Destination resource",
            max: ".5fr",
            cell: ({ message: { channel }, config }) => {
              if (channel.type === "raw") {
                return <Text color="text.secondary">N/A</Text>;
              }
              return (
                <DestinationResourceLink
                  channel={channel}
                  resourceId={config.resourceId ?? config.campaignId}
                  showInactiveTooltip={false}
                />
              );
            },
          },
          {
            name: "",
            max: "max-content",
            cell: ({ message: { id } }) => {
              return (
                <Box onClick={(event) => event.stopPropagation()}>
                  <DeleteButton
                    variant="icon"
                    label="message"
                    onDelete={async () => {
                      const response = await deleteMutation.mutateAsync({
                        id,
                      });
                      if (
                        response.deleteDecisionEngineMessage.__typename ===
                        "DecisionEngineError"
                      ) {
                        throw new Error(
                          response.deleteDecisionEngineMessage.error,
                        );
                      }
                    }}
                  />
                </Box>
              );
            },
          },
        ]}
        primaryKey="id"
        data={messages.map((message) => ({
          ...message,
          id: message.message.id,
        }))}
        placeholder={{
          title: "No actions",
        }}
        onRowClick={({ message: { id } }) => navigate(`messages/${id}`)}
        onSelect={onRowSelect}
        selectedRows={selectedRows}
      />
    </Card>
  );
};
