import { type FC, useEffect, useRef } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  Dialog,
  FormField,
  Select,
  TextInput,
  Textarea,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatInTimeZone } from "date-fns-tz";
import { useFlags } from "launchdarkly-react-client-sdk";
import lodashOrderBy from "lodash/orderBy";
import { Controller } from "react-hook-form";

import { useNavigate } from "src/router";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import {
  useCloneJourneyMutation,
  useCreateJourneyMutation,
  useParentModelsWithIconsQuery,
  ParentModelsWithIconsQuery,
} from "src/graphql";
import { JourneyStatus } from "src/types/journeys";
import { DEFAULT_INTERVAL_SCHEDULE } from "src/pages/journeys/constants";
import { CreateJourneySchema } from "src/pages/journeys/forms";
import { getCreateJourneyPermission } from "src/pages/journeys/permission-utils";
import type {
  CloneJourneyInput,
  CreateJourneyForm,
} from "src/pages/journeys/types";
import { QueryType } from "src/types/models";

type CreateJourneyModalProps = {
  isOpen: boolean;
  closeModal?: () => void;
  cloneJourneyParams?: CloneJourneyInput;
};

export const CreateJourneyModal: FC<CreateJourneyModalProps> = ({
  isOpen,
  closeModal,
  cloneJourneyParams,
}) => {
  const navigate = useNavigate();
  const { enableRedshiftJourneys } = useFlags();

  const currentDateOnMount = useRef(
    formatInTimeZone(
      new Date(),
      Intl.DateTimeFormat().resolvedOptions().timeZone,
      "MM-dd-yyyy hh:mma z",
    ),
  );

  const createJourneyMutation = useCreateJourneyMutation();
  const cloneJourneyMutation = useCloneJourneyMutation();
  const isCloning = Boolean(cloneJourneyParams?.sourceJourneyId);

  const parentModelsQuery = useParentModelsWithIconsQuery(undefined, {
    select: (data) => {
      return lodashOrderBy(
        data.segments,
        ["connection.plan_in_warehouse", "name"],
        ["desc", "asc"],
      );
    },
  });
  const parentModelOptions: ParentModelsWithIconsQuery["segments"] =
    parentModelsQuery?.data || [];

  const createJourney = async (data: CreateJourneyForm) => {
    const result = await createJourneyMutation.mutateAsync({
      input: {
        name: data.name,
        description: data.description ? data.description : null,
        parent_model_id: data.parentModelId?.toString(),
        status: JourneyStatus.Disabled,
        is_latest: true,
        schedule: DEFAULT_INTERVAL_SCHEDULE,
      },
    });

    if (result.insert_journeys_one) {
      navigate(`/journeys/${result.insert_journeys_one.id}`);
    }
  };

  const cloneJourney = async (data: CreateJourneyForm) => {
    if (!isCloning || !cloneJourneyParams?.sourceJourneyId) {
      return;
    }
    const result = await cloneJourneyMutation.mutateAsync({
      input: {
        journey: {
          id: "", // Id will be generated on the backend.
          name: data.name,
          description: data.description ? data.description : null,
          status: JourneyStatus.Disabled,
        },
        source_journey_id: cloneJourneyParams.sourceJourneyId,
      },
    });

    if (result?.cloneJourney?.journey_id) {
      navigate(`/journeys/${result?.cloneJourney?.journey_id}`);
    }
  };

  const form = useHightouchForm<CreateJourneyForm>({
    onSubmit: isCloning ? cloneJourney : createJourney,
    resolver: yupResolver(CreateJourneySchema),
    success: false,
    values: isCloning
      ? {
          name: `Copy of ${cloneJourneyParams?.name}`,
          description: cloneJourneyParams?.description || null,
          parentModelId: cloneJourneyParams
            ? Number(cloneJourneyParams.parentModelId)
            : null,
        }
      : {
          name: `Journey created ${currentDateOnMount.current}`,
          description: null,
          parentModelId: null,
        },
  });

  const onCloseModal = () => {
    form.reset();
    if (!closeModal) {
      navigate("/journeys");
    } else {
      closeModal();
    }
  };

  // If there's only one parent model option, pre-select it
  useEffect(() => {
    if (!isCloning && parentModelOptions?.length === 1) {
      form.reset({
        name: `Journey created ${currentDateOnMount.current}`,
        description: null,
        parentModelId: parentModelOptions[0]!.id,
      });
    }
  }, [parentModelOptions, isCloning, form.reset]);

  return (
    <Form form={form}>
      <Dialog
        isOpen={isOpen}
        title="Create journey"
        variant="form"
        onClose={onCloseModal}
        actions={
          <ButtonGroup>
            <Button onClick={onCloseModal}>Cancel</Button>
            <SaveButton
              enablePristineSubmit={isCloning}
              permission={getCreateJourneyPermission(
                form.watch("parentModelId")?.toString(),
              )}
              size="md"
            >
              Create journey
            </SaveButton>
          </ButtonGroup>
        }
      >
        <Column gap={4}>
          <Controller
            control={form.control}
            name="parentModelId"
            render={({ field, fieldState }) => (
              <FormField
                label="Select a parent model"
                error={fieldState.error?.message}
              >
                <Select
                  isDisabled={isCloning}
                  isInvalid={Boolean(fieldState.error?.message)}
                  isOptionDisabled={(option) =>
                    !option.connection?.plan_in_warehouse ||
                    !option.connection?.definition.supportedQueries.includes(
                      QueryType.JourneyNode,
                    ) ||
                    (option.connection?.definition?.type === "redshift" &&
                      !enableRedshiftJourneys)
                  }
                  isLoading={parentModelsQuery.isLoading}
                  placeholder="Select a parent model"
                  optionLabel={(option) => option.name}
                  optionValue={(option) => option.id}
                  optionDescription={(option) => {
                    if (
                      !option.connection?.definition.supportedQueries.includes(
                        QueryType.JourneyNode,
                      ) ||
                      (option.connection?.definition?.type === "redshift" &&
                        !enableRedshiftJourneys)
                    ) {
                      return "This source does not support journeys";
                    }
                    if (!option.connection?.plan_in_warehouse) {
                      return "Lightning sync engine must be enabled to use this parent model in journeys";
                    }
                    return "";
                  }}
                  optionAccessory={(option) => ({
                    type: "image",
                    url: option.connection?.definition.icon ?? "",
                  })}
                  options={parentModelOptions}
                  width="100%"
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormField>
            )}
          />
          <Controller
            control={form.control}
            name="name"
            render={({ field, fieldState }) => (
              <FormField label="Journey name" error={fieldState.error?.message}>
                <TextInput
                  placeholder="Enter a name..."
                  width="100%"
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormField>
            )}
          />
          <Controller
            control={form.control}
            name="description"
            render={({ field, fieldState }) => (
              <FormField
                isOptional
                label="Journey description"
                error={fieldState.error?.message}
              >
                <Textarea
                  placeholder="Enter a description..."
                  width="100%"
                  value={field.value ?? ""}
                  onChange={field.onChange}
                />
              </FormField>
            )}
          />
        </Column>
      </Dialog>
    </Form>
  );
};
