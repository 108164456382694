export enum QueryType {
  RawSql = "raw_sql",
  Visual = "visual",
  Table = "table",
  DbtModel = "dbt_model",
  LookerLook = "looker_look",
  Custom = "custom",
  Sigma = "sigma",
  JourneyNode = "journey-node",
  DecisionEngine = "decision-engine",
  IDRGoldenRecord = "idr-golden-record",
}

export const ManagedQueryTypes = [
  QueryType.JourneyNode,
  QueryType.DecisionEngine,
  QueryType.IDRGoldenRecord,
] as const;

export function isQueryType(
  type: string | null | undefined,
): type is QueryType {
  return Object.values(QueryType).includes(type as QueryType);
}

export function isManagedQueryType(
  type: string | null | undefined,
): type is ManagedQueryType {
  return ManagedQueryTypes.includes(type as any);
}

export type ManagedQueryType = (typeof ManagedQueryTypes)[number];
