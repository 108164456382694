import { FC } from "react";

import type {
  JourneyEventType,
  JourneyNode,
  JourneyNodeRun,
} from "src/pages/journeys/types";
import { IconBox } from "src/components/icon-box";
import { Heading, Text, Row, Column, JourneyIcon } from "@hightouchio/ui";
import { JourneyNodeType } from "src/types/journeys";
import { commaNumber } from "src/utils/numbers";
import { JourneyRun } from "src/pages/journeys/types";
import { NODE_WITH_BRANCHES_LIBRARY_OPTIONS } from "src/pages/journeys/node-library";
import pluralize from "pluralize";

const eventMapToHeaderCopy: Record<JourneyEventType, string> = {
  "in-progress": "are currently in",
  "entered-journey": "entered",
  "completed-journey": "completed",
  "exited-journey": "completed", // Same as 'completed-journey'
  "exited-journey-by-criteria": "exited by exit rule from",
  "moved-to-node": "advanced from", // Used for typechecking
  "entered-tile": "entered into",
  "advanced-from": "advanced from",
};

const getJourneySubheaderCopy = (options: {
  eventType?: JourneyEventType;
  nodeName?: string;
  isPreview: boolean;
}): string => {
  const { eventType, nodeName, isPreview } = options;
  const eventSubheader = eventMapToHeaderCopy[eventType || ""];
  if (!eventSubheader) return ""; // Return early if no valid event type

  let subheader =
    (isPreview ? "Preview of users who " : "Users who ") + eventSubheader;

  if (nodeName) {
    subheader += ` "${nodeName}" tile`;
  } else {
    subheader += " journey";
  }

  return subheader;
};

const NodeTypeToIcon: FC<{ nodeType?: JourneyNodeType }> = ({
  nodeType,
}): JSX.Element => {
  const tileMetadata = NODE_WITH_BRANCHES_LIBRARY_OPTIONS.find(
    ({ type }) => type === nodeType,
  );

  if (nodeType == null || tileMetadata == null) {
    return (
      <Row color="text.tertiary" fontSize="24px">
        <JourneyIcon />
      </Row>
    );
  }

  return (
    <IconBox
      bg={`${tileMetadata?.color ?? "gray"}.400`}
      icon={tileMetadata.icon}
    />
  );
};
const getAssociatedCountForNodeEvent = (options: {
  event?: JourneyEventType;
  latestJourneyRun?: JourneyRun;
  currentNode?: JourneyNode;
  currentNodeStats?: JourneyNodeRun;
  numberUsersInJourney?: number;
  numberUniqueUsersInJourney?: number;
}): number => {
  const {
    event,
    latestJourneyRun,
    currentNode,
    currentNodeStats,
    numberUsersInJourney,
  } = options;

  if (latestJourneyRun == null) {
    return 0;
  } else if (event == null) {
    return Number(latestJourneyRun.entry_count);
  }

  switch (event) {
    case "entered-journey":
      return Number(latestJourneyRun.entry_count);
    case "exited-journey":
    case "completed-journey": // We should only see the above
      if (currentNode != null) {
        return Number(currentNodeStats?.completed_count);
      }
      return Number(latestJourneyRun.completed_count);
    case "exited-journey-by-criteria":
      if (currentNode != null) {
        return Number(currentNodeStats?.exit_count);
      }
      return Number(latestJourneyRun.exit_count);
    case "advanced-from":
      return Number(currentNodeStats?.advanced_count || 0);
    case "entered-tile":
      return Number(currentNodeStats?.entry_count || 0);
    case "in-progress":
      if (currentNode != null) {
        // Current users in a given node
        return Number(currentNode.data.number_users);
      }
      // Current users in the entire journey
      return numberUsersInJourney || 0;
    default:
      return 0;
  }
};

export const MemberDetailHeader: FC<{
  loading?: boolean;
  latestJourneyRun?: JourneyRun;
  event?: JourneyEventType;
  currentNode?: JourneyNode;
  currentNodeStats?: JourneyNodeRun;
  numberUsersInJourney?: number;
  numberUniqueUsersInJourney?: number;
}> = ({
  event,
  latestJourneyRun,
  currentNode,
  currentNodeStats,
  loading,
  numberUsersInJourney,
  numberUniqueUsersInJourney,
}) => {
  const subheading = getJourneySubheaderCopy({
    eventType: event,
    nodeName: currentNode?.data?.name,
    // We hardcap this member drawer to 100 rows/users for performance reasons,
    // even if there are more rows in the table (no pagination).
    // If there are more than 100, we denote that it's a "preview"/subset to the user,
    // but if there are <= 100 rows, we can show all rows!
    isPreview: Number(currentNodeStats) > 100,
  });

  const currentUserCount = getAssociatedCountForNodeEvent({
    event,
    latestJourneyRun,
    currentNode,
    currentNodeStats,
    numberUsersInJourney,
    numberUniqueUsersInJourney,
  });

  return (
    <Row align="center" justify="space-between" gap={2}>
      <NodeTypeToIcon nodeType={currentNode?.data?.config?.type} />
      <Column px={2}>
        <Heading>
          {loading
            ? "Calculating..."
            : ` ${commaNumber(Number(currentUserCount))} ${pluralize("user", currentUserCount)}`}
        </Heading>
        <Text size="md" fontWeight="medium" color="text.tertiary">
          {subheading}
        </Text>
      </Column>
    </Row>
  );
};
