import { useState } from "react";

import { Box, Row, SectionHeading, Select } from "@hightouchio/ui";
import { BreakdownTable } from "src/pages/analytics/breakdown-table";
import { GroupByColumn, TimeOptions } from "src/pages/analytics/types";
import {
  AnalyticsFrequency,
  AndOrCondition,
  PropertyCondition,
} from "src/types/visual";
import { Flow } from "..";
import { FlowChartTimeOptions, FlowChartWrapper } from "./flow-chart-wrapper";

type SendsByBreakdownChartProps = {
  flowId: string;
  parentModelId: string | number | undefined | null;
  flowMessages: Flow["messages"];
  lastFlowRunStartedAt: string;
  flowStartDate: string;
  breakdownOptions: { label: string; value: GroupByColumn }[];
  defaultBreakdownColumn: GroupByColumn;
  conditions?: AndOrCondition<PropertyCondition>[];
};

export const SendsByBreakdownChart = ({
  flowId,
  parentModelId,
  flowMessages,
  lastFlowRunStartedAt,
  flowStartDate,
  breakdownOptions,
  defaultBreakdownColumn,
  conditions = [],
}: SendsByBreakdownChartProps) => {
  const [timeValue, setTimeValue] = useState<FlowChartTimeOptions>(
    TimeOptions.ThirtyDays,
  );
  const [breakdownByColumn, setBreakdownByColumn] = useState<GroupByColumn>(
    defaultBreakdownColumn,
  );

  return (
    <FlowChartWrapper
      header={
        <Row gap={2}>
          <SectionHeading>Sends breakdown by</SectionHeading>
          <Box width={32}>
            <Select
              options={breakdownOptions}
              value={breakdownByColumn}
              size="sm"
              onChange={(value) => {
                setBreakdownByColumn(value as GroupByColumn);
              }}
              popoverWidth="4xs"
            />
          </Box>
        </Row>
      }
      timeValue={timeValue}
      setTimeValue={setTimeValue}
      allTimeStartDate={flowStartDate}
      lastFlowRunStartedAt={lastFlowRunStartedAt}
      flowId={flowId}
      parentModelId={parentModelId}
      groupByColumns={[breakdownByColumn]}
      frequency={AnalyticsFrequency.All}
      conditions={conditions}
      flowMessages={flowMessages}
      transformForPerformance={false}
    >
      {({ data }) => (
        <BreakdownTable
          data={data}
          groupByColumns={[breakdownByColumn]}
          enableSearch={false}
          hideMetricColumn={true}
          valueColumnName="Sends"
        />
      )}
    </FlowChartWrapper>
  );
};
