import { FC, useMemo } from "react";

import {
  Alert,
  Box,
  Button,
  ChakraAccordion,
  ChakraAccordionButton,
  ChakraAccordionItem,
  ChakraAccordionPanel,
  ChevronRightIcon,
  Column,
  Row,
  Text,
  Tooltip,
  InformationIcon,
  JourneyIcon,
} from "@hightouchio/ui";

import { useParams } from "src/router";
import {
  PERFORMANCE_TABLE_CELL_STYLES,
  PERFORMANCE_TABLE_HEADER_STYLES,
  PERFORMANCE_TABLE_RIGHT_ALIGNED_CELL_STYLES,
  PERFORMANCE_TABLE_RIGHT_ALIGNED_HEADER_STYLES,
} from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import { JourneyRun } from "src/pages/journeys/types";
import { Table } from "src/ui/table";
import { Card } from "./card";
import {
  JourneyNodePerformanceTable,
  PerformanceCellRowData,
} from "src/pages/journeys/components/journey-node-performance-table";
import { CellContent } from "src/pages/journeys/components/journey-node-performance-cell";
import { getIsJourneyRunning } from "src/pages/journeys/utils/get-journey-running-state";

const initialJourneyRun = {
  entry_count: "0",
  unique_entry_count: "0",
  exit_count: "0",
  unique_exit_count: "0",
  completed_count: "0",
  unique_completed_count: "0",
};

const getJourneyRunRowData = (params: {
  journeyRun?: JourneyRun;
  numberOfUsersInJourney?: number;
  numberOfUniqueUsersInJourney?: number;
}): PerformanceCellRowData[] => {
  const {
    entry_count: journeyEntryCount,
    unique_entry_count: journeyUniqueEntryCount,
    exit_count: journeyExitCount,
    unique_exit_count: journeyUniqueExitCount,
    completed_count: journeyCompletedCount,
    unique_completed_count: journeyUniqueCompletedCount,
  } = params.journeyRun ?? initialJourneyRun;
  return [
    {
      label: "In progress",
      total: String(params.numberOfUsersInJourney),
      unique: String(params.numberOfUniqueUsersInJourney),
      eventType: "in-progress",
    },
    {
      label: "Entered",
      total: journeyEntryCount,
      unique: journeyUniqueEntryCount,
      eventType: "entered-journey",
    },
    {
      label: "Met exit criteria",
      total: journeyExitCount,
      unique: journeyUniqueExitCount,
      eventType: "exited-journey-by-criteria",
    },
    {
      label: "Finished",
      total: journeyCompletedCount,
      unique: journeyUniqueCompletedCount,
      eventType: "completed-journey",
    },
  ];
};

export const PerformanceCard: FC = () => {
  const { node_id } = useParams<{ node_id?: string }>();
  const {
    nodes,
    latestJourneyRun,
    lastCompletedJourneyRun,
    nodeRunStats,
    onOpenDrawer,
    numberOfUsersInJourney = 0,
    numberOfUniqueUsersInJourney = 0,
  } = useGraphContext();

  const isRunning = getIsJourneyRunning(latestJourneyRun);
  const journeyRunToDisplay = isRunning
    ? lastCompletedJourneyRun
    : latestJourneyRun;

  const selectedNode = useMemo(() => {
    return nodes.find(({ id }) => id === node_id);
  }, [node_id, nodes]);

  return (
    <Card overflowY="auto" mb={4} pt={0}>
      <ChakraAccordion allowToggle defaultIndex={0}>
        <ChakraAccordionItem border="none">
          {({ isExpanded }) => (
            <>
              <Row
                pt={4}
                align="center"
                justify="space-between"
                sx={{ bg: "white", position: "sticky", top: "0" }}
              >
                <Box>
                  {isRunning && (
                    <Tooltip message="Performance numbers may not reflect the latest state while the journey is running.">
                      <Box
                        as={InformationIcon}
                        boxSize={4}
                        mr={2}
                        color="gray.600"
                      />
                    </Tooltip>
                  )}

                  <Text fontWeight="medium">Performance</Text>
                </Box>

                <ChakraAccordionButton
                  cursor="pointer"
                  outline="none !important"
                  borderRadius="md"
                  display="inline-flex"
                  width="min-content"
                  p={1}
                >
                  <Box
                    as={ChevronRightIcon}
                    fontSize="18px"
                    transform={isExpanded ? "rotate(90deg)" : ""}
                    transition="transform 150ms ease-in-out"
                  />
                </ChakraAccordionButton>
              </Row>
              <ChakraAccordionPanel p={0} pt={4} my="auto">
                <Column gap={4}>
                  <Table
                    rowHeight="24px"
                    data={getJourneyRunRowData({
                      journeyRun: journeyRunToDisplay,
                      numberOfUsersInJourney,
                      numberOfUniqueUsersInJourney,
                    })}
                    columns={[
                      {
                        headerSx: {
                          ...PERFORMANCE_TABLE_HEADER_STYLES,
                          pl: "0 !important",
                        },
                        cellSx: {
                          ...PERFORMANCE_TABLE_CELL_STYLES,
                          pl: "0 !important",
                        },
                        min: "180px",
                        header: () => (
                          <Row as={Text} gap={2} fontWeight="semibold">
                            <Box
                              as={JourneyIcon}
                              fontSize="20px"
                              color="text.secondary"
                            />
                            Entire journey
                          </Row>
                        ),
                        cell: ({ label }, index) => (
                          <Text
                            fontWeight={index === 0 ? "semibold" : "normal"}
                          >
                            {label}
                          </Text>
                        ),
                      },
                      {
                        headerSx: {
                          ...PERFORMANCE_TABLE_HEADER_STYLES,
                          ...PERFORMANCE_TABLE_RIGHT_ALIGNED_HEADER_STYLES,
                        },
                        cellSx: {
                          ...PERFORMANCE_TABLE_CELL_STYLES,
                          ...PERFORMANCE_TABLE_RIGHT_ALIGNED_CELL_STYLES,
                        },
                        header: () => <Text color="text.secondary">Total</Text>,
                        cell: ({ total, eventType }, index) => (
                          <CellContent
                            onOpenDrawer={onOpenDrawer}
                            fontWeight={index == 0 ? "semibold" : "normal"}
                            text={total}
                            eventType={eventType}
                          />
                        ),
                      },
                      {
                        headerSx: {
                          ...PERFORMANCE_TABLE_HEADER_STYLES,
                          ...PERFORMANCE_TABLE_RIGHT_ALIGNED_HEADER_STYLES,
                        },
                        cellSx: {
                          ...PERFORMANCE_TABLE_CELL_STYLES,
                          ...PERFORMANCE_TABLE_RIGHT_ALIGNED_CELL_STYLES,
                        },
                        header: () => (
                          <Text color="text.secondary">Uniques</Text>
                        ),
                        cell: ({ unique, eventType }, index) => (
                          <CellContent
                            onOpenDrawer={onOpenDrawer}
                            fontWeight={index == 0 ? "semibold" : "normal"}
                            text={unique}
                            eventType={eventType}
                          />
                        ),
                      },
                    ]}
                  />
                  {selectedNode ? (
                    <>
                      <JourneyNodePerformanceTable
                        selectedNode={selectedNode}
                        nodeRunStats={nodeRunStats}
                        onOpenDrawer={onOpenDrawer}
                      />

                      <Box>
                        <Button onClick={onOpenDrawer}>
                          View configuration
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Alert
                      title="Select a tile to view its performance"
                      type="subtle"
                      variant="inline"
                    />
                  )}
                </Column>
              </ChakraAccordionPanel>
            </>
          )}
        </ChakraAccordionItem>
      </ChakraAccordion>
    </Card>
  );
};
