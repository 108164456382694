import { Box, Column, Row, AudienceIcon } from "@hightouchio/ui";
import { Link } from "src/router";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { MetadataLabel } from "src/components/metadata-bar";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import type { Flow } from "src/pages/decision-engines/flows";
import { useExperimentsQuery } from "src/graphql";
import { useMemo } from "react";

export const Audience = ({ flow }: { flow: Flow }) => {
  const experimentsQuery = useExperimentsQuery({
    decisionEngineFlowId: flow.id,
  });

  // Calculate percentages for pie chart
  const chartData = useMemo(() => {
    const experiments =
      experimentsQuery.data?.decision_engine_flow_experiments || [];
    const holdoutPercent = flow.config.holdout * 100;

    const customerManagedExperiment = experiments.find(
      (e) => e.name === "customer_managed",
    );

    const customerManagedPercent =
      (customerManagedExperiment?.audience_percent || 0) * 100;

    // Calculate treatment percent (including uniform if it exists)
    const treatmentPercent = 100 - holdoutPercent - customerManagedPercent;

    const chartData = [
      {
        value: treatmentPercent,
        name: `${Math.round(treatmentPercent)}% Treatment`,
        color: "var(--chakra-colors-teal-base)",
      },
    ];

    if (customerManagedPercent > 0) {
      chartData.push({
        value: customerManagedPercent,
        name: `${customerManagedPercent}% Customer Managed`,
        color: "var(--chakra-colors-teal-500)",
      });
    }

    if (holdoutPercent > 0) {
      chartData.push({
        value: holdoutPercent,
        name: `${holdoutPercent}% Holdout`,
        color: "var(--chakra-colors-gray-base)",
      });
    }

    return chartData;
  }, [experimentsQuery.data, flow.config.holdout]);

  return (
    <Column>
      <MetadataLabel>Target audience</MetadataLabel>
      <Column>
        <Row>
          <Link href={`/audiences/${flow.audience?.id}`}>
            <Row gap={2} align="center">
              <Box as={AudienceIcon} boxSize={5} />
              <TextWithTooltip color="inherit">
                {flow.audience?.name}
              </TextWithTooltip>
            </Row>
          </Link>
        </Row>
        <ResponsiveContainer width="100%" height={100}>
          <PieChart>
            <Pie
              isAnimationActive={false}
              data={chartData}
              dataKey="value"
              nameKey="name"
              innerRadius={15}
              outerRadius={30}
              cx={30}
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              wrapperStyle={{ left: 80 }}
            />
          </PieChart>
        </ResponsiveContainer>
      </Column>
    </Column>
  );
};
