import { HTImage } from "src/components/image";
import {
  Column,
  Row,
  SectionHeading,
  Text,
  useDisclosure,
} from "@hightouchio/ui";

import { useWorkspaceNotificationChannelsQuery } from "src/graphql";
import { SubscribedResourceItem } from "src/components/notification-channels/subscribed-resource-item";
import { AddRecipientButton } from "./add-recipient-button";
import { useMemo } from "react";
import { ChannelData, CreateRecipientModal } from "./create-recipient-modal";
import { Card } from "src/components/card";
import { Channel } from "src/components/notification-channels/channel-definitions";
import notificationsPlaceholder from "src/assets/placeholders/notifications.svg";

export const Recipients = ({
  channels,
  onAdd,
  onRemove,
  onCreate,
  showSuccessToast = true,
}: {
  channels: (Channel & { disableRemove?: boolean })[];
  onAdd: (channel: Channel) => void | Promise<void>;
  onRemove: (channel: Channel) => void | Promise<void>;
  onCreate: (data: ChannelData) => void | Promise<void>;
  showSuccessToast?: boolean;
}) => {
  const createRecipientModal = useDisclosure();

  const { data: workspaceChannels } = useWorkspaceNotificationChannelsQuery(
    undefined,
    {
      suspense: true,
      select: (data) => data.workspace_notification_channels,
    },
  );

  // Workspace channels contains all possible notification channels
  // We need to filter out the ones that are already subscribed to by this resource
  const avilableChannels = useMemo(
    () =>
      (workspaceChannels ?? []).filter(
        (c) => !channels.map((channel) => channel.id).includes(c.id),
      ),
    [workspaceChannels, channels],
  );

  return (
    <Column gap={2}>
      <Column>
        <SectionHeading>Recipients</SectionHeading>
        <Text color="text.secondary">
          Send all alerts to the following recipients:
        </Text>
      </Column>
      {channels.length === 0 ? (
        <Card>
          <Column gap={4} align="center">
            <HTImage src={notificationsPlaceholder} boxSize={24} decorative />
            <Column align="center">
              <Text size="lg" color="text.secondary" fontWeight="medium">
                Add recipients to send alerts to external channels
              </Text>
              <Text color="text.secondary">
                Send alerts to Slack, PagerDuty, SMS, webhook, or email. Alerts
                will always be visible in the Hightouch app.
              </Text>
            </Column>
            <AddRecipientButton
              onAdd={onAdd}
              onCreate={createRecipientModal.onOpen}
              channels={avilableChannels}
              showSuccessToast={showSuccessToast}
            />
          </Column>
        </Card>
      ) : (
        <>
          <Row gap={2} flexWrap="wrap">
            {channels.map((channel) => (
              <SubscribedResourceItem
                key={channel.id}
                channel={channel}
                onRemove={
                  channel.disableRemove ? undefined : () => onRemove(channel)
                }
                showSuccessToast={showSuccessToast}
              />
            ))}
            <AddRecipientButton
              isIcon
              onAdd={onAdd}
              onCreate={createRecipientModal.onOpen}
              channels={avilableChannels}
              showSuccessToast={showSuccessToast}
            />
          </Row>
        </>
      )}
      <CreateRecipientModal
        isOpen={createRecipientModal.isOpen}
        onClose={createRecipientModal.onClose}
        onCreate={onCreate}
      />
    </Column>
  );
};
