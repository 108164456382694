import { Column, Combobox, SectionHeading, Text } from "@hightouchio/ui";
import { Controller } from "react-hook-form";

import { Card } from "src/components/card";
import { useMinimalParentModelsQuery } from "src/graphql";

export const Targeting = () => {
  const modelsQuery = useMinimalParentModelsQuery(undefined, {
    select: (data) => data.segments,
  });

  return (
    <Card>
      <Column mb={4}>
        <SectionHeading>Parent model</SectionHeading>
        <Text color="text.secondary">
          Define a parent model to base your agents off of
        </Text>
      </Column>
      <Controller
        name="segment_id"
        render={({ field }) => (
          <Combobox
            {...field}
            isLoading={modelsQuery.isLoading}
            optionValue={(option) => option.id}
            optionLabel={(option) => option.name}
            optionAccessory={(option) => ({
              type: "image",
              url: option.connection!.definition.icon,
            })}
            options={modelsQuery.data ?? []}
          />
        )}
      />
    </Card>
  );
};
