import { FC, useState } from "react";

import { EditableHeading, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { TraitQuery } from "src/graphql";

import {
  TraitDefinitionsConstraint,
  useUpdateTraitWithHasuraMutation,
} from "src/graphql";

import { traitNameValidator } from "src/components/traits/utils";

type Props = {
  id: string;
  isTemplate: boolean;
  name: string;
  parentModel: NonNullable<
    TraitQuery["trait_definitions_by_pk"]
  >["parent_model"];
};

export const EditTraitName: FC<Readonly<Props>> = ({
  id,
  isTemplate,
  name,
  parentModel,
}) => {
  const { toast } = useToast();

  const traitMutation = useUpdateTraitWithHasuraMutation();

  const [remountKey, setRemountKey] = useState(0);

  const updateName = async (newName: string) => {
    try {
      traitNameValidator(parentModel?.connection?.type).validateSync(newName);
    } catch (error) {
      toast({
        id: "update-trait-name",
        title: error.message,
        variant: "error",
      });
      setRemountKey((prevKey) => prevKey + 1);
      return;
    }

    try {
      await traitMutation.mutateAsync({ id, input: { name: newName } });
      toast({
        id: "update-trait-name",
        title: "Trait name was updated",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "update-trait-name",
        title: "Trait name was not updated",
        message: error.message.includes(
          TraitDefinitionsConstraint.TraitDefinitionsNameParentModelIdKey,
        )
          ? `There is an existing ${
              isTemplate ? "template" : "trait"
            } named "${newName}" associated with this parent model. Please choose a different name and try again.`
          : error.message,
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  return (
    <EditableHeading key={remountKey} value={name} onChange={updateName} />
  );
};
