import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { SyncRequest } from "./types";

export const SourceIcon = ({ syncRequest }: { syncRequest: SyncRequest }) => {
  const definition = syncRequest.sync?.segment?.connection?.definition;

  return (
    <IntegrationIcon size={4} name={definition?.name} src={definition?.icon} />
  );
};

export const DestinationIcon = ({
  syncRequest,
}: {
  syncRequest: SyncRequest;
}) => {
  const definition = syncRequest.sync?.destination?.definition;

  return (
    <IntegrationIcon size={4} name={definition?.name} src={definition?.icon} />
  );
};

export const HightouchIcon = () => {
  return (
    <IntegrationIcon
      size={4}
      name="Hightouch"
      src="https://cdn.sanity.io/images/pwmfmi47/production/66906ca430b1a4d6c36bc3c81a9607ef0bd82216-97x97.svg"
    />
  );
};
