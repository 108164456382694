import { FC } from "react";

import { Box, Button, Column, Row, Spinner, Text } from "@hightouchio/ui";

import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import { diff } from "src/utils/time";
import { getIsJourneyRunning } from "src/pages/journeys/utils/get-journey-running-state";

const STATUS_SIZE = 7;

export const JourneyStatusCell: FC = () => {
  const { latestJourneyRun, onShowJourneyRunErrors } = useGraphContext();

  const error = latestJourneyRun?.error;

  if (!latestJourneyRun || !latestJourneyRun.started_at) {
    return <Text fontWeight="medium">--</Text>;
  }

  if (getIsJourneyRunning(latestJourneyRun)) {
    return (
      <Row align="center" gap={2}>
        <Spinner size="sm" />
        <Text fontWeight="medium">Running...</Text>
      </Row>
    );
  }

  return (
    <>
      <Column>
        <Row align="center" justify="space-between" gap={2}>
          <Row align="center" gap={2}>
            <Box
              height={`${STATUS_SIZE}px`}
              width={`${STATUS_SIZE}px`}
              bg={error ? "danger.base" : "success.base"}
              borderRadius="full"
            />
            <Text fontWeight="medium">
              {error ? "Errors during last run" : "Completed"}
            </Text>
          </Row>
          {error && (
            <Button
              variant="warning"
              size="sm"
              onClick={onShowJourneyRunErrors}
            >
              View error
            </Button>
          )}
        </Row>
        <Text ml={4} color="text.secondary">
          {diff(new Date().toISOString(), latestJourneyRun.finished_at ?? "")}{" "}
          ago
        </Text>
      </Column>
    </>
  );
};
