import {
  AudienceIcon,
  Avatar,
  Box,
  CloseIcon,
  Column,
  Drawer,
  DrawerProps,
  Heading,
  IconButton,
  QuestionIcon,
  Row,
  SearchInput,
  Switch,
  Text,
} from "@hightouchio/ui";
import { FC, useMemo, useState } from "react";
import { FullParentModel } from "src/components/audiences/types";
import { IconBox } from "src/components/icon-box";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { FilterableColumnFragment } from "src/graphql";
import { ColumnType, isMergedColumn } from "src/types/visual";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";
import { commaNumber } from "src/utils/numbers";
import { UseCalculateAudienceSizeOutput } from "src/utils/use-calculate-audience-size";
import { ALL_COLUMN_OPTIONS } from "src/components/audiences/constants";
import { DataRow } from "./data-row";
import { ParentModelFields, useParentModelFields } from "./fields";
import { MembersTablePlaceholder } from "./table-placeholder";
import { stringify } from "src/utils/string";

const tableCell = ({
  columnName,
  columnType,
}: {
  columnName: string;
  columnType: ColumnType;
}) => {
  const isNumeric = columnType === ColumnType.Number;
  return {
    name: columnName,
    cellSx: {
      justifyContent: isNumeric ? "right" : "unset",
    },
    headerSx: {
      justifyContent: isNumeric ? "right" : "unset",
    },
    cell: (row) => (
      <DataRow
        value={stringify(row[columnName])}
        type={columnType}
        copyable={false}
      />
    ),
  };
};

export const MembersDrawer: FC<{
  audienceSize: UseCalculateAudienceSizeOutput["audienceSize"];
  audienceRows: any[];
  parentModel: FullParentModel | undefined | null;
  isLoading: boolean;
  error?: string;
  isOpen: boolean;
  includeMergedColumns: boolean;
  onClose: () => void;
  onToggleMergedColumns?: () => void;
  size?: DrawerProps["size"];
  customHeader?: JSX.Element;
  customTableFooter?: JSX.Element;
}> = ({
  audienceRows,
  audienceSize,
  includeMergedColumns,
  isOpen,
  error,
  parentModel,
  isLoading,
  onClose,
  onToggleMergedColumns,
  size = "2xl",
  customHeader,
  customTableFooter,
}) => {
  const parentModelFields = useParentModelFields(parentModel);
  const { selectedRows, onRowSelect } = useRowSelect();

  const selectedMergedColumns = includeMergedColumns
    ? (parentModel?.filterable_audience_columns ?? []).filter((col) =>
        isMergedColumn(col.column_reference),
      )
    : [];

  if (!parentModel || !parentModelFields) {
    return null;
  }

  const { primaryKey, secondaryLabelKey, primaryLabelKey, columns } =
    parentModelFields;

  const highlight =
    selectedRows.length === 1 ? selectedRows[0]![primaryKey] : undefined;
  return (
    <>
      <Drawer
        isOpen={isOpen}
        size={size}
        onClose={onClose}
        closeOnEsc
        trapFocus
      >
        <Box p={6} borderBottom="1px solid" borderBottomColor="base.border">
          <DrawerHeader
            header={
              customHeader || (
                <>
                  <IconBox
                    bg="ocean.400"
                    boxSize="20px"
                    icon={<AudienceIcon />}
                    iconSize="14px"
                  />
                  <Heading>
                    {isLoading
                      ? "Calculating..."
                      : audienceSize !== null
                        ? `${audienceSize.isEstimate ? "~" : ""}${commaNumber(
                            audienceSize.count,
                          )} Members`
                        : "--"}
                  </Heading>
                </>
              )
            }
            controls={
              onToggleMergedColumns && (
                <Row
                  gap={2}
                  alignItems="center"
                  cursor="pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    onToggleMergedColumns();
                  }}
                >
                  <Text>Show merged columns</Text>
                  <Switch
                    aria-label="Show merged columns."
                    size="sm"
                    isChecked={includeMergedColumns}
                    onChange={onToggleMergedColumns}
                  />
                </Row>
              )
            }
            onClose={onClose}
          />
        </Box>

        <Column overflowY="auto" position="relative" m={6}>
          <Column height="100%" position="relative">
            <Box flexGrow={1} position="relative">
              <Box
                position="absolute"
                top={0}
                display={audienceRows.length > 0 ? undefined : "none"}
                borderRightRadius="md"
                height="100%"
                width="12px"
                right={0}
                bg="linear-gradient(270deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.00) 100%)"
              />

              <Table
                scrollableX
                defaultMin="min-content"
                onRowClick={(row) => onRowSelect([row])}
                highlight={highlight}
                primaryKey={primaryKey}
                placeholder={{
                  custom: <MembersTablePlaceholder isLoading={isLoading} />,
                  error: error,
                }}
                top={0}
                error={Boolean(error)}
                columns={[
                  {
                    name: primaryKey,
                    headerSx: { pl: "16px !important" },
                    cellSx: { pl: "16px !important" },
                    cell: (row) => (
                      <TextWithTooltip color="text.secondary" isTruncated>
                        {stringify(row[primaryKey])}
                      </TextWithTooltip>
                    ),
                  },
                  {
                    name: parentModel.visual_query_primary_label || "",
                    cell: (row) => (
                      <Text color="text.primary" fontWeight="medium">
                        {stringify(row[primaryLabelKey])}
                      </Text>
                    ),
                    max: "max-content",
                  },
                  ...(secondaryLabelKey
                    ? [
                        {
                          name: parentModel.visual_query_secondary_label || "",
                          cell: (row) => (
                            <Text color="text.primary" fontWeight="medium">
                              {stringify(row[secondaryLabelKey])}
                            </Text>
                          ),
                          max: "max-content",
                        },
                      ]
                    : []),
                  ...columns
                    .filter(
                      (column) =>
                        ![
                          primaryKey,
                          secondaryLabelKey,
                          primaryLabelKey,
                        ].includes(column.name),
                    )
                    .map((column) =>
                      tableCell({
                        columnName: column.alias ?? column.name,
                        columnType: column.type as ColumnType,
                      }),
                    ),
                  ...(selectedMergedColumns ?? []).map((column) =>
                    tableCell({
                      columnName: `${column.model_name}:${column.name}`,
                      columnType: column.type as ColumnType,
                    }),
                  ),
                ]}
                data={audienceRows}
              />
            </Box>

            {customTableFooter && <Box p={0}>{customTableFooter}</Box>}
          </Column>
        </Column>
      </Drawer>
      <Drawer
        isOpen={selectedRows.length !== 0}
        size="md"
        onClose={() => onRowSelect([])}
        closeOnEsc
        trapFocus
      >
        {selectedRows.length >= 1 && (
          <MemberDetailsDrawerContent
            // @ts-expect-error the row is basically an any
            selectedMember={selectedRows[0]!}
            onClose={() => onRowSelect([])}
            parentModelFields={parentModelFields}
            selectedMergeColumns={selectedMergedColumns}
          />
        )}
      </Drawer>
    </>
  );
};

const DrawerHeader: FC<{
  header: React.ReactNode | undefined;
  controls?: React.ReactNode | undefined;
  onClose: () => void;
}> = ({ onClose, header, controls }) => {
  return (
    <Row alignItems="center" justifyContent="space-between">
      <Row gap={4} alignItems="center">
        {header}
      </Row>

      <Row gap={4} alignItems="center">
        {controls}
        <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
      </Row>
    </Row>
  );
};

function lowercaseCompare(val1: unknown, val2: string) {
  return val1 && String(val1).toLowerCase().includes(val2.toLowerCase());
}

const MemberDetailsDrawerContent: FC<{
  selectedMember: Record<string, string>;
  onClose: () => void;
  parentModelFields: NonNullable<ParentModelFields>;
  selectedMergeColumns?: FilterableColumnFragment[];
}> = ({ selectedMember, parentModelFields, selectedMergeColumns, onClose }) => {
  const { primaryLabelKey, secondaryLabelKey, columns } = parentModelFields;
  const allColumns = [
    ...columns,
    ...(selectedMergeColumns ?? []).map((col) => ({
      ...col,
      name: `${col.model_name}:${col.name}`,
    })),
  ];
  const [searchValue, setSearchValue] = useState("");

  const columnOptions = useMemo(() => {
    if (searchValue === "") {
      return [...allColumns];
    }

    return allColumns.filter(
      (col) =>
        lowercaseCompare(col.alias, searchValue) ||
        lowercaseCompare(col.name, searchValue) ||
        lowercaseCompare(selectedMember[col.name], searchValue),
    );
  }, [searchValue, allColumns, selectedMember]);

  const { limit, offset, page, setPage } = useTableConfig({
    limit: 10,
  });

  const currentPage = useMemo(
    () => columnOptions.slice(offset, offset + limit),
    [columnOptions, offset, limit],
  );

  return (
    <Column gap={6} px={6} py={4}>
      <DrawerHeader
        header={
          <>
            <Avatar name={stringify(selectedMember[primaryLabelKey])} />

            <Column>
              <Heading>{selectedMember[primaryLabelKey]}</Heading>
              {secondaryLabelKey && (
                <Text>{selectedMember[secondaryLabelKey]}</Text>
              )}
            </Column>
          </>
        }
        onClose={onClose}
      />
      <Row justifyContent="space-between">
        <Text size="lg" fontWeight="medium">
          Attributes
        </Text>
        <Row gap={4} alignItems="center">
          <Box width="200px">
            <SearchInput
              width="100%"
              placeholder="Search attributes..."
              size="sm"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Box>
          <Pagination
            alwaysShow
            compact
            count={columnOptions.length || 1}
            page={page}
            rowsPerPage={10}
            setPage={setPage}
          />
        </Row>
      </Row>

      <Table
        data={currentPage}
        columns={[
          {
            name: "Name",
            cell: ({ name, type }) => (
              <Row gap={2} mr={4} maxWidth="200px">
                <Row color="text.secondary" fontSize="xl">
                  <IconForColumnType columnType={type} />
                </Row>
                <TextWithTooltip color="text.secondary" isMonospace>
                  {name.toUpperCase()}
                </TextWithTooltip>
              </Row>
            ),
          },
          {
            name: "Value",
            cell: ({ name, alias, type }) => {
              const value = selectedMember[alias ?? name];
              return (
                <DataRow value={value} type={type as ColumnType} copyable />
              );
            },
          },
        ]}
      />
    </Column>
  );
};

const IconForColumnType: FC<{ columnType: string }> = ({ columnType }) => {
  const match = ALL_COLUMN_OPTIONS.find(({ value }) => value === columnType);

  if (match) {
    const Icon = match.icon;
    return <Icon />;
  }

  return <QuestionIcon />;
};
