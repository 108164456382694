import { routes as monitoringRoutes } from "src/pages/alerting";
import { routes as analyticsRoutes } from "src/pages/analytics/routes";
import { routes as audienceRoutes } from "src/pages/audiences/routes";
import { routes as destinationRoutes } from "src/pages/destinations";
import { routes as identityResolutionRoutes } from "src/pages/identity-resolution";
import { routes as journeyRoutes } from "src/pages/journeys/routes";
import { routes as modelRoutes } from "src/pages/models";
import { routes as schemaRoutes } from "src/pages/schema/routes";
import { routes as sourceRoutes } from "src/pages/sources";
import { routes as syncRoutes } from "src/pages/syncs";
import { routes as eventRoutes } from "src/events";
import { routes as homeRoutes } from "src/pages/home";
/**
 * New style of routes.
 * TODO: migrate routes over to this new pattern
 */
export const jsonSlugRoutes = [
  ...homeRoutes,
  ...monitoringRoutes,
  ...modelRoutes,
  ...sourceRoutes,
  ...destinationRoutes,
  ...syncRoutes,
  ...audienceRoutes,
  ...analyticsRoutes,
  ...schemaRoutes,
  ...identityResolutionRoutes,
  ...journeyRoutes,
  ...eventRoutes,
];
