import { FC, useEffect, useState } from "react";

import {
  Column,
  DeleteIcon,
  FormField,
  Heading,
  Menu,
  MenuActionsButton,
  MenuList,
  RefreshIcon,
  Row,
  Text,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Outlet } from "src/router";
import lookerImage from "src/components/extensions/assets/looker.png";
import { Overview } from "src/components/extensions/overview";
import { DetailPage } from "src/components/layout";
import { PermissionedMenuItem } from "src/components/permission";
import { TunnelSelect } from "src/components/tunnel-select";
import {
  useCreateLookerCredentialMutation,
  useDeleteLookerExtensionMutation,
  useLookerCredentialsQuery,
  useTestLookerExtensionQuery,
  useUpdateLookerCredentialMutation,
} from "src/graphql";
import { LookerIcon } from "src/ui/icons";
import { ConnectedExtension } from "./common";
import { DocsLink } from "src/components/docs-link";
import { Card } from "src/components/card";
import { Form, useHightouchForm } from "src/components/form";
import { FormBar } from "src/components/form/form-bar";
import { SecretInput } from "src/components/secret-input";

export const LookerOverview: FC = () => {
  return (
    <Overview
      description="When you create a Hightouch model backed by a Look, Hightouch converts that Look to SQL and runs it against your data source."
      icon={LookerIcon}
      image={lookerImage}
      subtitle="Import models from Looker"
      title="Looker"
    />
  );
};

export const Looker: FC = () => {
  return (
    <DetailPage
      bg="base.lightBackground"
      crumbs={[{ label: "Extensions", link: "/extensions" }]}
      title="Looker - Extensions"
      header={<Heading size="xl">Looker</Heading>}
      tabs={[
        {
          path: "",
          title: "Overview",
        },
        {
          path: "configuration",
          title: "Configuration",
          size: "small",
          contentFullHeight: true,
        },
      ]}
    >
      <Outlet />
    </DetailPage>
  );
};

type LookerTestStatus = "failed" | "loading" | "success" | "error";

const schema = yup.object().shape({
  tunnel_id: yup.string().optional().trim(),
  url: yup
    .string()
    .required("URL is required")
    .url("Please enter a valid URL")
    .trim(),
  client_id: yup.string().required("Client ID is required").trim(),
  client_secret: yup.string().required("Client secret is required").trim(),
});

export const LookerConfiguration: FC = () => {
  const { toast } = useToast();

  const { data: credentials } = useLookerCredentialsQuery(undefined, {
    select: (data) => data.looker_credentials?.[0],
    suspense: true,
  });

  const { mutateAsync: deleteExtension, isLoading: extensionDeleting } =
    useDeleteLookerExtensionMutation();
  const [testStatus, setTestStatus] = useState<LookerTestStatus>("loading");

  const {
    data: testResult,
    isLoading: isTesting,
    refetch: testExtension,
    isRefetching: isReTesting,
  } = useTestLookerExtensionQuery(
    {},
    { select: (data) => data.testLookerExtension, enabled: !!credentials },
  );

  const { mutateAsync: create } = useCreateLookerCredentialMutation();
  const { mutateAsync: update } = useUpdateLookerCredentialMutation();

  const form = useHightouchForm({
    values: {
      tunnel_id: credentials?.tunnel_id ?? "",
      url: credentials?.url ?? "",
      client_id: credentials?.client_id ?? "",
      client_secret: "",
    },
    resolver: yupResolver(schema),
    onSubmit: async (data) => {
      if (!data.tunnel_id) {
        // @ts-expect-error - we don't want to pass tunnel_id if it's not present
        delete data.tunnel_id;
      }
      if (credentials?.id) {
        await update({
          credentials: { ...data, id: credentials.id },
        });
      } else {
        await create({
          credentials: data,
        });
      }
    },
  });

  const { control, watch } = form;

  const isTunnel = Boolean(watch("tunnel_id"));

  useEffect(() => {
    if (credentials) {
      if (isTesting || isReTesting) {
        setTestStatus("loading");
        return;
      }
      if (!testResult) {
        setTestStatus("failed");
        toast({
          id: "looker-extension",
          variant: "error",
          title: "Looker credentials test failed.",
          message: "Please check your Looker API credentials.",
        });
      } else {
        setTestStatus("success");
      }
    }
  }, [credentials, isTesting, isReTesting]);

  const Header = () => {
    return (
      <Row justifyContent="space-between">
        <Heading>Looker configuration</Heading>
        <Row gap={4} align="center">
          <DocsLink name="Looker extension" href="models/looker-models" />
          {credentials?.id && (
            <Menu closeOnSelect={false}>
              <MenuActionsButton variant="secondary" />
              <MenuList>
                <PermissionedMenuItem
                  permission={{
                    v2: { resource: "workspace", grant: "can_update" },
                  }}
                  isDisabled={isTesting || isReTesting || extensionDeleting}
                  icon={RefreshIcon}
                  onClick={() => {
                    setTestStatus("loading");
                    testExtension({});
                  }}
                >
                  {isTesting || isReTesting ? "Testing..." : "Test connection"}
                </PermissionedMenuItem>
                <PermissionedMenuItem
                  permission={{
                    v2: { resource: "workspace", grant: "can_update" },
                  }}
                  isDisabled={extensionDeleting || isTesting}
                  isLoading={extensionDeleting}
                  icon={DeleteIcon}
                  variant="danger"
                  onClick={async () => {
                    try {
                      await deleteExtension({
                        workspaceId: credentials.workspace_id,
                      });
                      toast({
                        id: "looker-extension",
                        title: "Looker disconnected",
                        variant: "success",
                      });
                    } catch (_err) {
                      toast({
                        id: "looker-extension",
                        title: "Could not disconnect Looker",
                        variant: "error",
                      });
                    }
                  }}
                >
                  Disconnect
                </PermissionedMenuItem>
              </MenuList>
            </Menu>
          )}
        </Row>
      </Row>
    );
  };

  return (
    <Form form={form}>
      <Column flex={1} gap={6}>
        <Header />
        {credentials?.id ? (
          <ConnectedExtension credentials={credentials} testStatus={testStatus}>
            <Text fontWeight="medium">
              <Text fontWeight="semibold">Client ID: </Text>
              {credentials?.client_id || "Not configured"}
            </Text>
          </ConnectedExtension>
        ) : (
          <Card gap={6}>
            <Controller
              control={control}
              name="tunnel_id"
              render={({ field }) => (
                <TunnelSelect
                  value={field.value ? { id: field.value } : undefined}
                  onChange={(tunnel) => {
                    field.onChange(tunnel?.id ?? null);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="url"
              render={({ field, fieldState: { error } }) => (
                <FormField label="URL" error={error?.message}>
                  <TextInput
                    isDisabled={isTunnel}
                    {...field}
                    isInvalid={Boolean(error?.message)}
                  />
                </FormField>
              )}
            />
            <Controller
              control={control}
              name="client_id"
              render={({ field, fieldState: { error } }) => (
                <FormField label="Client ID" error={error?.message}>
                  <TextInput {...field} isInvalid={Boolean(error?.message)} />
                </FormField>
              )}
            />
            <Controller
              control={control}
              name="client_secret"
              render={({ field, fieldState: { error } }) => (
                <FormField label="Client secret" error={error?.message}>
                  <SecretInput
                    isInvalid={Boolean(error?.message)}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                </FormField>
              )}
            />
          </Card>
        )}
      </Column>
      <FormBar
        permission={{
          v2: {
            resource: "workspace",
            grant: "can_update",
          },
        }}
      />
    </Form>
  );
};
