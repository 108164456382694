import { FC, useState } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  ConfirmationDialog,
  CopyIcon,
  DeleteIcon,
  Dialog,
  FormField,
  Menu,
  MenuActionsButton,
  MenuList,
  Paragraph,
  PauseIcon,
  PlayIcon,
  Radio,
  RadioGroup,
  ResetIcon,
  Row,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import capitalize from "lodash/capitalize";
import { useFormContext } from "react-hook-form";
import { useNavigate, useSearchParams } from "src/router";

import {
  PermissionedButton,
  PermissionedMenuItem,
} from "src/components/permission";
import { CreateJourneyModal } from "src/pages/journeys/forms/create-journey-modal";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import { getDeleteJourneyPermission } from "src/pages/journeys/permission-utils";
import { JourneyGraph } from "src/pages/journeys/types";
import { getJourneyState } from "src/pages/journeys/utils";
import { JourneyStatus } from "src/types/journeys";

import { DeleteJourneyModal } from "./delete-journey-modal";

type JourneyActionButtonProps = {
  isLoading?: boolean;
  numberOfPreviousRuns: number;
  numberOfUsersInJourney: number;
  reset: boolean;
  status: JourneyStatus;
};

export const JourneyActionButtons: FC<JourneyActionButtonProps> = ({
  isLoading = false,
  numberOfPreviousRuns,
  numberOfUsersInJourney,
  reset,
  status,
}) => {
  const navigate = useNavigate();

  const form = useFormContext<JourneyGraph>();
  const { journeysFullCloning } = useFlags();
  const {
    parentModel,
    versionId,
    updateJourneyPermission,
    unauthorizedTooltip,
    onDeleteJourney,
    onRunJourney,
    onUpdateJourneyStatus,
    onResetJourney,
  } = useGraphContext();

  const journeyState = getJourneyState({
    reset,
    status,
    numberOfPreviousRuns,
    numberOfUsersInJourney,
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamDeleteRequested = searchParams.get("delete");
  const showDeleteModal = searchParamDeleteRequested === "1";

  const [showJourneyTurnedOffDialog, setShowJourneyTurnedOffDialog] =
    useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(showDeleteModal);
  const [showConfirmReset, setShowConfirmReset] = useState(false);
  const [showConfirmManualRun, setShowConfirmManualRun] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);

  const deleteJourney = async () => {
    await onDeleteJourney();
    setSearchParams({});
    // Navigates away on finish, so no need to close dialog
    navigate("/journeys");
  };

  const resetJourney = async () => {
    // Navigates away on finish, so no need to close dialog
    await onResetJourney();
  };

  const buttons = ["draft", "draining", "off"].includes(journeyState) ? (
    <JourneyStartButton
      isLoading={isLoading}
      onUpdateJourneyStatus={onUpdateJourneyStatus}
      type="start"
    />
  ) : journeyState === "resetting" ? null : (
    <ButtonGroup>
      <JourneyTurnOffButton
        isLoading={isLoading}
        onUpdateJourneyStatus={onUpdateJourneyStatus}
      />
      {journeyState === "live" ? (
        <JourneyPauseButton
          isLoading={isLoading}
          numberOfUsersInJourney={numberOfUsersInJourney}
          onTurnJourneyOff={() => setShowJourneyTurnedOffDialog(true)}
          onUpdateJourneyStatus={onUpdateJourneyStatus}
        />
      ) : (
        <JourneyStartButton
          isLoading={isLoading}
          type="resume"
          onUpdateJourneyStatus={onUpdateJourneyStatus}
        />
      )}
    </ButtonGroup>
  );

  return (
    <>
      <Row gap={2}>
        <Menu>
          <MenuActionsButton />
          <MenuList>
            {journeyState === "live" && (
              <PermissionedMenuItem
                permission={updateJourneyPermission}
                unauthorizedTooltip={unauthorizedTooltip}
                onClick={() => setShowConfirmManualRun(true)}
                icon={PlayIcon}
              >
                Trigger manual run
              </PermissionedMenuItem>
            )}
            <>
              {journeyState !== "live" && (
                <PermissionedMenuItem
                  icon={ResetIcon}
                  isDisabled={journeyState === "resetting"}
                  tooltip={
                    journeyState === "resetting" && "Journey is resetting"
                  }
                  permission={updateJourneyPermission}
                  unauthorizedTooltip={unauthorizedTooltip}
                  onClick={() => setShowConfirmReset(true)}
                >
                  Reset journey
                </PermissionedMenuItem>
              )}
              {journeyState !== "resetting" && journeysFullCloning && (
                <PermissionedMenuItem
                  icon={CopyIcon}
                  permission={updateJourneyPermission}
                  unauthorizedTooltip={unauthorizedTooltip}
                  onClick={() => setShowCloneModal(true)}
                >
                  Make a copy
                </PermissionedMenuItem>
              )}
              {journeyState !== "live" && (
                <PermissionedMenuItem
                  icon={DeleteIcon}
                  permission={getDeleteJourneyPermission(parentModel?.id)}
                  unauthorizedTooltip="You do not have permission to delete this journey"
                  onClick={() => setShowConfirmDelete(true)}
                  variant="danger"
                >
                  Delete journey
                </PermissionedMenuItem>
              )}
            </>
          </MenuList>
        </Menu>

        {buttons}
      </Row>

      <ConfirmationDialog
        isOpen={showJourneyTurnedOffDialog}
        title="Journey has been turned off"
        confirmButtonText="Ok"
        variant="warning"
        onClose={() => setShowJourneyTurnedOffDialog(false)}
        onConfirm={() => setShowJourneyTurnedOffDialog(false)}
      >
        <Paragraph>
          Nobody is currently in the journey so we’ve turned it off.
        </Paragraph>
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={showConfirmManualRun}
        title="Trigger manual run"
        confirmButtonText="Confirm"
        variant="warning"
        onClose={() => setShowConfirmManualRun(false)}
        onConfirm={onRunJourney}
      >
        <Paragraph>
          Are you sure you want to kick off a manual run? During a run, all
          syncs are triggered which could cause syncs to run outside of their
          expected interval or schedule.
        </Paragraph>
      </ConfirmationDialog>

      <DeleteJourneyModal
        isOpen={showConfirmDelete}
        journeyVersionId={versionId}
        onClose={() => setShowConfirmDelete(false)}
        onConfirm={deleteJourney}
      />

      <ConfirmationDialog
        isOpen={showConfirmReset}
        title="Reset journey"
        confirmButtonText="Reset"
        variant="warning"
        onClose={() => setShowConfirmReset(false)}
        onConfirm={resetJourney}
      >
        <Column gap={6}>
          <Paragraph>
            Resetting this journey will make it behave as if you've never ran
            the journey before. All entry history will be cleared and all syncs
            will be reset. Are you sure you want to reset this journey?
          </Paragraph>
        </Column>
      </ConfirmationDialog>

      <CreateJourneyModal
        isOpen={showCloneModal}
        cloneJourneyParams={{
          sourceJourneyId: form.watch("journey.id"),
          name: form.watch("journey.name"),
          description: form.watch("journey.description"),
          parentModelId: parentModel?.id,
        }}
        closeModal={() => setShowCloneModal(false)}
      />
    </>
  );
};

type JourneyStartButtonProps = {
  isLoading?: boolean;
  type: "start" | "resume";
  numberOfPreviousRuns?: number;
  onUpdateJourneyStatus: (
    status: JourneyStatus,
    hardStop?: boolean,
  ) => Promise<void>;
};

const JourneyStartButton: FC<JourneyStartButtonProps> = ({
  isLoading = false,
  type,
  numberOfPreviousRuns = 0,
  onUpdateJourneyStatus,
}) => {
  const { unauthorizedTooltip, updateJourneyPermission } = useGraphContext();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const capitalizedButtonText = capitalize(type);

  const startJourney = async () => {
    await onUpdateJourneyStatus(JourneyStatus.Enabled);

    setShowConfirmation(false);
  };

  return (
    <>
      <PermissionedButton
        isLoading={isLoading}
        icon={PlayIcon}
        variant="primary"
        tooltip="Journey will begin running"
        placement="bottom-end"
        permission={updateJourneyPermission}
        unauthorizedTooltip={unauthorizedTooltip}
        onClick={() => setShowConfirmation(true)}
      >
        {capitalizedButtonText}
      </PermissionedButton>

      <ConfirmationDialog
        isOpen={showConfirmation}
        confirmButtonText={capitalizedButtonText}
        title={`${capitalizedButtonText} journey`}
        variant="warning"
        onCancel={() => setShowConfirmation(false)}
        onClose={() => setShowConfirmation(false)}
        onConfirm={startJourney}
      >
        <Paragraph>
          {type === "start" && "Are you sure you want to start this journey?"}
          {type === "resume" &&
            (numberOfPreviousRuns === 0
              ? "Are you sure you want to resume this journey?"
              : "Existing members will continue on in the new version of the journey. Are you sure you want to resume this journey?")}
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};

type JourneyTurnOffButtonProps = {
  isLoading?: boolean;
  onUpdateJourneyStatus: (
    status: JourneyStatus,
    hardStop?: boolean,
  ) => Promise<void>;
};

const JourneyTurnOffButton: FC<JourneyTurnOffButtonProps> = ({
  isLoading = false,
  onUpdateJourneyStatus,
}) => {
  const { updateJourneyPermission, unauthorizedTooltip } = useGraphContext();
  // Custom dialog so loading state must be set manually
  const [turningOff, setTurningOff] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [hardStop, setHardStop] = useState(false);

  const updateStatus = async () => {
    setTurningOff(true);

    await onUpdateJourneyStatus(
      hardStop ? JourneyStatus.Disabled : JourneyStatus.Draining,
      hardStop,
    );

    setTurningOff(false);
    setShowConfirmation(false);
  };

  return (
    <>
      <PermissionedButton
        isLoading={isLoading}
        permission={updateJourneyPermission}
        tooltip="Journey will stop accepting new entries"
        unauthorizedTooltip={unauthorizedTooltip}
        variant="danger"
        onClick={() => setShowConfirmation(true)}
      >
        Turn off
      </PermissionedButton>

      <Dialog
        isOpen={showConfirmation}
        title="Turn off journey"
        variant="info"
        actions={
          <ButtonGroup>
            <Button
              isLoading={turningOff}
              isDisabled={false}
              onClick={() => setShowConfirmation(false)}
            >
              Cancel
            </Button>
            <PermissionedButton
              isLoading={turningOff}
              isDisabled={false}
              permission={updateJourneyPermission}
              unauthorizedTooltip={unauthorizedTooltip}
              variant="danger"
              onClick={updateStatus}
            >
              Turn off
            </PermissionedButton>
          </ButtonGroup>
        }
        onClose={() => setShowConfirmation(false)}
      >
        <FormField label="Should existing members complete their journey?">
          <RadioGroup
            orientation="vertical"
            value={hardStop}
            onChange={setHardStop}
          >
            <Radio
              value={false}
              label="Existing members should complete the journey first"
              description="All active members will complete the journey and no new entries will occur"
            />
            <Radio
              value={true}
              label="Hard stop"
              description="All existing members exit the journey and no new entries will occur"
            />
          </RadioGroup>
        </FormField>
      </Dialog>
    </>
  );
};

type JourneyPauseButtonProps = {
  isLoading?: boolean;
  numberOfUsersInJourney: number;
  onTurnJourneyOff: () => void;
  onUpdateJourneyStatus: (
    status: JourneyStatus,
    hardStop?: boolean,
  ) => Promise<void>;
};

const JourneyPauseButton: FC<JourneyPauseButtonProps> = ({
  isLoading = false,
  numberOfUsersInJourney,
  onUpdateJourneyStatus,
  onTurnJourneyOff,
}) => {
  const { unauthorizedTooltip, updateJourneyPermission } = useGraphContext();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const pauseJourney = async () => {
    await onUpdateJourneyStatus(JourneyStatus.Disabled);

    setShowConfirmation(false);

    if (numberOfUsersInJourney === 0) {
      onTurnJourneyOff();
    }
  };

  return (
    <>
      <PermissionedButton
        isLoading={isLoading}
        icon={PauseIcon}
        permission={updateJourneyPermission}
        tooltip="Journey will stop accepting new entries and existing members will remain in their current step"
        placement="bottom-end"
        unauthorizedTooltip={unauthorizedTooltip}
        onClick={() => setShowConfirmation(true)}
      >
        Pause
      </PermissionedButton>

      <ConfirmationDialog
        isOpen={showConfirmation}
        title="Pause journey"
        confirmButtonText="Pause"
        variant="warning"
        onClose={() => setShowConfirmation(false)}
        onCancel={() => setShowConfirmation(false)}
        onConfirm={pauseJourney}
      >
        <Paragraph>
          The journey will be paused and all existing members will remain in
          their current tile. Are you sure you want to pause this journey?
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
