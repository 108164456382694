import {
  Badge,
  Column,
  Row,
  Skeleton,
  SkeletonBox,
  Text,
} from "@hightouchio/ui";
import { Card } from "src/components/card";
import { formatFriendlyDistanceToNow } from "src/utils/time";
import {
  useMonitoredResourceStatusQuery,
  useRenderedConditionChangesQuery,
} from "src/graphql";
import {
  MonitorStatus,
  MonitoredResourceType,
} from "@hightouch/lib/resource-monitoring/types";
import { capitalize, chunk, first, sortBy } from "lodash";
import { useMemo, useState } from "react";
import { Pagination } from "src/ui/table";

export const EventTypeAlertsDetails = ({
  eventSourceResourceId,
}: {
  eventSourceResourceId: string;
}) => {
  const { data: resourceStatuses, isLoading: statusesLoading } =
    useMonitoredResourceStatusQuery(
      {
        resourceId: eventSourceResourceId,
        resourceType: MonitoredResourceType.EventSource,
      },
      { select: (data) => data.resource_monitor_statuses },
    );

  const unhealthyEventTypeStatusChanges = resourceStatuses?.filter(
    (status) =>
      status.new_status === MonitorStatus.Unhealthy ||
      status.new_status === MonitorStatus.Warning,
  );

  const { data: renderedConditionChanges, isLoading: renderLoading } =
    useRenderedConditionChangesQuery(
      {
        resourceId: "",
        resourceType: "EventSource",
        conditionChangeIds:
          unhealthyEventTypeStatusChanges?.flatMap(
            (status) => status.condition_changes as string[],
          ) || [],
      },
      {
        enabled: !!unhealthyEventTypeStatusChanges,
        select: (data) =>
          sortBy(
            data.renderedMonitorConditionStatuses.filter(
              (s) => s.status !== MonitorStatus.Healthy,
            ),
            (conditionStatus) => conditionStatus.evaluatedAt,
          )
            .reverse()
            .map((conditionStatus) => ({
              ...conditionStatus,
              isActive: !!first(resourceStatuses)?.condition_changes.includes(
                // Is this condition change part of the latest resource health?
                conditionStatus.id,
              ),
            })),
      },
    );
  const p = 4;

  const pageSize = 6;
  const [pageNumber, setPageNumber] = useState(0);
  const paginatedChanges = useMemo(
    () => chunk(renderedConditionChanges ?? [], pageSize),
    [renderedConditionChanges],
  );
  const totalPages = paginatedChanges.length;
  const conditionsPage = paginatedChanges[pageNumber];

  return (
    <Skeleton isLoading={statusesLoading || renderLoading}>
      <SkeletonBox borderRadius={8}>
        <Card p={0} overflow="hidden">
          <Row p={p} borderBottom="1px" borderColor="base.border" gap={2}>
            <Text fontWeight="semibold" size="lg">
              History
            </Text>
          </Row>
          <Column overflow="scroll" maxH="400px">
            {conditionsPage && conditionsPage.length > 0 ? (
              <>
                {conditionsPage.map((change) => {
                  const statusChange = unhealthyEventTypeStatusChanges?.find(
                    (status) => status.condition_changes.includes(change.id),
                  );
                  if (statusChange) {
                    return (
                      <Column
                        key={change.id}
                        borderTop="1px"
                        borderColor="base.border"
                        p={p}
                        _first={{ borderTop: "none" }}
                        gap={2}
                        backgroundColor={
                          change.isActive ? "danger.background" : undefined
                        }
                      >
                        <Row justifyContent="space-between">
                          <Text>
                            {change.title && (
                              <Text fontWeight="semibold">
                                {capitalize(change.title)}{" "}
                              </Text>
                            )}
                            {change.text.toLowerCase()}
                          </Text>
                          {change.isActive && (
                            <Badge
                              variant="danger"
                              size="sm"
                              {...({ backgroundColor: "white" } as any)}
                            >
                              Active{" "}
                            </Badge>
                          )}
                        </Row>
                        <Text size="sm" color="text.secondary">
                          {formatFriendlyDistanceToNow(change.eventTime)}
                        </Text>
                      </Column>
                    );
                  }
                  return null;
                })}
                {totalPages > 1 && (
                  <Row borderTop="1px" borderColor="base.border" p={p}>
                    <Pagination
                      count={totalPages}
                      page={pageNumber}
                      rowsPerPage={pageSize}
                      setPage={setPageNumber}
                      label="alerts"
                    />
                  </Row>
                )}
              </>
            ) : (
              <Column p={p}>
                <Row
                  width="100%"
                  justifyContent="center"
                  color="text.secondary"
                >
                  No alerts for this event type
                </Row>
              </Column>
            )}
          </Column>
        </Card>
      </SkeletonBox>
    </Skeleton>
  );
};
