import { FC, useEffect, useMemo, useState } from "react";

import {
  Button,
  Spinner,
  Row,
  Column,
  Heading,
  Text,
  CloseIcon,
  IconButton,
  ChakraModal,
  ChakraModalOverlay,
  ChakraModalContent,
  ChakraModalHeader,
  ChakraModalBody,
  ChakraModalFooter,
  ButtonGroup,
  SearchInput,
  Box,
} from "@hightouchio/ui";

import {
  useAudienceTemplatesByParentModelQuery,
  useReadOnlyAudienceDependenciesQuery,
} from "src/graphql";
import { ReadOnlyConditions } from "src/components/explore/read-only-conditions";
import { PageSpinner } from "src/components/loading";
import { AudienceTemplateDefinition } from "src/types/visual";

type Props = {
  highlightTemplateId?: string;
  isOpen: boolean;
  parentModelId: string;
  onAddTemplate: (
    templateId: string,
    templateConditions: AudienceTemplateDefinition["conditions"],
  ) => void;
  onReplaceAudience?: (
    templateId: string,
    templateConditions: AudienceTemplateDefinition["conditions"],
  ) => void;
  onClose: () => void;
};

export const AudienceTemplateSelectorModal: FC<Props> = ({
  highlightTemplateId,
  isOpen,
  parentModelId,
  onAddTemplate,
  onReplaceAudience,
  onClose,
}) => {
  const [search, setSearch] = useState("");
  const [tabIndex, setTabIndex] = useState(0);

  const { data, isLoading } = useAudienceTemplatesByParentModelQuery({
    parentModelId,
  });

  const readOnlyTemplateDependencies = useReadOnlyAudienceDependenciesQuery({
    parentModelId,
  });

  // Automatically highlight the requested template
  useEffect(() => {
    if (!data?.audience_templates) {
      return;
    }

    if (highlightTemplateId) {
      const highlightTemplateIndex = data.audience_templates.findIndex(
        (t) => t.id === highlightTemplateId,
      );
      if (highlightTemplateIndex >= 0) {
        setTabIndex(highlightTemplateIndex);
      }
    }
  }, [highlightTemplateId, data?.audience_templates]);

  // When closing the modal, reset the tab index.
  // Otherwise if the user re-opens the modal, it may highlight
  // a previous template which could be a minorly confusing user experience.
  useEffect(() => {
    if (!isOpen) {
      setTabIndex(0);
    }
  }, [isOpen]);

  const filteredTemplates = useMemo(
    () =>
      data?.audience_templates?.filter(({ name }) =>
        name.toLowerCase().includes(search.toLowerCase()),
      ),
    [data?.audience_templates, search],
  );

  const template = data?.audience_templates?.[tabIndex];

  const content = (
    <Column height="100%">
      <Box
        display="grid"
        columnGap={4}
        gridTemplateColumns="340px 1fr"
        height="100%"
      >
        <Column height="100%" overflowY="auto">
          <SearchInput
            marginBottom={2}
            placeholder="Seach for a template by name"
            value={search}
            width="auto"
            onChange={(event) => setSearch(event.target.value)}
          />
          <Column>
            {filteredTemplates?.map((template, index) => (
              <Column
                key={template.id}
                bg={tabIndex === index ? "primary.background" : undefined}
                borderRadius="md"
                cursor="pointer"
                p={2}
                role="listbox"
                _hover={{ bg: "base.background" }}
                onClick={() => setTabIndex(index)}
              >
                <Text>{template.name}</Text>
              </Column>
            ))}
          </Column>
        </Column>

        {template && (
          <Column
            border="1px solid"
            borderColor="base.border"
            borderRadius="md"
            height="100%"
            overflowY="auto"
            p={4}
          >
            <Column gap={4}>
              <Column gap={2}>
                <Column>
                  <Text fontWeight="medium">{template.name}</Text>
                  <Text color="text.secondary">{template.description}</Text>
                </Column>
                {template.created_by_user?.name && (
                  <Text color="text.secondary" size="sm">
                    Created by {template.created_by_user?.name}
                  </Text>
                )}
              </Column>
              {readOnlyTemplateDependencies.isLoading ||
              !readOnlyTemplateDependencies.data ? (
                <PageSpinner />
              ) : (
                <ReadOnlyConditions
                  rootConditions={
                    (template.definition as AudienceTemplateDefinition)
                      .conditions
                  }
                  audiences={readOnlyTemplateDependencies.data.audiences}
                  relationships={
                    readOnlyTemplateDependencies.data.relationships
                  }
                  traits={readOnlyTemplateDependencies.data.traits}
                />
              )}
            </Column>
          </Column>
        )}
      </Box>
    </Column>
  );

  const handleAddTemplate = () => {
    if (!template) {
      return;
    }

    onAddTemplate(template.id, template.definition.conditions);
    onClose();
  };

  const handleReplaceAudience = () => {
    if (!template || !onReplaceAudience) {
      return;
    }

    onReplaceAudience(template.id, template.definition.conditions);
    onClose();
  };

  return (
    <ChakraModal
      closeOnEsc
      closeOnOverlayClick
      isCentered
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ChakraModalOverlay />
      <ChakraModalContent
        height="70vh"
        maxWidth="70vw"
        padding={0}
        overflow="hidden"
        my="auto"
      >
        <ChakraModalHeader
          alignItems="center"
          padding={6}
          borderBottom="1px solid"
          borderColor="base.border"
          mb={0}
        >
          <Row alignItems="center" justifyContent="space-between" width="100%">
            <Heading>Choose a template</Heading>
            <IconButton
              aria-label="Close modal"
              color="text.secondary"
              icon={CloseIcon}
              onClick={onClose}
            />
          </Row>
        </ChakraModalHeader>
        <ChakraModalBody m={0} p={6} height="100%">
          {isLoading ? (
            <Column height="100px" alignItems="center" justifyContent="center">
              <Spinner />
            </Column>
          ) : (
            content
          )}
        </ChakraModalBody>
        <ChakraModalFooter
          borderTop="1px solid"
          borderColor="base.border"
          m={0}
          px={6}
          py={4}
        >
          {onReplaceAudience ? (
            <Row justifyContent="space-between" width="100%">
              <Button variant="tertiary" onClick={onClose}>
                Close
              </Button>
              <ButtonGroup>
                <Button onClick={handleAddTemplate}>
                  Add to current audience
                </Button>
                <Button variant="primary" onClick={handleReplaceAudience}>
                  Replace current audience
                </Button>
              </ButtonGroup>
            </Row>
          ) : (
            <ButtonGroup>
              <Button variant="tertiary" onClick={onClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleAddTemplate}>
                Add template
              </Button>
            </ButtonGroup>
          )}
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};
