import sum from "lodash/sum";

export const getNumberOfUsersInJourney = (
  nodes: { number_users: string | null }[],
) => {
  return sum(nodes.map(({ number_users }) => Number(number_users ?? 0)));
};

// Adding the unique_counts of each node doesn't necessarily give us complete accuracy, as we can
// have the same row across multiple points in the journey.
export const getNumberOfUniqueUsersInJourney = (
  nodes: { number_unique_users: string | null }[],
) => {
  return sum(
    nodes.map(({ number_unique_users }) => Number(number_unique_users ?? 0)),
  );
};
