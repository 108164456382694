import {
  createContext,
  FC,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from "react";

import groupBy from "lodash/groupBy";
import noop from "lodash/noop";
import partition from "lodash/partition";
import sortBy from "lodash/sortBy";

import { useUser } from "src/contexts/user-context";
import {
  DestinationDefinitionFragment as DestinationDefinition,
  MakeOptional,
  Maybe,
  SourceColumnDescription,
  SourceDefinitionFragment as SourceDefinition,
  SyncableColumn,
  SyncQuery,
} from "src/graphql";
import { useModelRun, useUpdateQuery } from "src/utils/models";
import { useModelState } from "src/hooks/use-model-state";
import { ExtendedTypes, RelationshipHierarchy } from "@hightouch/formkit";
import {
  isRelatedColumn,
  isTransformedColumn,
  isTraitColumn,
  isMergedColumn,
  isRelatedJourneyEventColumn,
  ColumnType,
} from "@hightouch/lib/query/visual/types";
import { OverrideConfig } from "src/components/destinations/types";

export type ColumnModelType = "model" | "related" | "journey-event";

export type ColumnOption = {
  customType?: string | null;
  description?: string | null;
  extendedType?: ExtendedTypes;
  icon?: React.ElementType;
  label: string;
  metadata?: {
    properties: Record<string, string[] | null>;
  };
  entity?: {
    name: string;
    modelType: ColumnModelType | null;
    isMerged: boolean;
    isTrait: boolean;
    isSplit: boolean;
  };
  options?: ColumnOption[];
  type: string | null;
  value: string | Record<string, unknown>;
};

export type FormkitSync = SyncQuery["syncs"][number] & {
  segment:
    | (NonNullable<SyncQuery["syncs"][number]>["segment"] & {
        syncable_columns: SyncableColumn[];
      })
    | null;
};

export type FormkitSyncTemplateConfig = {
  id: number | null;
  config: Record<string, unknown> | null;
  override_config: OverrideConfig | null; // The overridable fields
};

export type FormkitModel = MakeOptional<
  Pick<
    NonNullable<FormkitSync["segment"]>,
    | "columns"
    | "syncable_columns"
    | "connection"
    | "custom_query"
    | "id"
    | "name"
    | "parent"
    | "primary_key"
    | "query_dbt_model_id"
    | "query_looker_look_id"
    | "query_integrations"
    | "query_raw_sql"
    | "query_table_name"
    | "query_type"
    | "visual_query_filter"
    | "visual_query_parent_id"
    | "matchboosting_enabled"
  >,
  | "custom_query"
  | "parent"
  | "query_dbt_model_id"
  | "query_looker_look_id"
  | "query_integrations"
  | "query_raw_sql"
  | "query_table_name"
  | "visual_query_filter"
  | "visual_query_parent_id"
> & {
  source_column_descriptions?: Array<SourceColumnDescription> | null;
};

export type FormkitDestination = NonNullable<FormkitSync["destination"]>;

export type ExternalSegment = {
  id: string;
  external_id: Maybe<string>;
  created_at: string;
};

export type DraftChange = { key: string; op: "add" | "replace" };

type FormkitSyncTemplateOverrideContext = {
  showLockUI: boolean; // Show lock UI
  showRestoreDefaultUI: boolean; // Show the 'restore default' UI
  overrideConfig: OverrideConfig | null;
  relationshipHierarchy: RelationshipHierarchy | null;
  relatedSyncsWithOverrides: Record<string, number> | null;
  isFieldDisabled: (key: string) => boolean;
  /**
   * Function to add missing properties to the relationship hierarchy
   *
   * Some keys do not exist in the formkit definition, but are important when syncing.
   * Ex: `autoSyncColumns` is not in the google sheets formkit definition, but if it's true then `mappings` will be ignored
   * `autoSyncColumns` is set in the mappings component
   */
  onAddMissingRelationships: (parent: string, keys: string[]) => void;
};

export type BaseFormkitContextType = {
  destination: FormkitDestination | undefined;
  destinationDefinition: DestinationDefinition | undefined;
  sourceDefinition: SourceDefinition | undefined;
  model: FormkitModel | undefined;
  sync: FormkitSync | undefined;
  syncTemplate: FormkitSyncTemplateConfig | undefined;
  supportsMatchboosting: boolean | undefined;
  reloadModel: () => void;
  reloadRows: () => void;
  queryRowsError: string | undefined;
  loadingModel: boolean;
  loadingRows: boolean;
  rows: any;
  slug: string | undefined;
  validate: any;
  isEventForwardingForm?: boolean;
  /**Used for drafts */
  isModelDraft?: boolean;
  draftChanges?: DraftChange[];
};

export type FormkitContextType = BaseFormkitContextType & {
  columns: ColumnOption[];
  sourceId: string | undefined;
  workspaceId: string;
  isSetup: boolean;
  tunnel: any;
  credentialId: string | undefined;
  externalSegment: ExternalSegment | undefined;
  destinationConfig: Record<string, unknown> | undefined;
  syncConfig: Record<string, unknown> | undefined;
  // Used to set cloud provider from forms
  setCredentialId?: (credentialId: string) => void;
  /**Used for sync template overrides */
  /**
   * Used to determine where the missing relationships should exist in the formkit definition
   */
  missingRelationshipsInFormkitDefinition: Record<string, string[]> | null;
} & FormkitSyncTemplateOverrideContext;

export const FormkitContext = createContext<FormkitContextType>(
  {} as FormkitContextType,
);

export const useFormkitContext = () =>
  useContext<FormkitContextType>(FormkitContext);

type FormkitProviderProps = {
  children: ReactNode;
  model?: FormkitModel;
  destination?: FormkitDestination;
  destinationConfig?: Record<string, unknown>;
  sync?: FormkitSync;
  syncTemplate?: FormkitSyncTemplateConfig;
  syncConfig?: Record<string, unknown>;
  relatedSyncsWithOverrides?: Record<string, number>;
  destinationDefinition?: DestinationDefinition;
  sourceDefinition?: SourceDefinition;
  externalSegment?: ExternalSegment;
  supportsMatchboosting?: boolean;
  validate: any;
  sourceId?: string;
  credentialId?: string;
  setCredentialId?: (credentialId: string) => void;
  isFieldDisabled?: (key: string) => boolean;
  /**Used within formkit to determine how to render secret fields */
  isSetup?: boolean;
  tunnel?: any;
  /**Used for drafts */
  isModelDraft?: boolean;
  draftChanges?: DraftChange[];
  isEventForwardingForm?: boolean;
  /**Used for sync template overrides */
} & Partial<FormkitSyncTemplateOverrideContext>;

const getColumnValue = (column: any) => {
  if (column.column_reference) {
    if (column.column_reference.type === "raw") {
      return column.column_reference.name;
    } else {
      return column.column_reference;
    }
  }
  return column.name;
};

const getModelType = (
  column: SyncableColumn,
  topLevelModelId: string,
): ColumnModelType => {
  if (isMergedColumn(column.column_reference)) {
    return "related";
  }

  if (isTrait(column)) {
    // Event model columns cannot be synced unless they are used in a journey
    if (column.model.is_event_model) return "journey-event";

    return column.model.model_id?.toString() === topLevelModelId.toString()
      ? "model"
      : "related";
  }

  if (column.is_boosted_column) {
    return "model";
  }

  // Event model columns cannot be synced unless they are used in a journey
  return column.model.is_event_model ? "journey-event" : "model";
};

/**
 * Maps a SyncableColumn to a ColumnOption for UI display
 */
const mapColumn = ({
  column,
  isModelDraft,
  modelId,
  description,
}: {
  column: SyncableColumn;
  isModelDraft: boolean | undefined;
  modelId: string;
  description?: string | null;
}): ColumnOption => {
  const isMerged = isMergedColumn(column.column_reference);
  const isSplitColumn = isSplit(column);
  const isTraitColumn = isTrait(column);

  return {
    customType: column.custom_type,
    label: column.alias || column.name,
    metadata: column.metadata,
    entity: {
      name: !isSplitColumn
        ? column.model.model_name + (isModelDraft ? " (draft)" : "")
        : column.model.model_name,
      modelType: getModelType(column, modelId),
      isMerged,
      isSplit: isSplitColumn,
      isTrait: isTraitColumn,
    },
    type: isSplitColumn ? ColumnType.String : column.type,
    value: getColumnValue(column),
    description,
  };
};

export const MATCHBOOSTED_IDENTIFIER_KEY = "__BOOSTED_COLUMNS__";
export const TRAIT_IDENTIFIER_KEY = "__TRAITS__";
export const SPLIT_IDENTIFIER_KEY = "__SPLITS__";

function isSplit(column: SyncableColumn | undefined): boolean {
  return column?.column_reference?.type === "splitTest";
}

// Covers parent-level and audience-level traits
function isTrait(column: SyncableColumn | undefined): boolean {
  const isParentTrait =
    (isRelatedColumn(column?.column_reference) &&
      isTraitColumn(column?.column_reference.column)) ||
    // Formula trait is represented as a TransformedColumn
    isTransformedColumn(column?.column_reference);

  const isAudienceTrait =
    column?.column_reference?.type === "additionalColumnReference";

  return isParentTrait || isAudienceTrait;
}

/**
 * Groups and maps columns into categorized options for UI display
 *
 * Columns are grouped into:
 * 1. Boosted columns (if supported)
 * 2. Trait columns
 * 3. Split columns
 * 4. Regular columns (grouped by model name). This includes the model that's being synced and columns from merged models.
 */
export const mapColumns = ({
  columns,
  isModelDraft,
  modelId,
  sourceColumnDescriptions,
}: {
  columns: SyncableColumn[] | undefined;
  isModelDraft: boolean | undefined;
  modelId: string;
  sourceColumnDescriptions?: Array<SourceColumnDescription> | null;
}): {
  label: string;
  options: ColumnOption[];
  type: string | null;
  value: string;
}[] => {
  // Early return if no columns
  if (!columns?.length) {
    return [];
  }

  // Create description lookup map
  const descriptionMap = createDescriptionMap(sourceColumnDescriptions);

  // Categorize columns by type
  // Note that we want to prevent users selecting MB columns because these should only
  // be appended through the MB toggle in the mapper
  const columnCategories = categorizeColumns(columns);
  const { traitColumns, splitColumns, regularColumns } = columnCategories;

  // Group regular columns by model name
  const modelGroups = groupBy(
    regularColumns,
    (column) => column.model.model_name,
  );

  // Combine all groups
  const groups = {
    ...modelGroups,
    ...(traitColumns.length > 0 && { [TRAIT_IDENTIFIER_KEY]: traitColumns }),
    ...(splitColumns.length > 0 && { [SPLIT_IDENTIFIER_KEY]: splitColumns }),
  };

  // Format groups into the expected output structure
  return formatGroupsToColumnOptions(groups, {
    isModelDraft,
    modelId,
    descriptionMap,
  });
};

/**
 * Creates a lookup map of column descriptions by name
 */
function createDescriptionMap(
  sourceColumnDescriptions?: Array<SourceColumnDescription> | null,
): Record<string, string | null> {
  return (
    sourceColumnDescriptions?.reduce<Record<string, string | null>>(
      (result, { name, description }) => {
        if (name && description) {
          result[name.toLowerCase()] = description;
        }
        return result;
      },
      {},
    ) ?? {}
  );
}

/**
 * Categorizes columns into boosted, trait, split, and regular columns
 */
function categorizeColumns(columns: SyncableColumn[]) {
  const [boostedColumns, nonBoostedColumns] = partition(
    columns,
    (column) => column.is_boosted_column,
  );

  const [traitColumns, nonTraitColumns] = partition(
    nonBoostedColumns,
    (column) => isTrait(column),
  );

  const [splitColumns, regularColumns] = partition(nonTraitColumns, (column) =>
    isSplit(column),
  );

  return {
    boostedColumns,
    traitColumns,
    splitColumns,
    regularColumns,
  };
}

/**
 * Formats grouped columns into the expected column options structure
 */
function formatGroupsToColumnOptions(
  groups: Record<string, SyncableColumn[]>,
  options: {
    isModelDraft: boolean | undefined;
    modelId: string;
    descriptionMap: Record<string, string | null>;
  },
) {
  const { isModelDraft, modelId, descriptionMap } = options;

  const formattedGroups = Object.entries(groups).map(
    ([key, columns], index) => {
      const firstColumn = columns[0];

      // First group is always "Columns"
      let label = index === 0 ? "Columns" : key.replace(/_/g, " ");
      const isMergedModel = isMergedColumn(firstColumn?.column_reference);
      const isJourneyEventModel = isRelatedJourneyEventColumn(
        firstColumn?.column_reference,
      );

      if (isMergedModel) {
        label = "Merged: " + label;
      } else if (isJourneyEventModel) {
        label = "Properties of journey entry event";
      }

      return {
        label,
        options: sortBy(
          columns.map((column) =>
            mapColumn({
              column,
              isModelDraft,
              modelId,
              description: descriptionMap[column.name.toLowerCase()],
            }),
          ),
          ["label"],
        ),
        entity: {
          name: key,
          modelType: firstColumn ? getModelType(firstColumn, modelId) : null,
          isMerged: isMergedModel,
          isSplit: isSplit(firstColumn),
          isTrait: isTrait(firstColumn),
        },
        type: firstColumn ? getModelType(firstColumn, modelId) : "",
        value: "",
      } satisfies ColumnOption;
    },
  );

  // Filter out empty groups
  return formattedGroups.filter((group) => group.options.length > 0);
}

export const FormkitProvider: FC<Readonly<FormkitProviderProps>> = ({
  children,
  model,
  destination,
  destinationDefinition,
  sourceDefinition,
  supportsMatchboosting,
  showLockUI = false,
  sync,
  syncTemplate,
  relatedSyncsWithOverrides = null,
  syncConfig,
  externalSegment,
  validate,
  sourceId,
  isSetup,
  tunnel,
  credentialId,
  setCredentialId,
  draftChanges,
  isModelDraft,
  destinationConfig,
  relationshipHierarchy = null,
  showRestoreDefaultUI = false,
  isFieldDisabled = () => false,
  onAddMissingRelationships = noop,
  isEventForwardingForm = false,
}) => {
  const { workspace } = useUser();
  const update = useUpdateQuery();

  // XXX: Used to determine where the missing relationships should exist in the formkit definition
  const [
    missingRelationshipsInFormkitDefinition,
    setMissingRelationshipsInFormkitDefinition,
  ] = useState<Record<string, string[]>>({});

  const addMissingRelationships = (parent: string, keys: string[]) => {
    setMissingRelationshipsInFormkitDefinition((previous) => ({
      ...previous,
      [parent]: keys,
    }));
    onAddMissingRelationships(parent, keys);
  };

  const columns = mapColumns({
    columns: model?.syncable_columns,
    isModelDraft,
    modelId: model?.parent ? model.parent.id : model?.id,
    sourceColumnDescriptions: model?.source_column_descriptions,
  });

  const modelState = useModelState(model);

  useEffect(() => {
    if (model) {
      modelState.set(model);
    }
  }, [model]);

  const {
    rows,
    error: queryRowsError,
    runQuery,
    getSchema,
    schemaLoading,
    loading: rowsLoading,
  } = useModelRun(modelState.state, {
    onCompleted: async ({ columns, rows }, error) => {
      if (error || !columns) {
        return;
      }

      if (rows && columns) {
        const jsonColumnsMetadata = extractJsonColumnsAndTheirProperties(rows);

        if (jsonColumnsMetadata) {
          const keys = Object.keys(jsonColumnsMetadata);
          keys.forEach((key) => {
            columns.forEach((column, index) => {
              if (column.name === key) {
                // @ts-expect-error upgraded type from `any`, metadata should be typed
                columns[index].metadata = {
                  properties: jsonColumnsMetadata[key]
                    ? Array.from(jsonColumnsMetadata[key] || [])
                    : undefined,
                };
              }
            });
          });
        }
      }

      await update({
        model: modelState.state,
        columns,
        overwriteMetadata: rows ? true : false,
      });
    },
  });

  const columnsLoading = sourceDefinition?.supportsResultSchema
    ? schemaLoading
    : rowsLoading;

  const reloadRows = () => {
    return runQuery({
      limit: true,
      // No reason to add this here since we only care about the columns.
      disableRowCounter: true,
    });
  };

  const reloadColumns = () => {
    if (sourceDefinition?.supportsResultSchema) {
      getSchema();
    } else {
      reloadRows();
    }
  };

  return (
    <FormkitContext.Provider
      value={{
        isSetup: Boolean(isSetup),
        model,
        destination,
        validate,
        sync,
        syncTemplate,
        syncConfig: syncConfig ?? sync?.config,
        destinationDefinition,
        destinationConfig,
        sourceDefinition,
        externalSegment,
        columns,
        supportsMatchboosting,
        loadingModel: columnsLoading,
        loadingRows: rowsLoading,
        reloadModel: reloadColumns,
        reloadRows,
        queryRowsError,
        rows,
        slug: destination?.type,
        sourceId,
        tunnel,
        workspaceId: String(workspace?.id),
        credentialId,
        setCredentialId,
        draftChanges,
        isModelDraft,

        showLockUI,
        overrideConfig:
          // syncTemplate or sync will be passed in, provided by the view. This context is used for both cases.
          // if syncTemplate is passed in, it will be the override config for the sync template
          // if sync is passed in, it will be the override config for the sync template attached to the sync
          syncTemplate?.override_config || sync?.sync_template?.override_config,
        relatedSyncsWithOverrides,
        relationshipHierarchy,
        showRestoreDefaultUI,
        isFieldDisabled,
        missingRelationshipsInFormkitDefinition,
        onAddMissingRelationships: addMissingRelationships,
        isEventForwardingForm,
      }}
    >
      {children}
    </FormkitContext.Provider>
  );
};

function extractJsonColumnsAndTheirProperties(rows: Record<string, any>[]): {
  [column: string]: Set<string>;
} {
  const jsonColumns: { [column: string]: Set<string> } = {};
  rows.forEach((row) => {
    const columns = Object.keys(row);
    columns.forEach((column) => {
      if (Array.isArray(row[column])) {
        if (!jsonColumns[column]) {
          jsonColumns[column] = new Set();
        }
        const firstEntry = row[column][0];
        if (
          typeof firstEntry === "object" &&
          !Array.isArray(firstEntry) &&
          firstEntry !== null
        ) {
          const props = Object.keys(firstEntry);
          props.forEach((prop) => jsonColumns[column]?.add(prop));
        }
      }
    });
  });

  return jsonColumns;
}
