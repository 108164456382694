import { HTImage } from "src/components/image";
import { FC, useEffect, useState } from "react";

import {
  Column,
  Row,
  Heading,
  FormField,
  Select,
  TextInput,
  useToast,
  Textarea,
} from "@hightouchio/ui";

import { DestinationForm } from "src/components/destinations/sync-form";
import { Explore } from "src/components/explore";
import { QueryTypeSelect } from "src/components/explore/query-type-select";
import {
  ScheduleManager,
  useScheduleState,
} from "src/components/schedule/schedule-manager";
import { ScheduleType } from "src/components/schedule/types";
import { useUser } from "src/contexts/user-context";
import {
  useCreateSyncMutation,
  SourceQuery,
  DestinationQuery,
  useCreateModelMutation,
  useModelQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { QueryType } from "src/types/models";
import { WizardStep, DeprecatedWizard } from "src/components/wizard";
import { useModelRun, getModelInputFromState } from "src/utils/models";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import { useWizardStepper } from "src/utils/use-wizard-stepper";
import { useModelState } from "src/hooks/use-model-state";

type Props = {
  source: SourceQuery["connections_by_pk"];
  destination: DestinationQuery["destinations_by_pk"];
  onSubmit: ({ id }: { id: string }) => void;
  onCancel: () => void;
};

export const CreateOnboardingSyncWizard: FC<Readonly<Props>> = ({
  destination,
  source,
  onSubmit,
  onCancel,
}) => {
  const { toast } = useToast();
  const { workspace } = useUser();
  const { validateSchedule } = useScheduleState("sync");
  const [modelId, setModelId] = useState<string>();
  const [modelName, setModelName] = useState("");
  const [modelDescription, setModelDescription] = useState("");
  const [schedule, setSchedule] = useState<any>({ type: ScheduleType.MANUAL });
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [config, setConfig] = useState<any>();
  const [hasQueryColumns, setHasQueryColumns] = useState(false);
  const { getSlug: getSyncSlug } = useResourceSlug(SlugResourceType.Syncs);
  const { getSlug: getModelSlug } = useResourceSlug(SlugResourceType.Segments);
  const [step, setStep] = useWizardStepper(0);
  const [syncDescription, setSyncDescription] = useState("");

  const { data: model } = useModelQuery(
    {
      id: String(modelId),
    },
    { enabled: Boolean(modelId), select: (data) => data.segments_by_pk },
  );

  const { mutateAsync: createSync } = useCreateSyncMutation();
  const createModelMutation = useCreateModelMutation();

  const modelState = useModelState({ connection: source });

  const {
    runQuery,
    cancelQuery,
    getSchema,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
  } = useModelRun(modelState.state);

  const createModel = async () => {
    const slug = await getModelSlug(modelName);

    const data = await createModelMutation.mutateAsync({
      input: {
        name: modelName,
        description: modelDescription,
        ...getModelInputFromState(modelState.state),
        slug,
        columns: { data: columns },
        draft: false,
      },
    });

    if (!data) {
      return;
    }

    const id = data.insert_segments_one?.id;

    analytics.track("Model Config Completed", {
      source_type: source?.type,
      source_id: source?.id,
      model_id: id,
      query_type: modelState.state.query_type,
    });

    analytics.track("Model Created", {
      workspace_id: workspace?.id,
      workspace_slug: workspace?.slug,
      model_id: id,
      model_name: modelName,
      source_id: source?.id,
      source_type: source?.type,
      query_type: modelState.state.query_type,
      origin_page: location.pathname,
    });

    setModelId(id);
  };

  const submit = async () => {
    const slug = await getSyncSlug(`${model?.name} to ${destination?.name}`);

    const data = await createSync({
      object: {
        slug,
        destination_id: destination?.id,
        description: syncDescription,
        segment_id: model?.id,
        config: {
          ...config,
          configVersion: destination?.definition?.configVersion,
        },
        schedule: schedule?.type === "manual" ? null : schedule,
        draft: workspace?.approvals_required,
        is_streaming: isStreaming,
      },
    });

    const id = data?.insert_destination_instances_one?.id;

    analytics.track("Sync Config Completed", {
      source_type: source?.type,
      source_id: source?.id,
      model_id: model?.id,
      query_type: model?.query_type,
      destination_type: destination?.type,
      destination_id: destination?.id,
      sync_id: id,
    });

    analytics.track("Sync Created", {
      destination_id: destination?.id,
      destination_name: destination?.name,
      destination_type: destination?.definition?.name,
      model_id: model?.id,
      model_name: model?.name,
      schedule_type: schedule?.type,
    });

    // If we need to create a draft, we don't want to open the toast here.
    if (!workspace?.approvals_required) {
      toast({
        id: "create-sync",
        title: "Sync was created",
        variant: "success",
      });
    }

    if (typeof onSubmit === "function" && id) {
      onSubmit({ id });
    }
  };

  useEffect(() => {
    if (columns?.length && !queryError) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  useEffect(() => {
    if (step === 1) {
      analytics.track("Model Config Started", {
        source_id: source?.id,
      });
    }
    if (step === 3) {
      analytics.track("Sync Config Started", {
        source_id: source?.id,
        model_id: model?.id,
        destination_id: destination?.id,
      });
    }
  }, [step]);

  const steps: WizardStep[] = [
    {
      title: "Query source",
      disabled:
        !modelState.isValid ||
        (source?.definition?.supportsResultSchema
          ? false
          : !hasQueryColumns || Boolean(queryError)),
      header: <Heading>Query your source</Heading>,
      onContinue: async () => {
        if (source?.definition?.supportsResultSchema && !hasQueryColumns) {
          const { error } = (await getSchema()) || {};
          if (error) {
            return;
          }
        }
        setStep((step) => step + 1);
      },
      continueTooltip:
        // if query is defined and we don't support result schema,
        // ask them to either fix their error or preview before continuing
        modelState.isValid &&
        !source?.definition?.supportsResultSchema &&
        !hasQueryColumns
          ? queryError
            ? "Fix the error and preview again to continue"
            : "Preview query to continue"
          : undefined,
      render: () => {
        return (
          <>
            {modelState.state.query_type ? (
              <Explore
                modelState={modelState}
                cancelQuery={cancelQuery}
                columns={columns}
                isResultTruncated={Boolean(isResultTruncated)}
                numRowsWithoutLimit={numRowsWithoutLimit}
                rows={rows}
                runQuery={runQuery}
                source={source}
                error={queryError}
                errorAtLine={queryErrorAtLine}
                loading={queryLoading}
                rowsPerPage={15}
              />
            ) : (
              <QueryTypeSelect
                selected={modelState.state.query_type as QueryType}
                source={source}
                onChange={(query_type) => {
                  modelState.onChange({
                    query_type,
                  });
                }}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Configure model",
      header: <Heading>Configure your model</Heading>,
      onContinue: async () => {
        await createModel();
        setStep((step) => step + 1);
      },
      render: () => {
        const columnOptions =
          columns?.map(({ name }) => ({ value: name, label: name })) ?? [];

        return (
          <Column gap={6}>
            <FormField label="Model name">
              <TextInput
                value={modelName}
                onChange={(event) => setModelName(event.target.value)}
              />
            </FormField>
            <FormField isOptional label="Description">
              <Textarea
                placeholder="Enter a description..."
                value={modelDescription}
                onChange={(e) => setModelDescription(e.target.value)}
              />
            </FormField>
            <FormField
              description="This is the column that uniquely identifies each row (e.g., customer ID, email address, invoice number)"
              label="Primary key"
            >
              <Select
                isDisabled={!columnOptions?.length}
                optionLabel={(co) => co.label}
                optionValue={(co) => co.value}
                options={columnOptions as { value: string; label: string }[]}
                placeholder="Select a column..."
                value={
                  columnOptions?.find(
                    (co) => co.value === modelState.state.primary_key,
                  )?.value
                }
                onChange={(primary_key) => {
                  modelState.onChange({
                    primary_key,
                  });
                }}
              />
            </FormField>
          </Column>
        );
      },
    },
    {
      title: "Configure sync",
      continueProps: { form: "destination-form", type: "submit" as const },
      onContinue: () => {}, // required to defer continue to DestinationForm onSubmit
      bg: "base.lightBackground",
      header: (
        <Row align="center" gap={4}>
          <HTImage
            alt={destination?.definition?.name || ""}
            src={destination?.definition?.icon || ""}
            sx={{ width: "32px", objectFit: "contain", height: "32px" }}
          />
          <Heading>
            Configure sync to{" "}
            {destination?.name || destination?.definition?.name}
          </Heading>
        </Row>
      ),
      render: () => {
        if (destination && model && source) {
          return (
            <DestinationForm
              hideSave
              destination={destination}
              destinationDefinition={destination?.definition}
              model={model}
              slug={destination.type}
              permission={{
                v2: {
                  resource: "sync",
                  grant: "can_create",
                  creationOptions: {
                    modelId: model.id.toString(),
                    destinationId: destination.id.toString(),
                  },
                },
                v1: {
                  resource: "sync",
                  grant: "create",
                },
              }}
              testPermission={{
                v2: {
                  resource: "sync",
                  grant: "can_test",
                  creationOptions: {
                    modelId: model.id.toString(),
                    destinationId: destination.id.toString(),
                  },
                },
                v1: {
                  resource: "sync",
                  grant: "testrow",
                },
              }}
              sourceDefinition={source.definition}
              onSubmit={(config) => {
                setConfig(config);
                setStep((step) => step + 1);
                return Promise.resolve();
              }}
            />
          );
        }
        return null;
      },
    },
    {
      title: "Finalize sync",
      disabled: !validateSchedule(schedule),
      header: <Heading>Finalize settings for this sync</Heading>,
      render: () => (
        <Column gap={6}>
          <FormField label="Description" isOptional>
            <Textarea
              placeholder="Enter a description..."
              value={syncDescription}
              onChange={(e) => setSyncDescription(e.target.value)}
            />
          </FormField>
          <ScheduleManager
            schedule={schedule}
            setSchedule={setSchedule}
            isStreamable={model?.is_streamable}
            isStreaming={isStreaming}
            setIsStreaming={setIsStreaming}
          />
        </Column>
      ),
    },
  ];

  return (
    <DeprecatedWizard
      title="Create sync"
      step={step}
      steps={steps}
      setStep={setStep}
      onSubmit={submit}
      onCancel={onCancel}
    />
  );
};
