import { JourneyNodeDetails } from "src/pages/journeys/types";
import {
  EntryCohortConfig,
  EntryEventConfig,
  JourneyNodeConfig,
  JourneyNodeType,
  SegmentsConfig,
  SplitsConfig,
  SyncConfig,
  TimeDelayConfig,
  WaitUntilEventConfig,
} from "src/types/journeys";

import {
  EntryCohortNodeDescription,
  EntryEventNodeDescription,
} from "./entry-node-description";
import { HoldUntilNodeDescription } from "./hold-until-node-description";
import { SegmentNodeDescription } from "./segment-node-description";
import { SyncNodeDescription } from "./sync-node-description";
import { TimeDelayDescription } from "./time-delay-node-description";
import { SplitNodeDescription } from "./split-node-description";

export const NodeDescription = (
  props: JourneyNodeDetails<JourneyNodeConfig>,
) => {
  switch (props.config.type) {
    case JourneyNodeType.EntryCohort:
      return (
        <EntryCohortNodeDescription
          {...(props as JourneyNodeDetails<EntryCohortConfig>)}
        />
      );
    case JourneyNodeType.EntryEvent:
      return (
        <EntryEventNodeDescription
          {...(props as JourneyNodeDetails<EntryEventConfig>)}
        />
      );
    case JourneyNodeType.TimeDelay:
      return (
        <TimeDelayDescription
          {...(props as JourneyNodeDetails<TimeDelayConfig>)}
        />
      );
    case JourneyNodeType.WaitUntilEvent:
      return (
        <HoldUntilNodeDescription
          {...(props as JourneyNodeDetails<WaitUntilEventConfig>)}
        />
      );
    case JourneyNodeType.Segments:
      return (
        <SegmentNodeDescription
          {...(props as JourneyNodeDetails<SegmentsConfig>)}
        />
      );
    case JourneyNodeType.Splits:
      return (
        <SplitNodeDescription
          {...(props as JourneyNodeDetails<SplitsConfig>)}
        />
      );
    case JourneyNodeType.Sync:
      return (
        <SyncNodeDescription {...(props as JourneyNodeDetails<SyncConfig>)} />
      );
    default:
      return null;
  }
};
