import * as yup from "yup";

export enum InsightsChartType {
  GlobalBreakdown = "global",
  CorrelationsBreakdown = "correlation",
}

export enum InsightsType {
  TopPerformers = "Top performers",
  PersonalizationInsights = "Personalization insights",
}

export enum BreakdownType {
  UserFeature = "user_feature",
  ActionFeature = "action_feature",
}

/**
 * Since the charts are generated by the backend but the main request object is
 * stored as a JSON blob, we should validate the request schema here to ensure
 * the data is coming in as expected. This schema can also be used to catch any
 * missing links between the frontend and backend.
 */
export const chartSchema = yup.object().shape({
  id: yup.string().required(),
  outcome_id: yup.string().required(),
  chart_type: yup
    .string()
    .oneOf([
      InsightsChartType.GlobalBreakdown,
      InsightsChartType.CorrelationsBreakdown,
    ])
    .required(),
  breakdown_by_type: yup
    .string()
    .oneOf([BreakdownType.UserFeature, BreakdownType.ActionFeature])
    .required(),
  breakdown_by_dimension: yup.string().required(),
  rank: yup.number().required(),
  top_chart: yup.boolean().optional(),
  time_series_request: yup
    .object()
    .shape({
      cohortIds: yup.array().of(yup.string()).required(),
      frequency: yup.string().required(),
      groupByColumns: yup.array().of(yup.object()).required(),
      metricDefinitions: yup.array().of(yup.object()).required(),
      timeWindow: yup.object().required(),
    })
    .required(),
});
