import { Column, EditIcon, Row, Text } from "@hightouchio/ui";
import pluralize from "pluralize";
import { useFormContext } from "react-hook-form";
import { Link } from "src/router";

import { PermissionedLinkButton } from "src/components/permission";
import { ScheduleText } from "src/pages/journeys/components";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";

import { Card } from "./card";

export const SettingsCard = () => {
  const { updateJourneyPermission, unauthorizedTooltip } = useGraphContext();
  const form = useFormContext();

  const schedule = form.watch("journey.schedule");
  const exitCriteria = form.watch("journey.exitCriteria");

  const numberOfExitCriteriaConditions = exitCriteria.conditions.length;

  return (
    <Card>
      <Row justify="space-between">
        <Text fontWeight="medium" size="lg">
          Settings
        </Text>
        <PermissionedLinkButton
          icon={EditIcon}
          href="settings"
          permission={updateJourneyPermission}
          unauthorizedTooltip={unauthorizedTooltip}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore size exists on link button props
          size="sm"
        >
          Edit
        </PermissionedLinkButton>
      </Row>
      <Row justify="space-between">
        <Column gap={2}>
          <Text
            fontWeight="semibold"
            size="sm"
            color="text.secondary"
            textTransform="uppercase"
          >
            Schedule
          </Text>
          <ScheduleText schedule={schedule} href="settings" />
        </Column>
        <Column gap={2}>
          <Text
            fontWeight="semibold"
            size="sm"
            color="text.secondary"
            textTransform="uppercase"
          >
            Exit criteria
          </Text>
          <Link href="settings">
            <Text color="inherit" fontWeight="medium">
              {pluralize("filter", numberOfExitCriteriaConditions, true)}
            </Text>
          </Link>
        </Column>
      </Row>
    </Card>
  );
};
