import { CreateEventWarehouseDestination } from "./create";
import { EventDestinationLayout } from "./destination";
import { EventDestinations } from "./destinations";
import { Navigate, RouteObject } from "src/router";
import { Configuration } from "./destination/configuration";
import { SchemaSettings } from "./destination/schema-settings";

export const routes: Array<RouteObject> = [
  {
    index: true,
    element: <EventDestinations />,
  },
  {
    path: "new",
    element: <CreateEventWarehouseDestination />,
  },
  {
    path: ":id",
    element: <EventDestinationLayout />,
    children: [
      {
        path: "configuration",
        element: <Configuration />,
      },
      {
        path: "sync-settings",
        element: <SchemaSettings />,
      },
      {
        index: true,
        element: (
          <Navigate
            to={{ pathname: "configuration", search: location.search }}
            replace
          />
        ),
      },
    ],
  },
];
