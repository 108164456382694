import { Box, ChakraTooltip, Row, Text } from "@hightouchio/ui";
import { FC } from "react";
import { GraphTooltip } from "src/components/analytics/graph-tooltip";
import { NumericFontStyles } from "src/components/audiences/constants";

const MinWidth = 5;

export const AnalyticsCell: FC<{
  color: string;
  metricName: string;
  value: string;
  tooltipValue: string;
  width: number;
  tooltipText?: string;
}> = ({ color, metricName, value, width, tooltipText, tooltipValue }) => {
  return (
    <Row align="center" width="100%" gap={1}>
      <ChakraTooltip
        bg="text.primary"
        p={3}
        placement="left"
        label={
          <GraphTooltip
            color={color}
            title={metricName}
            subtitles={[tooltipText]}
            value={tooltipValue ? [{ value: tooltipValue }] : []}
          />
        }
      >
        <Box
          bg={color}
          borderRadius="sm"
          height="16px"
          minWidth={value === "0" ? 0 : "1px"}
          flexGrow={value === "0" ? 0 : Math.max(width, MinWidth) / 100}
        />
      </ChakraTooltip>
      <Box as={Text} fontWeight="medium" sx={NumericFontStyles}>
        {value}
      </Box>
    </Row>
  );
};
