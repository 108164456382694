import { FC, useState } from "react";

import {
  Avatar,
  Column,
  ConfirmationDialog,
  CopyIcon,
  DeleteIcon,
  EditableDescription,
  Menu,
  MenuActionsButton,
  MenuDivider,
  MenuList,
  Paragraph,
  Row,
  Text,
} from "@hightouchio/ui";
import { Link, useNavigate } from "src/router";

import { Outlet, useOutletContext, useParams } from "src/router";

import { DetailBar } from "src/components/detail-bar";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Labels } from "src/components/labels/labels";
import { DetailPage } from "src/components/layout";
import { Crumb } from "src/components/layout/header/breadcrumbs";
import { PageSpinner } from "src/components/loading";
import { Warning } from "src/components/warning";
import {
  useAudienceTemplateQuery,
  useAudienceTemplateParentQuery,
  AudienceTemplateQuery,
  AudienceTemplateParentQuery,
} from "src/graphql";
import { formatDate } from "src/utils/time";

import {
  PermissionedEditableHeading,
  PermissionedMenuItem,
} from "src/components/permission";
import { useModelState } from "src/hooks/use-model-state";
import { QueryType } from "src/types/models";
import { AudienceExplore } from "src/components/audiences/audience-explore";
import { useMutations } from "./use-mutations";
import { createSchemaObjectLink } from "src/components/audiences/utils";
import { TextWithTooltip } from "src/components/text-with-tooltip";

export type AudienceTemplatePageOutletState = {
  audienceTemplate: NonNullable<
    AudienceTemplateQuery["audience_templates_by_pk"]
  >;
  parentModel: NonNullable<
    AudienceTemplateParentQuery["audience_templates_by_pk"]
  >["parent_model"];
  id: string;
};

export const AudienceTemplateLoader = () => {
  const { id } = useParams<{ id?: string }>();

  const { data: audienceTemplate } = useAudienceTemplateQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      select: (data) => data.audience_templates_by_pk,
      suspense: true,
    },
  );

  const { data: audienceTemplateParent } = useAudienceTemplateParentQuery(
    {
      audienceTemplateId: id ?? "",
    },
    {
      enabled: Boolean(id),
      select: ({ audience_templates_by_pk }) => {
        if (!audience_templates_by_pk?.parent_model)
          return audience_templates_by_pk?.parent_model;

        return {
          ...audience_templates_by_pk?.parent_model,
          // We can do this filtering in GraphQL, but we're seeing slow performance
          // so we do it here instead
          relationships:
            audience_templates_by_pk?.parent_model?.relationships.filter(
              (relationship) =>
                relationship.to_model.type === null ||
                relationship.to_model.type === "interaction",
            ),
        };
      },
      suspense: true,
    },
  );

  if (!id) {
    return <PageSpinner />;
  }

  if (!audienceTemplate) {
    return (
      <Warning
        subtitle="It may have been deleted"
        title="Audience template not found"
      />
    );
  }

  return (
    <Outlet
      context={{
        audienceTemplate,
        parentModel: audienceTemplateParent,
        id,
      }}
    />
  );
};

export const AudienceTemplate: FC = () => {
  const { audienceTemplate, parentModel, id } =
    useOutletContext<AudienceTemplatePageOutletState>();
  const navigate = useNavigate();
  const [deleteModal, setDeleteModal] = useState(false);

  const { updateName, updateDescription, updateTemplate, deleteTemplate } =
    useMutations({ id });

  const updatedByUsername =
    audienceTemplate.updated_by_user?.name ||
    audienceTemplate.created_by_user?.name;
  const labelKeys = Object.keys(audienceTemplate?.labels || {});

  const source = audienceTemplate?.parent_model.connection;
  const modelState = useModelState({
    query_type: QueryType.Visual,
    connection: source,
    parent: parentModel,
    visual_query_filter: audienceTemplate.definition,
  });

  const crumbs: Crumb[] = [
    { label: "All audience templates", link: "/templates/audience-templates" },
  ];

  return (
    <>
      <DetailPage
        title={`${audienceTemplate.name} - Audience Template`}
        contentFullWidth
        crumbs={crumbs}
        header={
          <Column width="100%">
            <Row
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <PermissionedEditableHeading
                permission={{
                  v1: {
                    resource: "audience_schema",
                    grant: "update",
                    id: parentModel.id,
                  },
                  v2: {
                    resource: "model",
                    grant: "can_update",
                    id: parentModel.id,
                  },
                }}
                size="lg"
                value={audienceTemplate?.name ?? ""}
                onChange={updateName}
              />
              <Row alignItems="center" gap={3} ml={8}>
                <Menu>
                  <MenuActionsButton variant="secondary" />
                  <MenuList>
                    <PermissionedMenuItem
                      permission={{
                        v1: {
                          resource: "audience_schema",
                          grant: "update",
                          id: parentModel.id,
                        },
                        v2: {
                          resource: "model",
                          grant: "can_update",
                          id: parentModel.id,
                        },
                      }}
                      icon={CopyIcon}
                      onClick={() => navigate("clone")}
                    >
                      Make a copy
                    </PermissionedMenuItem>
                    <MenuDivider />
                    <PermissionedMenuItem
                      permission={{
                        v1: {
                          resource: "audience_schema",
                          grant: "delete",
                          id: parentModel.id,
                        },
                        v2: {
                          resource: "model",
                          grant: "can_delete",
                          id: parentModel.id,
                        },
                      }}
                      icon={DeleteIcon}
                      variant="danger"
                      onClick={() => {
                        setDeleteModal(true);
                      }}
                    >
                      Delete
                    </PermissionedMenuItem>
                  </MenuList>
                </Menu>
              </Row>
            </Row>
            <Row>
              <EditableDescription
                value={audienceTemplate.description ?? ""}
                onChange={updateDescription}
              />
            </Row>
            <Row mt={1}>
              <DetailBar>
                <Row align="center" gap={2} flexShrink={0}>
                  <IntegrationIcon
                    src={source?.definition?.icon}
                    name={source?.definition?.name ?? ""}
                  />
                  <Link
                    href={createSchemaObjectLink(
                      source?.id,
                      parentModel.id,
                      "query",
                    )}
                  >
                    <TextWithTooltip fontWeight="medium" color="inherit">
                      {parentModel.name}
                    </TextWithTooltip>
                  </Link>
                </Row>
                <Row align="center" gap={2} flexShrink={0}>
                  <Text>Last updated:</Text>
                  <Row gap={1} align="center">
                    {formatDate(
                      (audienceTemplate.updated_at ||
                        audienceTemplate.created_at)!,
                    )}
                    {updatedByUsername && (
                      <>
                        <Text>by</Text>
                        <Avatar size="xs" name={updatedByUsername} />
                      </>
                    )}
                  </Row>
                </Row>

                {labelKeys?.length > 0 && (
                  <Row>
                    <Labels labels={audienceTemplate?.labels} />
                  </Row>
                )}
              </DetailBar>
            </Row>
          </Column>
        }
        tabs={[]}
      >
        <AudienceExplore
          modelState={modelState}
          parentModel={parentModel}
          source={source}
          variant="template"
          onSave={async () => {
            await updateTemplate({
              definition: modelState.state.visual_query_filter,
            });
          }}
        />
      </DetailPage>

      <ConfirmationDialog
        confirmButtonText="Delete"
        isOpen={deleteModal}
        title="Delete audience template"
        variant="danger"
        onClose={() => setDeleteModal(false)}
        onConfirm={async () => {
          await deleteTemplate();
          navigate("/templates/audience-templates");
        }}
      >
        <Paragraph>
          Are you sure you want to delete this template? You won't be able to
          undo this.
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
