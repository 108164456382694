import { FC } from "react";

import { RadioGroup, Radio } from "@hightouchio/ui";
import { capitalize } from "lodash";

import { FormMethod as EventsFormMethod } from "src/events/destinations/setup/form-method";
import {
  FormMethod as SourceFormMethod,
  FormMethodProps,
} from "./sources/setup/form-method";
import { OAuthMethod, OAuthMethodProps } from "./sources/setup/oauth-method";
import { Section } from "src/formkit/components";

type SelectedMethodFormProps = OAuthMethodProps &
  FormMethodProps & {
    methodKey: string | undefined;
    setMethodKey: (methodKey: string) => void;
    disableAuthMethod: boolean | undefined;
    isEventsForm?: boolean;
  };

export const SelectedMethodForm: FC<Readonly<SelectedMethodFormProps>> = (
  props,
) => {
  const {
    isEventsForm,
    setupMethods,
    config,
    disableAuthMethod,
    methodKey,
    setMethodKey,
  } = props;
  const selectedMethod = setupMethods?.find((o) => o.key === config?.methodKey);

  return (
    <>
      {setupMethods.length > 1 && (
        <Section heading="Choose an authentication method">
          <RadioGroup
            isDisabled={disableAuthMethod}
            orientation="vertical"
            value={methodKey ?? ""}
            onChange={setMethodKey}
          >
            {setupMethods.map((setupMethod) => (
              <Radio
                key={setupMethod.key}
                label={setupMethod.label || capitalize(setupMethod.key)}
                value={setupMethod.key}
              />
            ))}
          </RadioGroup>
        </Section>
      )}

      {selectedMethod?.method === "form" ? (
        isEventsForm ? (
          <EventsFormMethod {...props} />
        ) : (
          <SourceFormMethod {...props} />
        )
      ) : selectedMethod?.method === "oauth" ? (
        <OAuthMethod {...props} />
      ) : null}
    </>
  );
};
