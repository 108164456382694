import { useState } from "react";

import { SectionHeading } from "@hightouchio/ui";
import { AnalyticsGraph } from "src/components/analytics/cross-audience-graph/analytics-graph";
import { GraphType, TimeOptions } from "src/pages/analytics/types";
import {
  AnalyticsFrequency,
  AndOrCondition,
  PropertyCondition,
} from "src/types/visual";
import { Flow } from "..";
import { FlowChartTimeOptions, FlowChartWrapper } from "./flow-chart-wrapper";

type SendsOverTimeChartProps = {
  flowId: string;
  parentModelId: string | number | undefined | null;
  flowMessages: Flow["messages"];
  lastFlowRunStartedAt: string;
  flowStartDate: string;
  conditions?: AndOrCondition<PropertyCondition>[];
};

export const SendsOverTimeChart = ({
  flowId,
  parentModelId,
  flowMessages,
  lastFlowRunStartedAt,
  flowStartDate,
  conditions = [],
}: SendsOverTimeChartProps) => {
  const [hoveredLine, setHoveredLine] = useState<string>();
  const [timeValue, setTimeValue] = useState<FlowChartTimeOptions>(
    TimeOptions.ThirtyDays,
  );

  return (
    <FlowChartWrapper
      header={<SectionHeading>Sends over time</SectionHeading>}
      timeValue={timeValue}
      setTimeValue={setTimeValue}
      allTimeStartDate={flowStartDate}
      lastFlowRunStartedAt={lastFlowRunStartedAt}
      flowId={flowId}
      parentModelId={parentModelId}
      groupByColumns={[]}
      frequency={AnalyticsFrequency.Daily}
      conditions={conditions}
      flowMessages={flowMessages}
      transformForPerformance={true}
    >
      {({ data }) => (
        <AnalyticsGraph
          graphSeries={data}
          hoveredLine={hoveredLine}
          onHoverLine={setHoveredLine}
          type={GraphType.Column}
        />
      )}
    </FlowChartWrapper>
  );
};
