import { FC } from "react";

import {
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  FormField,
  IconButton,
  NumberInput,
  Row,
  Select,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Controller, useFormContext } from "react-hook-form";

import { Card } from "src/components/card";
import { QueryBuilderProvider } from "src/components/explore/context/query-builder-context";
import { isAndOrCondition } from "src/components/explore/utils/type-guards";
import { ErrorMessage } from "src/components/explore/visual/error-message";
import { EventFilter } from "src/components/explore/visual/event-filter";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { PermissionedEditableHeading } from "src/components/permission";
import { TIME_OPTIONS } from "src/constants";
import { useFormErrorContext } from "src/contexts/form-error-context";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import {
  JourneyNodeDetails,
  NodeDetailFormProps,
} from "src/pages/journeys/types";
import { getJourneyNodeDetailsByType } from "src/pages/journeys/utils/get-journey-nodes-by-type";
import { JourneyNodeType, WaitUntilEventConfig } from "src/types/journeys";
import {
  Audience,
  ConditionType,
  EventCondition,
  initialEventCondition,
} from "src/types/visual";

import { getFormattedSubconditions } from "./utils";
import { JourneyGraph } from "src/graphql";
import { journeyWaitUntilSchemaResolver } from "src/pages/journeys/forms/validation-resolvers";

export const HoldUntilForm: FC<NodeDetailFormProps<WaitUntilEventConfig>> = ({
  id, // id techincally lives in data too, redundant to have both :/
  data,
  onClose,
}) => {
  const {
    enableReferencingEntryEventInJourneyNodes,
    useLegacyJourneysTimeMinimum,
  } = useFlags();
  const {
    nodes,
    parentModel,
    onUpdateNode,
    updateJourneyPermission,
    unauthorizedTooltip,
    nodeErrors,
  } = useGraphContext();
  const { hasValidationErrors } = useFormErrorContext();

  const parentForm = useFormContext<JourneyGraph>();
  // We can use getValues here because the journey schedule cannot be
  // updated while we have this drawer open.
  const currentJourneySchedule = parentForm.getValues("journey.schedule");
  const nodeValidationError = nodeErrors[id];

  const entryEventNode = getJourneyNodeDetailsByType(
    nodes,
    JourneyNodeType.EntryEvent,
  )?.[0];
  const entryEventSegmentId = entryEventNode?.segment_id;
  const entryEventRelationshipId = entryEventNode?.event_relationship_id;

  const form = useHightouchForm<JourneyNodeDetails<WaitUntilEventConfig>>({
    onSubmit: (newTileData) => {
      if (hasValidationErrors()) {
        throw new Error("Form has validation errors");
      }

      onUpdateNode(id, {
        ...newTileData,
      });
      onClose();

      return Promise.resolve();
    },
    onError: () => {
      // Defined `onError` to ignore sentry tracking in `useHightouchForm`
    },
    success: "Tile was saved",
    values: {
      ...data,
      config: {
        ...data.config,
        // Make sure event_conditions is a top level AND condition
        event_conditions: getFormattedSubconditions(
          data.config.event_conditions,
        ),
      },
    },
    resolver: (data, context, options) =>
      journeyWaitUntilSchemaResolver(
        data,
        {
          ...context,
          useLegacyJourneysTimeMinimum,
          currentJourneySchedule,
        },
        options,
      ),
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Column types
  const segmentId = form.watch("segment_id");
  const eventRelationshipId = form.watch("event_relationship_id");

  return (
    <Form form={form}>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <Controller
            name="name"
            control={form.control}
            render={({ field, fieldState }) => (
              <Column>
                <PermissionedEditableHeading
                  permission={updateJourneyPermission}
                  unauthorizedTooltip={unauthorizedTooltip}
                  value={field.value}
                  onChange={field.onChange}
                />
                {fieldState.error?.message && (
                  <ErrorMessage fontWeight="normal">
                    {fieldState.error.message}
                  </ErrorMessage>
                )}
              </Column>
            )}
          />
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody bg="base.lightBackground">
        <Column minHeight={0} flex={1} gap={6} pb={4}>
          <Card>
            <FormField
              label="Hold criteria"
              description="Hold user at this tile until they perform the following"
            >
              <Column p={4} bg="gray.100" borderRadius="md">
                <Column gap={4}>
                  <Controller
                    control={form.control}
                    name="config.event_conditions"
                    render={({ field }) => (
                      <QueryBuilderProvider
                        audience={null}
                        parentModel={parentModel}
                        enableReferencingEntryEvent={
                          enableReferencingEntryEventInJourneyNodes
                        }
                        selectedEvent={
                          entryEventSegmentId && entryEventRelationshipId
                            ? {
                                // redundant - these are numbers but typed as strings
                                eventModelId: Number(entryEventSegmentId),
                                relationshipId: Number(
                                  entryEventRelationshipId,
                                ),
                              }
                            : undefined
                        }
                      >
                        <EventFilter
                          hideFunnelCondition
                          hideOperatorFilter
                          hideWindowCondition
                          showOnlyEventOptions
                          audience={{} as Audience}
                          condition={
                            segmentId && eventRelationshipId
                              ? ({
                                  type: ConditionType.Event,
                                  eventModelId: Number(segmentId),
                                  relationshipId: Number(eventRelationshipId),
                                  // Should always be true, but narrowing for ts sake
                                  subconditions: isAndOrCondition(field.value)
                                    ? [field.value]
                                    : [],
                                } as unknown as EventCondition)
                              : initialEventCondition
                          }
                          parent={parentModel}
                          onChange={({
                            eventModelId,
                            relationshipId,
                            subconditions,
                          }) => {
                            form.setValue(
                              "event_relationship_id",
                              // Note: these _are_ numbers. The type says string but it is incorrect.
                              relationshipId === null
                                ? null
                                : (Number(relationshipId) as unknown as string),
                            );
                            form.setValue(
                              "segment_id",
                              // Note: these _are_ numbers. The type says string but it is incorrect.
                              eventModelId === null
                                ? null
                                : (Number(eventModelId) as unknown as string),
                            );

                            // This is an array, but will be a single element array
                            field.onChange(
                              subconditions?.[0] ?? {
                                type: ConditionType.And,
                                conditions: [],
                              },
                            );
                          }}
                        />
                      </QueryBuilderProvider>
                    )}
                  />
                </Column>
              </Column>
            </FormField>
          </Card>
          <Card>
            <FormField
              label="Maximum hold duration"
              description="Users who don’t meet the criteria after this duration go down the “rule not met” branch"
            >
              <Row gap={4}>
                <Controller
                  control={form.control}
                  name="config.timeout_duration.quantity"
                  render={({ field, fieldState }) => (
                    <Column gap={1} width="40%">
                      <NumberInput
                        isInvalid={Boolean(
                          fieldState.error?.message ||
                            nodeValidationError?.config?.timeout_duration
                              ?.quantity?.message,
                        )}
                        placeholder="Enter the quantity..."
                        min={0}
                        value={field.value}
                        width="100%"
                        onChange={field.onChange}
                      />
                      {(fieldState.error?.message ||
                        nodeValidationError?.config?.timeout_duration?.quantity
                          ?.message) && (
                        <ErrorMessage>
                          {fieldState?.error?.message ||
                            nodeValidationError?.config?.timeout_duration
                              ?.quantity?.message}
                        </ErrorMessage>
                      )}
                    </Column>
                  )}
                />
                <Controller
                  control={form.control}
                  name="config.timeout_duration.unit"
                  render={({ field, fieldState }) => (
                    <Column gap={1}>
                      <Select
                        onChange={field.onChange}
                        options={TIME_OPTIONS}
                        placeholder="Select a unit..."
                        value={field.value}
                      />
                      {fieldState.error?.message && (
                        <ErrorMessage>Unit is required</ErrorMessage>
                      )}
                    </Column>
                  )}
                />
              </Row>
            </FormField>
          </Card>
        </Column>
      </DrawerBody>

      <DrawerFooter>
        <SaveButton
          permission={updateJourneyPermission}
          onClick={() => hasValidationErrors()}
        >
          Update
        </SaveButton>
      </DrawerFooter>
    </Form>
  );
};
