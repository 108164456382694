import { createContext, useContext } from "react";

import { noop } from "ts-essentials";

import { BaseParentModel } from "src/components/audiences/types";
import {
  Condition,
  EventCondition,
  FilterableColumn,
  InlineTraitCondition,
  PropertyCondition,
} from "src/types/visual";
import {
  AudienceReferenceStats,
  RelatedAudiencesQuery,
  RelationshipWithoutFilterableColumnsFragment,
  TraitDefinitionWithoutFilterableColumnsFragment,
} from "src/graphql";

export type SelectedCondition = {
  condition: PropertyCondition | InlineTraitCondition | EventCondition;
  update: (condition: Condition) => void;
} | null;

type QueryBuilderContextType = {
  audienceReferenceStats: AudienceReferenceStats | undefined;
  columns: FilterableColumn[];
  events: RelationshipWithoutFilterableColumnsFragment[];
  relatedAudiences: RelatedAudiencesQuery["segments"];
  // Note: Each condition hydrates the filterable columns when its mounted.
  relationships: RelationshipWithoutFilterableColumnsFragment[];
  traits: (TraitDefinitionWithoutFilterableColumnsFragment & {
    parentModel?: BaseParentModel | null;
  })[];
  /**
   * If selectedEvent is specified, its properties may be selected from the query builder and
   * formatted as property conditions.
   * */
  selectedEvent: { eventModelId: number; relationshipId: number } | null;
  selectedCondition: SelectedCondition;
  selectCondition: (input: SelectedCondition) => void;
  /** Temporary state. Remove when referencing entry event is fully supported. */
  enableReferencingEntryEvent: boolean;
};

const defaultContextValue: QueryBuilderContextType = {
  audienceReferenceStats: undefined,
  columns: [],
  events: [],
  relatedAudiences: [],
  relationships: [],
  traits: [],
  selectedCondition: null,
  selectedEvent: null,
  selectCondition: noop,
  enableReferencingEntryEvent: false,
};

export const QueryBuilderContext =
  createContext<QueryBuilderContextType>(defaultContextValue);

export const useQueryBuilderContext = () => useContext(QueryBuilderContext);
