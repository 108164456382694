import { FC } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  FormField,
  IconButton,
  NumberInput,
  Row,
  Select,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";

import { Card } from "src/components/card";
import { ErrorMessage } from "src/components/explore/visual/error-message";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { PermissionedEditableHeading } from "src/components/permission";
import { TIME_OPTIONS } from "src/constants";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import {
  JourneyNodeDetails,
  NodeDetailFormProps,
} from "src/pages/journeys/types";
import { TimeDelayConfig } from "src/types/journeys";
import { journeyTimeDelaySchemaResolver } from "src/pages/journeys/forms/validation-resolvers";
import { JourneyGraph } from "src/graphql";

export const TimeDelayForm: FC<NodeDetailFormProps<TimeDelayConfig>> = ({
  id, // id techincally lives in data too, redundant to have both :/
  data,
  onClose,
}) => {
  const { useLegacyJourneysTimeMinimum } = useFlags();
  const {
    isEditMode,
    unauthorizedTooltip,
    updateJourneyPermission,
    onUpdateNode,
    nodeErrors,
  } = useGraphContext();

  const parentForm = useFormContext<JourneyGraph>();
  // We can use getValues here because the journey schedule cannot be
  // updated while we have this drawer open.
  const currentJourneySchedule = parentForm.getValues("journey.schedule");
  const nodeValidationError = nodeErrors[id];

  const form = useHightouchForm<JourneyNodeDetails<TimeDelayConfig>>({
    onSubmit: (newTileData) => {
      onUpdateNode(id, newTileData);
      onClose();

      return Promise.resolve();
    },
    success: "Tile was saved",
    values: data,
    resolver: (data, context, options) =>
      journeyTimeDelaySchemaResolver(
        data,
        {
          ...context,
          useLegacyJourneysTimeMinimum,
          currentJourneySchedule,
        },
        options,
      ),
  });

  return (
    <Form form={form}>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <Controller
            name="name"
            control={form.control}
            render={({ field, fieldState }) => (
              <Column>
                <PermissionedEditableHeading
                  isDisabled={!isEditMode}
                  permission={updateJourneyPermission}
                  unauthorizedTooltip={unauthorizedTooltip}
                  value={field.value}
                  onChange={field.onChange}
                />
                {fieldState.error?.message && (
                  <ErrorMessage fontWeight="normal">
                    {fieldState.error.message}
                  </ErrorMessage>
                )}
              </Column>
            )}
          />
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody bg="base.lightBackground">
        <Column minHeight={0} flex={1} gap={6} pb={4}>
          <Card>
            <FormField label="Time duration">
              <Row gap={6}>
                <Controller
                  control={form.control}
                  name="config.delay.quantity"
                  render={({ field, fieldState }) => (
                    <Column justify="center" gap={1} width="40%">
                      <NumberInput
                        isDisabled={!isEditMode}
                        isInvalid={Boolean(
                          fieldState.error?.message ||
                            nodeValidationError?.config?.delay?.quantity,
                        )}
                        placeholder="Enter the quantity..."
                        min={0}
                        value={field.value}
                        width="100%"
                        onChange={field.onChange}
                      />
                      {(fieldState.error?.message ||
                        nodeValidationError?.config?.delay?.quantity
                          ?.message) && (
                        <ErrorMessage>
                          {fieldState.error?.message ||
                            nodeValidationError?.config?.delay?.quantity
                              ?.message}
                        </ErrorMessage>
                      )}
                    </Column>
                  )}
                />
                <Controller
                  control={form.control}
                  name="config.delay.unit"
                  render={({ field, fieldState }) => (
                    <Column gap={1}>
                      <Select
                        isDisabled={!isEditMode}
                        isInvalid={Boolean(fieldState.error?.message)}
                        onChange={field.onChange}
                        options={TIME_OPTIONS}
                        placeholder="Select a unit..."
                        value={field.value}
                      />
                      {fieldState.error?.message && (
                        <ErrorMessage>Unit is required</ErrorMessage>
                      )}
                    </Column>
                  )}
                />
              </Row>
            </FormField>
          </Card>
        </Column>
      </DrawerBody>

      {isEditMode && (
        <DrawerFooter>
          <SaveButton permission={updateJourneyPermission}>Update</SaveButton>
        </DrawerFooter>
      )}
    </Form>
  );
};
