import {
  Box,
  Column,
  DrawerBody,
  DrawerFooter,
  SectionHeading,
  Text,
} from "@hightouchio/ui";

import { useState } from "react";
import { Card } from "src/components/card";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { useUpdateDecisionEngineFlowMessageMutation } from "src/graphql";
import { useOutletContext } from "src/router";
import { FlowMessageContext } from ".";
import { Eligibility } from "./components/eligibility";
import { EligibilityLegacy } from "./components/eligibility-legacy";
import { SendLimit } from "./components/send-limit";
import { Timeline } from "./components/timeline";
import { useFlags } from "launchdarkly-react-client-sdk";

export const Guardrails = () => {
  const { flowMessage, flow } = useOutletContext<FlowMessageContext>();
  const { useAudienceFilteringV2 } = useFlags();
  const [useEligibilityV2, setUseEligibilityV2] = useState(true);

  const updateMutation = useUpdateDecisionEngineFlowMessageMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await updateMutation.mutateAsync({
        flowId: flow.id,
        messageId: flowMessage.message.id,
        input: {
          config: data.flowMessageConfig,
        },
      });
    },
    defaultValues: {
      flowMessageConfig: flowMessage.config,
    },
  });

  return (
    <Form form={form}>
      <DrawerBody bg="base.lightBackground">
        <Column gap={6}>
          <Column gap={4}>
            <Column>
              <SectionHeading>Frequency and timing</SectionHeading>
              <Text>
                Configure the frequency and timeliness of this message
              </Text>
            </Column>
            <Box display="grid" gridTemplateColumns="1fr 1fr" gap={4}>
              <Card>
                <SendLimit />
              </Card>
              <Card>
                <Timeline />
              </Card>
            </Box>
          </Column>

          {useEligibilityV2 ? (
            <Eligibility
              showToggle={!useAudienceFilteringV2}
              setUseEligibilityV1={() => {
                setUseEligibilityV2(false);
              }}
            />
          ) : (
            <EligibilityLegacy
              showToggle={!useAudienceFilteringV2}
              setUseEligibilityV2={() => {
                setUseEligibilityV2(true);
              }}
            />
          )}
        </Column>
      </DrawerBody>
      <DrawerFooter>
        <FormActions />
      </DrawerFooter>
    </Form>
  );
};
