import { FC, useState } from "react";

import { Box, DeleteIcon, IconButton } from "@hightouchio/ui";
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  useReactFlow,
} from "reactflow";

import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import { JourneyNode } from "src/pages/journeys/types";
import { JourneyNodeType } from "src/types/journeys";

export const EdgeWithArrow: FC<EdgeProps> = ({
  sourcePosition,
  sourceX,
  sourceY,
  targetPosition,
  targetX,
  targetY,
  ...props
}) => {
  const [hovering, setHovering] = useState(false);
  const { nodes, unauthorized, isEditMode } = useGraphContext();
  const { setEdges } = useReactFlow();

  const sourceNode: JourneyNode | undefined = nodes.find(
    ({ id }) => props.source === id,
  );

  const hideDelete =
    sourceNode?.type === JourneyNodeType.Segments ||
    sourceNode?.type === JourneyNodeType.Splits ||
    sourceNode?.type === JourneyNodeType.WaitUntilEvent;

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const deleteEdge = () => {
    setEdges((edges) => edges.filter(({ id }) => id !== props.id));
  };

  return (
    <Box
      as="g"
      pointerEvents="all"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <BaseEdge
        path={edgePath}
        {...props}
        style={{
          ...props.style,
          fill: "none",
          strokeWidth: "2px",
          stroke: "var(--chakra-colors-gray-500)",
        }}
      />
      {!unauthorized && isEditMode && !hideDelete && (
        <EdgeLabelRenderer>
          <Box
            position="absolute"
            transform={`translate(-50%, -50%) translate(${labelX}px,${labelY}px)`}
            opacity={hovering ? 1 : 0}
            transition="opacity 100 ease-in-out"
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents="all"
          >
            <IconButton
              aria-label="Delete connection."
              icon={DeleteIcon}
              size="sm"
              variant="danger"
              onClick={deleteEdge}
            />
          </Box>
        </EdgeLabelRenderer>
      )}
    </Box>
  );
};
