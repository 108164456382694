import { useMemo } from "react";

import * as Sentry from "@sentry/react";
import partition from "lodash/partition";
import { isPresent } from "ts-extras";

import { validatePropertyCondition } from "src/components/explore/visual/condition-validation";
import { MetricResult, MetricResultFromCache } from "src/graphql";
import {
  AnalyticsFrequency,
  ConditionType,
  PredefinedMetric,
  PropertyCondition,
  isSyntheticColumn,
} from "src/types/visual";

import { AggregationOptionsWithColumns } from "src/pages/metrics/constants";
import {
  DecisionEngineResources,
  formatGroupsFromDecisionEngineMetricResult,
  formatMetricsForDecisionEngineScope,
} from "src/pages/analytics/decision-engine-utils";
import {
  PLACEHOLDER_AUDIENCE,
  TimeMap,
} from "src/pages/analytics/state/constants";
import {
  GroupByColumn,
  MeasurementScope,
  MeasuringMode,
  MeasuringSelection,
  Metric,
  MetricSelection,
  SelectedAudience,
  SyntheticColumnValuesGetter,
  TimeOptions,
} from "src/pages/analytics/types";
import {
  formatMetricsAndMetricDefinitions,
  getInclusiveTimeWindow,
  isGroupByColumnPlaceholder,
  isValidDateRange,
  isValidMeasuringSelection,
  removeDisabledSplitGroupsFromMetricResult,
  separateCohortsAndCohortDefinitions,
} from "src/pages/analytics/utils";
import { DEFAULT_FILTER } from "src/pages/analytics/constants";
import { useEvaluateAndPollTimeSeriesRequest } from "./use-evaluate-and-poll-time-series";

type UseMetricSeriesArgs = {
  enabled: boolean;
  audiences: SelectedAudience[];
  frequency: AnalyticsFrequency;
  groupByColumns: GroupByColumn[];
  metricSelection: MetricSelection[];
  metrics: Metric[];
  parentModelId: string | number | undefined | null;
  timeValue: TimeOptions;
  customDateRange: Date[];
  // Cumulative flag can determine if the audience aggregation type should be
  // cumulative or not based on the aggregation option. This flag will determine
  // whether to calculate cumulative aggregation in the query rather than client-side
  cumulative: boolean;
  measuringSelection: MeasuringSelection | undefined;
  measuringMode: MeasuringMode | undefined;
  useSampledModels?: boolean;
  syntheticColumnValuesGetter?: SyntheticColumnValuesGetter;
  measuringMetricResources?: Record<string, unknown>;
  errorOnNoData?: boolean;
};

// Type to capture both return types, either data from cache or from a live
// query. The only real difference between the two is that MetricResult contains
// a background job ID, but GraphQL's generated types make it annoying to use
// one type in place of another.
export type MetricResultMaybeFromCache = MetricResult | MetricResultFromCache;

const DISALLOWED_METRICS_FOR_ANALYTICS_BREAKDOWNS = new Set<string>([
  PredefinedMetric.AudienceSize,
]);

export const useMetricSeries = ({
  enabled,
  audiences,
  frequency,
  groupByColumns,
  metrics,
  metricSelection: unfilteredMetricSelection,
  parentModelId,
  timeValue,
  customDateRange,
  measuringSelection,
  measuringMode,
  cumulative,
  useSampledModels,
  syntheticColumnValuesGetter,
  measuringMetricResources,
  errorOnNoData = true,
}: UseMetricSeriesArgs) => {
  const isBreakdownCall = frequency === "all";
  // Do not pass pre-defined metric selections to breakdowns
  const [metricSelection, disallowedBreakdownSelections] = partition(
    unfilteredMetricSelection,
    ({ id }) =>
      !isBreakdownCall || !DISALLOWED_METRICS_FOR_ANALYTICS_BREAKDOWNS.has(id),
  );

  const isMetricSelectionValid = metricSelection
    .flatMap(
      ({
        id,
        aggregationMethod,
        column,
        eventModelId,
        conditions,
      }): Record<string, string | null>[] => {
        // No id if metric is not selected yet
        if (!id) {
          return [{ id: "No metric has been selected yet" }];
        }

        // Check on the aggregation type that requires a column (only need to check
        // against event model metrics)
        if (
          Boolean(eventModelId) &&
          AggregationOptionsWithColumns.includes(aggregationMethod) &&
          !column
        ) {
          return [{ value: "Column required for this aggregation method" }];
        }

        // Will be wrapped in an 'and' condition
        if (
          conditions?.[0]?.type === ConditionType.And ||
          conditions?.[0]?.type === ConditionType.Or
        ) {
          const propertyConditions = conditions?.[0]
            ?.conditions as PropertyCondition[];
          return propertyConditions?.map((propertyCondition) =>
            validatePropertyCondition(propertyCondition),
          );
        }

        if (conditions.length > 0) {
          Sentry.captureException(
            `Conditions are malformed in 'use-metric-series' hook. First item condition type is 'type: ${conditions?.[0]?.type}'`,
          );
        }
        return [];
      },
    )
    .every(
      (validationResult) =>
        !validationResult || !Object.values(validationResult).some(Boolean),
    );

  const isDecisionEngineScope =
    measuringSelection?.scope === MeasurementScope.DecisionEngineFlow;

  const { metricIds, metricDefinitions } = isDecisionEngineScope
    ? formatMetricsForDecisionEngineScope({
        parentModelId: parentModelId?.toString(),
        metricSelections: metricSelection,
        measuringSelection,
        measuringMode,
        cumulative,
        decisionEngineResources:
          measuringMetricResources as DecisionEngineResources,
      })
    : formatMetricsAndMetricDefinitions(
        parentModelId?.toString() ?? "",
        metricSelection,
        metrics,
      );

  // Explicitly filter out the placeholder audiences so we know if we need to
  // use the parent model or not
  const filteredAudiences = audiences.filter(
    (a) => a.id !== PLACEHOLDER_AUDIENCE.id,
  );
  const { cohortIds, cohortDefinitions } = useMemo(
    () =>
      filteredAudiences.length
        ? separateCohortsAndCohortDefinitions(
            // parentModelId _is_ a number, but the type system doesn't know that
            parentModelId,
            audiences,
          )
        : {
            cohortIds: [],
            // If no audiences are selected, we default to reference the parent model
            cohortDefinitions: [
              {
                parentModelId: parentModelId?.toString() ?? "",
                filter: DEFAULT_FILTER,
              },
            ],
          },
    [audiences, parentModelId],
  );

  const { isPolling, timeSeriesData, pollingError } =
    useEvaluateAndPollTimeSeriesRequest({
      timeSeriesRequest: {
        metricIds,
        metricDefinitions,
        cohortIds,
        cohortDefinitions,
        groupByColumns: groupByColumns
          // Filter out groupBy placeholders
          .filter((gb) => !isGroupByColumnPlaceholder(gb))
          .map((column) => {
            if (column.type === "raw") {
              return {
                column: {
                  ...column,
                  modelId: column.modelId.toString(),
                },
              };
            } else if (
              column.type === "related" &&
              column.column.type === "raw"
            ) {
              return {
                column: {
                  ...column,
                  column: {
                    ...column.column,
                    modelId: column.column.modelId.toString(),
                  },
                },
              };
            } else if (isSyntheticColumn(column)) {
              return {
                column,
              };
            } else {
              Sentry.captureException(
                new Error(
                  "Column must be raw or related, no trait column are supported.",
                ),
              );
            }

            return null;
          })
          .filter(isPresent),
        lookbackWindow:
          timeValue !== TimeOptions.Custom ? TimeMap[timeValue] : undefined,
        timeWindow:
          timeValue === TimeOptions.Custom && isValidDateRange(customDateRange)
            ? getInclusiveTimeWindow(customDateRange)
            : undefined,
        frequency,
        useSampledModels,
      },
      requestEnabled:
        enabled &&
        isValidMeasuringSelection(measuringSelection) &&
        isMetricSelectionValid &&
        (metricIds.length > 0 || metricDefinitions.length > 0) &&
        (cohortIds.length > 0 || cohortDefinitions.length > 0) &&
        (Boolean(TimeMap[timeValue]) || isValidDateRange(customDateRange)),
    });

  // Create a stable reference of allData so that there
  // is a stable reference for side effects.
  const allData = useMemo(() => {
    let result = timeSeriesData ?? [];

    result = removeDisabledSplitGroupsFromMetricResult(result, audiences);
    result = formatGroupsFromDecisionEngineMetricResult(
      result,
      syntheticColumnValuesGetter,
    );

    return result;
  }, [timeSeriesData, audiences, syntheticColumnValuesGetter]);

  const errorMessagesByMetricId = useMemo(() => {
    const errorMessageDictionary = {};

    disallowedBreakdownSelections.forEach(({ id }) => {
      errorMessageDictionary[id] =
        "Breakdowns are not supported for this metric";
    });

    return errorMessageDictionary;
  }, [disallowedBreakdownSelections]);

  const errorMessagesByCohortId = useMemo(() => {
    const errorMessagesDictionary = {};

    timeSeriesData?.forEach(({ ids, result }) => {
      const id = ids.cohortDefinition?.parentModelId ?? ids.cohortId;
      if (id) {
        if ("error" in result) {
          // Ignore  group by errors. These are listed as a warning
          // under the groupby columns in the sidebar.
          if (result.error === "Error: unsupported group by column") {
            return;
          }
          errorMessagesDictionary[id] = result.error;
        } else if (
          "data" in result &&
          result.data.length === 0 &&
          errorOnNoData
        ) {
          errorMessagesDictionary[id] = "Audience returned no analytics data";
        }
      }
    });

    return errorMessagesDictionary;
  }, [timeSeriesData]);

  return {
    isPolling,
    data: allData,
    pollingError,
    errors: { ...errorMessagesByMetricId, ...errorMessagesByCohortId },
  };
};
