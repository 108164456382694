import { FC, useEffect, useState } from "react";

import {
  Box,
  Column,
  FormField,
  Row,
  Text,
  TextInput,
  useToast,
  RadioGroup,
  Radio,
  StatusIndicator,
  Dialog,
  Button,
  Heading,
  Badge,
  MenuActionsButton,
  Menu,
  MenuList,
  DeleteIcon,
  RefreshIcon,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Outlet, Link } from "src/router";
import sigmaExtensionImage from "src/components/extensions/assets/sigma-extension.png";
import { Overview } from "src/components/extensions/overview";
import { DetailPage } from "src/components/layout";
import {
  useUpsertSigmaExtensionMutation,
  useDeleteSigmaExtensionMutation,
  useGetSigmaExtensionQuery,
  useTestSigmaExtensionQuery,
  useModelsQuery,
} from "src/graphql";
import { SigmaIcon } from "src/ui/icons";
import { Table } from "src/ui/table";
import { ExtensionTestStatus } from "./common";
import { PermissionedMenuItem } from "src/components/permission";
import { Card } from "src/components/card";
import { DocsLink } from "src/components/docs-link";
import { Form, useHightouchForm } from "src/components/form";
import { FormBar } from "src/components/form/form-bar";
import { Controller } from "react-hook-form";
import { SecretInput } from "src/components/secret-input";

export const Sigma: FC = () => {
  return (
    <DetailPage
      bg="base.lightBackground"
      crumbs={[{ label: "Extensions", link: "/extensions" }]}
      title="Sigma - Extensions"
      header={<Heading size="xl">Sigma</Heading>}
      tabDepth={4}
      tabs={[
        {
          path: "",
          title: "Overview",
        },
        {
          path: "configuration",
          title: "Configuration",
          size: "small",
          contentFullHeight: true,
        },
      ]}
    >
      <Outlet />
    </DetailPage>
  );
};

export const SigmaOverview = () => {
  return (
    <Overview
      description="When you create a Hightouch model backed by a Sigma workbook, Hightouch converts that workbook into SQL and runs it against your data source."
      icon={SigmaIcon}
      image={sigmaExtensionImage}
      subtitle="Import models from Sigma"
      title="Sigma"
    />
  );
};

const schema = yup.object({
  clientId: yup.string().required("Client ID is required").trim(),
  clientSecret: yup.string().required("Client secret is required").trim(),
  cloud: yup.string().required("Cloud provider is required"),
});

export const SigmaConfiguration: FC = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { sigmaStaging } = useFlags();
  const { toast } = useToast();

  const [problemModelIds, setProblemModelIds] = useState<string[]>([]);
  const [testStatus, setTestStatus] = useState<ExtensionTestStatus>("loading");

  const { data: problemModels, isLoading: problemModelsLoading } =
    useModelsQuery(
      { filters: { id: { _in: problemModelIds } }, limit: 100, offset: 0 },
      { enabled: problemModelIds.length != 0, select: (data) => data.segments },
    );

  const { data: credentials } = useGetSigmaExtensionQuery(undefined, {
    select: (data) => data.sigma_credentials?.[0],
    suspense: true,
  });

  const form = useHightouchForm({
    values: {
      clientId: credentials?.clientId ?? "",
      cloud: credentials?.cloud ?? "",
      clientSecret: "",
    },
    resolver: yupResolver(schema),
    onSubmit: async (data) => {
      const resp = await upsert({
        id: credentials?.id.toString(),
        clientId: data.clientId,
        clientSecret: data.clientSecret,
        cloud: data.cloud,
      });
      if (resp.upsertSigmaExtension.__typename === "SigmaExtensionError") {
        const sigmaError = resp.upsertSigmaExtension?.sigmaError ?? "";
        if (sigmaError.includes("client secret")) {
          form.setError("clientSecret", {
            message: "Invalid client secret. Please check your credentials.",
          });
        } else if (sigmaError.includes("token")) {
          form.setError("clientId", {
            message:
              "Possibly invalid client ID. Please check your credentials.",
          });
          form.setError("cloud", {
            message:
              "Possibly invalid cloud provider. Please check your credentials.",
          });
        } else if (sigmaError.includes("cloud")) {
          form.setError("cloud", {
            message: "Invalid cloud. Please check your credentials.",
          });
        }
        throw Error(
          resp.upsertSigmaExtension.sigmaError ??
            resp.upsertSigmaExtension.message ??
            "",
        );
      }
    },
  });

  const { mutateAsync: upsert, isLoading: isCreating } =
    useUpsertSigmaExtensionMutation();

  const {
    data: testResult,
    isLoading: isTesting,
    refetch: testExtension,
    isRefetching: isReTesting,
  } = useTestSigmaExtensionQuery(
    {},
    { select: (data) => data.testSigmaExtension, enabled: !!credentials },
  );

  const { mutateAsync: deleteExtension } = useDeleteSigmaExtensionMutation();

  useEffect(() => {
    if (isTesting || isReTesting) {
      setTestStatus("loading");
      return;
    }
    if (testResult?.__typename === "SigmaExtensionError") {
      setTestStatus("failed");
      toast({
        id: "failed-sigma-test",
        variant: "error",
        title: "Sigma extension test failed.",
        message: testResult?.message ?? "Please check your API credentials.",
      });
    } else {
      setTestStatus("success");
    }
  }, [testResult, isTesting, isReTesting, isDeleting]);

  const deleteSigma = async () => {
    try {
      setIsDeleting(true);
      const r = await deleteExtension({});
      setIsDeleting(false);
      if (r.deleteSigmaExtension.__typename === "SigmaExtension") {
        toast({
          id: "extension-disconnect",
          title: "Sigma disconnected",
          message:
            "This extension has been removed from your Hightouch workspace.",
          variant: "success",
        });
      }
      if (r.deleteSigmaExtension.__typename === "SigmaExtensionError") {
        const serverMessage = r.deleteSigmaExtension.message;
        if (serverMessage?.includes("Sigma credentials still in use")) {
          const modelIds = serverMessage.match(/\d+/g) ?? [];
          setProblemModelIds(modelIds);
        } else {
          throw Error(serverMessage ?? "");
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "extension-disconnect",
        title: "Failed to disconnect",
        message:
          "Sigma could not be disconnected from your Hightouch workspace. Please try again.",
        variant: "error",
      });
    }
  };

  const Header = () => {
    return (
      <>
        <Row gap={4} justify="space-between" align="center">
          <Row gap={2} align="center">
            <Heading>Sigma configuration</Heading>
            {credentials?.id && (
              <Badge>
                <StatusIndicator
                  variant={
                    testStatus === "loading"
                      ? "processing"
                      : testStatus === "failed"
                        ? "error"
                        : "success"
                  }
                >
                  {testStatus === "loading"
                    ? "Testing..."
                    : testStatus === "failed"
                      ? "Connection Failed"
                      : "Connected"}
                </StatusIndicator>
              </Badge>
            )}
          </Row>
          <Row gap={4} align="center">
            <DocsLink href="extensions/sigma" name="Sigma extension" />
            {credentials?.id && (
              <Menu closeOnSelect={false}>
                <MenuActionsButton variant="secondary" />
                <MenuList>
                  <PermissionedMenuItem
                    icon={RefreshIcon}
                    permission={{
                      v2: { resource: "workspace", grant: "can_update" },
                    }}
                    isLoading={isTesting || isReTesting || isDeleting}
                    onClick={() => {
                      setTestStatus("loading");
                      testExtension({});
                    }}
                  >
                    {isTesting || isReTesting
                      ? "Testing..."
                      : "Test connection"}
                  </PermissionedMenuItem>
                  <PermissionedMenuItem
                    icon={DeleteIcon}
                    permission={{
                      v2: { resource: "workspace", grant: "can_update" },
                    }}
                    isDisabled={isTesting}
                    isLoading={isDeleting}
                    variant="danger"
                    onClick={() => deleteSigma()}
                  >
                    Disconnect
                  </PermissionedMenuItem>
                </MenuList>
              </Menu>
            )}
          </Row>
        </Row>
      </>
    );
  };

  return (
    <>
      <Form form={form}>
        <Column flex={1} gap={6}>
          <Header />
          <Card gap={6}>
            <Controller
              control={form.control}
              name="cloud"
              render={({ field, fieldState: { error } }) => (
                <FormField
                  description={
                    <Text>
                      You can find this by visiting Sigma and navigating to{" "}
                      <Text fontWeight="semibold">
                        Administration &gt; Account
                      </Text>
                      .
                    </Text>
                  }
                  error={error?.message}
                  label="Cloud provider"
                >
                  <RadioGroup
                    isDisabled={isCreating}
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  >
                    <Radio label="AWS" value="aws" />
                    <Radio label="Azure" value="azure" />
                    <Radio label="GCP" value="gcp" />
                    {sigmaStaging && <Radio label="Staging" value="staging" />}
                  </RadioGroup>
                </FormField>
              )}
            />
            <Controller
              control={form.control}
              name="clientId"
              render={({ field, fieldState: { error } }) => (
                <FormField
                  description={
                    <Text>
                      You can generate a new API key by visiting Sigma and
                      navigating to{" "}
                      <Text fontWeight="semibold">
                        Administration &gt; APIs & Embed Secrets
                      </Text>
                      , then clicking{" "}
                      <Text fontWeight="semibold">Create New</Text>.
                    </Text>
                  }
                  error={error?.message ?? undefined}
                  label="Client ID"
                >
                  <TextInput
                    isDisabled={isCreating}
                    isInvalid={Boolean(error?.message)}
                    placeholder="Enter your client ID..."
                    value={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                  />
                </FormField>
              )}
            />
            <Controller
              control={form.control}
              name="clientSecret"
              render={({ field, fieldState: { error } }) => (
                <FormField
                  description="This secret is generated at the end of the API key creation flow."
                  error={error?.message ?? undefined}
                  label="Client secret"
                >
                  <SecretInput
                    isDisabled={isCreating}
                    isInvalid={Boolean(error?.message)}
                    placeholder={
                      credentials?.id
                        ? "To update, enter your client secret..."
                        : "Enter your client secret..."
                    }
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                </FormField>
              )}
            />
          </Card>
        </Column>
        <FormBar
          permission={{
            v2: { resource: "workspace", grant: "can_update" },
          }}
        />
      </Form>

      <Dialog
        isOpen={problemModelIds.length > 0}
        variant="info"
        width="xl"
        title="A problem occurred while disconnecting Sigma"
        actions={<Button onClick={() => setProblemModelIds([])}>Close</Button>}
        onClose={() => setProblemModelIds([])}
      >
        <Row mb={8}>
          <Text>
            Disconnecting Sigma from your Hightouch workspace will affect the
            following models. Please update or delete them before deleting your
            Sigma extension.
          </Text>
        </Row>
        <Column>
          <Table
            columns={[
              {
                name: "Model",
                cell: ({ model }) => (
                  <Link href={`/models/${model.id}`}>{model.name}</Link>
                ),
              },
              {
                name: "Model source",
                cell: ({ source }) => (
                  <Link href={`/sources/${source.id}`}>{source.name}</Link>
                ),
              },
              {
                name: "Syncs",
                cell: ({ syncs }) => (
                  <Box>
                    {syncs.ids.sort().map((id, index) => (
                      <Link key={id} href={`/syncs/${id}`}>
                        {id}
                        {index + 1 !== syncs.ids.length ? ", " : ""}
                      </Link>
                    ))}
                  </Box>
                ),
              },
            ]}
            data={problemModels?.map((segment) => {
              return {
                model: {
                  id: segment.id,
                  name: segment.name,
                },
                source: {
                  id: segment?.connection?.id ?? "",
                  name: segment?.connection?.name ?? "",
                },
                syncs: {
                  ids: segment.syncs.map((sync) => sync?.id ?? ""),
                },
              };
            })}
            placeholder={{
              error:
                "Dependent models failed to load, please refresh this page.",
            }}
            loading={problemModelsLoading}
          />
        </Column>
      </Dialog>
    </>
  );
};
