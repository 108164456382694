import { HTImage } from "src/components/image";
import { FC } from "react";

import { Column, Heading, Row, Tooltip, Text } from "@hightouchio/ui";
import { LinkButton } from "src/router";

import { IconProps } from "src/ui/icons/icon";

interface Props {
  title: string;
  subtitle: string;
  description: string;
  image: string;
  icon: FC<IconProps>;
  integrations?: { icon: FC<IconProps>; name: string }[];
}

export const Overview: FC<Readonly<Props>> = ({
  title,
  subtitle,
  description,
  image,
  icon: Icon,
  integrations,
}) => {
  return (
    <>
      <Row justifyContent="space-between">
        <Column flex={1} mr={10} maxWidth="664px" alignItems="flex-start">
          <Icon size={32} sx={{ mb: 5 }} />
          <Heading mb={5} size="xl">
            {title}
          </Heading>
          <Text mb={5} color="text.secondary" size="lg">
            {subtitle}
          </Text>
          <Text mb={5} color="text.secondary">
            {description}
          </Text>
          <LinkButton
            href="configuration"
            variant="primary"
          >{`Configure extension  ->`}</LinkButton>
          {integrations && integrations?.length > 0 && (
            <Column mt={10}>
              <Text
                textTransform="uppercase"
                color="text.secondary"
                mb={4}
                size="sm"
                fontWeight="semibold"
              >
                Integrations
              </Text>
              <Row>
                {integrations.map(({ icon: Icon, name }) => (
                  <Row
                    key={name}
                    sx={{ p: 3, border: "small", borderRadius: 2, mr: 2 }}
                  >
                    <Tooltip message={name}>
                      <Icon size={24} />
                    </Tooltip>
                  </Row>
                ))}
              </Row>
            </Column>
          )}
        </Column>
        <Column flex={1}>
          <HTImage src={image} alt="Extension preview" />
        </Column>
      </Row>
    </>
  );
};
