import {
  JOURNEY_BRANCH_NODES,
  JOURNEY_ENTRY_NODES,
} from "src/pages/journeys/constants";
import { JourneyNode } from "src/pages/journeys/types";

/**
 * Get all selected nodes that are not branch or entry nodes.
 */
export const getSelectedNodes = (nodes: JourneyNode[]): JourneyNode[] => {
  return nodes
    .filter((node) => node.selected)
    .filter(
      (node) =>
        !JOURNEY_BRANCH_NODES.includes(node.data.config.type) &&
        !JOURNEY_ENTRY_NODES.includes(node.data.config.type),
    );
};
