import { VariableDB } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  Box,
  CheckIcon,
  Column,
  CopyIcon,
  DrawerBody,
  NumberInput,
  Row,
  SectionHeading,
  Text,
  Tooltip,
  useClipboard,
} from "@hightouchio/ui";
import { snakeCase } from "lodash";
import { useState } from "react";
import { useOutletContext } from "src/router";
import { Card } from "src/components/card";
import { FlowMessageContext } from ".";
import { getColumnTypeIcon } from "src/components/audiences/column-settings";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";

export const MessageIntegration = () => {
  const { flowMessage, engine } = useOutletContext<FlowMessageContext>();

  const userColumns = engine.segment.columns;

  return (
    <DrawerBody bg="gray.50">
      <Column gap={6} pb={6} maxW="2xl" mx="auto">
        <Column>
          <SectionHeading>
            Integrate with{" "}
            {flowMessage.message.channel.destination.definition.name}
          </SectionHeading>
          <Text>
            Decision Engine recommendations and up-to-date user data can be
            inserted into your message by copying the code snippets below
          </Text>
        </Column>

        <UserSection columns={userColumns} />
        {flowMessage.message.collections?.map((collection) => (
          <CollectionSection
            key={collection.decision_engine_collection.id}
            collection={collection}
          />
        ))}
        <VariableSection variables={flowMessage.message.variables} />
      </Column>
    </DrawerBody>
  );
};

const UserSection = ({
  columns,
}: {
  columns: { name: string; type?: string | null }[];
}) => {
  return (
    <Card>
      <Row align="center" gap={2} mb={4}>
        <SectionHeading>User</SectionHeading>
      </Row>
      <Column gap={2}>
        {columns ? (
          columns
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((column) => (
              <Reference
                key={column.name}
                label={column.name}
                type={column.type}
                property={`user.${column.name}`}
              />
            ))
        ) : (
          <Text>User properties are not known at this time.</Text>
        )}
      </Column>
    </Card>
  );
};

const CollectionSection = ({
  collection,
}: {
  collection: FlowMessageContext["flowMessage"]["message"]["collections"][0];
}) => {
  const [index, setIndex] = useState<number>(1);

  const columns =
    collection.decision_engine_collection.collection.catalog.model.columns;
  return (
    <Column gap={2}>
      <Card>
        <Row align="center" justify="space-between" w="100%" mb={4}>
          <Row align="center" gap={2}>
            <SectionHeading>
              {collection.decision_engine_collection.collection.name}
            </SectionHeading>
          </Row>
          <Row align="center" gap={1}>
            <Text>Item</Text>
            <Row width="40px">
              <NumberInput
                min={1}
                max={collection.item_count}
                width="100%"
                value={index}
                onChange={(value) => setIndex(value ?? 1)}
              />
            </Row>

            <Text>of {collection.item_count}</Text>
          </Row>
        </Row>
        <Column gap={2}>
          {columns?.length ? (
            columns
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((column) => (
                <Reference
                  key={column.name}
                  label={column.name}
                  type={column.type}
                  property={`recommendation.${snakeCase(
                    collection.decision_engine_collection.collection.name,
                  )}.[${index - 1}].${column.name}`}
                />
              ))
          ) : (
            <Text color="text.secondary">
              Collection properties are not known at this time
            </Text>
          )}
        </Column>
      </Card>
    </Column>
  );
};

const VariableSection = ({ variables }: { variables: VariableDB[] }) => {
  if (!variables?.length) {
    return null;
  }
  return (
    <Card>
      <Row align="center" gap={2} mb={4}>
        <SectionHeading>Variables</SectionHeading>
      </Row>
      <Column gap={2}>
        {variables
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ name }) => (
            <Reference
              key={name}
              label={name}
              property={`recommendation.variables.${snakeCase(name)}`}
            />
          ))}
      </Column>
    </Card>
  );
};

const Reference = ({
  property,
  label,
  type,
}: {
  property: string;
  label: string;
  type?: string | null | undefined;
  prefix?: string;
}) => {
  const { flowMessage } = useOutletContext<FlowMessageContext>();
  const channelDefinition = getChannelDefinition(
    flowMessage.message.channel.destination.type,
  );

  const template = channelDefinition.getReferenceString(
    `hightouch.${property.toLowerCase()}`,
  );
  const { onCopy, hasCopied } = useClipboard(template);
  const Icon = type ? getColumnTypeIcon(type) : null;
  return (
    <Row align="center" justify="space-between" w="100%" gap={4}>
      <Row gap={2} align="center">
        {Icon && <Box as={Icon} color="text.secondary" boxSize={5} />}
        <Text>{label}</Text>
      </Row>

      <Tooltip placement="left" message="Copy to clipboard" openSpeed="slow">
        <Row
          onClick={onCopy}
          align="center"
          cursor="pointer"
          py={1}
          px={2}
          bg="gray.100"
          borderRadius="md"
          _hover={{
            bg: "gray.200",
            svg: {
              display: "inline-block",
            },
          }}
          gap={1}
          overflow="hidden"
        >
          {hasCopied ? (
            <CheckIcon />
          ) : (
            <Box as={CopyIcon} display="none" flexShrink={0} />
          )}
          <Box
            fontFamily="mono"
            color="text.secondary"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            dir="rtl"
          >
            {template}
          </Box>
        </Row>
      </Tooltip>
    </Row>
  );
};
