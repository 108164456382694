import { AudienceIcon, CloseIcon, Column, RefreshIcon } from "@hightouchio/ui";

import { EventColumn } from "src/components/explore/filter-popover/constants";
import { IconBox } from "src/components/icon-box";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import { JourneyNodeDetails } from "src/pages/journeys/types";
import { EntryCohortConfig, EntryEventConfig } from "src/types/journeys";

import { DescriptionRow } from "./description-row";
import { EmptyStateDescription } from "./empty-state-description";

export const EntryCohortNodeDescription = ({
  segment_id,
  config,
}: JourneyNodeDetails<EntryCohortConfig>) => {
  const { audienceOptions } = useGraphContext();

  const selectedAudience = audienceOptions.find(
    (audience) => audience.id.toString() === segment_id?.toString(),
  );

  return selectedAudience ? (
    <Column gap={4}>
      <DescriptionRow label="On audience entry">
        {selectedAudience.name}
      </DescriptionRow>
      <DescriptionRow
        label="Re-entry"
        icon={config.max_num_entries === 1 ? CloseIcon : RefreshIcon}
      >
        {config.max_num_entries === 1 ? "Only one entry" : "Unlimited"}
      </DescriptionRow>
    </Column>
  ) : (
    <EmptyStateDescription />
  );
};

export const EntryEventNodeDescription = ({
  event_relationship_id,
  config,
}: JourneyNodeDetails<EntryEventConfig>) => {
  const { parentModel } = useGraphContext();

  const selectedEvent = parentModel?.relationships.find(
    (relationship) =>
      relationship.id.toString() === event_relationship_id?.toString(),
  );

  return selectedEvent ? (
    <Column gap={4}>
      <DescriptionRow label="Event triggered">
        <IconBox {...EventColumn} bg={EventColumn.color} color="white" />{" "}
        {selectedEvent.to_model.name}
      </DescriptionRow>
      <DescriptionRow
        label="Re-entry"
        icon={config.max_num_entries === 1 ? CloseIcon : RefreshIcon}
      >
        {config.max_num_entries === 1 ? "Only one entry" : "Unlimited"}
      </DescriptionRow>
      {config.max_num_entries === -1 && (
        <DescriptionRow label="Simultaneous entries" icon={AudienceIcon}>
          {config.max_simultaneous_entries === -1
            ? "Unlimited"
            : "One at a time"}
        </DescriptionRow>
      )}
    </Column>
  ) : (
    <EmptyStateDescription />
  );
};
