import { FC, ReactNode } from "react";

import {
  Box,
  ChevronRightIcon,
  ChakraAccordion,
  ChakraAccordionButton,
  ChakraAccordionItem,
  ChakraAccordionPanel,
  Badge,
  Text,
  Column,
  Row,
} from "@hightouchio/ui";
import noop from "lodash/noop";
import { Markdown } from "src/ui/markdown";

interface Props {
  children: ReactNode;
  label: string;
  labelFontSize?: "sm" | "md" | "lg";
  description?: string;
  count?: number;
  onChange?: () => void;
}

export const AccordionSection: FC<Readonly<Props>> = ({
  children,
  label,
  labelFontSize = "md",
  count,
  description,
  onChange = noop,
}) => (
  <ChakraAccordion allowToggle onChange={onChange}>
    <ChakraAccordionItem border="none">
      {({ isExpanded }) => {
        return (
          <>
            <ChakraAccordionButton
              cursor="pointer"
              outline="none !important"
              pl={2}
              borderRadius="md"
            >
              <Column>
                <Row align="center" gap={2}>
                  <Box
                    as={ChevronRightIcon}
                    fontSize="18px"
                    transform={isExpanded ? "rotate(90deg)" : ""}
                    transition="transform 150ms ease-in-out"
                  />
                  <Text size={labelFontSize} fontWeight="medium">
                    <Markdown>{label}</Markdown>
                  </Text>
                  {count && <Badge>{count}</Badge>}
                </Row>
              </Column>
              {description && (
                <Text size="sm" color="text.secondary">
                  <Markdown>{description}</Markdown>
                </Text>
              )}
            </ChakraAccordionButton>
            <ChakraAccordionPanel sx={{ px: 0 }}>
              {children}
            </ChakraAccordionPanel>
          </>
        );
      }}
    </ChakraAccordionItem>
  </ChakraAccordion>
);
