import { FC } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  Code,
  Column,
  ConfirmationDialog,
  Heading,
  Paragraph,
  Radio,
  RadioGroup,
  Row,
  Switch,
  Text,
} from "@hightouchio/ui";
import { Link } from "src/router";
import React from "react";
import { DiffModeOverride } from "@hightouch/lib/sync/diff-mode/types";
import { newPylonMessage } from "src/lib/pylon";

interface Props {
  oldPrimaryKey: string | undefined | null;
  newPrimaryKey: string | undefined | null;
  onClose: () => void;
  onConfirm: (options?: {
    diffModeOverride: DiffModeOverride;
    scheduleSyncsNow: boolean;
  }) => Promise<void>;
  useDiffModeDialog?: boolean; // Optional while Audiences still uses the old dialog
}

const Dialog = ({ onConfirm, onClose, oldPrimaryKey, newPrimaryKey }) => (
  <ConfirmationDialog
    variant="danger"
    confirmButtonText="Proceed with change"
    isOpen={Boolean(newPrimaryKey)}
    title="Full resync required"
    onClose={onClose}
    onConfirm={onConfirm}
  >
    <Column gap={2}>
      <Paragraph>
        You are changing this model’s primary key from{" "}
        <Code>{oldPrimaryKey}</Code> to <Code>{newPrimaryKey}</Code>. This
        change requires Hightouch to perform a full resync on any active syncs
        using this model.
      </Paragraph>
      <Paragraph>
        A full resync sends all rows to the destination. Be careful if you have
        any insert-only syncs, as this may result in duplicate records in your
        destination.
      </Paragraph>
      <Paragraph>
        See our{" "}
        <Link href="https://hightouch.com/docs/syncs/overview#full-resync-prerequisites">
          docs
        </Link>{" "}
        for more information, or reach out if you have any questions.
      </Paragraph>
    </Column>
  </ConfirmationDialog>
);

const ChatLink: FC = () => (
  <Box
    display="inline-block"
    onClick={() => {
      newPylonMessage(
        `Hi, can you help me choose a change data capture reset option for my model?`,
      );
    }}
  >
    <Link href="">Chat with us</Link>
  </Box>
);

const DialogV2 = ({
  onClose,
  oldPrimaryKey,
  newPrimaryKey,
  onConfirm,
}: Pick<
  Props,
  "onClose" | "oldPrimaryKey" | "newPrimaryKey" | "onConfirm"
>) => {
  const [scheduleSyncsNow, setScheduleSyncsNow] = React.useState(true);
  const [diffModeOverrideOption, setDiffModeOverrideOption] = React.useState(
    DiffModeOverride.ResetCdc as string,
  );

  return (
    <ChakraModal isOpen={Boolean(newPrimaryKey)} size="2xl" onClose={onClose}>
      <ChakraModalOverlay />
      <ChakraModalContent p={0} my="auto">
        <ChakraModalHeader
          p={6}
          borderBottom="1px solid"
          borderColor="base.border"
        >
          <Heading>CDC reset is required</Heading>
        </ChakraModalHeader>
        <ChakraModalBody
          mt={0}
          p={6}
          borderBottom="1px solid"
          borderColor="base.border"
        >
          <Column gap={2}>
            <Paragraph>
              You are changing this model{`'`}s primary key from{" "}
              <Code>{oldPrimaryKey}</Code> to <Code>{newPrimaryKey}</Code>.
            </Paragraph>
            <Paragraph>
              Hightouch relies on unique primary keys to detect when rows are
              added, changed, or removed.{" "}
              <Text fontWeight="semibold">
                Therefore, altering the primary key requires Hightouch to reset
                its change data capture (CDC) for syncs that depend on this
                model.
              </Text>{" "}
              Please consider the options below and choose whether or not a
              backfill should be performed after the primary key change. (
              <ChatLink /> if you{`'`}re unsure!)
            </Paragraph>
            <RadioGroup
              value={diffModeOverrideOption}
              onChange={setDiffModeOverrideOption}
            >
              <Radio
                label="Reset CDC without backfill"
                description={
                  <>
                    By electing to reset CDC{" "}
                    <Text fontWeight="semibold">without a backfill</Text>, you
                    are instructing Hightouch to ignore the current state of
                    your model and to track only rows that are added, changed,
                    or removed in the future. The next sync run will be used as
                    an opportunity to capture a snapshot of your model, but no
                    data will be sent to the destination. Thereafter, future
                    sync runs will track new changes only. (This option is
                    recommended for insert-only use cases like conversion
                    events, operational alerts, and other situations where a
                    backfill would create undesired duplicate records. Beware
                    that skipping the backfill may cause your destination to
                    drift out of sync with your data model.)
                  </>
                }
                value={DiffModeOverride.ResetCdc}
              />
              <Radio
                label={`Reset CDC with backfill (i.e., "full resync")`}
                description={
                  <>
                    By electing to reset CDC{" "}
                    <Text fontWeight="semibold">with a backfill</Text>, you are
                    instructing Hightouch to perform a full resync of your
                    entire data model. This means that Hightouch will not
                    perform any diffing and will instead process every row as if
                    it{`'`}s the first time the sync has ever run. All rows will
                    be sent to the destination again. (This option may be
                    preferred for syncs to CRMs and other services that are
                    configured to update existing records instead of creating
                    new ones. Do not use this option if a backfill will create
                    duplicates or trigger downstream actions.)
                  </>
                }
                value={DiffModeOverride.FullResync}
              />
            </RadioGroup>
            <Paragraph>
              By default, proceeding with your primary key change will
              immediately trigger a run for every sync that depends on this
              model. It is necessary to reset CDC as quickly as possible after
              the primary key is updated. However, you can opt out of this
              behavior below, in which case the CDC reset will be deferred until
              the next sync run.
            </Paragraph>
            <Row align="center" gap={2}>
              <Switch
                aria-label="Immediately trigger a run for all syncs that depend on this model."
                size="sm"
                isChecked={scheduleSyncsNow}
                onChange={setScheduleSyncsNow}
              />
              <Text fontWeight="medium">
                Immediately trigger a run for all syncs that depend on this
                model
              </Text>
            </Row>
          </Column>
        </ChakraModalBody>
        <ChakraModalFooter
          display="flex"
          justifyContent="space-between"
          mt={0}
          px={6}
          py={4}
        >
          <Button onClick={onClose}>Cancel</Button>
          <ButtonGroup>
            <Button
              variant="primary"
              onClick={() =>
                onConfirm({
                  diffModeOverride: diffModeOverrideOption as DiffModeOverride,
                  scheduleSyncsNow,
                })
              }
            >
              Change primary key and reset CDC
            </Button>
          </ButtonGroup>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};

export const PrimaryKeyDialog: FC<Readonly<Props>> = ({
  onConfirm,
  onClose,
  oldPrimaryKey,
  newPrimaryKey,
  useDiffModeDialog,
}) =>
  useDiffModeDialog ? (
    <DialogV2
      onConfirm={onConfirm}
      onClose={onClose}
      oldPrimaryKey={oldPrimaryKey}
      newPrimaryKey={newPrimaryKey}
    />
  ) : (
    <Dialog
      onConfirm={onConfirm}
      onClose={onClose}
      oldPrimaryKey={oldPrimaryKey}
      newPrimaryKey={newPrimaryKey}
    />
  );
