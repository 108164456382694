import { JsonObject } from "type-fest";
import { QueryFunctionContext } from "react-query";

import { getHightouchRegionConfig } from "./regions";

export const basicApiEndpoint = import.meta.env.VITE_API_BASE_URL as string;

export class HttpError extends Error {
  constructor(
    public readonly code: number,
    message: string,
    public readonly body: Record<string, any> = {},
  ) {
    super(message);
  }
}

export class GraphQlError extends Error {
  extensions: JsonObject;

  constructor(message: string, extensions: JsonObject = {}) {
    super(message);
    this.extensions = extensions;
  }
}

export const isFailedResponse = (
  res: Response,
): res is Response & { ok: false } => {
  return !res.ok;
};

/**
 * Converts a response to Error object.
 */
export const responseToError = async (
  res: Response & { ok: false },
): Promise<HttpError> => {
  const contentType = res.headers.get("content-type");

  if (contentType && contentType.includes("application/json")) {
    const content = await res.json();
    return new HttpError(res.status, content?.message ?? "Error", content);
  }

  return new HttpError(res.status, await res.text());
};

export const fetcher = <TData, TVariables>(
  query: string,
  variables?: TVariables,
  _options?: unknown,
): ((context?: QueryFunctionContext) => Promise<TData>) => {
  return async (context) => {
    const headers = {
      "Content-Type": "application/json",
      "x-should-compress": "true",
    };

    // Get API endpoint
    const apiRegion = window.Hightouch?.apiRegion;
    const regionalApiEndpoint = apiRegion
      ? getHightouchRegionConfig(apiRegion).apiEndpoint
      : basicApiEndpoint;
    const fastApiEndpoint =
      getHightouchRegionConfig("aws-us-east-1").apiEndpoint;
    const workspaceId = window.Hightouch?.workspaceId;

    if (workspaceId) {
      headers["X-Hightouch-Requested-Workspace-Id"] = String(workspaceId);
    }

    if (context?.meta?.isAdmin) {
      headers["X-Hasura-Role"] = "cs_admin";
    }

    // by default, the API calls get routed to us-east-1, but if the requests need to be regional (query previews, etc.),
    // they can be routed to the appropriate region
    let apiEndpoint = regionalApiEndpoint;
    if (context?.meta?.isFastEndpoint) {
      apiEndpoint = fastApiEndpoint;
    }

    const matches = query.trim().match(/(query|mutation) (\w+)/);
    if (matches && matches.length === 3) {
      headers["X-Hightouch-resolver-type"] = matches[1];
      headers["X-Hightouch-resolver-name"] = matches[2];
    }

    const res = await fetch(`${apiEndpoint}/v1/graphql`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
      credentials: "include",
    });

    if (res.status === 401) {
      if (
        !window.location.pathname.includes("login") &&
        !window.location.pathname.includes("signup") &&
        !window.location.pathname.includes("sso")
      ) {
        window.location.href = `${window.location.origin}/login${
          window.location.pathname !== "/"
            ? `?returnTo=${window.location.pathname}`
            : ""
        }`;
      }
    }

    const json = await res.json();

    if (json.errors) {
      const error = json.errors[0];

      if (!error) {
        throw new Error("Error");
      }

      throw new GraphQlError(error.message, error.extensions);
    }
    return json.data;
  };
};
