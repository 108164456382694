import { FC, useMemo, useEffect } from "react";

import { Select } from "@hightouchio/ui";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  SegmentSetCondition,
  segmentSetOperatorOptions,
} from "src/types/visual";

import { FilterPopover } from "../filter-popover";
import { FilterProps } from "./condition";
import { HStack } from "./shared";
import { validateSegmentSetCondition } from "./condition-validation";

export const SegmentSetFilter: FC<
  Readonly<FilterProps<SegmentSetCondition>>
> = ({ condition, onChange, ...props }) => {
  const filterId = useMemo<string>(uuidv4, []);

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const modelError = filterErrors?.modelId;
  const includesError = filterErrors?.includes;

  useEffect(() => {
    setFieldError(filterId, validateSegmentSetCondition(condition));

    return () => {
      removeErrors([filterId]);
    };
  }, [condition.modelId, condition.includes, filterId]);

  return (
    <HStack
      gap={2}
      sx={{ alignItems: "flex-start", button: { fontWeight: "medium" } }}
    >
      <Select
        removePortal
        isInvalid={Boolean(includesError)}
        options={segmentSetOperatorOptions}
        value={condition.includes}
        width="auto"
        onChange={(value) => onChange({ includes: Boolean(value) })}
      />
      <FilterPopover
        {...props}
        condition={condition}
        hasError={Boolean(modelError)}
        onChange={onChange}
      />
    </HStack>
  );
};
