import isObjectLike from "lodash/isObjectLike";

export const isNumericString = (str: string): boolean => /^\d+$/.test(str);

function escapeStringForRegex(text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export function getSearchRegExp(str: string, regex: string) {
  return new RegExp(escapeStringForRegex(str), regex);
}

export const stringify = (value: any): string => {
  return isObjectLike(value) ? JSON.stringify(value) : String(value);
};
