import type { FormkitBoolean } from "./booleans";
import type { AssociationOption } from "./components/associationOption";
import type { Option } from "./components/option";

export function isStateReference(node: any): node is FormkitStateReference {
  return node && typeof node === "object" && node.type === ReferenceType.State;
}

export function isContextReference(node: any): node is FormkitContextReference {
  return (
    node && typeof node === "object" && node.type === ReferenceType.Context
  );
}

export function isGraphQLReference(node: any): node is FormkitGraphQLReference {
  return (
    node && typeof node === "object" && node.type === ReferenceType.GraphQL
  );
}

export function isFormkitReference(node: any): node is FormkitReference {
  return (
    isStateReference(node) ||
    isContextReference(node) ||
    isGraphQLReference(node)
  );
}

export function isHandlerGraphQLVariables(
  node: any,
): node is HandlerGraphQLVariables {
  return (
    typeof node === "object" &&
    "input" in node &&
    typeof node.input === "object" &&
    "handler" in node.input &&
    "context" in node.input
  );
}

export type FormkitReference =
  | FormkitStateReference
  | FormkitContextReference
  | FormkitGraphQLReference<GraphQLVariables>;

export enum ReferenceType {
  State = "state",
  Context = "context",
  GraphQL = "graphQL",
}

export type BaseReference = {
  type: ReferenceType;
};

export type FormkitStateReference = BaseReference & {
  type: ReferenceType.State;
  key: string;
};

export type FormkitContextReference = BaseReference & {
  type: ReferenceType.Context;
  key: string;
};

export type FormkitGraphQLReference<
  TVariables extends GraphQLVariables = GraphQLVariables,
> = BaseReference & {
  type: ReferenceType.GraphQL;
  document: string;
  variables?: TVariables;
};

export type GraphQLVariables = {
  [key: string]: GraphQLVariablesValue;
};

export type GraphQLVariablesValue =
  | GraphQLVariables
  | FormkitReference
  | FormkitBoolean
  | boolean
  | string
  | number
  | undefined;

export type HandlerGraphQLVariables = {
  input: {
    handler: string;
    variables?: GraphQLVariables;
    context: GraphQLVariables;
  };
};

export type FormkitHandler<
  Variables,
  ContextType extends FormkitHandlerContext | FormkitSourceHandlerContext,
  Return,
> = (
  vars: Variables,
  queryCtx: ContextType,
  ctx?: Record<string, any>,
) => Promise<Return>;

//Default context is sync context, but future PR should remove default.
export type FormkitOptionsHandler<
  Variables,
  ContextType extends
    | FormkitHandlerContext
    | FormkitDestinationHandlerContext
    | FormkitSourceHandlerContext = FormkitHandlerContext,
> = FormkitHandler<Variables, ContextType, Option[]>;

export type FormkitAssociationOptionsHandler<
  Variables,
  ContextType extends
    | FormkitHandlerContext
    | FormkitDestinationHandlerContext
    | FormkitSourceHandlerContext = FormkitHandlerContext,
> = FormkitHandler<Variables, ContextType, AssociationOption>;

type CredentialConfig = {
  credentialId: number;
  workspaceId: number;
};

/**
 * Context for sync configuration
 * Should be renamed in a seperate PR.
 */
export type FormkitHandlerContext<
  TDestinationConfiguration = any,
  TSyncConfiguration = any,
> = {
  destination: {
    id: number;
    type: string;
    config: TDestinationConfiguration;
  } & Partial<CredentialConfig>;
  model: {
    id: number;
    name: string;
    columns: { name: string; type: string }[];
  };
  sync?: { id: number; config: TSyncConfiguration };
};

export type FormkitDestinationHandlerContext = {
  /**Type of destination. Example: 'launchdarkly' */
  type: string;
  workspaceId: string;
  /** Destinataion definition */
  definition: Record<string, any>;
};

export type FormkitSourceHandlerContext = {
  /**Type of source. Example: 'athena' */
  type: string;
  workspaceId: string;
  /** Source definition */
  definition: Record<string, any>;
};
