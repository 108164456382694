import { Column, Dialog, Button, Row, Text } from "@hightouchio/ui";

import * as SyncErrors from "src/types/sync-errors";
import { SyncRunStatus } from "src/utils/syncs";

import {
  ErrorOriginInfoModal,
  generateLinkProps,
  Sync,
} from "src/pages/syncs/sync/components/error-origin-info-modal";
import {
  getErrorCode,
  getSpecialCaseErrorInfoContent,
  isSpecialCaseErrorCode,
} from "src/pages/syncs/sync/components/error-utils";
import { Markdown } from "src/ui/markdown";
import { Link } from "src/router";

type SyncRequestErrorModalProps = {
  isOpen: boolean;
  sync?: Sync;
  syncRequestError?: SyncErrors.SyncRequestErrorInfo;
  syncStatus?: SyncRunStatus;
  onClose: () => void;
};

export const SyncRequestErrorModal = ({
  isOpen,
  sync,
  syncRequestError,
  syncStatus,
  onClose,
}: SyncRequestErrorModalProps) => {
  if (!syncRequestError) return null;

  const errorCode = getErrorCode(syncRequestError, syncStatus);

  if (syncRequestError.originInfo && !isSpecialCaseErrorCode(errorCode)) {
    return (
      <ErrorOriginInfoModal
        isOpen={isOpen}
        onClose={onClose}
        originInfo={syncRequestError.originInfo}
        sync={sync}
        syncRequestError={syncRequestError}
      />
    );
  }

  const { title, message, links } = isSpecialCaseErrorCode(errorCode)
    ? getSpecialCaseErrorInfoContent(errorCode, syncRequestError)
    : {
        title: "Run error",
        message:
          syncRequestError?.userFacingMessage ||
          syncRequestError?.message ||
          "We apologize for the inconvenience, but an unknown error has occurred. Please contact our customer support team for assistance.",
      };

  return (
    <Dialog
      isOpen={isOpen}
      variant="info"
      width="lg"
      title={title}
      actions={<Button onClick={onClose}>Close</Button>}
      onClose={onClose}
    >
      <Column gap={2} mt={-4}>
        <Text>
          <Markdown useParagraphMargins>{message}</Markdown>
        </Text>
        {links && (
          <Row gap={2} flexWrap="wrap">
            {links?.map((link, idx) => {
              const props = generateLinkProps(
                link,
                origin,
                sync?.destination,
                sync?.segment,
                sync?.segment?.connection,
                sync,
              );
              return props ? (
                <Row key={idx}>
                  <Link
                    href={props.url ?? "#"}
                    isExternal={link?.type === "external"}
                  >
                    <Button icon={props.icon} size="md" variant="secondary">
                      {props.label}
                    </Button>
                  </Link>
                </Row>
              ) : null;
            })}
          </Row>
        )}
      </Column>
    </Dialog>
  );
};
