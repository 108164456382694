import { FC, ReactNode } from "react";

import { BoxProps, Column } from "@hightouchio/ui";

export const Card: FC<{ children: ReactNode } & BoxProps> = ({
  children,
  ...props
}) => {
  return (
    <Column
      justify="space-between"
      p={4}
      gap={2}
      bg="white"
      border="1px solid"
      borderColor="base.border"
      borderRadius="md"
      {...props}
    >
      {children}
    </Column>
  );
};
