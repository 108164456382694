import {
  FormkitForm,
  FormkitNode,
  FormkitSection,
  getLiquidEngine,
  hasLiquid,
  NodeType,
} from "@hightouch/formkit";
import { get } from "lodash";

import { OverrideConfig } from "src/components/destinations/types";
import { useFormkitContext } from "./components/formkit-context";
import { useQuery } from "./hooks";

export type SpecialQuery = {
  enabled: boolean;
  data: any;
  error: unknown;
  isFetching: boolean;
  refetch?: () => void;
};

export function specialQuery(name: string, options?: any): SpecialQuery {
  const enabled =
    !Array.isArray(options) && options !== null && options !== undefined;
  const { destination, model } = useFormkitContext();
  const { data, error, refetch, isFetching } = useQuery<any>(
    JSON.stringify({ name, variables: options?.variables }),
    {
      enabled: enabled,
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: options?.query,
        variables: options?.variables,
      },
      keepPreviousData: true,
    },
  );
  return {
    enabled,
    data,
    error,
    refetch: enabled ? refetch : undefined,
    isFetching,
  };
}

export function parseHeadings(
  node: FormkitSection,
  context: Record<string, unknown>,
): { heading: string; subheading: string } {
  const engine =
    hasLiquid(node.heading) || hasLiquid(node.subheading)
      ? getLiquidEngine()
      : null;
  const heading =
    node.heading && engine
      ? engine.parseAndRenderSync(node.heading, context)
      : node.heading
        ? node.heading
        : "";
  const subheading =
    node.subheading && engine
      ? engine.parseAndRenderSync(node.subheading, context)
      : node.subheading
        ? node.subheading
        : "";
  return { heading, subheading };
}

/**
 * Show card if this is a sync attached to a template and
 * 1. The field is unlocked or
 * 2. At least one child is unlocked
 */
export function hasVisibleChild(
  node: FormkitNode,
  overrideConfig: OverrideConfig | null | undefined,
) {
  if (!overrideConfig) {
    return true;
  }

  if (node.type === NodeType.Component) {
    return Boolean(get(overrideConfig, node.key)?.overridable);
  }

  return node.children.some((child) => hasVisibleChild(child, overrideConfig));
}

export const shouldWrapInSection = (node: FormkitForm) => {
  return node.children?.some((node) => {
    if (node.type === "layout" && node.layout === "section") {
      return node.size === "small";
    }
    if (node.type === "component") {
      return node.component !== "message";
    }
    return false;
  });
};
