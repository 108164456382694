import { Box, Tooltip } from "@hightouchio/ui";

import { NavItem } from "src/components/layout/nav/nav-item";
import { Icon } from "src/components/layout/nav/icons/home";
import { UseSectionHook } from "./types";
import { useUser } from "src/contexts/user-context";

export const useHomeSection: UseSectionHook = () => {
  const { onboarding } = useUser();

  return {
    title: "Home",
    isEnabled: !onboarding,
    links: [
      {
        href: "/home",
        label: "Home",
      },
    ],
  };
};

export const Home = () => {
  const { isEnabled } = useHomeSection();

  if (!isEnabled) {
    return null;
  }

  return (
    <Box>
      <Box display={{ base: "none", lg: "flex" }}>
        <NavItem href="/home" icon={Icon} label="Home" />
      </Box>
      <Box display={{ base: "block", lg: "none" }}>
        <Tooltip message="Home" placement="right">
          <Box>
            <NavItem href="/home" icon={Icon} label="Home" />
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};
