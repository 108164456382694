import { Box, BoxProps } from "@hightouchio/ui";
import { forwardRef, PropsWithChildren } from "react";

export type CircleProps = PropsWithChildren<
  {
    radius: string | number;
  } & BoxProps
>;

export const Circle = forwardRef<HTMLDivElement, CircleProps>(
  ({ radius, ...props }, ref) => (
    <Box
      ref={ref}
      display="flex"
      borderRadius="50%"
      width={radius}
      height={radius}
      alignItems="center"
      justifyContent="center"
      flexShrink={0}
      {...props}
    />
  ),
);

Circle.displayName = "Circle";
