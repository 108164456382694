import { FC, useEffect, useState } from "react";

import { TraitCondition } from "@hightouch/lib/query/visual/types";
import {
  Button,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  CloseIcon,
  Column,
  Heading,
  IconButton,
  Row,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { isPresent } from "ts-extras";

import { useFormErrorContext } from "src/contexts/form-error-context";
import { FullParentModel } from "src/components/audiences/types";
import { AdditionalColumn, RelatedColumn, TraitColumn } from "src/types/visual";

import {
  TraitEnrichmentForm,
  TraitEnrichmentFormProps,
  traitEnrichmentValidationSchema,
} from "./trait-enrichments/trait-enrichment-form";

type Props = {
  trait: {
    id: string;
    relationship: {
      id: string;
    } | null;
  };
  parentModel?: FullParentModel | null;
  conditions: TraitCondition[];
  onClose: () => void;
  onSubmit: (additionalColumn: AdditionalColumn) => Promise<void>;
};
/*
 * This component should be used if a trait enrichment is being created from the audience builder.
 */
export const CreateTraitEnrichmentModal: FC<Readonly<Props>> = ({
  trait,
  parentModel,
  conditions,
  onClose,
  onSubmit,
}) => {
  const { hasValidationErrors } = useFormErrorContext();

  const { toast } = useToast();

  const [submitting, setSubmitting] = useState(false);

  const form = useForm<TraitEnrichmentFormProps>({
    resolver: yupResolver(
      traitEnrichmentValidationSchema(parentModel?.connection?.type),
    ),
  });
  const { reset } = form;

  useEffect(() => {
    reset({
      template_id: trait.id,
      relationship_id: trait.relationship?.id,
      conditions: conditions,
    });
  }, [reset, trait]);

  const wrappedOnSubmit = async (data: TraitEnrichmentFormProps) => {
    if (!parentModel) return;

    if (hasValidationErrors()) {
      toast({
        id: "audience-trait-form",
        title: "There was a problem saving your trait",
        message: "Please check your configuration and try again",
        variant: "error",
      });
      return;
    }

    setSubmitting(true);

    const traitColumn: TraitColumn = {
      type: "trait",
      traitDefinitionId: data.template_id,
      conditions: data.conditions,
    };
    const relatedColumn: RelatedColumn = {
      type: "related",
      path: [trait.relationship?.id.toString()].filter(isPresent),
      column: traitColumn,
    };

    const additionalColumn: AdditionalColumn = {
      alias: data.name,
      column: relatedColumn,
    };

    await onSubmit(additionalColumn);

    setSubmitting(false);

    onClose();
  };

  const onError = () => {
    toast({
      id: "edit-trait-enrichment-validation",
      variant: "error",
      title: "Trait enrichment failed to be saved",
      message: "There were errors in your submission",
    });
  };

  return (
    <ChakraModal
      closeOnEsc
      closeOnOverlayClick
      isCentered
      scrollBehavior="inside"
      isOpen
      onClose={onClose}
    >
      <ChakraModalOverlay />
      <ChakraModalContent
        maxHeight="90vh"
        maxWidth="700px"
        padding={0}
        my="auto"
      >
        <ChakraModalHeader
          alignItems="center"
          padding={6}
          borderBottom="1px solid"
          borderColor="base.border"
        >
          <Row alignItems="center" justifyContent="space-between" width="100%">
            <Heading>New trait enrichment</Heading>
            <IconButton
              aria-label="Close modal"
              color="text.secondary"
              icon={CloseIcon}
              onClick={onClose}
            />
          </Row>
        </ChakraModalHeader>

        <ChakraModalBody m={0}>
          <Column flex={1} p={6} gap={6} overflowY="auto">
            {parentModel && (
              <FormProvider {...form}>
                <TraitEnrichmentForm parentModel={parentModel} editConditions />
              </FormProvider>
            )}
          </Column>
        </ChakraModalBody>

        <ChakraModalFooter
          borderTop="solid 1px"
          borderTopColor="base.border"
          bg="white"
          m={0}
          px={6}
          py={4}
          gap={3}
          borderRadius="inherit"
        >
          <Button onClick={onClose}>Cancel</Button>
          <Button
            isDisabled={submitting}
            isLoading={submitting}
            variant="primary"
            onClick={form.handleSubmit(wrappedOnSubmit, onError)}
          >
            Save trait
          </Button>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};
