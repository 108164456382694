import {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

import {
  addMissingRelationshipToHierarchy,
  buildRelationshipHierarchy,
  FormkitNode,
  RelationshipHierarchy,
} from "@hightouch/formkit";
import {
  Column,
  Heading,
  Row,
  Spinner,
  Switch,
  Text,
  useToast,
} from "@hightouchio/ui";
import { compare } from "fast-json-patch";
import { useFlags } from "launchdarkly-react-client-sdk";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import omitBy from "lodash/omitBy";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as Yup from "yup";

import { JsonEditor } from "src/components/destinations/json-editor";
import { TestSync } from "src/components/destinations/test-sync";
import { DestinationFormProvider } from "src/contexts/destination-form-context";
import { useDraft } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import { Form } from "src/formkit/components/form";
import {
  DraftChange,
  ExternalSegment,
  FormkitDestination,
  FormkitModel,
  FormkitProvider,
  FormkitSync,
  FormkitSyncTemplateConfig,
} from "src/formkit/components/formkit-context";
import { ProcessFormNode } from "src/formkit/formkit";
import { OVERRIDE_CONFIG_FORM_KEY } from "src/formkit/components/sync-template-lock/constants";
import {
  DestinationDefinition,
  DraftOperation,
  FormkitSyncDefinitionQuery,
  SourceDefinitionFragment as SourceDefinition,
  useDestinationQuery,
  useFormkitSyncDefinitionQuery,
  useFormkitSyncValidationQuery,
  useMigrateConfigQuery,
  useSupportsMatchboostingQuery,
} from "src/graphql";
import { useDraftMerger } from "src/hooks/use-draft-merger";
import * as analytics from "src/lib/analytics";
import { validate as oldValidate } from "src/utils/destinations";

import { PermissionedButton } from "src/components/permission";
import { ResourcePermissionInput } from "src/components/permission/use-resource-permission";
import ActiveCampaignDestination from "./forms/active-campaign";
import CustomDestination from "./forms/custom";
import HeapDestination from "./forms/heap";
import HubspotLegacy from "./forms/hubspot-legacy";
import OneSignalDestination from "./forms/onesignal";
import OrbitForm from "./forms/orbit";
import ReplyioDestination from "./forms/replyio";
import RudderStackDestination from "./forms/rudderstack";
import SalesloftDestination from "./forms/salesloft";
import SfmcFileDropDestination from "./forms/sfmc-file-drop";
import TotangoDestination from "./forms/totango";
import VeroDestination from "./forms/vero";
import { OverrideConfig } from "./types";
import {
  cleanConfig,
  cleanOverrides,
  getUnlockedFields,
  hasUnlockedFields,
} from "./utils";
import { getFieldContext, isNodeVisible } from "./is-node-visible";
import isEmpty from "lodash/isEmpty";
import { DocsLink } from "src/components/docs-link";
import { ActionBar } from "src/components/action-bar";

type FormkitDefinition =
  FormkitSyncDefinitionQuery["formkitSyncDefinition"]["form"];

interface DestinationFormContext {
  sync: FormkitSync | undefined;
  syncConfig: Record<string, unknown> | undefined;
  slug: string | undefined | null;
  model: FormkitModel | undefined;
  isModelDraft: boolean;
  draftChanges: DraftChange[];
  destination: FormkitDestination | undefined;
  // TODO(XXX): get a better type for the destination config
  destinationConfig: any;
  destinationDefinition: DestinationDefinition;
  sourceDefinition: SourceDefinition | undefined;
  externalSegment: ExternalSegment | undefined;
  /**Sync template override specific context */
  isUsingSyncTemplate: boolean;
  supportsOverrides: boolean;
  syncTemplate: FormkitSyncTemplateConfig | undefined;
  hasOverrideConfig: boolean;
  shouldShowFullConfiguration: boolean;
  relatedSyncsWithOverrides?: Record<string, number>;
  isSyncForm: boolean; // TEMPORARY. Remove when sync and sync template forms are split
}

/**
 * XXX: Destinations moved to formkit must be removed from here.
 */
const DESTINATION_FORMS = {
  activeCampaign: ActiveCampaignDestination,
  rudderstack: RudderStackDestination,
  onesignal: OneSignalDestination,
  hubspotLegacy: HubspotLegacy,
  totango: TotangoDestination,
  sfmcFileDrop: SfmcFileDropDestination,
  vero: VeroDestination,
  salesloft: SalesloftDestination,
  orbit: OrbitForm,
  custom: CustomDestination,
  replyio: ReplyioDestination,
  heap: HeapDestination,
};

type Props = {
  model?: FormkitModel;
  destination?: FormkitDestination;
  sync?: FormkitSync;
  syncTemplate?: FormkitSyncTemplateConfig;
  syncConfig?: Record<string, unknown>;
  destinationDefinition: DestinationDefinition;
  sourceDefinition: SourceDefinition | undefined;
  /**
   * The overrides saved to syncs created from this sync template
   * The key is the sync id and the value is the number of syncs that have this override
   */
  relatedSyncsWithOverrides?: Record<string, number>;
  /**
   * Whether this is a sync form or a sync template form
   * TODO(samuel): This form should be refactored to be two separate forms
   * and this prop should be removed.
   */
  isSyncForm?: boolean;
  externalSegment?: ExternalSegment;
  slug: string | undefined;
  onSubmit: (config: any) => Promise<void>;
  hideSave?: boolean;
  hideHeader?: boolean;
  disableRowTesting?: boolean;
  permission:
    | ResourcePermissionInput<"model", "sync_template">
    | ResourcePermissionInput<"sync", "sync">;
  testPermission:
    | ResourcePermissionInput<"model", "sync_template">
    | ResourcePermissionInput<"sync", "sync">;
};

export interface FormRef {
  submit: () => Promise<void>;
}

const getRelationshipHierarchy = ({
  supportsOverrides,
  formkitDefinition,
  isUsingSyncTemplate,
}: {
  supportsOverrides: boolean;
  formkitDefinition: FormkitDefinition;
  isUsingSyncTemplate: boolean;
}): RelationshipHierarchy | null => {
  if (!supportsOverrides || !formkitDefinition || !isUsingSyncTemplate) {
    return null;
  }

  return buildRelationshipHierarchy({ node: formkitDefinition });
};

export const DestinationForm = forwardRef<FormRef, Props>(
  function DestinationForm(
    {
      sync,
      syncTemplate,
      syncConfig,
      relatedSyncsWithOverrides,
      model,
      destination,
      destinationDefinition,
      sourceDefinition,
      externalSegment,
      slug,
      onSubmit,
      hideSave,
      permission,
      testPermission,
      disableRowTesting = false,
      isSyncForm = true,
      hideHeader = false,
    },
    ref,
  ) {
    const { enableSyncTemplateOverrides } = useFlags();
    const { editingDraft, draft } = useDraft();
    const deprecatedForm = slug ? DESTINATION_FORMS[slug] : undefined;
    const [shouldShowFullConfiguration, setShowFullConfiguration] =
      useState(!isSyncForm);

    const {
      error: formkitDefinitionError,
      data,
      isLoading: formkitDefinitionLoading,
    } = useFormkitSyncDefinitionQuery(
      { type: destination?.type ?? "" },
      {
        enabled: Boolean(destination?.type) && !deprecatedForm,
      },
    );

    const { data: destinationConfig, isLoading: destinationConfigLoading } =
      useDestinationQuery(
        { id: String(destination?.id) },
        {
          enabled: Boolean(destination?.id),
          select: (data) => data.destinations_by_pk?.config,
        },
      );

    const { draft: draftModel, mergeResourceWithDraft } = useDraftMerger({
      resourceId: model?.id ?? "",
      resourceType: "model",
    });

    const { data: migrateData } = useMigrateConfigQuery(
      { draftId: draft?.id ?? "" },
      {
        enabled:
          draft?.operation !== DraftOperation.Create && Boolean(draft?.id),
      },
    );

    const draftConfig = migrateData?.migrateConfig;

    const formkitDefinition = data?.formkitSyncDefinition.form;
    const supportsOverrides = Boolean(
      enableSyncTemplateOverrides &&
        data?.formkitSyncDefinition.supportsOverrides,
    );

    const context: DestinationFormContext = useMemo(
      () => ({
        sync,
        isSyncForm,
        syncConfig: syncConfig || sync?.config,
        // provide the template so that the sync may know the original config values
        // the syncConfig will have the merged values
        syncTemplate: syncTemplate ?? sync?.sync_template ?? undefined,
        relatedSyncsWithOverrides,
        shouldShowFullConfiguration,
        isUsingSyncTemplate: Boolean(sync?.sync_template || syncTemplate),
        supportsOverrides,
        hasOverrideConfig: !isEmpty(
          sync?.sync_template?.override_config ?? syncTemplate?.override_config,
        ),
        slug,
        model:
          model && draftModel
            ? (mergeResourceWithDraft(model) as FormkitModel)
            : model,
        destination,
        destinationConfig,
        destinationDefinition,
        sourceDefinition,
        externalSegment,
        isModelDraft: Boolean(draftModel),
        draftChanges:
          editingDraft &&
          draft?.operation === DraftOperation.Update &&
          draftConfig
            ? compare(syncConfig || sync?.config, draftConfig)
                .map((o) => {
                  if (o.op === "add" || o.op === "replace") {
                    return {
                      key: o.path.split("/").filter(Boolean).join("."),
                      op: o.op,
                    };
                  } else return null;
                })
                .filter<DraftChange>((v): v is DraftChange => Boolean(v))
            : [],
      }),
      [
        sync,
        shouldShowFullConfiguration,
        slug,
        model,
        draftModel,
        destination,
        destinationConfig,
        destinationDefinition,
        sourceDefinition,
        externalSegment,
        editingDraft,
        draft,
        draftConfig,
        syncTemplate,
        syncConfig,
        isSyncForm,
        supportsOverrides,
      ],
    );

    if (!destination || !model || !sourceDefinition) {
      return (
        <Text>
          You do not have access to the underlying model, source or destination
          needed to edit this configuration.
        </Text>
      );
    }

    // XXX: added `!deprecatedForm` here so we dont have to re-render when loading.
    // Re-rendering when loading is causing the deprecated form to loose state.
    if (
      (!deprecatedForm &&
        !formkitDefinitionError &&
        formkitDefinitionLoading) ||
      destinationConfigLoading
    ) {
      return <Spinner size="lg" m="auto" />;
    }

    if (
      context.isSyncForm &&
      context.isUsingSyncTemplate &&
      !supportsOverrides
    ) {
      return null;
    }

    const formSyncConfig =
      editingDraft && draftConfig ? draftConfig : syncConfig || sync?.config;

    return (
      <Column gap={6}>
        {context.supportsOverrides &&
          context.isSyncForm &&
          context.hasOverrideConfig && (
            <Row justify="space-between" mb={-2}>
              <Text fontWeight="medium" size="lg">
                Configuration overrides
              </Text>
              <Row
                sx={{
                  label: { display: "flex", alignItems: "center", gap: 2 },
                }}
              >
                <Text as="label" fontWeight="medium" size="sm">
                  <Switch
                    aria-label="Show full configuration."
                    size="sm"
                    isChecked={shouldShowFullConfiguration}
                    onChange={(value) => setShowFullConfiguration(value)}
                  />
                  Show full configuration
                </Text>
              </Row>
            </Row>
          )}
        <DestForm
          ref={ref}
          context={context}
          deprecatedForm={deprecatedForm}
          formkitDefinition={formkitDefinition}
          hideSave={hideSave}
          hideHeader={hideHeader}
          syncConfig={formSyncConfig}
          onSubmit={onSubmit}
          permission={permission}
          testPermission={testPermission}
          disableRowTesting={disableRowTesting}
        />
      </Column>
    );
  },
);

const NullComponent = () => null;

interface DestFormProps {
  disableRowTesting?: boolean;
  syncConfig: Record<string, unknown>;
  context: DestinationFormContext;
  formkitDefinition: FormkitDefinition;
  deprecatedForm: { form: FC; validation: Yup.ObjectSchema };
  onSubmit: (config: any) => Promise<void>;
  hideSave: boolean | undefined;
  hideHeader: boolean;
  permission:
    | ResourcePermissionInput<"model", "sync_template">
    | ResourcePermissionInput<"sync", "sync">;
  testPermission:
    | ResourcePermissionInput<"model", "sync_template">
    | ResourcePermissionInput<"sync", "sync">;
}

const DestForm = forwardRef<FormRef, DestFormProps>(function DestForm(
  {
    syncConfig,
    disableRowTesting = false,
    context,
    formkitDefinition,
    deprecatedForm,
    onSubmit,
    hideSave,
    hideHeader,
    permission,
    testPermission,
  },
  ref,
) {
  const client = useQueryClient();
  const { workspace } = useUser();
  const { toast } = useToast();

  const [customValidation, setCustomValidation] = useState<{
    validate: (config: any) => Promise<{ yupError?: any; otherError?: any }>;
  }>();
  const [editingJson, setEditingJson] = useState(false);
  const [initialConfig, setConfig] = useState<any>(syncConfig || {});
  const [saving, setSaving] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>();
  const [isInitialized, setIsInitialized] = useState(false);

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }));

  const form = useForm();

  const DeprecatedForm = deprecatedForm?.form ?? NullComponent;

  const formkitValidate = async (config) => {
    const response = await client.fetchQuery({
      queryFn: useFormkitSyncValidationQuery.fetcher({
        type: context.destination?.type ?? "",
        config,
      }),
      queryKey: useFormkitSyncValidationQuery.getKey(config),
    });

    return response.formkitSyncValidation;
  };

  const overrideConfig: OverrideConfig | null =
    context.sync?.sync_template?.override_config ??
    context.syncTemplate?.override_config;

  const [relationshipHierarchy, setRelationshipHierarchy] =
    useState<RelationshipHierarchy | null>(() =>
      getRelationshipHierarchy({
        supportsOverrides: context.supportsOverrides,
        formkitDefinition,
        isUsingSyncTemplate: context.isUsingSyncTemplate,
      }),
    );

  const addMissingRelationships = (parent: string, keys: string[]): void => {
    setRelationshipHierarchy((previousHierarchy) => {
      if (!previousHierarchy) {
        return null;
      }

      return addMissingRelationshipToHierarchy({
        hierarchy: previousHierarchy,
        parent,
        keys,
      });
    });
  };

  // Keep relationship hierarchy in sync when the formkit definition is fetched.
  // Once it's set, don't replace it because new fields may have been added.
  useEffect(() => {
    if (formkitDefinition && !relationshipHierarchy) {
      setRelationshipHierarchy(
        getRelationshipHierarchy({
          supportsOverrides: context.supportsOverrides,
          formkitDefinition,
          isUsingSyncTemplate: context.isUsingSyncTemplate,
        }),
      );
    }
  }, [
    context.supportsOverrides,
    context.isUsingSyncTemplate,
    formkitDefinition,
    relationshipHierarchy,
  ]);

  const formkit = useMemo(() => {
    if (!formkitDefinition) {
      return null;
    }

    const canPartialFormBeShown = Boolean(
      context.isSyncForm && overrideConfig && hasUnlockedFields(overrideConfig),
    );

    const syncTemplateOverrideContext = context.supportsOverrides
      ? {
          showLocks:
            !context.isSyncForm && Boolean(context.isUsingSyncTemplate),
          showOverrides: canPartialFormBeShown,
          overrideConfig,
          isNodeVisible: (node: FormkitNode) =>
            context.shouldShowFullConfiguration ||
            isNodeVisible({
              node,
              relationshipHierarchy,
              overrideConfig,
            }),
        }
      : {};

    return (
      <ProcessFormNode
        node={formkitDefinition}
        context={{
          ...context,
          ...syncTemplateOverrideContext,
        }}
      />
    );
  }, [formkitDefinition, context, relationshipHierarchy, overrideConfig]);

  // XXX: Existing state should be set via reset without setting the default values
  // This is because default values should be set by the components only
  useEffect(() => {
    if (syncConfig && Object.keys(syncConfig).length) {
      const newConfig = { ...syncConfig };

      // Add locking for sync templates
      if (context.supportsOverrides) {
        if (!context.isSyncForm) {
          newConfig[OVERRIDE_CONFIG_FORM_KEY] =
            context.syncTemplate?.override_config ?? null;
        }
      }

      form.reset(newConfig, {
        keepDefaultValues: true,
      });

      // TODO(samuel): Deprecated forms won't handle sync template overrides
      setConfig(syncConfig || {});
    }
    setIsInitialized(true);
  }, [
    context.supportsOverrides,
    context.isSyncForm,
    context.syncTemplate?.override_config,
    syncConfig,
  ]);

  const config = formkitDefinition
    ? form.getValues()
    : deprecatedForm?.validation?.cast(initialConfig, { assert: false });

  const isConfigChanged = !isEqual(
    // Overrides will have been merged into the config on the backend.
    // The graphql query replaces config with `transformed_config` here
    syncConfig,
    // XXX: React hook form doesn't handle undefined values. We need to be able to rely on `null` to clear the value.
    omitBy(config, (v) => v === undefined),
  );

  const validate = async (config) => {
    if (formkitDefinition) {
      const cleanedConfig = cleanConfig(config);
      const errors = await formkitValidate(cleanedConfig);
      if (typeof errors === "object" && Object.keys(errors).length) {
        return errors;
      }
    } else {
      return oldValidate(config, deprecatedForm.validation, customValidation);
    }
  };

  const handleSubmit = async () => {
    setSaving(true);
    if (formkitDefinition) {
      // XXX: temporary fix to clear form errors before submit handler is validated so that the handleSubmit can
      // re-validate the form when theres an error. The proper fix should be to refactor the code to only use
      // "setErrors" only and not a combination of "setErrors" and "useForm.setError" within the formkit code
      form.clearErrors();
      await form.handleSubmit(async ({ __overrideConfig, ...data }) => {
        const cleanedConfig = cleanConfig(data);
        const errors = await formkitValidate(cleanedConfig);
        if (typeof errors === "object" && Object.keys(errors).length) {
          Object.entries(errors).forEach(([key, message]) => {
            form.setError(key, { message: String(message) });
          });
          analytics.track("Destination Config Validation Error");

          toast({
            id: "save-sync-config",
            title: "Couldn't save the sync configuration",
            variant: "error",
          });

          setErrors(errors);
        } else {
          const values = { ...cleanedConfig };

          if (context.supportsOverrides && context.isUsingSyncTemplate) {
            // If it's a sync attached to a sync template
            if (context.isSyncForm) {
              // Get the fields that are unlocked
              // The override config stores all the fields that are unlocked
              // Need to get the keys of the fields that are unlocked and their children!
              const unlockedFields = getUnlockedFields(
                overrideConfig,
                relationshipHierarchy,
              );
              const newValues = {};

              // Pull the updated config values from the config object
              // if they have changed from the initial config
              // Only send updated values, not the entire config
              unlockedFields.forEach((key) => {
                const defaultValue = get(context.syncTemplate?.config, key);
                const newValue = get(cleanedConfig, key);

                if (!isEqual(newValue, defaultValue)) {
                  newValues[key] = newValue;
                }
              });

              values["sync_template_overrides"] = newValues;
            }
            // Editing a sync template
            else {
              values["overrideConfig"] = cleanOverrides(
                __overrideConfig as OverrideConfig | null,
                Object.keys(cleanedConfig),
              );
            }
          }

          await onSubmit(values);

          setErrors(null);
        }
      })();
    } else {
      const errors = await oldValidate(
        config,
        deprecatedForm.validation,
        customValidation,
      );
      if (errors) {
        analytics.track("Destination Config Validation Error");

        toast({
          id: "save-sync-config",
          title: "Couldn't save the sync configuration",
          variant: "error",
        });

        setErrors(errors);
      } else {
        await onSubmit(config);
        setErrors(null);
      }
    }
    setSaving(false);
  };

  useEffect(() => {
    if (editingJson) {
      analytics.track("Sync JSON Editor Opened", {
        sync_id: context.sync?.id,
        sync_template_id: context.sync?.sync_template_id,
        sync_slug: context.slug,
        model_name: context.model?.name,
        query_type: context.model?.query_type,
        destination_type: context.destination?.type,
        source_type: context.sourceDefinition?.type,
      });
    }
  }, [editingJson]);

  useEffect(() => {
    if (errors) {
      analytics.track("Sync Destination Configuration Error", {
        sync_id: context.sync?.id,
        sync_slug: context.slug,
        model_name: context.model?.name,
        query_type: context.model?.query_type,
        destination_type: context.destination?.type,
        source_type: context.sourceDefinition?.type,
        errors,
      });
    }
  }, [errors]);

  const { data: supportsMatchboosting } = useSupportsMatchboostingQuery(
    {
      config,
      destinationType: context.destination?.type ?? "",
    },
    {
      enabled: !!context.destination?.slug,
      keepPreviousData: true,
    },
  );

  if (!isInitialized) {
    return null;
  }

  return (
    <FormkitProvider
      {...context}
      onAddMissingRelationships={addMissingRelationships}
      isFieldDisabled={
        context.supportsOverrides &&
        context.isSyncForm &&
        context.hasOverrideConfig
          ? (key: string) => {
              const { isUnlocked, isAnyAncestorUnlocked } = getFieldContext({
                key,
                overrideConfig,
                relationshipHierarchy,
              });

              // The field is disabled if it's not locked and none of its ancestors are unlocked
              return !isUnlocked && !isAnyAncestorUnlocked;
            }
          : undefined
      }
      showRestoreDefaultUI={
        context.supportsOverrides &&
        context.hasOverrideConfig &&
        context.isSyncForm &&
        hasUnlockedFields(overrideConfig)
      }
      showLockUI={
        context.supportsOverrides &&
        context.isUsingSyncTemplate &&
        !context.isSyncForm
      }
      relationshipHierarchy={
        context.supportsOverrides ? relationshipHierarchy : undefined
      }
      relatedSyncsWithOverrides={context.relatedSyncsWithOverrides}
      validate={validate}
      supportsMatchboosting={supportsMatchboosting?.supportsMatchboosting}
    >
      <DestinationFormProvider
        config={config}
        errors={errors}
        setConfig={setConfig}
        setCustomValidation={setCustomValidation}
        setErrors={setErrors}
      >
        <FormProvider {...form}>
          {!hideHeader && (
            <Row align="center" justify="space-between">
              <Heading>Sync configuration</Heading>
              <Row align="center" gap={4}>
                <DocsLink
                  name={context.destinationDefinition.name}
                  href={context.destinationDefinition.docs}
                />
                {!disableRowTesting && (
                  <TestSync formkit={formkit} permission={testPermission} />
                )}
                {!(context.isSyncForm && context.sync?.sync_template) && (
                  <PermissionedButton
                    permission={permission}
                    onClick={() => {
                      setEditingJson(true);
                    }}
                  >
                    Edit as JSON
                  </PermissionedButton>
                )}
              </Row>
            </Row>
          )}
          <Column flex={1} minWidth={0}>
            <Form>{formkitDefinition ? formkit : <DeprecatedForm />}</Form>
            <form
              hidden
              id="destination-form"
              onSubmit={(event) => {
                event.preventDefault();
                handleSubmit();
              }}
            ></form>
          </Column>
          {editingJson && (
            <JsonEditor
              keysToOmit={[OVERRIDE_CONFIG_FORM_KEY]}
              formkit={Boolean(formkitDefinition)}
              onClose={() => {
                setEditingJson(false);
              }}
            />
          )}
          {!hideSave && (
            <ActionBar>
              <PermissionedButton
                size="lg"
                variant="primary"
                permission={permission}
                isDisabled={!isConfigChanged}
                isLoading={saving}
                onClick={handleSubmit}
              >
                {workspace?.approvals_required ? "Save draft" : "Save changes"}
              </PermissionedButton>
            </ActionBar>
          )}
        </FormProvider>
      </DestinationFormProvider>
    </FormkitProvider>
  );
});
