import { FC } from "react";
import { Controller } from "react-hook-form";

import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  Box,
  ButtonGroup,
  Column,
  Combobox,
  FormField,
  NumberInput,
  Row,
  SectionHeading,
  Text,
} from "@hightouchio/ui";
import { Card } from "src/components/card";
import {
  DiscardButton,
  Form,
  SaveButton,
  useHightouchForm,
} from "src/components/form";
import {
  useEligibleTargetAudiencesQuery,
  useUpdateDecisionEngineFlowMutation,
} from "src/graphql";
import { OutletContext } from "src/pages/decision-engines";
import { useOutletContext } from "src/router";
import { Flow } from "..";
import { AudienceLink } from "./audience-link";

export const Targeting: FC<Readonly<{ flow: Flow }>> = ({ flow }) => {
  const { engine } = useOutletContext<OutletContext>();

  const modelsQuery = useEligibleTargetAudiencesQuery(
    {
      parentModelId: engine.segment.id,
    },
    {
      select: (data) => data.segments,
    },
  );

  const update = useUpdateDecisionEngineFlowMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await update.mutateAsync({
        id: flow?.id,
        input: {
          audience_id: data.audience_id,
          config: data.config,
        },
      });
    },
    values: {
      audience_id: flow.audience?.id,
      config: flow.config,
    },
  });

  const flowIsRunning =
    flow.status === DecisionEngineStatus.READY && flow.enabled;

  const flowIsInitializing = flow.status === DecisionEngineStatus.TRAINING;

  return (
    <Form form={form}>
      <Card gap={4} p={6} width="100%">
        <Row alignItems="center" justify="space-between" gap={4}>
          <Column>
            <SectionHeading>Targeting</SectionHeading>
            <Text>
              The target audience for this agent. Optionally hold out a
              percentage to measure incrementality.
            </Text>
          </Column>
        </Row>
        {flowIsInitializing ? (
          <Row gap={6}>
            {flow.audience && (
              <Column gap={2} width="2xs">
                <Text fontWeight="medium">Target audience</Text>
                <AudienceLink audience={flow.audience} />
              </Column>
            )}
            <Column gap={2} width="2xs">
              <Text fontWeight="medium">Holdout %</Text>
              <Text>{flow.config.holdout * 100}%</Text>
            </Column>
          </Row>
        ) : (
          <Row gap={4} align="flex-end">
            <Controller
              name="audience_id"
              render={({ field }) => (
                <Box as={FormField} label="Target audience" width="2xs">
                  <Combobox
                    {...field}
                    width="100%"
                    isLoading={modelsQuery.isLoading}
                    optionValue={(option) => option.id}
                    optionLabel={(option) => option.name}
                    options={modelsQuery.data ?? []}
                  />
                </Box>
              )}
            />
            <Controller
              name="config.holdout"
              render={({ field }) => (
                <Box as={FormField} label="Holdout" width="100px">
                  <NumberInput
                    width="100%"
                    {...field}
                    placeholder="Set a holdout..."
                    formatOptions={{ style: "percent" }}
                    min={0}
                    max={1}
                  />
                </Box>
              )}
            />
            <ButtonGroup>
              <SaveButton
                size="md"
                confirmation={
                  // Don't need confirmation if the agent is not live
                  flowIsRunning
                    ? {
                        title: "Confirm new target audience",
                        message:
                          "Are you sure you want to change your target audience? The previous target audience will immediately stop receiving messages from this agent and the new target audience will begin having messages scheduled within 24 hours.",
                      }
                    : undefined
                }
              >
                Save
              </SaveButton>
              <DiscardButton size="md">Discard</DiscardButton>
            </ButtonGroup>
          </Row>
        )}
      </Card>
    </Form>
  );
};
