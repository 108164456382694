import { get } from "lodash";
import { UseFormWatch } from "react-hook-form";

import {
  FormkitNode,
  getLiquidEngine,
  hasLiquid,
  NodeType,
  processValuesWithLiquid,
  ReferenceType,
  SKIP_LIQUID_PARSE_FIELDS,
} from "@hightouch/formkit";
import { FormkitContextType } from "./components/formkit-context";

export const getNestedKeys = (node: FormkitNode) => {
  if (node.type === NodeType.Component) {
    return node.key;
  } else {
    return node.children.map(getNestedKeys);
  }
};

export const processReferences = (
  value: any,
  context: FormkitContextType & Record<string, unknown>,
  watch: UseFormWatch<Record<string, unknown>>,
  skipParse?: boolean,
): unknown => {
  if (Array.isArray(value)) {
    if (!skipParse && hasLiquid(JSON.stringify(value))) {
      const engine = getLiquidEngine();
      return value.map((item) => {
        if (typeof item === "string") {
          return engine.parseAndRenderSync(item, context);
        } else if (typeof item === "object") {
          return processValuesWithLiquid(item, context, engine);
        }
        return item;
      });
    }
    return value;
  } else if (
    typeof value === "object" &&
    value !== undefined &&
    value !== null
  ) {
    if (value.type) {
      if (value.type === ReferenceType.GraphQL) {
        // Always skip liquid parsing for graphql references -- parsing gql or its variables could cause unwanted errors
        const variables = processReferences(
          value.variables,
          context,
          watch,
          true,
        );
        return { query: value.document, variables };
      } else if (value.type === ReferenceType.Context) {
        return get(context, value.key);
      } else if (value.type === ReferenceType.State) {
        return watch(value.key);
      }
    }

    let newObject = {};
    let engine;
    for (const [k, v] of Object.entries(value)) {
      let processedValue = processReferences(v, context, watch, skipParse);
      if (!SKIP_LIQUID_PARSE_FIELDS.includes(k) && hasLiquid(processedValue)) {
        if (!engine) {
          engine = getLiquidEngine();
        }
        if (!skipParse && typeof processedValue === "string") {
          try {
            processedValue = engine.parseAndRenderSync(processedValue, context);
          } catch (_err) {
            // do nothing
          }
        } else if (!skipParse && typeof processedValue === "object") {
          processedValue = processValuesWithLiquid(
            processedValue as Record<string, unknown>,
            context,
            engine,
          );
        }
      }
      newObject = { ...newObject, [k]: processedValue };
    }
    return newObject;
  } else {
    return value;
  }
};
