import { FC } from "react";

import {
  ChakraListItem,
  ChakraUnorderedList,
  Column,
  Text,
} from "@hightouchio/ui";
import { Table } from "src/ui/table";

import { Link } from "src/router";

import { TraitQuery, useAudiencesForTraitQuery } from "src/graphql";
import { SyncsCell } from "src/pages/syncs/sync/components/syncs-cell";
import { TextWithTooltip } from "../text-with-tooltip";

type Props = {
  trait: NonNullable<TraitQuery["trait_definitions_by_pk"]>;
};

export const TraitReferences: FC<Readonly<Props>> = ({ trait }) => {
  const traitType = trait.is_template ? "template" : "trait";

  const { data: audienceData, isLoading } = useAudiencesForTraitQuery(
    {
      segmentIds: trait.trait_references_v2.audiences,
    },
    {
      enabled: trait.trait_references_v2.audiences.length > 0,
    },
  );

  return (
    <Column mt={4} gap={2} alignItems="start">
      <Text>This {traitType} is used in the following:</Text>

      {/* Active traits */}
      {trait.active_traits.length > 0 && (
        <Column alignItems="start">
          <Text fontWeight="medium">Traits:</Text>
          <ChakraUnorderedList>
            {trait.active_traits.map(
              ({ id, name }: { id: string; name: string }) => (
                <ChakraListItem key={id}>
                  <TextWithTooltip message={name}>
                    <Link href={`/traits/active/${id}`} isExternal>
                      {name}
                    </Link>
                  </TextWithTooltip>
                </ChakraListItem>
              ),
            )}
          </ChakraUnorderedList>
        </Column>
      )}

      {/* Audiences and syncs */}
      {trait.trait_references_v2.audiences.length > 0 && (
        <Table
          loading={isLoading}
          data={audienceData?.segments}
          columns={[
            {
              name: "Audience",
              cell: (audience) => (
                <TextWithTooltip message={audience.name}>
                  <Link isExternal href={`/audiences/${audience.id}`}>
                    {audience.name}
                  </Link>
                </TextWithTooltip>
              ),
              cellSx: { fontWeight: "medium", cusror: "pointer" },
            },
            {
              name: "Syncs",
              cell: (audience) => <SyncsCell syncs={audience.syncs} />,
            },
          ]}
        />
      )}
    </Column>
  );
};

export const isTraitReferenced = (
  trait: NonNullable<TraitQuery["trait_definitions_by_pk"]>,
) => {
  return Boolean(
    trait.trait_references_v2.audiences.length || trait.active_traits.length,
  );
};
