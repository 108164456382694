import { PlayIcon } from "@hightouchio/ui";

import { TileNode } from "./tile-node";
import { TileProps } from "./types";

export const StartNode = (props: TileProps) => {
  return (
    <TileNode
      isSource
      {...props}
      selected={false} // Start node is not cloneable nor deletable
      isCloneable={false}
      isDeleteable={false}
      colorScheme="gray"
      icon={<PlayIcon />}
    />
  );
};
