import type { JourneyRun } from "src/pages/journeys/types";

/**
 * Determines if the journey is currently running.
 */
export const getIsJourneyRunning = (journeyRun?: JourneyRun): boolean => {
  if (journeyRun == null) {
    return false;
  }
  return journeyRun.started_at != null && journeyRun.finished_at == null;
};
