import { FC } from "react";

import {
  Checkbox,
  Column,
  GroupedCombobox,
  InformationIcon,
  Row,
  Select,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import immutableUpdate from "immutability-helper";
import { captureException } from "@sentry/react";

import { useQueryBuilderContext } from "src/components/explore/context/hooks";
import { useFilterableColumnsQuery } from "src/graphql";
import {
  ColumnType,
  ColumnValue,
  DefaultOperators,
  FilterableColumn,
  isColumnTypeValue,
  isRelatedJourneyEventColumn,
  OperatorOptions,
  OperatorsWithoutValue,
  PropertyCondition,
  ValueType,
} from "src/types/visual";

import { FilterProps } from "./condition";
import { ErrorMessage } from "./error-message";
import { Filter } from "./filter";
import { PropertyInput, PropertyInputProps } from "./property-input";
import {
  createJourneyEventColumn,
  formatValue,
  getPropertyNameFromColumn,
  updateConditionOperator,
} from "./utils";

const ValueOrPropertyOptions = [
  {
    label: "value",
    value: "value",
  },
  {
    label: "property",
    value: "property",
  },
];

type Props = Pick<
  FilterProps<PropertyCondition>,
  "parent" | "condition" | "onChange"
> & {
  isDisabled?: boolean;
  loadingSuggestions: boolean;
  operatorError: string | null | undefined;
  suggestions: PropertyInputProps["suggestions"];
  showParameterizeCheckbox?: boolean;
  allowPropertyComparison?: boolean;
  valueError: string | null | undefined;
  columns?: FilterableColumn[] | undefined;
  onSearchUpdate?: (search: string) => void;
};

export const PropertyOperatorValueFilter: FC<Readonly<Props>> = ({
  condition,
  isDisabled = false,
  loadingSuggestions,
  operatorError,
  parent,
  showParameterizeCheckbox,
  allowPropertyComparison = false,
  suggestions,
  valueError,
  onChange,
  onSearchUpdate,
  columns,
}) => {
  const { events, selectedEvent } = useQueryBuilderContext();
  const isParameterized = Boolean(condition.propertyOptions?.parameterize);
  const showValueOrPropertySelector =
    allowPropertyComparison && Boolean(selectedEvent);
  const selectedEventModel = events.find(
    (event) => event.to_model.id === selectedEvent?.eventModelId,
  );
  const isPropertySelected =
    isColumnTypeValue(condition.value) &&
    isRelatedJourneyEventColumn(condition.value.property);

  // Fetch columns for the selected event, if there is one
  const filterableColumnsQuery = useFilterableColumnsQuery(
    {
      segmentId: selectedEventModel?.to_model.id?.toString() ?? "",
    },
    {
      enabled: Boolean(selectedEventModel?.to_model),
      select: (data) => data.segments_by_pk?.filterable_audience_columns ?? [],
    },
  );
  const eventColumns = filterableColumnsQuery.data ?? [];

  const connectionType = parent?.connection?.definition.type;
  const operatorOptions = condition.propertyType
    ? OperatorOptions[condition.propertyType].filter(
        (op) =>
          !("supportedSources" in op) ||
          op.supportedSources.includes(connectionType),
      )
    : undefined;
  const operatorLabel = isParameterized
    ? "is"
    : operatorOptions?.find((option) => option.value === condition.operator)
        ?.label;
  const formattedValue = formatValue(condition, condition.value, {
    showParameterizedLabel: true,
    prefix: showValueOrPropertySelector ? "entry event property: " : undefined,
  });

  const updateConditionValueType = (
    conditionToUpdate: PropertyCondition,
    valueType: string,
  ) => {
    if (valueType === "property") {
      return immutableUpdate(conditionToUpdate, {
        value: {
          $set: {
            type: ValueType.Column,
            propertyType: ColumnType.Unknown,
            property: createJourneyEventColumn({
              modelId: "",
              name: "",
            }),
          } satisfies ColumnValue,
        },
      });
    } else if (valueType === "value") {
      return immutableUpdate(conditionToUpdate, {
        value: { $set: null },
      });
    }

    return conditionToUpdate;
  };

  const isEmpty =
    condition.value == undefined &&
    !OperatorsWithoutValue.includes(condition.operator);

  return (
    <Filter
      isDisabled={isDisabled}
      isEmpty={isEmpty}
      content={
        <Row alignItems="flex-start" gap={1}>
          {showParameterizeCheckbox && (
            <Row alignSelf="center" gap={1}>
              <Checkbox
                label="Parameterize"
                isDisabled={isDisabled}
                isChecked={isParameterized}
                onChange={(event) => {
                  const propertyType =
                    condition.propertyType ?? ColumnType.Unknown;
                  onChange({
                    operator: DefaultOperators[propertyType],
                    value: null,
                    propertyOptions: {
                      ...condition.propertyOptions,
                      parameterize: event.target.checked,
                    },
                  });
                }}
              />
              <Tooltip message="Parameterizing a filter allows users to select a value on the audience level. For example: set a “Revenue” metric with “Brand” as a parametrized filter and users can select the relevant brand for each audience.">
                <InformationIcon />
              </Tooltip>
            </Row>
          )}
          {!isParameterized && (
            <>
              {showValueOrPropertySelector && (
                <Select
                  removePortal
                  isDisabled={isDisabled}
                  options={ValueOrPropertyOptions}
                  value={isPropertySelected ? "property" : "value"}
                  width="auto"
                  onChange={(value) => {
                    if (!value) return;

                    onChange(updateConditionValueType(condition, value));
                  }}
                />
              )}
              <Select
                removePortal
                isDisabled={isDisabled}
                options={operatorOptions ?? []}
                placeholder="Filter on"
                value={condition.operator}
                width="auto"
                onChange={(value) =>
                  onChange(updateConditionOperator(condition, value))
                }
              />
              {isPropertySelected ? (
                <Column>
                  <GroupedCombobox
                    removePortal
                    isInvalid={Boolean(valueError)}
                    isLoading={filterableColumnsQuery.isLoading}
                    optionGroups={[
                      {
                        label: "Entry event columns",
                        options: eventColumns,
                      },
                    ].filter((group) => group.options.length > 0)}
                    optionValue={(option) => option}
                    optionLabel={(option) =>
                      option.alias ??
                      getPropertyNameFromColumn(option.column_reference) ??
                      ""
                    }
                    placeholder="Entry event properties"
                    // The value is a reference column to a column on the entry event
                    value={eventColumns.find(
                      (column) =>
                        getPropertyNameFromColumn(column.column_reference) ===
                        condition.value.property?.column?.name,
                    )}
                    width="2xs"
                    onChange={(option) => {
                      if (!option) return;

                      const columnName = getPropertyNameFromColumn(
                        option.column_reference,
                      );

                      if (!columnName) {
                        captureException(
                          `Property operator value filter: column name not found for option ${JSON.stringify(option)}`,
                        );
                        return;
                      }

                      onChange(
                        immutableUpdate(condition, {
                          value: {
                            $set: {
                              type: ValueType.Column,
                              propertyType: option.type as ColumnType,
                              property: createJourneyEventColumn({
                                modelId: selectedEventModel?.to_model.id,
                                name: columnName,
                              }),
                            } satisfies ColumnValue,
                          },
                        }),
                      );
                    }}
                  />
                  {valueError && <ErrorMessage>{valueError}</ErrorMessage>}
                </Column>
              ) : (
                <PropertyInput
                  isDisabled={isDisabled}
                  condition={condition}
                  error={valueError}
                  loading={loadingSuggestions}
                  suggestions={suggestions}
                  onChange={onChange}
                  onSearchUpdate={onSearchUpdate}
                  columns={columns}
                  parent={parent ?? undefined}
                />
              )}
            </>
          )}
        </Row>
      }
      error={operatorError || valueError}
    >
      <Row gap={1} overflow="hidden">
        {operatorLabel ? (
          <>
            <Text color="text.secondary">{operatorLabel}</Text>{" "}
            <Text color="text.primary" fontWeight="medium">
              {formattedValue}
            </Text>
          </>
        ) : (
          <Text color="text.secondary">Filter on</Text>
        )}
      </Row>
    </Filter>
  );
};
