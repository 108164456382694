import { useEffect, useMemo, useRef, useState } from "react";
import {
  EvaluateTimeSeriesMetricQueryVariables,
  useCancelAnalyticsQueryMutation,
  useEvaluateTimeSeriesMetricQuery,
  useGetMetricResultBackgroundQuery,
} from "src/graphql";
import { MetricResultMaybeFromCache } from "./use-metric-series";

const POLL_INTERVAL_MS = 3000;

type EvaluateAndPollTimeSeriesRequestResult = {
  isPolling: boolean;
  timeSeriesData: MetricResultMaybeFromCache[];
  pollingError: Error | null;
};

export const useEvaluateAndPollTimeSeriesRequest = ({
  timeSeriesRequest,
  requestEnabled,
}: {
  timeSeriesRequest: EvaluateTimeSeriesMetricQueryVariables;
  requestEnabled: boolean;
}): EvaluateAndPollTimeSeriesRequestResult => {
  const [isPolling, setIsPolling] = useState(false);

  const previousJobIds = useRef<string[]>([]);

  const cancelAnalyticsQuery = useCancelAnalyticsQueryMutation({
    onSuccess: () => {
      // set `onSuccess` as a noop so running the mutation
      // does not invalidate cache on cancellations
    },
  });

  const evaluteTimeSeriesMetricQuery = useEvaluateTimeSeriesMetricQuery(
    timeSeriesRequest,
    {
      enabled: requestEnabled,
      keepPreviousData: true,
      notifyOnChangeProps: "tracked",
      onSettled: (data) => {
        if (data) {
          const backgroundJobs = data.evaluateTimeSeriesMetric.backgroundJobs;

          // cancel previous requests
          const newJobsIds = backgroundJobs.map((job) => job.jobId);

          if (newJobsIds.some((id) => !previousJobIds.current.includes(id))) {
            previousJobIds.current.forEach((jobId) =>
              cancelAnalyticsQuery.mutate({ jobId }),
            );
          }

          previousJobIds.current = newJobsIds;
        }
      },
    },
  );

  const backgroundJobs =
    evaluteTimeSeriesMetricQuery.data?.evaluateTimeSeriesMetric.backgroundJobs;

  // If data is empty, keep polling.
  const polledTimeSeriesMetricQuery = useGetMetricResultBackgroundQuery(
    {
      jobIds: backgroundJobs?.map(({ jobId }) => jobId) ?? [],
    },
    {
      enabled: isPolling && Boolean(backgroundJobs?.length),
      refetchInterval: POLL_INTERVAL_MS,
      // when this finishes we should remove the old job ids.
      onSettled: (data) => {
        if (
          data &&
          data.getMetricResultBackground.data.length ===
            previousJobIds.current.length
        ) {
          previousJobIds.current = [];
        }
      },
    },
  );

  const pollingError = polledTimeSeriesMetricQuery.error;

  const immediateData: MetricResultMaybeFromCache[] | undefined = useMemo(
    () =>
      evaluteTimeSeriesMetricQuery.data?.evaluateTimeSeriesMetric?.immediateData?.map(
        (data) => ({
          ...data,
          ids: {
            ...data.ids,
            cohortDefinition: data.ids.cohortDefinition,
          },
        }),
      ),
    [evaluteTimeSeriesMetricQuery.data],
  );

  const delayedData: MetricResultMaybeFromCache[] | undefined = useMemo(
    () =>
      polledTimeSeriesMetricQuery.data?.getMetricResultBackground?.data?.map(
        (data) => ({
          ...data,
          ids: {
            ...data.ids,
            cohortDefinition: data.ids.cohortDefinition,
          },
        }),
      ),
    [polledTimeSeriesMetricQuery.data],
  );

  useEffect(() => {
    const hasBackgroundJobs =
      backgroundJobs !== undefined && backgroundJobs.length > 0;

    const analyticsStarted =
      evaluteTimeSeriesMetricQuery.isLoading || hasBackgroundJobs;

    const shouldPoll =
      !pollingError &&
      analyticsStarted &&
      (!delayedData ||
        !backgroundJobs ||
        delayedData.length < backgroundJobs.length);

    setIsPolling(shouldPoll);
  }, [delayedData, immediateData, backgroundJobs, pollingError]);

  const timeSeriesData = useMemo(() => {
    let data = immediateData ?? [];

    if (backgroundJobs?.length) {
      data = data.concat(delayedData ?? []);
    }

    return data;
  }, [immediateData, delayedData, backgroundJobs?.length]);

  return {
    isPolling:
      isPolling ||
      evaluteTimeSeriesMetricQuery.isLoading ||
      evaluteTimeSeriesMetricQuery.isRefetching ||
      polledTimeSeriesMetricQuery.isLoading,
    timeSeriesData,
    pollingError,
  };
};
