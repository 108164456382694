import { FC } from "react";

import {
  Button,
  Text,
  Box,
  Column,
  Row,
  SectionHeading,
  BidirectionalArrowIcon,
} from "@hightouchio/ui";

import { Logo } from "src/ui/brand";
import { Card } from "src/components/card";
import { PermissionedButton } from "./permission";
import { ResourcePermissionInput } from "./permission/use-resource-permission";
import { HTImage } from "src/components/image";

interface Props {
  href: string;
  icon: string;
  name: string;
  buttonText?: string;
  onAuthorize: () => Promise<void | undefined> | void | undefined;
}

interface WebhookAuthorizeButtonProps
  extends Omit<Props, "href" | "onAuthorize"> {
  isDisabled?: boolean;
  onAuthorize: (e: any) => Promise<void | undefined>;
}

export const WebhookAuthorizeButton: FC<
  Readonly<WebhookAuthorizeButtonProps>
> = ({ icon, isDisabled, name, onAuthorize }) => {
  return (
    <Row>
      <Button size="lg" isDisabled={isDisabled} onClick={onAuthorize}>
        <HTImage
          alt={name}
          src={icon}
          sx={{ width: "24px", objectFit: "contain", mr: 2 }}
        />
        Get access token
      </Button>
    </Row>
  );
};

export const AuthorizeConnection: FC<Readonly<Props>> = ({
  href,
  icon,
  name,
  buttonText,
  onAuthorize,
}) => {
  const btnText = buttonText || `Log in to ${name}`;

  return (
    <Card>
      <Column sx={{ alignItems: "center", gap: 4 }}>
        <Row sx={{ alignItems: "center", gap: 4, mb: 4 }}>
          <Logo
            theme="dark"
            sx={{ width: "60px", mr: 2, objectFit: "contain", flexShrink: 0 }}
          />

          <Row align="center" fontSize="48px" flexShrink={0} color="base.4">
            <BidirectionalArrowIcon />
          </Row>

          <HTImage
            alt={name}
            src={icon}
            sx={{ width: "60px", mr: 2, objectFit: "contain", flexShrink: 0 }}
          />
        </Row>
        <SectionHeading>Authorize connection</SectionHeading>
        <Box maxWidth="64ch" mb={4} textAlign="center">
          <Text>
            Hightouch requires limited access to your {name} account. Your
            credentials will be encrypted, and authorization can be revoked at
            any time.
          </Text>
        </Box>
        <Button
          size="lg"
          onClick={() => {
            onAuthorize();
            window.location.href = href;
          }}
        >
          <HTImage
            alt={name}
            src={icon}
            sx={{ width: "24px", objectFit: "contain", mr: 2 }}
          />
          {btnText}
        </Button>
      </Column>
    </Card>
  );
};

export const ReauthorizeConnection: FC<
  Props & {
    permission: ResourcePermissionInput<
      "source" | "destination",
      "source" | "destination"
    >;
  }
> = ({ onAuthorize, href, name, icon, permission }) => {
  return (
    <Box>
      <Box mt={2}>
        <PermissionedButton
          permission={permission}
          onClick={() => {
            onAuthorize();
            window.location.href = href;
          }}
        >
          <HTImage
            alt={name}
            src={icon}
            sx={{ width: "18px", objectFit: "contain", mr: 2 }}
          />
          Reauthorize {name}
        </PermissionedButton>
      </Box>
    </Box>
  );
};
