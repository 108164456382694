import { FC, useEffect, useMemo } from "react";

import {
  RadioGroup as HightouchUiRadioGroup,
  Radio,
  Box,
  FormField,
  TextInput,
  Alert,
  Badge,
  CodeSnippet,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";

import { AccordionSection } from "src/components/accordion-section";
import { TunnelSelect } from "src/components/tunnel-select";
import { ProcessFormNode } from "src/formkit/formkit";
import { SourceDefinition, useCloudCredentialsV2Query } from "src/graphql";
import { Markdown } from "src/ui/markdown";
import {
  Context,
  DEFAULT_PERMISSIONS,
  Permissions,
  PERMISSIONS,
} from "./constants";
import { ProviderSection } from "./providers";
import { useUser } from "src/contexts/user-context";
import { Section } from "src/formkit/components/section";
import { shouldWrapInSection } from "src/formkit/utils";

export const DEFAULT_LIGHTNING_SCHEMA_PLANNER = "hightouch_planner";
export const DEFAULT_LIGHTNING_SCHEMA_AUDIT = "hightouch_audit";

export interface WarehouseSchemaConfig {
  planner: string;
  audit: string;
  matchbooster: string;
}

export const DEFAULT_LIGHTNING_SCHEMA_CONFIG: WarehouseSchemaConfig = {
  audit: "hightouch_audit",
  planner: "hightouch_planner",
  matchbooster: "hightouch_planner",
};

/**
 * Props shared between source and event destination forms.
 */
export type SharedFormMethodProps = {
  definition: SourceDefinition;
  config: Record<string, unknown> | undefined;
  setupMethods: Record<string, any>;
  credentialId: string | undefined;
  setCredentialId: (credential: string) => void;
  tunnelId: string | null | undefined;
  setTunnelId: (tunnel: string | null | undefined) => void;
  isSetup: boolean;
};

export type FormMethodProps = SharedFormMethodProps & SyncEngineProps;

export const FormMethod: FC<Readonly<FormMethodProps>> = ({
  definition,
  config,
  setupMethods,
  tunnelId,
  setTunnelId,
  lightningEnabled,
  setLightningEnabled,
  plannerDatabase,
  setPlannerDatabase,
  credentialId,
  setCredentialId,
  hasSetupLightning,
  lightningSchemaMode,
  schema,
  setSchema,
  setLightningSchemaMode,
  isSetup,
}) => {
  const { sourceSnowflakeEnableTunnel } = useFlags();
  const matchingSource = setupMethods?.find((o) => o.key === config?.methodKey);
  const { isEmbedded } = useUser();

  const Form = useMemo(() => {
    if (!matchingSource?.form) return null;
    const node = <ProcessFormNode node={matchingSource.form} />;
    if (
      matchingSource.form.children[0].layout === "section" &&
      !matchingSource.form.children[0].heading
    ) {
      // Handle incorrect definitions that don't have a heading
      matchingSource.form.children[0].heading = `Configure your ${definition.name} source`;
    }
    // Handles improper defintion where top children are fields, but should always be sections
    if (shouldWrapInSection(matchingSource.form)) {
      return (
        <Section heading={`Configure your ${definition.name} source`}>
          {node}
        </Section>
      );
    }
    return node;
  }, [matchingSource?.key]);

  const CredentialsForm = useMemo(() => {
    if (!matchingSource?.credentialsForm) return null;
    const node = <ProcessFormNode node={matchingSource.credentialsForm} />;
    // Handles improper defintion where top children are fields, but should always be sections
    if (shouldWrapInSection(matchingSource.credentialsForm)) {
      return (
        <Section heading={`Provide your ${definition.name} credentials`}>
          {node}
        </Section>
      );
    }
    return node;
  }, [matchingSource?.key]);

  useEffect(() => {
    if (isSetup && definition.supportsInWarehouseDiffing) {
      setLightningEnabled(true);
    }
  }, [definition.supportsInWarehouseDiffing, isSetup]);

  const showTunneling =
    // Does the source form support tunneling?
    matchingSource?.tunneling &&
    // If the source is Snowflake, check that the user has the matching feature flag.
    (definition.type !== "snowflake" ||
      (definition.type === "snowflake" && sourceSnowflakeEnableTunnel));
  const showProvider = ["gcp", "aws", "azure"].includes(
    matchingSource?.provider,
  );
  const showForm = matchingSource?.form;
  const showLightning = definition.supportsInWarehouseDiffing;
  const showCredentials = matchingSource?.credentialsForm;

  return (
    <>
      {showTunneling && (
        <Section
          heading="Choose your connection type"
          subheading={`Hightouch can connect directly to ${definition.name} if it's exposed to the internet. However, if you need to open a connection within a private network or VPC, you will need to set up an SSH tunnel.`}
        >
          <Tunnel
            name={definition.name}
            value={tunnelId}
            onChange={setTunnelId}
          />
        </Section>
      )}
      {showProvider && (
        <Section heading="Configure your credentials">
          <ProviderSection
            credentialId={credentialId}
            provider={matchingSource.provider}
            setCredentialId={setCredentialId}
          />
        </Section>
      )}
      {showForm && Form}
      {showLightning && (
        <Section
          subheading={
            isEmbedded
              ? `For the best sync performance, you'll need to enable the Lightning sync engine, which is optimized for ${definition.name} and uses the power of your warehouse to process data more efficiently. This engine supports faster and more frequent syncs. If you're unable to grant the permissions required by the Lightning sync engine, you can downgrade to the Basic sync engine, but your syncs will be slower, and some features will not be available.`
              : `To get the most out of Hightouch, you'll need to enable the Lightning sync engine, which is optimized for ${definition.name} and uses the power of your warehouse to process data more efficiently. This engine supports faster and more frequent syncs, and it unlocks the full capabilities of Customer Studio, Campaign Intelligence, and other Hightouch features. If you're unable to grant the permissions required by the Lightning sync engine, you can downgrade to the Basic sync engine, but your syncs will be slower, and some features will not be available.`
          }
          heading="Choose your sync engine"
        >
          <SyncEngine
            config={config}
            credentialId={credentialId}
            definition={definition}
            hasSetupLightning={hasSetupLightning}
            lightningEnabled={lightningEnabled}
            plannerDatabase={plannerDatabase}
            setLightningEnabled={setLightningEnabled}
            setPlannerDatabase={setPlannerDatabase}
            supportsPlannerDatabase={definition.supportsCrossDbReference}
            schema={schema}
            lightningSchemaMode={lightningSchemaMode}
            setSchema={setSchema}
            setLightningSchemaMode={setLightningSchemaMode}
          />
        </Section>
      )}
      {showCredentials && CredentialsForm}
    </>
  );
};

export type SyncEngineProps = {
  hasSetupLightning: boolean;
  lightningEnabled: boolean | undefined;
  setLightningEnabled: (enabled: boolean | undefined) => void;
  plannerDatabase: string | undefined;
  setPlannerDatabase: (database: string | undefined) => void;
  setSchema: (cfg: WarehouseSchemaConfig) => void;
  schema?: WarehouseSchemaConfig;
  lightningSchemaMode?: "shared" | "separate";
  setLightningSchemaMode: (mode: "shared" | "separate") => void;
};

const SyncEngine: FC<
  SyncEngineProps & {
    supportsPlannerDatabase: boolean;
    definition: SourceDefinition;
    config: Record<string, unknown> | undefined;
    credentialId: string | undefined;
  }
> = ({
  lightningEnabled,
  setLightningEnabled,
  plannerDatabase,
  setPlannerDatabase,
  supportsPlannerDatabase,
  definition,
  config,
  credentialId,
  hasSetupLightning,
  schema,
  setSchema,
  lightningSchemaMode,
  setLightningSchemaMode,
}) => {
  const name = definition.name;
  const { data } = useCloudCredentialsV2Query(
    { id: String(credentialId) },
    { enabled: Boolean(credentialId) },
  );
  const { isEmbedded } = useUser();

  const ctx = {
    definitionName: name,
    configuration: config,
    credential: data?.getCloudCredentials?.[0],
    plannerDatabase,
    schema,
  };

  const lightningPermissions =
    PERMISSIONS[definition.type]?.["lightning"] ||
    DEFAULT_PERMISSIONS["lightning"];
  const standardPermissions =
    PERMISSIONS[definition.type]?.["default"] || DEFAULT_PERMISSIONS["default"];

  const syncLogUrl =
    import.meta.env.VITE_DOCS_URL + "/syncs/warehouse-sync-logs";

  return (
    <>
      {hasSetupLightning && (
        <Alert
          variant="inline"
          type="info"
          title="Lightning sync engine enabled"
          message="This source is already configured to use the Lightning sync engine. Once enabled, the Lightning sync engine cannot be disabled."
        />
      )}

      <HightouchUiRadioGroup
        isDisabled={hasSetupLightning}
        value={lightningEnabled ? "lightning" : "standard"}
        onChange={(syncEngine) => {
          setLightningEnabled(syncEngine === "lightning");
        }}
      >
        <Radio
          badge={<Badge>Recommended</Badge>}
          description={
            isEmbedded
              ? `This engine offers the best sync performance for ${name}. It uses a dedicated schema in ${name} to store temporary tables that help Hightouch efficiently track changes to your data.`
              : `This engine offers the best sync performance for ${name} and unlocks additional platform features. It uses a dedicated schema in ${name} to store temporary tables that help Hightouch efficiently track changes to your data.`
          }
          label="Lightning sync engine"
          value="lightning"
        />
        <Radio
          description={`This engine is available as a fallback if you're unable to set up the Lightning sync engine. You can configure it with read-only credentials. Some features will be unavailable, and syncs will be slower.`}
          label="Basic sync engine"
          value="standard"
        />
      </HightouchUiRadioGroup>

      {!lightningEnabled && standardPermissions && !hasSetupLightning && (
        <Box>
          <AdditionalPermissions ctx={ctx} permissions={standardPermissions} />
        </Box>
      )}

      {lightningEnabled && lightningPermissions && !hasSetupLightning && (
        <Box>
          <AdditionalPermissions ctx={ctx} permissions={lightningPermissions} />
        </Box>
      )}

      {lightningEnabled && (
        <Box>
          <AccordionSection label="Override schema names and locations (optional)">
            {lightningEnabled && supportsPlannerDatabase && (
              <FormField
                isOptional
                description="The vast majority of users can safely skip this question. If you need the Lightning sync engine to store its cache in a different location than specified above, you may provide another database or project here. (Leave blank to skip.)"
                label="Override location of Lightning sync engine cache?"
                mt={3}
              >
                <TextInput
                  isDisabled={hasSetupLightning}
                  placeholder="Enter a different database or project..."
                  value={plannerDatabase ?? ""}
                  onChange={(event) => {
                    setPlannerDatabase(event.target.value);
                  }}
                />
              </FormField>
            )}

            <Box>
              <FormField
                description={
                  <Markdown>
                    Hightouch will use this schema to store temporary tables and
                    other internal artifacts used by the sync engine and other
                    features. It is fully managed by Hightouch and is not
                    intended for consumption by other systems.
                  </Markdown>
                }
                label="Override name of internal schema?"
                mt={3}
              >
                <TextInput
                  isDisabled={hasSetupLightning}
                  placeholder="Enter a different schema..."
                  value={
                    schema?.planner ?? DEFAULT_LIGHTNING_SCHEMA_CONFIG.planner
                  }
                  onChange={(event) => {
                    setSchema({
                      ...(schema ?? {}),
                      planner: event.target.value,
                      // We may support separating the MB schema, but currently we
                      // haven't verified that this works.
                      matchbooster: event.target.value,
                      ...(lightningSchemaMode === "shared"
                        ? // In shared mode, overwrite audit schema with planner schema
                          { audit: event.target.value }
                        : {}),
                    } as WarehouseSchemaConfig);
                  }}
                />
              </FormField>

              <HightouchUiRadioGroup
                isDisabled={hasSetupLightning}
                value={lightningSchemaMode}
                onChange={(mode) => {
                  setLightningSchemaMode(mode as "shared" | "separate");
                  if (mode === "shared") {
                    setSchema({
                      ...(schema ?? {}),
                      planner: (schema?.planner ??
                        DEFAULT_LIGHTNING_SCHEMA_CONFIG.planner) as string,
                      audit: (schema?.planner ??
                        DEFAULT_LIGHTNING_SCHEMA_CONFIG.audit) as string,
                      matchbooster: (schema?.planner ??
                        DEFAULT_LIGHTNING_SCHEMA_CONFIG.matchbooster) as string,
                    } as WarehouseSchemaConfig);
                  }
                }}
                orientation="vertical"
                mt={4}
              >
                <Radio
                  label="Use a single schema for all tables managed by the Lightning sync engine"
                  value="shared"
                />
                <Radio
                  label="Use separate schemas for internal tables and user-queryable tables"
                  value="separate"
                />
              </HightouchUiRadioGroup>

              {lightningSchemaMode !== "shared" && (
                <FormField
                  description={
                    <Markdown>
                      {`Hightouch will use this schema to store [warehouse sync logs](${syncLogUrl}) and other user-queryable tables.`}
                    </Markdown>
                  }
                  label="Override name of public schema?"
                  mt={6}
                >
                  <TextInput
                    isDisabled={hasSetupLightning}
                    placeholder="Enter a different schema..."
                    value={schema?.audit ?? DEFAULT_LIGHTNING_SCHEMA_AUDIT}
                    onChange={(event) => {
                      setSchema({
                        ...(schema ?? {}),
                        audit: event.target.value,
                      } as WarehouseSchemaConfig);
                    }}
                  />
                </FormField>
              )}
            </Box>
          </AccordionSection>
        </Box>
      )}
    </>
  );
};

const AdditionalPermissions: FC<
  Readonly<{
    ctx: Context;
    permissions: NonNullable<Permissions["default"] | Permissions["lightning"]>;
  }>
> = ({ permissions, ctx }) => {
  return (
    <Alert
      variant="inline"
      type="warning"
      title="Additional permissions are required. Please modify and run the snippet below."
      message={
        <>
          <Markdown>{permissions.reason(ctx)}</Markdown>

          {permissions.code.length > 0 && (
            <Box
              display="flex"
              flexDirection="column"
              gap={3}
              mt={3}
              sx={{ pre: { whiteSpace: "pre-wrap" } }}
            >
              {permissions.code.map((codeBlock, index) => (
                <CodeSnippet
                  key={index}
                  code={codeBlock.code(ctx)}
                  label={
                    permissions.code.length > 1
                      ? codeBlock.title(ctx)
                      : undefined
                  }
                />
              ))}
            </Box>
          )}
        </>
      }
    />
  );
};

type TunnelProps = {
  name: string;
  value: string | null | undefined;
  onChange: (tunnel: string | null | undefined) => void;
};

export const Tunnel: FC<TunnelProps> = ({ name, value, onChange }) => {
  const connectViaTunnel = value !== undefined;

  return (
    <>
      <HightouchUiRadioGroup
        orientation="vertical"
        value={connectViaTunnel ? "tunnel" : "direct"}
        onChange={(value) => {
          onChange(value === "tunnel" ? null : undefined);
        }}
      >
        <Radio
          badge={<Badge>Most common</Badge>}
          label={`Connect directly to ${name}`}
          value="direct"
        />

        <Radio label="Connect via SSH tunnel" value="tunnel" />
      </HightouchUiRadioGroup>

      {connectViaTunnel && (
        <TunnelSelect
          value={value ? { id: value } : undefined}
          onChange={(tunnel) => {
            onChange(tunnel?.id ?? null);
          }}
        />
      )}
    </>
  );
};
