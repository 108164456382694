import orderBy from "lodash/orderBy";
import { Edge, getOutgoers } from "reactflow";
import { Schedule, ScheduleType } from "src/components/schedule/types";

import {
  JourneyEventType,
  JourneyNode,
  JourneyNodeDetails,
  SegmentPriorityList,
  SplitBranch,
} from "src/pages/journeys/types";
import {
  isSegmentBranchNodeDetails,
  isSplitBranchNodeDetails,
} from "src/pages/journeys/utils";
import {
  JourneyNodeType,
  SegmentBranchConfig,
  SplitBranchConfig,
} from "src/types/journeys";
import {
  AndOrCondition,
  ConditionType,
  exhaustiveCheck,
  PropertyCondition,
  IntervalUnit,
} from "src/types/visual";
import {
  MINUTES_IN_DAY,
  MINUTES_IN_HOUR,
  MINUTES_IN_MONTH,
  MINUTES_IN_WEEK,
  MINUTES_IN_YEAR,
} from "../constants";

/**
 * Ensure that the property conditions are wrapped in a top level AND condition
 */
export const getFormattedSubconditions = (
  subcondition: AndOrCondition<PropertyCondition>,
): AndOrCondition<PropertyCondition> => {
  // If top level condition is an AND or OR condition, return
  if (
    subcondition.type === ConditionType.And ||
    subcondition.type === ConditionType.Or
  ) {
    return subcondition;
  }

  return {
    type: ConditionType.And,
    conditions: [subcondition],
  };
};

export const getOrderedEditableSegments = (
  nodes: JourneyNode[],
): SegmentPriorityList => {
  const nodeDetails = nodes.map(({ data }) => data);

  const segmentBranchNodes: JourneyNodeDetails<SegmentBranchConfig>[] =
    nodeDetails.filter(isSegmentBranchNodeDetails);

  const branchNodes = orderBy(
    segmentBranchNodes
      // Catch all node is omitted
      .filter((node) => !node.config.segment_is_catch_all),
    ["config.segment_priority_rank"],
    ["asc"],
  );

  return branchNodes.map((node) => ({
    id: node.id,
    name: node.name,
  }));
};

export const getSplitGroups = (nodes: JourneyNode[]): SplitBranch[] => {
  const nodeDetails = nodes.map(({ data }) => data);

  const splitBranchNodes: JourneyNodeDetails<SplitBranchConfig>[] =
    nodeDetails.filter(isSplitBranchNodeDetails);

  const branchNodes = orderBy(splitBranchNodes, ["asc"]);

  return branchNodes.map((node) => ({
    id: node.id,
    name: node.name,
    percentage: node.config.percentage,
  }));
};

/**
 * Traverse down and find all downstream nodes from the given one.
 **/
export const getAllDownstreamNodes = (
  sourceNode: JourneyNode,
  nodes: JourneyNode[],
  edges: Edge[],
  visited?: Map<string, JourneyNode[]>,
): JourneyNode[] => {
  const visitedNodes = visited || new Map<string, JourneyNode[]>();

  // If sourceNode has already been visited, return the stored downstream nodes
  if (visitedNodes.has(sourceNode.id)) {
    return visitedNodes.get(sourceNode.id)!;
  }

  // Get all the downstream (outgoing) nodes
  const downstreamNodes = sourceNode
    ? getOutgoers(sourceNode, nodes, edges)
    : [];
  const allChildren: JourneyNode[] = downstreamNodes;

  // Recursively get downstream nodes for each child
  downstreamNodes.forEach((childNode) => {
    if (visitedNodes.has(childNode.id)) {
      return;
    }

    allChildren.push(
      ...getAllDownstreamNodes(childNode, nodes, edges, visitedNodes),
    );
  });

  // Store the result in the visitedNodes map
  visitedNodes.set(sourceNode.id, allChildren);
  return allChildren;
};

function isTimeDelayNode(node: JourneyNode): boolean {
  return (
    node.type === JourneyNodeType.WaitUntilEvent ||
    node.type === JourneyNodeType.WaitUntilEventBranch ||
    node.type === JourneyNodeType.TimeDelay
  );
}

/**
 * Returns true/false based on if any of the downstream nodes have a time-based
 * or condition-based wait.
 **/
export const hasWaitNodeDownstream = (
  currentNode: JourneyNode,
  nodes: JourneyNode[],
  edges: Edge[],
): boolean => {
  const downstreamNodes = currentNode
    ? getAllDownstreamNodes(currentNode, nodes, edges)
    : [];

  if (downstreamNodes.length == 0) {
    return false;
  }

  return downstreamNodes.find(isTimeDelayNode) != null;
};

/**
 * Collapses the external-facing event type to the types we use internally.
 **/
export const mapJourneyEventForResolver = (
  event: JourneyEventType,
): { direction?: string; event?: JourneyEventType } => {
  switch (event) {
    case "in-progress":
      return { direction: "in" };
    case "completed-journey":
      return { event: "exited-journey" };
    case "advanced-from":
      return { direction: "from", event: "moved-to-node" };
    case "entered-tile":
      return { direction: "to", event: "moved-to-node" };
    default:
      return { event: event };
  }
};

export type JourneyInterval = {
  unit: IntervalUnit;
  quantity: number;
};

// Converts ScheduleInterval to a comparable number in minutes.
// This function is a heuristic for month/year, as it doesn't account for non-30day months
// and leap years. The functionality here currently mirrors the existing functionality
// in https://github.com/hightouchio/hightouch/blob/master/packages/backend/lib/query/visual/time-range.ts#L156
export const convertIntervalToMinutes = (interval: JourneyInterval): number => {
  const { unit, quantity } = interval;
  if (unit == null || quantity == null) {
    throw new Error("Invalid interval provided");
  }

  switch (unit) {
    case IntervalUnit.Minute:
      return quantity;
    case IntervalUnit.Hour:
      return quantity * MINUTES_IN_HOUR;
    case IntervalUnit.Day:
      return quantity * MINUTES_IN_DAY;
    case IntervalUnit.Week:
      return quantity * MINUTES_IN_WEEK;
    case IntervalUnit.Month:
      return quantity * MINUTES_IN_MONTH;
    case IntervalUnit.Year:
      return quantity * MINUTES_IN_YEAR;
    default:
      exhaustiveCheck(unit);
  }
};

/**
 * isIntervalLessThan compares two intervals by converting to minutes and checking
 * if interval1 < interval2. The function assumes the input params have been validated.
 */
export const isIntervalLessThan = (
  interval1: JourneyInterval,
  interval2: JourneyInterval,
): boolean =>
  convertIntervalToMinutes(interval1) < convertIntervalToMinutes(interval2);

// convertTimeToMinutes expects a 24-hr (23:59) based time string and converts the input into minutes
const convertTimeToMinutes = (timeStr: string): number => {
  const [hours, minutes] = timeStr.split(":").map(Number);
  return (hours || 0) * MINUTES_IN_HOUR + (minutes || 0);
};

const daysOfWeek = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

/**
 * Finds the minimum gap in minutes for a VisualCRON expression over a week (including rollover [Sat->Sun]).
 * This function is written for journeys with the expectation that the feature only supports
 * Interval and VisualCRON (custom) schedules. Additionally, findMinimumGapInMinutesAcrossVisualCronSchedule
 * is only called after the ScheduleManager is used for setting/validating the schedule.
 *
 * @param schedule - a Custom (Visual CRON) schedule object
 * @returns number of minutes for the minimum found gap. Default will be 1 week.
 */
export const findMinimumGapInMinutesAcrossVisualCronSchedule = (
  schedule: Schedule,
): number => {
  if (!schedule || schedule.type !== ScheduleType.CUSTOM) {
    throw new Error("invalid schedule provided");
  }

  const scheduleExpressions = schedule.schedule?.expressions ?? [];
  const minutesInWeek: number[] = [];

  // Parse scheduleExpressions and then map times into minutes across a week
  for (const entry of scheduleExpressions) {
    for (const day in entry.days) {
      if (entry.days[day]) {
        // Map the given schedule expression into minutes across the entire week with
        // Sunday 12:00AM as minute 0 and Saturday 11:59PM as minute 10079.
        const dayIndex = daysOfWeek.indexOf(day);
        const timeMinutesAtDay = convertTimeToMinutes(entry.time);
        const calculatedMinutes = dayIndex * MINUTES_IN_DAY + timeMinutesAtDay;

        // Ensure calculatedMinutes is non-negative before pushing
        if (calculatedMinutes >= 0) {
          minutesInWeek.push(calculatedMinutes);
        }
      }
    }
  }

  // One week in minutes
  let minDuration = MINUTES_IN_WEEK;
  if (minutesInWeek.length <= 1) {
    return minDuration;
  }

  // Sort times based on the day of the week and time
  minutesInWeek.sort((a, b) => a - b);
  // Calculate the minimum gap between consecutive schedule times
  for (let i = 1; i < minutesInWeek.length; i++) {
    const currentTime = minutesInWeek[i];
    const previousTime = minutesInWeek[i - 1];

    if (currentTime == null || previousTime == null) {
      // Needed for typechecking
      continue;
    }

    minDuration = Math.min(minDuration, currentTime - previousTime);
  }

  const lastTime = minutesInWeek[minutesInWeek.length - 1];
  const firstTime = minutesInWeek[0];
  if (firstTime != null && lastTime != null) {
    // Calculate the difference between the first and last elements
    const wraparoundGap = MINUTES_IN_WEEK - lastTime + firstTime;
    minDuration = Math.min(minDuration, wraparoundGap);
  }

  return minDuration;
};
