import { FC } from "react";

import { BoxProps, Row } from "@hightouchio/ui";

type IconBoxProps = {
  bg?: string;
  color?: string;
  icon: JSX.Element;
  boxSize?: number | string;
  iconSize?: number | string;
  hideBorder?: boolean;
};

export const IconBox: FC<Readonly<IconBoxProps & BoxProps>> = ({
  bg,
  color = "white",
  icon,
  boxSize = "24px",
  iconSize = "16px",
  hideBorder = false,
  ...props
}) => {
  const fontSize = typeof iconSize === "number" ? iconSize * 4 : iconSize;
  const hasGradient = color === "white";

  return (
    <Row
      align="center"
      justify="center"
      flexShrink={0}
      height={boxSize}
      width={boxSize}
      borderRadius="md"
      color={color}
      bg={bg}
      backgroundImage={
        hasGradient
          ? "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%)"
          : undefined
      }
      border={color !== "white" && !hideBorder ? "1px solid" : undefined}
      borderColor={color}
      fontSize={fontSize}
      sx={{
        svg: {
          color: `${color} !important`,
        },
      }}
      {...props}
    >
      {icon}
    </Row>
  );
};
