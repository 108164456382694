import { FC } from "react";

import {
  Alert,
  Box,
  CloseIcon,
  Column,
  DrawerBody,
  DrawerHeader,
  EmptyState,
  IconButton,
  Row,
  SectionHeading,
  Text,
} from "@hightouchio/ui";
import { useFormContext } from "react-hook-form";

import { NodeDetailFormProps } from "src/pages/journeys/types";
import { TimeDelayConfig } from "src/types/journeys";
import { JourneyGraph } from "src/pages/journeys/types";
import { Link, LinkButton } from "src/router";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { getIncomers } from "reactflow";

export const MiniNodeDetailsForm: FC<NodeDetailFormProps<TimeDelayConfig>> = ({
  id, // id techincally lives in data too, redundant to have both :/
  data,
  onClose,
}) => {
  const form = useFormContext<JourneyGraph>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Condition types
  const nodes = form.getValues("nodes");
  const edges = form.getValues("edges");
  const node = nodes.find(({ id: nodeId }) => nodeId === id);
  const parentNode = node ? getIncomers(node, nodes, edges)?.[0] : undefined;

  return (
    <>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <SectionHeading>{data.name}</SectionHeading>
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody bg="base.lightBackground">
        <Column minHeight={0} flex={1} gap={6} pb={4}>
          {parentNode == null ? (
            <EmptyState
              imageUrl={genericPlaceholder}
              title="Parent tile not found"
              message="The tile may have been removed. Please close this window and click on the tile you wish to inspect."
              actions={
                <LinkButton href="../" variant="primary">
                  Go back
                </LinkButton>
              }
            />
          ) : (
            <Column>
              <Row align="center" gap={2} mb={4}>
                <Box
                  as={LinkButton}
                  href={`../${parentNode.id}`}
                  px={2}
                  mx={-2}
                  fontWeight="normal"
                  variant="tertiary"
                >
                  <Text color="text.secondary">{parentNode.data?.name}</Text>
                </Box>

                <Text color="text.secondary" size="lg">
                  /
                </Text>

                <Text color="text.secondary" fontWeight="medium">
                  {data.name}
                </Text>
              </Row>

              <Alert
                type="subtle"
                title="Nothing to show here"
                message={
                  <>
                    <Link href={`../${parentNode.id}`}>Go back</Link> to
                    configure{" "}
                    <Text fontWeight="medium">{parentNode.data?.name}</Text>{" "}
                    tile
                  </>
                }
              />
            </Column>
          )}
        </Column>
      </DrawerBody>
    </>
  );
};
