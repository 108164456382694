import { useMemo } from "react";
import { Column, Row, Text } from "@hightouchio/ui";
import isEmpty from "lodash/isEmpty";
import pluralize from "pluralize";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Labels } from "src/components/labels/labels";
import { PageSpinner } from "src/components/loading";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  SyncTemplatesBoolExp,
  SyncTemplatesQuery,
  useSyncTemplatesQuery,
} from "src/graphql";
import { useNavigate } from "src/router";
import {
  Table as HightouchTable,
  Pagination,
  TableColumn,
  useTableConfig,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import {
  EmptyTemplatesState,
  NO_SEARCH_RESULTS_PLACEHOLDER,
} from "src/pages/templates/empty-templates-state";
import { useSearchParams } from "src/router";

export const SyncTemplates = () => {
  const [searchParam] = useSearchParams();
  const search = searchParam.get("search");

  const { limit, offset, page, setPage } = useTableConfig({ limit: 20 });
  const filters: SyncTemplatesBoolExp = useMemo(
    () => (search ? { name: { _ilike: `%${search}%` } } : {}),
    [search],
  );
  const { data, isLoading } = useSyncTemplatesQuery({
    filters,
    limit,
    offset,
  });

  if (isLoading || !data) {
    return <PageSpinner />;
  }

  return (
    <Column>
      {data.sync_templates_aggregate.aggregate?.count === 0 && !search ? (
        <EmptyTemplatesState
          docsUrl="customer-studio/syncs#sync-templates"
          newTemplateUrl="/schema-v2/settings/sync-templates/new"
          title="No sync templates have been created yet"
          message="Sync templates let you define sync configuration settings once for a particular parent model and destination."
        />
      ) : (
        <Table data={data} page={page} pageSize={limit} setPage={setPage} />
      )}
    </Column>
  );
};

const Table = ({
  data,
  page,
  pageSize,
  setPage,
}: {
  data: SyncTemplatesQuery;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
}) => {
  const navigate = useNavigate();

  const columns: TableColumn<SyncTemplatesQuery["sync_templates"][number]>[] = [
    {
      name: "Name",
      max: "3fr",
      cell: ({ name, tags }) => (
        <Column gap={0.5} overflowX="hidden" py={4}>
          <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
          {!isEmpty(tags) && <Labels labels={tags} />}
        </Column>
      ),
      cellSx: {
        pl: "4 !important",
      },
      headerSx: {
        "&:first-of-type": {
          pl: 4,
        },
      },
    },
    {
      name: "Parent Model",
      max: "2fr",
      cell: ({ segment }) => (
        <Row gap={2} width="100%">
          <IntegrationIcon
            src={segment?.connection?.definition?.icon}
            name={segment?.connection?.definition?.name}
            size={5}
          />
          <TextWithTooltip textTransform="capitalize">
            {segment.name}
          </TextWithTooltip>
        </Row>
      ),
    },
    {
      name: "Usage",
      max: "1fr",
      cell: ({ destination_instances_aggregate }) => (
        <Text>
          {pluralize(
            "syncs",
            destination_instances_aggregate.aggregate?.count ?? 0,
            true,
          )}
        </Text>
      ),
    },
    {
      name: "Destination",
      max: "2fr",
      cell: ({ destination }) => (
        <Row gap={2} width="100%">
          <IntegrationIcon
            src={destination.definition.icon}
            name={destination.definition.name}
            size={5}
          />
          <TextWithTooltip textTransform="capitalize">
            {destination?.name ?? destination.definition.name}
          </TextWithTooltip>
        </Row>
      ),
    },
    LastUpdatedColumn,
  ];

  return (
    <Column px={6}>
      <HightouchTable
        columns={columns}
        data={data.sync_templates}
        placeholder={NO_SEARCH_RESULTS_PLACEHOLDER}
        onRowClick={(row) => {
          navigate(`/schema-v2/settings/sync-templates/${row.id}`);
        }}
      />
      <Row justifyContent="right" mr={8} mt={4}>
        <Pagination
          count={data.sync_templates_aggregate.aggregate?.count}
          label="Audience templates"
          page={page}
          rowsPerPage={pageSize}
          setPage={setPage}
        />
      </Row>
    </Column>
  );
};
