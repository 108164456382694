// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import {
  NavigateFunction,
  NavigateOptions,
  To,
  useParams,
  useLocation,
  useMatch as useRRMatch,
  useOutletContext,
  useSearchParams,
  Outlet,
  Route,
  Routes,
  createSearchParams,
  NavigateProps,
  Link as RRLink,
  useNavigate as RRuseNavigate,
  Navigate as RRNavigate,
  LinkProps as RRLinkProps,
  MemoryRouter,
  generatePath,
  useNavigation,
  RouteObject,
  LoaderFunction,
  PathPattern,
} from "react-router-dom";
import { forwardRef, useCallback } from "react";
import {
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  Link as HTLink,
  // eslint-disable-next-line @typescript-eslint/no-restricted-imports
  LinkButton as HTLinkButton,
  LinkButtonProps as HTLinkButtonProps,
  LinkProps as HTLinkProps,
} from "@hightouchio/ui";

// Modify paths to include workspace slug when available
const modifyToWithWorkspace = (
  to: To | number,
  root_workspace_slug: string | undefined,
): To | number => {
  if (typeof to === "number") {
    return to;
  }
  if (!root_workspace_slug) {
    return to;
  }
  if (typeof to === "string") {
    if (to.startsWith("/")) {
      return `/${root_workspace_slug}${to}`;
    }
    return to;
  }
  if (to?.pathname?.startsWith("/")) {
    return { ...to, pathname: `/${root_workspace_slug}${to.pathname}` };
  }
  return to;
};

const useNavigate = () => {
  const navigate = RRuseNavigate();
  const { root_workspace_slug } = useParams<{ root_workspace_slug: string }>();

  const navigateWithWorkspace: NavigateFunction = useCallback(
    (to: To | number, options: NavigateOptions = {}) => {
      const processedTo = modifyToWithWorkspace(to, root_workspace_slug);
      if (typeof processedTo === "number") {
        return navigate(processedTo);
      }
      return navigate(processedTo, options);
    },
    [navigate, root_workspace_slug],
  );

  return navigateWithWorkspace;
};

const useMatch = (path: string | PathPattern<string>) => {
  const { root_workspace_slug } = useParams<{ root_workspace_slug: string }>();
  const match = useRRMatch(`/${root_workspace_slug}${path}`);

  return match;
};

const Navigate = ({ to, ...props }: NavigateProps) => {
  const { root_workspace_slug } = useParams<{ root_workspace_slug: string }>();
  return (
    <RRNavigate
      to={modifyToWithWorkspace(to, root_workspace_slug) as To}
      {...props}
    />
  );
};

const RouterLink = forwardRef<
  HTMLAnchorElement,
  RRLinkProps & { preload?: "mount" | "intent" }
>(({ to, preload, ...props }, ref) => {
  const { root_workspace_slug } = useParams<{
    root_workspace_slug: string;
  }>();

  return (
    <RRLink
      ref={ref}
      to={modifyToWithWorkspace(to, root_workspace_slug) as To}
      {...props}
    />
  );
});

RouterLink.displayName = "RouterLink";

type AnchorProps = {
  target?: string;
  rel?: string;
  download?: string;
};

const LinkButton = forwardRef<
  HTMLAnchorElement,
  HTLinkButtonProps & AnchorProps
>(({ href, ...props }, ref) => {
  // If the button is disabled, we don't want to use the RouterLink,
  // because that will still allow a navigation event to be triggered.
  // So instead fallback to a normal HTUI LinkButton when disabled.
  if (props.isDisabled) {
    return <HTLinkButton href={href} {...props} ref={ref} />;
  }

  // @ts-expect-error to is RouterLink prop
  return <HTLinkButton as={RouterLink} to={href} {...props} ref={ref} />;
});

LinkButton.displayName = "LinkButton";

const Link = forwardRef<HTMLAnchorElement, HTLinkProps & AnchorProps>(
  ({ href, ...props }, ref) => {
    // @ts-expect-error to is RouterLink prop
    return <HTLink as={RouterLink} to={href} ref={ref} {...props} />;
  },
);

Link.displayName = "Link";

export {
  Route,
  Routes,
  useSearchParams,
  useParams,
  useLocation,
  Outlet,
  useOutletContext,
  createSearchParams,
  MemoryRouter,
  generatePath,
  useNavigation,

  // Modified for workspace slug
  Link,
  LinkButton,
  RouterLink,
  useNavigate,
  Navigate,
  useMatch,
};

export type {
  To,
  NavigateOptions,
  NavigateFunction,
  RouteObject,
  LoaderFunction,
};
