import * as Yup from "yup";
import pluralize from "pluralize";
import { yupResolver } from "@hookform/resolvers/yup";
import merge from "lodash/merge";

import {
  IntervalSchedule,
  Schedule,
  ScheduleType,
  VisualCronSchedule,
} from "src/components/schedule/types";
import {
  convertIntervalToMinutes,
  findMinimumGapInMinutesAcrossVisualCronSchedule,
  isIntervalLessThan,
  JourneyInterval,
} from "./utils";
import { formatDurationDisplay } from "src/utils/match-boosting";
import {
  JourneySchema,
  JourneySettingSchema,
  LegacyTimeDelayFormSchema,
  LegacyWaitUntilEventFormSchema,
  SplitsFormSchema,
  TimeDelayFormSchema,
  WaitUntilEventFormSchema,
} from "./validation-schemas";
import {
  JourneyNodeType,
  type WaitUntilEventConfig,
  type TimeDelayConfig,
  SplitsConfig,
  JourneyExitCriteriaConfig,
} from "src/types/journeys";
import type {
  JourneyGraph,
  JourneyNodeDetails,
  SplitBranch,
} from "src/pages/journeys/types";
import { ResolverOptions } from "react-hook-form";

type JourneyFormContext = {
  useLegacyJourneysTimeMinimum?: boolean;
  currentJourneySchedule: IntervalSchedule | VisualCronSchedule;
};

/**
 * This file contains custom validation resolvers when we need to do additional state-aware
 * validation for a form.
 */

export const validateIntervalScheduleDuration = (
  duration: JourneyInterval,
  schedule: Schedule,
): { isValid: boolean; message: string | null } => {
  const scheduleInterval = (schedule as IntervalSchedule)?.schedule?.interval;

  if (scheduleInterval != null) {
    // If we have a journey with an interval schedule, verify that
    // the current time delay is at least the journey's schedule cadence.
    const durationLessThanCadence = isIntervalLessThan(
      duration,
      scheduleInterval as unknown as JourneyInterval,
    );

    if (durationLessThanCadence) {
      return {
        isValid: false,
        message: `Duration must be greater than or equal to the journey schedule interval of ${scheduleInterval.quantity} ${pluralize(scheduleInterval.unit, scheduleInterval.quantity)}.`,
      };
    }
  }

  return { isValid: true, message: null };
};

export const validateVisualCronScheduleDuration = (
  duration: JourneyInterval,
  schedule: Schedule,
): { isValid: boolean; message: string | null } => {
  const nodeIntervalInMinutes = convertIntervalToMinutes(duration);
  const scheduleMinimumGap =
    findMinimumGapInMinutesAcrossVisualCronSchedule(schedule);

  if (nodeIntervalInMinutes < scheduleMinimumGap) {
    const formattedDuration = formatDurationDisplay({
      start: 0,
      end: scheduleMinimumGap * 60 * 1000, // minute -> ms
    });

    return {
      isValid: false,
      message: `Duration must be greater than or equal to the journey schedule minimum window of ${formattedDuration}.`,
    };
  }

  return { isValid: true, message: null };
};

export const journeyTimeDelaySchemaResolver = async (
  data: JourneyNodeDetails<TimeDelayConfig>,
  context: JourneyFormContext,
  options: ResolverOptions<JourneyNodeDetails<TimeDelayConfig>>,
) => {
  if (context?.useLegacyJourneysTimeMinimum) {
    // All journeys going forward should have 5-minute minimum time values. Only a handful
    // of journeys have been grandfathered in to allow for the 1-minute values.
    return await yupResolver(LegacyTimeDelayFormSchema)(data, context, options);
  }

  const { values, errors } = await yupResolver(TimeDelayFormSchema)(
    data,
    context,
    options,
  );

  const mappingErrors: typeof errors = { ...errors };
  const currentSchedule: Schedule = context?.currentJourneySchedule;

  if (currentSchedule?.type === ScheduleType.INTERVAL) {
    const { isValid, message } = validateIntervalScheduleDuration(
      data.config.delay,
      currentSchedule,
    );

    if (!isValid) {
      mappingErrors.config = mappingErrors.config || {};
      mappingErrors.config.delay = mappingErrors.config.delay || {};
      mappingErrors.config.delay.quantity = {
        message,
      };
    }
  } else if (currentSchedule?.type === ScheduleType.CUSTOM) {
    // If we have a journey with an custom (visual cron) schedule, find the minimum cadence
    // and then check that the wait until timeout is at least the minimum cadence.
    const { isValid, message } = validateVisualCronScheduleDuration(
      data.config.delay,
      currentSchedule,
    );

    if (!isValid) {
      mappingErrors.config = mappingErrors.config || {};
      mappingErrors.config.delay = mappingErrors.config.delay || {};
      mappingErrors.config.delay.quantity = {
        message,
      };
    }
  }

  return {
    values,
    errors: { ...errors, ...mappingErrors },
  };
};

export const journeyWaitUntilSchemaResolver = async (
  data: JourneyNodeDetails<WaitUntilEventConfig>,
  context: JourneyFormContext,
  options: ResolverOptions<JourneyNodeDetails<WaitUntilEventConfig>>,
) => {
  if (context?.useLegacyJourneysTimeMinimum) {
    // All journeys going forward should have 5-minute minimum time values. Only a handful
    // of journeys have been grandfathered in to allow for the 1-minute values.
    return await yupResolver(LegacyWaitUntilEventFormSchema)(
      data,
      context,
      options,
    );
  }

  const { values, errors } = await yupResolver(WaitUntilEventFormSchema)(
    data,
    context,
    options,
  );

  const mappingErrors: typeof errors = { ...errors };

  const currentSchedule: Schedule = context?.currentJourneySchedule;
  if (currentSchedule?.type === ScheduleType.INTERVAL) {
    const scheduleInterval = (currentSchedule as IntervalSchedule)?.schedule
      ?.interval;

    if (scheduleInterval != null) {
      const { isValid, message } = validateIntervalScheduleDuration(
        data.config.timeout_duration,
        currentSchedule,
      );

      if (!isValid) {
        mappingErrors.config = mappingErrors.config || {};
        mappingErrors.config.timeout_duration =
          mappingErrors.config.delay || {};
        mappingErrors.config.timeout_duration.quantity = {
          message,
        };
      }
    }
  } else if (currentSchedule?.type === ScheduleType.CUSTOM) {
    // If we have a journey with an custom (visual cron) schedule, find the minimum cadence
    // and then check that the wait until timeout is at least the minimum cadence.
    const { isValid, message } = validateVisualCronScheduleDuration(
      data.config.timeout_duration,
      currentSchedule,
    );

    if (!isValid) {
      mappingErrors.config = mappingErrors.config || {};
      mappingErrors.config.delay = mappingErrors.config.delay || {};
      mappingErrors.config.delay.quantity = {
        message,
      };
    }
  }

  return {
    values,
    errors: { ...errors, ...mappingErrors },
  };
};

export const journeySchemaResolver = async (
  data: JourneyGraph,
  context: JourneyFormContext,
  options,
) => {
  const { values, errors } = await yupResolver(JourneySchema)(
    data,
    context,
    options,
  );

  if (context?.useLegacyJourneysTimeMinimum) {
    // Legacy
    return { values, errors };
  }

  const mappingErrors: typeof errors = { ...errors };

  if (Array.isArray(data?.nodes)) {
    for (let index = 0; index < data.nodes.length; index++) {
      const node = data.nodes[index];
      const type = node?.type as JourneyNodeType;
      let nodeErrors = {};

      if (node && type === JourneyNodeType.TimeDelay) {
        ({ errors: nodeErrors } = await journeyTimeDelaySchemaResolver(
          node.data as JourneyNodeDetails<TimeDelayConfig>,
          context,
          options,
        ));
      } else if (node && type === JourneyNodeType.WaitUntilEvent) {
        ({ errors: nodeErrors } = await journeyWaitUntilSchemaResolver(
          node.data as JourneyNodeDetails<WaitUntilEventConfig>,
          context,
          options,
        ));
      }

      if (Object.keys(nodeErrors).length > 0) {
        // Ensure mappingErrors.nodes is an array
        if (!mappingErrors.nodes) {
          mappingErrors.nodes = [];
        }
        if (!mappingErrors.nodes[index]) {
          mappingErrors.nodes[index] = {};
        }
        if (!mappingErrors.nodes[index]?.data) {
          mappingErrors.nodes[index].data = {};
        }

        // Merge nodeErrors into the specific index
        mappingErrors.nodes[index] = {
          ...mappingErrors.nodes[index].data,
          ...nodeErrors, // Use nested node error if available
        };
      }
    }
  }

  return {
    values,
    errors: { ...errors, ...mappingErrors },
  };
};

export const journeySplitConfigValidationResolver = async (
  data: {
    node: JourneyNodeDetails<SplitsConfig>;
    splits: SplitBranch[];
  },
  context: JourneyFormContext,
  options: ResolverOptions<{
    node: JourneyNodeDetails<SplitsConfig>;
    splits: SplitBranch[];
  }>,
) => {
  const { values, errors } = await yupResolver(SplitsFormSchema)(
    data,
    context,
    options,
  );

  const mappingErrors: typeof errors = {};

  if (errors?.splits) {
    mappingErrors.splits = new Array(data.splits.length);
    if (errors?.splits?.type == "percentages-check") {
      data?.splits.forEach((_, index) => {
        mappingErrors.splits[index] = {
          percentage: { message: errors?.splits?.message },
        };
      });
    }
    if (errors?.splits?.type == "unique-name-check") {
      const splitGroupNameSet = new Map<string, number>();

      data.splits.forEach((split, index) => {
        if (splitGroupNameSet.has(split.name)) {
          mappingErrors.splits[index] = {
            name: { message: errors?.splits?.message },
          };

          const otherIndex = splitGroupNameSet.get(split.name);
          if (otherIndex != null) {
            mappingErrors.splits[otherIndex] = {
              name: { message: errors?.splits?.message },
            };
          }
        }

        splitGroupNameSet.set(split.name, index);
      });
    }
  }

  return {
    values: Object.keys(mappingErrors).length > 0 ? {} : values,
    errors: merge(errors, mappingErrors),
  };
};

export const journeySettingValidationResolver = async (
  data: {
    schedule: Schedule;
    exitCriteria: JourneyExitCriteriaConfig;
  },
  context: JourneyFormContext,
  options: ResolverOptions<{
    schedule: Schedule;
    exitCriteria: JourneyExitCriteriaConfig;
  }>,
) => {
  let schemaToUse: Yup.ObjectSchema = JourneySettingSchema;
  if (context?.useLegacyJourneysTimeMinimum) {
    // All journeys going forward should have 5-minute minimum time values. Only a handful
    // of journeys have been grandfathered in to allow for the 1-minute values. We did not have
    // any additional Journey-settings validation.
    schemaToUse = Yup.object({});
  }

  return await yupResolver(schemaToUse)(data, context, options);
};
