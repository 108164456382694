import { PropsWithChildren } from "react";

import { Box, Skeleton } from "@hightouchio/ui";

import { Card } from "src/components/card";

type StatsRowProps = PropsWithChildren<{
  isLoading?: boolean;
}>;

export const StatsRow = ({ isLoading = false, ...props }: StatsRowProps) => {
  return (
    <Skeleton isLoading={isLoading}>
      <Box
        {...props}
        as={Card}
        flexDirection="row"
        py={4}
        px={0}
        display={["grid", "grid", "grid", "grid", "flex"]}
        gridTemplateColumns={[
          "repeat(auto-fit, minmax(160px, 1fr))",
          "repeat(auto-fit, minmax(160px, 1fr))",
          "repeat(auto-fit, minmax(160px, 1fr))",
          "repeat(auto-fit, minmax(160px, 1fr))",
          "none",
        ]}
      />
    </Skeleton>
  );
};

export const StatBorderLine = () => {
  return (
    <Box
      display={["none", "none", "none", "none", "block"]}
      w="1px"
      bg="base.border"
    />
  );
};
