import orderBy from "lodash/orderBy";
import partition from "lodash/partition";
import uniq from "lodash/uniq";
import * as Yup from "yup";

import {
  AudienceSplit,
  Column,
  SplitSamplingType,
  VisualQueryFilter,
  Audience,
} from "src/types/visual";

import { transformSplitGroups } from "./utils";

export type FormData = {
  isEnabled: boolean;
  groupColumnName: string;
  samplingType: string;
  splits: AudienceSplit[];
  stratificationVariables: Column[];
};

export const getDefaultFormValues = (audience: Audience): FormData => {
  const splitTestDefinition = (
    audience?.visual_query_filter as VisualQueryFilter
  ).splitTestDefinition;
  const splits = audience?.splits || [];

  // If splits are enabled, transform the existintg data into frontend-friendly values.
  // Then, populate the form.
  if (splitTestDefinition && splits.length) {
    const transformedSplits = transformSplitGroups(splits);
    const [holdoutGroups, treatmentGroups] = partition(
      transformedSplits,
      (split) => split.is_holdout_group,
    );

    // Sort the splits, so that the holdout group is first in the list (i.e., at the top of the UI).
    // If a holdout group hasn't been persisted in the backend, add it to our list.
    const orderedSplits = orderBy(
      holdoutGroups.length
        ? transformedSplits
        : [
            createHoldoutGroup({ enabled: false, percentage: 0 }),
            ...treatmentGroups,
          ],
      ["is_holdout_group"],
      ["desc"],
    );

    return {
      isEnabled: true,
      groupColumnName: splitTestDefinition.groupColumnName,
      samplingType: splitTestDefinition.samplingType,
      splits: orderedSplits,
      stratificationVariables: splitTestDefinition.stratificationVariables,
    };
  }

  // Splits is not enabled
  return createInitialFormValues(audience);
};

const createInitialFormValues = (audience: Audience): FormData => {
  return {
    isEnabled: false,
    groupColumnName: "split_column",
    samplingType: SplitSamplingType.NonStratified,
    splits: [
      createHoldoutGroup({ enabled: true, percentage: 20 }),
      createTreatmentGroup({
        friendlyName: "Treatment",
        percentage: 80,
        destinationInstanceIds:
          audience?.syncs.map(({ id }) => Number(id)) ?? [], // assign all syncs to this treatment group
      }),
    ],
    stratificationVariables: [],
  };
};

const createHoldoutGroup = (opts: {
  enabled: boolean;
  percentage: number;
}): AudienceSplit => ({
  friendly_name: "Holdout group",
  is_holdout_group: true,
  percentage: opts.percentage,
  destination_instance_ids: [],
});

export const createTreatmentGroup = (opts: {
  friendlyName: string;
  percentage: number;
  destinationInstanceIds: number[];
}): AudienceSplit => ({
  friendly_name: opts.friendlyName,
  is_holdout_group: false,
  percentage: opts.percentage,
  destination_instance_ids: opts.destinationInstanceIds,
});

const VALIDATE_MIN_PERCENTAGE = "validate_min_percentage";
const VALIDATE_TOTAL_PERCENTAGE = "validate_total_percentage";
const VALIDATE_UNIQUE_SPLIT_GROUP_NAMES = "validate_unique_split_group_names";

export const getValidationSchema = (
  connectionSourceType: string | undefined,
) => {
  return Yup.object().shape({
    groupColumnName: splitColumnNameValidator(connectionSourceType),
    samplingType: Yup.string()
      .required()
      .test(
        "validate-stratified-sampling-type",
        "Splits must be assigned to the same sync",
        function (value) {
          // Stratified sampling is only allowed if each split is assigned to no more than one sync
          // and there is one uniquely assigned sync across all splits.
          if (value !== "stratified") {
            return true;
          }

          const allAssignedDestinationInstanceIds = (
            this.parent.splits?.map(
              (split: AudienceSplit) => split.destination_instance_ids,
            ) || []
          ).flatMap((syncIds: number) => syncIds);

          const uniqueDestinationInstanceIds = uniq(
            allAssignedDestinationInstanceIds,
          );

          return uniqueDestinationInstanceIds.length <= 1;
        },
      ),
    splits: Yup.array()
      .of(
        Yup.object().shape({
          friendly_name: Yup.string().required("Split group name required"),
          percentage: Yup.number()
            .typeError("Split percent must be a number")
            .test(
              VALIDATE_MIN_PERCENTAGE,
              "Split percent must be between 1 and 99",
              function (percentage) {
                return this.parent.is_holdout_group
                  ? Number(percentage) >= 0
                  : Number(percentage) >= 1;
              },
            )
            .max(100, "Split percent must be less than 1 and 100")
            .required("Split percent is required"),
          destination_instance_ids: Yup.array().of(Yup.number()),
        }),
      )
      .test(
        VALIDATE_TOTAL_PERCENTAGE,
        "Split percentage must add up to 100%",
        (splits) => {
          const totalPercentage = splits?.reduce(
            (sum, split) =>
              sum + ((split as { percentage?: number })?.percentage || 0),
            0,
          );
          return totalPercentage === 100;
        },
      )
      .test(
        VALIDATE_UNIQUE_SPLIT_GROUP_NAMES,
        "Split group names must be unique",
        (splits) => {
          if (!splits) return false;
          const validSplits = splits.filter(
            (split): split is AudienceSplit => split != null,
          );

          return (
            uniq(validSplits.map((split) => split.friendly_name)).length ===
            validSplits.length
          );
        },
      ),
    stratificationVariables: Yup.array().of(
      Yup.mixed()
        .transform((variableObj) =>
          Object.keys(variableObj).length ? variableObj : undefined,
        )
        .required("Stratification variable required"),
    ),
  });
};

const splitColumnNameValidator = (connectionSourceType: string | undefined) => {
  const shared = Yup.string().required("Column name required");
  switch (connectionSourceType) {
    // Note that BigQuery is especially sensitive to special characters in column
    // names, so we disallow them here. The backend should also be robust to
    // this, but it's better if we don't let the user enter them in the first place.
    case "bigquery":
      return shared.test(
        "validate-column-name-characters",
        "Split column name must only contain alphanumeric characters and underscores",
        (value) => {
          return !value?.match(/[^a-zA-Z0-9_]/g);
        },
      );
    case "databricks":
      return shared.test(
        "validate-column-name-characters",
        "Databricks column must not contain any of the following characters ' ,;{}()\\n\\t='",
        (value) => {
          return !value?.match(/[ ,;{}()\n\t=]/g);
        },
      );
    default:
      return shared;
  }
};
