import { useState } from "react";
import { useDebounce } from "use-debounce";
import {
  useColumnSuggestionsQuery,
  useColumnSuggestionsV2Query,
  useTraitSuggestionsQuery,
} from "src/graphql";
import { useFlags } from "launchdarkly-react-client-sdk";

export function useFetchSuggestions(
  args: {
    modelId: string | undefined;
    columnName: string | undefined;
    traitId?: string | undefined;
    query?: string | undefined;
  },
  options: { enabled?: boolean } = { enabled: true },
): {
  suggestions: Array<{
    value: string | number | boolean | null;
    count: number | undefined;
  }>;
  loadingSuggestions: boolean;
  onSearchUpdate: (search: string) => void;
} {
  const { enabled } = options;
  const isValid = !!args.modelId && !!args.columnName;
  const isTrait = !!args.traitId;
  const [search, setSearch] = useState<string>("");
  const { enableNewColumnSuggestionsReads } = useFlags();
  const [debouncedSearch, { isPending: isSearchPending }] = useDebounce(
    search,
    // Debounce time in milliseconds.
    500,
  );

  const { data: newColumnSuggestionsData, isLoading: newLoadingSuggestions } =
    useColumnSuggestionsV2Query(
      {
        modelId: args.modelId?.toString() ?? "",
        columnName: args.columnName ?? "",
        // Empty string is a valid query grabbing the top values with no
        // filters applied.
        query: debouncedSearch ?? "",
      },
      {
        enabled:
          enableNewColumnSuggestionsReads && enabled && isValid && !isTrait,
      },
    );

  const { data: oldColumnSuggestionsData, isLoading: oldLoadingSuggestions } =
    useColumnSuggestionsQuery(
      {
        modelIds: args.modelId ? [args.modelId.toString()] : [],
        columnNames: args.columnName ? [args.columnName?.toString()] : [],
      },
      {
        enabled:
          !enableNewColumnSuggestionsReads && enabled && isValid && !isTrait,
      },
    );

  const { data: traitSuggestionsData, isLoading: traitSuggestionsLoading } =
    useTraitSuggestionsQuery(
      {
        traitId: args.traitId?.toString() ?? "",
        query: debouncedSearch ?? "",
      },
      {
        enabled: enabled && isValid && isTrait,
      },
    );

  const loadingSuggestions = isTrait
    ? traitSuggestionsLoading
    : enableNewColumnSuggestionsReads
      ? newLoadingSuggestions
      : oldLoadingSuggestions;

  const suggestions = isTrait
    ? traitSuggestionsData?.topKForTrait?.values
    : enableNewColumnSuggestionsReads
      ? newColumnSuggestionsData?.topKForQuery?.values
      : oldColumnSuggestionsData?.getTopK?.columns?.find(
          (column) =>
            column.modelId === args.modelId && column.name === args.columnName,
        )?.values;

  return {
    suggestions: suggestions ?? [],
    loadingSuggestions: loadingSuggestions || isSearchPending(),
    onSearchUpdate: setSearch,
  };
}
