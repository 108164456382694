import { FC, useCallback, useEffect, useRef } from "react";

import {
  Box,
  Column,
  DeleteIcon,
  IconButton,
  InformationIcon,
  NumberInput,
  Row,
  Switch,
  BadgeInput,
  Tooltip,
} from "@hightouchio/ui";

import { AudienceSplit } from "src/types/visual";
import { ConnectionNode } from "./connection-node";
import { SplitGroupName } from "./split-group-name";
import {
  getSplitGroupNodeId,
  getSyncIdsForSplit,
  getSyncNodeId,
  isSplitGroupEnabled,
  sharedNodeStyles,
} from "./utils";

type Props = {
  isHighlighted: boolean;
  split: AudienceSplit;
  syncOptions: {
    label: string;
    logo: string;
    status: string | null;
    value: number;
  }[];
  onDelete: (() => void) | undefined;
  onMouseEnter: (nodeIds: string[]) => void;
  onMouseLeave: () => void;
  onNodeResize: ({
    nodeId,
    align,
  }: {
    nodeId: string;
    align: "left" | "right";
  }) => void;
  onToggleHoldoutGroup: (enable: boolean, holdoutGroup: AudienceSplit) => void;
  onUpdate: (updates: Partial<AudienceSplit>) => void;
};

export const SplitGroupNode: FC<Readonly<Props>> = ({
  isHighlighted,
  split,
  syncOptions,
  onDelete,
  onMouseEnter,
  onMouseLeave,
  onNodeResize,
  onToggleHoldoutGroup,
  onUpdate,
}) => {
  const elem = useRef(null);

  const nodeId = getSplitGroupNodeId(split);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      onNodeResize({ nodeId, align: "left" });
    });

    if (elem.current) {
      resizeObserver.observe(elem.current);
    }

    return () => resizeObserver.disconnect();
  }, [onNodeResize]);

  const updatePercentage = (ratio: number | undefined) => {
    if (ratio == undefined) return;

    onUpdate({ percentage: ratio * 100 });
  };

  const isDisabled = !isSplitGroupEnabled(split);

  // Return ID of this split group and its connected syncs
  const getConnectedNodeIds = useCallback(
    () => [nodeId, ...getSyncIdsForSplit(split).map((id) => getSyncNodeId(id))],
    [nodeId, split.destination_instance_ids],
  );

  // Only show the connection node if it's connected to any syncs
  const showConnectionNode =
    split.destination_instance_ids.length > 0 ||
    (split.is_holdout_group && split.percentage > 0);

  return (
    <Column
      {...sharedNodeStyles(isHighlighted)}
      ref={elem}
      onMouseEnter={() => onMouseEnter(getConnectedNodeIds())}
      onMouseLeave={onMouseLeave}
    >
      <Box
        alignItems="center"
        display="grid"
        gridTemplateColumns="64px 1fr min-content"
        columnGap={4}
        p={4}
        position="relative"
      >
        <NumberInput
          formatOptions={{ style: "percent" }}
          min={split.is_holdout_group ? 0 : 0.01}
          max={0.99}
          value={split.percentage / 100}
          width="100%"
          onChange={updatePercentage}
        />
        <Row alignItems="center" gap={1}>
          <SplitGroupName
            name={split.friendly_name}
            isDisabled={isDisabled}
            isEditable={!split.is_holdout_group}
            onSubmit={(value) => onUpdate({ ...split, friendly_name: value })}
          />
          {split.is_holdout_group && (
            <Tooltip message="Holdout group members are not synced to destinations but can be viewed in the audience snapshots in your data warehouse.">
              <InformationIcon color="text.secondary" />
            </Tooltip>
          )}
        </Row>

        {split.is_holdout_group ? (
          <Switch
            aria-label="Toggle holdout group."
            isChecked={split.percentage > 0}
            onChange={(enabled) => onToggleHoldoutGroup(enabled, split)}
          />
        ) : (
          onDelete && <TrashButton onClick={onDelete} />
        )}

        {showConnectionNode && (
          <Box
            id={nodeId}
            position="absolute"
            right={0}
            top={split.is_holdout_group ? "50%" : "100%"}
            transform="translate(50%, -50%)"
          >
            <ConnectionNode />
          </Box>
        )}
      </Box>

      {!split.is_holdout_group && (
        <Column borderTop="1px solid" borderColor="base.border" p={4}>
          <BadgeInput
            options={syncOptions}
            optionIconSrc={(option) => option.logo}
            placeholder="Assign syncs..."
            value={split.destination_instance_ids}
            width="100%"
            onChange={(updates) =>
              onUpdate({ destination_instance_ids: updates })
            }
          />
        </Column>
      )}
    </Column>
  );
};

const TrashButton = ({ onClick }: { onClick: () => void }) => (
  <Tooltip message="Delete split group">
    <IconButton
      aria-label="Delete split group"
      icon={DeleteIcon}
      variant="danger"
      onClick={onClick}
    />
  </Tooltip>
);
