import { FC } from "react";

import { Alert } from "@hightouchio/ui";

import { useUser } from "src/contexts/user-context";
import { getHightouchRegionConfig } from "src/utils/regions";

const joinIpAddresses = (ipAddresses: string[]): string => {
  return ipAddresses
    .map((ipAddress, index) => {
      const isLast = index === ipAddresses.length - 1;
      return isLast ? `or ${ipAddress}` : ipAddress;
    })
    .join(", ");
};

export const IPWhitelistMessage: FC = () => {
  const { region } = useUser();

  return (
    <Alert
      variant="inline"
      type="info"
      title="Make sure to allow inbound traffic from Hightouch IP addresses"
      message={`Hightouch will connect to your source using ${joinIpAddresses(
        getHightouchRegionConfig(region).ipAddresses,
      )}.`}
    />
  );
};
