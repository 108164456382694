import {
  Column,
  Row,
  SectionHeading,
  Text,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Tooltip,
  InformationIcon,
  Badge,
  IconButton,
  ChevronRightIcon,
  ChevronDownIcon,
} from "@hightouchio/ui";
import { useMemo, useState } from "react";
import { capitalize, shuffle } from "lodash";

import { Card } from "src/components/card";
import { InteractionRow } from "./messages";
import { OutletContext } from "src/pages/decision-engines";
import {
  frequencyLabel,
  timeRangeLabel,
} from "src/pages/decision-engines/flows/flow/timing";
import { Table } from "src/ui/table";
import { ChannelOptionsMap } from "src/pages/decision-engines/utils";
import { useSearchParams } from "src/router";
import { randomItem, randomNumber } from "src/utils/random";

export const MOCK_FEATURES = [
  { name: "days_since_last_purchase", values: ["0-7", "7-30", "30+"] },
  { name: "engagement_score", values: ["High", "Medium"] },
  {
    name: "email_open_rate",
    values: ["High", "Medium"],
  },
  {
    name: "email_click_rate",
    values: ["High", "Medium"],
  },
  {
    name: "email_conversion_rate",
    values: ["High", "Medium"],
  },
  {
    name: "lifetime_value",
    values: ["High", "Medium"],
  },
  {
    name: "state",
    values: ["CA", "TX", "NY", "FL", "MI", "IL"],
  },
  {
    name: "discount_sensitivity",
    values: ["High", "Medium"],
  },
  {
    name: "last_page_viewed",
    values: [
      "Home",
      "Promotion",
      "Wishlist",
      "Product - Activators",
      "Product - In sync",
    ],
  },
  { name: "last_purchased_material", values: ["Wood", "Metal", "Plastic"] },
  { name: "last_purchased_color", values: ["Red", "Blue", "Green", "White"] },
];

const MESSAGE_RELEVANT_FEATURES = [
  "email_conversion_rate",
  "age",
  "gender",
  "last_page_viewed",
  "discount_sensitivity",
];

const CONTENT_RELEVANT_FEATURES = [
  "age",
  "gender",
  "engagement_score",
  "last_page_viewed",
  "lifetime_value",
];

const COLLECTION_RELEVANT_FEATURES = [
  "age",
  "gender",
  "last_purchased_material",
  "last_purchased_color",
  "state",
];

const getUserAttributes = () => {
  const age = randomNumber(18, 65);
  const gender = randomItem(["Male", "Female"]);

  const features = MOCK_FEATURES.map((feature) => {
    return {
      name: feature.name,
      value: shuffle(feature.values)[0]!,
    };
  });

  return [
    {
      name: "age",
      value: age,
    },
    {
      name: "gender",
      value: gender,
    },
    ...features,
  ];
};

export const Reasoning = ({
  interaction,
  message,
  channel,
}: {
  interaction: InteractionRow;
  message: {
    name: string;
  };
  channel: OutletContext["channels"][number];
}) => {
  const { variables, ...collections } = interaction.sync_metadata ?? {};
  const [params] = useSearchParams();
  const messageId = params.get("message");

  // Only re-generate attributes when the messageId changes
  const attributes = useMemo(() => getUserAttributes(), [messageId]);

  return (
    <Column gap={4}>
      <UserAttributes attributes={attributes} />
      <Card gap={4}>
        <Box>
          <SectionHeading>Decisions</SectionHeading>
          <Text color="text.secondary">
            To make each decision, the model determines which option has
            historically led to the best outcome for this user and users with
            similar attributes.
          </Text>
        </Box>
        <Tabs>
          <TabList>
            <Tab>Message</Tab>
            <Tab>Delivery</Tab>
            <Tab>Content</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ReasoningCard
                title="Message"
                choices={[message.name]}
                attributes={attributes}
                relevantFeatures={MESSAGE_RELEVANT_FEATURES}
              />
            </TabPanel>
            <TabPanel>
              <Column gap={4}>
                {interaction.action_features.frequency_arm && (
                  <ReasoningCard
                    title="Frequency"
                    choices={[
                      frequencyLabel(interaction.action_features.frequency_arm),
                    ]}
                    attributes={attributes}
                    relevantFeatures={[
                      "days_since_last_purchase",
                      "engagement_score",
                      "email_open_rate",
                      "age",
                    ]}
                  />
                )}
                {interaction.action_features.time_of_day && (
                  <ReasoningCard
                    title="Timing"
                    choices={[
                      timeRangeLabel(interaction.action_features.time_of_day),
                    ]}
                    attributes={attributes}
                    relevantFeatures={[
                      "email_open_rate",
                      "age",
                      "email_conversion_rate",
                    ]}
                  />
                )}
                <ReasoningCard
                  title="Channel"
                  choices={[ChannelOptionsMap[channel.type]?.label]}
                  attributes={attributes}
                  relevantFeatures={[
                    "engagement_score",
                    "email_open_rate",
                    "email_conversion_rate",
                  ]}
                />
              </Column>
            </TabPanel>
            <TabPanel>
              <Column gap={4}>
                {Object.entries(variables ?? {}).map(([key, value]) => {
                  if (key === "subject") {
                    return (
                      <ReasoningCard
                        key="subject"
                        title="Subject line"
                        choices={[value]}
                        attributes={attributes}
                        relevantFeatures={[
                          "gender",
                          "age",
                          "state",
                          "lifetime_value",
                          "discount_sensitivity",
                        ]}
                      />
                    );
                  }
                  return (
                    <ReasoningCard
                      key={key}
                      title={key}
                      choices={[value]}
                      attributes={attributes}
                      relevantFeatures={CONTENT_RELEVANT_FEATURES}
                    />
                  );
                })}
                {Object.entries(collections).map(([name, items]) => {
                  return (
                    <ReasoningCard
                      key={name}
                      title={name}
                      choices={items.map((item) => item.name)}
                      attributes={attributes}
                      relevantFeatures={COLLECTION_RELEVANT_FEATURES}
                    />
                  );
                })}
              </Column>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </Column>
  );
};

const UserAttributes = ({
  attributes,
}: {
  attributes: Array<{ name: string; value: string | number }>;
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Card gap={4}>
      <Row justify="space-between" align="center">
        <Box>
          <Row gap={2}>
            <SectionHeading>User attributes</SectionHeading>
            <Badge>{attributes.length}</Badge>
          </Row>
          <Text color="text.secondary">
            {`These attributes inform the AI model of the user's preferences`}
          </Text>
        </Box>
        <IconButton
          icon={expanded ? ChevronDownIcon : ChevronRightIcon}
          aria-label={expanded ? "Collapse" : "Expand"}
          size="lg"
          onClick={() => {
            setExpanded(!expanded);
          }}
        />
      </Row>

      {expanded && (
        <Table
          columns={[
            {
              name: "Attribute",
              cell: ({ name }) => name,
            },
            {
              name: "Value",
              cell: ({ value }) => value,
            },
          ]}
          data={attributes}
        />
      )}
    </Card>
  );
};

const OPTIMIZED_FLOW_IDS = [
  "a1a10224-bd93-47ff-8212-57249e964609",
  "2e7bc26d-e18a-4268-aab3-a1f1744804d1",
];

const ReasoningCard = ({
  title,
  choices,
  attributes,
  relevantFeatures,
}: {
  title: string;
  choices: string[];
  features?: Record<string, string>;
  phase?: "learning" | "optimized";
  attributes?: Array<{ name: string; value: string | number }>;
  relevantFeatures?: string[];
}) => {
  const [params] = useSearchParams();
  const flowId = params.get("flow");
  // Mock data
  const [phase] = useState(
    OPTIMIZED_FLOW_IDS.includes(flowId ?? "")
      ? "optimized"
      : randomItem(["learning", "optimized"]),
  );

  return (
    <Column gap={4}>
      <Row align="center" gap={2}>
        <Text fontWeight="medium">{capitalize(title)}:</Text>
        {choices.map((choice) => (
          <Badge key={choice}>{choice}</Badge>
        ))}
      </Row>
      <Phase
        type={phase as any}
        attributes={
          attributes
            ? attributes?.filter(({ name }) => relevantFeatures?.includes(name))
            : []
        }
      />
    </Column>
  );
};

const Phase = ({
  type,
  attributes,
}: {
  type: "learning" | "optimized";
  attributes?: Array<{ name: string; value: string | number }>;
}) => {
  switch (type) {
    case "optimized":
      return (
        <Column
          gap={4}
          flex={1}
          borderRadius="md"
          p={4}
          bg="linear-gradient(36deg, rgba(124, 23, 235, 0.05) 11.65%, rgba(42, 177, 201, 0.05) 87.12%)"
        >
          <Row gap={1}>
            <Text fontWeight="medium">
              This decision is{" "}
              <Box
                fontWeight="medium"
                as="span"
                sx={{
                  background:
                    "linear-gradient(36deg, #7C17EB 11.65%, #2AB1C9 87.12%)",
                  backgroundClip: "text",
                }}
              >
                Personalized
              </Box>
            </Text>
            <Tooltip message="Personalized decision expected to drive positive outcomes">
              <Box as={InformationIcon} boxSize={4} color="text.secondary" />
            </Tooltip>
          </Row>
          {attributes && attributes.length > 0 && (
            <Column>
              <Text color="text.secondary" mb={2}>
                This user has the following attributes which have historically
                responded well to this decision:
              </Text>
              <Row flexWrap="wrap" m={-1}>
                {attributes.map(({ name, value }) => (
                  <Box key={name} m={1}>
                    <Badge>
                      {name} : {value}
                    </Badge>
                  </Box>
                ))}
              </Row>
            </Column>
          )}
        </Column>
      );
    case "learning":
      return (
        <Column
          gap={4}
          flex={1}
          borderRadius="md"
          p={4}
          bg="linear-gradient(36deg, rgba(41, 154, 13, 0.05) 11.65%, rgba(51, 75, 76, 0.05) 87.12%)"
        >
          <Row gap={1}>
            <Text fontWeight="medium">
              This decision is{" "}
              <Box
                fontWeight="medium"
                as="span"
                sx={{
                  background:
                    "linear-gradient(36deg, #299A0D 11.65%, #334B4C 87.12%)",
                  backgroundClip: "text",
                }}
              >
                Exploratory
              </Box>
            </Text>
            <Tooltip message="Experimenting with this option to gather more data">
              <Box as={InformationIcon} boxSize={4} color="text.secondary" />
            </Tooltip>
          </Row>
        </Column>
      );
    default:
      return null;
  }
};
