import { Column, Row, Text } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { MetadataLabel } from "src/components/metadata-bar";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import type { Flow } from "src/pages/decision-engines/flows";
import { OutcomeClassification } from "src/pages/decision-engines/flows/flow/outcomes/components/outcome-classification";
import { sortOutcomes } from "src/pages/decision-engines/utils";

export const Outcomes = ({ flow }: { flow: Flow }) => {
  const { decisionEngineDemo } = useFlags();

  return (
    <Column overflow="auto">
      <Row align="center" justify="space-between">
        <MetadataLabel>Outcomes</MetadataLabel>
      </Row>
      <Column gap={3}>
        {flow.outcomes
          .sort((a, b) => sortOutcomes(a.outcome.weight, b.outcome.weight))
          .map(({ outcome }) => {
            const lift = Math.round(Math.random() * 50);
            return (
              <Row
                gap={4}
                align="center"
                key={outcome.id}
                overflow="hidden"
                justify="space-between"
              >
                <Row align="center" gap={3}>
                  <OutcomeClassification
                    weight={outcome.weight}
                    showLabel={false}
                  />
                  <TextWithTooltip fontWeight="medium">
                    {outcome.name}
                  </TextWithTooltip>
                </Row>
                {decisionEngineDemo && (
                  <>
                    {outcome.weight.type === "positive" ? (
                      <Text color="success.base" fontWeight="medium">
                        +{lift}%
                      </Text>
                    ) : (
                      <Text color="danger.base" fontWeight="medium">
                        -{lift}%
                      </Text>
                    )}
                  </>
                )}
              </Row>
            );
          })}

        {!flow.outcomes?.length && (
          <Text color="text.tertiary">No outcomes</Text>
        )}
      </Column>
    </Column>
  );
};
