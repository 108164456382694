import { EventContracts } from "./contracts";
import { SchemaIdRedirect } from "./contract/event-schema/schema-id-redirect";
import { RouteObject } from "src/router";
import { ContractLoader } from "./contract/contract";
import { ContractDetails } from "./contract/contract";
import { CreateEventSchema } from "./contract/event-schema/create-event-schema";
import { EventSchema } from "./contract/event-schema/event-schema";

export const routes: Array<RouteObject> = [
  {
    index: true,
    element: <EventContracts />,
  },
  {
    path: "schemas/:event_schema_id",
    element: <SchemaIdRedirect />,
  },
  {
    path: ":id",
    element: <ContractLoader />,
    children: [
      {
        index: true,
        element: <ContractDetails />,
      },
      {
        path: "new_event",
        element: <CreateEventSchema />,
      },
      {
        path: ":event_type_and_name/:event_version",
        element: <EventSchema />,
      },
    ],
  },
];
