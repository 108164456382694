import { FC } from "react";

import { Text } from "@hightouchio/ui";
import { Link } from "src/router";

export const TileCountText: FC<{
  href: string;
  fontWeight: "normal" | "medium" | "semibold" | undefined;
  text: string;
  onClick;
}> = ({ href, fontWeight = "medium", text, onClick }) => {
  return (
    <Link href={href} onClick={onClick}>
      <Text color="inherit" fontWeight={fontWeight}>
        {text}
      </Text>
    </Link>
  );
};
