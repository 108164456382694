import { FC } from "react";

import { Heading, Row } from "@hightouchio/ui";
import { Link, Outlet } from "src/router";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Page } from "src/components/layout";
import { PermissionProvider } from "src/components/permission/permission-context";

import { RouteTabs, useTabState } from "src/components/route-tabs";

enum AudienceSetupPath {
  ParentModels = "parent-models",
  RelatedModels = "related-models",
  Events = "events",
  SyncTemplates = "sync-templates",
}

enum NavigationTab {
  ParentModels = "Parent models",
  RelatedModels = "Related models",
  Events = "Events",
  SyncTemplates = "Sync templates",
  Metrics = "Metrics",
}

const TABS = [
  {
    title: NavigationTab.ParentModels,
    path: AudienceSetupPath.ParentModels,
  },
  {
    title: NavigationTab.RelatedModels,
    path: AudienceSetupPath.RelatedModels,
  },
  {
    title: NavigationTab.Events,
    path: AudienceSetupPath.Events,
  },
  {
    title: NavigationTab.SyncTemplates,
    path: AudienceSetupPath.SyncTemplates,
  },
];

export const SetupAudiences: FC = () => {
  const { schemaV2 } = useFlags();

  const { activeTab } = useTabState(TABS);

  return (
    <PermissionProvider
      permission={{
        v1: {
          resource:
            activeTab?.path === AudienceSetupPath.SyncTemplates
              ? "sync_template"
              : "audience_schema",
          grant: "create",
        },
      }}
    >
      <Page title={`${activeTab?.title} - Schema`}>
        <Row align="center" justify="space-between" mb={4}>
          <Heading size="xl">Schema</Heading>

          {schemaV2 && <Link href="/schema-v2">New schema experience</Link>}
        </Row>

        <RouteTabs tabs={TABS} />

        <Outlet />
      </Page>
    </PermissionProvider>
  );
};
