import { useMemo, useState } from "react";

import { Controller, type SubmitHandler } from "react-hook-form";
import { getNodesWithoutValidations } from "@hightouch/formkit";
import { Column } from "@hightouchio/ui";

import { ProcessFormNode } from "src/formkit/formkit";
import { Form as FormkitForm } from "src/formkit/components/form";
import { FormkitProvider } from "src/formkit/components/formkit-context";
import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { SelectCredential } from "src/components/credentials";
import { Tunnel } from "src/components/sources/setup/form-method";
import { Section } from "src/formkit/components";
import type { EventSourceFormProps } from "./types";

export default function EventSourceFormkitForm({
  id,
  mode,
  source,
  definition,
  onSubmit,
}: EventSourceFormProps) {
  // if `source.tunnelId` is `null`, initialize as `undefined` since we want to default to hiding the tunnel selector
  const [tunnelId, setTunnelId] = useState<string | null | undefined>(
    source.tunnelId ?? undefined,
  );

  // if `source.credentialId` is `null`, initialize as `undefined` since we want to default to hiding the credential selector
  const [credentialId, setCredentialId] = useState<string | null | undefined>(
    source.credentialId ?? undefined,
  );

  const formkitContext = useMemo(
    () => ({
      tunnel: tunnelId,
      credentialId: credentialId ?? undefined,
    }),
    [tunnelId, credentialId],
  );

  const formkit = useMemo(() => {
    if (!definition.form) return;

    // remove validations from form since we're on the frontend
    const [form] = getNodesWithoutValidations([definition.form]);
    if (!form) return;

    if (
      // @ts-expect-error bad typechecking
      form.children[0].size === "small"
    ) {
      return (
        <Section heading={`Configure your ${definition.name} source`}>
          <ProcessFormNode node={form} />
        </Section>
      );
    }
    return <ProcessFormNode node={form} />;
  }, [definition.form]);

  // TODO: re-enable validation once yup version is updated (v0.28 does not work with formkit)
  // const schema = useMemo(() => {
  //   if (!definition.form) return;
  //   return getYupSchemaForForm(definition.form, formkitContext);
  // }, [definition.form, formkitContext]);

  const handleSubmit: SubmitHandler<Record<string, any>> = ({
    _tunnelId, // ignore `_tunnelId` since it's just for dirtying the form state
    _credentialId, // ignore `_credentialId` since it's just for dirtying the form state
    ...config
  }) => {
    return onSubmit({ config, tunnelId, credentialId });
  };

  const form = useHightouchForm({
    // TODO: re-enable validation once yup version is updated (v0.28 does not work with formkit)
    // resolver: yupResolver(schema),
    defaultValues: {
      ...source.config,
    },
    onSubmit: (data) => handleSubmit(data),
  });

  return (
    <Form form={form}>
      <form id={id} onSubmit={form.handleSubmit(handleSubmit)}>
        <FormkitProvider
          {...formkitContext}
          validate={() => true} // defer validation to `useHightouchForm.resolver`
          isSetup={mode === "create"}
        >
          <Column gap={6}>
            <Section
              heading="Choose your connection type"
              subheading={`Hightouch can connect directly to ${definition.name} if it's exposed to the internet. However, if you need to open a connection within a private network or VPC, you will need to set up an SSH tunnel.`}
            >
              {/* Wrap <Tunnel/> in a RHF Controller that way we can dirty the form when tunnel changes are made.
                  Without this, the save button stays disabled when editing a source and changing the tunnel selection. */}
              <Controller
                name="_tunnelId"
                control={form.control}
                render={({ field }) => (
                  <Tunnel
                    name={definition.name}
                    value={tunnelId}
                    onChange={(tunnelId) => {
                      setTunnelId(tunnelId);
                      field.onChange(tunnelId);
                    }}
                  />
                )}
              />
            </Section>

            <Section heading="Configure your credentials">
              <Controller
                name="_credentialId"
                control={form.control}
                render={({ field }) => (
                  <SelectCredential
                    provider={definition.provider}
                    value={credentialId}
                    onChange={(credentialId) => {
                      setCredentialId(credentialId);
                      field.onChange(credentialId);
                    }}
                    isClearable
                  />
                )}
              />
            </Section>

            <FormkitForm>{formkit}</FormkitForm>
          </Column>

          {mode === "edit" && (
            <ActionBar>
              <FormActions
                permission={{
                  v1: { resource: "workspace", grant: "update" },
                  v2: {
                    resource: "workspace",
                    grant: "can_update",
                  },
                }}
              />
            </ActionBar>
          )}
        </FormkitProvider>
      </form>
    </Form>
  );
}
