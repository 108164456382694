import { useFlags } from "launchdarkly-react-client-sdk";
import { Suspense, useState } from "react";

import {
  Box,
  ClickIcon,
  Column,
  EmptyState,
  Row,
  Select,
  Spinner,
  StarIcon,
  Text,
  TimeIcon,
  Tooltip,
} from "@hightouchio/ui";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { DocsLink } from "src/components/docs-link";
import { IconBox } from "src/components/icon-box";
import { Page } from "src/components/layout/page";
import { PageSidebar } from "src/components/layout/page-sidebar";
import {
  useDecisionEngineFlowChartsQuery,
  useMinimalDecisionEngineFlowMessagesQuery,
} from "src/graphql";
import { Link, LinkButton, useOutletContext } from "src/router";
import { formatFriendlyDistanceToNow } from "src/utils/time";
import { DecisionEngine, OutletContext } from "..";
import { GlobalBreakdowns } from "./global-breakdowns";
import { InsightsType } from "./types";

export const InsightsLoader = () => {
  const { decisionInsightsEnabled } = useFlags();
  const { engine } = useOutletContext<OutletContext>();

  // This includes both running and paused agents
  const readyAgents = engine.flows.filter((f) => f.status === "ready");

  if (!readyAgents.length || !decisionInsightsEnabled) {
    return (
      <Page heading="Insights">
        <EmptyState
          imageUrl={genericPlaceholder}
          title="Insights are not available"
          message="Insights will be available once you set up an agent and an outcome"
          actions={
            <LinkButton variant="primary" href="/ai/flows">
              Go to agents
            </LinkButton>
          }
        />
      </Page>
    );
  }

  return <Insights agents={readyAgents} />;
};

type Agent = Pick<DecisionEngine["flows"][0], "id" | "name"> & {
  outcomes: Array<{
    outcome: Pick<
      DecisionEngine["flows"][0]["outcomes"][0]["outcome"],
      "id" | "name"
    >;
  }>;
};

export const Insights = ({ agents }: { agents: Agent[] }) => {
  const [insightsType, setInsightsType] = useState<InsightsType>(
    InsightsType.TopPerformers,
  );
  const [agent, setAgent] = useState<Agent | undefined>(agents[0]);
  const agentId = agent?.id;

  // Get the latest chart for the agent to get the date for when these charts were generated
  // and use the given outcome id as the primary outcome
  // Note: for *now*, we're only generating charts for one outcome (the top ranked positive outcome
  // or an overridden outcome from the admin panel). Later, we will have a dropdown to select
  // any of the outcomes.
  const { data: latestChart } = useDecisionEngineFlowChartsQuery(
    {
      flowId: agentId ?? "",
      orderBy: { created_at: "desc", rank: "desc" },
      limit: 1,
    },
    {
      enabled: Boolean(agentId),
      select: (data) => data.decision_engine_flow_charts[0],
      suspense: true,
    },
  );

  const primaryOutcome = agent?.outcomes.find(
    (o) => o.outcome.id === latestChart?.outcome_id,
  )?.outcome;

  const { data: flowMessages } = useMinimalDecisionEngineFlowMessagesQuery(
    {
      flowId: agentId ?? "",
    },
    {
      enabled: Boolean(agentId),
      select: (data) => data.decision_engine_flow_messages,
    },
  );

  return (
    <Page
      heading="Insights"
      fullWidth
      sidebar={
        <PageSidebar
          header={null}
          collapsible={false}
          contentStyles={{
            height: "100%",
          }}
        >
          <Column p={2} flex={1} justifyContent="space-between">
            <Column gap={4}>
              <Column gap={2}>
                <Text color="text.secondary" fontWeight="medium">
                  Agent
                </Text>
                <Select
                  options={agents.map((a) => ({
                    label: a.name,
                    value: a.id,
                  }))}
                  value={agentId}
                  onChange={(value) => {
                    setAgent(agents.find((a) => a.id === value));
                  }}
                />
                {/** We use `created_at` bc we're always replacing the chart entries when inserting new charts data */}
                {latestChart?.created_at && (
                  <Row align="center" gap={2}>
                    <Box
                      as={TimeIcon}
                      boxSize={4}
                      transform="translateY(-1px)"
                      color="text.secondary"
                    />
                    <Text color="text.secondary" size="sm">
                      Insights last updated{" "}
                      {formatFriendlyDistanceToNow(latestChart.created_at)}
                    </Text>
                  </Row>
                )}
              </Column>
              <Column gap={2}>
                <Text color="text.secondary" fontWeight="medium">
                  Key conversion event
                </Text>
                {primaryOutcome ? (
                  <Tooltip message="This is the top ranked positive outcome">
                    <Link
                      href={`/ai/flows/${agentId}/configuration/outcomes/${primaryOutcome.id}`}
                      display="inline-flex"
                      width="fit-content"
                    >
                      <Row gap={2} align="center">
                        <Box as={ClickIcon} boxSize={5} />
                        {primaryOutcome.name}
                      </Row>
                    </Link>
                  </Tooltip>
                ) : (
                  <Text color="text.secondary">
                    No primary outcome for this agent. Configure a positive
                    ranked outcome to generate insights.
                  </Text>
                )}
              </Column>
              <Column gap={2}>
                <Text color="text.secondary" fontWeight="medium">
                  Views
                </Text>
                <Column gap={2}>
                  <InsightsTypeView
                    icon={<StarIcon />}
                    label={InsightsType.TopPerformers}
                    isSelected={insightsType === InsightsType.TopPerformers}
                    onClick={() => setInsightsType(InsightsType.TopPerformers)}
                  />
                  {/* <InsightsTypeView
                    icon={<BulbSuggestionIcon />}
                    label={InsightsType.PersonalizationInsights}
                    isSelected={
                      insightsType === InsightsType.PersonalizationInsights
                    }
                    onClick={() =>
                      setInsightsType(InsightsType.PersonalizationInsights)
                    }
                  /> */}
                </Column>
              </Column>
            </Column>
            {/* TODO: Update docs link once we have a dedicated docs page for insights */}
            <DocsLink href="ai-decisioning/overview" label="Insights docs" />
          </Column>
        </PageSidebar>
      }
    >
      <Suspense
        fallback={
          <Column flex={1} align="center" justify="center">
            <Spinner size="lg" />
          </Column>
        }
      >
        {!agentId || !primaryOutcome?.id ? (
          <EmptyState
            imageUrl={genericPlaceholder}
            title="Insights are not available"
            message="Insights will be available once you have an active agent and an outcome configured"
          />
        ) : (
          <>
            {insightsType === InsightsType.TopPerformers && (
              <GlobalBreakdowns
                agentId={agentId}
                outcomeId={primaryOutcome.id}
                flowMessages={flowMessages ?? []}
              />
            )}
          </>
        )}
      </Suspense>
    </Page>
  );
};

const InsightsTypeView = ({
  icon,
  label,
  isSelected,
  onClick,
}: {
  icon: React.ReactElement;
  label: InsightsType;
  isSelected: boolean;
  onClick: () => void;
}) => (
  <Row
    h="40px"
    px={4}
    gap={2}
    border="1px solid"
    borderColor={isSelected ? "primary.border" : "transparent"}
    borderRadius="md"
    bg={isSelected ? "primary.background" : "transparent"}
    alignItems="center"
    onClick={onClick}
    cursor="pointer"
  >
    <IconBox
      icon={icon}
      color={isSelected ? "primary.base" : "text.secondary"}
      height="20px"
      hideBorder
    />
    <Text
      color={isSelected ? "primary.base" : "text.secondary"}
      fontWeight={isSelected ? "medium" : "normal"}
    >
      {label}
    </Text>
  </Row>
);
