import { useEntitlements } from "src/hooks/use-entitlement";
import { CIMetrics } from "./ci-metrics";
import { FallbackMetrics } from "./fallback-metrics";
import { useSavedChartsQuery } from "src/graphql/types";

export const Metrics = () => {
  const { data: entitlementsData } = useEntitlements(false);

  const { data: savedCharts } = useSavedChartsQuery(
    {
      filter: { chart_type: { _nin: ["table", "funnel"] } },
    },
    {
      enabled: entitlementsData.entitlements.analytics,
      suspense: true,
      select: (data) => data.saved_analytics_charts,
    },
  );

  if (!entitlementsData.entitlements.analytics || !savedCharts?.length) {
    return <FallbackMetrics />;
  }

  return <CIMetrics />;
};
