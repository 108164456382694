import { useState } from "react";

import {
  CloseIcon,
  Column,
  DrawerBody,
  DrawerHeader,
  IconButton,
  Row,
  Switch,
  Text,
} from "@hightouchio/ui";
import { capitalize, omit } from "lodash";
import { useOutletContext, useParams } from "src/router";

import { TextWithTooltip } from "src/components/text-with-tooltip";
import { NotFound } from "src/pages/not-found";
import { Table } from "src/ui/table";
import { formatDatetime } from "src/utils/time";

import { InspectorOutletContext } from "../types";
import { EventData, InspectorData, ObjectData } from "./inspector-data";

export const InspectorDetails = () => {
  const { onClose } = useOutletContext<InspectorOutletContext>();
  const { htId } = useParams<{ htId?: string }>();

  const [showOnlyGoldRecordValues, setShowOnlyGoldRecordValues] =
    useState(false);

  const row = InspectorData.find((row) => row.ht_id === htId);

  if (!row) {
    return <NotFound />;
  }

  return (
    <>
      <DrawerHeader>
        <Row flex={1} minWidth={0} justify="space-between" align="center">
          <TextWithTooltip fontWeight="medium" size="lg">
            <>HT_ID: {row.ht_id}</>
          </TextWithTooltip>

          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody>
        <Column flex={1} minWidth={0} gap={8} pb={24}>
          <Column gap={4}>
            <Row
              flex={1}
              minWidth={0}
              justify="space-between"
              sx={{
                label: {
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  cursor: "pointer",
                },
              }}
            >
              <Text fontWeight="medium" size="lg">
                Identifiers
              </Text>

              <Text as="label" size="sm" fontWeight="medium">
                <Switch
                  aria-label="Show only golden record values."
                  isChecked={showOnlyGoldRecordValues}
                  onChange={setShowOnlyGoldRecordValues}
                />
                Only show golden record values
              </Text>
            </Row>
            <Table
              data={Object.entries(omit(row, "ht_id"))}
              columns={[
                {
                  name: "Type",
                  cell: ([key]) => (
                    <TextWithTooltip fontWeight="medium">
                      {capitalize(key.split("_").join(" "))}
                    </TextWithTooltip>
                  ),
                },
                {
                  name: "value",
                  cell: ([, value]) => (
                    <Column
                      my={2}
                      sx={{ "& > :first-child": { bg: "warning.200" } }}
                    >
                      {Array.isArray(value) && !showOnlyGoldRecordValues ? (
                        value.map((item) => (
                          <TextWithTooltip key={item}>{item}</TextWithTooltip>
                        ))
                      ) : (
                        <TextWithTooltip>
                          {Array.isArray(value) ? value?.[0] : value}
                        </TextWithTooltip>
                      )}
                    </Column>
                  ),
                },
              ]}
            />
          </Column>

          <Column gap={4}>
            <Text fontWeight="medium" size="lg">
              Events
            </Text>
            <Table
              data={EventData}
              columns={[
                {
                  name: "Model",
                  cell: (row) => (
                    <TextWithTooltip fontWeight="medium">
                      {row.modelName}
                    </TextWithTooltip>
                  ),
                },
                {
                  name: "Latest timestamp",
                  cell: (row) => (
                    <TextWithTooltip>
                      {formatDatetime(row.latestTimestamp) ?? "--"}
                    </TextWithTooltip>
                  ),
                },
                {
                  name: "Count",
                  headerSx: {
                    display: "flex",
                    justifyContent: "flex-end",
                    pr: "4 !important",
                  },
                  cell: (row) => (
                    <Row flex={1} minWidth={0} justify="flex-end">
                      <TextWithTooltip>{row.count}</TextWithTooltip>
                    </Row>
                  ),
                },
              ]}
            />
          </Column>

          <Column gap={4}>
            <Text fontWeight="medium" size="lg">
              Objects
            </Text>

            <Table
              data={ObjectData}
              columns={[
                {
                  name: "Model",
                  cell: (row) => (
                    <TextWithTooltip fontWeight="medium">
                      {row.modelName}
                    </TextWithTooltip>
                  ),
                },
                {
                  name: "Count",
                  headerSx: {
                    display: "flex",
                    justifyContent: "flex-end",
                    pr: "4 !important",
                  },
                  cell: (row) => (
                    <Row flex={1} minWidth={0} justify="flex-end">
                      <TextWithTooltip>{row.count}</TextWithTooltip>
                    </Row>
                  ),
                },
              ]}
            />
          </Column>
        </Column>
      </DrawerBody>
    </>
  );
};
