import { FC, useMemo, useState } from "react";

import {
  Column,
  CubeIcon,
  FrameStackIcon,
  Row,
  Badge,
  Text,
  TimeIcon,
  Tooltip,
} from "@hightouchio/ui";

import { IconBox } from "src/components/icon-box";
import {
  GetLatestSampledSegmentJobsQuery,
  SampledSegmentJobStatus,
  useGetLatestSampledSegmentJobsQuery,
} from "src/graphql";
import { Pagination, Table, TableColumn } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { abbreviateNumber } from "src/utils/numbers";
import * as time from "src/utils/time";

const ROWS_PER_PAGE = 10;

type Props = {
  parentModel: {
    id: number;
    name: string;
  };
  relationships: {
    to_model: {
      id: number;
      name: string;
      event: { timestamp_column: string } | null;
      primary_key: string | null;
    };
  }[];
};

type TableRowData =
  GetLatestSampledSegmentJobsQuery["getLatestSampledSegmentJobs"]["result"][0];

export const RunsTable: FC<Props> = ({ parentModel, relationships }) => {
  const { data, isLoading } = useGetLatestSampledSegmentJobsQuery(
    {
      parentModelId: parentModel.id.toString(),
    },
    {
      select: (data) => data.getLatestSampledSegmentJobs.result,
    },
  );

  const [currentPage, setCurrentPage] = useState(0);

  const modelsById: Record<
    string,
    { id: string; name: string; isEvent: false; isParent: true }
  > = useMemo(() => {
    const modelsById = {};
    for (const relationship of relationships) {
      modelsById[relationship.to_model.id] = {
        id: relationship.to_model.id?.toString(),
        name: relationship.to_model.name,
        isEvent: Boolean(relationship.to_model.event),
        isParent: Boolean(relationship.to_model.primary_key),
      };
    }

    modelsById[parentModel.id.toString() ?? ""] = {
      id: parentModel.id.toString(),
      name: parentModel.name,
      isEvent: false,
      isParent: true,
    };

    return modelsById;
  }, [relationships, parentModel]);

  const columns: TableColumn[] = [
    {
      header: () => <Text color="text.secondary">Status</Text>,
      cell: (row: TableRowData) => {
        return (
          <Tooltip
            isDisabled={row.result.error === null}
            message={row.result.error ?? ""}
          >
            <Badge
              variant={
                row.status === SampledSegmentJobStatus.Finished
                  ? "success"
                  : "error"
              }
            >
              {row.status}
            </Badge>
          </Tooltip>
        );
      },
      headerSx: { paddingLeft: "16px !important" },
      cellSx: { paddingLeft: "16px !important" },
    },
    {
      header: () => <Text color="text.secondary">Timestamp</Text>,
      cell: (row: TableRowData) => {
        return (
          <Column>
            <Row>
              <Text>{time.formatDatetime(row.createdAt)}</Text>
            </Row>
            <Row>
              <Text color="text.secondary" size="sm">
                Duration: {time.diff(row.createdAt, row.finishedAt)}
              </Text>
            </Row>
          </Column>
        );
      },
      max: "min-content",
    },
    {
      header: () => <Text color="text.secondary">Model</Text>,
      cell: (row: TableRowData) => {
        const model = modelsById[row.segmentId];
        if (!model) {
          return null;
        }

        return (
          <Row gap={2} width="100%">
            <IconBox
              bg={
                model.isEvent
                  ? "cyan.400"
                  : model.isParent
                    ? "electric.400"
                    : "grass.400"
              }
              boxSize={5}
              icon={
                model.isEvent ? (
                  <TimeIcon />
                ) : model.isParent ? (
                  <CubeIcon />
                ) : (
                  <FrameStackIcon />
                )
              }
              iconSize={3}
            />
            <TextWithTooltip>{model.name}</TextWithTooltip>
          </Row>
        );
      },
    },
    {
      header: () => <Text color="text.secondary">Total size</Text>,
      cell: (row: TableRowData) => (
        <Column>
          <Text>{abbreviateNumber(Number(row.result.totalRowCount))}</Text>
          <Text color="text.secondary" size="sm">
            Rows
          </Text>
        </Column>
      ),
      max: "min-content",
    },
    {
      header: () => <Text color="text.secondary">Sample size</Text>,
      cell: (row: TableRowData) => (
        <Column>
          <Text>{abbreviateNumber(Number(row.result.sampledRowCount))}</Text>
          <Text color="text.secondary" size="sm">
            Rows
          </Text>
        </Column>
      ),
      headerSx: { paddingRight: "16px !important" },
      cellSx: { paddingRight: "16px !important" },
      max: "min-content",
    },
  ];

  return (
    <Column gap={4}>
      <Table
        columns={columns}
        data={data?.slice(
          currentPage * ROWS_PER_PAGE,
          currentPage * ROWS_PER_PAGE + ROWS_PER_PAGE,
        )}
        loading={isLoading}
        placeholder={{
          title: "No runs available yet",
        }}
      />
      <Pagination
        count={data?.length}
        page={currentPage}
        rowsPerPage={ROWS_PER_PAGE}
        setPage={setCurrentPage}
      />
    </Column>
  );
};
