import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { set, orderBy as lodashOrderByImpl, get } from "lodash";

import { useSearchParams } from "src/router";
import { OrderBy } from "src/graphql";

export type TableConfigOptions = {
  limit?: number;
  page?: number;
  setPage?: (number) => void;
  defaultSortKey?: string | null;
  defaultSortDirection?: "desc" | "asc";
  sortOptions?: Array<string>;
};

export type SortState = {
  sortKey: string | null;
  sortDirection: OrderBy;
};

type TableConfigResult<TOrderByType> = {
  page: number;
  limit: number;
  offset: number;
  orderBy: TOrderByType | undefined;
  sortKey: string | null;
  sortDirection: OrderBy | null;
  setPage: Dispatch<SetStateAction<number>>;
  onSort(sortKey: string): void;
  columnSortState: (key: string) => {
    sortDirection: OrderBy | null;
    onClick: () => void;
  };
};

/**
 * Determines next sort direction for a column.
 *
 * If no previous sort direction, it will derive the default sort direction.
 *
 * @param sortKey Path that will be sorted on.
 * @param prevSortDirection Previous sort direction
 * @returns {OrderBy} The direction to sort
 */
const getNextSortDirection = (prevSortDirection?: OrderBy | null) => {
  if (!prevSortDirection) {
    return "desc";
  }

  return prevSortDirection?.startsWith("asc") ? "desc" : "asc";
};

export const useTableConfig = <TOrderByType>({
  defaultSortKey = null,
  defaultSortDirection = "desc",
  sortOptions = [],
  ...options
}: TableConfigOptions = {}): TableConfigResult<TOrderByType> => {
  // pagination
  const [page, setPage] = useState<number>(options?.page ?? 0);

  const limit = options?.limit ?? 50;
  const offset = limit * page;

  // sorting
  const [searchParams, setSearchParams] = useSearchParams();

  // check if key is allowed to be sorted on
  const sortKeyFromParams = searchParams.get("sortKey");
  const sortKey =
    sortKeyFromParams && sortOptions.includes(sortKeyFromParams)
      ? sortKeyFromParams
      : defaultSortKey;
  const sortDirection =
    ((searchParams.get("sortDirection") || null) as OrderBy) ||
    defaultSortDirection;

  const orderBy = useMemo(() => {
    const key = sortKey || defaultSortKey;

    if (!key) {
      return undefined;
    }

    return set<TOrderByType>({}, key, sortDirection);
  }, [sortKey, defaultSortKey, sortDirection]);

  const onSort = (newSortKey: string) => {
    const nextSortDirection =
      newSortKey !== sortKey
        ? defaultSortDirection
        : getNextSortDirection(sortDirection);

    if (nextSortDirection) {
      searchParams.set("sortKey", newSortKey);
      searchParams.set("sortDirection", nextSortDirection);
    } else {
      searchParams.delete("sortKey");
      searchParams.delete("sortDirection");
    }

    setSearchParams(searchParams);

    setPage(0);
  };

  return {
    page: options.page || page,
    limit,
    offset,
    orderBy,
    sortKey,
    sortDirection,
    setPage: options.setPage || setPage,
    onSort,
    // Seems relatively simple, but replicating this on every column can be pretty error prone
    // Also note: we compare the sort key as opposed to looking up a key in the orderBy object
    // This is because orderBy converts keys into nested objects (eg "name.first" -> {name: {first: ...}})
    columnSortState: (key: string) => ({
      sortDirection: key === sortKey ? sortDirection : null,
      onClick: () => onSort(key),
    }),
  };
};

export type SortOption<Key> = {
  key: Key;
  label: string;
  direction: OrderBy;
};

export const useTableSort = <TOrderByType>(
  initial: SortOption<keyof TOrderByType>,
  options: SortOption<keyof TOrderByType>[],
): TOrderByType | undefined => {
  const [searchParams] = useSearchParams();

  const sortKeyFromParams = searchParams.get("sort");
  const sortKey =
    sortKeyFromParams &&
    options.find((option) => option.key === sortKeyFromParams)?.key;
  const sortDirection =
    ((searchParams.get("dir") || null) as OrderBy) || "desc";

  const orderBy = useMemo(() => {
    const key = (sortKey || initial.key) as any;

    if (!key) {
      return undefined;
    }

    return set<TOrderByType>({}, key, sortDirection);
  }, [sortKey, initial, sortDirection]);

  return orderBy;
};

export const lodashOrderBy = <T>(
  data: T[],
  sortKey: string,
  sortDirection: OrderBy,
): T[] => {
  const key = (obj: T) => {
    // This allows us to sort by nested properties (eg sortKey = "name.first")
    const value = get(obj, sortKey);

    // Lodash order by sort A-Z,a-z, so convert to lowercase
    if (typeof value === "string") {
      return value.toLowerCase();
    }

    return value;
  };

  const order = sortDirection === "asc" ? "asc" : "desc";

  return lodashOrderByImpl(data, [key], [order]);
};
