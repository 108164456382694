import { FC } from "react";

import { FormField, SlugInput } from "@hightouchio/ui";
import { Controller } from "react-hook-form";

import { Card } from "src/components/card";
import { IDRv1OutputTableSuffix, IDRv2OutputTableSuffix } from "src/types/idr";

import { SlugValidationState } from "./hooks";
import { IDRv2GoldenRecordTableSuffix } from "@hightouch/lib/idr/v2/types/config-types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isNil } from "lodash";

const listFormat = new Intl.ListFormat("en");

function getOutputTableTip(
  fieldValue: string | undefined,
  isIDRv2: boolean,
  goldenRecordEnabled: boolean,
) {
  const value = fieldValue || "<table_name>";

  const numTables = isIDRv2 && goldenRecordEnabled ? "up to three" : "two";

  const tableNames = [
    `${value}_resolved`,
    isIDRv2
      ? `${value}${IDRv2OutputTableSuffix}`
      : `${value}${IDRv1OutputTableSuffix}`,
    isIDRv2 && goldenRecordEnabled
      ? `${value}${IDRv2GoldenRecordTableSuffix}`
      : undefined,
  ].filter((x) => !isNil(x));

  return `The output will be ${numTables} tables in the hightouch_planner schema called ${listFormat.format(tableNames)}.`;
}

export const OutputTable: FC<{
  isIDRv2: boolean;
  validationState: SlugValidationState;
}> = ({ isIDRv2, validationState }) => {
  const { enableProfileBuilder } = useFlags();

  return (
    <Card gap={6}>
      <Controller
        name="output_table"
        render={({ field, fieldState: { error } }) => (
          <FormField
            label="Where should Hightouch send the output of resolved identities?"
            description="Provide a prefix for this new table (don’t use an existing table name)"
            tip={getOutputTableTip(field.value, isIDRv2, enableProfileBuilder)}
            error={
              validationState === "invalid"
                ? "This name is not available"
                : error?.message
            }
          >
            <SlugInput
              {...field}
              placeholder="Enter a prefix..."
              validationState={validationState}
            />
          </FormField>
        )}
      />
    </Card>
  );
};
