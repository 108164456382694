import { ReactElement, useState } from "react";
import { MergedSyncRequestEventStatus } from "src/graphql";

import {
  Badge,
  Box,
  Button,
  CheckIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  Column,
  InformationIcon,
  Row,
  Spinner,
  Text,
  TimeIcon,
} from "@hightouchio/ui";

import { PhaseError } from "./phase-error";
import { PhaseDuration } from "./phase-duration";
import { PHASE_DISPLAY_CONFIG } from "./phase-display-config";
import { Card } from "src/components/card";
import { SyncRequest, SyncRunPhase } from "./types";

export const PhaseDisplay = ({
  phase,
  syncRequest,
}: {
  phase: SyncRunPhase;
  syncRequest: SyncRequest;
}) => {
  const [showDescription, setShowDescription] = useState(false);
  const config = PHASE_DISPLAY_CONFIG[phase.span];

  // If no config for this phase span type is found, don't render anything
  if (!config) return null;

  const { displayName, Description, HeaderData, HeaderAction, AdditionalData } =
    config;

  const lightningEngineEnabled = syncRequest.planner_type === "inWarehouse";
  const componentProps = { phase, syncRequest };

  return (
    <Column gap={2} p={4}>
      <Row justifyContent="space-between" alignItems="center">
        <Row gap={2} fontSize="xl" alignItems="center">
          <PhaseStatusIcon phase={phase} />
          <Column alignItems="flex-start">
            <Button
              size="sm"
              variant="tertiary"
              directionIcon={
                showDescription ? ChevronDownIcon : ChevronRightIcon
              }
              onClick={() => setShowDescription(!showDescription)}
            >
              <Text fontWeight="normal">{displayName}</Text>
            </Button>
            {HeaderData && (
              <Box ml={2}>
                <HeaderData {...componentProps} />
              </Box>
            )}
          </Column>
        </Row>
        <Row alignItems="center" gap={4}>
          {HeaderAction && <HeaderAction {...componentProps} />}
          <PhaseStatusBadge phase={phase} />
          {phase.isSkipped ? (
            <Text size="sm" color="text.tertiary">
              Skipped
            </Text>
          ) : (
            phase.durationMillis.self > 0 && (
              <Row minW="60px" justifyContent="flex-end">
                <PhaseDuration
                  durationMillis={phase.durationMillis.self}
                  isActive={
                    phase.status === MergedSyncRequestEventStatus.Active
                  }
                />
              </Row>
            )
          )}
        </Row>
      </Row>
      {AdditionalData && (
        <Box ml={8}>
          <AdditionalData {...componentProps} />
        </Box>
      )}
      {phase.status === MergedSyncRequestEventStatus.Failed &&
        phase.errors.length > 0 && (
          <Box ml={8} mt={2}>
            <PhaseError errors={phase.errors} syncRequest={syncRequest} />
          </Box>
        )}
      {showDescription && (
        <Card backgroundColor="base.background" p={4} ml={8} mt={2}>
          <Column gap={2}>
            <Text color="text.secondary" fontWeight="medium">
              What's happening during this phase?
            </Text>
            <Description lightningEngineEnabled={lightningEngineEnabled} />
          </Column>
        </Card>
      )}
    </Column>
  );
};

const PhaseStatusIcon = ({ phase }: { phase: SyncRunPhase }) => {
  if (phase.isInterrupted || phase.isSkipped) {
    return <InformationIcon color="text.placeholder" />;
  }

  return PHASE_STATUS_ICON[phase.status];
};

const PHASE_STATUS_ICON: Record<SyncRunPhase["status"], ReactElement> = {
  [MergedSyncRequestEventStatus.NotStarted]: (
    <TimeIcon color="text.placeholder" />
  ),
  [MergedSyncRequestEventStatus.Active]: <Spinner size="sm" speed="slow" />,
  [MergedSyncRequestEventStatus.Failed]: (
    <InformationIcon color="danger.base" />
  ),
  [MergedSyncRequestEventStatus.Completed]: <CheckIcon color="success.base" />,
};

const PhaseStatusBadge = ({ phase }: { phase: SyncRunPhase }) => {
  // Always show the "Interrupted" badge if the phase is interrupted
  if (phase.isInterrupted) {
    return <Badge>Interrupted</Badge>;
  }

  // Only show additional badges if there are errors
  if (phase.errors.length === 0) return null;

  switch (phase.status) {
    case MergedSyncRequestEventStatus.NotStarted:
    case MergedSyncRequestEventStatus.Active:
      return (
        <Badge variant="info">Error encountered - retry in progress</Badge>
      );
    case MergedSyncRequestEventStatus.Completed:
      return <Badge>Recovered from error</Badge>;
    case MergedSyncRequestEventStatus.Failed:
      return <Badge variant="error">Failed</Badge>;
  }
};
