import { FC, useEffect, useState } from "react";

import { TraitConfig, TraitType } from "@hightouch/lib/query/visual/types";
import {
  Button,
  Spinner,
  Row,
  Column,
  Heading,
  Text,
  AddBoxIcon,
  CloseIcon,
  IconButton,
  ChakraModal,
  ChakraModalOverlay,
  ChakraModalContent,
  ChakraModalHeader,
  ChakraModalBody,
  chakra,
} from "@hightouchio/ui";
import { useNavigate } from "src/router";

import { useTraitQuery, useTraitsQuery } from "src/graphql";

import { CalculationSummary } from "./calculation-summary";
import { Header } from "./header";
import { PreviewTrait } from "./preview-trait";

const BOTTOM_BAR_SHARED_STYLES = {
  h: "72px",
  flexShrink: 0,
  borderTop: "1px solid",
  borderTopColor: "base.border",
};

export const SelectTemplateModal: FC = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState<{
    traitId: string | undefined;
    index: number;
  }>({ traitId: undefined, index: -1 });

  const traitTemplatesQuery = useTraitsQuery({
    filter: {
      is_template: { _eq: true },
      archived_at: { _eq: null },
    },
  });

  // Automatically show the first trait template
  useEffect(() => {
    if (!tab.traitId) {
      if (traitTemplatesQuery.data?.trait_definitions?.[0]) {
        const firstTemplate = traitTemplatesQuery.data?.trait_definitions[0];
        setTab({ traitId: firstTemplate.id, index: 0 });
      }
    }
  }, [tab.traitId, traitTemplatesQuery.data?.trait_definitions]);

  const traitQuery = useTraitQuery(
    { id: tab.traitId ?? "" },
    {
      enabled: tab.traitId != undefined,
    },
  );

  const trait = traitQuery.data?.trait_definitions_by_pk;

  const onClose = () => navigate("/traits/active");

  const handleCreateTrait = () => {
    navigate(`/traits/active/new_from_template?template_id=${tab?.traitId}`);
  };

  const handleCreateTraitTemplate = () => {
    navigate("/traits/templates/new");
  };

  const content = (
    <Column>
      <Row height="75vh">
        <Column
          width="300px"
          borderRight="1px solid"
          borderRightColor="base.border"
          justifyContent="space-between"
        >
          <Column overflowY="auto">
            {traitTemplatesQuery.data?.trait_definitions.map((trait, index) => (
              <Column
                key={trait.id}
                bg={tab.index === index ? "primary.background" : "gray.50"}
                cursor="pointer"
                py={2}
                px={6}
                role="listbox"
                _hover={{ bg: "base.background" }}
                onClick={() => setTab({ traitId: trait.id, index: index })}
              >
                <Text>{trait.name}</Text>
                <Text color="text.secondary">{trait.description}</Text>
              </Column>
            ))}
          </Column>
          <Column
            as={chakra.button}
            textAlign="left"
            position="sticky"
            bottom={0}
            px={6}
            justifyContent="center"
            bg="gray.50"
            _hover={{ bg: "base.background" }}
            {...BOTTOM_BAR_SHARED_STYLES}
            onClick={handleCreateTraitTemplate}
          >
            <Row gap={2}>
              <AddBoxIcon color="text.secondary" />
              <Column>
                <Text>New template</Text>
                <Text color="text.secondary">Save the calculation method</Text>
              </Column>
            </Row>
          </Column>
        </Column>

        <Column width="600px">
          {(traitQuery.isIdle || traitQuery.isLoading) && (
            <Column height="100%" alignItems="center" justifyContent="center">
              {traitQuery.isIdle ? (
                "Select or create a new template to get started"
              ) : (
                <Spinner />
              )}
            </Column>
          )}
          {trait && (
            <Column height="100%">
              <Row position="sticky" p={6} top={0} background="white">
                <Header
                  id={trait.id}
                  name={trait.name}
                  description={trait?.description}
                  parentModel={trait.parent_model}
                  isTemplate={trait.is_template}
                  isArchived={Boolean(trait.archived_at)}
                />
              </Row>

              <Column p={6} gap={8} flex={1} overflowY="scroll">
                <Column gap={2}>
                  <Text fontWeight="medium">Calculation summary</Text>

                  <CalculationSummary
                    type={trait.type as TraitType}
                    config={trait.config as TraitConfig}
                    parentModel={trait.parent_model}
                    relatedModel={trait.relationship?.to_model}
                  />

                  <Column mt={2}>
                    <PreviewTrait
                      parentModel={trait.parent_model}
                      trait={{
                        name: trait.name,
                        type: trait.type as TraitType,
                        config: trait.config,
                        relationshipId: trait.relationship?.id,
                      }}
                    />
                  </Column>
                </Column>
              </Column>
              <Row
                alignItems="center"
                justifyContent="right"
                px={6}
                bg="white"
                {...BOTTOM_BAR_SHARED_STYLES}
              >
                <Button variant="primary" onClick={handleCreateTrait}>
                  Choose this template
                </Button>
              </Row>
            </Column>
          )}
        </Column>
      </Row>
    </Column>
  );

  return (
    <ChakraModal
      closeOnEsc
      closeOnOverlayClick
      isCentered
      scrollBehavior="inside"
      isOpen
      onClose={onClose}
    >
      <ChakraModalOverlay />
      <ChakraModalContent
        maxHeight="90vh"
        maxWidth="800px"
        padding={0}
        overflow="hidden"
        my="auto"
      >
        <ChakraModalHeader
          alignItems="center"
          padding={6}
          borderBottom="1px solid"
          borderColor="base.border"
          mb={0}
        >
          <Row alignItems="center" justifyContent="space-between" width="100%">
            <Heading>Choose a template</Heading>
            <IconButton
              aria-label="Close modal"
              color="text.secondary"
              icon={CloseIcon}
              onClick={onClose}
            />
          </Row>
        </ChakraModalHeader>
        <ChakraModalBody m={0} px={0}>
          {traitTemplatesQuery.isLoading ? (
            <Column height="100px" alignItems="center" justifyContent="center">
              <Spinner />
            </Column>
          ) : (
            content
          )}
        </ChakraModalBody>
      </ChakraModalContent>
    </ChakraModal>
  );
};
