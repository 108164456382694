import { HTImage } from "src/components/image";
import {
  Column,
  Badge,
  Row,
  SectionHeading,
  Skeleton,
  SkeletonBox,
  Text,
} from "@hightouchio/ui";

import { LinkButton } from "src/router";
import EmptySvg from "./empty-alerts.svg";
import { parseEventSourceResourceId } from "@hightouch/lib/resource-monitoring/composite-ids";
import { Card } from "src/components/card";
import { formatFriendlyDistanceToNow } from "src/utils/time";
import { useActiveAlertsForSource } from "src/events/sources/use-active-alerts-for-source";

export const AlertsForSource = ({
  source,
}: {
  source: { id: string; name: string };
}) => {
  const { isLoading, alerts } = useActiveAlertsForSource({
    sourceId: source.id,
  });
  const p = 4;

  return (
    <Skeleton isLoading={isLoading}>
      <SkeletonBox height="400px" borderRadius={8}>
        <Card p={0}>
          <Row
            p={p}
            borderBottom="1px"
            borderColor="base.border"
            gap={2}
            alignItems="center"
          >
            <SectionHeading>Active alerts</SectionHeading>
            {alerts && alerts.length > 0 && (
              <Badge size="sm" variant="error">
                {alerts?.length.toLocaleString()}
              </Badge>
            )}
          </Row>
          <Column overflow="auto" maxH="400px">
            {alerts && alerts.length > 0 ? (
              alerts.map((alert) => {
                const resource = parseEventSourceResourceId(
                  alert.eventTypeResourceId,
                );
                if (!resource.error) {
                  return (
                    <Column
                      key={alert.conditionChangeId}
                      borderTop="1px"
                      borderColor="base.border"
                      p={p}
                      _first={{ borderTop: "none" }}
                      gap={2}
                    >
                      <Row justifyContent="space-between">
                        <Column>
                          <Text>
                            <Text fontWeight="semibold">
                              {resource.eventName}
                            </Text>{" "}
                            had{" "}
                            {(alert.title ?? alert.description).toLowerCase()}
                          </Text>
                          <Text size="sm" color="text.secondary">
                            {formatFriendlyDistanceToNow(
                              alert.eventTime.toISOString(),
                            )}
                          </Text>
                        </Column>

                        <LinkButton
                          ml={4}
                          href={`../event-catalog/configure/${encodeURIComponent(
                            alert.eventTypeResourceId,
                          )}`}
                          {...{ size: "sm" }}
                        >
                          View details
                        </LinkButton>
                      </Row>
                    </Column>
                  );
                }

                return <></>;
              })
            ) : (
              <Column borderTop="1px" borderColor="base.border" p={p}>
                <HTImage
                  src={String(EmptySvg)}
                  mx="auto"
                  decorative
                  boxSize={16}
                />
                <Row
                  width="100%"
                  justifyContent="center"
                  color="text.secondary"
                  mt={4}
                >
                  No alerts for any events in this source
                </Row>
              </Column>
            )}
          </Column>
        </Card>
      </SkeletonBox>
    </Skeleton>
  );
};
