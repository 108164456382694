import { FC } from "react";

import { MarketingHome } from "./components/marketing";
import { useEntitlements } from "src/hooks/use-entitlement";
import { usePersistedState } from "src/hooks/use-persisted-state";
import { useUser } from "src/contexts/user-context";
import { ActivationHome } from "./components/activation";
import { useResourcePermission } from "src/components/permission/use-resource-permission";

export const MAX_WIDTH = "1400px";

export const Home: FC = () => {
  const { workspace } = useUser();
  const { data: entitlementsData } = useEntitlements(false);
  const { isPermitted: isWorkspaceAdmin } = useResourcePermission({
    v2: { resource: "workspace", grant: "can_update" },
  });

  const isDefaultActivation =
    !entitlementsData.entitlements.audiences || isWorkspaceAdmin;

  const state = usePersistedState(
    `${workspace?.id}-home-view`,
    isDefaultActivation ? "activation" : "marketing",
  );

  // Wait for persisted state to load in
  if (!state.value) {
    return null;
  }

  if (state.value === "marketing") {
    return <MarketingHome onToggleView={() => state.set("activation")} />;
  }

  return <ActivationHome onToggleView={() => state.set("marketing")} />;
};

export const routes = [
  {
    path: "home",
    element: <Home />,
  },
];
