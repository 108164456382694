import { FC, useState } from "react";

import {
  ActivityIcon,
  ArrowRightIcon,
  Box,
  Button,
  CloseIcon,
  Column,
  DrawerBody,
  DrawerHeader,
  IconButton,
  Row,
  SectionHeading,
  Spinner,
  Text,
  useDisclosure,
} from "@hightouchio/ui";
import { formatDistance, parseISO, startOfHour, subDays } from "date-fns";
import { groupBy } from "lodash";

import { RouterLink } from "src/router";
import { HomeActivityFeedQuery, useHomeActivityFeedQuery } from "src/graphql";
import { Drawer } from "src/components/drawer";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";

type EnrichedActivityItem = Omit<
  HomeActivityFeedQuery["auditLog"]["items"][0],
  "old" | "new"
> & {
  source?: {
    icon: string;
    name: string;
  };
  destination?: {
    icon: string;
    name: string;
  };
  old: Record<string, unknown> | null;
  new: Record<string, unknown> | null;
};

const LIMIT = 500;
const LOOKBACK_WINDOW = 30; // days

function activityOperation(
  n: Record<string, unknown> | null,
  o: Record<string, unknown> | null,
): "updated" | "created" | "deleted" | null {
  if (n && !o) return "created";
  if (n && o) return "updated";
  if (!n && o) return "deleted";
  return null;
}

function activityItemLink(
  resource: string,
  resourceId: string,
  meta?: any,
): string {
  switch (resource) {
    case "Sync":
      return `/syncs/${resourceId}`;
    case "Model":
      return `/models/${resourceId}`;
    case "Audience":
      return `/audiences/${resourceId}`;
    case "Source":
      return `/sources/${resourceId}`;
    case "Destination":
      return `/destinations/${resourceId}`;
    case "Audience Schema":
      return `/schema-v2/view/query?source=${meta?.new?.connection_id}&id=${resourceId}`;
    default:
      return "";
  }
}

export const ActivityFeed = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [since] = useState(subDays(new Date(), LOOKBACK_WINDOW).toISOString());

  const { data, isLoading } = useHomeActivityFeedQuery(
    {
      since,
      limit: LIMIT,
    },
    {
      refetchOnMount: "always",
      select(data): EnrichedActivityItem[] {
        if (!data) {
          return [];
        }
        // Group items by resource and time window
        const groupedItems = groupBy(data.auditLog.items, (item) => {
          // Bucket to the hour
          const timeWindowStart = startOfHour(parseISO(item.created_at));
          return `${item.resource_id}-${
            item.resource
          }-${timeWindowStart.toISOString()}`;
        });

        const syncLookup = new Set(
          data.destination_instances.map((item) => item.id),
        );

        const segmentLookup: Record<string, { name: string; icon: string }> =
          data.segments.reduce(
            (acc, item) => {
              if (!item.connection) return acc;
              const sourceDefinition = data.getSourceDefinitions.find(
                (def) => def.type === item.connection?.type,
              );
              acc[item.id] = {
                name: item.name || sourceDefinition?.name || "Unknown",
                icon: sourceDefinition?.icon || "",
              };

              return acc;
            },
            {} as Record<string, { name: string; icon: string }>,
          );

        const sourceLookup: Record<string, { name: string; icon: string }> =
          data.connections.reduce(
            (acc, item) => {
              const sourceDefinition = data.getSourceDefinitions.find(
                (def) => def.type === item.type,
              );
              acc[item.id] = {
                name: item.name || sourceDefinition?.name || "Unknown",
                icon: sourceDefinition?.icon || "",
              };
              return acc;
            },
            {} as Record<string, { name: string; icon: string }>,
          );

        const destinationLookup: Record<
          string,
          { name: string; icon: string }
        > = data.destinations.reduce(
          (acc, item) => {
            const destinationDefinition = data.getDestinationDefinitions.find(
              (def) => def.type === item.type,
            );
            acc[item.id] = {
              name: item.name || destinationDefinition?.name || "Unknown",
              icon: destinationDefinition?.icon || "",
            };
            return acc;
          },
          {} as Record<string, { name: string; icon: string }>,
        );

        // Now return a list of the last item in each group
        const filteredItems = Object.values(groupedItems).map(
          (items) => items[items.length - 1],
        );

        // Now we enrich the filtered items
        const enrichedItems: EnrichedActivityItem[] = [];

        for (const item of filteredItems) {
          // We skip showing deleted items
          if (!item || !item.new) {
            continue;
          }
          let destination: undefined | { name: string; icon: string };
          let source: undefined | { name: string; icon: string };

          if (item.resource === "Sync") {
            // Permissions check
            if (!syncLookup.has(item.resource_id)) {
              continue;
            }
            destination = destinationLookup[item.new?.destination_id];
            source = segmentLookup[item.new?.segment_id];
          }

          if (
            item.resource === "Model" ||
            item.resource === "Audience" ||
            item.resource === "Audience Schema"
          ) {
            if (!segmentLookup[item.resource_id]) continue;
            source =
              segmentLookup[item.resource_id] ||
              sourceLookup[item.new?.connection_id];
          }

          if (item.resource === "Source") {
            if (!sourceLookup[item.resource_id]) continue;
            source = sourceLookup[item.resource_id];
          }
          if (item.resource === "Destination") {
            if (!destinationLookup[item.resource_id]) continue;
            destination = destinationLookup[item.resource_id];
          }

          enrichedItems.push({
            ...item,
            source,
            destination,
          });
        }

        return enrichedItems;
      },
    },
  );

  return (
    <>
      <Button icon={ActivityIcon} variant="secondary" onClick={onOpen}>
        Activity feed
      </Button>
      <Drawer isOpen={isOpen} onClose={onClose} size="md">
        <DrawerHeader>
          <Row justify="space-between" align="center" flex={1}>
            <Row gap={2} align="center">
              <SectionHeading>Activity feed</SectionHeading>
            </Row>
            <IconButton
              aria-label="Close"
              icon={CloseIcon}
              size="sm"
              onClick={onClose}
            />
          </Row>
        </DrawerHeader>
        <DrawerBody p={0}>
          {isLoading ? (
            <Spinner size="lg" mx="auto" mt={6} />
          ) : data?.length ? (
            data.map((activityItem, idx) => (
              <ActivityItem key={idx} item={activityItem} />
            ))
          ) : (
            <Box color="text.secondary" textAlign="center" p={6}>
              No recent activity in this workspace, or you don't have permission
              to view activity.
            </Box>
          )}
        </DrawerBody>
      </Drawer>
    </>
  );
};

const ActivityItem: FC<{ item: EnrichedActivityItem }> = ({ item }) => {
  if (!item || !item.resource || !item.id || !item.created_at) return null;
  return (
    <Row
      h="100px"
      align="center"
      as={RouterLink}
      borderBottom="1px"
      borderColor="base.border"
      _hover={{ bg: "base.lightBackground" }}
      to={activityItemLink(item.resource, item.resource_id, item)}
      gap={2}
      px={6}
      className="group"
      justify="space-between"
    >
      <Column gap={2}>
        <Column>
          <Text fontWeight="medium">
            {item.resource} {activityOperation(item.new, item.old)} by{" "}
            {item.user_name}
          </Text>
          <Text size="sm" color="text.secondary">
            {formatDistance(parseISO(item.created_at), new Date(), {
              addSuffix: true,
            })}
          </Text>
        </Column>
        {item.source && (
          <Row align="center" gap={1}>
            {item.source.icon && (
              <IntegrationIcon
                size={4}
                src={item.source.icon}
                name={item.source.name}
              />
            )}
            <TextWithTooltip>{item.source.name}</TextWithTooltip>
          </Row>
        )}
        {item.destination && (
          <Row align="center" gap={1}>
            {item.destination.icon && (
              <IntegrationIcon
                size={4}
                src={item.destination.icon}
                name={item.destination.name}
              />
            )}
            <TextWithTooltip>{item.destination.name}</TextWithTooltip>
          </Row>
        )}
      </Column>
      <Box
        as={ArrowRightIcon}
        _groupHover={{ opacity: 1 }}
        color="text.tertiary"
        boxSize={5}
        opacity={0}
        transition="200ms all ease-in-out"
      />
    </Row>
  );
};
