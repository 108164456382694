import { AudienceIcon } from "@hightouchio/ui";
import pluralize from "pluralize";

import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import { JourneyNodeDetails } from "src/pages/journeys/types";
import { SplitsConfig } from "src/types/journeys";

import { DescriptionRow } from "./description-row";
import { EmptyStateDescription } from "./empty-state-description";

export const SplitNodeDescription = ({
  id,
}: JourneyNodeDetails<SplitsConfig>) => {
  const { getBranchNodes } = useGraphContext();
  const splits = getBranchNodes(id);

  return splits.length === 1 ? (
    <EmptyStateDescription />
  ) : (
    <DescriptionRow icon={AudienceIcon}>
      {pluralize("split groups", splits.length, true)}
    </DescriptionRow>
  );
};
