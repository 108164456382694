import { Column } from "@hightouchio/ui";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Outlet, useOutletContext } from "src/router";
import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { DetailPage } from "src/components/layout";
import { useUpdateDecisionEngineWithChannelsMutation } from "src/graphql";
import { Channels, formatChannel, channelsSchema } from "./components/channels";
import { TimeZoneSettings, timezoneColumnSchema } from "./components/time-zone";
import { Targeting } from "./components/targeting";
import { OutletContext } from "..";

const schema = yup.object().shape({
  channels: channelsSchema,
  config: yup.object().shape({
    timezone_column: timezoneColumnSchema,
    scheduling_timezone: yup.string().nullable(),
  }),
});

export const Configuration = () => {
  const context = useOutletContext<OutletContext>();
  const { engine } = context;
  const update = useUpdateDecisionEngineWithChannelsMutation();

  const form = useHightouchForm({
    resolver: yupResolver(schema),
    onSubmit: async (data) => {
      const channels = data.channels.map((channel) => ({
        ...channel,
        decision_engine_id: engine.id,
      }));

      const config = data.config;

      if (!config.timezone_column?.format || !config.timezone_column?.name) {
        config.timezone_column = null;
      }

      await update.mutateAsync({
        id: engine.id,
        input: {
          config: {
            ...engine.config,
            ...data.config,
          },
          segment_id: data.segment_id,
        },
        channels,
      });
    },
    values: {
      segment_id: engine.segment.id,
      config: {
        timing: engine.config?.timing ?? { frequency: [], days: [], times: [] },
        timezone_column: engine.config?.timezone_column ?? {
          format: null,
          name: null,
        },
        scheduling_timezone: engine.config?.scheduling_timezone ?? null,
      },
      channels: engine.channels
        .map(formatChannel)
        .sort((a, b) => Number(b.enabled) - Number(a.enabled)),
    },
  });

  return (
    <DetailPage
      heading="Configuration"
      bg="base.lightBackground"
      hasBottomPadding
    >
      <Form form={form}>
        <Column gap={6} maxW="xl">
          <Targeting />
          <TimeZoneSettings />
          <Channels />
        </Column>
        <ActionBar>
          <FormActions />
        </ActionBar>
      </Form>
      <Outlet context={context} />
    </DetailPage>
  );
};
