import {
  NormalizedToLiftMetadata,
  SplitGroupId,
} from "@hightouch/lib/query/visual/types/analytics";
import { MetricResultMaybeFromCache } from "src/pages/analytics/hooks/use-metric-series";
import { Outcome } from "./outcomes";

export const SPLIT_GROUP_SORT_ORDER: SplitGroupId[] = [
  "holdout",
  "uniform",
  "customer_managed",
  "treatment",
];

export const SPLIT_GROUP_LABEL: Record<SplitGroupId, string> = {
  holdout: "Holdout",
  uniform: "Uniform",
  customer_managed: "Customer Managed",
  treatment: "Treatment",
};

const MIN_CONVERSION_THRESHOLD = 100;
const MIN_PERCENT_CONFIDENCE = 0.6;
const HIGH_CONFIDENCE_THRESHOLD = 0.95;

type SplitGroupConversionRate = {
  isBaseline: boolean;
  rate: number;
  conversions: number;
  groupSize: number;
  splitGroup: string;
  // Will be null if this is the baseline group, or if there is no baseline
  liftFromBaseline: number | null;
  // Will be null if there is no baseline
  winProbability: { winPercent: number; lossPercent: number } | null;
};

export type OutcomeConversionRates = {
  outcome: Outcome;
  relationshipId: string;
  bestPerformingGroup:
    | {
        type: "no-data";
      }
    | { type: "not-statsig" }
    | {
        type: "best-performing-group";
        splitGroup: string;
        percentConfidence: number;
        isHighConfidence: boolean;
      };
  conversionRates: SplitGroupConversionRate[];
};

export const metricDataToOutcomeConversionRates = (
  data: MetricResultMaybeFromCache[],
  outcomesWithRelationshipId: {
    outcome: Outcome;
    relationshipId: string;
  }[],
): OutcomeConversionRates[] => {
  const outcomeConversionRates: OutcomeConversionRates[] = [];

  data.forEach(({ ids, result }) => {
    if (!("data" in result)) return;

    const outcome = outcomesWithRelationshipId.find(
      ({ outcome }) => outcome.id === ids.metricDefinition?.id,
    );
    if (!outcome) return;

    // The baseline metadata should be the same for all items in the series
    const firstPoint = result.data[0]?.data[0];
    const firstPointMetadata = firstPoint?.meta as
      | NormalizedToLiftMetadata
      | undefined;
    const baselineSplitId = firstPointMetadata?.baselineSplitId;

    const conversionRates: OutcomeConversionRates["conversionRates"] =
      result.data
        .map(({ data: [res], splitId: splitGroup }) => {
          if (!res || !splitGroup) return null;

          const { conversions, groupSize, winProbability } =
            res.meta as NormalizedToLiftMetadata;
          const rate = conversions / groupSize;

          return {
            isBaseline: false,
            splitGroup,
            rate,
            conversions,
            groupSize,
            // The backend will return zeros for these values if there is no baseline
            // So convert that to null here so we know if the lift is actually zero or there is no baseline
            liftFromBaseline: baselineSplitId ? res.value : null,
            winProbability: baselineSplitId ? winProbability : null,
          };
        })
        .filter((r) => r !== null);

    if (baselineSplitId) {
      const { baselineConversions, baselineGroupSize, baselineWinProbability } =
        firstPointMetadata;

      conversionRates.push({
        isBaseline: true,
        splitGroup: baselineSplitId,
        rate: baselineConversions / baselineGroupSize,
        conversions: baselineConversions,
        groupSize: baselineGroupSize,
        liftFromBaseline: null,
        // If there is no other split data, null out the baseline win probability as it's meaningless
        winProbability:
          conversionRates.length > 0 ? baselineWinProbability : null,
      });
    }

    const bestPerformingGroup = pickBestPerformingGroup(
      outcome.outcome,
      conversionRates,
    );

    outcomeConversionRates.push({
      outcome: outcome.outcome,
      relationshipId: outcome.relationshipId,
      conversionRates,
      bestPerformingGroup,
    });
  });

  return outcomeConversionRates;
};

export const pickBestPerformingGroup = (
  outcome: Pick<Outcome, "weight">,
  conversionRates: SplitGroupConversionRate[],
): OutcomeConversionRates["bestPerformingGroup"] => {
  if (conversionRates.length === 0) {
    return {
      type: "no-data",
    };
  }

  const isPositiveOutcome = outcome.weight.type === "positive";
  const bestPerformingGroup = conversionRates.reduce((acc, curr) => {
    if (isPositiveOutcome) {
      return curr.rate > acc.rate ? curr : acc;
    }
    return curr.rate < acc.rate ? curr : acc;
  });

  // Possible if there are no other groups to compare against
  if (!bestPerformingGroup || !bestPerformingGroup.winProbability) {
    return {
      type: "no-data",
    };
  }

  const allGroupsAboveMinThreshold = conversionRates.every(
    ({ conversions }) => conversions > MIN_CONVERSION_THRESHOLD,
  );

  const percentConfidence = isPositiveOutcome
    ? bestPerformingGroup.winProbability.winPercent
    : bestPerformingGroup.winProbability.lossPercent;

  if (
    !allGroupsAboveMinThreshold ||
    percentConfidence < MIN_PERCENT_CONFIDENCE
  ) {
    return {
      type: "not-statsig",
    };
  }

  return {
    type: "best-performing-group",
    splitGroup: bestPerformingGroup.splitGroup,
    percentConfidence,
    isHighConfidence: percentConfidence >= HIGH_CONFIDENCE_THRESHOLD,
  };
};
