import { HTImage } from "src/components/image";
import { FC, useEffect } from "react";

import {
  Column,
  FormField,
  TextInput,
  Textarea,
  Row,
  Text,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  Radio,
  UncontrolledWizardStep,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { eventSourceDefinitions } from "src/events/types";
import { useEventSourcesQuery } from "src/graphql";
import { WizardDrawer } from "src/components/drawer";

import { SchemaViolations } from "./types";

const yupSchema = yup.object().shape({
  name: yup.string().required("This field is required"),
});

export type NewContractFormState = {
  name: string;
  description?: string;
  sources: string[];
  blockUndeclaredEventTypes: SchemaViolations;
};

interface CreateContractDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (formData: NewContractFormState) => Promise<void>;
}

export const CreateContractDrawer: FC<CreateContractDrawerProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const eventSourcesQuery = useEventSourcesQuery(undefined, {
    select: (data) => data.event_sources,
  });
  const eventSourceOptions =
    eventSourcesQuery.data?.map(({ id, name, type, event_plan_id }) => ({
      label: name,
      value: id,
      type,
      eventPlanId: event_plan_id,
    })) ?? [];

  const { control, watch, handleSubmit, formState, reset } =
    useForm<NewContractFormState>({
      defaultValues: {
        name: "",
        description: "",
        sources: [],
        blockUndeclaredEventTypes: SchemaViolations.ALLOW_EVENT,
      },
      resolver: yupResolver(yupSchema),
    });

  const hasName = Boolean(watch("name"));

  const submit = async (data: NewContractFormState) => {
    await onSubmit(data);
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [isOpen]);

  const steps: UncontrolledWizardStep[] = [
    {
      label: "Name & description",
      isDisabled: !hasName,
      render: () => (
        <Column width="100%" gap={4}>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <FormField label="Name" error={fieldState.error?.message}>
                <TextInput
                  {...field}
                  isInvalid={Boolean(fieldState.error?.message)}
                  placeholder="Enter a contract name"
                  width="100%"
                />
              </FormField>
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <FormField label="Description" isOptional>
                <Textarea
                  {...field}
                  placeholder="Enter a description..."
                  width="100%"
                  value={field.value ?? ""}
                />
              </FormField>
            )}
          />
        </Column>
      ),
    },
    {
      label: "Select sources",
      isDisabled: !hasName,
      render: () => (
        <Controller
          name="sources"
          control={control}
          render={({ field }) => (
            <FormField
              label="Which event source will this contract apply to?"
              description="Hightouch will monitor events coming from selected sources for contract violations."
            >
              {eventSourceOptions.length > 0 ? (
                <CheckboxGroup>
                  {eventSourceOptions.map(
                    ({ label, value, type, eventPlanId }) => {
                      const isChecked = Boolean(field.value.includes(value));

                      return (
                        <Row
                          key={value}
                          align="center"
                          gap={2}
                          as="label"
                          htmlFor={value}
                        >
                          <Checkbox
                            id={value}
                            isDisabled={Boolean(eventPlanId)}
                            isChecked={isChecked}
                            onChange={(event) => {
                              if (event.target.checked) {
                                field.onChange([...field.value, value]);
                              } else {
                                const newValues: string[] = field.value.slice();
                                const indexToRemove = newValues?.indexOf(value);
                                if (indexToRemove > -1) {
                                  newValues.splice(indexToRemove, 1);
                                  field.onChange(newValues);
                                }
                              }
                            }}
                          />
                          <HTImage
                            src={eventSourceDefinitions[type].icon}
                            alt={type}
                            width="20px"
                            height="20px"
                          />
                          <Text
                            color={
                              eventPlanId ? "text.placeholder" : "text.primary"
                            }
                            fontWeight="medium"
                          >
                            {label}
                          </Text>
                        </Row>
                      );
                    },
                  )}
                </CheckboxGroup>
              ) : (
                <Column align="center" justify="center" pt={16}>
                  <Text fontWeight="medium">No event sources found</Text>
                </Column>
              )}
            </FormField>
          )}
        />
      ),
    },
    {
      label: "Enforcement",
      isDisabled: !hasName,
      submit: handleSubmit(submit),
      render: () => (
        <Controller
          name="blockUndeclaredEventTypes"
          control={control}
          render={({ field }) => (
            <FormField
              label="Undeclared event types"
              description="When Hightouch finds event types that are not part of the contract."
            >
              <RadioGroup
                orientation="vertical"
                value={field.value}
                onChange={field.onChange}
              >
                <Radio
                  label="Allow"
                  description="Undeclared events will sync to their destination with warnings."
                  value="ALLOW_EVENT"
                />
                <Radio
                  label="Block"
                  description="Undeclared events will not sync to their destination."
                  value="BLOCK_EVENT"
                />
              </RadioGroup>
            </FormField>
          )}
        />
      ),
    },
  ];

  return (
    <>
      {isOpen && (
        <Helmet>
          <title>New contract</title>
        </Helmet>
      )}
      <WizardDrawer
        isOpen={Boolean(isOpen)}
        title="New contract"
        isDirty={formState.isDirty}
        steps={steps}
        size="lg"
        onClose={onClose}
      />
    </>
  );
};
