import React, { createContext, useContext, useMemo } from "react";

import { Box, Column, Row, Text } from "@hightouchio/ui";
import pluralize from "pluralize";
import {
  AudienceColumn,
  EventColumn,
  PropertyColumn,
  RelationColumn,
  TraitColumn,
} from "./filter-popover/constants";
import { IconBox } from "src/components/icon-box";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  Condition as UnionConditionType,
  AndCondition as AndConditionType,
  OrCondition as OrConditionType,
  PropertyCondition as PropertyConditionType,
  ReferencedPropertyCondition as ReferencedPropertyConditionType,
  EventCondition as EventConditionType,
  NumberOfCondition as NumberOfConditionType,
  SegmentSetCondition as SegmentSetConditionType,
  OperatorsWithoutValue,
  isRelatedColumn,
  isTraitColumn,
  ConditionType,
  eventOperatorToLabel,
  numberOperatorToLabel,
  AndOrCondition,
  exhaustiveCheck,
} from "src/types/visual";
import { colors } from "./visual/colors";
import {
  formatValue,
  getPropertyNameFromProperty,
  getTimeValue,
} from "./visual/utils";

interface ConditionsProps {
  rootConditions?: UnionConditionType[];
  isInline?: boolean;
  audiences: {
    id: string;
    name: string;
  }[];
  relationships: {
    id: string;
    name: string | null;
    to_model: {
      id: string;
      name: string;
      event: {
        timestamp_column: string;
      } | null;
    };
  }[];
  traits: {
    id: string;
    name: string;
  }[];
}

const ConditionContext = createContext<
  Pick<ConditionsProps, "audiences" | "relationships" | "traits">
>({
  audiences: [],
  relationships: [],
  traits: [],
});

const useConditionContext = () => useContext(ConditionContext);

export const ReadOnlyConditions: React.FC<ConditionsProps> = ({
  rootConditions,
  audiences,
  relationships,
  traits,
}) => {
  if (!rootConditions) {
    return null;
  }

  return (
    <ConditionContext.Provider value={{ audiences, relationships, traits }}>
      <Box bg="base.lightBackground">
        {rootConditions.map((condition, index) => (
          <Condition key={index} condition={condition} />
        ))}
      </Box>
    </ConditionContext.Provider>
  );
};

const Condition: React.FC<{
  condition: UnionConditionType;
  isInline?: boolean;
}> = ({ condition, isInline = false }) => {
  const conditionType = condition.type;

  switch (conditionType) {
    case ConditionType.And:
      return <AndCondition condition={condition} isInline={isInline} />;
    case ConditionType.Or:
      return <OrCondition condition={condition} isInline={isInline} />;
    case ConditionType.Property:
      return <PropertyCondition condition={condition} />;
    case ConditionType.ReferenceProperty:
      return <ReferencePropertyCondition condition={condition} />;
    case ConditionType.Event:
      return <EventCondition condition={condition} />;
    case ConditionType.NumberOf:
      return <NumberOfCondition condition={condition} />;
    case ConditionType.SegmentSet:
      return <SegmentSetCondition condition={condition} />;
    default:
      exhaustiveCheck(conditionType);
  }
};

const AndCondition: React.FC<{
  condition: AndConditionType;
  isInline?: boolean;
}> = ({ condition, isInline }) => (
  <Box {...getAndOrConditionStyles({ type: ConditionType.And, isInline })}>
    <Column gap={3}>
      {condition.conditions.map((subCondition, index) => (
        <React.Fragment key={index}>
          <Condition condition={subCondition} />
          {index !== condition.conditions.length - 1 && (
            <Text fontWeight="medium">AND</Text>
          )}
        </React.Fragment>
      ))}
    </Column>
  </Box>
);

const OrCondition: React.FC<{
  condition: OrConditionType;
  isInline: boolean;
}> = ({ condition, isInline }) => (
  <Box {...getAndOrConditionStyles({ type: ConditionType.Or, isInline })}>
    <Column gap={3}>
      {condition.conditions.map((subCondition, index) => (
        <React.Fragment key={index}>
          <Condition condition={subCondition} />
          {index! == 0 && <Text fontWeight="medium">OR</Text>}
        </React.Fragment>
      ))}
    </Column>
  </Box>
);

const PropertyCondition: React.FC<{
  condition: PropertyConditionType;
}> = ({ condition }) => {
  const { traits } = useConditionContext();

  const property = useMemo(() => {
    if (
      isRelatedColumn(condition.property) &&
      isTraitColumn(condition.property.column)
    ) {
      const traitId = condition.property.column.traitDefinitionId;
      return {
        name: traits?.find((t) => t.id === traitId)?.name,
        type: "trait" as const,
      };
    }

    return {
      name: getPropertyNameFromProperty(condition.property),
      type: "column" as const,
    };
  }, [condition, traits]);

  return (
    <Row gap={1}>
      {property && <NameWithIcon name={property.name} type={property.type} />}
      <Text color="text.secondary">{condition.operator}</Text>
      {condition.value || OperatorsWithoutValue.includes(condition.operator) ? (
        formatValue(condition, condition.value)
      ) : (
        <Text fontWeight="medium">[ ____ ]</Text>
      )}
    </Row>
  );
};

const ReferencePropertyCondition: React.FC<{
  condition: ReferencedPropertyConditionType;
}> = ({ condition }) => {
  const propertyName = getPropertyNameFromProperty(condition.property);
  const valueFromColumn = condition.valueFromColumn;

  return (
    <Row gap={1}>
      <Text color="text.secondary">value of</Text>
      <NameWithIcon name={propertyName} type="column" />
      <Text color="text.secondary">{condition.operator}</Text>
      <NameWithIcon name={valueFromColumn} type="column" />
    </Row>
  );
};

const EventCondition: React.FC<{ condition: EventConditionType }> = ({
  condition,
}) => {
  const { eventModelId, funnelCondition } = condition;
  const window = funnelCondition?.window;

  const { relationships } = useConditionContext();

  const parentRelationship = useMemo(() => {
    return relationships?.find(
      (r) => r.to_model.id.toString() === eventModelId?.toString(),
    );
  }, [relationships, eventModelId]);

  const funnelRelationship = useMemo(() => {
    if (!funnelCondition?.relationshipId) {
      return null;
    }

    return relationships?.find(
      (r) => r.id.toString() === funnelCondition?.relationshipId?.toString(),
    );
  }, [relationships, funnelCondition?.relationshipId]);

  return (
    <Column gap={3}>
      <Row gap={1}>
        <Text color="text.secondary">Performed</Text>
        {parentRelationship && (
          <NameWithIcon
            name={parentRelationship.to_model.name}
            type={parentRelationship.to_model.event ? "event" : "related"}
          />
        )}
        <Text>{eventOperatorToLabel[condition.operator]}</Text>
        <Text fontWeight="medium">
          {pluralize("time", condition.value, true)}
        </Text>
      </Row>
      <Subconditions conditions={condition.subconditions} />

      {funnelCondition && (
        <Column gap={3}>
          <Column gap={3}>
            <Row gap={1}>
              <Text color="text.secondary">
                {funnelCondition.didPerform
                  ? "Then did perform"
                  : "Then did not perform"}
              </Text>
              {funnelRelationship && (
                <NameWithIcon
                  name={funnelRelationship.name}
                  type={funnelRelationship.to_model.event ? "event" : "related"}
                />
              )}
            </Row>
            {window && (
              <Row gap={1}>
                <Text color="text.secondary" ml={8}>
                  {window.operator}
                </Text>
                {getTimeValue(window.value, window.timeType, true)}
                {parentRelationship && (
                  <>
                    <Text color="text.secondary">of</Text>
                    <NameWithIcon
                      name={parentRelationship.to_model.name}
                      type={
                        parentRelationship.to_model.event ? "event" : "related"
                      }
                    />
                  </>
                )}
              </Row>
            )}
          </Column>
          <Subconditions conditions={condition.subconditions} />
        </Column>
      )}
    </Column>
  );
};

const NumberOfCondition: React.FC<{ condition: NumberOfConditionType }> = ({
  condition,
}) => {
  const { relationships } = useConditionContext();

  const relationship = useMemo(() => {
    return relationships?.find(
      (r) => r.id.toString() === condition.relationshipId?.toString(),
    );
  }, [relationships, condition.relationshipId]);

  return (
    <Column gap={3}>
      <Row gap={1}>
        <Text color="text.secondary">Has</Text>
        <Text fontWeight="medium">
          {numberOperatorToLabel[condition.operator]} {condition.value}
        </Text>
        {relationship && (
          <NameWithIcon
            name={relationship?.to_model.name}
            type={relationship.to_model.event ? "event" : "related"}
          />
        )}
      </Row>
      <Subconditions conditions={condition.subconditions} />
    </Column>
  );
};

const SegmentSetCondition: React.FC<{
  condition: SegmentSetConditionType;
}> = ({ condition }) => {
  const { audiences } = useConditionContext();

  const audience = useMemo(() => {
    return audiences?.find(
      ({ id }) => id.toString() === condition.modelId?.toString(),
    );
  }, [audiences, condition.modelId]);

  return (
    <Row gap={1}>
      <Text color="text.secondary">
        {condition.includes ? "Is included in" : "Is not included in"}
      </Text>
      {audience && <NameWithIcon name={audience.name} type="audience" />}
    </Row>
  );
};

const Subconditions = ({
  conditions,
}: {
  conditions?: AndOrCondition<PropertyConditionType>[];
}) => {
  if (!conditions || conditions.length === 0) {
    return null;
  }

  return (
    <Row alignItems="baseline" gap={1} pl={8}>
      <Text color="text.secondary">Where</Text>
      <Column gap={2}>
        {conditions.map((subCondition, index) => (
          <Condition key={index} condition={subCondition} isInline />
        ))}
      </Column>
    </Row>
  );
};

const NameWithIcon = ({
  name,
  type,
}: {
  name?: string | null;
  type: "audience" | "column" | "event" | "related" | "trait";
}) => {
  if (!name) {
    return null;
  }

  return (
    <Row alignItems="center" gap={1} maxWidth="180px" ml={0.5}>
      <IconBox
        bg={
          type === "column"
            ? PropertyColumn.color
            : type === "trait"
              ? TraitColumn.color
              : type === "audience"
                ? AudienceColumn.color
                : type === "event"
                  ? EventColumn.color
                  : RelationColumn.color
        }
        boxSize={4}
        icon={
          type === "column"
            ? PropertyColumn.icon
            : type === "trait"
              ? TraitColumn.icon
              : type === "audience"
                ? AudienceColumn.icon
                : type === "event"
                  ? EventColumn.icon
                  : RelationColumn.icon
        }
        iconSize={3}
      />
      <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
    </Row>
  );
};

const getAndOrConditionStyles = ({
  type,
  isInline,
}: {
  type: ConditionType.And | ConditionType.Or;
  isInline?: boolean;
}) => {
  return isInline
    ? { py: 1 }
    : {
        borderLeft: "8px solid",
        borderColor: colors.base[type],
        borderRadius: "md",
        pl: 4,
        py: 2,
      };
};
