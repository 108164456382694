import { useState } from "react";

import {
  Box,
  Button,
  Column,
  IconButton,
  Row,
  TextInput,
  Tooltip,
  Switch,
  ArrowRightIcon,
  CloseIcon,
  Text,
} from "@hightouchio/ui";

type enableEncryptionProps = { value: string; isSecret: boolean };

type KeyValueMappingProps = {
  isDisabled?: boolean;
  mapping: any;
  setMapping: any;
  enableEncryption?: boolean;
};

export const KeyValueMapping = ({
  isDisabled = false,
  mapping,
  setMapping,
  enableEncryption,
}: KeyValueMappingProps) => {
  const [newKey, setNewKey] = useState<null | string>(null);
  const [isSecret, setIsSecret] = useState(false);

  const setKeyValueMappingValue = ({
    key,
    value,
  }: {
    key: string;
    value: string | enableEncryptionProps;
  }) => {
    const newMapping = { ...mapping };
    newMapping[key] = value;
    setMapping(newMapping);
  };

  const addValue = (): void => {
    if (!newKey) {
      return;
    }
    const mappingValue = enableEncryption ? { value: "", isSecret } : "";
    setKeyValueMappingValue({ key: newKey, value: mappingValue });
    setNewKey(null);
    setIsSecret(false);
  };

  const changeValue = ({
    key,
    value,
  }: {
    key: string;
    value: string;
  }): void => {
    const valueBasedOnType =
      typeof mapping[key] === "object"
        ? { value, isSecret: (mapping[key]?.isSecret || false) as boolean }
        : value;

    setKeyValueMappingValue({ key, value: valueBasedOnType });
  };

  const retrieveInputValue = (
    mapping: string | { value: string; isSecret: boolean },
  ): string => {
    if (typeof mapping === "string") {
      return mapping;
    }

    if (typeof mapping === "object" && mapping.value) {
      return mapping.value;
    }

    return "";
  };

  const hasHeaders = Object.keys(mapping || {}).length > 0;

  return (
    <Column gap={3}>
      {Object.keys(mapping || {})?.map((key, i) => (
        <Box
          key={i}
          display="grid"
          gap={4}
          gridTemplateColumns="1fr 16px 1fr 36px"
        >
          <TextInput isReadOnly={true} value={key} width="auto" />

          <Row align="center" justify="center">
            <Box as={ArrowRightIcon} fontSize="16px" color="gray.500" />
          </Row>

          <TextInput
            isDisabled={isDisabled}
            placeholder={`Value of ${key}`}
            type={mapping[key]?.isSecret ? "password" : "text"}
            value={retrieveInputValue(mapping[key])}
            width="auto"
            onChange={(value) =>
              changeValue({ key, value: value.target.value })
            }
          />

          <Box flex="none">
            <IconButton
              aria-label="Delete header"
              isDisabled={isDisabled}
              icon={CloseIcon}
              onClick={() => {
                const newMapping = { ...mapping };
                delete newMapping[key];
                setMapping(newMapping);
              }}
            />
          </Box>
        </Box>
      ))}
      <Box
        display="grid"
        gap={4}
        gridTemplateColumns={
          hasHeaders ? "1fr 16px 1fr 36px" : "1fr 16px 1fr 36px"
        }
      >
        <TextInput
          isDisabled={isDisabled}
          placeholder="Key"
          value={newKey || ""}
          width="auto"
          onChange={(value) => setNewKey(value.target.value)}
        />

        <Box />

        <Row
          alignItems="center"
          gridColumn={hasHeaders ? undefined : "span 2 / span 2"}
          justifyContent="flex-end"
        >
          {enableEncryption && (
            <Row align="center" gap={3} mr={4}>
              <Switch
                aria-label="Make value secret."
                isDisabled={isDisabled}
                isChecked={isSecret}
                onChange={(value) => setIsSecret(value)}
              />
              <Text size="sm" fontWeight="semibold">
                Secret
              </Text>
            </Row>
          )}

          <Tooltip
            isDisabled={Boolean(newKey && !mapping?.[newKey])}
            message={
              !newKey
                ? "Enter header key first"
                : "Header with this key already exists"
            }
          >
            <Button
              isDisabled={isDisabled || !newKey || mapping?.[newKey]}
              onClick={addValue}
            >
              Add key
            </Button>
          </Tooltip>
        </Row>

        {hasHeaders && <Box />}
      </Box>
    </Column>
  );
};
