import { FC, useState } from "react";

import { ChannelType } from "@hightouch/lib/resource-monitoring/types";
import {
  Box,
  DeleteIcon,
  EditIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Row,
  Spinner,
  Text,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";

import { ChannelIcon } from "src/pages/alerting/recipients/components/channel-icon";
import { useNavigate } from "src/router";
import { Channel, channelName } from "./channel-definitions";

export const SubscribedResourceItem: FC<{
  channel: Channel;
  onRemove?: () => void | Promise<void>;
  showSuccessToast?: boolean;
}> = ({ channel, onRemove, showSuccessToast = true }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box width="max-content">
      <Menu closeOnSelect={false}>
        <MenuButton>
          <Row align="center" gap={1} fontSize="xl">
            <ChannelIcon
              channelType={channel.channel_type as ChannelType}
              size={4}
            />
            <Text color="text.secondary" fontWeight="medium">
              {channelName(channel)}
            </Text>
          </Row>
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={EditIcon}
            onClick={() => {
              navigate(`/alerting/recipients?channel=${channel.id}`);
            }}
          >
            Edit recipient settings
          </MenuItem>
          <Tooltip
            placement="right"
            isDisabled={Boolean(onRemove)}
            message="This recipient is inherited from the parent resource."
          >
            <MenuItem
              isDisabled={!onRemove}
              variant="danger"
              icon={(props) =>
                isLoading ? <Spinner size="sm" /> : <DeleteIcon {...props} />
              }
              onClick={async () => {
                if (!onRemove) return;

                setIsLoading(true);
                try {
                  await onRemove();
                  if (showSuccessToast) {
                    toast({
                      id: "remove-recipient",
                      title: "Removed recipient",
                      variant: "success",
                    });
                  }
                } catch (e) {
                  captureException(e);
                  toast({
                    id: "remove-recipient",
                    title: "Error removing recipient",
                    variant: "error",
                  });
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              Remove recipient
            </MenuItem>
          </Tooltip>
        </MenuList>
      </Menu>
    </Box>
  );
};

SubscribedResourceItem.displayName = SubscribedResourceItem.name;
