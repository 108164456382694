import { FC, ReactNode } from "react";

import { BoxProps, Row } from "@hightouchio/ui";

export const HStack: FC<{ children: ReactNode } & BoxProps> = ({
  children,
  gap,
  sx,
  ...props
}) => (
  <Row
    align="center"
    width="inherit"
    sx={{
      "& > *:not(:last-child)": { mr: gap, flexShrink: 0 },
      ...sx,
    }}
    {...props}
  >
    {children}
  </Row>
);
