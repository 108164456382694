import { FC } from "react";

import {
  Box,
  Button,
  InformationIcon,
  MeetingIcon,
  Row,
  Select,
  Text,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { noop } from "ts-essentials";
import { DateRangePicker } from "src/components/analytics/date-range-picker";
import { GraphOptionsSelection } from "./common";
import {
  AccumulationTypes,
  LookbackOptions,
  RollupFrequencies,
} from "./state/constants";
import { ChartFormState, TimeOptions } from "./types";
import { formatDatePickerLabel } from "./utils";

type AnalyticsControlsProps = {
  timeValue: TimeOptions;
  setLookbackWindow: (value: TimeOptions) => void;
  selectedDates: Date[];
  setSelectedDates: (dates: Date[]) => void;
  disableAccumulationSelection: boolean;
  showLowConfidenceWarning?: boolean;
};

export const AnalyticsControls: FC<AnalyticsControlsProps> = ({
  timeValue,
  setLookbackWindow,
  selectedDates,
  setSelectedDates,
  disableAccumulationSelection,
  showLowConfidenceWarning = false,
}) => {
  const form = useFormContext<ChartFormState>();

  return (
    <Row
      align="center"
      justify="space-between"
      mt={showLowConfidenceWarning ? 0 : 6}
      pl={6}
      pr={6}
      gap={2}
      width="100%"
    >
      <Row gap={2}>
        <Controller
          control={form.control}
          name="rollupFrequency"
          render={({ field }) => (
            <Select
              options={RollupFrequencies}
              value={field.value}
              width="auto"
              onChange={field.onChange}
              size="sm"
            />
          )}
        />
        <ToggleButtonGroup
          size="sm"
          value={timeValue}
          onChange={(value) => {
            setLookbackWindow(value as TimeOptions);
          }}
        >
          {LookbackOptions.map((option) => (
            <ToggleButton key={option.value} {...option} />
          ))}
        </ToggleButtonGroup>
        <DateRangePicker
          selectedDates={selectedDates}
          onChange={(dates) => {
            setSelectedDates(dates);
          }}
        >
          <Box
            as={Button}
            background={timeValue === TimeOptions.Custom ? "gray.200" : "unset"}
            fontWeight={
              timeValue === TimeOptions.Custom ? "semibold" : "normal"
            }
            icon={MeetingIcon}
            size="sm"
            onClick={noop}
          >
            {formatDatePickerLabel(selectedDates, timeValue)}
          </Box>
        </DateRangePicker>
      </Row>
      <Row align="center" gap={1}>
        <Row gap={2}>
          <Controller
            control={form.control}
            name="cumulative"
            render={({ field }) => (
              <Box width={32}>
                <Select
                  isDisabled={disableAccumulationSelection}
                  optionAccessory={({ icon }) => ({
                    type: "icon",
                    icon,
                  })}
                  options={AccumulationTypes}
                  value={field.value}
                  size="sm"
                  onChange={field.onChange}
                  popoverWidth="2xs"
                  width="100%"
                />
              </Box>
            )}
          />
          <GraphOptionsSelection />
        </Row>
        {disableAccumulationSelection && (
          <Tooltip
            message='Audience size can only be displayed "over time"'
            placement="top-end"
          >
            <Text color="color.secondary">
              <InformationIcon />
            </Text>
          </Tooltip>
        )}
      </Row>
    </Row>
  );
};
