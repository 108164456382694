import { FC } from "react";

import { Column } from "@hightouchio/ui";
import { format as formatDate } from "date-fns";
import { TooltipProps } from "recharts";
import { isPresent } from "ts-extras";

import { GraphTooltip } from "src/components/analytics/graph-tooltip";
import { formatMetricValue, getTooltipSuffixTextFromMetric } from "./utils";
import { shouldUsePercentFormat } from "src/pages/analytics/utils";
import { DataPoint, GraphSeries } from "./types";

type Props = {
  graphSeries: GraphSeries[];
  hoveredLine: string | undefined;
} & TooltipProps<number, string>;

export const Tooltip: FC<Readonly<Props>> = ({
  payload,
  hoveredLine,
  graphSeries,
}) => {
  if (!payload || !hoveredLine) return null;

  // The first payload will be the dataset that is being hovered over
  const calculatedAt = payload[0]?.payload.calculatedAt as
    | DataPoint["calculatedAt"]
    | undefined;

  if (!calculatedAt) return null;

  // Find the series that corresponds to the hovered line
  const series = graphSeries.find((series) => series.key === hoveredLine);

  // Find the data point in that series
  const point = series?.data.find(
    (point) => point.calculatedAt === calculatedAt,
  );

  if (!series || !point) return null;

  const displayedValue = isPresent(point.metricValue)
    ? formatMetricValue(
        point.metricValue,
        shouldUsePercentFormat(series.aggregation, series.normalization),
      )
    : "Not available";

  const subtitles = [formatDate(new Date(point.calculatedAt), "LLL d, yyyy")];

  if (series.description && series.description !== series.metricName) {
    subtitles.unshift(series.description);
  }

  return (
    <Column bg="gray.900" padding={2} borderRadius="md" gap={2}>
      <GraphTooltip
        color={series.color}
        title={series.metricName}
        subtitles={subtitles}
        value={[
          {
            value: displayedValue,
            description: series.aggregation
              ? getTooltipSuffixTextFromMetric(
                  series.aggregation,
                  series.normalization,
                )
              : undefined,
          },
        ]}
      />
    </Column>
  );
};
