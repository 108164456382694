import { useMemo, useState } from "react";

import {
  Menu,
  Button,
  MenuButton,
  MenuDivider,
  MenuIconButton,
  MenuItem,
  MenuList,
  PlusIcon,
  Row,
  useToast,
  Column,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { ChannelType } from "@hightouch/lib/resource-monitoring/types";

import { ChannelIcon } from "src/pages/alerting/recipients/components/channel-icon";
import {
  Channel,
  channelName,
} from "src/components/notification-channels/channel-definitions";

export const AddRecipientButton = ({
  onAdd,
  onCreate,
  channels,
  isIcon = false,
  showSuccessToast = true,
}: {
  onAdd: (channel: Channel) => void | Promise<void>;
  onCreate: () => void | Promise<void>;
  channels: Array<Channel>;
  isIcon?: boolean;
  showSuccessToast?: boolean;
}) => {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const addRecipient = async (channel: Channel) => {
    setIsLoading(true);
    try {
      await onAdd(channel);
      if (showSuccessToast) {
        toast({
          id: "add-recipient",
          title: "Successfully added recipient",
          variant: "success",
        });
      }
    } catch (e) {
      captureException(e);
      toast({
        id: "add-recipient-error",
        title: "Error adding recipient",
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const AddMenuItem = ({ channel }: { channel: Channel }) => {
    return (
      <MenuItem onClick={(_event) => addRecipient(channel)}>
        <Row align="center" gap={4}>
          <ChannelIcon channelType={channel.channel_type as ChannelType} />
          {channelName(channel)}
        </Row>
      </MenuItem>
    );
  };

  const sortedChannels = useMemo(
    () =>
      channels.sort(
        (a, b) =>
          a.channel_type.localeCompare(b.channel_type) ||
          channelName(a).localeCompare(channelName(b)),
      ),
    [channels],
  );

  const list = (
    <MenuList>
      <Column maxH="400px" overflow="auto" minW="sm">
        {sortedChannels.map((channel) => (
          <AddMenuItem key={channel.id} channel={channel} />
        ))}
      </Column>
      <MenuDivider />
      <MenuItem onClick={onCreate} icon={PlusIcon}>
        Create new recipient
      </MenuItem>
    </MenuList>
  );

  if (isIcon) {
    return (
      <Menu>
        <MenuIconButton
          aria-label="Add recipients"
          icon={PlusIcon}
          variant="secondary"
          isLoading={isLoading}
        />
        {list}
      </Menu>
    );
  }

  if (!channels.length) {
    return (
      <Button onClick={onCreate} variant="primary">
        Add new recipient
      </Button>
    );
  }

  return (
    <Menu>
      <MenuButton variant="primary" isLoading={isLoading}>
        Add recipients
      </MenuButton>
      {list}
    </Menu>
  );
};
