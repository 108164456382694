import { type FC, useEffect, useRef, useState } from "react";

import {
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Column,
  ExternalLinkIcon,
  Portal,
  Row,
  StatusIndicator,
  Text,
} from "@hightouchio/ui";
import { RouterLink } from "src/router";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { AnimatedBox } from "src/ui/animations";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  getSyncStatusColor,
  syncHealthOrUnknown,
  SyncHealthStatus,
  SyncRunStatus,
  SyncRunStatusOrUnknownStatus,
  syncRunStatusOrUnknownStatus,
  syncRunStatusToDescription,
  syncRunStatusToIndicatorVariant,
  UnknownSyncRunStatus,
} from "src/utils/syncs";
import { useUser } from "src/contexts/user-context";
import type {
  DestinationDefinition,
  Maybe,
  RenderedMonitorConditionStatus,
  SyncRequests,
} from "@hightouch/core/server/graphql/types";
import { format } from "date-fns";
import { SyncRunStatusBadge } from "src/components/syncs/sync-run-status-badge";
import { useAlertsForSyncs } from "src/hooks/use-alerts-for-syncs";
import { ElementOf } from "ts-essentials";
import { SyncAlertsBadge } from "src/components/syncs/sync-alerts-badge";
import {
  SkeletonSyncAlertCard,
  SyncAlertCard,
} from "src/components/syncs/sync-alert-card";
import type { Sync } from "src/pages/syncs/sync/utils/types";
import { MonitoredResourceType } from "@hightouch/lib/resource-monitoring/types";

type MinimalSyncProperties = {
  sync_requests: Array<
    Pick<SyncRequests, "id" | "created_at" | "status_computed">
  >;
  destination: Maybe<{
    name: Maybe<string>;
    definition: Pick<DestinationDefinition, "name" | "icon">;
  }>;
};

export type Props = {
  syncs: (Pick<
    Sync,
    "id" | "status" | "health" | "schedule_paused" | "last_run_at"
  > &
    MinimalSyncProperties)[];
  getSyncLink?: (syncId: string) => string;
  getRunLink?: (syncId: string, runId: string) => string;
  resourceType?: MonitoredResourceType.Sync | MonitoredResourceType.EventSync;
};

const gap = 8;

const variants = {
  expand: (index) => ({
    x: index * gap,
  }),
  collapse: (index) => ({
    x: index * -gap,
  }),
};

export const SyncsCell: FC<Readonly<Props>> = ({
  syncs,
  getSyncLink: getSyncLink = (syncId) => `/syncs/${syncId}`,
  getRunLink: getRunLink = (syncId, runId) => `/syncs/${syncId}/runs/${runId}`,
  resourceType = MonitoredResourceType.Sync,
}) => {
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const [expand, setExpand] = useState<boolean>(false);
  const [openSyncIndex, setOpenSyncIndex] = useState<number | undefined>();
  const parent = useRef<HTMLDivElement | null>(null);
  const { workspace } = useUser();
  const alertingEnabled = workspace?.alerting_v2_enabled || false;

  useEffect(() => {
    if (expand) {
      if (!mouseOver && openSyncIndex === undefined) {
        setExpand(false);
      }
    } else if (mouseOver || openSyncIndex !== undefined) {
      setExpand(true);
    }
  }, [mouseOver, expand, openSyncIndex]);

  if (syncs?.length === 0) {
    return (
      <Text fontWeight="medium" color="text.placeholder">
        No syncs
      </Text>
    );
  }

  const visibleSyncs = syncs?.slice(0, 4);
  const collapsedSyncs = syncs?.slice(4, syncs?.length);

  return (
    <Row
      align="center"
      ref={parent}
      onMouseEnter={() => {
        setMouseOver(true);
      }}
      onMouseLeave={() => {
        setMouseOver(false);
        setOpenSyncIndex(undefined);
      }}
      w="100%"
      h="100%"
      // This is a little gross, but I'm trying to leave a healthy margin before we dismiss the tooltip so we don't force users to sharpshoot if they want to click on it.
      m="-10px"
      p="10px"
    >
      {visibleSyncs?.map((sync, index) => {
        const syncStatusColor = getSyncStatusColor(
          alertingEnabled && sync.health
            ? syncHealthOrUnknown(sync.health)
            : syncRunStatusOrUnknownStatus(sync.status),
        );
        const isOpen = index === openSyncIndex;
        if (!sync.id) return null;
        return (
          <AnimatedBox
            key={sync.id}
            animate={expand ? "expand" : "collapse"}
            custom={index}
            variants={variants}
          >
            <ChakraPopover
              isLazy
              placement="top-start"
              isOpen={isOpen}
              onClose={() => {
                setOpenSyncIndex(undefined);
              }}
            >
              <RouterLink to={getSyncLink(sync.id)}>
                <ChakraPopoverTrigger>
                  <Row
                    onMouseEnter={(e) => {
                      e.stopPropagation();
                      setOpenSyncIndex(isOpen ? undefined : index);
                    }}
                    align="center"
                    justify="center"
                    w="30px"
                    h="30px"
                    borderRadius="50%"
                    bg="white"
                    border="1px"
                    borderColor={syncStatusColor}
                  >
                    <IntegrationIcon
                      size={4}
                      name={sync.destination?.definition?.name}
                      src={sync.destination?.definition?.icon}
                    />
                  </Row>
                </ChakraPopoverTrigger>
              </RouterLink>
              <Portal>
                <ChakraPopoverContent w="fit-content">
                  <ChakraPopoverBody p={0} px={3}>
                    <SyncDisplay
                      resourceType={resourceType}
                      sync={sync}
                      getRunLink={getRunLink}
                      getSyncLink={getSyncLink}
                    />
                  </ChakraPopoverBody>
                </ChakraPopoverContent>
              </Portal>
            </ChakraPopover>
          </AnimatedBox>
        );
      })}
      {collapsedSyncs?.length > 0 && (
        <AnimatedBox
          animate={expand ? "expand" : "collapse"}
          custom={4}
          variants={variants}
        >
          <ChakraPopover
            isLazy
            placement="top-end"
            isOpen={openSyncIndex === 6}
            onClose={() => {
              setOpenSyncIndex(undefined);
            }}
          >
            <ChakraPopoverTrigger>
              <Row
                align="center"
                justify="center"
                w="30px"
                h="30px"
                borderRadius="50%"
                bg="gray.200"
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  setOpenSyncIndex(openSyncIndex === 6 ? undefined : 6);
                }}
              >
                <Text size="sm">+{collapsedSyncs.length}</Text>
              </Row>
            </ChakraPopoverTrigger>
            <Portal>
              <ChakraPopoverContent maxH="80vh">
                <ChakraPopoverBody
                  p={0}
                  display="flex"
                  flexDir="column"
                  gap={2}
                  overflow="auto"
                  px={3}
                >
                  {collapsedSyncs.map((sync) => {
                    if (!sync.id) return null;
                    return (
                      <Row
                        key={sync.id}
                        _notLast={{
                          borderBottom: "1px",
                          borderColor: "base.divider",
                        }}
                        pb={2}
                      >
                        <SyncDisplay
                          resourceType={resourceType}
                          sync={sync}
                          getRunLink={getRunLink}
                          getSyncLink={getSyncLink}
                          condensed
                        />
                      </Row>
                    );
                  })}
                </ChakraPopoverBody>
              </ChakraPopoverContent>
            </Portal>
          </ChakraPopover>
        </AnimatedBox>
      )}
    </Row>
  );
};

const SyncDisplay = ({
  sync,
  getRunLink: getRunLink,
  getSyncLink: getSyncLink = (syncId) => `/syncs/${syncId}`,
  condensed = false,
  resourceType,
}: {
  getSyncLink: (syncId: string) => string;
  getRunLink: (syncId: string, runId: string) => string;
  sync: ElementOf<Props["syncs"]>;
  resourceType: MonitoredResourceType.Sync | MonitoredResourceType.EventSync;
  condensed?: boolean;
}) => {
  const { id, destination, sync_requests, last_run_at } = sync;
  if (!id) return null;
  const request = sync_requests?.[0];

  const definition = destination?.definition;
  const name = destination?.name;

  const verifiedLastRunStatus = request
    ? syncRunStatusOrUnknownStatus(request.status_computed)
    : SyncRunStatus.PENDING;

  const { workspace } = useUser();
  const alertingEnabled = workspace?.alerting_v2_enabled ?? false;

  const { isLoading: alertsLoading, data: alertsBySync } = useAlertsForSyncs(
    alertingEnabled ? [String(id)] : [],
    resourceType,
  );
  const alerts = alertsBySync?.[String(id)]?.alerts;

  const SyncDetailsDisplay = () => {
    const syncDisabled = sync.schedule_paused;
    const noRunsYet = !last_run_at || !request;

    if (syncDisabled || noRunsYet) return <></>;

    if (!alertingEnabled) {
      return (
        <LastRunDisplay
          runLink={
            noRunsYet ? getSyncLink(sync.id) : getRunLink(sync.id, request.id)
          }
          request={request}
          verifiedLastRunStatus={verifiedLastRunStatus}
        />
      );
    } else {
      if (sync.health === SyncHealthStatus.Healthy) return <></>;
      if (alertsLoading) return <SkeletonSyncAlertCard />;
      return (
        <SyncAlertsDisplay
          alerts={alerts || []}
          maxAlerts={condensed ? 1 : 3}
        />
      );
    }
  };

  return (
    <Column py={2} w="315px" gap={2}>
      <Row justifyContent="space-between" gap={2} w="full">
        <Row
          align="center"
          gap={2}
          flex="1 1 0%"
          overflow="hidden"
          as={RouterLink}
          to={getSyncLink(id)}
        >
          <IntegrationIcon
            name={definition?.name}
            src={definition?.icon}
            size={5}
          />
          <TextWithTooltip fontWeight="semibold" sx={{ w: "100%" }}>
            {name ?? definition?.name ?? "Private destination"}
          </TextWithTooltip>
          {condensed && (
            <Text size="lg">
              <ExternalLinkIcon color="text.tertiary" />
            </Text>
          )}
        </Row>
        <Row>
          {resourceType === MonitoredResourceType.EventSync &&
          sync.status === SyncRunStatus.ACTIVE ? (
            <SyncRunStatusBadge status={sync.status} />
          ) : alertingEnabled ? (
            <SyncAlertsBadge
              alerts={alerts}
              disableTooltip
              isLoading={alertsLoading}
              sync={sync}
            />
          ) : (
            verifiedLastRunStatus !== UnknownSyncRunStatus && (
              <SyncRunStatusBadge status={verifiedLastRunStatus} />
            )
          )}
        </Row>
      </Row>
      <SyncDetailsDisplay />
      {!condensed && (
        <RouterLink to={getSyncLink(id)}>
          <Text size="sm" color="text.tertiary">
            Click to view sync details
          </Text>
        </RouterLink>
      )}
    </Column>
  );
};

const LastRunDisplay = ({
  verifiedLastRunStatus,
  request,
  runLink,
}: {
  verifiedLastRunStatus: SyncRunStatusOrUnknownStatus;
  request?: Pick<SyncRequests, "id" | "created_at" | "status_computed">;
  runLink: string;
}) => {
  return verifiedLastRunStatus === UnknownSyncRunStatus ? null : (
    <RouterLink to={runLink}>
      <Column
        p={2}
        backgroundColor="base.background"
        gap={1}
        borderLeft="2px solid"
        borderColor={getSyncStatusColor(verifiedLastRunStatus)}
        borderRadius="0px 4px 4px 0px"
      >
        <Row align="center" gap={4}>
          <StatusIndicator
            variant={syncRunStatusToIndicatorVariant(verifiedLastRunStatus)}
          >
            <Text size="sm" fontWeight="medium">
              {syncRunStatusToDescription[verifiedLastRunStatus]}
            </Text>
          </StatusIndicator>
        </Row>
        <Text size="sm" ml={4} color="text.secondary">
          {request?.created_at &&
            format(new Date(request.created_at), "MMMM dd, yyyy · h:mm aa")}
        </Text>
      </Column>
    </RouterLink>
  );
};

const SyncAlertsDisplay = ({
  alerts,
  maxAlerts,
}: {
  alerts: RenderedMonitorConditionStatus[];
  maxAlerts: number;
}) => {
  return (
    <Column gap={2}>
      {alerts
        ?.slice(0, maxAlerts)
        .map((a) => <SyncAlertCard alert={a} variant="light" key={a.id} />)}
      {alerts.length > maxAlerts && (
        <Text size="sm" fontWeight="medium">
          +{alerts.length - maxAlerts} more
        </Text>
      )}
    </Column>
  );
};
