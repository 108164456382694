import {
  Column,
  Combobox,
  FormField,
  Row,
  SectionHeading,
  Select,
  Text,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";

import { Card } from "src/components/card";
import { useModelColumnsQuery } from "src/graphql";
import { useOutletContext } from "src/router";
import { OutletContext } from "../..";
import TIME_ZONES from "./iana-time-zones.json";
import * as y from "yup";

export const timezoneColumnSchema = y
  .object()
  .shape(
    {
      name: y.string().when("format", {
        is: (v) => Boolean(v),
        then: y
          .string()
          .nullable()
          .required("You must select a time zone column"),
        otherwise: y.string().nullable(),
      }),
      format: y.string().when("name", {
        is: (v) => Boolean(v),
        then: y
          .string()
          .nullable()
          .required("You must select a time zone format"),
        otherwise: y.string().nullable(),
      }),
    },
    [["name", "format"]],
  )
  .nullable();

export const TimeZoneSettings = () => {
  const context = useOutletContext<OutletContext>();

  const { data: columns, isLoading } = useModelColumnsQuery(
    {
      modelId: context.engine.segment.id,
    },
    { select: (data) => data.model_columns },
  );

  const { formState } = useFormContext();

  return (
    <Card gap={4}>
      <Column>
        <SectionHeading>Time zone</SectionHeading>
        <Text color="text.secondary">
          Configure the time zone for your agents
        </Text>
      </Column>
      <FormField
        isOptional
        label="Time zone column"
        description="Column and format to use for time zone"
        error={
          (formState.errors as any)?.config?.timezone_column?.format?.message ||
          (formState.errors as any)?.config?.timezone_column?.name?.message
        }
      >
        <Row gap={2}>
          <Controller
            name="config.timezone_column.name"
            render={({ field, fieldState: { error } }) => (
              <Combobox
                isClearable
                isInvalid={Boolean(error)}
                placeholder="Select time zone column..."
                {...field}
                onChange={(value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    field.onChange(null);
                  }
                }}
                width="2xs"
                isLoading={isLoading}
                optionValue={(option) => option.name}
                optionLabel={(option) => option.alias ?? option.name}
                options={columns ?? []}
              />
            )}
          />
          <Controller
            name="config.timezone_column.format"
            render={({ field, fieldState: { error } }) => (
              <Select
                isClearable
                placeholder="Select format..."
                isInvalid={Boolean(error)}
                {...field}
                onChange={(value) => {
                  if (value) {
                    field.onChange(value);
                  } else {
                    field.onChange(null);
                  }
                }}
                width="2xs"
                options={[
                  {
                    label: "IANA",
                    description: "e.g. America/Los_Angeles",
                    value: "iana",
                  },
                  {
                    label: "UTC Offset",
                    description: "e.g. -0800",
                    value: "offset_string",
                  },
                ]}
              />
            )}
          />
        </Row>
      </FormField>
      <Controller
        name="config.scheduling_timezone"
        render={({ field }) => (
          <FormField
            isOptional
            label="Fallback time zone"
            description="Time zone to use when no user-level time zone is available"
            tip="If left blank, the system default time zone is America/Los_Angeles"
          >
            <Combobox
              isClearable
              placeholder="Select a fallback time zone..."
              options={Object.keys(TIME_ZONES).map((tz) => ({
                label: tz,
                value: tz,
              }))}
              {...field}
              onChange={(value) => {
                if (value) {
                  field.onChange(value);
                } else {
                  field.onChange(null);
                }
              }}
            />
          </FormField>
        )}
      />
    </Card>
  );
};
