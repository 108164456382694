import {
  Column,
  Combobox,
  ComboboxProps,
  GroupedCombobox,
  GroupedComboboxProps,
} from "@hightouchio/ui";

export const ComboboxFilter = <
  Option,
  OptionValue extends Option[keyof Option],
>(
  props: Readonly<ComboboxProps<Option, OptionValue>>,
) => {
  return (
    <Column
      sx={getEmptyValueStyles({
        isEmpty: props.value == undefined,
        isInvalid: Boolean(props.isInvalid),
      })}
    >
      <Combobox {...props} />
    </Column>
  );
};

export const GroupedComboboxFilter = <
  Option,
  OptionValue extends Option[keyof Option],
>(
  props: Readonly<GroupedComboboxProps<Option, OptionValue>>,
) => {
  return (
    <Column
      sx={getEmptyValueStyles({
        isEmpty: props.value == undefined,
        isInvalid: Boolean(props.isInvalid),
      })}
    >
      <GroupedCombobox {...props} />
    </Column>
  );
};

// These are styles that should be passed to the container of a combobox.
// They'll be injected into the input of the combobox.
// Ideally, we'd generalize this by creating a new variant for the combobox.
// However, it's not clear if these styles will be used in other parts of the app,
// so for now we'll specialize them here.
const getEmptyValueStyles = ({
  isEmpty,
  isInvalid,
}: {
  isEmpty: boolean;
  isInvalid: boolean;
}) => {
  return {
    input: { fontWeight: "medium" },
    ...(isEmpty && !isInvalid
      ? {
          // Apply these styles when the combobox is collapsed
          "& > div[aria-expanded='false']": {
            bg: "forest.background",
            borderColor: "forest.border",
            borderStyle: "dashed",
            "input::placeholder": {
              color: "forest.base",
            },
          },
        }
      : {}),
  };
};
