import { Column } from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import countBy from "lodash/countBy";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";

import { DestinationForm } from "src/components/destinations/sync-form";
import { Warning } from "src/components/warning";
import { useOutletContext } from "src/router";

import { SyncTemplateOutletContext } from "./types";

export const SyncTemplateConfiguration = () => {
  const { dependentSyncs, syncTemplate, onUpdateConfig } =
    useOutletContext<SyncTemplateOutletContext>();

  const destination = syncTemplate.destination;
  const source = syncTemplate.segment.connection;

  const syncOverrides: Record<string, any>[] = dependentSyncs
    .map(({ sync_template_overrides }) =>
      omit(sync_template_overrides, ["configVersion"]),
    )
    .filter((overrides) => !isEmpty(overrides));

  const relatedSyncsWithOverrides = countBy(
    syncOverrides.flatMap((config) => Object.keys(config)),
  );

  if (!source) {
    captureException(`Source not found for sync template ${syncTemplate.id}`);
    return (
      <Warning title="Source not found" subtitle="It may have been deleted." />
    );
  }

  return (
    <Column pb={20}>
      <DestinationForm
        isSyncForm={false}
        testPermission={{
          v1: { resource: "sync_template", grant: "update" },
          v2: {
            resource: "model",
            grant: "can_update",
            id: syncTemplate.segment.id,
          },
        }}
        permission={{
          v1: { resource: "sync_template", grant: "update" },
          v2: {
            resource: "model",
            grant: "can_update",
            id: syncTemplate.segment.id,
          },
        }}
        destination={destination}
        destinationDefinition={destination?.definition}
        model={syncTemplate.segment}
        slug={destination?.definition?.type}
        sourceDefinition={source.definition}
        syncConfig={syncTemplate.config} // sync config to populate the form
        syncTemplate={syncTemplate}
        relatedSyncsWithOverrides={relatedSyncsWithOverrides}
        onSubmit={async (config) => {
          onUpdateConfig(config);
        }}
      />
    </Column>
  );
};
