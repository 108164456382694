// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { Route, Routes } from "react-router-dom";

import { PermissionedRoute } from "src/components/router/permissioned-route";
import { TemplatesRoutes } from "src/pages/templates";
import { CampaignsRoutes } from "src/pages/campaigns";
import { Copilot } from "src/pages/copilot";
import { DecisionEngineRoutes } from "src/pages/decision-engines";
import { ExtensionsRouter } from "src/pages/extensions";
import { GovernanceRoutes } from "src/pages/governance";
import { MetricsRoutes } from "src/pages/metrics/index";
import { MiscRoutes } from "src/pages/misc";
import { NotFound } from "src/pages/not-found";
import { OrganizationRoutes } from "src/pages/organizations";
import { PriorityListsRouter } from "src/pages/priority-lists";
import { SequenceRoutes } from "src/pages/sequences";
import { SettingsRouter } from "src/pages/settings";
import { Setup } from "src/pages/setup";
import { Traits } from "src/pages/traits/traits";
import { homeRoute } from "./constants";
import { Navigate } from "src/router";

/**
 * These need to be migrated to the new style of routes
 * @deprecated
 */
export const SlugRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      <Route path="onboarding/*" element={<Setup />} />
      <Route path="overview" element={<Navigate to={homeRoute} />} />
      <Route path="sequences/*" element={<SequenceRoutes />} />
      <Route path="extensions/*" element={<ExtensionsRouter />} />
      <Route path="settings/*" element={<SettingsRouter />} />
      <Route
        path="metrics/*"
        element={
          <PermissionedRoute
            permission={{
              v1: { resource: "audience_schema", grant: "create" },
            }}
            redirect={homeRoute}
          >
            <MetricsRoutes />
          </PermissionedRoute>
        }
      />
      <Route path="priority-lists/*" element={<PriorityListsRouter />} />
      <Route path="copilot" element={<Copilot />} />
      <Route path="campaigns/*" element={<CampaignsRoutes />} />
      <Route path="traits/*" element={<Traits />} />
      <Route path="organization/*" element={<OrganizationRoutes />} />
      <Route path="ai/*" element={<DecisionEngineRoutes />} />
      <Route path="governance/*" element={<GovernanceRoutes />} />
      <Route path="templates/*" element={<TemplatesRoutes />} />
      <Route path="misc/*" element={<MiscRoutes />} />
    </Routes>
  );
};
