export const Icon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    fill={color}
    viewBox="0 0 24 24"
  >
    <path
      fillRule="evenodd"
      d="m12 1.353 8.952 7.35a1.5 1.5 0 0 1 .548 1.159V20.5A1.5 1.5 0 0 1 20 22h-4.5a1.5 1.5 0 0 1-1.5-1.5v-5.361a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v5.36A1.5 1.5 0 0 1 8.5 22H4a1.5 1.5 0 0 1-1.5-1.5V9.863a1.5 1.5 0 0 1 .548-1.16L12 1.353Zm0 1.294L3.683 9.475a.5.5 0 0 0-.183.387V20.5a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-5.361a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5v5.36a.5.5 0 0 0 .5.5H20a.5.5 0 0 0 .5-.5V9.863a.5.5 0 0 0-.183-.387L12 2.647Z"
      clipRule="evenodd"
    />
  </svg>
);
