import { DECISION_ENGINE_SUPPORTED__DESTINATIONS } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  DrawerBody,
  DrawerHeader,
  FormField,
  Heading,
  IconButton,
  Radio,
  RadioGroup,
  CloseIcon,
  Row,
  DrawerFooter,
  Column,
} from "@hightouchio/ui";
import { Controller } from "react-hook-form";

import { Drawer } from "src/components/drawer";
import { Form, SubmitButton, useHightouchForm } from "src/components/form";
import { ChannelForms } from "./components/channel-forms";
import {
  useCreateDecisionEngineChannelMutation,
  useDestinationsQuery,
} from "src/graphql";
import { useNavigate, useOutletContext } from "src/router";
import { OutletContext } from "..";

export const AddChannel = () => {
  const { engine } = useOutletContext<OutletContext>();
  const navigate = useNavigate();

  const createMutation = useCreateDecisionEngineChannelMutation();

  const form = useHightouchForm({
    success: "Channel added successfully",
    onSubmit: async (data) => {
      await createMutation.mutateAsync({
        input: {
          type: data.type,
          destination_id: data.destination_id,
          enabled: true,
          decision_engine_id: engine.id,
          config: data.config,
        },
      });
      navigate("/ai/configuration");
    },
    defaultValues: {
      type: "email",
      destination_id: "",
      segment_id: engine.segment.id,
      config: {},
    },
  });

  const type = form.watch("type");

  const supportedDestinations = DECISION_ENGINE_SUPPORTED__DESTINATIONS[type];

  const { data: destinations } = useDestinationsQuery(
    supportedDestinations
      ? {
          filters: {
            type: {
              _in: supportedDestinations,
            },
          },
        }
      : undefined,
    { select: (data) => data.destinations, suspense: true },
  );

  const ChannelForm = ChannelForms[type];

  return (
    <Form form={form}>
      <Drawer
        size="lg"
        isOpen
        onClose={() => {
          navigate("/ai/configuration");
        }}
      >
        <DrawerHeader>
          <Row width="100%" align="center" justify="space-between">
            <Heading>Add a channel</Heading>
            <IconButton
              aria-label="Close"
              icon={CloseIcon}
              onClick={() => {
                navigate("/ai/configuration");
              }}
            />
          </Row>
        </DrawerHeader>
        <DrawerBody>
          <Column gap={6}>
            <FormField label="Select a channel type">
              <Controller
                name="type"
                render={({ field }) => (
                  <RadioGroup {...field} value={field.value}>
                    <Radio
                      value="email"
                      label="Email"
                      description="Send personalized email messages to your users."
                    />
                    <Radio
                      value="sms"
                      label="SMS"
                      description="Send personalized text messages to your users."
                    />
                    <Radio
                      value="push"
                      label="Push"
                      description="Send personalized push notifications to users."
                    />
                    <Radio
                      value="raw"
                      label="Custom"
                      description="Send personalized decisions to any destination in our catalog."
                    />
                  </RadioGroup>
                )}
              />
            </FormField>
            {type && (
              <ChannelForm destinations={destinations ?? []} type={type} />
            )}
          </Column>
        </DrawerBody>
        <DrawerFooter>
          <SubmitButton>Add channel</SubmitButton>
        </DrawerFooter>
      </Drawer>
    </Form>
  );
};
