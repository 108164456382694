import {
  Column,
  EmptyState,
  FormField,
  Heading,
  Text,
  TextInput,
} from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { Card } from "src/components/card";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { useUpdateDecisionEngineOutcomeMutation } from "src/graphql";
import { useOutletContext } from "src/router";
import { DecisionEngine, OutletContext } from "..";

export const Outcomes = () => {
  const { engine } = useOutletContext<OutletContext>();

  // Flatten all outcomes from all flows
  const allOutcomes = engine.flows.flatMap((flow) =>
    flow.outcomes.map((flowOutcome) => flowOutcome.outcome),
  );

  if (!allOutcomes.length) {
    return <EmptyState title="No outcomes" message="" />;
  }

  // Group outcomes by flow
  return (
    <Column gap={6}>
      {engine.flows.map((flow) => {
        if (!flow.outcomes.length) return null;

        return (
          <Column key={flow.id} gap={4}>
            <Column>
              <Heading>{flow.name}</Heading>
              <Text size="sm" color="text.secondary">
                {flow.id}
              </Text>
            </Column>

            <Column gap={4}>
              {flow.outcomes.map((flowOutcome) => (
                <Outcome
                  key={flowOutcome.outcome.id}
                  outcome={flowOutcome.outcome}
                />
              ))}
            </Column>
          </Column>
        );
      })}
    </Column>
  );
};

const Outcome = ({
  outcome,
}: {
  outcome: DecisionEngine["flows"][0]["outcomes"][0]["outcome"];
}) => {
  const mutation = useUpdateDecisionEngineOutcomeMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await mutation.mutate({
        id: outcome.id,
        input: {
          attribution: data.attribution,
          weight: data.weight,
        },
        appendInput: {},
      });
    },
    values: {
      attribution: outcome.attribution,
      weight: outcome.weight,
    },
  });

  return (
    <Form form={form}>
      <Card footer={<FormActions />} gap={4}>
        <Column>
          <Heading>{outcome.name}</Heading>
          <Text size="sm" color="text.secondary">
            {outcome.id}
          </Text>
        </Column>

        <Controller
          name="attribution.metadata_column"
          control={form.control}
          render={({ field }) => (
            <FormField label="Metadata Column">
              <TextInput {...field} />
            </FormField>
          )}
        />

        <Controller
          name="weight.reward_value"
          control={form.control}
          render={({ field }) => (
            <FormField label="Reward Value">
              <TextInput
                {...field}
                type="number"
                onChange={(e) =>
                  field.onChange(e.target.value ? Number(e.target.value) : null)
                }
              />
            </FormField>
          )}
        />
      </Card>
    </Form>
  );
};
