import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  Alert,
  Button,
  ButtonGroup,
  Column,
  ConfirmationDialog,
  Paragraph,
  StatusIndicator,
  useToast,
} from "@hightouchio/ui";
import { useState } from "react";
import { DetailPage } from "src/components/layout";
import { useUser } from "src/contexts/user-context";
import {
  useClearCachedStateMutation,
  useResetAttributionStateMutation,
  useResetStateMutation,
  useUpdateDecisionEngineMutation,
  useValidateDecisionEngineConfigQuery,
} from "src/graphql";
import { Navigate, Outlet, useOutletContext } from "src/router";
import { OutletContext } from "..";

export const Admin = () => {
  const { user } = useUser();
  const context = useOutletContext<OutletContext>();
  const { engine } = context;
  const { toast } = useToast();

  const [resetStateOpen, setResetStateOpen] = useState<boolean>(false);
  const [resetAttributionStateOpen, setResetAttributionStateOpen] =
    useState<boolean>(false);
  const [clearCachedStateOpen, setClearCachedStateOpen] =
    useState<boolean>(false);
  const updateMutation = useUpdateDecisionEngineMutation();
  const validateQuery = useValidateDecisionEngineConfigQuery({
    decisionEngineId: engine.id,
  });
  const resetMutation = useResetStateMutation();
  const resetAttributionMutation = useResetAttributionStateMutation();
  const clearCachedStateMutation = useClearCachedStateMutation();
  const isValid =
    validateQuery.data?.validateDecisionEngineConfig.__typename ===
    "DecisionEngineSuccess";

  if (!user?.can_impersonate) {
    return <Navigate to="/ai/flows" />;
  }

  return (
    <DetailPage
      title="AI Admin"
      heading="AI Admin"
      tabs={[
        {
          title: "Engine",
          path: "engine",
        },
        {
          title: "Agents",
          path: "agents",
        },
        {
          title: "Outcomes",
          path: "outcomes",
        },
        {
          title: "Collections",
          path: "collections",
        },
        {
          title: "Insights",
          path: "insights",
        },
        {
          title: "Demo",
          path: "demo",
        },
      ]}
      actions={
        <ButtonGroup size="lg">
          {engine.status === DecisionEngineStatus.PENDING ? (
            <Button
              variant="primary"
              isDisabled={!isValid}
              onClick={async () => {
                try {
                  await updateMutation.mutateAsync({
                    id: engine.id,
                    input: { status: DecisionEngineStatus.READY },
                  });
                } catch (error) {
                  toast({
                    id: "ready",
                    title: "Error",
                    message: error.message,
                    variant: "error",
                  });
                }
              }}
            >
              Ready engine
            </Button>
          ) : (
            <>
              <Button
                onClick={async () => {
                  try {
                    await updateMutation.mutate({
                      id: engine.id,
                      input: { status: DecisionEngineStatus.PENDING },
                    });
                  } catch (error) {
                    toast({
                      id: "ready",
                      title: "Error",
                      message: error.message,
                      variant: "error",
                    });
                  }
                }}
              >
                Unready engine
              </Button>
              <Button
                variant="danger"
                onClick={() => setResetStateOpen(true)}
                isDisabled={resetMutation.isLoading}
              >
                Reset warehouse state
              </Button>
              <Button
                variant="danger"
                onClick={() => setResetAttributionStateOpen(true)}
                isDisabled={resetAttributionMutation.isLoading}
              >
                Reset attribution state
              </Button>
            </>
          )}
          <Button
            variant="warning"
            onClick={() => setClearCachedStateOpen(true)}
            isDisabled={clearCachedStateMutation.isLoading}
          >
            Clear cached state
          </Button>
          <Tooltip
            message={isValid ? "Valid" : "Invalid"}
            isDisabled={validateQuery.isFetching}
          >
            <Button
              isLoading={validateQuery.isFetching}
              onClick={() => {
                validateQuery.refetch();
              }}
            >
              <StatusIndicator variant={isValid ? "success" : "error"}>
                Validate config
              </StatusIndicator>
            </Button>
          </Tooltip>
        </ButtonGroup>
      }
    >
      <Column gap={6}>
        {validateQuery.data?.validateDecisionEngineConfig.__typename ===
          "DecisionEngineError" && (
          <Alert
            type="error"
            title="Validation error"
            message={JSON.stringify(
              validateQuery.data?.validateDecisionEngineConfig.error,
            )}
          />
        )}

        <Outlet context={context} />

        <ConfirmationDialog
          isOpen={resetAttributionStateOpen}
          title="Reset attribution state"
          confirmButtonText="Reset attribution state"
          variant="danger"
          onClose={() => setResetAttributionStateOpen(false)}
          onConfirm={async () => {
            resetAttributionMutation.mutate({ decisionEngineId: engine.id });
          }}
        >
          <Paragraph>
            Are you sure you want to reset the attribution state? You won't be
            able to undo this.
          </Paragraph>
        </ConfirmationDialog>

        <ConfirmationDialog
          isOpen={resetStateOpen}
          title="Reset state"
          confirmButtonText="Reset state"
          variant="danger"
          onClose={() => setResetStateOpen(false)}
          onConfirm={async () => {
            resetMutation.mutate({ decisionEngineId: engine.id });
          }}
        >
          <Paragraph>
            Are you sure you want to reset the state? You won't be able to undo
            this.
          </Paragraph>
        </ConfirmationDialog>
        <ConfirmationDialog
          isOpen={clearCachedStateOpen}
          title="Clear cached state"
          confirmButtonText="Clear cached state"
          variant="warning"
          onClose={() => setClearCachedStateOpen(false)}
          onConfirm={async () => {
            clearCachedStateMutation.mutate({ decisionEngineId: engine.id });
          }}
        >
          <Paragraph>
            Are you sure you want to clear the cached state?
          </Paragraph>
        </ConfirmationDialog>
      </Column>
    </DetailPage>
  );
};

// Missing Tooltip import
import { Tooltip } from "@hightouchio/ui";

export { Flows } from "./flows";
export { Collections as AdminCollections } from "./collections";
export { Demo as AdminDemo } from "./demo";
export { Engine as AdminEngine } from "./engine";
export { Insights as AdminInsights } from "./insights";
export { Outcomes as AdminOutcomes } from "./outcomes";
