import { RouteObject } from "src/router";
import { PermissionedRoute } from "src/components/router/permissioned-route";
import { FormErrorProvider } from "src/contexts/form-error-context";

import { MemberDetailForm, NodeDetailForm, SettingsForm } from "./forms";
import Journey from "./journey";
import { JourneysLayout } from "./journeys";
import { CreateJourneySyncWizard } from "./wizards";
import { CreateJourneyModal } from "./forms/create-journey-modal";

export const routes: Array<RouteObject> = [
  {
    path: "journeys",
    element: <JourneysLayout />,
    children: [
      {
        path: "new",
        element: <CreateJourneyModal isOpen />,
      },
    ],
  },
  {
    path: "journeys/:id",
    element: <Journey />,
    children: [
      {
        path: "settings",
        element: (
          <FormErrorProvider>
            <SettingsForm />
          </FormErrorProvider>
        ),
      },
      {
        path: ":node_id?/member-details",
        element: <MemberDetailForm />,
      },
      {
        path: ":node_id/new-sync",
        element: (
          <PermissionedRoute
            permission={{ v1: { resource: "sync", grant: "create" } }}
            redirect="/journeys/:id/:node_id"
          >
            <CreateJourneySyncWizard />
          </PermissionedRoute>
        ),
      },
      {
        path: ":node_id/*",
        element: <NodeDetailForm />,
      },
    ],
  },
];
