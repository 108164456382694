import { FC } from "react";

import { Text } from "@hightouchio/ui";
import { useNavigate } from "src/router";

import { TraitQuery, useAudiencesForTraitQuery } from "src/graphql";
import { Table, TableColumn } from "src/ui/table";
import { SyncsCell } from "src/pages/syncs/sync/components/syncs-cell";

type Props = {
  trait: TraitQuery["trait_definitions_by_pk"];
};

export const UsageTable: FC<Readonly<Props>> = ({ trait }) => {
  const navigate = useNavigate();

  if (!trait || trait?.archived_at) {
    return null;
  }

  if (trait.is_template && trait.active_traits.length === 0) {
    return <Text color="text.secondary">No trait usage yet.</Text>;
  }

  if (!trait.is_template && trait.trait_references_v2.audiences.length === 0) {
    return <Text color="text.secondary">No audience usage yet.</Text>;
  }

  if (trait.is_template) {
    return (
      <Table
        data={trait.active_traits}
        columns={traitTemplateColumns}
        onRowClick={(trait) => navigate(`/traits/active/${trait.id}`)}
      />
    );
  } else {
    // The trait has a list of audience ids that reference it.
    // For GraphQL simplicity, there is an additional round trip query to load the audience sync details so the existing SyncsCell component can be reused.
    // Ideally, this would be a single query, but Hasura doesn't support calling back into the primary graph from the remote resolver.
    return <TraitTable segmentIds={trait.trait_references_v2.audiences} />;
  }
};

const TraitTable = ({ segmentIds }: { segmentIds: string[] }) => {
  const navigate = useNavigate();

  const { data: audienceData, isLoading } = useAudiencesForTraitQuery({
    segmentIds,
  });

  return (
    <Table
      loading={isLoading}
      data={audienceData?.segments}
      columns={traitColumns}
      onRowClick={(audience) => navigate(`/audiences/${audience.id}`)}
    />
  );
};

const traitTemplateColumns: TableColumn[] = [
  {
    name: "Traits",
    cell: ({ name }) => name,
    cellSx: { fontWeight: "medium", cusror: "pointer" },
  },
];

const traitColumns: TableColumn[] = [
  {
    name: "Audience",
    cell: (audience) => audience.name,
    cellSx: { fontWeight: "medium", cusror: "pointer" },
  },
  {
    name: "Syncs",
    cell: (audience) => <SyncsCell syncs={audience.syncs} />,
  },
];
