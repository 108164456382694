import { DetailPage } from "src/components/layout";
import { useEntitlements } from "src/hooks/use-entitlement";
import { Outlet } from "src/router";

export const MetricsLayout = () => {
  const { data: entitlementsData } = useEntitlements(false);
  const campaignsEnabled = entitlementsData.entitlements.campaigns;

  return (
    <DetailPage
      heading="Metrics"
      tabDepth={3}
      tabs={
        campaignsEnabled
          ? [
              {
                title: "Metrics",
                path: "",
              },
              ...(campaignsEnabled
                ? [
                    {
                      title: "Attribution methods",
                      path: "attributions",
                    },
                  ]
                : []),
            ]
          : undefined
      }
    >
      <Outlet />
    </DetailPage>
  );
};
