import { Column } from "@hightouchio/ui";
import { Helmet } from "react-helmet";

import { SyncHealthCharts } from "./sync-health-charts";
import { SyncHealthTable } from "./sync-health-table";
import { Header } from "src/pages/home/components/header";
import { ResourceCards } from "src/pages/home/components/resource-cards";
import { RecentlyViewed } from "src/pages/home/components/recently-viewed";
import { useUser } from "src/contexts/user-context";
import { SyncHealthTableLegacy } from "./sync-health-table-legacy";

export const ActivationHome = ({
  onToggleView,
}: {
  onToggleView: () => void;
}) => {
  const { workspace } = useUser();
  const alertingV2Enabled = Boolean(workspace?.alerting_v2_enabled);

  return (
    <Column flex={1} minW="500px">
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Header view="activation" onToggleView={onToggleView}>
        <ResourceCards
          resources={["model", "destination", "sync"]}
          view="activation"
        />
      </Header>
      <Column flex={1} align="center">
        <Column gap={6} maxW="1400px" w="100%" p={6}>
          <RecentlyViewed view="activation" />
          <SyncHealthCharts />
          {alertingV2Enabled ? <SyncHealthTable /> : <SyncHealthTableLegacy />}
        </Column>
      </Column>
    </Column>
  );
};
