import { ReactNode, useMemo } from "react";

import {
  Row,
  Heading,
  Column,
  Box,
  BidirectionalArrowIcon,
  IconButton,
  Tooltip,
} from "@hightouchio/ui";

import { PageHeader } from "src/components/layout/header/page-header";
import { useUser } from "src/contexts/user-context";
import { MAX_WIDTH } from "src/pages/home";
import { ActivityFeed } from "./activity-feed";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Drafts } from "./drafts";
import { useEntitlements } from "src/hooks/use-entitlement";

export const Header = ({
  children,
  view,
  onToggleView,
}: {
  children?: ReactNode;
  view: "marketing" | "activation";
  onToggleView: () => void;
}) => {
  const { user, workspace } = useUser();
  const { auditLogs } = useFlags();
  const { data: entitlementsData } = useEntitlements(true);

  const title = useMemo(() => {
    if (user?.name) {
      const names = user?.name.split(" ");
      return `👋 Welcome back, ${names[0]}`;
    }
    return "👋 Welcome back";
  }, [user]);

  const hasAuditLogs =
    workspace?.organization?.plan?.sku === "business_tier" || auditLogs;

  return (
    <>
      <PageHeader />
      <Column py={8} bg="base.background" align="center">
        <Column maxW={MAX_WIDTH} w="100%" px={6}>
          <Row align="center" justify="space-between" gap={4} mb={6}>
            <Box as={Heading} size="2xl" display={["block", "block", "none"]}>
              👋 Welcome
            </Box>
            <Box as={Heading} size="2xl" display={["none", "none", "block"]}>
              {title}
            </Box>
            <Row gap={3}>
              {workspace?.approvals_required && <Drafts />}
              {hasAuditLogs && <ActivityFeed />}
              {entitlementsData.entitlements.audiences && (
                <Tooltip
                  message={`Switch to ${
                    view === "activation" ? "Customer Studio" : "Activation"
                  } view`}
                  placement="top-start"
                >
                  <IconButton
                    onClick={onToggleView}
                    variant="secondary"
                    aria-label="Change view."
                    icon={BidirectionalArrowIcon}
                  />
                </Tooltip>
              )}
            </Row>
          </Row>
          {children}
        </Column>
      </Column>
    </>
  );
};
