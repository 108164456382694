import { FC } from "react";

import { Column } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";

import { FullParentModel } from "src/components/audiences/types";
import {
  Audience,
  AndCondition,
  AndOrCondition,
  Condition,
  ConditionType,
  OrCondition,
  RootCondition,
} from "src/types/visual";

import { QueryBuilderProvider } from "./context/query-builder-context";
import { AndOrConditionField } from "./visual/condition";

export type BaseQueryBuilderProps = {
  audience?: Audience;
  parentModel: FullParentModel | undefined | null;
  selectedEvent?: { eventModelId: number; relationshipId: number };
  enableReferencingEntryEvent?: boolean;
};

export type QueryBuilderProps = {
  isDisabled?: boolean;
  isLoading?: boolean;
  filter: AndOrCondition<AndCondition | OrCondition> | undefined;
  setConditions: (filter: AndCondition | OrCondition | null) => void;
} & BaseQueryBuilderProps;

export const QueryBuilder: FC<Readonly<QueryBuilderProps>> = ({
  enableReferencingEntryEvent = false,
  isLoading = false,
  isDisabled = false,
  audience,
  parentModel,
  filter = { type: ConditionType.And, conditions: [] as RootCondition[] },
  selectedEvent,
  setConditions,
}) => {
  /* top level conditions may only have one element. There the type may be defined so that the query builder
    is aware of whether the conditions should be `AND`'d or `OR`'d */

  const updateFilter = (
    newCondition: Condition | AndCondition | OrCondition,
  ) => {
    if (
      newCondition.type !== ConditionType.And &&
      newCondition.type !== ConditionType.Or
    ) {
      Sentry.captureException(
        new Error(
          "The top level condition in the query builder is neither an AND condition nor an OR condition",
        ),
        {
          extra: {
            condition: newCondition,
          },
        },
      );
      return;
    }

    return setConditions({
      ...filter,
      ...newCondition,
    });
  };

  return (
    <QueryBuilderProvider
      audience={audience}
      parentModel={parentModel}
      selectedEvent={selectedEvent}
      enableReferencingEntryEvent={enableReferencingEntryEvent}
    >
      <Column width="100%" fontSize={0}>
        <AndOrConditionField
          isLoading={isLoading}
          isDisabled={isDisabled}
          audience={audience}
          condition={filter}
          level={0}
          parent={parentModel}
          onChange={updateFilter}
          // Top level onRemove and onGroup do nothing
          onRemove={() => updateFilter({ ...filter, conditions: [] })}
        />
      </Column>
    </QueryBuilderProvider>
  );
};
