import { Icon } from "src/components/layout/nav/icons/decision-engine";
import { UseSectionHook } from "./types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { NavSection } from "src/components/layout/nav/nav-section";
import { ActiveSectionProps } from "src/components/layout/nav/utils";
import { useUser } from "src/contexts/user-context";
import { Box, Tooltip } from "@hightouchio/ui";
import { NavItem } from "src/components/layout/nav/nav-item";

export const useDecisionEnginesSection: UseSectionHook = () => {
  const { decisionEngineEnabled, decisionInsightsEnabled } = useFlags();
  const { resources } = useUser();

  if (!resources?.decisionEngine) {
    return {
      title: "AI Decisioning",
      isEnabled: decisionEngineEnabled,
      links: [
        {
          href: "/ai",
          label: "AI Decisioning",
        },
      ],
    };
  }

  return {
    title: "AI Decisioning",
    isEnabled: decisionEngineEnabled,
    links: [
      {
        isSub: true,
        href: "/ai/flows",
        label: "Agents",
      },
      decisionInsightsEnabled && {
        isSub: true,
        href: "/ai/insights",
        label: "Insights",
      },
      {
        isSub: true,
        href: "/ai/inspector",
        label: "Inspector",
      },
      {
        isSub: true,
        href: "/ai/collections",
        label: "Collections",
      },
      {
        isSub: true,
        href: "/ai/configuration",
        label: "Configuration",
      },
    ],
  };
};

export const DecisionEngines = (props: ActiveSectionProps) => {
  const section = useDecisionEnginesSection();
  const { resources } = useUser();

  if (!section.isEnabled) {
    return null;
  }

  if (resources?.decisionEngine) {
    return <NavSection icon={Icon} {...section} {...props} />;
  }

  return (
    <Box>
      <Box display={{ base: "none", lg: "flex" }}>
        <NavItem href="/ai" icon={Icon} label="AI Decisioning" />
      </Box>
      <Box display={{ base: "block", lg: "none" }}>
        <Tooltip message="AI Decisioning" placement="right">
          <Box>
            <NavItem href="/ai" icon={Icon} label="AI Decisioning" />
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};
