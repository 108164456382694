import { FC, useEffect, useState } from "react";

import {
  ButtonGroup,
  Column,
  EyeIcon,
  Menu,
  MenuIconButton,
  MenuList,
  Row,
  SettingsIcon,
  Switch,
  Text,
} from "@hightouchio/ui";

import { CustomQueryForm } from "src/components/sources/forms/custom-query";
import { SqlEditor } from "src/components/sql-editor";
import { useUnsavedValue } from "src/hooks/use-unsaved-value";
import * as analytics from "src/lib/analytics";
import { isQueryType, QueryableSource, QueryType } from "src/types/models";

import { DBTModelSelector } from "./dbt-model-selector";
import { LookerSelector } from "./looker-selector";
import { QueryTypeMenu } from "./query-type-menu";
import { Results } from "./results";
import { SampleModelSelector } from "./sample-model-selector";
import { SigmaSelector } from "./sigma-selector";
import { TableSelector } from "./table-selector";
import { PermissionedButton } from "src/components/permission";
import { UseModelStateReturn } from "src/hooks/use-model-state";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ManagedQuery } from "./preview/managed-query";
import { UnreachableCaseError } from "ts-essentials";

const EMBEDDED_SUPPORTED_QUERIES = ["raw_sql", "table"];

export type ExploreProps = {
  modelState: UseModelStateReturn;
  runQuery: (options: {
    limit: boolean;
    disableRowCounter?: boolean;
  }) => Promise<any>;
  cancelQuery: () => void;
  source: QueryableSource | undefined | null;
  rowsPerPage?: number;
  error: string | undefined;
  errorAtLine: number | undefined;
  rows?: any;
  rowsCount?: number | null;
  page?: number;
  asyncPagination?: boolean;
  numRowsWithoutLimit?: number | null;
  isResultTruncated: boolean;
  columns?: any;
  loading?: boolean;
  onPageChange?: (page: number) => void;
  overflow?: string;
  limit?: boolean;
  enableRowCounter?: boolean;
  isPlaceholder?: boolean;
  isEmbeddedFlow?: boolean;
  isSchema?: boolean;
};

export const Explore: FC<Readonly<ExploreProps>> = ({
  modelState,
  runQuery,
  cancelQuery,
  rows,
  rowsCount,
  asyncPagination,
  numRowsWithoutLimit,
  isResultTruncated,
  columns,
  loading,
  error: queryError,
  errorAtLine: queryErrorAtLine,
  source,
  rowsPerPage,
  onPageChange,
  page,
  isEmbeddedFlow,
  overflow = "hidden",
  isPlaceholder = true,
  isSchema = false,
  ...props
}) => {
  const [limit, setLimit] = useState<boolean>(props.limit ?? true);

  const { showRowCountDefault } = useFlags();

  const [enableRowCounter, setEnableRowCounter] = useState<boolean>(
    props.enableRowCounter ?? showRowCountDefault,
  );

  useEffect(() => {
    if (queryError) {
      analytics.track("Model Query Error", {
        model_type: source?.definition?.type,
        query_mode: modelState.state.query_type,
        error: queryError,
      });
    }
  }, [queryError]);

  useEffect(() => {
    const handler = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        runQuery({ limit, disableRowCounter: !enableRowCounter });
      }
    };
    window.addEventListener("keydown", handler);

    return () => window.removeEventListener("keydown", handler);
  }, [runQuery, limit, enableRowCounter]);

  const error = queryError;

  const unsavedSql = isEmbeddedFlow
    ? undefined
    : useUnsavedValue<string>("sql");

  const showQueryTypeMenu =
    source &&
    modelState.state.query_type &&
    source?.definition?.supportedQueries?.length > 1;

  const previewActions = (
    <ButtonGroup size="lg">
      {!source?.definition?.cleanRoomType && (
        <PermissionedButton
          permission={{
            v1: { resource: "source", grant: "preview", id: source?.id },
            v2: modelState.state.id
              ? {
                  resource: "model",
                  id: modelState.state.id ?? "",
                  grant: "can_preview",
                }
              : {
                  resource: "model",
                  grant: "can_preview",
                  creationOptions: {
                    type: isSchema ? "schema" : "model",
                    sourceId: String(source?.id),
                  },
                },
          }}
          icon={EyeIcon}
          isDisabled={loading || !modelState.isValid}
          onClick={async () => {
            await runQuery({ limit, disableRowCounter: !enableRowCounter });
            analytics.track("Model Query Previewed", {
              model_type: source?.definition?.type,
              query_mode: modelState.state.query_type,
            });
          }}
        >
          Preview results
        </PermissionedButton>
      )}
      {!(props.enableRowCounter && props.limit) && (
        <Menu>
          <MenuIconButton
            aria-label="Preview settings"
            variant="secondary"
            icon={SettingsIcon}
          />

          <MenuList>
            {!props.enableRowCounter && (
              <Row width="100%" p={2} gap={4}>
                <Column sx={{ gap: 1, maxWidth: "300px" }}>
                  <Text fontWeight="medium" size="md">
                    Show row count
                  </Text>
                  <Text size="sm" color="text.secondary">
                    Always show a count of the number of rows. Disabling this
                    may result in better performance.
                  </Text>
                </Column>
                <Switch
                  aria-label="Show row count."
                  isChecked={enableRowCounter}
                  onChange={setEnableRowCounter}
                />
              </Row>
            )}
            {!props.limit && (
              <Row width="100%" p={2} gap={4}>
                <Column sx={{ gap: 1, maxWidth: "300px" }}>
                  <Text fontWeight="medium" size="md">
                    Limit preview to 100 rows
                  </Text>
                  <Text size="sm" color="text.secondary">
                    Limits the returned rows to 100. For most sources, we still
                    show the row count of the full query results.
                  </Text>
                </Column>
                <Switch
                  aria-label="Limit preview to 100 rows."
                  isChecked={limit}
                  onChange={setLimit}
                />
              </Row>
            )}
          </MenuList>
        </Menu>
      )}
    </ButtonGroup>
  );

  const resultsNode = (
    <Results
      isPlaceholder={isPlaceholder}
      isLoading={loading}
      asyncPagination={asyncPagination}
      columns={columns}
      error={error}
      isResultTruncated={isResultTruncated}
      numRowsWithoutLimit={numRowsWithoutLimit ?? undefined}
      page={page}
      rows={rows}
      rowsCount={rowsCount}
      rowsPerPage={rowsPerPage}
      onPageChange={onPageChange}
      onCancel={cancelQuery}
    />
  );

  const editor = (() => {
    if (!source || !modelState.state) return null;

    if (
      source?.definition?.isSampleDataSource === true &&
      source?.definition?.sampleModels?.length
    ) {
      return (
        <SampleModelSelector
          sql={modelState.state.query_raw_sql ?? ""}
          source={source}
          onSQLChange={(query_raw_sql) => {
            modelState.onChange({ query_raw_sql });
          }}
          unsavedSql={unsavedSql}
          queryErrorAtLine={queryErrorAtLine}
        />
      );
    }
    if (isQueryType(modelState.state.query_type)) {
      switch (modelState.state.query_type) {
        case QueryType.Custom: {
          return (
            <CustomQueryForm
              query={modelState.state.custom_query!}
              source={source}
              onChange={(custom_query) => {
                modelState.onChange({ custom_query });
              }}
            />
          );
        }
        case QueryType.Table: {
          return (
            <TableSelector
              source={source}
              table={modelState.state.query_table_name}
              onChange={(query_table_name) => {
                modelState.onChange({ query_table_name });
              }}
            />
          );
        }
        case QueryType.DbtModel: {
          return (
            <DBTModelSelector
              dbtModelId={modelState.state.query_dbt_model_id}
              source={source}
              onChange={(query_dbt_model_id) => {
                modelState.onChange({ query_dbt_model_id });
              }}
            />
          );
        }
        case QueryType.LookerLook: {
          return (
            <LookerSelector
              lookerLookId={modelState.state.query_looker_look_id}
              source={source}
              onChange={(query_looker_look_id) => {
                modelState.onChange({ query_looker_look_id });
              }}
            />
          );
        }
        case QueryType.Sigma: {
          return (
            <SigmaSelector
              queryError={queryError}
              sigma={modelState.state.query_integrations!}
              source={source}
              onChange={(query_integrations) => {
                modelState.onChange({ query_integrations });
              }}
            />
          );
        }
        case QueryType.RawSql: {
          return (
            <SqlEditor
              highlightErroredLine={queryErrorAtLine}
              placeholder={
                source?.definition?.isSampleDataSource === true
                  ? "Select a sample model or enter a custom query..."
                  : "Enter your query..."
              }
              source={source}
              unsavedValue={unsavedSql}
              value={modelState.state.query_raw_sql ?? ""}
              onChange={(query_raw_sql) => {
                modelState.onChange({ query_raw_sql });
              }}
            />
          );
        }
        case QueryType.JourneyNode:
        case QueryType.DecisionEngine:
        case QueryType.IDRGoldenRecord:
          return (
            <Column gap={2}>
              <ManagedQuery
                type={modelState.state.query_type}
                overriden={Boolean(modelState.state.query_raw_sql)}
              />
              {/* Decision Engine allows overriding the managed query via raw SQL */}
              {modelState.state.query_type === QueryType.DecisionEngine && (
                <SqlEditor
                  highlightErroredLine={queryErrorAtLine}
                  placeholder="Enter your override SQL query..."
                  source={source}
                  unsavedValue={unsavedSql}
                  value={modelState.state.query_raw_sql ?? ""}
                  onChange={(query_raw_sql) => {
                    modelState.onChange({ query_raw_sql });
                  }}
                />
              )}
            </Column>
          );
        case QueryType.Visual:
          // Audience queries are not handled here
          return null;
        default: {
          throw new UnreachableCaseError(modelState.state.query_type);
        }
      }
    }
    return null;
  })();

  return (
    <Column flex={1} overflow={overflow} gap={4}>
      <Column
        maxHeight="38vh"
        gap={4}
        overflow="hidden"
        flex={modelState.state.query_type === QueryType.RawSql ? 1 : undefined}
      >
        {showQueryTypeMenu && (
          <QueryTypeMenu
            supportedQueries={
              isEmbeddedFlow
                ? EMBEDDED_SUPPORTED_QUERIES
                : source.definition.supportedQueries
            }
            type={modelState.state.query_type as QueryType}
            onChange={(query_type) =>
              modelState.onChange({
                query_type,
              })
            }
          />
        )}
        {editor}
      </Column>
      <Column gap={4}>
        {previewActions}
        {resultsNode}
      </Column>
    </Column>
  );
};
