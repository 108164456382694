import { FC } from "react";

import { Box, Badge, Text, Tooltip } from "@hightouchio/ui";
import pluralize from "pluralize";

import { DestinationDefinitionsQuery } from "src/graphql";
import { BillingDestinationType } from "src/hooks/use-entitlement";
import { HTImage } from "src/components/image";

const unknownDestination = {
  type: "unknown",
  name: "Unknown",
  icon: "",
};

interface DestinationDefinition {
  type: string;
  name: string;
  icon: string;
}

interface Props {
  destinationBillingTypes: Record<string, BillingDestinationType>;
  definitions: DestinationDefinitionsQuery | undefined;
}

export const DestinationTiles: FC<Props> = ({
  destinationBillingTypes,
  definitions,
}) => {
  return (
    <Box
      display="grid"
      gap={4}
      gridTemplateColumns={[
        "1fr",
        "1fr",
        "repeat(2,1fr)",
        "repeat(3, 1fr)",
        "repeat(3, 264px)",
      ]}
    >
      {Object.entries(destinationBillingTypes).map(
        ([billingType, destInfo]) => {
          if (!billingType) {
            return null;
          }

          // If defintion can not be found in the loaded definitions, use the unknown destination.
          const definition: DestinationDefinition =
            definitions?.getDestinationDefinitions.find(
              ({ type }) => type === billingType,
            ) || unknownDestination;

          return (
            <Box
              key={definition.type}
              alignItems="center"
              border="1px"
              borderColor="gray.300"
              borderRadius="md"
              display="flex"
              gap={2}
              position="relative"
              px={3}
              py={5}
            >
              {definition.icon && (
                <HTImage
                  alt={`${definition.name}.`}
                  flex="none"
                  src={definition.icon}
                  width={9}
                />
              )}

              <Box flex="1" minWidth={0}>
                <Text fontWeight="medium">{definition.name}</Text>
              </Box>

              {!destInfo.isFreeDestination && destInfo.isInTrial ? (
                <Box position="absolute" right="-10px" top="-10px">
                  <Badge>In trial</Badge>
                </Box>
              ) : (
                (destInfo.isFreeDestination ||
                  destInfo.isPremiumDestination) && (
                  <Box position="absolute" right="-10px" top="-10px">
                    {destInfo.isFreeDestination ? (
                      <Badge>Free</Badge>
                    ) : (
                      <Badge variant="upsell">Premium</Badge>
                    )}
                  </Box>
                )
              )}

              {destInfo.count && (
                <Box flex="none">
                  <Tooltip
                    message={`There ${destInfo.count === 1 ? "is" : "are"} ${
                      destInfo.count
                    } ${pluralize(
                      "destination type",
                      destInfo.count,
                    )} of this group currently active in the organization.`}
                  >
                    <Box>
                      <Badge>{destInfo.count}</Badge>
                    </Box>
                  </Tooltip>
                </Box>
              )}
            </Box>
          );
        },
      )}

      {Object.keys(destinationBillingTypes).length === 0 && (
        <Text>No destinations found</Text>
      )}
    </Box>
  );
};
