import { FC, useEffect, useState } from "react";

import {
  Column,
  DeleteIcon,
  FormField,
  Heading,
  Menu,
  MenuActionsButton,
  MenuList,
  RefreshIcon,
  Row,
  Text,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { Outlet } from "src/router";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import fivetranExtensionImage from "src/components/extensions/assets/fivetran-extension.png";
import { Overview } from "src/components/extensions/overview";
import { FeaturePreview } from "src/components/feature-gates/preview";
import { DetailPage } from "src/components/layout";
import { PermissionedMenuItem } from "src/components/permission";
import { ScheduleType } from "src/components/schedule/types";
import { useUser } from "src/contexts/user-context";
import { Form, useHightouchForm } from "src/components/form";
import { FormBar } from "src/components/form/form-bar";
import {
  useCreateFivetranCredentialsMutation,
  useDeleteFivetranCredentialsMutation,
  useGetFivetranExtensionQuery,
  useTestFivetranExtensionQuery,
} from "src/graphql";
import {
  ConnectedExtension,
  DependentSyncsModal,
  ExtensionTestStatus,
} from "./common";
import { FivetranIcon } from "src/ui/icons";
import { DocsLink } from "src/components/docs-link";
import { Card } from "src/components/card";
import { SecretInput } from "src/components/secret-input";

export const Fivetran: FC = () => {
  return (
    <DetailPage
      bg="base.lightBackground"
      crumbs={[{ label: "Extensions", link: "/extensions" }]}
      title="Fivetran - Extensions"
      header={<Heading size="xl">Fivetran</Heading>}
      tabs={[
        { path: "", title: "Overview" },
        {
          path: "configuration",
          title: "Configuration",
          size: "small",
          contentFullHeight: true,
        },
      ]}
    >
      <Outlet />
    </DetailPage>
  );
};

export const FivetranOverview = () => {
  return (
    <Overview
      description="Schedule your Hightouch syncs to run when your Fivetran connectors and dbt transformations complete."
      icon={FivetranIcon}
      image={fivetranExtensionImage}
      subtitle="Trigger syncs upon completion of Fivetran jobs"
      title="Fivetran"
    />
  );
};

interface FivetranFormValues {
  apiKey: string;
  apiSecret: string;
}

const schema = yup.object().shape({
  apiKey: yup.string().required("API key is required").trim(),
  apiSecret: yup.string().required("API secret is required").trim(),
});

export const FivetranConfiguration: FC = () => {
  const { workspace } = useUser();
  const [isDeleting, setIsDeleting] = useState(false);
  const [testStatus, setTestStatus] = useState<ExtensionTestStatus>("loading");
  const { toast } = useToast();

  const { data: credentials } = useGetFivetranExtensionQuery(undefined, {
    select: (data) => data.fivetran_credentials?.[0],
    suspense: true,
  });

  const {
    data: testResult,
    isLoading: isTesting,
    refetch: testExtension,
    isRefetching: isReTesting,
  } = useTestFivetranExtensionQuery(
    {},
    { select: (data) => data.testFivetranWebhook, enabled: !!credentials },
  );

  const { mutateAsync: create, isLoading: isCreating } =
    useCreateFivetranCredentialsMutation();
  const { mutateAsync: deleteExtension, isLoading: fivetranDeleting } =
    useDeleteFivetranCredentialsMutation();

  const form = useHightouchForm<FivetranFormValues>({
    resolver: yupResolver(schema),
    values: {
      apiKey: credentials?.api_key ?? "",
      apiSecret: "",
    },
    onSubmit: async (data) => {
      const resp = await create({
        apiKey: data.apiKey,
        apiSecret: data.apiSecret,
      });
      if (
        resp.createFivetranExtension.__typename === "FivetranExtensionError"
      ) {
        throw Error(
          resp.createFivetranExtension.fivetranError ??
            resp.createFivetranExtension.message,
        );
      }
    },
  });

  useEffect(() => {
    if (credentials) {
      if (isTesting || isReTesting) {
        setTestStatus("loading");
        return;
      }
      if (!testResult?.succeed) {
        setTestStatus("failed");
        toast({
          id: "failed-fivetran-test",
          variant: "error",
          title: "Fivetran webhook test failed.",
          message: testResult?.message ?? "Please check your API credentials.",
        });
      } else {
        setTestStatus("success");
      }
    }
  }, [credentials, isTesting, isReTesting]);

  const Header = () => {
    return (
      <>
        <FeaturePreview
          enabled={workspace?.organization?.plan?.sku === "business_tier"}
          featureDetails={{
            pitch:
              "Trigger for a Hightouch sync to run after a Fivetran job completes",
            description:
              "The Fivetran extension creates an end-to-end flow for your data. This extension enables a Hightouch sync to begin running whenever a Fivetran sync or transformation completes.",
            bullets: [
              "Schedule Hightouch syncs based on when a Fivetran job finishes",
              "Support for both Fivetran syncs and Fivetran transformations",
              "Guarantee freshness when sending data into your business tools",
            ],
            video: {
              src: "https://cdn.sanity.io/files/pwmfmi47/production/05f48da576ab4ec1cd867525c68931a70932fa79.mp4",
            },
          }}
          featureName="Fivetran extension"
          variant="full"
        />
        <Row align="center" justify="space-between">
          <Heading>Fivetran configuration</Heading>
          <Row gap={4} align="center">
            <DocsLink href="extensions/fivetran" name="Fivetran extension" />
            {credentials?.id && (
              <Menu closeOnSelect={false}>
                <MenuActionsButton variant="secondary" />
                <MenuList>
                  <PermissionedMenuItem
                    permission={{
                      v2: { resource: "workspace", grant: "can_update" },
                    }}
                    icon={RefreshIcon}
                    isDisabled={isTesting || isReTesting || isDeleting}
                    isLoading={isTesting || isReTesting}
                    onClick={() => {
                      setTestStatus("loading");
                      testExtension({});
                    }}
                  >
                    {isTesting || isReTesting
                      ? "Testing..."
                      : "Test connection"}
                  </PermissionedMenuItem>
                  <PermissionedMenuItem
                    icon={DeleteIcon}
                    permission={{
                      v2: { resource: "workspace", grant: "can_update" },
                    }}
                    isDisabled={isDeleting || isTesting}
                    isLoading={isDeleting}
                    variant="danger"
                    onClick={() => {
                      setIsDeleting(true);
                    }}
                  >
                    Disconnect
                  </PermissionedMenuItem>
                </MenuList>
              </Menu>
            )}
          </Row>
        </Row>
      </>
    );
  };

  return (
    <Form form={form}>
      <Column flex={1} gap={6}>
        <Header />
        {credentials?.id ? (
          <ConnectedExtension credentials={credentials} testStatus={testStatus}>
            <Text fontWeight="medium">
              <Text fontWeight="semibold">API key: </Text>
              {credentials?.api_key}
            </Text>
            <Text fontWeight="medium">
              <Text fontWeight="semibold">Fivetran webhook ID: </Text>
              {credentials?.fivetran_webhook_id}
            </Text>
          </ConnectedExtension>
        ) : (
          <Card gap={6}>
            <Controller
              name="apiKey"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <FormField error={error?.message} label="API key">
                  <TextInput
                    isDisabled={isCreating}
                    isInvalid={Boolean(error?.message)}
                    {...field}
                  />
                </FormField>
              )}
            />
            <Controller
              name="apiSecret"
              control={form.control}
              render={({ field, fieldState: { error } }) => (
                <FormField error={error?.message} label="API secret">
                  <SecretInput
                    isDisabled={isCreating}
                    isInvalid={Boolean(error?.message)}
                    {...field}
                  />
                </FormField>
              )}
            />
          </Card>
        )}
      </Column>
      <FormBar
        permission={{
          v2: { resource: "workspace", grant: "can_update" },
        }}
      />
      <DependentSyncsModal
        deleteExtension={() => deleteExtension({})}
        extensionName="Fivetran"
        isDeleting={fivetranDeleting}
        open={isDeleting}
        scheduleType={ScheduleType.FIVETRAN}
        onClose={() => setIsDeleting(false)}
      />
    </Form>
  );
};
