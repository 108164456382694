import { FC, useMemo, useState, useEffect } from "react";

import * as Sentry from "@sentry/react";
import {
  RefreshIcon,
  Row,
  Select,
  Text,
  useToast,
  Column,
  Box,
  Tooltip,
  WarningIcon,
} from "@hightouchio/ui";

import {
  PermissionedIconButton,
  PermissionedSwitch,
} from "src/components/permission";
import { formatDate } from "src/utils/time";

import { useResourcePermission } from "src/components/permission/use-resource-permission";
import {
  REFRESH_OPTIONS,
  RefreshIntervals,
  limitErrorMessage,
  checkIsLimitError,
} from "src/components/audiences/utils";
import { getTraitPropertyType } from "src/components/explore/visual/utils";

import { TraitQuery, useUpdateTraitWithHasuraMutation } from "src/graphql";
import { ColumnType } from "src/types/visual";
import { useFlags } from "launchdarkly-react-client-sdk";
import { TraitDefinitions } from "src/graphql";

export type TopKConfigOptions = Pick<
  TraitDefinitions,
  "top_k_enabled" | "top_k_sync_interval" | "trigger_top_k_sync"
>;

type Props = {
  trait: NonNullable<TraitQuery["trait_definitions_by_pk"]>;
};

const getInitialState = (trait: Props["trait"]): TopKConfigOptions => ({
  top_k_enabled: trait.top_k_enabled,
  top_k_sync_interval: trait.top_k_sync_interval,
  trigger_top_k_sync: Boolean(trait.trigger_top_k_sync),
});

export const TraitSuggestionConfig: FC<Readonly<Props>> = ({ trait }) => {
  const { traitTopKEnabled } = useFlags();
  const { toast } = useToast();
  const traitMutation = useUpdateTraitWithHasuraMutation();

  const parentModelId = trait.parent_model.id;

  // Local state for immediate UI feedback
  const [localConfig, setLocalConfig] = useState<TopKConfigOptions>(
    getInitialState(trait),
  );

  // Update local state when trait prop changes
  useEffect(() => {
    setLocalConfig(getInitialState(trait));
  }, [trait]);

  const isStringTrait = useMemo(() => {
    return (
      getTraitPropertyType(
        trait,
        trait.relationship?.to_model.filterable_audience_columns,
      ) === ColumnType.String
    );
  }, [trait]);

  const topKError = trait.top_k_trait_statuses[0]?.top_k_error;

  const updateTraitConfig = async (newConfig: Partial<TopKConfigOptions>) => {
    // Update local state immediately
    setLocalConfig((prev) => ({
      ...prev,
      ...newConfig,
    }));

    try {
      await traitMutation.mutateAsync({
        id: trait.id,
        input: { ...newConfig },
      });
      // Keep local state as is since it's now confirmed by the server
      setLocalConfig((prev) => ({
        ...prev,
      }));
    } catch (error) {
      toast({
        id: "update-trait-suggestion-config",
        title: "Update failed",
        message: error.message,
        variant: "error",
      });
      // Revert local state on error
      setLocalConfig(getInitialState(trait));
      Sentry.captureException(error);
    }
  };

  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "model", grant: "can_update", id: parentModelId },
    v1: { resource: "audience_schema", grant: "update" },
  });

  const lastUpdatedText = useMemo(() => {
    if (!trait.top_k_trait_statuses[0]?.last_sync_completed) {
      return null;
    }

    return `Last updated: ${formatDate(trait.top_k_trait_statuses[0]?.last_sync_completed)}`;
  }, [trait]);

  return isStringTrait && traitTopKEnabled ? (
    <Column mt={6}>
      <Row align="center" justify="space-between">
        <Text fontWeight="medium">Trait value suggestions</Text>
        <Row align="center" gap={2}>
          <PermissionedSwitch
            aria-label="Enable trait value suggestions."
            permission={{
              v2: {
                resource: "model",
                grant: "can_update",
                id: parentModelId,
              },
              v1: { resource: "audience_schema", grant: "update" },
            }}
            size="md"
            isChecked={localConfig.top_k_enabled}
            isDisabled={traitMutation.isLoading}
            onChange={(value) => {
              updateTraitConfig({
                top_k_enabled: value,
                // Default traits to manual refresh only
                top_k_sync_interval: value ? RefreshIntervals.ManualOnly : null,
              });
            }}
          />
          {localConfig.top_k_enabled && topKError && (
            <Tooltip
              message={
                checkIsLimitError(topKError)
                  ? limitErrorMessage(topKError)
                  : topKError
              }
            >
              <Box fontSize="20px">
                <WarningIcon
                  color={
                    checkIsLimitError(topKError)
                      ? "warning.border"
                      : "danger.base"
                  }
                />
              </Box>
            </Tooltip>
          )}
        </Row>
      </Row>
      <Column mb={2}>
        <Text color="text.secondary">
          Suggest commonly used trait values when using the audience builder.
        </Text>
      </Column>
      <Column>
        <Text fontWeight="medium" mb={2}>
          Refresh interval
        </Text>

        <Row align="center" justify="flex-start" gap={2}>
          <Select
            width="sm"
            isDisabled={
              !localConfig.top_k_enabled ||
              !hasUpdatePermission ||
              traitMutation.isLoading
            }
            options={REFRESH_OPTIONS}
            placeholder="Select an interval..."
            value={localConfig.top_k_sync_interval ?? undefined}
            onChange={(value) =>
              updateTraitConfig({
                top_k_sync_interval: value,
              })
            }
          />

          <PermissionedIconButton
            permission={{
              v2: {
                resource: "model",
                grant: "can_update",
                id: parentModelId,
              },
              v1: { resource: "audience_schema", grant: "update" },
            }}
            tooltip={
              !localConfig.top_k_enabled
                ? "Suggestions are not enabled for this trait"
                : "Refresh trait suggestions"
            }
            aria-label="Refresh suggestions"
            variant="secondary"
            isDisabled={!localConfig.top_k_enabled || traitMutation.isLoading}
            isLoading={
              localConfig.trigger_top_k_sync || traitMutation.isLoading
            }
            onClick={() =>
              updateTraitConfig({
                trigger_top_k_sync: true,
              })
            }
            icon={RefreshIcon}
          />
        </Row>

        {localConfig.top_k_enabled && lastUpdatedText && (
          <Text mt={2} color="text.secondary">
            {lastUpdatedText}
          </Text>
        )}
      </Column>
    </Column>
  ) : null;
};
