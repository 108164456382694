import {
  MonitoredResourceType,
  MonitorStatus,
} from "@hightouch/lib/resource-monitoring/types";
import { mapValues } from "lodash";
import { useMemo } from "react";
import {
  useRenderedConditionChangesQuery,
  useSyncsMonitorStatusesQuery,
} from "src/graphql/types";
import { syncHealthOrUnknown } from "src/utils/syncs";

export const useAlertsForSyncs = (
  syncIds: string[],
  resourceType: MonitoredResourceType.EventSync | MonitoredResourceType.Sync,
  refetchInterval?: number,
  suspense?: boolean,
) => {
  const enabled = Boolean(syncIds.length);
  const syncMonitorStatusesQuery = useSyncsMonitorStatusesQuery(
    {
      syncIds,
      resourceType,
    },
    {
      enabled,
      refetchInterval: refetchInterval ?? 3000,
      suspense,
      select: (data) => {
        return Object.fromEntries(
          data.resource_monitor_statuses.map((status) => [
            status.resource_id as string,
            {
              health: status.new_status,
              condition_changes: (status.condition_changes || []) as string[],
            },
          ]),
        );
      },
    },
  );

  const conditionChangeIds =
    (syncMonitorStatusesQuery.data &&
      Object.values(syncMonitorStatusesQuery.data).flatMap(
        (sync) => sync.condition_changes,
      )) ||
    [];

  const renderedConditionChangesQuery = useRenderedConditionChangesQuery(
    {
      resourceId: "", // TODO: These two fields are unused in the resolver, but it will
      resourceType, // require a series of PRs to remove them. Removing soon.
      conditionChangeIds,
    },
    {
      select: (result) => {
        return Object.fromEntries(
          result.renderedMonitorConditionStatuses
            .filter((s) => s.status !== MonitorStatus.Healthy)
            .map((status) => [status.id, status]),
        );
      },
      notifyOnChangeProps: "tracked",
      enabled: Boolean(syncMonitorStatusesQuery.data),
    },
  );

  return useMemo(() => {
    return {
      isLoading:
        syncMonitorStatusesQuery.isLoading ||
        renderedConditionChangesQuery.isLoading,
      data:
        syncMonitorStatusesQuery.data &&
        renderedConditionChangesQuery.data &&
        mapValues(syncMonitorStatusesQuery.data, (status) => ({
          health: syncHealthOrUnknown(status.health),
          alerts: status.condition_changes.flatMap((change_id) =>
            renderedConditionChangesQuery.data[change_id]
              ? [renderedConditionChangesQuery.data[change_id]]
              : [],
          ),
        })),
    };
  }, [
    syncMonitorStatusesQuery.data,
    renderedConditionChangesQuery.data,
    syncMonitorStatusesQuery.isLoading,
    renderedConditionChangesQuery.isLoading,
  ]);
};
