import { ReactNode } from "react";

import {
  BooleanIcon,
  Box,
  MeetingIcon,
  NullIcon,
  NumberIcon,
  Row,
  Text,
  TextStringIcon,
} from "@hightouchio/ui";

import { ExtendedOption } from "../../../../formkit";
import { FormkitModel } from "./formkit-context";
import { MappingType, StaticType } from "./types";

export function formatValue(value: any): ReactNode {
  if (typeof value === "object") {
    if (value.variable) {
      return value.variable;
    }
    if (value.type === MappingType.OBJECT) {
      return "Object:";
    }
    if (
      value.type === MappingType.ARRAY ||
      value.type === MappingType.ARRAY_FIELD
    ) {
      return (
        <Row alignItems="center" gap="3px">
          <Text color="forest.700">Array:</Text>
          <Text color="base.6" fontWeight="normal" isTruncated>
            {value.from ||
              (value.type === MappingType.ARRAY
                ? "Pick an array from the list."
                : "Enter an array field.")}
          </Text>
        </Row>
      );
    }

    if (
      (value.type === MappingType.STANDARD ||
        value.type === MappingType.FIELD) &&
      value.custom
    ) {
      return (
        <Row alignItems="center" gap="3px">
          <Text>{resolveFromValue(value.from)}</Text>
          <Box sx={{ span: { fontWeight: "300" } }}>
            <Text color="base.6" isTruncated>
              (expected column)
            </Text>
          </Box>
        </Row>
      );
    }

    if (value.from) {
      return resolveFromValue(value.from);
    }
    if (typeof value.value === "undefined") {
      return value.template;
    } else {
      return String(value.value);
    }
  }

  return value;
}

export function isTraitEnrichment(type: string | undefined): boolean {
  return type === "additionalColumnReference";
}

export function isSplitColumn(type: string | undefined): boolean {
  return type === "splitTest";
}

export function resolveFromValue(fromValue: any): string {
  if (typeof fromValue === "object") {
    if (Array.isArray(fromValue)) {
      return fromValue.map(formatValue).join(", ");
    }

    if (fromValue.type === "boosted") {
      return fromValue.semanticType;
    }
    if (isTraitEnrichment(fromValue.type) || isSplitColumn(fromValue.type)) {
      return fromValue.columnAlias;
    }
    if (fromValue.type === "related" || fromValue.type === "transformed") {
      return fromValue.column.name;
    }
    if (fromValue.type === "raw") {
      return fromValue.name;
    }
  }

  return fromValue;
}

export const NEW_STATIC_ICONS = {
  [StaticType.STRING]: TextStringIcon,
  [StaticType.NUMBER]: NumberIcon,
  [StaticType.BOOLEAN]: BooleanIcon,
  [StaticType.DATETIME]: MeetingIcon,
  [StaticType.NULL]: NullIcon,
};

export function isMatchboostingEnabled({ fields = [] }: { fields?: any[] }) {
  return fields?.some((field) => field?.from?.type === "boosted");
}

export function isMatchBoostingAvailableForSyncConfig({
  options,
  componentSupportsMatchBoosting,
  destinationSupportsMatchBoosting,
}: {
  componentSupportsMatchBoosting?: boolean;
  options?: ExtendedOption[];
  destinationSupportsMatchBoosting?: boolean;
}): boolean {
  // In the case that the component didn't explicitly define it supports match
  // boosting, check if they have any semantic columns defined (which is only
  // used for MB) to infer if this component supports MB
  const containBoostedDestinationOptions =
    options?.some((opt) => opt?.extendedType?.semanticColumnType != null) ??
    false;

  return Boolean(
    // Destination (and possibly the destination type - e.g. segment type) supports MB
    destinationSupportsMatchBoosting &&
      // Destination mappings component supports boosting field
      (componentSupportsMatchBoosting || containBoostedDestinationOptions),
  );
}

export function isMatchBoosterSetUpToUse({
  mbEnabledForWorkspace,
  model,
  lastEnrichedAt,
}: {
  mbEnabledForWorkspace: boolean;
  model?: FormkitModel;
  lastEnrichedAt?: string;
}): boolean {
  return Boolean(
    // MB feature flag is on for this wkspc
    mbEnabledForWorkspace &&
      // Model has MB enabled
      (model?.parent?.matchboosting_enabled || model?.matchboosting_enabled) &&
      // Enrichment finished running (at least one enrichment run is required before ppl can use MB)
      lastEnrichedAt,
  );
}
