import { Navigate, RouteObject, useOutletContext } from "src/router";

import { CreateEventWarehouseSync } from "./create";
import { EventSync, OutletContext } from "./common";
import { EventWarehouseSyncs } from "./syncs";
import { Schedule } from "./common/schedule";
import { Archive } from "./common/archive";
import { Errors } from "./common/errors";
import { Runs } from "./common/runs";
import { Configuration } from "./common/configuration";
import { ForwardingSyncFailuresArchived } from "./forwarding-sync/errors/archived";
import { ForwardingSyncFailuresRetrying } from "./forwarding-sync/errors/retrying";
import { Overview } from "./common/overview";
import { WarehouseSyncRun } from "./warehouse-sync/run";
import { EventSyncAlerting } from "./common/alerting";

const SyncIndex = () => {
  const context = useOutletContext<OutletContext>();

  if (context.type === "forwarding") {
    return (
      <Navigate to={`/events/syncs/${context.sync.id}/overview`} replace />
    );
  }

  return <Navigate to={`/events/syncs/${context.sync.id}/runs`} replace />;
};

export const routes: Array<RouteObject> = [
  {
    index: true,
    element: <EventWarehouseSyncs />,
  },
  {
    path: "new",
    element: <CreateEventWarehouseSync />,
  },
  {
    path: ":id",
    element: <EventSync />,
    children: [
      {
        index: true,
        element: <SyncIndex />,
      },
      {
        path: "alerting",
        element: <EventSyncAlerting />,
      },
      {
        path: "overview",
        element: <Overview />,
      },
      {
        path: "configuration",
        element: <Configuration />,
      },
      {
        path: "configuration/event/:event?",
        element: <Configuration />,
      },
      {
        path: "errors",
        element: <Errors />,
        children: [
          {
            index: true,
            element: <Navigate to="retrying" replace />,
          },
          {
            path: "archived",
            element: <ForwardingSyncFailuresArchived />,
          },
          {
            path: "retrying",
            element: <ForwardingSyncFailuresRetrying />,
          },
        ],
      },
      {
        path: "runs",
        element: <Runs />,
      },
      {
        path: "archive",
        element: <Archive />,
      },
      {
        path: "schedule",
        element: <Schedule />,
      },
    ],
  },
  {
    path: ":id/runs/:runId",
    element: <WarehouseSyncRun />,
  },
];
