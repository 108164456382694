import { FC, useMemo } from "react";

import {
  Box,
  CloseIcon,
  Column,
  Row,
  SuccessIcon,
  Text,
} from "@hightouchio/ui";
import { useFormContext } from "react-hook-form";
import { getIncomers, Position } from "reactflow";
import { useNavigate, useParams } from "src/router";

import { TextWithTooltip } from "src/components/text-with-tooltip";
import { NODE_WIDTH } from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import { HiddenHandle, MiniNodeHandle } from "src/pages/journeys/handles";
import { JourneyGraph } from "src/pages/journeys/types";
import { JourneyNodeConfig, JourneyNodeType } from "src/types/journeys";

type MiniNodeProps = {
  id: string;
  name: string;
  config: JourneyNodeConfig;
};

export const MiniNode: FC<MiniNodeProps> = ({ id, name, config }) => {
  const { node_id } = useParams<{ node_id?: string }>();
  const navigate = useNavigate();
  const parentForm = useFormContext<JourneyGraph>();

  const {
    connectionSourceNodeId,
    isEditMode,
    hasOutgoers,
    nodes,
    unauthorized,
  } = useGraphContext();

  const hasConnections = hasOutgoers(id);

  const isActive = useMemo(() => {
    if (!isEditMode) return false;

    if (node_id === id) return true;

    const edges = parentForm.getValues("edges");
    const node = nodes.find((node) => node.id === id);

    if (!node) return false;

    const selectedNodes = nodes
      .filter((node) => node.selected)
      .map((node) => node.id);

    const parentNode = getIncomers(node, nodes, edges);

    // Parent node is selected
    if (parentNode[0]) {
      return selectedNodes.includes(parentNode[0].id);
    }

    return false;
  }, [nodes, isEditMode, node_id, id]);

  return (
    // Fill in the space until 200px
    <Column align="center" width={`${NODE_WIDTH}px`}>
      <Row
        className={isActive ? "selected-node" : undefined}
        align="center"
        p={4}
        gap={2}
        background="white"
        borderRadius="md"
        boxShadow="sm"
        border="2px solid"
        borderColor="transparent"
        width="min-content"
        maxWidth={`${NODE_WIDTH}px`}
        _hover={{
          cursor: "pointer",
          boxShadow: "md",
          borderColor: connectionSourceNodeId
            ? "danger.base"
            : isActive
              ? "success"
              : "transparent",
        }}
        _active={{
          borderColor: "success",
          boxShadow: "lg",
        }}
        onClick={(event) => {
          if (!event.shiftKey) {
            navigate(id);
          }
        }}
      >
        {config.type === JourneyNodeType.WaitUntilEventBranch && (
          <Box as={Text} fontSize="20px">
            {name === "Rule met" ? (
              <SuccessIcon color="avocado.base" />
            ) : (
              <CloseIcon color="danger.base" />
            )}
          </Box>
        )}
        <TextWithTooltip>{name}</TextWithTooltip>
        <MiniNodeHandle
          type="source"
          enabled={!unauthorized && !hasConnections}
          position={Position.Bottom}
        />
        <HiddenHandle
          type="target"
          enabled={!unauthorized}
          position={Position.Top}
        />
      </Row>
    </Column>
  );
};
