import { FC, useState, useEffect } from "react";

import {
  Textarea,
  useToast,
  Column,
  Row,
  Button,
  Alert,
  FormField,
  Select,
  TextInput,
  Dialog,
  Text,
  ButtonGroup,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { parseISO } from "date-fns";
import { Controller, useForm } from "react-hook-form";

import { TunnelSelect } from "src/components/tunnel-select";
import { usePermissionContext } from "src/components/permission/permission-context";
import {
  GitCredentialsQuery,
  useCreateGitCredentialsMutation,
  useUpdateGitCredentialsMutation,
} from "src/graphql";
import { Card } from "src/components/card";
import { Circle } from "src/ui/circle";
import { SecretInput } from "src/components/secret-input";

const serviceOptions = [
  { label: "GitHub app", value: "github_app" },
  { label: "GitHub", value: "github" },
  { label: "GitLab", value: "gitlab" },
  { label: "Bitbucket", value: "bitbucket" },
  { label: "Azure", value: "azure" },
  { label: "Other", value: "other" },
];

enum Protocol {
  SSH = "ssh",
  HTTPS = "https",
}

const GITHUB_PERMISSIONS_LAST_CHANGED_AT = "2022-09-16";
export const githubCredentialsNeedUpdating = (
  credentials: NonNullable<GitCredentialsQuery["git_credentials"][0]>,
) => {
  if (!credentials) {
    return false;
  }
  if (credentials.type !== "github_app") {
    return false;
  }
  return (
    !credentials.updated_at ||
    parseISO(credentials.updated_at) <
      new Date(GITHUB_PERMISSIONS_LAST_CHANGED_AT)
  );
};

const githubCredentialsNeedApproval = (
  credentials: NonNullable<GitCredentialsQuery["git_credentials"][0]>,
) => {
  if (!credentials) {
    return false;
  }
  if (credentials.type !== "github_app") {
    return false;
  }
  return !credentials.credentials?.installationId;
};

const protocolOption = [
  { label: "HTTPS", value: Protocol.HTTPS },
  { label: "SSH", value: Protocol.SSH },
];

interface Props {
  isSetup: boolean;
  credentials: NonNullable<GitCredentialsQuery["git_credentials"][0]>;
  page: "git-sync" | "dbt-models";
}

export const GitCredentialsFields: FC<Readonly<Props>> = ({
  credentials,
  isSetup,
  page,
}) => {
  const { toast } = useToast();
  const formMethods = useForm();
  const permission = usePermissionContext();

  const { mutateAsync: create } = useCreateGitCredentialsMutation();
  const { mutateAsync: update } = useUpdateGitCredentialsMutation();

  const {
    control,
    setValue,
    watch,
    reset,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = formMethods;

  const type = watch("type");
  const username = watch("username");
  const password = watch("password");
  const sshPrivateKey = watch("ssh_privatekey");

  const submit = async (data) => {
    try {
      if (!credentials?.id) {
        await create({ object: data });
      } else {
        await update({
          object: {
            ...data,
            id: credentials.id.toString(),
          },
        });
      }

      toast({
        id: "save-git-config",
        title: "Configuration was saved",
        variant: "success",
      });

      setShowModal(false);
    } catch (e) {
      toast({
        id: "save-git-config",
        title: "Couldnt' save your configuration",
        variant: "error",
      });

      Sentry.captureException(e);
    }
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  const [protocol, setProtocol] = useState<Protocol>(
    !credentials || credentials?.username ? Protocol.HTTPS : Protocol.SSH,
  );

  useEffect(() => {
    if (username || password) {
      setValue("ssh_privatekey", "");
    }
  }, [username, password]);

  useEffect(() => {
    if (sshPrivateKey) {
      setValue("username", "");
      setValue("password", "");
    }
  }, [sshPrivateKey]);

  useEffect(() => {
    reset({
      id: credentials?.id,
      tunnel_id: credentials?.tunnel_id,
      username: credentials?.username || "",
      password: "",
      ssh_privatekey: "",
      type: credentials?.type || "github_app",
    });
  }, [credentials]);

  return (
    <>
      <Card>
        <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Text fontWeight="medium" size="lg">
            Git credentials
          </Text>
          <Button
            size="sm"
            onClick={
              permission?.unauthorized ? undefined : () => setShowModal(true)
            }
          >
            {permission?.unauthorized
              ? "Contact your workspace admin"
              : credentials?.id
                ? "Manage"
                : "Set up"}
          </Button>
        </Row>
      </Card>
      {githubCredentialsNeedUpdating(credentials) && (
        <Alert
          variant="inline"
          type="warning"
          title="Your GitHub app permissions are out of date"
          message="Please update your GitHub app installation to receive the latest features."
        />
      )}
      {githubCredentialsNeedApproval(credentials) && (
        <Alert
          variant="inline"
          type="error"
          title="Your organization has not yet approved your GitHub app installation"
          message="Please contact your GitHub admin(s) to approve the installation and then reinstall the Hightouch GitHub app."
        />
      )}
      <Dialog
        isOpen={showModal}
        variant="form"
        width="xl"
        title="Git credentials"
        actions={
          <ButtonGroup>
            <Button onClick={() => setShowModal(false)}>Close</Button>
            <Button
              variant="primary"
              isDisabled={!isDirty}
              isLoading={isSubmitting}
              onClick={handleSubmit(submit)}
            >
              Save
            </Button>
          </ButtonGroup>
        }
        onClose={() => setShowModal(false)}
      >
        <Column gap={4}>
          <FormField label="Git service">
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <Select
                  {...field}
                  options={serviceOptions}
                  placeholder="Select a service..."
                />
              )}
            />

            {type === "github_app" &&
              (credentials ? (
                <Card my={4}>
                  <Row
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Row sx={{ alignItems: "center" }}>
                      <Circle bg="success.base" radius="12px" />
                      <Text ml={2}>
                        GitHub App ID:{" "}
                        {credentials?.credentials?.installationId}
                      </Text>
                    </Row>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        window.location.href = `${
                          import.meta.env.VITE_API_BASE_URL
                        }/github/oauth/integration?page=${page}`;
                      }}
                    >
                      Configure GitHub App
                    </Button>
                  </Row>
                </Card>
              ) : (
                <Card>
                  <Row
                    sx={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text>Install the Hightouch GitHub app</Text>
                    <Button
                      variant="primary"
                      onClick={() => {
                        window.location.href = `${
                          import.meta.env.VITE_API_BASE_URL
                        }/github/oauth/integration`;
                      }}
                    >
                      Install app
                    </Button>
                  </Row>
                </Card>
              ))}
          </FormField>
          {type !== "github_app" && (
            <>
              <Controller
                control={control}
                name="tunnel_id"
                render={({ field }) => (
                  <TunnelSelect
                    optional
                    value={field.value ? { id: field.value } : undefined}
                    onChange={(value) => {
                      field.onChange(value?.id ?? null);
                    }}
                  />
                )}
              />

              <FormField label="Protocol">
                <Select
                  options={protocolOption}
                  placeholder="Select protocol"
                  value={protocol}
                  onChange={(selected) => {
                    if (selected) {
                      setProtocol(selected);
                    }
                  }}
                />
              </FormField>
              {protocol === Protocol.HTTPS ? (
                <>
                  <FormField label="Username">
                    <Controller
                      control={control}
                      name="username"
                      render={({ field }) => (
                        <TextInput {...field} isDisabled={!type} />
                      )}
                    />
                  </FormField>
                  <FormField label="Token">
                    <Controller
                      control={control}
                      name="password"
                      render={({ field }) => (
                        <SecretInput
                          isHidden={isSetup}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </FormField>
                </>
              ) : (
                <FormField
                  description="Access Git server with ssh protocol"
                  label="SSH Private key"
                >
                  <Controller
                    name="ssh_privatekey"
                    control={control}
                    render={({ field }) => (
                      <Textarea
                        placeholder={isSetup ? "<REDACTED>" : undefined}
                        rows={10}
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </FormField>
              )}
            </>
          )}
        </Column>
      </Dialog>
    </>
  );
};
