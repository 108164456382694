import {
  Box,
  ChakraListItem,
  ChakraUnorderedList,
  Column,
  ConfirmationDialog,
  ExternalLinkIcon,
  Paragraph,
  Row,
  Spinner,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { isPresent } from "ts-extras";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useJourneySyncsForJourneyVersionQuery } from "src/graphql";

type DeleteJourneyDialogProps = {
  isOpen: boolean;
  journeyVersionId: string | null;
  onClose: () => void;
  onConfirm: () => void;
};

export const DeleteJourneyModal = ({
  isOpen,
  journeyVersionId,
  onClose,
  onConfirm,
}: DeleteJourneyDialogProps) => {
  const journeySyncsQuery = useJourneySyncsForJourneyVersionQuery(
    {
      journeyVersionId: journeyVersionId ?? "",
    },
    {
      enabled: Boolean(journeyVersionId),
      select: (data) =>
        data.journey_syncs
          .filter(
            ({ sync }) =>
              sync && sync.status !== null && sync.destination !== null,
          )
          .map(({ sync }) => sync),
    },
  );

  const journeySyncs = journeySyncsQuery.data?.filter(isPresent) ?? [];

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title="Delete journey"
      confirmButtonText="Delete"
      variant="danger"
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <Column gap={6}>
        <Paragraph>
          Are you sure you want to delete this journey? You will not be able to
          undo this.
        </Paragraph>
        {journeySyncsQuery.isLoading && (
          <Column align="center" justify="center">
            <Spinner />
          </Column>
        )}
        {!journeySyncsQuery.isLoading &&
          journeySyncsQuery.data &&
          journeySyncs.length > 0 && (
            <>
              <Paragraph>These syncs will also be deleted.</Paragraph>
              <ChakraUnorderedList pl={0} ml={0}>
                {journeySyncs.map(({ id, destination }) => (
                  <Row
                    as={ChakraListItem}
                    key={id}
                    align="center"
                    gap={2}
                    overflowX="hidden"
                    mb={3}
                  >
                    {destination && (
                      <IntegrationIcon
                        name={destination.definition.name}
                        src={destination.definition.icon}
                        size={4}
                      />
                    )}

                    <Row
                      gap={2}
                      overflowX="hidden"
                      sx={{
                        a: {
                          overflowX: "hidden",
                        },
                      }}
                    >
                      <Link href={`/syncs/${id}`} isExternal>
                        <TextWithTooltip
                          color="inherit"
                          message={destination?.definition.name}
                        >
                          {`${destination?.definition.name} #${id}`}
                        </TextWithTooltip>
                      </Link>
                      <Box as={ExternalLinkIcon} flexShrink={0} />
                    </Row>
                  </Row>
                ))}
              </ChakraUnorderedList>
            </>
          )}
      </Column>
    </ConfirmationDialog>
  );
};
