import {
  Box,
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  EditableHeading,
  EyeIcon,
  FormField,
  IconButton,
  IgnoreIcon,
  Row,
  Select,
  Spinner,
  Text,
  useToast,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { useOutletContext, useParams } from "src/router";
import { ElementOf } from "ts-essentials";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  DiscardButton,
  Form,
  SaveButton,
  useHightouchForm,
} from "src/components/form";
import { TextWithTooltip } from "src/components/text-with-tooltip";

import { Warning } from "src/components/warning";
import {
  GoldenRecordOutletContext,
  SourceSurvivorshipRule,
  SourceSurvivorshipRules,
  SurvivorshipRule,
  SurvivorshipRules,
} from "src/pages/identity-resolution/types";
import { useNavigate } from "src/router";
import { Reorder } from "src/components/reorder";
import { HeaderCell } from "src/ui/table/cells";
import eventIcon from "src/pages/schema/assets/event.svg";
import parentIcon from "src/pages/schema/assets/parent.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Drawer } from "src/components/drawer";
import { useMemo } from "react";
import {
  GoldenRecordColumnQuery,
  useDeleteGoldenRecordColumnMutation,
  useGoldenRecordColumnQuery,
  useUpdateGoldenRecordColumnAndSourceSurvivorshipMutation,
} from "src/graphql";
import { noop, toLower } from "lodash";
import { IDRV2GoldenRecordReservedColumnNames } from "@hightouch/lib/idr/v2/types/config-types";
import { FieldError } from "src/components/field-error";

type Model = ElementOf<GoldenRecordOutletContext["graph"]["models"]>;
type Column = ElementOf<GoldenRecordColumnQuery["idr_golden_record_columns"]>;

type FormContext = {
  columnName: string;
  survivorshipRule: SurvivorshipRule;
  sourceSurvivorships: {
    idrModel: Model;
    survivorshipRule: SourceSurvivorshipRule;
  }[];
};

export const GoldenRecordColumn = () => {
  const { graph, goldenRecord } = useOutletContext<GoldenRecordOutletContext>();
  const { columnName } = useParams<{ columnName?: string }>();

  const navigate = useNavigate();
  const onClose = () => navigate("..");

  const { data: column, isLoading } = useGoldenRecordColumnQuery(
    {
      goldenRecordId: goldenRecord?.id ?? "",
      columnName: columnName ?? "",
    },
    {
      enabled: Boolean(goldenRecord?.id && columnName),
      select: (data) => data.idr_golden_record_columns[0],
    },
  );

  if (isLoading || !column) {
    return (
      <Drawer closeOnEsc isOpen size="lg" onClose={onClose}>
        <Column height="100%">
          <Row px={4} pt={6} justify="flex-end">
            <IconButton
              aria-label="Close drawer"
              icon={CloseIcon}
              onClick={onClose}
            />
          </Row>
          {isLoading ? (
            <Spinner size="lg" m="auto" />
          ) : (
            <Warning
              title="Column not found"
              subtitle="It may have been deleted"
            />
          )}
        </Column>
      </Drawer>
    );
  }

  return (
    <Drawer closeOnEsc isOpen size="lg" onClose={onClose}>
      <GoldenRecordColumnForm
        graph={graph}
        goldenRecord={goldenRecord}
        column={column}
      />
    </Drawer>
  );
};

type GoldenRecordColumnFormProps = {
  graph: GoldenRecordOutletContext["graph"];
  goldenRecord?: Pick<
    NonNullable<GoldenRecordOutletContext["goldenRecord"]>,
    "idr_golden_record_columns"
  >;
  column: ElementOf<GoldenRecordColumnQuery["idr_golden_record_columns"]>;
};

const columnValidationSchema = (otherColumnName: string[]) =>
  Yup.object().shape({
    columnName: Yup.string()
      .required("Column name is required")
      .notOneOf(
        IDRV2GoldenRecordReservedColumnNames,
        "Cannot use a reserved column name",
      )
      .matches(
        /^\w+$/,
        "Column name may only contain alphanumeric characters and underscores",
      )
      .test("unique", "Column name must be unique", (value) =>
        Boolean(
          value && !otherColumnName.map(toLower).includes(toLower(value)),
        ),
      ),
    // Note: not worth validating other fields here, because other options are all enforced via dropdown
  });

// only models that contain the `column.identifier` should be allowed in source survivorship rules
function isValidSourceSurvivorshipModel(model: Model, column: Column) {
  return model.mappings.some(
    (m) => m.identifier.toLowerCase() === column.identifier.toLowerCase(),
  );
}

const GoldenRecordColumnForm = ({
  graph,
  goldenRecord,
  column,
}: GoldenRecordColumnFormProps) => {
  const navigate = useNavigate();
  const onClose = () => navigate("..");
  const { toast } = useToast();

  const deleteGoldenRecordColumn = useDeleteGoldenRecordColumnMutation();
  const updateGoldenRecordColumnAndSourceSurvivorship =
    useUpdateGoldenRecordColumnAndSourceSurvivorshipMutation();

  const otherColumnNames = (goldenRecord?.idr_golden_record_columns ?? [])
    .filter((c) => c.id !== column.id)
    .map((c) => c.column_name);

  const initialSourceSurvivorships =
    column.survivorship_rule_type === SurvivorshipRule.SourcePriority
      ? column.idr_golden_record_column_source_survivorships.map((source) => ({
          idrModel: source.idr_model,
          survivorshipRule:
            source.survivorship_rule_type as SourceSurvivorshipRule,
        }))
      : // If the survivorship rule is not source priority, set the default value using all models in the graph
        // Note: this will be used as the initial state if a user toggles the survivorship rule to source priority,
        // otherwise it will be ignored for other survivorship rule types
        graph.models
          .filter((model) => isValidSourceSurvivorshipModel(model, column))
          .map((model) => ({
            idrModel: model,
            survivorshipRule: SourceSurvivorshipRule.MostRecent,
          }));

  const form = useHightouchForm<FormContext>({
    values: {
      columnName: column.column_name,
      survivorshipRule: column.survivorship_rule_type as SurvivorshipRule,
      sourceSurvivorships: initialSourceSurvivorships,
    },
    resolver: yupResolver(columnValidationSchema(otherColumnNames)),
    onSubmit: async (data) => {
      const sourceSurvivorships =
        data.survivorshipRule === SurvivorshipRule.SourcePriority
          ? data.sourceSurvivorships.map((source) => ({
              idrModelId: source.idrModel.id,
              survivorshipRuleType: source.survivorshipRule,
            }))
          : [];

      if (
        data.survivorshipRule == SurvivorshipRule.SourcePriority &&
        !sourceSurvivorships.length
      ) {
        throw new Error("Source priority must have at least one source.");
      }

      const {
        updateGoldenRecordColumn,
        setGoldenRecordColumnSourceSurvivorship,
      } = await updateGoldenRecordColumnAndSourceSurvivorship.mutateAsync({
        columnInput: {
          id: column.id,
          columnName: data.columnName,
          survivorshipRuleType: data.survivorshipRule,
        },
        sourceSurvivorshipInput: {
          goldenRecordColumnId: column.id,
          sourceSurvivorships,
        },
      });

      if (
        updateGoldenRecordColumn.__typename ===
        "UpdateGoldenRecordColumnErrorResponse"
      ) {
        throw new Error(updateGoldenRecordColumn.error);
      }

      if (
        setGoldenRecordColumnSourceSurvivorship.__typename ===
        "SetGoldenRecordColumnSourceSurvivorshipErrorResponse"
      ) {
        throw new Error(setGoldenRecordColumnSourceSurvivorship.error);
      }

      // Navigate to the new column name, required since we route based on the column name
      navigate(`../${data.columnName}`);
    },
  });

  const deleteColumn = async (columnId: string) => {
    try {
      const res = await deleteGoldenRecordColumn.mutateAsync({
        input: {
          id: columnId,
        },
      });

      if (
        res.deleteGoldenRecordColumn.__typename ===
        "DeleteGoldenRecordColumnErrorResponse"
      ) {
        throw new Error(res.deleteGoldenRecordColumn.error);
      }

      navigate("..");

      toast({
        id: "delete-golden-record-column",
        title: "Successfully deleted golden record column",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "delete-golden-record-column",
        title: "Failed to delete golden record column",
        message: error.message,
        variant: "error",
      });
    }
  };

  const survivorshipRule = form.watch("survivorshipRule");
  const sourceSurvivorships = form.watch("sourceSurvivorships");

  const ignoredModels = useMemo(() => {
    const modelIds = new Set(
      sourceSurvivorships.map(({ idrModel }) => idrModel.id),
    );

    return graph.models.filter(
      (model) =>
        isValidSourceSurvivorshipModel(model, column) &&
        !modelIds.has(model.id),
    );
  }, [sourceSurvivorships, graph.models]);

  const setIgnored = (ignore: boolean, model: Model) => {
    const newValues = ignore
      ? sourceSurvivorships.filter(({ idrModel }) => model.id !== idrModel.id)
      : [
          ...sourceSurvivorships,
          {
            idrModel: model,
            survivorshipRule: SourceSurvivorshipRule.MostRecent,
          },
        ];

    form.setValue("sourceSurvivorships", newValues, { shouldDirty: true });
  };

  return (
    <Form form={form}>
      <DrawerHeader>
        <Column gap={4} flex={1} minWidth={0}>
          <Row justify="space-between">
            <Controller
              control={form.control}
              name="columnName"
              render={({ field, fieldState }) => (
                <Column>
                  <EditableHeading
                    value={field.value ?? ""}
                    onChange={field.onChange}
                  />
                  <FieldError error={fieldState.error?.message} />
                </Column>
              )}
            />
            <IconButton
              aria-label="Close drawer"
              icon={CloseIcon}
              onClick={onClose}
            />
          </Row>
          <Row gap={1} align="center">
            <Text color="text.secondary" fontWeight="normal">
              Identifier:
            </Text>
            <TextWithTooltip fontWeight="normal">
              {column?.identifier ?? "No identifier"}
            </TextWithTooltip>
          </Row>
        </Column>
      </DrawerHeader>

      <DrawerBody>
        <Column gap={6}>
          <FormField
            label="Survivorship rule"
            description="Choose the survivorship rule to determine which value will be stored in the golden record."
          >
            <Controller
              control={form.control}
              name="survivorshipRule"
              render={({ field }) => (
                <Select
                  placeholder="Select a rule..."
                  options={Object.values(SurvivorshipRules)}
                  value={field.value}
                  onChange={field.onChange}
                  width="100%"
                />
              )}
            />
          </FormField>

          {survivorshipRule === SurvivorshipRule.SourcePriority && (
            <FormField
              label="Source priority"
              description="Rank the sources to select a golden record field from. We'll try to select a value from the highest ranked source first before trying lower ranked sources."
            >
              <Box display="grid" gridTemplateColumns="3fr 2fr 2fr" mb={4}>
                <HeaderCell alignFirstHeader>Priority</HeaderCell>
                <HeaderCell sx={{ pl: 8 }}>Survivorship rule</HeaderCell>
                <HeaderCell sx={{ width: "100%", pr: "16px !important" }}>
                  <Row justify="flex-end" flex={1}>
                    Count of values
                  </Row>
                </HeaderCell>
              </Box>

              {!sourceSurvivorships.length && (
                <Text color="text.secondary">No prioritized sources</Text>
              )}

              <Reorder
                nodeKey="modelId"
                items={sourceSurvivorships}
                showEdgeLabels={false}
                onChange={(items) =>
                  form.setValue("sourceSurvivorships", items, {
                    shouldDirty: true,
                  })
                }
              >
                {sourceSurvivorships.map((sourceSurvivorship, index) => (
                  <ModelRow
                    key={sourceSurvivorship.idrModel.id}
                    model={sourceSurvivorship.idrModel}
                    survivorshipRule={sourceSurvivorship.survivorshipRule}
                    index={index}
                    onToggleIgnore={() =>
                      setIgnored(true, sourceSurvivorship.idrModel)
                    }
                  />
                ))}
              </Reorder>

              <Box my={4}>
                <HeaderCell alignFirstHeader>Ignore</HeaderCell>
              </Box>

              {!ignoredModels.length && (
                <Text color="text.secondary">No ignored sources</Text>
              )}

              {/* Note: ignored models cannot be sorted, we're just using reorder for styling to match the other list */}
              <Reorder
                nodeKey="id"
                items={ignoredModels}
                showEdgeLabels={false}
                onChange={noop}
                isDisabled
              >
                {ignoredModels.map((model, index) => (
                  <ModelRow
                    key={model.id}
                    model={model}
                    index={index}
                    onToggleIgnore={() => setIgnored(false, model)}
                  />
                ))}
              </Reorder>
            </FormField>
          )}
        </Column>
      </DrawerBody>

      <DrawerFooter>
        <ButtonGroup>
          <SaveButton>Save changes</SaveButton>
          <DiscardButton />
        </ButtonGroup>

        <Button
          size="lg"
          variant="warning"
          onClick={() => deleteColumn(column.id)}
          isLoading={deleteGoldenRecordColumn.isLoading}
        >
          Delete
        </Button>
      </DrawerFooter>
    </Form>
  );
};

const ModelRow = ({
  model,
  index,
  survivorshipRule,
  onToggleIgnore,
}: {
  model: Model;
  index: number;
  survivorshipRule?: SourceSurvivorshipRule;
  onToggleIgnore: () => void;
}) => {
  const form = useFormContext<FormContext>();

  // Note: this is a nested field within the sortable items
  // Because of this, we need to use direct form.setValue calls instead of the Controller component
  // I believe this is because the Controller component doesn't update correctly as the item index changes
  const setSourceSurvivorshipRule = (rule: SourceSurvivorshipRule) => {
    form.setValue(`sourceSurvivorships.${index}.survivorshipRule`, rule, {
      shouldDirty: true,
    });
  };

  const modelSize = model.model.query_runs[0]?.size;

  return (
    <Row
      width="100%"
      display="grid"
      gridTemplateColumns="3fr 2fr 2fr"
      alignItems="center"
      gap={2}
      _hover={{
        ".hovered": { display: "block" },
      }}
    >
      <Row align="center" gap={2} overflow="hidden">
        <IntegrationIcon
          name={model.model.name}
          src={model.type === "event" ? eventIcon : parentIcon}
        />
        <TextWithTooltip fontWeight="medium">
          {model.model.name}
        </TextWithTooltip>
      </Row>

      {!survivorshipRule ? (
        <Text color="text.secondary">--</Text>
      ) : (
        <Select
          placeholder="Select a rule..."
          options={Object.values(SourceSurvivorshipRules)}
          value={survivorshipRule}
          width="100%"
          onChange={(o) => o && setSourceSurvivorshipRule(o)}
        />
      )}

      <Row gap={2} minW={0} width="100%" justify="flex-end">
        <TextWithTooltip
          color={modelSize === undefined ? "text.secondary" : "inherit"}
        >
          {modelSize ?? "Unknown size"}
        </TextWithTooltip>

        <Box className="hovered" display="none">
          <IconButton
            aria-label={
              !survivorshipRule ? "Prioritize source" : "Ignore source"
            }
            icon={!survivorshipRule ? EyeIcon : IgnoreIcon}
            mr={-2}
            onClick={onToggleIgnore}
          />
        </Box>
      </Row>
    </Row>
  );
};
