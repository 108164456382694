import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  DrawerFooter,
  EditIcon,
  IconButton,
  Row,
  Skeleton,
  SkeletonBox,
  Text,
} from "@hightouchio/ui";
import { useState } from "react";
import { Drawer } from "src/components/drawer";
import { ReadOnlyConditions } from "src/components/explore/read-only-conditions";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import {
  PermissionedButton,
  PermissionedEditableDescription,
  PermissionedEditableHeading,
} from "src/components/permission";
import {
  AudienceTemplatesQuery,
  usePreviewAudienceTemplateQuery,
} from "src/graphql";
import { Link, useNavigate } from "src/router";
import { AudienceTemplateDefinition } from "src/types/visual";
import { formatDate } from "src/utils/time";
import { useMutations } from "./use-mutations";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import times from "lodash/times";

type Props = {
  templateData: Pick<
    NonNullable<AudienceTemplatesQuery["audience_templates"][number]>,
    | "id"
    | "description"
    | "name"
    | "parent_model"
    | "updated_at"
    | "updated_by_user"
  >;
  isOpen: boolean;
  onClose: () => void;
};

export const PreviewAudienceTemplateDrawer = ({
  templateData: {
    id,
    description,
    name,
    parent_model,
    updated_at,
    updated_by_user,
  },
  isOpen,
  onClose,
}: Props) => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data, isLoading } = usePreviewAudienceTemplateQuery(
    {
      id,
    },
    {
      select: (data) => data.audience_templates_by_pk,
    },
  );

  const { deleteTemplate, updateDescription, updateName } = useMutations({
    id,
  });

  return (
    <>
      <Drawer
        closeOnOverlayClick
        // The header and footer should have all the data needed to be render.
        // Only the content needs to load asynchronously, so we can manaage
        // the loading state within this component.
        isLoading={false}
        isOpen={isOpen}
        size="lg"
        onClose={onClose}
      >
        <>
          <Row
            px={6}
            py={4}
            borderBottom="1px solid"
            borderColor="base.border"
            justifyContent="space-between"
          >
            <Column gap={4}>
              <Column>
                <PermissionedEditableHeading
                  permission={{
                    v1: {
                      resource: "audience_schema",
                      grant: "update",
                      id: parent_model.id,
                    },
                    v2: {
                      resource: "model",
                      grant: "can_update",
                      id: parent_model.id,
                    },
                  }}
                  value={name}
                  onChange={updateName}
                />
                <PermissionedEditableDescription
                  permission={{
                    v1: {
                      resource: "audience_schema",
                      grant: "update",
                      id: parent_model.id,
                    },
                    v2: {
                      resource: "model",
                      grant: "can_update",
                      id: parent_model.id,
                    },
                  }}
                  value={description}
                  onChange={updateDescription}
                />
              </Column>
              <Row>
                <Row alignItems="center" gap={2}>
                  <IntegrationIcon
                    src={parent_model.connection?.definition.icon}
                    name={parent_model.connection?.definition.name}
                  />
                  <Link
                    href={`/schema-v2/view?source=${parent_model?.connection?.id}&id=${parent_model?.connection?.id}`}
                    isExternal
                  >
                    {parent_model?.name}
                  </Link>
                </Row>
                {updated_at && (
                  <>
                    <Divider />
                    <Row alignItems="center" gap={1}>
                      <Text>Last updated: {formatDate(updated_at)} by</Text>
                      <Avatar name={updated_by_user?.name} size="xs" />
                    </Row>
                  </>
                )}
              </Row>
            </Column>
            <IconButton
              aria-label="Closer drawer"
              icon={CloseIcon}
              onClick={onClose}
            />
          </Row>
          <Column flex={1} overflowY="auto" p={6}>
            {!data || isLoading ? (
              <SkeletonDefinition />
            ) : (
              <ReadOnlyConditions
                rootConditions={
                  (data.definition as AudienceTemplateDefinition).conditions
                }
                audiences={data.parent_model.audiences}
                relationships={data.parent_model.relationships}
                traits={data.parent_model.traits}
              />
            )}
          </Column>
        </>

        <DrawerFooter width="100%">
          <ButtonGroup>
            <PermissionedButton
              icon={EditIcon}
              permission={{
                v1: {
                  resource: "audience_schema",
                  grant: "update",
                  id: parent_model.id,
                },
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: parent_model.id,
                },
              }}
              size="lg"
              variant="primary"
              onClick={() => navigate(id)}
            >
              Edit
            </PermissionedButton>
            <Button size="lg" onClick={onClose}>
              Close
            </Button>
          </ButtonGroup>

          <PermissionedButton
            permission={{
              v1: { resource: "audience_schema", grant: "delete" },
              v2: {
                resource: "model",
                grant: "can_delete",
                id: parent_model.id,
              },
            }}
            variant="warning"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </PermissionedButton>
        </DrawerFooter>
      </Drawer>
      <DeleteConfirmationModal
        isOpen={showDeleteModal}
        title="Delete audience template"
        label="audience template"
        showToast={false} // The deleteTemplate callback we're using has its own toast
        onClose={() => setShowDeleteModal(false)}
        onDelete={async () => {
          await deleteTemplate();
          onClose();
        }}
      />
    </>
  );
};

const Divider = () => (
  <Box borderRight="1px solid" borderColor="base.border" mx={4} />
);

const SkeletonDefinition = () => {
  return (
    <Skeleton isLoading>
      <Row gap={4} height="100%" width="100%">
        <SkeletonBox borderLeftRadius="md" height="100%" width="8px" />
        {/* Random width between 70% and 90% */}
        <Column gap={3} width={`${Math.random() * (90 - 70) + 70}%`}>
          {times(3).map((i) => (
            <Column key={i} gap={3}>
              <SkeletonBox borderRadius="md" height="25px" />
              <SkeletonBox
                borderRadius="md"
                height="25px"
                ml={8}
                // Random width between 50% and 70%
                width={`${Math.random() * (70 - 50) + 50}%`}
              />
            </Column>
          ))}
        </Column>
      </Row>
    </Skeleton>
  );
};
