import { FC, useCallback, useMemo, useState } from "react";

import {
  Badge,
  Button,
  ButtonGroup,
  Checkbox,
  Column,
  Dialog,
  FormField,
  Paragraph,
  PlusIcon,
  Row,
  Select,
  Text,
} from "@hightouchio/ui";
import { Link, useNavigate } from "src/router";
import { isPresent } from "ts-extras";

import { useSyncCreationPermissions } from "src/components/permission/creation/sync";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useUser } from "src/contexts/user-context";
import { SyncTemplatesForParentModelQuery } from "src/graphql";
import { Audience } from "src/types/visual";
import { disambiguateSyncs } from "src/utils/syncs";

import { MinimalSyncTemplate } from "./types";
import { HTImage } from "src/components/image";

type Props = {
  audience: NonNullable<Audience>;
  syncTemplates: MinimalSyncTemplate[];
  isLoading?: boolean;
  onSubmit: (args: {
    syncTemplates: MinimalSyncTemplate[];
    splitId?: string;
  }) => void;
  onClose: () => void;
};

export const AddSyncsFromTemplatesModal: FC<Readonly<Props>> = ({
  audience,
  syncTemplates,
  isLoading = false,
  onSubmit,
  onClose,
}) => {
  const { workspace } = useUser();

  const navigate = useNavigate();
  const [selectedSyncTemplates, setSelectedSyncTemplates] = useState<
    { id: number; name: string; destination: { id: string } }[]
  >([]);

  const treatmentGroups = audience.splits.filter(
    (split) => !split.is_holdout_group,
  );
  const treatmentGroupOptions = treatmentGroups.map((treatmentGroup) => ({
    label: `${treatmentGroup.friendly_name} (${treatmentGroup.percentage}%)`,
    value: treatmentGroup,
  }));
  const [selectedTreatmentGroup, setSelectedTreatmentGroup] = useState<
    NonNullable<Audience>["splits"][0] | undefined
  >(treatmentGroupOptions?.[0]?.value);

  const disambiguatedSyncs = useMemo(
    () => disambiguateSyncs(audience.syncs),
    [audience.syncs],
  );

  const syncsIdsForSelectedTreatmentGroup = selectedTreatmentGroup
    ? selectedTreatmentGroup.destination_instance_splits.map(
        (dis) => dis.destination_instance_id,
      )
    : [];
  const activeSyncs = useMemo(
    () =>
      selectedTreatmentGroup
        ? disambiguatedSyncs.filter((sync) =>
            syncsIdsForSelectedTreatmentGroup.includes(sync.id),
          )
        : disambiguatedSyncs,
    [disambiguateSyncs, selectedTreatmentGroup],
  );

  const activeSyncTemplateIds = activeSyncs
    .filter(({ sync_template_id }) => sync_template_id != null)
    .map(({ sync_template_id }) => sync_template_id);
  const inactiveSyncTemplates = syncTemplates.filter(
    ({ id }) => !activeSyncTemplateIds.includes(id),
  );

  const newSyncUrl = `/syncs/new?model=${audience.id}`;
  const newSyncTemplateUrl = "/schema-v2/settings/sync-templates/new";

  const handleAddSyncTemplates = async () => {
    const syncTemplatesMap = new Map(
      syncTemplates.map((syncTemplate) => [syncTemplate.id, syncTemplate]),
    );

    onSubmit({
      syncTemplates: selectedSyncTemplates
        .map(({ id }) => syncTemplatesMap.get(id))
        .filter(isPresent),
      splitId: selectedTreatmentGroup?.id,
    });
  };

  const { syncTemplateFilter } = useSyncCreationPermissions(audience.id);

  const toggleSyncTemplate = useCallback(
    (
      isChecked: boolean,
      syncTemplate: SyncTemplatesForParentModelQuery["sync_templates"][0],
    ) => {
      if (isChecked) {
        setSelectedSyncTemplates((prevState) => prevState.concat(syncTemplate));
      } else {
        setSelectedSyncTemplates((prevState) =>
          prevState.filter((item) => item !== syncTemplate),
        );
      }
    },
    [],
  );

  return (
    <Dialog
      isOpen
      width="xl"
      title="Create syncs from templates"
      variant="form"
      onClose={onClose}
      actions={
        <Row
          justify={!workspace?.sync_templates_only ? "space-between" : "end"}
          width="100%"
        >
          {!workspace?.sync_templates_only && (
            <Button
              icon={PlusIcon}
              variant="tertiary"
              onClick={() => navigate(newSyncUrl)}
            >
              Create sync from scratch
            </Button>
          )}

          <ButtonGroup>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              isDisabled={isLoading || selectedSyncTemplates.length === 0}
              isLoading={isLoading}
              variant="primary"
              onClick={handleAddSyncTemplates}
            >
              Add syncs
            </Button>
          </ButtonGroup>
        </Row>
      }
    >
      <Column gap={6}>
        <Paragraph>
          Add syncs using the templates below which are pre-configured to work
          with this Audience. If your destination isn't listed below, you can{" "}
          <Link href={newSyncUrl}>create a new sync</Link> from scratch or{" "}
          <Link href={newSyncTemplateUrl}>create a new sync template.</Link>
        </Paragraph>

        {treatmentGroups.length > 1 && (
          <FormField label="Split group">
            <Select
              options={treatmentGroupOptions}
              value={selectedTreatmentGroup}
              onChange={setSelectedTreatmentGroup}
            />
          </FormField>
        )}

        {activeSyncs.length > 0 && (
          <Column gap={2}>
            <Text fontWeight="medium">Active syncs</Text>
            <Row flexWrap="wrap" gap={2}>
              {activeSyncs.map((sync) => (
                <Badge
                  key={sync.id}
                  iconSrc={sync?.destination?.definition.icon}
                >
                  {sync.name}
                </Badge>
              ))}
            </Row>
          </Column>
        )}

        <Column gap={2}>
          <Text fontWeight="medium">Add sync templates</Text>

          <Column
            sx={{
              "& > :not(:last-child)": {
                borderBottom: "1px solid",
                borderColor: "base.border",
              },
            }}
          >
            {inactiveSyncTemplates.length ? (
              inactiveSyncTemplates.map((syncTemplate) => {
                const isDisabled = !syncTemplateFilter(syncTemplate.id);
                return (
                  <Row key={syncTemplate.id} alignItems="center" gap={3} py={2}>
                    <Checkbox
                      isDisabled={isDisabled}
                      isChecked={selectedSyncTemplates.includes(syncTemplate)}
                      onChange={(event) =>
                        toggleSyncTemplate(event.target.checked, syncTemplate)
                      }
                    />
                    <Row
                      alignItems="center"
                      justify="space-between"
                      overflow="hidden"
                      flex={1}
                      minWidth={0}
                      cursor="pointer"
                      pointerEvents={isDisabled ? "none" : "auto"}
                      gap={2}
                      onClick={() => {
                        const isSelected = selectedSyncTemplates.find(
                          (template) => template.id === syncTemplate.id,
                        );
                        toggleSyncTemplate(!isSelected, syncTemplate);
                      }}
                    >
                      <Row align="center" gap={1.5} overflow="hidden">
                        <HTImage
                          h={6}
                          w={6}
                          src={syncTemplate.destination.definition.icon}
                          alt={syncTemplate.destination.definition.name}
                        />
                        <TextWithTooltip fontWeight="medium">
                          {syncTemplate.name}
                        </TextWithTooltip>
                      </Row>
                    </Row>
                  </Row>
                );
              })
            ) : (
              <Text color="text.secondary">
                All sync templates have been applied
              </Text>
            )}
          </Column>
        </Column>
      </Column>
    </Dialog>
  );
};
