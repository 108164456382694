import { useAllFilterableColumnsQuery } from "src/graphql";
import { FilterableColumn } from "src/types/visual";

type UseFetchFilterableAudienceColumnsReturn = {
  filterableColumns: { [modelId: string]: FilterableColumn[] };
  isLoading: boolean;
};

// Stable reference for side effects
const EMPTY_OBJECT = {};

/**
 * This hook is used to fetch the filterable audience columns for a list of segments.
 *
 * The columns will be grouped by model id.
 *
 * Fetching the columns of nested relationships is quite slow. To speed things up, those are removed from
 * most nested relationships.
 */
export const useFetchFilterableAudienceColumns = (
  segmentIds: string[],
): UseFetchFilterableAudienceColumnsReturn => {
  const { data: filterableColumns, isLoading } = useAllFilterableColumnsQuery(
    {
      segmentIds,
    },
    {
      enabled: segmentIds.length > 0,
      select: (data) =>
        data.segments.reduce(
          (dictionary, model) => {
            // Add columns by model id
            dictionary[model.id] = model.filterable_audience_columns;

            return dictionary;
          },
          {} as Record<string, FilterableColumn[]>,
        ),
    },
  );

  return {
    filterableColumns: filterableColumns ?? EMPTY_OBJECT,
    isLoading,
  };
};
