import { useState } from "react";
import { subDays, subHours, subMinutes } from "date-fns";
import { Button, Dialog, Row, ArrowRightIcon } from "@hightouchio/ui";
import {
  MonitorConditionTypes,
  MonitorStatus,
} from "@hightouch/lib/resource-monitoring/types";

import { Link, useNavigate } from "src/router";
import {
  useMigrateToV2AlertsMutation,
  useUpdateWorkspaceMutation,
} from "src/graphql";
import { SplashPage } from "src/components/splash-page";
import { SyncHealthAlerts } from "src/pages/syncs/sync/components/sync-health-alerts";
import { AlertingIcon } from "src/ui/icons";
import { SyncHealthStatus } from "src/utils/syncs";
import bottomRightGradient from "src/assets/backgrounds/bottom-right-green-gradient.svg";
import topLeftGradient from "src/assets/backgrounds/top-left-green-gradient.svg";
import { PermissionedButton } from "src/components/permission";
import { useUser } from "src/contexts/user-context";
import { HTImage } from "src/components/image";

export const AdvancedAlertingSplashPage = () => {
  const navigate = useNavigate();
  const [migrationModalOpen, setMigrationModalOpen] = useState(false);

  const migrateMutation = useMigrateToV2AlertsMutation();
  const enableAlertingMutation = useUpdateWorkspaceMutation();

  const { workspace } = useUser();

  const enableAlertingV2 = async () => {
    await enableAlertingMutation.mutateAsync({
      id: workspace?.id,
      input: {
        alerting_v2_enabled: true,
      },
    });

    await migrateMutation.mutateAsync({});

    navigate("/alerting");
  };

  return (
    <>
      <SplashPage
        hideLogos
        icon={<AlertingIcon />}
        eyebrow="Advanced Alerting"
        heading="New alerting features are available for early access"
        description="Detect, triage, and resolve issues with your syncs before they impact customers and internal teams. With advanced alerting, you can receive actionable alerts based on custom monitors for sync throughput, row-level errors, and other metrics. Reduce alert fatigue and set custom routing rules to loop in the relevant teams when things break. Now in beta."
        visual={
          <SyncHealthAlerts
            health={SyncHealthStatus.Unhealthy}
            statuses={[
              {
                conditionType: MonitorConditionTypes.SyncRejectedRows,
                id: "throughput",
                eventTime: subMinutes(new Date(), 15).toISOString(),
                text: "More than 10% of rows were rejected during a sync run",
                status: MonitorStatus.Unhealthy,
              },
              {
                conditionType: MonitorConditionTypes.SyncDuration,
                id: "slow",
                eventTime: subHours(new Date(), 8).toISOString(),
                text: "Sync has been running for more than 2 hours",
                status: MonitorStatus.Warning,
              },
              {
                conditionType: MonitorConditionTypes.SyncSequentialFailures,
                id: "rejected",
                eventTime: subDays(new Date(), 2).toISOString(),
                text: "3 or more consecutive sync runs experienced fatal errors",
                status: MonitorStatus.Unhealthy,
              },
            ]}
          />
        }
        backgroundGradient={
          <>
            <HTImage
              position="absolute"
              top={0}
              left={0}
              src={topLeftGradient}
              decorative
            />
            <HTImage
              position="absolute"
              bottom={0}
              right={0}
              src={bottomRightGradient}
              decorative
            />
          </>
        }
        actions={
          <Row alignItems="baseline" gap={4}>
            <PermissionedButton
              unauthorizedTooltip="You must be an admin to enable beta features"
              permission={{
                v2: { resource: "workspace", grant: "can_update" },
              }}
              variant="primary"
              onClick={() => setMigrationModalOpen(true)}
            >
              Enable beta features
            </PermissionedButton>

            <Link href="/extensions/alerting/configuration">
              Continue to alerting settings <ArrowRightIcon />
            </Link>
          </Row>
        }
      />
      <Dialog
        title="Almost there! Please watch this video before migrating."
        width={"4xl" as any}
        isOpen={migrationModalOpen}
        variant="form"
        actions={
          <Row alignItems="baseline" justifyContent="space-between" w="100%">
            <Link href="" onClick={() => setMigrationModalOpen(false)}>
              Never mind, do not enable beta features
            </Link>
            <Button
              isLoading={
                migrateMutation.isLoading || enableAlertingMutation.isLoading
              }
              variant="primary"
              onClick={() => enableAlertingV2()}
            >
              Enable and migrate existing alerts
            </Button>
          </Row>
        }
        onClose={() => setMigrationModalOpen(false)}
      >
        <video
          controls={true}
          src="https://cdn.sanity.io/files/pwmfmi47/production/494785d34ebf8fa1bc7086370f750a9696d21ae3.mp4"
          poster="https://cdn.sanity.io/images/pwmfmi47/production/228a3f8aba24888f9b90ce33a61cc41fd8c469f6-1891x1064.jpg?fm=png"
        ></video>
      </Dialog>
    </>
  );
};
