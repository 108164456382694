import { abbreviateNumber } from "src/utils/numbers";
import { TileCountText } from "./tile-count-text";
import { Text } from "@hightouchio/ui";
import { JourneyEventType } from "src/pages/journeys/types";

export const CellContent = ({
  showCount = false,
  onOpenDrawer,
  fontWeight = "normal",
  nodeId = "",
  text = "",
  isBranch = false,
  eventType,
}: {
  showCount?: boolean;
  onOpenDrawer: () => void;
  fontWeight?: "normal" | "medium" | "semibold" | undefined;
  nodeId?: string;
  text?: null | string | number;
  isBranch?: boolean;
  eventType?: JourneyEventType;
}): JSX.Element => {
  if (text === "") return <></>;
  if (text == null) {
    return <Text fontWeight={fontWeight}>--</Text>;
  } else if (Number(text) === 0 || isNaN(Number(text))) {
    return <Text fontWeight={fontWeight}>0</Text>;
  }
  if (eventType == null) {
    return (
      <Text fontWeight={fontWeight}>{abbreviateNumber(Number(text))}</Text>
    );
  }

  const cellText =
    !isBranch || showCount ? abbreviateNumber(Number(text)) : `${text}%`;
  const href = nodeId ? `${nodeId}/member-details` : "member-details";
  const finalHref = eventType ? `${href}?event=${eventType}` : href;

  return (
    <TileCountText
      fontWeight={fontWeight}
      text={cellText}
      href={finalHref}
      onClick={onOpenDrawer}
    />
  );
};
