import { Box, BoxProps } from "@hightouchio/ui";

type BaseImageProps = Omit<BoxProps, "alt"> & {
  src: string;
};

type ImageWithAlt = BaseImageProps & {
  alt: string;
  decorative?: never;
};

type DecorativeImage = BaseImageProps & {
  decorative: true;
  alt?: never;
};

type ImageProps = ImageWithAlt | DecorativeImage;

export const HTImage = ({ alt, decorative, ...props }: ImageProps) => {
  return <Box as="img" alt={decorative ? "" : alt} {...props} />;
};
