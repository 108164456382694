import { FC } from "react";

import { Row } from "@hightouchio/ui";
import { AudienceColumn } from "src/components/explore/filter-popover/constants";
import { IconBox } from "src/components/icon-box";
import { LinkWithTooltip } from "src/components/link-with-tooltip";

export const AudienceLink: FC<
  Readonly<{ audience: { id: string; name: string } }>
> = ({ audience }) => {
  if (!audience) return null;

  return (
    <Row gap={2} align="center" overflow="hidden">
      <IconBox
        bg={AudienceColumn.color}
        boxSize="20px"
        icon={AudienceColumn.icon}
        iconSize="12px"
      />
      <LinkWithTooltip href={`/audiences/${audience.id}`}>
        {audience.name}
      </LinkWithTooltip>
    </Row>
  );
};
