import { FC } from "react";

import {
  Box,
  ClickIcon,
  Column,
  CartIcon,
  FormField,
  NumberInput,
  Row,
  Select,
  Text,
  TextInput,
} from "@hightouchio/ui";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";

import { useListInteractionModelsQuery } from "src/graphql";
import { TIME_OPTIONS } from "src/pages/metrics/constants";
import { AttributionMethodType } from "src/types/visual";

import { WeightingOptions } from "./constants";

export const AttributionMethodForm: FC<{
  parentModelId: string;
}> = ({ parentModelId }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  const weightingOption = watch("weighting");

  const { fields } = useFieldArray({
    control,
    name: "interactions",
    rules: {
      minLength: 1,
    },
  });

  const { data: interactionModelData, isLoading } =
    useListInteractionModelsQuery(
      { parentModelId },
      {
        select: (data) => data.segments ?? [],
      },
    );

  return (
    <Column gap={4}>
      <Controller
        control={control}
        name="name"
        render={({ field, fieldState }) => (
          <FormField
            label="Name"
            description="Something simple like “14 day email open”"
            error={fieldState.error?.message}
          >
            <TextInput
              placeholder="Enter a name..."
              isInvalid={Boolean(fieldState.error?.message)}
              {...field}
            />
          </FormField>
        )}
      />

      <Column gap={4}>
        {fields.map((field, index) => (
          <FormField
            key={field.id}
            label="Event"
            description="Select the event that attributes users"
            error={
              errors.interactions?.[index]
                ? String(errors.interactions[index].interactionModelId.message)
                : undefined
            }
            {...field}
          >
            <Column gap="4">
              <Controller
                control={control}
                name={`interactions.${index}.interactionModelId`}
                render={({ field }) => (
                  <Select
                    isLoading={isLoading}
                    placeholder="Select an event..."
                    isInvalid={
                      errors.interactions?.[index].interactionModelId.message
                    }
                    {...field}
                    options={interactionModelData ?? []}
                    optionLabel={(option) => option.name}
                    optionValue={(option) => option.id}
                  />
                )}
              />
              <Row pl="4" gap={2} alignItems="center">
                <Text>Lookback window</Text>
                <Box maxWidth="50px">
                  <Controller
                    control={control}
                    name={`interactions.${index}.lookbackWindow.quantity`}
                    render={({ field }) => (
                      <NumberInput isInvalid={false} min={1} {...field} />
                    )}
                  />
                </Box>
                <Controller
                  control={control}
                  name={`interactions.${index}.lookbackWindow.unit`}
                  render={({ field }) => (
                    <Select options={TIME_OPTIONS} {...field} />
                  )}
                />
              </Row>
            </Column>
          </FormField>
        ))}
      </Column>

      <FormField
        label="Weighting"
        description="How should campaigns be attributed when multiple are interacted with?"
      >
        <Controller
          control={control}
          name="weighting"
          render={({ field }) => (
            <Select options={WeightingOptions} {...field} />
          )}
        />
      </FormField>
      <WeightingGraphics weightingOption={weightingOption} />
    </Column>
  );
};

const AttributionMethodDescriptionPrefix = {
  [AttributionMethodType.Participation]: "every",
  [AttributionMethodType.FirstTouch]: "the first",
  [AttributionMethodType.LastTouch]: "the last",
};

export const WeightingGraphics: FC<{
  weightingOption: AttributionMethodType;
}> = ({ weightingOption }) => {
  return (
    <Column
      background="base.lightBackground"
      pt={4}
      pb={6}
      px={6}
      border="1px solid"
      borderColor="base.border"
      borderRadius={6}
      width="fit-content"
      align-items="flex-start"
      gap={6}
    >
      <Row gap={10} position="relative" zIndex="1">
        <Column width={20} alignItems="center">
          <InteractionGraphic
            isHighlighted={[
              AttributionMethodType.FirstTouch,
              AttributionMethodType.Participation,
            ].includes(weightingOption)}
          />
          <Text color="text.secondary">Campaign A</Text>
          <Text color="text.secondary">Event</Text>
        </Column>
        <Column width={20} alignItems="center">
          <InteractionGraphic
            isHighlighted={[AttributionMethodType.Participation].includes(
              weightingOption,
            )}
          />
          <Text color="text.secondary">Campaign B</Text>
          <Text color="text.secondary">Event</Text>
        </Column>
        <Column width={20} alignItems="center">
          <InteractionGraphic
            isHighlighted={[
              AttributionMethodType.Participation,
              AttributionMethodType.LastTouch,
            ].includes(weightingOption)}
          />
          <Text color="text.secondary">Campaign C</Text>
          <Text color="text.secondary">Event</Text>
        </Column>
        <Column width={20} alignItems="center">
          <ConversionGraphic />
          <Text color="text.secondary">Conversion</Text>
          <Text color="text.secondary">Event</Text>
        </Column>
        <Box
          my={2}
          borderTop="2px dashed"
          borderColor="base.border"
          width="348px"
          position="absolute"
          left="32px"
          top="24px"
          zIndex="-1"
        />
      </Row>
      <Row justifyContent="center">
        <Text size="sm" color="text.tertiary">
          The conversion is attributed to{" "}
          <Text size="sm" fontWeight="semibold">
            {AttributionMethodDescriptionPrefix[weightingOption]}{" "}
          </Text>
          campaign interacted with.
        </Text>
      </Row>
    </Column>
  );
};

const InteractionGraphic: FC<{
  isHighlighted: boolean;
}> = ({ isHighlighted }) => {
  return (
    <Box
      padding="2"
      background={
        isHighlighted
          ? "radial-gradient(50% 50% at 50% 50%, rgba(248, 251, 252, 0.00) 0%, rgba(248, 251, 252, 0.12) 50.5%, #F8FBFC 95%), #1D656A;"
          : "base.lightBackground"
      }
      borderRadius="full"
    >
      <Box
        padding="2"
        color="text.secondary"
        fontSize="24px"
        lineHeight="24px"
        background="white"
        filter="drop-shadow(0px 33.529px 13.554px rgba(0, 0, 0, 0.01)) drop-shadow(0px 19.261px 11.414px rgba(0, 0, 0, 0.02)) drop-shadow(0px 8.561px 8.561px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2.14px 4.28px rgba(0, 0, 0, 0.05));"
        border="2px solid"
        borderColor={isHighlighted ? "primary.base" : "white"}
        borderRadius="full"
      >
        <ClickIcon color="primary.base" />
      </Box>
    </Box>
  );
};

const ConversionGraphic = () => (
  <Box padding="2" background="base.lightBackground">
    <Box
      padding="2"
      borderWidth="2px"
      borderColor="primary.base"
      fontSize="24px"
      lineHeight="24px"
      borderRadius="8px"
      background="primary.base"
      filter="drop-shadow(0px 33.529px 13.554px rgba(0, 0, 0, 0.01)) drop-shadow(0px 19.261px 11.414px rgba(0, 0, 0, 0.02)) drop-shadow(0px 8.561px 8.561px rgba(0, 0, 0, 0.04)) drop-shadow(0px 2.14px 4.28px rgba(0, 0, 0, 0.05));"
    >
      <CartIcon color="white" />
    </Box>
  </Box>
);
