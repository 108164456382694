import {
  Alert,
  Box,
  Column,
  Row,
  Skeleton,
  SkeletonBox,
} from "@hightouchio/ui";
import { Card } from "src/components/card";
import { MetadataLabel } from "src/components/metadata-bar";
import { abbreviateNumber } from "src/utils/numbers";
import { useSendsSummary } from "src/pages/decision-engines/flows/flow/use-sends-summary";

export const SendsSummary = ({
  flowId,
  parentModelId,
  flowStartDate,
  lastFlowRunStartedAt,
  messageId,
  emptyTotalSendsAlertMessage,
}: {
  flowId: string;
  parentModelId: string;
  flowStartDate: string;
  lastFlowRunStartedAt: string;
  messageId?: string;
  emptyTotalSendsAlertMessage?: string;
}) => {
  const { summary, isLoading } = useSendsSummary({
    flowId,
    parentModelId,
    flowStartDate,
    lastFlowRunStartedAt,
    messageId,
  });

  return (
    <>
      {summary?.totalSends === 0 && emptyTotalSendsAlertMessage && (
        <Alert
          variant="inline"
          title="No sends yet"
          type="subtle"
          message={emptyTotalSendsAlertMessage}
        />
      )}
      <Row gap={4}>
        <Stat
          key="sends-total"
          heading="Total Sends"
          value={summary?.totalSends}
          isLoading={isLoading}
        />
        <Stat
          key="sends-scheduled-today"
          heading="Scheduled for today"
          value={summary?.scheduledToday}
          isLoading={isLoading}
        />
        <Stat
          key="sends-scheduled-tomorrow"
          heading="Scheduled for tomorrow"
          value={summary?.scheduledTomorrow}
          isLoading={isLoading}
        />
      </Row>
    </>
  );
};

const Stat = ({
  heading,
  value,
  isLoading = false,
}: {
  key: string;
  heading: string;
  value: number | null | undefined;
  isLoading?: boolean;
}) => {
  return (
    <Card p={6} flex="1">
      <Column gap={2}>
        <MetadataLabel>{heading}</MetadataLabel>
        <Skeleton isLoading={isLoading}>
          {isLoading ? (
            <SkeletonBox h="20px" w="120px" />
          ) : (
            <Box as="span" fontSize="24px" fontWeight="semibold">
              {value === null || value === undefined
                ? "--"
                : value === 0
                  ? "0"
                  : abbreviateNumber(value)}
            </Box>
          )}
        </Skeleton>
      </Column>
    </Card>
  );
};
