import { Box, Button, Column, Row, Text } from "@hightouchio/ui";
import { useSampleAudienceTemplateSelectorQuery } from "src/graphql";
import { TextWithTooltip } from "src/components/text-with-tooltip";

const NUM_SAMPLE_TEMPLATES = 4;

export const AudienceTemplateSelector = ({
  parentModelId,
  onClick,
}: {
  parentModelId: string;
  onClick: (templateId?: string) => void;
}) => {
  const { data, isLoading } = useSampleAudienceTemplateSelectorQuery({
    parentModelId,
    limit: NUM_SAMPLE_TEMPLATES,
  });

  const sampleAudienceTemplates = data?.audience_templates;
  const audienceTemplatesCount =
    data?.audience_templates_aggregate.aggregate?.count ?? 0;

  const hasMoreTemplates = audienceTemplatesCount > NUM_SAMPLE_TEMPLATES;

  if (isLoading || audienceTemplatesCount === 0) {
    return null;
  }

  return (
    <Column
      bg="white"
      borderRadius="md"
      border="1px solid"
      borderColor="base.border"
      gap={3}
      height="fit-content"
      p={6}
    >
      <Row alignItems="center" justifyContent="space-between">
        <Text fontWeight="medium" size="lg">
          Start with an audience template
        </Text>
        {hasMoreTemplates && (
          <Button onClick={() => onClick()}>
            View all ({audienceTemplatesCount})
          </Button>
        )}
      </Row>
      <Box
        display="grid"
        columnGap={4}
        gridTemplateColumns={
          sampleAudienceTemplates?.length === NUM_SAMPLE_TEMPLATES
            ? "repeat(4, minmax(0, 1fr))"
            : "repeat(3, minmax(0, 1fr))"
        }
      >
        {sampleAudienceTemplates?.map((template) => (
          <Column
            key={template.id}
            border="1px solid"
            borderColor="base.border"
            borderRadius="md"
            borderLeftColor="teal.300"
            borderLeftWidth="8px"
            boxShadow="sm"
            cursor="pointer"
            gap={4}
            _hover={{ bg: "base.lightBackground", boxShadow: "md" }}
            justifyContent="space-between"
            maxHeight="162px"
            p={4}
            role="button"
            onClick={() => onClick(template.id)}
          >
            <Column>
              <TextWithTooltip fontWeight="medium">
                {template.name}
              </TextWithTooltip>
              <TextWithTooltip color="text.secondary">
                {template.description}
              </TextWithTooltip>
            </Column>
            {template.created_by_user?.name && (
              <Text color="text.secondary" size="sm">
                Created by {template.created_by_user?.name}
              </Text>
            )}
          </Column>
        ))}
      </Box>
    </Column>
  );
};
