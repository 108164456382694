import * as Sentry from "@sentry/react";
import { useMemo, useState } from "react";

import {
  Checkbox,
  CheckboxGroup,
  Column,
  FilterIcon,
  Menu,
  MenuIconButton,
  MenuList,
  Row,
  SectionHeading,
  Text,
} from "@hightouchio/ui";

import { Card } from "src/components/card";
import {
  MinimalDecisionEngineFlowMessagesQuery,
  QueryEvaluateTimeSeriesMetricArgs,
  useDecisionEngineFlowChartsQuery,
} from "src/graphql";
import { flowSyntheticColumnValuesGetter } from "src/pages/analytics/decision-engine-utils";
import {
  GroupByColumn,
  SyntheticColumnValuesGetter,
} from "src/pages/analytics/types";
import { Pagination } from "src/ui/table/pagination";
import { useTableConfig } from "src/ui/table/use-table-config";
import { BreakdownByBadge } from "./components/breakdown-by-badge";
import { BreakdownChart } from "./components/breakdown-chart";
import { EmptyChartsPlaceholder } from "./components/empty-charts-placeholder";
import { GeneratedChartsBlurb } from "./components/generated-charts-blurb";
import { BreakdownType, chartSchema, InsightsChartType } from "./types";
import { useBreakdownMetricSeries } from "./use-breakdown-metric-series";

const MAX_CHARTS = 200;
const CHARTS_PER_PAGE = 10;

type GlobalBreakdownFilters = {
  creatives: boolean;
  users: boolean;
};

export const GlobalBreakdowns = ({
  agentId,
  outcomeId,
  flowMessages,
}: {
  agentId: string;
  outcomeId: string;
  flowMessages: MinimalDecisionEngineFlowMessagesQuery["decision_engine_flow_messages"];
}) => {
  const [filters, setFilters] = useState<GlobalBreakdownFilters>({
    creatives: true,
    users: true,
  });

  const { limit, offset, page, setPage } = useTableConfig({
    limit: CHARTS_PER_PAGE,
  });

  const breakdownByTypeFilter =
    !filters.creatives || !filters.users
      ? {
          breakdown_by_type: {
            _in: [
              filters.creatives && BreakdownType.ActionFeature,
              filters.users && BreakdownType.UserFeature,
            ].filter(Boolean) as BreakdownType[],
          },
        }
      : null;

  const { data: chartsData } = useDecisionEngineFlowChartsQuery(
    {
      flowId: agentId,
      filters: {
        _and: [
          { outcome_id: { _eq: outcomeId } },
          { chart_type: { _eq: InsightsChartType.GlobalBreakdown } },
          ...(breakdownByTypeFilter ? [breakdownByTypeFilter] : []),
        ],
      },
      orderBy: { top_chart: "desc_nulls_last", rank: "desc" },
      limit: MAX_CHARTS,
    },
    {
      select: (data) => ({
        charts: data.decision_engine_flow_charts,
      }),
      suspense: true,
    },
  );

  const validCharts = useMemo(() => {
    const charts = chartsData?.charts ?? [];
    return charts.filter((chart) => {
      try {
        chartSchema.validateSync(chart);
        return true;
      } catch (error) {
        Sentry.captureException(
          `Global breakdown chart ${chart.id} schema validation failed: ${error?.message}`,
        );
        return false;
      }
    });
  }, [chartsData?.charts]);

  const paginatedCharts = validCharts.slice(offset, offset + limit);

  // Transforms values for certain columns (e.g. message IDs -> message name)
  const valuesTransformer = flowSyntheticColumnValuesGetter(flowMessages);

  return (
    <Column gap={6} p={6} flex={1}>
      <Row justify="space-between">
        <Column>
          <Text size="lg" fontWeight="medium">
            Top performers
          </Text>
          <Text size="sm" color="text.secondary">
            Overall best performing users & creative
          </Text>
        </Column>
        <Row gap={4}>
          {/* <Button
            variant="tertiary"
            onClick={() => {}}
            icon={EditIcon}
            aria-label="Build a chart"
          >
            Build charts from scratch
          </Button> */}
          <GlobalBreakdownsFilterMenu
            filters={filters}
            onChange={(filters) => {
              setFilters(filters);
              setPage(0);
            }}
          />
        </Row>
      </Row>
      {validCharts.length === 0 ? (
        <EmptyChartsPlaceholder />
      ) : (
        <>
          <GeneratedChartsBlurb />
          {paginatedCharts.map((chart) => (
            <GlobalBreakdownChart
              key={chart.id}
              breakdownByDimension={chart.breakdown_by_dimension!}
              timeSeriesRequest={chart.time_series_request}
              valuesTransformer={valuesTransformer}
            />
          ))}
          <Pagination
            page={page}
            setPage={setPage}
            count={validCharts.length}
            rowsPerPage={limit}
            label="charts"
          />
        </>
      )}
    </Column>
  );
};

const GlobalBreakdownChart = ({
  breakdownByDimension,
  timeSeriesRequest,
  valuesTransformer,
}: {
  breakdownByDimension: NonNullable<string>;
  timeSeriesRequest: QueryEvaluateTimeSeriesMetricArgs;
  valuesTransformer: SyntheticColumnValuesGetter;
}) => {
  const groupByColumns: GroupByColumn[] = (
    timeSeriesRequest.groupByColumns ?? []
  ).map((gb) => gb.column);

  const { graph, isLoading, errorMessage } = useBreakdownMetricSeries({
    timeSeriesRequest,
    valuesTransformer,
    groupByColumns,
  });

  return (
    <Card p={6} gap={6}>
      <Row gap={2}>
        <SectionHeading>Conversion rate by</SectionHeading>
        <BreakdownByBadge breakdownByDimension={breakdownByDimension} />
      </Row>
      <BreakdownChart
        isLoading={isLoading}
        isError={!!errorMessage}
        data={graph.series}
        groupByColumns={groupByColumns}
        enableSearch={false}
        hideMetricColumn={true}
      />
    </Card>
  );
};

const GlobalBreakdownsFilterMenu = ({
  filters,
  onChange,
}: {
  filters: GlobalBreakdownFilters;
  onChange: (filters: GlobalBreakdownFilters) => void;
}) => {
  return (
    <Menu>
      <MenuIconButton
        icon={FilterIcon}
        aria-label="Filter charts"
        variant="secondary"
      />
      <MenuList>
        <Column gap={4} p={4}>
          <Text
            size="sm"
            color="text.secondary"
            fontWeight="semibold"
            textTransform="uppercase"
          >
            Show me charts about...
          </Text>
          <CheckboxGroup>
            <Checkbox
              onChange={(e) =>
                onChange({
                  ...filters,
                  creatives: e.target.checked,
                })
              }
              label="Top performing creatives"
              isChecked={filters.creatives}
            />
            <Checkbox
              onChange={(e) =>
                onChange({
                  ...filters,
                  users: e.target.checked,
                })
              }
              label="Top performing users"
              isChecked={filters.users}
            />
          </CheckboxGroup>
        </Column>
      </MenuList>
    </Menu>
  );
};
