import { Navigate, RouteObject } from "src/router";
import { FunctionsOverview } from "./functions";
import { FunctionLayout } from "./function";
import { CreateFunction } from "./function/create";
import { FunctionDetails } from "./function/function";
import { FunctionSyncs } from "./function/connected-syncs";

export const routes: Array<RouteObject> = [
  {
    index: true,
    element: <FunctionsOverview />,
  },
  {
    path: "new",
    element: <CreateFunction />,
  },
  {
    path: ":id",
    element: <FunctionLayout />,
    children: [
      {
        path: "code",
        element: <FunctionDetails />,
      },
      {
        path: "syncs",
        element: <FunctionSyncs />,
      },
      {
        index: true,
        element: (
          <Navigate
            to={{ pathname: "code", search: location.search }}
            replace
          />
        ),
      },
    ],
  },
];
