import { FC, useMemo } from "react";

import { Navigate, Outlet, Route, Routes } from "src/router";
import { DetailPage } from "src/components/layout";
import { useUser } from "src/contexts/user-context";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { APIKeys } from "./api-keys";
import { AuditLog } from "./audit-log";
import { Billing } from "./billing";
import { CloudProviders } from "./cloud-providers";
import { Members } from "./members";
import { Roles } from "./roles";
import { SSO } from "./sso";
import { Storage } from "./storage";
import { Tunnels } from "./tunnels";
import { Workspace } from "./workspace";
import { Groups } from "./groups";
import { RoleWorkspacePolicy as RoleWorkspaceGrants } from "src/pages/organizations/roles/role/workspaces/workspace";
import { DestinationGrants } from "src/pages/organizations/roles/role/workspaces/workspace/destinations";
import { SourceGrants } from "src/pages/organizations/roles/role/workspaces/workspace/sources";
import { ParentModelGrants } from "src/pages/organizations/roles/role/workspaces/workspace/parent-models";
import { GeneralGrants } from "src/pages/organizations/roles/role/workspaces/workspace/general";
import { DetailPageTab } from "src/components/layout/detail-page";

export const SettingsRouter: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/workspace" element={<Workspace />} />
        <Route path="/members" element={<Members />} />
        <Route path="/groups" element={<Groups />}>
          <Route
            path=":roleId/workspaces/:workspaceId"
            element={<RoleWorkspaceGrants redirectToWorkspaceSettings />}
          >
            <Route
              index
              element={
                <Navigate
                  to={{ pathname: "general", search: location.search }}
                  replace
                />
              }
            />
            <Route path="general" element={<GeneralGrants />} />
            <Route path="sources" element={<SourceGrants />} />
            <Route path="destinations" element={<DestinationGrants />} />
            <Route path="parent-models" element={<ParentModelGrants />} />
          </Route>
        </Route>
        <Route path="/roles" element={<Roles />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/cloud-providers" element={<CloudProviders />} />
        <Route path="/api-keys" element={<APIKeys />} />
        <Route path="/storage" element={<Storage />} />
        <Route path="/tunnels/*" element={<Tunnels />} />
        <Route path="/sso" element={<SSO />} />
        <Route path="/audit-log" element={<AuditLog />} />
        <Route
          index
          element={
            <Navigate
              to={{ pathname: "members", search: location.search }}
              replace
            />
          }
        />
      </Route>
    </Routes>
  );
};

const Layout: FC = () => {
  const { user } = useUser();
  const { isPermitted: hasReadPermission } = useResourcePermission({
    v2: { resource: "workspace", grant: "can_read" },
  });
  const { isPermitted: hasUpdatePermission } = useResourcePermission({
    v2: { resource: "workspace", grant: "can_update" },
  });

  const tabs = useMemo(() => {
    const BASE_TABS = [
      { title: "Billing", path: "billing" },
      {
        title: "Cloud providers",
        path: "cloud-providers",
      },
      { title: "API keys", path: "api-keys" },
      {
        title: "Storage",
        path: "storage",
        bg: "base.lightBackground",
      },
      { title: "Tunnels", path: "tunnels" },
      {
        title: "Audit log",
        path: "audit-log",
      },
    ];
    if (user?.permissions_v2_enabled) {
      if (hasUpdatePermission) {
        return [
          {
            title: "Workspace",
            path: "workspace",
            bg: "base.lightBackground",
          },
          {
            title: "Members",
            path: "members",
          },
          {
            title: "Groups",
            path: "groups",
          },
          ...BASE_TABS,
        ];
      } else {
        if (user.is_impersonating) {
          return [
            {
              title: "Members",
              path: "members",
            },
            { title: "Audit log", path: "audit-log" },
            { title: "Billing", path: "billing" },
          ];
        }
        return [
          {
            title: "Members",
            path: "members",
          },
        ];
      }
    } else {
      if (!hasReadPermission) {
        if (user?.is_impersonating) {
          return [
            {
              title: "Members",
              path: "members",
            },
            { title: "Audit log", path: "audit-log" },
            { title: "Billing", path: "billing" },
          ];
        }
        return [
          {
            title: "Members",
            path: "members",
          },
        ];
      } else {
        return [
          {
            title: "Workspace",
            path: "workspace",
            bg: "base.lightBackground",
          },
          {
            title: "Members",
            path: "members",
          },
          {
            title: "Roles",
            path: "roles",
          },
          ...BASE_TABS,
          {
            title: "SSO",
            path: "sso",
            bg: "base.lightBackground",
            size: "small",
          },
        ] satisfies DetailPageTab[];
      }
    }
  }, [hasReadPermission, hasUpdatePermission, user?.permissions_v2_enabled]);

  return (
    <DetailPage
      tabs={tabs}
      tabDepth={3}
      heading="Workspace settings"
      hasBottomPadding
    >
      <Outlet />
    </DetailPage>
  );
};
