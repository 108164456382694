import { FC, ReactNode } from "react";

import { Column, Combobox, NumberInput, Row, Text } from "@hightouchio/ui";

import { PropertyCondition } from "src/types/visual";

import { ErrorMessage } from "./error-message";

type Props = {
  condition: PropertyCondition;
  isDisabled?: boolean;
  error?: ReactNode;
  suggestionsLoading?: boolean;
  suggestionOptions;
  suggestionsTip: string;
  onChange: (updates: Partial<PropertyCondition>) => void;
};

export const NumberRangeInput: FC<Readonly<Props>> = ({
  condition,
  isDisabled = false,
  error,
  suggestionsLoading,
  suggestionOptions,
  suggestionsTip,
  onChange,
}) => {
  const wrappedOnChange = (
    key: "min" | "max",
    value: string | number | undefined,
  ) => {
    const numericValue = Number(value);

    // Ignore strings that can't be casted to numbers
    if (typeof value === "string" && isNaN(numericValue)) {
      return;
    }

    // Allow undefined so that the input can be reset (e.g., the user deletes the existing value)
    onChange({
      value: {
        ...condition.value,
        [key]: value == undefined ? value : numericValue,
      },
    });
  };

  const updateMinValue = (value: string | number | undefined) =>
    wrappedOnChange("min", value);
  const updateMaxValue = (value: string | number | undefined) =>
    wrappedOnChange("max", value);

  const minValue = condition.value?.min;
  const maxValue = condition.value?.max;

  const comboboxProps = {
    isClearable: true,
    isDisabled,
    isLoading: suggestionsLoading,
    options: suggestionOptions ?? [],
    tip: suggestionsTip,
  };

  return (
    <Column>
      <Row gap={2}>
        {suggestionOptions.length ? (
          <Combobox
            {...comboboxProps}
            placeholder="search min..."
            value={minValue}
            onChange={(option) => updateMinValue(option)}
            onCreateOption={(value) => updateMinValue(value)}
          />
        ) : (
          <NumberInput
            isDisabled={isDisabled}
            isInvalid={Boolean(error)}
            placeholder="min"
            value={minValue}
            width="auto"
            onChange={updateMinValue}
          />
        )}
        <Column justifyContent="center">
          <Text>and</Text>
        </Column>
        {suggestionOptions.length ? (
          <Combobox
            {...comboboxProps}
            placeholder="search max..."
            value={maxValue}
            onChange={(option) => updateMaxValue(option)}
            onCreateOption={(value) => updateMaxValue(value)}
          />
        ) : (
          <NumberInput
            isDisabled={isDisabled}
            isInvalid={Boolean(error)}
            placeholder="max"
            value={maxValue}
            width="auto"
            onChange={updateMaxValue}
          />
        )}
      </Row>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Column>
  );
};
