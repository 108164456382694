import { FC } from "react";

import { Box, Row, SystemStyleObject, Text } from "@hightouchio/ui";

import { useFilterableColumnsQuery } from "src/graphql";
import {
  ConditionType,
  PropertyCondition,
  TraitDefinition,
} from "src/types/visual";

import { AndOrToggleButton } from "./condition-buttons";
import { PropertyFilter, PropertyFilterProps } from "./property-filter";

type NestedPropertyFilterProps = {
  /**Used to fetch the filterable columns for the model. */
  modelId: string | null;
  traits?: TraitDefinition[];
  condition: PropertyCondition;
  isFirstCondition?: boolean;
  isWrappedWithAndCondition?: boolean;
  onChange(updates: Partial<PropertyCondition>): void;
  onRemove(): void;
  onToggleWrappingConditionType(
    conditionType: ConditionType.And | ConditionType.Or,
  ): void;
  containerSx?: SystemStyleObject;
};

export const NestedPropertyFilter: FC<
  Readonly<
    NestedPropertyFilterProps &
      // This component overrides the columns for its children, so disallow `columns` from being passed in.
      Omit<PropertyFilterProps, "columns"> & { columns?: never }
  >
> = ({
  modelId,
  condition,
  isFirstCondition = false,
  isWrappedWithAndCondition = true,
  onChange,
  onRemove,
  onToggleWrappingConditionType,
  containerSx,
  ...props
}) => {
  const filterableColumnsQuery = useFilterableColumnsQuery(
    {
      segmentId: modelId?.toString() ?? "",
    },
    {
      enabled: Boolean(modelId),
      select: (data) => data.segments_by_pk?.filterable_audience_columns ?? [],
    },
  );

  return (
    <Row sx={containerSx}>
      {isFirstCondition && (
        <Box mt={1.5} mr={2}>
          <Text color="text.secondary">Where</Text>
        </Box>
      )}
      {!isFirstCondition && (
        <AndOrToggleButton
          hideIcon
          isDisabled={props.isDisabled}
          conditionType={
            isWrappedWithAndCondition ? ConditionType.And : ConditionType.Or
          }
          ml={-1}
          width="44px"
          onClick={() =>
            onToggleWrappingConditionType(
              isWrappedWithAndCondition ? ConditionType.Or : ConditionType.And,
            )
          }
        />
      )}
      <PropertyFilter
        {...props}
        isNested
        showSecondaryFilter
        isLoading={filterableColumnsQuery.isLoading || props.isLoading}
        columns={filterableColumnsQuery.data ?? []}
        condition={condition}
        onChange={onChange}
        onRemove={onRemove}
      />
    </Row>
  );
};
