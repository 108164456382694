import { FC } from "react";

import {
  Row,
  Badge,
  SparkleIcon,
  Tooltip,
  Box,
  ArrowRightIcon,
  Text,
  AudienceIcon,
  ModelIcon,
  JourneyIcon,
  TextProps,
} from "@hightouchio/ui";

import placeholderDestination from "src/components/permission/destination.svg";
import { Maybe } from "src/graphql";
import { QueryType } from "src/types/models";
import { SourceBadges } from "src/utils/sources";
import { getObjectName } from "src/utils/syncs";
import {
  Mapping,
  isModelMatchboosted,
  isSyncMatchBoosted,
} from "src/pages/syncs/sync/matchbooster";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { LinkWithTooltip } from "src/components/link-with-tooltip";

export type Props = {
  isDisabled?: boolean;
  journeyId?: string;
  model:
    | {
        id: string;
        name: string;
        query_type: string | null;
        matchboosting_enabled: boolean;
        parent:
          | {
              matchboosting_enabled: boolean;
            }
          | null
          | undefined;
        flow_message:
          | {
              decision_engine_message_id: string;
              decision_engine_flow_id: string;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  source:
    | Maybe<{
        definition: {
          name: string;
          icon: string;
          isSampleDataSource: boolean;
        };
      }>
    | undefined;
  destination:
    | Maybe<{
        id: string;
        name: Maybe<string>;
        definition: {
          name: string;
          icon: string;
        };
      }>
    | undefined;
  sync:
    | {
        config: {
          object: string;
          mappings: Mapping[] | undefined;
        };
      }
    | null
    | undefined;
};

export const SyncName: FC<Readonly<Props>> = ({
  model,
  source,
  destination,
  journeyId,
  sync,
  isDisabled = false,
}) => {
  const modelMatchboostingEnabled = model ? isModelMatchboosted(model) : false;
  const syncMatchboostingEnabled = sync
    ? isSyncMatchBoosted(sync.config)
    : false;

  const isAudience = model?.query_type === QueryType.Visual;
  const isJourney = model?.query_type === QueryType.JourneyNode;
  const isDecisioning = Boolean(model?.flow_message);

  let modelLink: string;
  if (isAudience) {
    modelLink = `/audiences/${model?.id}`;
  } else if (isJourney) {
    modelLink = `/journeys/${journeyId}`;
  } else if (isDecisioning) {
    modelLink = `/ai/flows/${model?.flow_message?.decision_engine_flow_id}/configuration/messages/${model?.flow_message?.decision_engine_message_id}`;
  } else {
    modelLink = `/models/${model?.id}`;
  }

  return (
    <Row
      flex={1}
      alignItems="center"
      gap={4}
      overflow="hidden"
      pointerEvents={isDisabled ? "none" : "auto"}
      sx={{
        a: {
          color: "text.primary",
        },
        "a:hover": {
          color: "link.default",
        },
      }}
    >
      <ModelTypeIcon
        queryType={model?.query_type as QueryType}
        isDecisioning={isDecisioning}
      />
      {model ? (
        <Row
          align="center"
          gap={2}
          overflow="hidden"
          flex={1}
          maxW="max-content"
        >
          <IntegrationIcon
            name={source?.definition.name}
            src={source?.definition.icon}
          />
          <LinkWithTooltip
            fontWeight="semibold"
            fontSize="lg"
            color="inherit"
            href={modelLink}
          >
            {model?.name || "Private model"}
          </LinkWithTooltip>
          <SourceBadges
            isSampleDataSource={source?.definition?.isSampleDataSource}
            sx={{ ml: 0 }}
          />
          {modelMatchboostingEnabled &&
            (syncMatchboostingEnabled ? (
              <Tooltip message="This model has Match Booster enabled and the destination is configured to boost matches">
                <Box fontSize="20px">
                  <SparkleIcon color="warning.400" />
                </Box>
              </Tooltip>
            ) : (
              <Tooltip message="This model has Match Booster enabled but the destination is not configured to boost matches">
                <Box fontSize="20px">
                  <SparkleIcon color="text.secondary" />
                </Box>
              </Tooltip>
            ))}
        </Row>
      ) : (
        <Tooltip message="This source is only visible to some users">
          <Row alignItems="center" gap={2}>
            <IntegrationIcon
              name={source?.definition.name}
              src={source?.definition.icon}
            />
            <Text fontWeight="semibold">Private Model</Text>
            <SourceBadges
              isSampleDataSource={source?.definition?.isSampleDataSource}
            />
          </Row>
        </Tooltip>
      )}

      <Box
        as={ArrowRightIcon}
        color="text.secondary"
        boxSize={6}
        flexShrink={0}
      />

      {destination ? (
        <Row
          align="center"
          gap={2}
          overflow="hidden"
          flex={1}
          maxW="max-content"
        >
          <IntegrationIcon
            size={6}
            name={destination?.definition?.name}
            src={destination?.definition?.icon}
          />
          <LinkWithTooltip
            fontWeight="semibold"
            fontSize="lg"
            color="inherit"
            href={`/destinations/${destination?.id}`}
          >
            {destination?.name || destination?.definition?.name}
          </LinkWithTooltip>
          {sync?.config?.object && (
            <Box as={Badge} size="sm" overflowX="hidden">
              <TextWithTooltip size="sm" color="inherit" textTransform="none">
                {getObjectName(sync.config.object)}
              </TextWithTooltip>
            </Box>
          )}
          {syncMatchboostingEnabled && (
            <Tooltip message="This destination has Match Booster enabled">
              <Badge size="sm" svgIcon={SparkleIcon} iconColor="warning.400">
                <Box fontWeight="medium">Boosted</Box>
              </Badge>
            </Tooltip>
          )}
        </Row>
      ) : (
        <Tooltip message="This destination is only visible to some users">
          <Row alignItems="center" gap={2}>
            <IntegrationIcon
              size={6}
              name="Private destination"
              src={placeholderDestination}
            />
            <Text fontWeight="semibold">Private Destination</Text>
          </Row>
        </Tooltip>
      )}
    </Row>
  );
};

const ModelTypeIcon = ({
  queryType,
  isDecisioning,
}: {
  queryType: QueryType;
  isDecisioning: boolean;
}) => {
  const getIcon = () => {
    if (isDecisioning) {
      return SparkleIcon;
    }
    switch (queryType) {
      case QueryType.Visual:
        return AudienceIcon;
      case QueryType.JourneyNode:
        return JourneyIcon;
      default:
        return ModelIcon;
    }
  };

  const getMessage = () => {
    if (isDecisioning) {
      return "Decisioning";
    }
    switch (queryType) {
      case QueryType.Visual:
        return "Audience";
      case QueryType.JourneyNode:
        return "Journey";
      default:
        return "Model";
    }
  };

  return (
    <Tooltip message={`${getMessage()} sync`}>
      <Row
        align="center"
        justify="center"
        bg="gray.100"
        w={8}
        h={8}
        borderRadius="sm"
        flexShrink={0}
      >
        <Box as={getIcon()} boxSize={5} color="text.secondary" />
      </Row>
    </Tooltip>
  );
};

export type MinimalSyncNameProps = {
  fontSize?: TextProps["size"];
  sync:
    | {
        segment: {
          name: string | null;
          connection?: {
            definition?: {
              name: string;
              icon: string;
              isSampleDataSource?: boolean;
            };
          } | null;
        } | null;
        destination: {
          name: string | null;
          definition?: {
            name: string;
            icon: string;
          };
        } | null;
        config: {
          object: string;
          mappings: Mapping[] | undefined;
        };
      }
    | null
    | undefined;
};

export const MinimalSyncName = ({
  fontSize = "md",
  sync,
}: MinimalSyncNameProps) => {
  const destination = sync?.destination;
  const model = sync?.segment;
  const source = sync?.segment?.connection;

  return (
    <Row flex={1} alignItems="center" gap={4} overflow="hidden">
      {model ? (
        <Row
          align="center"
          gap={2}
          overflow="hidden"
          flex={1}
          maxW="max-content"
        >
          <IntegrationIcon
            name={source?.definition?.name}
            src={source?.definition?.icon}
          />
          <TextWithTooltip fontWeight="medium" size={fontSize}>
            {model?.name || "Private model"}
          </TextWithTooltip>
          <SourceBadges
            isSampleDataSource={source?.definition?.isSampleDataSource}
            sx={{ ml: 0 }}
          />
        </Row>
      ) : (
        <Tooltip message="This source is only visible to some users">
          <Row alignItems="center" gap={2}>
            <IntegrationIcon
              name={source?.definition?.name}
              src={source?.definition?.icon}
            />
            <Text fontWeight="medium">Private Model</Text>
            <SourceBadges
              isSampleDataSource={source?.definition?.isSampleDataSource}
            />
          </Row>
        </Tooltip>
      )}

      <Box
        as={ArrowRightIcon}
        color="text.secondary"
        boxSize={6}
        flexShrink={0}
      />

      {sync?.destination ? (
        <Row
          align="center"
          gap={2}
          overflow="hidden"
          flex={1}
          maxW="max-content"
        >
          <IntegrationIcon
            size={6}
            name={destination?.definition?.name}
            src={destination?.definition?.icon}
          />
          <TextWithTooltip fontWeight="medium" size={fontSize}>
            {destination?.name || destination?.definition?.name}
          </TextWithTooltip>
          {sync?.config?.object && (
            <Box as={Badge} size="sm" overflowX="hidden">
              <TextWithTooltip size="sm" color="inherit" textTransform="none">
                {getObjectName(sync.config.object)}
              </TextWithTooltip>
            </Box>
          )}
        </Row>
      ) : (
        <Tooltip message="This destination is only visible to some users">
          <Row alignItems="center" gap={2}>
            <IntegrationIcon
              size={6}
              name="Private destination"
              src={placeholderDestination}
            />
            <Text fontWeight="medium">Private Destination</Text>
          </Row>
        </Tooltip>
      )}
    </Row>
  );
};
