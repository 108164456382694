import { getSyncRunError } from "src/utils/syncs";
import type { ElementOf } from "ts-essentials";
import * as time from "src/utils/time";

import {
  MergedSyncRequestEventSpan,
  MergedSyncRequestEventStatus,
} from "src/graphql";

import {
  BaseSyncRunPhase,
  EMPTY_SYNC_PHASE,
  GroupedPhaseData,
  phaseFailedWithErrors,
  SyncRequest,
  SyncRunPhase,
} from "./types";

import { PHASE_DISPLAY_CONFIG, PhaseGroup } from "./phase-display-config";

export const groupPhases = (
  allPhases: BaseSyncRunPhase[],
): GroupedPhaseData => {
  const syncPhase =
    allPhases.find((phase) => phase.span === MergedSyncRequestEventSpan.Sync) ??
    EMPTY_SYNC_PHASE;

  // Find any errors in spans that we won't be displayed directly in the UI
  const parentPhaseErrors = allPhases
    .filter((phase) => PHASE_DISPLAY_CONFIG[phase.span] === null)
    .filter(phaseFailedWithErrors)
    .flatMap((phase) => phase.errors);

  const groups = Object.values(
    allPhases
      .filter((phase) => PHASE_DISPLAY_CONFIG[phase.span] !== null)
      .reduce<{
        [key: string]: { group: PhaseGroup; phases: SyncRunPhase[] };
      }>((acc, basePhase, index, renderablePhases) => {
        const config = PHASE_DISPLAY_CONFIG[basePhase.span];
        if (!config) return acc;

        const previousPhases = renderablePhases.slice(0, index);
        const nextPhases = renderablePhases.slice(index + 1);

        const isSkipped = isPhaseSkipped(basePhase, previousPhases, nextPhases);
        const isInterrupted = isPhaseInterrupted(basePhase, previousPhases);
        const childPhases = config.collectChildPhases?.(allPhases) ?? [];

        const phase: SyncRunPhase = {
          ...basePhase,
          isSkipped,
          isInterrupted,
          childPhases,
        };

        const group = config.group;
        const phases = acc[group]?.phases ?? [];

        return {
          ...acc,
          [group]: {
            group,
            phases: [...phases, phase],
          },
        };
      }, {}),
  );

  return { syncPhase, parentPhaseErrors, groups };
};

// If the phase hasn't started but the previous phase has successfully completed,
// and future phases have progress, then mark the phase as skipped.
// This is relatively simplistic, eg. in the case of errors where many phases are not started, we don't mark any phases as skipped.
const isPhaseSkipped = (
  phase: BaseSyncRunPhase,
  previousPhases: BaseSyncRunPhase[],
  nextPhases: BaseSyncRunPhase[],
) => {
  const previousPhase = previousPhases[previousPhases.length - 1];

  return (
    phase.status === MergedSyncRequestEventStatus.NotStarted &&
    previousPhase?.status === MergedSyncRequestEventStatus.Completed &&
    nextPhases.some((p) => p.status !== MergedSyncRequestEventStatus.NotStarted)
  );
};

// If phase is completed but any prior phases are still active, mark as interrupted
const isPhaseInterrupted = (
  phase: BaseSyncRunPhase,
  previousPhases: BaseSyncRunPhase[],
) => {
  return (
    phase.status === MergedSyncRequestEventStatus.Completed &&
    previousPhases.some((p) => p.status === MergedSyncRequestEventStatus.Active)
  );
};

export const formatTimestamp = (timestamp: number) =>
  time.formatDatetime(new Date(timestamp).toISOString(), "MMM d, yyyy 'at' p");

export const syncRequestErrorToPhaseError = (
  syncRequest: Pick<
    SyncRequest,
    "error" | "error_code_detail" | "sync_attempts"
  >,
): ElementOf<SyncRunPhase["errors"]> | null => {
  const syncRequestErrorInfo = getSyncRunError({
    syncRequest,
    attempt: syncRequest.sync_attempts[0],
  });

  if (!syncRequestErrorInfo) return null;

  return {
    timestamp: null,
    message:
      syncRequestErrorInfo.userFacingMessage ||
      syncRequestErrorInfo.message ||
      "We apologize for the inconvenience, but an unknown error has occurred. Please contact our customer support team for assistance.",
    errorInfo: syncRequestErrorInfo ?? null,
    errorCodeDetail: syncRequest.error_code_detail ?? null,
  };
};
