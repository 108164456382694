import { useToast } from "@hightouchio/ui";
import { ReactElement, useState } from "react";

export const OptimisticUpdate = <T,>({
  initialValue,
  render,
  onUpdate,
  successMessage = "Changes saved",
  errorMessage = "There was a problem saving your changes",
}: {
  render: (params: {
    field: {
      value: T;
      onChange: (value: T) => void;
    };
  }) => ReactElement;
  initialValue: T;
  onUpdate: (value: T) => Promise<void>;
  successMessage?: string;
  errorMessage?: string;
}) => {
  const [value, setValue] = useState<T>(initialValue);
  const { toast } = useToast();

  const optimisticallyUpdateValue = async (newValue: T) => {
    const oldValue = value;
    setValue(newValue);

    try {
      await onUpdate(newValue);
      toast({
        id: "optimistic-update",
        title: successMessage,
        variant: "success",
      });
    } catch (_err) {
      setValue(oldValue);
      toast({
        id: "optimistic-update",
        title: errorMessage,
        variant: "error",
      });
    }
  };

  return render({
    field: { value, onChange: optimisticallyUpdateValue },
  });
};
