import { FC, useMemo, useState } from "react";

import { useOutletContext, useSearchParams } from "src/router";

import { FormErrorProvider } from "src/contexts/form-error-context";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import { NodeOutletContext } from "src/pages/journeys/types";
import { JourneyNodeType } from "src/types/journeys";

import {
  Alert,
  Button,
  ChakraListItem,
  ChakraUnorderedList,
  Dialog,
} from "@hightouchio/ui";
import { HoldUntilForm } from "./hold-until-form";
import { NotFoundForm } from "./not-found-form";
import { PriorityListForm } from "./priority-list-form";
import { SegmentForm } from "./segment-form";
import { StartForm } from "./start-form";
import { SyncConfigForm } from "./sync-config-form";
import { SyncForm } from "./sync-form";
import { TimeDelayForm } from "./time-delay-form";
import { MiniNodeDetailsForm } from "./mini-node-details-form";
import { SplitsForm } from "./splits-form";

export const NodeDetailForm: FC = (props) => {
  const [searchParams] = useSearchParams();
  const { nodes, nodeRunErrors: allNodeRunErrors } = useGraphContext();
  const { nodeId, onClose } = useOutletContext<NodeOutletContext>();

  const [showNodeRunErrors, setShowNodeRunErrors] = useState(false);

  const syncNodeId = searchParams.get("sync");

  const selectedNode = useMemo(
    () => nodes.find(({ id }) => id === nodeId),
    [nodeId, nodes],
  );

  const nodeRunErrors = allNodeRunErrors
    .filter(({ node_id }) => node_id === nodeId)
    .map(({ error }) => error);

  if (!selectedNode) {
    return <NotFoundForm onClose={onClose} />;
  }

  let Form;

  switch (selectedNode?.type) {
    case JourneyNodeType.EntryCohort:
    case JourneyNodeType.EntryEvent:
      Form = StartForm;
      break;
    // Both WaitUntilEvent and WaitUntilEventBranch show the same form
    // TODO(samuel): clicking mini node should open parent node for wait until branch
    case JourneyNodeType.WaitUntilEvent:
      Form = HoldUntilForm;
      break;
    case JourneyNodeType.WaitUntilEventBranch:
      Form = MiniNodeDetailsForm;
      break;
    case JourneyNodeType.TimeDelay:
      Form = TimeDelayForm;
      break;
    case JourneyNodeType.Segments:
      Form = PriorityListForm;
      break;
    case JourneyNodeType.SegmentBranch:
      Form = SegmentForm;
      break;
    case JourneyNodeType.Splits:
      Form = SplitsForm;
      break;
    case JourneyNodeType.SplitBranch:
      Form = MiniNodeDetailsForm;
      break;
    case JourneyNodeType.Sync:
      // The sync config lives _on_ the sync node, rather than having its own node,
      // so in this case we'll use the same node data to render two forms.
      // Use search params to determine which form to render.
      Form = syncNodeId ? SyncConfigForm : SyncForm;
      break;
    default:
      Form = null;
      break;
  }

  return (
    <>
      {nodeRunErrors.length > 0 && (
        <Alert
          variant="banner"
          type="error"
          justify="center"
          title="An error occurred during journey execution"
          message={null}
          actions={
            <Button onClick={() => setShowNodeRunErrors(true)}>
              View details
            </Button>
          }
        />
      )}

      <FormErrorProvider>
        <Form
          id={nodeId}
          data={selectedNode.data}
          onClose={onClose}
          {...props}
        />
      </FormErrorProvider>

      <Dialog
        isOpen={showNodeRunErrors}
        title="Journey tile error details"
        variant="info"
        actions={
          <Button variant="primary" onClick={() => setShowNodeRunErrors(false)}>
            Close
          </Button>
        }
        onClose={() => setShowNodeRunErrors(false)}
      >
        <ChakraUnorderedList pl={0}>
          {nodeRunErrors.map((nodeRunError, index) => (
            <ChakraListItem key={`${nodeRunError}-${index}`}>
              {nodeRunError}
            </ChakraListItem>
          ))}
        </ChakraUnorderedList>
      </Dialog>
    </>
  );
};
