import { FC } from "react";

import { Navigate, useOutletContext } from "src/router";
import { ForwardingSyncConfiguration } from "src/events/syncs/forwarding-sync/configuration";
import { OutletContext } from ".";

export const Configuration: FC = () => {
  const context = useOutletContext<OutletContext>();

  if (context.type === "warehouse") {
    return <Navigate to={`/events/syncs/${context.sync.id}`} replace />;
  }

  return <ForwardingSyncConfiguration />;
};
