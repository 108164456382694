import {
  FormulaTraitConfig,
  TraitCondition,
  TraitConfig,
  TraitType,
  isVariableResultingTypeTraitConfig,
} from "@hightouch/lib/query/visual/types/trait-definitions";
import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";

import { TraitQuery } from "src/graphql";
import { QueryType } from "src/types/models";
import { useModelRun } from "src/utils/models";
import {
  RelatedColumn,
  TransformedColumn,
} from "@hightouch/lib/query/visual/types";
import { RAW_SQL_JSON_ARR_RES_TYPES } from "./utils";

export const usePreviewTrait = ({
  isFastPreviewEnabled,
  parentModel,
  trait,
}: {
  isFastPreviewEnabled: boolean;
  parentModel: NonNullable<TraitQuery["trait_definitions_by_pk"]>["config"];
  trait: {
    type: TraitType;
    config: TraitConfig;
    relationshipId?: string | null;
    conditions?: TraitCondition[];
  };
}) => {
  const { toast } = useToast();

  let sortable = true;
  // For RawSql and Formula traits, we can't sort by JSON array result types since they're not
  // comparable values. Need config to be typed as RawSqlTraitConfig | FormulaTraitConfig
  const config = trait.config;
  if (isVariableResultingTypeTraitConfig(trait.type, config)) {
    sortable = !RAW_SQL_JSON_ARR_RES_TYPES.some(
      (type) => type.value === config.resultingType,
    );
  }

  const traitColumn: RelatedColumn | TransformedColumn =
    trait.type !== TraitType.Formula && trait.relationshipId
      ? {
          type: "related",
          column: {
            type: "inline_trait",
            traitType: trait.type,
            traitConfig: trait.config,
            conditions: trait.conditions ?? [],
            relationshipId: trait.relationshipId,
          },
          path: [trait.relationshipId],
        }
      : {
          type: "transformed",
          column: {
            type: "inline_trait",
            // Formula traits do not have relationship ids
            traitType: trait.type as TraitType.Formula,
            traitConfig: trait.config as FormulaTraitConfig,
          },
        };

  const visualQueryFilter = {
    conditions: [],
    additionalColumns: [
      {
        alias: "trait_preview",
        column: traitColumn,
      },
    ],
  };

  if (sortable) {
    visualQueryFilter["sizeCap"] = {
      limit: 100,
      orderByProperty: traitColumn,
      bottom: false,
    };
  }

  const { runQuery, columns, rows, loading, error } = useModelRun(
    {
      query_type: QueryType.Visual,
      visual_query_parent_id: parentModel?.id,
      connection: parentModel?.connection,
      visual_query_filter: visualQueryFilter,
    },
    {
      columns: parentModel?.columns,
      includeTraitDependencies: true,
      useDefaultSubsets: true,
      useSampledModels: isFastPreviewEnabled,
      onCompleted: (_data, error) => {
        if (error) {
          toast({
            id: "trait-preview",
            title: "Trait preview failed",
            variant: "error",
          });

          Sentry.captureException(error);
          return;
        }
      },
    },
  );

  const previewTrait = async () => {
    await runQuery({ limit: true, disableRowCounter: true });
  };

  return {
    previewTrait,
    isLoading: loading,
    data: { rows, columns },
    error,
  };
};
