import { FC } from "react";

import { FormField } from "@hightouchio/ui";

import { SelectCredential } from "src/components/credentials";

type ProviderSectionProps = {
  provider: "gcp" | "aws" | "azure";
  credentialId?: any;
  setCredentialId?: any;
};

const labelMap = {
  gcp: "GCP",
  aws: "AWS",
  azure: "Azure",
};

export const ProviderSection: FC<Readonly<ProviderSectionProps>> = ({
  provider,
  credentialId,
  setCredentialId,
}) => {
  return (
    <FormField label={`${labelMap[provider]} credentials`}>
      <SelectCredential
        provider={provider}
        value={credentialId}
        onChange={setCredentialId}
      />
    </FormField>
  );
};
