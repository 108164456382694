import {
  ButtonGroup,
  Column,
  EmptyState,
  Heading,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  Row,
  useToast,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { captureException } from "@sentry/react";
import { Outlet, useOutletContext } from "src/router";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { Page } from "src/components/layout";

import {
  DecisionEngineFlowsQuery,
  useDecisionEngineFlowsQuery,
  useRunDecisionEngineMutation,
} from "src/graphql";
import { OutletContext } from "..";
import { FlowCard } from "./components/flow";
import { sortBy } from "lodash";

export type Flow = DecisionEngineFlowsQuery["decision_engine_flows"][0];

export const DecisionEngineFlows = () => {
  const context = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const runEngine = useRunDecisionEngineMutation();

  const { data: flows } = useDecisionEngineFlowsQuery(
    {
      id: context.engine?.id,
    },
    {
      enabled: Boolean(context.engine),
      // order data by status (ready -> training -> pending), then name
      select: (data) =>
        sortBy(data.decision_engine_flows, [
          (o) => {
            switch (o.status) {
              case "ready":
                return 0;
              case "training":
                return 1;
              case "pending":
                return 2;
              default:
                return -1;
            }
          },
          "name",
        ]),
      suspense: true,
    },
  );

  if (!context.engine) {
    return null;
  }

  return (
    <Page
      title="Agents"
      fullWidth
      sx={{ bg: "base.lightBackground", p: 0 }}
      header={
        <Row
          justify="space-between"
          py={4}
          bg="white"
          px={6}
          borderBottom="1px"
          borderColor="base.border"
        >
          <Row gap={4} align="center">
            <Heading size="xl">Agents</Heading>
          </Row>
          {flows!.length > 0 && (
            <ButtonGroup size="lg">
              <Menu>
                <MenuActionsButton />
                <MenuList>
                  <MenuItem
                    onClick={async () => {
                      try {
                        await runEngine.mutateAsync({ id: context.engine.id });
                        toast({
                          id: "run-flows",
                          title: "Running agents",
                          variant: "success",
                        });
                      } catch (e) {
                        captureException(e);
                        toast({
                          id: "run-flows",
                          title: "Failed to run agents",
                          variant: "error",
                        });
                      }
                    }}
                  >
                    Manually run agents
                  </MenuItem>
                </MenuList>
              </Menu>

              <LinkButton variant="primary" href="new">
                Add agent
              </LinkButton>
            </ButtonGroup>
          )}
        </Row>
      }
    >
      {flows?.length ? (
        <Column gap={8} px={6} pt={6} pb={24}>
          {flows.map((flow) => (
            <FlowCard flow={flow} key={flow.id} />
          ))}
        </Column>
      ) : (
        <EmptyState
          m={6}
          imageUrl={genericPlaceholder}
          title="No agents"
          message="Add an agent to start using AI decisioning"
          actions={
            <LinkButton variant="primary" href="new">
              Add agent
            </LinkButton>
          }
        />
      )}
      <Outlet context={context} />
    </Page>
  );
};
