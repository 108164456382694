import { FC, useEffect, useState } from "react";

import {
  Column,
  DeleteIcon,
  FormField,
  Heading,
  Menu,
  MenuActionsButton,
  MenuList,
  RefreshIcon,
  Row,
  Text,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Outlet } from "src/router";
import dbtCloudImage from "src/components/extensions/assets/dbt-cloud.png";
import { Overview } from "src/components/extensions/overview";
import { DetailPage } from "src/components/layout";
import { PermissionedMenuItem } from "src/components/permission";
import { ScheduleType } from "src/components/schedule/types";
import {
  DbtCredentialsQuery,
  useCreateDbtCredentialMutation,
  useDbtCredentialsQuery,
  useDeleteDbtCloudCredentialMutation,
  useTestDbtCloudCredentialQuery,
} from "src/graphql";
import { DBTIcon } from "src/ui/icons";
import { ConnectedExtension, DependentSyncsModal } from "./common";
import { Card } from "src/components/card";
import { DocsLink } from "src/components/docs-link";
import { Form, useHightouchForm } from "src/components/form";
import { FormBar } from "src/components/form/form-bar";
import { Controller } from "react-hook-form";
import { SecretInput } from "src/components/secret-input";

export const DbtCloudOverview: FC = () => {
  return (
    <Overview
      description="When your Hightouch sync depends on a table or materialized view generated by dbt, you can schedule it to run after completion of the associated dbt job."
      icon={DBTIcon}
      image={dbtCloudImage}
      subtitle="Trigger syncs upon completion of dbt jobs"
      title="dbt Cloud"
    />
  );
};

export const DbtCloud: FC = () => {
  return (
    <DetailPage
      bg="base.lightBackground"
      crumbs={[{ label: "Extensions", link: "/extensions" }]}
      title="dbt cloud - Extensions"
      header={<Heading size="xl">dbt Cloud</Heading>}
      tabs={[
        {
          path: "",
          title: "Overview",
        },
        {
          path: "configuration",
          title: "Configuration",
          size: "small",
          contentFullHeight: true,
        },
      ]}
    >
      <Outlet />
    </DetailPage>
  );
};

const ConnectedDbtCloudContent: FC<{
  credentials: DbtCredentialsQuery["dbt_credentials"][0];
}> = ({ credentials }) => {
  return (
    <Column gap={3}>
      {!credentials?.account_url ? (
        <Text fontWeight="medium">
          <Text fontWeight="semibold">Subdomain: </Text>
          {credentials?.subdomain || "Not configured"}
        </Text>
      ) : (
        <Text fontWeight="medium">
          <Text fontWeight="semibold">Account URL: </Text>
          {credentials?.account_url}
        </Text>
      )}
    </Column>
  );
};

type DbtCloudTestStatus = "failed" | "loading" | "success" | "error";

const schema = yup.object({
  accountUrl: yup.string().required("Account URL is required").trim(),
  apiKey: yup.string().required("API token is required").trim(),
});

export const DbtCloudConfiguration: FC = () => {
  const { toast } = useToast();
  const [isDeleting, setIsDeleting] = useState(false);
  const [testStatus, setTestStatus] = useState<DbtCloudTestStatus>("loading");

  const { data: credentials } = useDbtCredentialsQuery(undefined, {
    select: (data) => data.dbt_credentials?.[0],
    suspense: true,
  });

  const {
    data: testResult,
    isLoading: isTesting,
    refetch: testExtension,
    isRefetching: isReTesting,
  } = useTestDbtCloudCredentialQuery(
    {},
    { select: (data) => data.testDbtCloudCredential, enabled: !!credentials },
  );

  const { mutateAsync: deleteExtension, isLoading: extensionDeleting } =
    useDeleteDbtCloudCredentialMutation();
  const { mutateAsync: create } = useCreateDbtCredentialMutation();

  useEffect(() => {
    if (credentials) {
      if (isTesting || isReTesting) {
        setTestStatus("loading");
        return;
      }
      if (!testResult) {
        setTestStatus("failed");
        toast({
          id: "dbt-cloud-test",
          variant: "error",
          title: "dbt Cloud credentials test failed.",
          message:
            "Please check your API credentials on your dbt Cloud account.",
        });
      } else {
        setTestStatus("success");
      }
    }
  }, [credentials, isTesting, isReTesting]);

  const form = useHightouchForm({
    values: {
      accountUrl: credentials?.account_url ?? "",
      apiKey: "",
    },
    resolver: yupResolver(schema),
    onSubmit: async (data) => {
      await create({ apiKey: data.apiKey, accountUrl: data.accountUrl });
    },
  });

  const Header = () => {
    return (
      <Row alignItems="center" justifyContent="space-between" gap={4}>
        <Heading>dbt Cloud configuration</Heading>
        <Row gap={4} align="center">
          <DocsLink name="dbt Cloud" href="extensions/dbt-cloud" />
          {credentials?.id && (
            <Menu closeOnSelect={false}>
              <MenuActionsButton variant="secondary" />
              <MenuList>
                <PermissionedMenuItem
                  permission={{
                    v2: { resource: "workspace", grant: "can_update" },
                  }}
                  icon={RefreshIcon}
                  isLoading={isTesting || isReTesting}
                  isDisabled={isDeleting}
                  onClick={async () => {
                    setTestStatus("loading");
                    await testExtension({});
                  }}
                >
                  {isTesting || isReTesting ? "Testing..." : "Test connection"}
                </PermissionedMenuItem>
                <PermissionedMenuItem
                  permission={{
                    v2: { resource: "workspace", grant: "can_update" },
                  }}
                  icon={DeleteIcon}
                  isLoading={isDeleting}
                  isDisabled={isTesting}
                  variant="danger"
                  onClick={() => {
                    setIsDeleting(true);
                  }}
                >
                  Disconnect
                </PermissionedMenuItem>
              </MenuList>
            </Menu>
          )}
        </Row>
      </Row>
    );
  };

  return (
    <Form form={form}>
      <Column flex={1} gap={6}>
        <Header />
        {credentials?.id ? (
          <ConnectedExtension credentials={credentials} testStatus={testStatus}>
            <ConnectedDbtCloudContent credentials={credentials} />
          </ConnectedExtension>
        ) : (
          <Card gap={6}>
            <Controller
              control={form.control}
              name="accountUrl"
              render={({ field, fieldState: { error } }) => (
                <FormField
                  description="Provide your dbt cloud account URL e.g. ab123.us1.dbt.com"
                  label="dbt cloud URL"
                  error={error?.message}
                >
                  <TextInput
                    placeholder="Enter your dbt cloud URL..."
                    isDisabled={Boolean(credentials?.id)}
                    isInvalid={Boolean(error?.message)}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                </FormField>
              )}
            />
            <Controller
              control={form.control}
              name="apiKey"
              render={({ field, fieldState: { error } }) => (
                <FormField error={error?.message} label="dbt Cloud API token">
                  <SecretInput
                    {...field}
                    placeholder="Enter your dbt Cloud API token..."
                    isDisabled={Boolean(credentials?.id)}
                    isInvalid={Boolean(error?.message)}
                  />
                </FormField>
              )}
            />
          </Card>
        )}
      </Column>
      <FormBar
        permission={{
          v2: { resource: "workspace", grant: "can_update" },
        }}
      />
      <DependentSyncsModal
        deleteExtension={() => deleteExtension({})}
        extensionName="dbt Cloud"
        isDeleting={extensionDeleting}
        open={isDeleting}
        scheduleType={ScheduleType.DBT_CLOUD}
        onClose={() => setIsDeleting(false)}
      />
    </Form>
  );
};
