import { AudienceIcon, SplitIcon } from "@hightouchio/ui";
import { TileNode } from "./tile-node";
import { TileProps } from "./types";
import { JourneyNodeType } from "src/types/journeys";

export const FlowNode = (props: TileProps) => (
  <TileNode
    {...props}
    isTarget
    isSource
    colorScheme="electric"
    icon={
      props.data.config.type == JourneyNodeType.Segments ? (
        <AudienceIcon />
      ) : (
        <SplitIcon />
      )
    }
  />
);
