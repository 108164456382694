import { FC, ReactNode, Suspense, useRef } from "react";

import { Box, BoxProps, Column, Heading, Row, Spinner } from "@hightouchio/ui";
// import { Box, Column, Row, Spinner } from "@hightouchio/ui";
import { Helmet } from "react-helmet";
import { useScroll } from "react-use";

import { RouteTab, RouteTabs, useTabState } from "src/components/route-tabs";
import { SIZES } from "src/ui/box";
import { Props as HeaderProps, PageHeader } from "./header/page-header";
import { DefaultPageContainerPadding } from "./page-container";

// TODO(rabaut): Add more variants like "table", "dashboard", etc...
type DetailPageVariant = "form";

export type DetailPageTab = RouteTab & {
  size?: keyof typeof SIZES;
  bg?: string;
  contentFullHeight?: boolean;
  hasBottomPadding?: boolean;
  variant?: DetailPageVariant;
};

export type Props = {
  bg?: string;
  children: ReactNode;
  contentFullWidth?: boolean;
  header?: ReactNode;
  hasBottomPadding?: boolean;
  sidebar?: ReactNode;
  size?: keyof typeof SIZES;
  /**
   * Node to render between the header and the main content
   */
  intermediateContent?: ReactNode;
  title?: string;
  tabs?: Array<DetailPageTab>;
  tabDepth?: number;
  heading?: string;
  actions?: ReactNode;
} & HeaderProps;

const headerBarDefaultHeight = 249;

const variantStyles: Record<
  DetailPageVariant,
  { container: BoxProps; content: BoxProps }
> = {
  form: {
    container: {
      bg: "base.lightBackground",
      paddingBottom: DefaultPageContainerPadding.Bottom,
    },
    content: {
      flex: 1,
      maxWidth: SIZES["small"],
    },
  },
};

// distance from top of window
export const cssVariableTopOffset = "--top-section-offset";
// total height of header content
export const cssVariableTopSectionHeight = "--top-section-height";
// visible height of header content
export const cssVariableVisibleTopSectionHeight =
  "--visible-top-section-height";
// height of footer content
export const cssVariableBottomSectionHeight = "--bottom-section-height";

export const InnerScrollableDetailPage: FC<
  Readonly<
    Props & {
      contentFullHeight?: boolean;
    }
  >
> = ({
  bg = "white",
  children,
  contentFullWidth,
  contentFullHeight,
  header,
  heading,
  actions,
  hasBottomPadding,
  crumbs,
  outsideTopbar,
  size = "page",
  intermediateContent = null,
  sync,
  tabs,
  title,
  tabDepth = 4,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const headerBarHeight =
    headerRef.current?.offsetHeight ?? headerBarDefaultHeight;

  const scrollPosition = useScroll(containerRef);
  const visibleTopSectionHeight = Math.max(
    0,
    headerBarHeight - scrollPosition.y,
  );

  const { activeTab } = useTabState(tabs ?? [], tabDepth);

  return (
    <Column
      ref={containerRef}
      height="100vh"
      position="relative"
      overflowY="auto"
      bg={activeTab?.bg ?? bg}
      pb={
        (activeTab?.hasBottomPadding ?? hasBottomPadding)
          ? DefaultPageContainerPadding.Bottom
          : 0
      }
      sx={{
        [cssVariableTopOffset]: "0px",
        [cssVariableTopSectionHeight]: `${headerBarHeight}px`,
        [cssVariableVisibleTopSectionHeight]: `${visibleTopSectionHeight}px`,
        [cssVariableBottomSectionHeight]: "0px",
      }}
    >
      <Helmet>
        <title>{title ?? heading}</title>
      </Helmet>

      <Column bg="white" ref={headerRef}>
        <PageHeader
          crumbs={crumbs}
          outsideTopbar={outsideTopbar}
          stickToTop={false}
          sync={sync}
        />

        <Column
          bg="white"
          px={DefaultPageContainerPadding.X}
          width="100%"
          mb={6}
          pt={crumbs ? 0 : 6}
        >
          {header}
          {heading || actions ? (
            <Row justify="space-between" align="center" gap={4}>
              {heading && <Heading size="xl">{heading}</Heading>}
              {actions && (
                <Row align="center" ml="auto" gap={4}>
                  {actions}
                </Row>
              )}
            </Row>
          ) : null}
        </Column>

        {tabs ? (
          <>
            <RouteTabs
              tabs={tabs}
              depth={tabDepth}
              px={DefaultPageContainerPadding.X}
            />
          </>
        ) : (
          <BorderLine />
        )}
      </Column>

      {intermediateContent}

      <Column
        id="detail-page-content"
        justifyContent="flex-start"
        maxWidth={contentFullWidth ? undefined : SIZES[activeTab?.size ?? size]}
        mt={contentFullWidth ? undefined : 6}
        mx={contentFullWidth ? undefined : "auto"}
        position="relative"
        px={contentFullWidth ? undefined : DefaultPageContainerPadding.X}
        width="100%"
        minHeight={
          (activeTab?.contentFullHeight ?? contentFullHeight) ? 0 : undefined
        }
        // NOTE: adding height="100%" breaks scrolling of long content
        // such as for the audience builder.
        height={
          (activeTab?.contentFullHeight ?? contentFullHeight)
            ? "100%"
            : undefined
        }
      >
        <Suspense fallback={<Spinner size="lg" m="auto" />}>
          {children}
        </Suspense>
      </Column>
    </Column>
  );
};

const BorderLine = () => (
  <Box
    borderBottom="1px solid"
    borderColor="base.border"
    flex={1}
    minWidth={DefaultPageContainerPadding.X}
  />
);

export const DetailPage: FC<Readonly<Props>> = ({
  bg = "white",
  children,
  contentFullWidth,
  header,
  heading,
  actions,
  hasBottomPadding,
  crumbs,
  outsideTopbar,
  size = "page",
  intermediateContent = null,
  sync,
  tabs,
  title,
  tabDepth = 4,
}) => {
  const { activeTab } = useTabState(tabs ?? [], tabDepth);

  return (
    <Column
      position="relative"
      bg={activeTab?.bg ?? bg}
      pb={hasBottomPadding ? DefaultPageContainerPadding.Bottom : 0}
      height="100%"
      {...(activeTab?.variant
        ? variantStyles[activeTab.variant].container
        : {})}
    >
      <Helmet>
        <title>{title ?? heading}</title>
      </Helmet>

      <Column bg="white">
        <PageHeader
          crumbs={crumbs}
          outsideTopbar={outsideTopbar}
          stickToTop={false}
          sync={sync}
        />

        <Column
          bg="white"
          px={DefaultPageContainerPadding.X}
          width="100%"
          mb={6}
          pt={crumbs ? 0 : 6}
        >
          {header}
          {heading || actions ? (
            <Row justify="space-between" align="center" gap={4}>
              {heading && <Heading size="xl">{heading}</Heading>}
              {actions && (
                <Row align="center" ml="auto" gap={4}>
                  {actions}
                </Row>
              )}
            </Row>
          ) : null}
        </Column>

        {tabs ? (
          <>
            <RouteTabs
              tabs={tabs}
              depth={tabDepth}
              px={DefaultPageContainerPadding.X}
            />
          </>
        ) : (
          <BorderLine />
        )}
      </Column>

      {intermediateContent}

      <Column
        justifyContent="flex-start"
        maxWidth={contentFullWidth ? undefined : SIZES[activeTab?.size ?? size]}
        mt={contentFullWidth ? undefined : 6}
        mx={contentFullWidth ? undefined : "auto"}
        position="relative"
        height={activeTab?.contentFullHeight ? "100%" : undefined}
        px={contentFullWidth ? undefined : DefaultPageContainerPadding.X}
        width="100%"
        {...(activeTab?.variant
          ? variantStyles[activeTab.variant].content
          : {})}
      >
        <Suspense fallback={<Spinner size="lg" m="auto" />}>
          {children}
        </Suspense>
      </Column>
    </Column>
  );
};
