import {
  ConditionFriendlyNames,
  MonitorConditionTypes,
  MonitoredResourceType,
  MonitorStatus,
} from "@hightouch/lib/resource-monitoring/types";
import {
  Box,
  ExternalLinkIcon,
  Alert,
  AlertProps,
  CodeSnippet,
  Row,
  Badge,
  SectionHeading,
  CheckIcon,
  Text,
  Column,
  SkeletonBox,
  Skeleton,
} from "@hightouchio/ui";
import * as time from "src/utils/time";
import { SyncHealthOrUnknown, UnknownSyncHealth } from "src/utils/syncs";
import { enumOrFallback } from "src/types/utils";
import { Card } from "src/components/card";
import { useAlertsForSyncs } from "src/hooks/use-alerts-for-syncs";
import { useAllMonitorConditionsForResourceQuery } from "src/graphql";

const monitorStatusToAlertType: {
  [key in MonitorStatus]: AlertProps["type"];
} = {
  [MonitorStatus.Healthy]: "success",
  [MonitorStatus.Warning]: "warning",
  [MonitorStatus.Unhealthy]: "error",
  [MonitorStatus.Disabled]: "subtle",
} as const;

export const SyncHealthAlertsForSync = ({
  syncId,
  resourceType,
  destinationId,
  onClick,
}: {
  resourceType: MonitoredResourceType.EventSync | MonitoredResourceType.Sync;
  syncId: string;
  destinationId: string;
  onClick: () => void;
}) => {
  const stringSyncId = String(syncId);
  const { isLoading, data } = useAlertsForSyncs([stringSyncId], resourceType);
  const alerts = data?.[stringSyncId]?.alerts;
  const health = data?.[stringSyncId]?.health;

  const { data: monitorCount } = useAllMonitorConditionsForResourceQuery(
    {
      resourceId: syncId,
      resourceType,
      parentResourceType: "Destination",
      parentResourceId: destinationId,
      hasParentResource: true,
    },
    {
      select: (data) =>
        data.resource_monitor_conditions.length +
        (data.monitor_condition_templates?.length || 0),
    },
  );

  return (
    <Skeleton isLoading={isLoading}>
      <SkeletonBox height={isLoading ? "70px" : undefined} borderRadius="md">
        <SyncHealthAlerts
          health={health ?? UnknownSyncHealth}
          monitorCount={monitorCount}
          statuses={
            alerts?.map((change) => ({
              ...change,
              conditionType: enumOrFallback(
                MonitorConditionTypes,
                MonitorConditionTypes.SyncSequentialFailures,
                false,
              )(change.conditionType),
              status: enumOrFallback(
                MonitorStatus,
                MonitorStatus.Unhealthy,
                false,
              )(change.status),
            })) ?? []
          }
          onClick={onClick}
        />
      </SkeletonBox>
    </Skeleton>
  );
};

export const SyncHealthAlerts = ({
  health,
  monitorCount,
  statuses: renderedStatuses,
  onClick,
}: {
  health: SyncHealthOrUnknown;
  monitorCount?: number;
  statuses: {
    id: string;
    text: string;
    metadata?: { error?: string | null } | null;
    conditionType: MonitorConditionTypes;
    status: MonitorStatus;
    eventTime: string;
  }[];
  onClick?: () => void;
}) => (
  <Card p={0}>
    {health === MonitorStatus.Healthy || renderedStatuses.length === 0 ? (
      <Row p={4} justifyContent="space-between" alignItems="center">
        <Row gap={2} alignItems="center">
          <Text size="lg">
            <Box
              borderRadius="50%"
              w="20px"
              h="20px"
              backgroundColor="grass.600"
              alignItems="center"
              display="flex"
              padding="2px"
            >
              <CheckIcon color="white" />
            </Box>
          </Text>
          <SectionHeading>No alerts</SectionHeading>
        </Row>
        <Box cursor="pointer" onClick={onClick}>
          <Text color="text.secondary" size="sm">
            {monitorCount} alert triggers configured <ExternalLinkIcon />
          </Text>
        </Box>
      </Row>
    ) : (
      <Column p={4} pb={0}>
        <Row gap={2} alignItems="center" pb={2}>
          <SectionHeading>Alerts</SectionHeading>
          <Badge size="sm" variant="error">
            {renderedStatuses.length}
          </Badge>
        </Row>
        <Column rowGap={2} display="flex" maxH="350px" overflow="auto" pb={4}>
          {renderedStatuses.map((status) => (
            <Alert
              key={status.id}
              type={monitorStatusToAlertType[status.status]}
              variant="inline"
              message={
                <Box>
                  {status.text}
                  {status.metadata?.error && (
                    <CodeSnippet
                      code={status.metadata.error}
                      mt={2}
                      hasCopyButton={false}
                    />
                  )}
                </Box>
              }
              title={ConditionFriendlyNames[status.conditionType]}
              contextMessage={
                <Box display="flex" alignItems="center">
                  Since {time.formatFriendlyDistanceToNow(status.eventTime)}{" "}
                  {onClick && (
                    <>
                      <Box
                        borderLeft="1px solid"
                        borderColor="base.borderColor"
                        h={4}
                        mx={2}
                      ></Box>
                      <Box cursor="pointer" onClick={onClick}>
                        Edit alert <ExternalLinkIcon />
                      </Box>
                    </>
                  )}
                </Box>
              }
            />
          ))}
        </Column>
      </Column>
    )}
  </Card>
);
