import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";

import { Schedule } from "src/components/schedule/types";
import { useUpdateJourneyMutation } from "src/graphql";
import { DEFAULT_INTERVAL_SCHEDULE } from "src/pages/journeys/constants";
import { JourneyStatus } from "src/types/journeys";

export const useDeleteJourney = () => {
  const { toast } = useToast();
  const updateJourneyMutation = useUpdateJourneyMutation();

  const onDeleteJourney = async (
    journeyToDelete: {
      id: string;
      name: string;
      description: string | null;
      schedule: Schedule | null;
    },
    callback?: () => void,
  ) => {
    try {
      await updateJourneyMutation.mutateAsync({
        journey_graph: {
          journey: {
            id: journeyToDelete.id,
            // status must be disabled for the journey to be deleted
            status: JourneyStatus.Disabled,
            deleted: true, // Triggers journey deletion
            archived: false, // archival was removed, so always set this to false for now.
            description: journeyToDelete.description,
            name: journeyToDelete.name,
            schedule: journeyToDelete.schedule ?? DEFAULT_INTERVAL_SCHEDULE,
          },
        },
      });

      toast({
        id: "journey-save",
        title: "Journey deleted",
        variant: "success",
      });

      callback?.();
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "journey-save",
        title: "Failed to delete journey",
        variant: "error",
      });
    }
  };

  return onDeleteJourney;
};
