import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  ChatIcon,
  Checkbox,
  CheckboxGroup,
  Code,
  Column,
  Combobox,
  Dialog,
  Paragraph,
  Row,
  SectionHeading,
  Badge,
  Text,
  useToast,
  BadgeProps,
} from "@hightouchio/ui";
import { formatDuration, intervalToDuration } from "date-fns";
import { useEffect, useMemo, useState } from "react";

import { ActionBar } from "src/components/action-bar";
import {
  PermissionedButton,
  PermissionedSwitch,
} from "src/components/permission";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import {
  ScheduleIntervalUnit,
  ScheduleType,
} from "src/components/schedule/types";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  IngestionStatsQuery,
  JobStatus,
  LatestIngestionJobQuery,
  useDestinationQuery,
  useIngestionStatsQuery,
  useLatestIngestionJobQuery,
  useSourcesQuery,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import { Markdown } from "src/ui/markdown";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { formatDatetime } from "src/utils/time";
import { newPylonMessage } from "src/lib/pylon";
import { useParams } from "src/router";
import { CodeWithOverflow } from "src/pages/syncs/sync/components/code-with-overflow";

// Default to 1 day for now
const defaultSchedule = {
  type: ScheduleType.INTERVAL,
  schedule: { interval: { unit: ScheduleIntervalUnit.DAY, quantity: 1 } },
};

export const Element = () => {
  const { destination_id: id } = useParams<{ destination_id: string }>();
  const { data: destination } = useDestinationQuery(
    { id: String(id) },
    {
      enabled: Boolean(id),
      select: (data) => data.destinations_by_pk,
      suspense: true,
    },
  );

  const { toast } = useToast();
  const [enabled, setEnabled] = useState<boolean>(
    Boolean(destination?.analytics_ingestion_schedule),
  );
  const [sourceId, setSourceId] = useState<string | undefined>(
    destination?.analytics_ingestion_connection_id?.toString(),
  );
  const { page, limit, offset, setPage } = useTableConfig({ limit: 10 });
  const [ingestionRunError, setIngestionRunError] = useState<null | string>(
    null,
  );

  const { isPermitted: hasUpdateDestinationPermission } = useResourcePermission(
    {
      v2: {
        resource: "destination",
        grant: "can_update",
        id: id!,
      },
    },
  );

  const {
    mutateAsync: updateDestination,
    isLoading: updating,
    isSuccess: finishedUpdating,
  } = useUpdateDestinationV2Mutation();

  useEffect(() => {
    if (finishedUpdating) {
      toast({
        id: "update-destination",
        title: `Data extraction settings have been updated.`,
        variant: "success",
      });
    }
  }, [finishedUpdating]);

  const { data: latestIngestionJob } = useLatestIngestionJobQuery(
    {
      destinationId: id!,
    },
    {
      enabled: Boolean(id),
      refetchInterval: 10_000,
      select: (data) => data.latestIngestionJob,
    },
  );

  const { data: ingestionStatsData } = useIngestionStatsQuery(
    {
      destinationId: id!,
    },
    {
      enabled: Boolean(id),
      refetchInterval: 10_000,
      select: (data) => data.analytics_ingestion_stats,
    },
  );

  // Currently, require sources to be on lightning engine for data extraction
  const { data: sources } = useSourcesQuery(
    {
      filters: {
        plan_in_warehouse: { _eq: true },
      },
    },
    { select: (data) => data.connections },
  );

  const sourceOptions = useMemo(() => {
    return (
      sources
        // XXX: don't show databricks sources for now bc we KNOW they don't work
        // and the other non main ones aren't too common
        ?.filter((source) => source.type !== "databricks")
        ?.map((source) => ({
          value: `${source.id}`,
          label: source.name,
          icon: source.definition.icon,
        })) ?? []
    );
  }, [sources]);

  // only alert once we finished fetching sources
  const alertUnavailableSources = sources && !sources.length;

  const onSubmit = async () => {
    if (!id) return;

    if (!sourceId) {
      toast({
        id: "update-destination",
        title: `Please select a data system to enable data extraction.`,
        variant: "error",
      });
      return;
    }

    if (enabled) {
      await updateDestination({
        id: id?.toString(),
        destination: {
          analytics_ingestion_schedule: defaultSchedule,
          analytics_ingestion_connection_id: sourceId,
        },
      });
    } else {
      await updateDestination({
        id: id?.toString(),
        destination: {
          analytics_ingestion_schedule: null,
          analytics_ingestion_connection_id: null,
        },
      });
      setSourceId(undefined);
    }
  };

  const detectedChanges =
    enabled !== Boolean(destination?.analytics_ingestion_schedule) ||
    sourceId !== destination?.analytics_ingestion_connection_id?.toString();

  return (
    <>
      <Row flex={1} justifyContent="space-between" maxWidth="960px">
        <Column gap={8} maxWidth="570px">
          <Column gap={2}>
            {alertUnavailableSources && (
              <Row mb={4} maxWidth="570px">
                <Alert
                  variant="inline"
                  type="warning"
                  title="No lightning sync engine sources configured"
                  message={
                    <Text>
                      You must configure at least one source on the lightning
                      sync engine to enable data ingestion.
                    </Text>
                  }
                />
              </Row>
            )}
            <Row alignItems="center" gap={4}>
              <SectionHeading>Enable data extraction</SectionHeading>
              <PermissionedSwitch
                aria-label="Enable data extraction."
                permission={{
                  v2: {
                    resource: "destination",
                    grant: "can_update",
                    id: id!,
                  },
                }}
                isChecked={enabled}
                onChange={setEnabled}
                isDisabled={!sources?.length}
              />
            </Row>
            <Paragraph color="text.secondary" size="md">
              Extract audience and other data from{" "}
              {destination?.name ?? "this destination"} into a centralized data
              system on a regular schedule.
            </Paragraph>
          </Column>

          {enabled && (
            <>
              <Column gap={2}>
                <SectionHeading>
                  Which data system should we send {destination?.name} data to?
                </SectionHeading>
                <Paragraph color="text.secondary" size="md">
                  Hightouch will extract the data from{" "}
                  {destination?.name ?? "this destination"} and write to the{" "}
                  <Code>hightouch_audit</Code> schema in your data system.
                </Paragraph>
                <Combobox
                  optionAccessory={(option: any) => ({
                    type: "image",
                    url: option.icon,
                  })}
                  value={sourceId}
                  options={sourceOptions}
                  onChange={setSourceId}
                  width="xs"
                  isDisabled={!hasUpdateDestinationPermission}
                />
              </Column>

              <Column gap={2}>
                <SectionHeading>
                  Which report should we extract from{" "}
                  {destination?.name ?? "this destination"}?
                </SectionHeading>
                <CheckboxGroup>
                  <Checkbox
                    label="Audience size report"
                    description={`Data about the size of each of your audiences in ${
                      destination?.name ?? "this destination"
                    }`}
                    // The value doesn't matter here, we just want to always have this
                    // checked bc it's the only option when this feature is enabled
                    value="audience_report"
                    isChecked={true}
                    onChange={() => {}}
                  />
                </CheckboxGroup>
              </Column>
            </>
          )}
        </Column>

        {enabled && (
          <Column>
            <LatestIngestionStatus latestIngestionJob={latestIngestionJob} />
          </Column>
        )}
      </Row>

      {enabled && ingestionStatsData && Boolean(ingestionStatsData.length) && (
        <Row my={16}>
          <Column gap={4}>
            <SectionHeading>Extraction runs</SectionHeading>
            <IngestionRunsStatsTable
              ingestionStats={ingestionStatsData}
              page={page}
              limit={limit}
              offset={offset}
              setPage={setPage}
              setIngestionRunError={setIngestionRunError}
            />
          </Column>
        </Row>
      )}

      <Dialog
        isOpen={Boolean(ingestionRunError)}
        variant="info"
        width="lg"
        title="Data extraction run error"
        actions={
          <Button
            icon={ChatIcon}
            size="md"
            variant="secondary"
            onClick={() =>
              newPylonMessage(
                "I'm experiencing an issue with the data extraction from my destination " +
                  "and could use some assistance. The error message I'm receiving is: " +
                  `'${ingestionRunError}'. Here's a link to my model: <a href="${window.location.href}">${window.location.href}</a>.`,
              )
            }
          >
            Chat with support
          </Button>
        }
        onClose={() => setIngestionRunError(null)}
      >
        <Column gap={4}>
          <CodeWithOverflow lineClampCount="5">
            <Markdown>{ingestionRunError ?? "Unknown error"}</Markdown>
          </CodeWithOverflow>
          <Column>
            <Text fontWeight="medium" size="lg">
              Need more help?
            </Text>
            <Text mt={2}>
              If you feel stuck, please reach out! We want to make sure you have
              all the help you need. Our team is available via chat to help you
              troubleshoot this error.
            </Text>
          </Column>
        </Column>
      </Dialog>

      <ActionBar>
        <ButtonGroup>
          <PermissionedButton
            permission={{
              v2: {
                resource: "destination",
                grant: "can_update",
                id: id!,
              },
            }}
            size="lg"
            variant="primary"
            isDisabled={!detectedChanges}
            isLoading={updating}
            onClick={onSubmit}
          >
            Save changes
          </PermissionedButton>
        </ButtonGroup>
      </ActionBar>
    </>
  );
};

type LatestIngestionJobProps = {
  latestIngestionJob: LatestIngestionJobQuery["latestIngestionJob"] | undefined;
};

const LatestIngestionStatus = ({
  latestIngestionJob,
}: LatestIngestionJobProps) => {
  let text: string | undefined = "Pending";
  let variant: BadgeProps["variant"] | undefined = "subtle";

  const status = latestIngestionJob?.status;
  const isPending = !latestIngestionJob || status === JobStatus.Queued;

  if (isPending) {
    text = "Pending";
    variant = "subtle";
  } else if (status === JobStatus.Cancelling) {
    text = "Cancelling";
    variant = "subtle";
  } else if (status === JobStatus.Cancelled) {
    text = "Cancelled";
    variant = "subtle";
  } else if (status === JobStatus.Failed) {
    text = "Failed";
    variant = "error";
  } else if (status === JobStatus.Finished) {
    variant = "success";
    text = "Success";
  } else if (status === JobStatus.Processing) {
    text = "Processing";
    variant = "info";
  }

  return (
    <Row bg="white" gap={4} align="flex-start" flexDir="column">
      <Column gap={1}>
        <Text fontWeight="semibold" color="text.secondary" size="sm">
          STATUS
        </Text>
        <Badge variant={variant}>{text}</Badge>
      </Column>

      <Column gap={1}>
        <Text fontWeight="semibold" color="text.secondary" size="sm">
          EXTRACTION SCHEDULE
        </Text>
        <Text size="sm">Every day</Text>
      </Column>

      {latestIngestionJob?.created_at && (
        <Column gap={1}>
          <Text fontWeight="semibold" color="text.secondary" size="sm">
            {isJobCompleted(status) ? "LAST RUN AT" : "STARTED AT"}
          </Text>
          <Text size="sm">{formatDatetime(latestIngestionJob.created_at)}</Text>

          {latestIngestionJob.finished_at && (
            <Text size="sm">
              {formatDuration(
                intervalToDuration({
                  start: new Date(latestIngestionJob.created_at).getTime(),
                  end: new Date(latestIngestionJob.finished_at).getTime(),
                }),
                { zero: false },
              )}
            </Text>
          )}
        </Column>
      )}
    </Row>
  );
};

function isJobCompleted(status: JobStatus | undefined) {
  return (
    status === JobStatus.Finished ||
    status === JobStatus.Failed ||
    status === JobStatus.Cancelled
  );
}

type IngestionRunsStatsProps = {
  ingestionStats: IngestionStatsQuery["analytics_ingestion_stats"];
  page: number;
  limit: number;
  offset: number;
  setPage: (number) => void;
  setIngestionRunError: (error: string) => void;
};

const IngestionRunsStatsTable = ({
  ingestionStats,
  page,
  limit,
  offset,
  setPage,
  setIngestionRunError,
}: IngestionRunsStatsProps) => {
  const tableData = ingestionStats.slice(offset, offset + limit);
  const tableColumns: TableColumn[] = [
    {
      name: "Status",
      cell: ({ status }) => {
        switch (status) {
          case "success":
            return <Badge variant="success">Successful</Badge>;
          case "failed":
            return <Badge variant="error">Failed</Badge>;
          default:
            return <Badge variant="warning">Unknown</Badge>;
        }
      },
    },
    {
      name: "Started at",
      cell: ({ last_ran_at }) => {
        const formattedStartedAt =
          formatDatetime(new Date(last_ran_at).toISOString()) ?? "--";

        return (
          <TextWithTooltip message={formattedStartedAt}>
            {formattedStartedAt}
          </TextWithTooltip>
        );
      },
    },
    {
      name: "Duration",
      cell: ({ duration_ms }) => {
        const duration = formatDuration(
          intervalToDuration({ start: 0, end: duration_ms }),
        );
        return <TextWithTooltip>{duration}</TextWithTooltip>;
      },
    },
    {
      name: "Ad accounts",
      cell: ({ processed_ad_accounts_count }) => (
        <Box textAlign="right" width="100%">
          <Text>{processed_ad_accounts_count ?? "--"}</Text>
        </Box>
      ),
    },
    {
      name: "Total audiences",
      cell: ({ extracted_audiences_count }) => (
        <Box textAlign="right" width="100%">
          <Text>{extracted_audiences_count ?? "--"}</Text>
        </Box>
      ),
    },
  ];

  // if at least one error in the set, show the error column
  if (tableData.some((data) => data.status === "failed")) {
    tableColumns.push({
      name: "Error",
      cell: ({ error, status }) => {
        if (status !== "failed") return null;

        return (
          <Button
            size="sm"
            ml="auto"
            variant="danger"
            onClick={() => {
              setIngestionRunError(
                error ?? "Something went wrong during data extraction.",
              );
            }}
          >
            View error
          </Button>
        );
      },
    });
  }

  return (
    <>
      <Table columns={tableColumns} data={tableData} />
      <Box>
        <Pagination
          page={page}
          setPage={setPage}
          rowsPerPage={limit}
          count={ingestionStats?.length}
        />
      </Box>
    </>
  );
};
