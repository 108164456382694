import { FC, useCallback, useMemo } from "react";

import {
  Heading,
  Row,
  Text,
  Box,
  Tooltip,
  InformationIcon,
  Skeleton,
  Badge,
  SearchInput,
  Column,
} from "@hightouchio/ui";
import { groupBy, intersection, sortedUniqBy, sum } from "lodash";
import { useNavigate } from "src/router";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import {
  eventSourceDefinitions,
  eventWarehouseDestinationDefinitions,
} from "src/events/types";
import {
  ConnectionsBoolExp,
  EventSourcesOrderBy,
  useEventMetricsQuery,
  useEventSourceFiltersQuery,
  useEventSourcesQuery,
  useEventSyncsQuery,
  useMonitorsForEventSourceQuery,
} from "src/graphql";
import { PageTable, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { openUrl } from "src/utils/urls";
import { Page } from "src/components/layout";
import {
  destinationMetadataFromForwardingSync,
  destinationMetadataFromWarehouseSync,
} from "src/events/utils";
import {
  minutesToMilliseconds,
  roundToNearestMinutes,
  subDays,
} from "date-fns";
import { EventVolumeDisplay } from "./source/components/event-volume-display";
import { sourceIdLike } from "@hightouch/lib/resource-monitoring/composite-ids";
import { useActiveAlertsForSource } from "./use-active-alerts-for-source";
import { useSearchParam } from "src/components/routeable-tabs/utils";
import { PageSidebar } from "src/components/layout/page-sidebar";
import {
  Filters,
  sourceTypeFilterConfig,
  useFilters,
} from "src/components/folders/filters";
import { ChartGranularity } from "src/components/charts/chart-utils";
import { SyncsCell } from "src/pages/syncs/sync/components/syncs-cell";
import { getEventSyncStatusFromFragment } from "src/events/syncs/warehouse-sync/runs";
import { SyncRunStatus } from "src/utils/syncs";
import { isPresent } from "src/types/utils";
import { PermissionedLinkButton } from "src/components/permission";
import { MonitoredResourceType } from "@hightouch/lib/resource-monitoring/types";
import { useAlertsForSyncs } from "src/hooks/use-alerts-for-syncs";
import { HTImage } from "src/components/image";
import { useUser } from "src/contexts/user-context";

enum SortKeys {
  Name = "name",
  UpdatedAt = "updated_at",
}

export const EventSources: FC = () => {
  const navigate = useNavigate();
  const { workspace } = useUser();
  const appEventMonitoringEnabled = workspace?.alerting_v2_enabled;

  const { orderBy, onSort } = useTableConfig<EventSourcesOrderBy>({
    defaultSortKey: SortKeys.UpdatedAt,
    sortOptions: Object.values(SortKeys),
  });

  const eventSourceFiltersQuery = useEventSourceFiltersQuery(undefined, {
    select: (data) =>
      sortedUniqBy(
        [
          ...data.event_warehouse_syncs.map(
            ({ event_warehouse_destination }) => {
              const eventWarehouseDestinationDefinition =
                eventWarehouseDestinationDefinitions[
                  event_warehouse_destination.type
                ];

              return {
                id: event_warehouse_destination.type,
                type: event_warehouse_destination.type,
                name: eventWarehouseDestinationDefinition.name,
                definition: eventWarehouseDestinationDefinition,
              };
            },
          ),
          ...data.event_forwarding_syncs.map(({ destination }) => {
            return destination;
          }),
        ],
        (option) => option.type,
      ),
  });

  const filterDefinitions = useMemo(
    () => ({
      viewKey: "source",
      loading: false,
      filters: {
        destinations: {
          options: [
            {
              id: "not-connected",
              renderOption: () => <Text size="sm">No syncs connected</Text>,
            },
            ...sourceTypeFilterConfig(eventSourceFiltersQuery.data ?? []),
          ],
          isDefaultOpen: true,
          title: "Active sync destinations",
        },
        type: {
          options: sourceTypeFilterConfig(
            Object.entries(eventSourceDefinitions).map(([type, defn]) => ({
              definition: defn,
              name: defn.name,
              id: type,
              type,
            })),
          ),
          title: "Type",
        },
      },
    }),
    [eventSourceFiltersQuery.data],
  );

  const {
    result: { state: filters, data: filterData },
    state: { creatingView, selectedView, viewNotSaved, views, updatingView },
    actions: {
      createView,
      deleteView,
      selectView,
      updateCurrentView,
      resetViewFilters,
      clearFilters,
    },
  } = useFilters(filterDefinitions);

  const [search, setSearch] = useSearchParam("search");

  const buildHasuraFilters = () => {
    const hasuraFilters: ConnectionsBoolExp = {
      _and: [
        {
          type: !filters.type?.isAllSelected
            ? { _in: filters.type?.selected.map((filter) => filter.id) }
            : {},
        },
      ],
    };

    if (search) {
      hasuraFilters._and!.push({ name: { _ilike: `%${search}%` } });
    }

    return hasuraFilters;
  };

  const hasuraFilters = useMemo(() => {
    return buildHasuraFilters();
  }, [search, filters]);

  const {
    data: sources,
    isFetching,
    error,
  } = useEventSourcesQuery(
    { orderBy, filters: hasuraFilters },
    {
      select: (data) => data.event_sources,
    },
  );

  const { data: eventSyncs } = useEventSyncsQuery(
    {},
    {
      select: (data) => [
        ...data.event_warehouse_syncs,
        ...data.event_forwarding_syncs,
      ],
    },
  );
  const { data: alerts } = useAlertsForSyncs(
    eventSyncs?.map((e) => String(e.id)) ?? [],
    MonitoredResourceType.EventSync,
  );

  const groupedEventSyncs = groupBy(eventSyncs, (sync) => sync.event_source.id);

  const selectedIds =
    filters.destinations?.selected.map((filter) => filter.id) || [];

  const sourcesWithSyncs = useMemo(
    () =>
      sources
        ?.map((source) => ({
          ...source,
          activeSyncDefinitions:
            groupedEventSyncs[source.id]?.map((sync) => {
              const metadata =
                sync.__typename === "event_warehouse_syncs"
                  ? destinationMetadataFromWarehouseSync(sync)
                  : destinationMetadataFromForwardingSync(sync);
              const latestSyncRun =
                sync.__typename === "event_warehouse_syncs"
                  ? sync.runs.event_warehouse_sync_runs[0]
                  : undefined;
              const computedStatus =
                sync.__typename === "event_warehouse_syncs"
                  ? getEventSyncStatusFromFragment(latestSyncRun)
                  : SyncRunStatus.ACTIVE;
              const alertsRecord = alerts?.[String(sync.id)];
              return {
                health: alertsRecord?.health ?? null,
                last_run_at: latestSyncRun?.started_at ?? null,
                schedule_paused: false,
                status: computedStatus,
                id: sync.id,
                sync_requests:
                  latestSyncRun && latestSyncRun.started_at
                    ? [
                        {
                          id: latestSyncRun.id,
                          created_at: latestSyncRun.started_at,
                          status_computed: computedStatus,
                        },
                      ]
                    : [],
                destination: {
                  name: metadata.name,
                  definition: metadata,
                },
              };
            }) || [],
        }))
        .filter((source) => {
          return !filters.destinations?.isAllSelected
            ? (selectedIds.includes("not-connected") &&
                source.activeSyncDefinitions.length === 0) ||
                intersection(
                  selectedIds,
                  source.activeSyncDefinitions.map(
                    (defn) => defn.destination?.definition.type,
                  ),
                ).length
            : true;
        }) || [],
    [sources, filters, groupedEventSyncs, selectedIds],
  );

  const onRowClick = useCallback(
    ({ id }, event) => openUrl(`/events/sources/${id}`, navigate, event),
    [navigate],
  );

  const now = roundToNearestMinutes(new Date(), { nearestTo: 5 });
  const yesterday = subDays(now, 1);
  const aWeekAgo = subDays(now, 7);

  return (
    <Page
      title="Event sources"
      sx={{ p: 0 }}
      sidebar={
        <PageSidebar
          header={
            <SearchInput
              placeholder="Search all sources..."
              value={search ?? ""}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          }
        >
          <Filters
            clearFilters={clearFilters}
            createView={createView}
            creatingView={creatingView}
            deleteView={deleteView}
            filters={filterData}
            resetFilters={resetViewFilters}
            resource="source"
            selectView={selectView}
            selectedView={selectedView}
            updateCurrentView={updateCurrentView}
            updatingView={updatingView}
            viewNotSaved={viewNotSaved}
            views={views}
          />
        </PageSidebar>
      }
    >
      <PageTable
        width="100%"
        header={
          <Column w="100%" gap={4}>
            <Row justifyContent="space-between">
              <Heading isTruncated size="xl">
                Event sources
              </Heading>
              <Row>
                <PermissionedLinkButton
                  permission={{
                    v1: { resource: "workspace", grant: "update" },
                    v2: {
                      resource: "workspace",
                      grant: "can_update",
                    },
                  }}
                  href="/events/sources/new"
                  variant="primary"
                >
                  Add source
                </PermissionedLinkButton>
              </Row>
            </Row>
          </Column>
        }
        columns={[
          {
            name: "Name",
            sortDirection: orderBy?.name,
            onClick: () => onSort(SortKeys.Name),
            max: "auto",
            cell: ({ name, type }) => {
              const definition = eventSourceDefinitions[type];
              return (
                <Row align="center" gap={2} width="100%">
                  <HTImage
                    src={definition?.icon}
                    alt={type}
                    width="24px"
                    height="24px"
                  />
                  <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
                </Row>
              );
            },
          },
          {
            name: "Active syncs",
            max: "auto",
            disabled: ({ activeSyncDefinitions }) =>
              Boolean(activeSyncDefinitions?.length),
            cell: ({ activeSyncDefinitions }) => (
              <SyncsCell
                resourceType={MonitoredResourceType.EventSync}
                syncs={activeSyncDefinitions}
                getSyncLink={(id) => `/events/syncs/${id}`}
                getRunLink={(id, runId) => `/events/syncs/${id}/runs/${runId}`}
              />
            ),
          },
          ...(appEventMonitoringEnabled
            ? [
                {
                  name: "Active alerts",
                  max: "auto",
                  cell: ({ id }) => {
                    const { data: hasMonitors } =
                      useMonitorsForEventSourceQuery(
                        {
                          sourceIdLike: sourceIdLike(id),
                        },
                        {
                          select: (data) =>
                            Boolean(data.resource_monitor_conditions.length),
                          refetchOnMount: false,
                          staleTime: minutesToMilliseconds(2),
                        },
                      );
                    const { isLoading: statusesLoading, alerts } =
                      useActiveAlertsForSource({ sourceId: id });
                    return (
                      <Skeleton isLoading={statusesLoading}>
                        {alerts && alerts.length ? (
                          <>
                            <Badge variant="error" size="sm" mr={2}>
                              {alerts.length}
                            </Badge>
                            Critical
                          </>
                        ) : hasMonitors ? (
                          "Monitoring..."
                        ) : (
                          <Text color="text.secondary">Not configured</Text>
                        )}
                      </Skeleton>
                    );
                  },
                },
              ]
            : []),
          {
            name: "24hr volume",
            max: "auto",
            cellSx: { justifyContent: "right" },
            headerSx: { justifyContent: "right" },
            cell: ({ id }) => {
              return (
                <EventVolumeDisplay
                  sourceId={id}
                  startTime={yesterday}
                  endTime={now}
                  granularity={ChartGranularity.OneDay}
                />
              );
            },
          },
          {
            max: "auto",
            breakpoint: "md" as const,
            header: () => (
              <Box textAlign="right" w="100%">
                Daily volume
                <Tooltip message="Average over last 7 days">
                  <InformationIcon ml={1} />
                </Tooltip>
              </Box>
            ),
            cellSx: { justifyContent: "right" },
            cell: ({ id }) => {
              const { isLoading, data } = useEventMetricsQuery(
                {
                  input: {
                    id,
                    resourceType: "SOURCE",
                    requests: [
                      {
                        aggregateRequests: {
                          aggType: "SUM",
                          startTime: aWeekAgo.valueOf(),
                          endTime: now.valueOf(),
                          metrics: ["RECEIVED"],
                        },
                      },
                    ],
                  },
                },
                {
                  refetchOnMount: false,
                  staleTime: minutesToMilliseconds(2),
                  select: (data) =>
                    Math.round(
                      sum(
                        data.getEventMetrics.results[0]?.aggregateData?.map(
                          (data) => data?.value,
                        ),
                      ) / 7,
                    ).toLocaleString(),
                },
              );
              return (
                <Skeleton isLoading={isLoading}>
                  <Text>{data || "----"}</Text>
                </Skeleton>
              );
            },
          },
          {
            sortDirection: orderBy?.updated_at,
            onClick: () => onSort(SortKeys.UpdatedAt),
            ...LastUpdatedColumn,
          },
        ].filter(isPresent)}
        data={sourcesWithSyncs}
        error={Boolean(error)}
        loading={isFetching}
        placeholder={placeholder}
        onRowClick={onRowClick}
      />
    </Page>
  );
};

const placeholder = {
  image: searchPlaceholder,
  title: "No event sources",
  body: "Create a new event source",
  error: "Event sources failed to load, please try again.",
};
