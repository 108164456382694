import { FC } from "react";

import {
  MonitorConditionEvaluationProperties,
  MonitoredResourceType,
  ParentResourceTypes,
  SupportedConditionsForResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import {
  Text,
  Column,
  Heading,
  Row,
  SectionHeading,
  ExternalLinkIcon,
} from "@hightouchio/ui";

import { LinkButton, useParams } from "src/router";
import { MonitorConditionForm } from "src/components/resource-alert-triggers/monitor-condition-forms/monitor-condition-form";
import {
  useResourceMonitorConditionTemplatesQuery,
  useUpsertMonitorConditionTemplatesMutation,
} from "src/graphql";
import { ParentRecipients } from "src/components/resource-alert-triggers/parent-recipients";

export const Element: FC = () => {
  const { destination_id } = useParams<{ destination_id: string }>();
  const id = destination_id!;

  const { data } = useResourceMonitorConditionTemplatesQuery(
    {
      id,
      type: ParentResourceTypes.Destination,
    },
    { select: (data) => data.monitor_condition_templates },
  );

  const upsert = useUpsertMonitorConditionTemplatesMutation();

  const onSubmit = async ({ condition }) => {
    await upsert.mutateAsync({
      objects: [
        {
          evaluation_trigger:
            MonitorConditionEvaluationProperties[condition.type]
              .EvaluationTrigger,
          evaluation_type:
            MonitorConditionEvaluationProperties[condition.type].EvaluationType,
          type: condition.type,
          enabled: condition.enabled,
          error_value: condition.error_value,
          warning_value: condition.warning_value,
          parent_resource_id: id,
          parent_resource_type: ParentResourceTypes.Destination,
        },
      ],
    });
  };

  return (
    <Column gap={6}>
      <Row justify="space-between">
        <Column>
          <Heading>Destination-level alert settings</Heading>
          <Text color="text.secondary">
            Set defaults for all syncs to this destination.
          </Text>
        </Column>
        <LinkButton href="/alerting/alerts" directionIcon={ExternalLinkIcon}>
          See alerts for all destinations
        </LinkButton>
      </Row>
      <ParentRecipients
        resourceId={id}
        resourceType={ParentResourceTypes.Destination}
      />
      <Column gap={2}>
        <Row gap={4} align="center" justify="space-between">
          <Column>
            <SectionHeading>Triggers</SectionHeading>
            <Text color="text.secondary">Send alerts when:</Text>
          </Column>
        </Row>
        {SupportedConditionsForResourceTypes[MonitoredResourceType.Sync].map(
          (conditionType) => {
            const conditions =
              data?.filter((c) => c.type === conditionType) ?? [];
            return (
              <MonitorConditionForm
                key={conditionType}
                conditionType={conditionType}
                conditions={conditions}
                onSubmit={onSubmit}
              />
            );
          },
        )}
      </Column>
    </Column>
  );
};
