import pluralize from "pluralize";

import * as SyncErrors from "src/types/sync-errors";
import { SyncRequestErrorCode } from "src/types/sync-errors";
import { SyncRunStatus } from "src/utils/syncs";
import { DocumentLink } from "./error-origin-info-modal";

// These error codes have special error messages and titles that are not in sanity,
// and don't fit the error origin pattern
const SPECIAL_CASE_ERROR_CODES = [
  SyncRequestErrorCode.UNSUPPORTED_PRIMARY_KEY_TYPE,
  SyncRequestErrorCode.PREVIOUS_SYNC_RUN_OBJECT_MISSING,
  SyncRequestErrorCode.REMOVE_RETRY_CHANGED_COLUMN_TYPES,
  SyncRequestErrorCode.WAREHOUSE_TABLE_MISSING,
] as const;

type SpecialCaseErrorCode = (typeof SPECIAL_CASE_ERROR_CODES)[number];

export function isSpecialCaseErrorCode(
  errorCode: NonNullable<
    SyncErrors.SyncRequestErrorInfo["syncRequestErrorCode"]
  >,
): errorCode is SpecialCaseErrorCode {
  return SPECIAL_CASE_ERROR_CODES.includes(errorCode as SpecialCaseErrorCode);
}

export const getErrorCode = (
  { syncRequestErrorCode }: SyncErrors.SyncRequestErrorInfo,
  syncStatus?: SyncRunStatus | null,
): NonNullable<SyncErrors.SyncRequestErrorInfo["syncRequestErrorCode"]> => {
  return (
    syncRequestErrorCode ??
    (syncStatus === SyncRunStatus.UNPROCESSABLE
      ? SyncRequestErrorCode.PREVIOUS_SYNC_RUN_OBJECT_MISSING
      : SyncRequestErrorCode.UNSPECIFIED)
  );
};

const docsUrl = (path: string) => `${import.meta.env.VITE_DOCS_URL}${path}`;

export const getSpecialCaseErrorInfoContent = (
  errorCode: SpecialCaseErrorCode,
  syncRequestError: SyncErrors.SyncRequestErrorInfo,
): {
  title: string;
  message: string;
  links?: DocumentLink[];
} => {
  switch (errorCode) {
    case SyncRequestErrorCode.UNSUPPORTED_PRIMARY_KEY_TYPE: {
      const errInfo =
        syncRequestError as SyncErrors.UnsupportedPrimaryKeyTypeErrorInfo;

      return {
        title: "Primary key type not supported",
        message: [
          `The primary key type ${errInfo.type} of your model is not supported for Lightning Sync Engine. Please cast your primary key in your sql. Supported types are:`,
          `String variants: ${errInfo.supportedPrimaryKeyType.strings}`,
          `Integer variants: ${errInfo.supportedPrimaryKeyType.ints}`,
          errInfo.supportedPrimaryKeyType.floats
            ? `Float variants: ${errInfo.supportedPrimaryKeyType.floats}`
            : "",
        ].join("\n\n"),
      };
    }
    case SyncRequestErrorCode.PREVIOUS_SYNC_RUN_OBJECT_MISSING:
      return {
        title: "Full resync needed",
        message: [
          "Previous sync state required for diffing is missing. This is usually because your sync hasn't run in more than 30 days, so your sync data has been purged from our storage.",
          "Trigger a full resync to restart the sync without diffing. This will cause the sync to re-run as if it were newly created.",
        ].join("\n\n"),
        links: [
          {
            label: "Full resync",
            url: docsUrl("/syncs/overview/#resync-full-query"),
            type: "external",
          },
          {
            label: "Storage",
            url: docsUrl("/security/storage"),
            type: "external",
          },
        ],
      };
    case SyncRequestErrorCode.REMOVE_RETRY_CHANGED_COLUMN_TYPES: {
      const errInfo =
        syncRequestError as SyncErrors.RemoveRetryChangedColumnTypes;
      const changedColumns = Array.isArray(errInfo?.changedColumns)
        ? errInfo.changedColumns.slice(0, 10)
        : [];

      return {
        title: "Column types changed in model",
        message: [
          "Changing column types is not supported when there are removed rows that need to be retried. Try reverting your model and resolving any errors with removed rows before syncing your new query.",
          changedColumns.length > 0
            ? `The affected ${
                changedColumns.length > 1 ? "columns are" : "column is"
              }:`
            : "",
          ...changedColumns.map((column) => `\`${column}\``),
        ].join("\n\n"),
      };
    }

    case SyncRequestErrorCode.WAREHOUSE_TABLE_MISSING: {
      const errInfo = syncRequestError as SyncErrors.WarehouseTableMissing;
      const missingTables = Array.isArray(errInfo?.missingTables)
        ? errInfo.missingTables
        : [];

      return {
        title: "Required tables missing",
        message: [
          "Tables required for Lightning Sync Engine are not in your source.",
          `Missing the following ${pluralize("tables", missingTables.length)}:`,
          ...missingTables.map((table) => `\`${table}\``),
          "Trigger a full resync to restart the sync without diffing. This will cause the sync to re-run as if it were newly created.",
        ].join("\n\n"),
        links: [
          {
            label: "Lightning Sync Engine",
            url: docsUrl("/syncs/lightning-sync-engine"),
            type: "external",
          },
          {
            label: "Full resync",
            url: docsUrl("/syncs/overview/#resync-full-query"),
            type: "external",
          },
        ],
      };
    }
  }
};
