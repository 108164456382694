import { FC } from "react";

import { FormField, BadgeInput } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { useOutletContext } from "src/router";
import { FlowMessageContext } from "..";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";

export const CampaignAttribution: FC = () => {
  const { watch } = useFormContext();
  const campaignId = watch("flowMessageConfig.campaignId");
  const resourceId = watch("config.resourceId");
  const { flowMessage } = useOutletContext<FlowMessageContext>();
  const { channel } = flowMessage.message;
  const channelDefinition = getChannelDefinition(channel.destination.type);
  return (
    <Controller
      name="flowMessageConfig.attributionCampaignIds"
      render={({ field }) => {
        const value = (field.value ?? []) as Array<string>;
        return (
          <FormField
            isOptional
            label="Campaign IDs"
            description={`Hightouch has associated this action to campaign ${campaignId || resourceId} in ${channelDefinition.getChannelDescription(channel.config)}. If there are additional campaigns that this action is triggering, add those campaign IDs below so that Hightouch can associate their engagement events with this action.`}
          >
            <BadgeInput
              {...field}
              value={value}
              supportsCreatableOptions
              options={value}
              optionLabel={(option) => option}
              optionValue={(option) => option}
              placeholder="Campaign IDs..."
              onCreateOption={(option) => {
                field.onChange([...value, option]);
              }}
            />
          </FormField>
        );
      }}
    />
  );
};
