import { FC, ReactNode } from "react";
import { Alert } from "@hightouchio/ui";

import { QueryType, type ManagedQueryType } from "src/types/models";
import { UnreachableCaseError } from "ts-essentials";

type Props = {
  type: ManagedQueryType;
  actions?: ReactNode;
  overriden?: boolean;
};

const getCopy = (type: ManagedQueryType) => {
  switch (type) {
    case QueryType.JourneyNode:
      return {
        title: "Journey node managed model",
        message:
          "This query is managed by Hightouch and associated with a journey node",
      };
    case QueryType.DecisionEngine:
      return {
        title: "AI Decisioning managed model",
        message:
          "This query is managed by Hightouch and associated with a flow message",
      };
    case QueryType.IDRGoldenRecord:
      return {
        title: "Golden Record managed model",
        message:
          "This query is managed by Hightouch and associated with an identity graph and its golden record configuration",
      };
    default:
      throw new UnreachableCaseError(type);
  }
};

export const ManagedQuery: FC<Readonly<Props>> = ({
  type,

  actions,
  overriden,
}) => {
  const { title, message } = getCopy(type);

  return (
    <Alert
      actions={actions}
      title={title}
      message={overriden ? `This managed query has been overridden` : message}
      variant="inline"
      type={overriden ? "warning" : "info"}
    />
  );
};
