import { FC } from "react";

import {
  AddBoxIcon,
  Box,
  Column,
  Menu,
  MenuButton,
  MenuList,
  Row,
  SearchInput,
  Text,
  TraitIcon,
  TraitTemplateIcon,
} from "@hightouchio/ui";

import {
  Outlet,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
  Navigate,
} from "src/router";
import { DetailPage } from "src/components/layout";
import { PermissionedRoute } from "src/components/router/permissioned-route";
import { CreateTrait } from "src/components/traits/create-trait";
import { CreateTraitFromTemplate } from "src/components/traits/create-trait-from-template";
import { DuplicateTrait } from "src/components/traits/duplicate-trait";
import { EditTrait } from "src/components/traits/edit-trait";
import { ViewTrait } from "src/components/traits/view-trait";
import { NotFound } from "src/pages/not-found";
import { TraitsTable } from "./traits-table";
import { PermissionedMenuItem } from "src/components/permission";

import { TraitType } from "./types";
import { FormErrorProvider } from "src/contexts/form-error-context";
export const Traits: FC = () => (
  <Routes>
    <Route path="/" element={<Layout />}>
      <Route index element={<Navigate to="active" />} />
      <Route path="active" element={<TraitsTable type={TraitType.Active} />}>
        <Route
          path="new"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "audience_schema", grant: "create" },
              }}
              redirect="/traits/active"
            >
              <FormErrorProvider>
                <CreateTrait isTemplate={false} />
              </FormErrorProvider>
            </PermissionedRoute>
          }
        />
        <Route
          path="new_from_template"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "audience_schema", grant: "create" },
              }}
              redirect="/traits/active"
            >
              <CreateTraitFromTemplate />
            </PermissionedRoute>
          }
        />
        <Route
          path=":traitId"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "audience_schema", grant: "read" },
              }}
              redirect="/traits/active"
            >
              <ViewTrait type={TraitType.Active} />
            </PermissionedRoute>
          }
        />
        <Route
          path=":traitId/edit"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "audience_schema", grant: "update" },
              }}
              redirect="/traits/active/:traitId"
            >
              <FormErrorProvider>
                <EditTrait />
              </FormErrorProvider>
            </PermissionedRoute>
          }
        />
        <Route
          path=":traitId/duplicate"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "audience_schema", grant: "create" },
              }}
              redirect="/traits/active/:traitId"
            >
              <FormErrorProvider>
                <DuplicateTrait />
              </FormErrorProvider>
            </PermissionedRoute>
          }
        />
      </Route>
      <Route
        path="templates"
        element={<TraitsTable type={TraitType.Templates} />}
      >
        <Route
          path="new"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "audience_schema", grant: "create" },
              }}
              redirect="/traits/templates"
            >
              <FormErrorProvider>
                <CreateTrait isTemplate />
              </FormErrorProvider>
            </PermissionedRoute>
          }
        />
        <Route
          path=":traitId"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "audience_schema", grant: "read" },
              }}
              redirect="/traits/templates"
            >
              <ViewTrait type={TraitType.Templates} />
            </PermissionedRoute>
          }
        />
        <Route
          path=":traitId/edit"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "audience_schema", grant: "update" },
              }}
              redirect="/traits/templates/:traitId"
            >
              <FormErrorProvider>
                <EditTrait isTemplate />
              </FormErrorProvider>
            </PermissionedRoute>
          }
        />
      </Route>
      <Route
        path="archived"
        element={<TraitsTable type={TraitType.Archived} />}
      >
        <Route
          path=":traitId"
          element={
            <PermissionedRoute
              permission={{
                v1: { resource: "audience_schema", grant: "read" },
              }}
              redirect="/traits/archived"
            >
              <ViewTrait type={TraitType.Archived} />
            </PermissionedRoute>
          }
        ></Route>
      </Route>
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes>
);

const Layout: FC = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const menuItems = [
    {
      label: "New trait",
      description:
        "Run a calculation on a related model column and add filtering conditions",
      icon: TraitIcon,
      onClick: () => navigate("/traits/active/new"),
    },
    {
      label: "Start with a template",
      description: "Build a new trait based on a template",
      icon: TraitTemplateIcon,
      onClick: () => navigate("/traits/active/new_from_template"),
    },
    {
      label: "New template",
      description: "Save the calculation method",
      icon: AddBoxIcon,
      onClick: () => navigate("/traits/templates/new"),
    },
  ];

  return (
    <DetailPage
      heading="Traits"
      actions={
        <Row justifyContent="space-between" align="center" gap={4}>
          <Row gap={4}>
            <SearchInput
              placeholder="Search traits..."
              value={searchParams.get("search") ?? ""}
              onChange={(event) => {
                setSearchParams((previousSearchParams) => {
                  previousSearchParams.set("search", event.target.value);

                  return previousSearchParams;
                });
              }}
            />
            <Menu>
              <MenuButton variant="primary">Create</MenuButton>
              <MenuList>
                {menuItems.map((item) => (
                  <PermissionedMenuItem
                    permission={{
                      v1: { resource: "audience_schema", grant: "create" },
                    }}
                    key={item.label}
                    onClick={item.onClick}
                  >
                    <Row alignItems="center" gap={3} width="320px">
                      <Box
                        as={item.icon}
                        color="text.secondary"
                        height={4}
                        width={4}
                      />
                      <Column width="248px">
                        <Text>{item.label}</Text>
                        <Text color="text.secondary">{item.description}</Text>
                      </Column>
                    </Row>
                  </PermissionedMenuItem>
                ))}
              </MenuList>
            </Menu>
          </Row>
        </Row>
      }
      tabDepth={3}
      tabs={[
        {
          title: "Traits",
          path: "active",
        },
        {
          title: "Templates",
          path: "templates",
        },
        {
          title: "Archived",
          path: "archived",
        },
      ]}
    >
      <Outlet />
    </DetailPage>
  );
};
