import { exhaustiveCheck } from "../../util/exhaustive-check";
import {
  type AndCondition,
  ColumnType,
  type Condition,
  ConditionType,
  type EventCondition,
  type FunnelCondition,
  type NumberOfCondition,
  type OrCondition,
  type PropertyCondition,
  type ReferencedPropertyCondition,
  type SegmentSetCondition,
  StringOperator,
  AggregationOption,
  PerUserAggregationType,
  AudienceAggregationType,
  type ColumnReference,
  type SyntheticColumn,
  isSyntheticColumn,
  isJourneyEventColumn,
  type FormulaTraitConfig,
  TraitType,
  type RawSqlTraitConfig,
  type TraitConfig,
} from "./types";

export type VisitableCondition = Condition | FunnelCondition;
type VisitorFunction<TCondition> = (
  condition: TCondition,
  path: VisitableCondition[],
) => void;

/**
 * Visitor functions type. Exposes one function per condition type, to be executed
 * for each condition of that type in a given condition tree.
 *
 * Note that for now, this doesn't include And and Or conditions.
 */
export type AudienceVisitors = {
  [ConditionType.Property]?: VisitorFunction<PropertyCondition>;
  [ConditionType.ReferenceProperty]?: VisitorFunction<ReferencedPropertyCondition>;
  [ConditionType.Event]?: VisitorFunction<EventCondition>;
  [ConditionType.NumberOf]?: VisitorFunction<NumberOfCondition>;
  [ConditionType.SegmentSet]?: VisitorFunction<SegmentSetCondition>;
};

/**
 * Runs a depth-first search of the condition tree, executing any visitor functions
 * along the way. The deepest conditions in the tree will be visited first.
 *
 * @param conditions Root conditions to explore.
 * @param visitors Visitor functions to be executed for each condition in the tree.
 */
export function visitConditionList(
  conditions: Condition[],
  visitors: AudienceVisitors,
  path: VisitableCondition[] = [],
) {
  for (const condition of conditions) {
    visitCondition(condition, visitors, path);
  }
}

/**
 * Runs a depth-first search of the condition tree, executing any visitor functions
 * along the way. The deepest conditions in the tree will be visited first.
 *
 * @param condition Root of the condition tree.
 * @param visitors Visitor functions to be executed for each condition in the tree.
 */
function visitCondition(
  condition: Condition,
  visitors: AudienceVisitors,
  path: VisitableCondition[],
) {
  const nextPath = [...path, condition];
  switch (condition.type) {
    case ConditionType.And:
    case ConditionType.Or:
      visitConditionList(condition.conditions, visitors, nextPath);
      break;
    case ConditionType.Property:
      visitPropertyCondition(condition, visitors, path);
      if (ConditionType.Property in visitors) {
        visitors[ConditionType.Property]?.(condition, path);
      }
      break;
    case ConditionType.ReferenceProperty:
      visitPropertyCondition(condition, visitors, path);
      if (ConditionType.ReferenceProperty in visitors) {
        visitors[ConditionType.ReferenceProperty]?.(condition, path);
      }
      break;
    case ConditionType.Event: {
      if (condition.subconditions) {
        visitConditionList(condition.subconditions, visitors, nextPath);
      }
      if (
        condition.funnelCondition &&
        condition.funnelCondition.subconditions
      ) {
        visitConditionList(condition.funnelCondition.subconditions, visitors, [
          ...nextPath,
          condition.funnelCondition,
        ]);
      }
      if (ConditionType.Event in visitors) {
        visitors[ConditionType.Event]?.(condition, path);
      }
      break;
    }
    case ConditionType.NumberOf:
      if (condition.subconditions) {
        visitConditionList(condition.subconditions, visitors, nextPath);
      }
      if (ConditionType.NumberOf in visitors) {
        visitors[ConditionType.NumberOf]?.(condition, path);
      }
      break;
    case ConditionType.SegmentSet:
      if (ConditionType.SegmentSet in visitors) {
        visitors[ConditionType.SegmentSet]?.(condition, path);
      }
      break;
    default:
      exhaustiveCheck(condition);
  }
}

function visitPropertyCondition(
  condition: PropertyCondition | ReferencedPropertyCondition,
  visitors: AudienceVisitors,
  path: VisitableCondition[],
) {
  if (
    !condition.property ||
    typeof condition.property === "string" ||
    condition.property.type === "raw" ||
    isSyntheticColumn(condition.property) ||
    isJourneyEventColumn(condition.property)
  ) {
    return;
  }
  if (condition.property.column.type === "trait") {
    // Check the trait itself, and also any filter conditions on the trait property condition.
    visitConditionList(condition.property.column.conditions, visitors, [
      ...path,
      condition,
    ]);
  }
}

/**
 * Shorthand for creating an and condition.
 */
export function and<T extends Condition = Condition>(
  ...conditions: T[]
): AndCondition<T> {
  return {
    type: ConditionType.And,
    conditions: conditions,
  };
}

/**
 * Shorthand for creating an or condition.
 */
export function or<T extends Condition = Condition>(
  ...conditions: T[]
): OrCondition<T> {
  return {
    type: ConditionType.Or,
    conditions: conditions,
  };
}

export const numberEqualsCondition = (
  property: string,
  value: number,
): PropertyCondition => ({
  type: ConditionType.Property,
  property,
  propertyType: ColumnType.Number,
  operator: StringOperator.Equals,
  value,
});

// Given a user-facing AggregationOption, return the corresponding PerUserAggregationType and
// AudienceAggregationType.
export function getAggregationConfiguration(
  aggregation: AggregationOption | undefined,
  cumulative?: boolean,
): {
  aggregation: PerUserAggregationType;
  audienceAggregation: AudienceAggregationType;
} | null {
  if (!aggregation) {
    return null;
  }

  switch (aggregation) {
    case AggregationOption.Count: {
      if (cumulative) {
        return {
          aggregation: PerUserAggregationType.Count,
          audienceAggregation: AudienceAggregationType.Cumulative,
        };
      } else {
        return {
          aggregation: PerUserAggregationType.Count,
          audienceAggregation: AudienceAggregationType.Sum,
        };
      }
    }
    case AggregationOption.CountDistinctProperty:
      return {
        aggregation: PerUserAggregationType.CountDistinct,
        audienceAggregation: AudienceAggregationType.None,
      };
    case AggregationOption.UniqueUsers: {
      if (cumulative) {
        return {
          aggregation: PerUserAggregationType.UniqueUsers,
          audienceAggregation: AudienceAggregationType.Cumulative,
        };
      } else {
        return {
          aggregation: PerUserAggregationType.UniqueUsers,
          audienceAggregation: AudienceAggregationType.Sum,
        };
      }
    }
    case AggregationOption.PercentOfAudience:
      return {
        aggregation: PerUserAggregationType.UniqueUsers,
        audienceAggregation: AudienceAggregationType.PercentOfAudience,
      };
    case AggregationOption.AverageOfProperty:
      return {
        aggregation: PerUserAggregationType.Average,
        audienceAggregation: AudienceAggregationType.None,
      };
    case AggregationOption.AverageOfPropertyPerUser:
      return {
        aggregation: PerUserAggregationType.Average,
        audienceAggregation: AudienceAggregationType.Average,
      };
    case AggregationOption.SumOfProperty:
      return {
        aggregation: PerUserAggregationType.Sum,
        audienceAggregation: cumulative
          ? AudienceAggregationType.Cumulative
          : AudienceAggregationType.Sum,
      };
    case AggregationOption.SumOfPropertyPerUser:
      return {
        aggregation: PerUserAggregationType.Sum,
        audienceAggregation: AudienceAggregationType.Average,
      };
    default:
      throw new Error(`Unknown aggregation option: ${aggregation}`);
  }
}

export function getColumnName(
  column: ColumnReference | SyntheticColumn | null | undefined,
) {
  if (!column) {
    return null;
  }
  if (column.type === "raw" || isSyntheticColumn(column)) {
    return column.name;
  } else if (column.type === "related") {
    return getColumnName(column.column);
  } else if (column.type === "trait") {
    return null;
  }

  return null;
}

export function getTraitType(type: TraitType, config: TraitConfig) {
  switch (type) {
    case TraitType.Count:
    case TraitType.Sum:
    case TraitType.Average:
      return ColumnType.Number;
    case TraitType.First:
    case TraitType.Last:
    case TraitType.MostFrequent:
    case TraitType.LeastFrequent:
      // TODO: Get the column type from the relationship
      return ColumnType.Unknown;
    case TraitType.RawSql:
      return (config as RawSqlTraitConfig).resultingType;
    case TraitType.Formula:
      return (config as FormulaTraitConfig).resultingType;
    default:
      return ColumnType.Unknown;
  }
}
