import { FC } from "react";

import { Column, Text } from "@hightouchio/ui";

import { QueryBuilder } from "src/components/explore";
import { PageSpinner } from "src/components/loading";
import { QueryBuilderProps } from "src/components/explore/query-builder";

type Props = {
  isLoading?: boolean;
  parentModel: QueryBuilderProps["parentModel"];
  conditions: QueryBuilderProps["filter"];
  setConditions: QueryBuilderProps["setConditions"];
};

export const Filter: FC<Readonly<Props>> = ({
  isLoading = false,
  parentModel,
  conditions,
  setConditions,
}) => {
  if (isLoading) {
    return <PageSpinner />;
  }

  return (
    <>
      <Column>
        <Text fontWeight="medium">Rule definition</Text>
        <Text color="text.secondary">
          Define which records are eligible to be synced to the destination
        </Text>
      </Column>

      <QueryBuilder
        parentModel={parentModel}
        filter={conditions}
        setConditions={setConditions}
      />
    </>
  );
};
