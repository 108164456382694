import { FC, useEffect, useState } from "react";

import {
  ArrowRightIcon,
  Badge,
  Column,
  DeleteIcon,
  Menu,
  MenuActionsButton,
  MenuDivider,
  MenuList,
  PlayIcon,
  Row,
  Spinner,
  Text,
  useToast,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";

import { Outlet, useNavigate, useParams } from "src/router";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { PermissionProvider } from "src/components/permission/permission-context";
import { Warning } from "src/components/warning";
import {
  SyncQuery,
  useDeleteSyncMutation,
  useSyncQuery,
  useUpdateSyncMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { getObjectName } from "src/utils/syncs";
import { useQueryString } from "src/utils/use-query-string";
import {
  PermissionedButton,
  PermissionedMenuItem,
  PermissionedSwitch,
} from "src/components/permission";
import { useCurrentRun } from "src/pages/syncs/sync/utils/use-current-run";
import { RouteTabs } from "src/components/route-tabs";

const getDeleteSyncErrorMessage = (error: Error): string => {
  return error.message.startsWith("Foreign key violation") &&
    error.message.includes("sync_sequence")
    ? "This sync cannot be deleted because it is used in one or more sequences"
    : error.message;
};

export type OutletContext = {
  sync: NonNullable<SyncQuery["syncs"][0]>;
  startRun: ReturnType<typeof useCurrentRun>["startRun"];
};

export const SyncLayout: FC = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [deleting, setDeleting] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);

  const { mutateAsync: updateSync } = useUpdateSyncMutation();
  const { mutateAsync: deleteSyncById } = useDeleteSyncMutation();

  const { id } = useParams<{ id: string }>();
  const { data: sync } = useSyncQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      suspense: true,
      select: (data) => data.syncs[0],
    },
  );

  const { isCancelling, isRunning, startRun, cancelRun, isRunDisabled } =
    useCurrentRun(sync?.id);
  const model = sync?.segment;
  const destination = sync?.destination;

  const deleteSync = async () => {
    try {
      await deleteSyncById({
        id: sync?.id,
        stringId: sync?.id.toString(),
      });
      toast({
        id: "delete-sync",
        title: "Sync deleted",
        variant: "success",
      });
      analytics.track("Sync Deleted", {
        sync_id: sync?.id,
        destination_type: destination?.definition?.name,
        schedule_type: sync?.schedule?.type,
        source_type: model?.connection?.type,
      });
      navigate("/syncs");
    } catch (error) {
      captureException(error);
      toast({
        id: "delete-sync",
        title: "Couldn't delete this sync",
        message: getDeleteSyncErrorMessage(error),
        variant: "error",
      });
    }
  };

  const toggleSyncPause = async (enabled: boolean) => {
    try {
      await updateSync({
        id: sync?.id,
        object: {
          schedule_paused: !enabled,
        },
      });

      toast({
        id: "toggle-sync-pause",
        title: `Sync was ${enabled ? "enabled" : "disabled"}`,
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "toggle-sync-pause",
        title: `Sync could not be ${enabled ? "enabled" : "disabled"}`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const {
    data: { autorun },
  } = useQueryString();

  useEffect(() => {
    let autoRunTimeout: number | undefined | null;
    if (sync && autorun) {
      autoRunTimeout = window.setTimeout(() => {
        startRun();
      }, 400);
    }
    return () => {
      if (autoRunTimeout) {
        clearTimeout(autoRunTimeout);
        autoRunTimeout = null;
      }
    };
  }, [autorun, sync?.id]);

  useEffect(() => {
    setEnabled(!sync?.schedule_paused);
  }, [sync?.schedule_paused]);

  if (!sync) {
    return (
      <Warning subtitle="It may have been deleted" title="Sync not found" />
    );
  }

  return (
    <>
      <PermissionProvider
        permission={{
          v2: {
            resource: "sync",
            grant: "can_update",
            id: sync.id,
          },
        }}
      >
        <Column width="100%" flex={1}>
          <Row
            align="center"
            justify="space-between"
            width="100%"
            mb={6}
            gap={6}
            p={4}
          >
            <Row align="center" gap={4}>
              <Row align="center" gap={3}>
                <IntegrationIcon
                  size={8}
                  src={sync.segment?.connection?.definition.icon}
                  name={sync.segment?.connection?.definition.name}
                />
                <Column overflow="hidden">
                  <Text
                    size="sm"
                    fontWeight="semibold"
                    color="text.tertiary"
                    textTransform="uppercase"
                  >
                    Model
                  </Text>
                  <Text fontWeight="medium" size="lg" isTruncated>
                    {sync.segment?.name}
                  </Text>
                </Column>
              </Row>
              <Row fontSize="3xl" color="text.placeholder">
                <ArrowRightIcon />
              </Row>
              <Row align="center" gap={3}>
                <IntegrationIcon
                  size={8}
                  src={sync.destination?.definition.icon}
                  name={sync.destination?.definition.name}
                />
                <Column overflow="hidden">
                  <Text
                    size="sm"
                    fontWeight="semibold"
                    color="text.tertiary"
                    textTransform="uppercase"
                  >
                    Destination
                  </Text>
                  <Row align="center" gap={3}>
                    <Text fontWeight="medium" size="lg" isTruncated>
                      {sync.destination?.name}
                    </Text>
                    {sync?.config?.object && (
                      <Badge size="sm">
                        {getObjectName(sync.config.object)}
                      </Badge>
                    )}
                  </Row>
                </Column>
              </Row>
            </Row>
            <Row gap={4}>
              <Row align="center" gap={2}>
                <Text
                  textTransform="uppercase"
                  size="sm"
                  fontWeight="semibold"
                  color="text.tertiary"
                >
                  {enabled ? "Enabled" : "Disabled"}
                </Text>
                <PermissionedSwitch
                  aria-label="Enable sync."
                  isChecked={enabled}
                  permission={{
                    v2: { resource: "sync", grant: "can_run", id: sync.id },
                  }}
                  onChange={(value) => {
                    setEnabled(value);
                    toggleSyncPause(value);
                  }}
                />
              </Row>
              <Menu>
                <MenuActionsButton variant="secondary" />
                <MenuList>
                  <PermissionedMenuItem
                    icon={PlayIcon}
                    isDisabled={isRunning}
                    onClick={() => {
                      startRun({ resync: true });
                    }}
                    permission={{
                      v2: { resource: "sync", grant: "can_run", id: sync.id },
                    }}
                  >
                    Resync full query
                  </PermissionedMenuItem>
                  <MenuDivider />
                  <PermissionedMenuItem
                    icon={DeleteIcon}
                    isDisabled={isRunning}
                    variant="danger"
                    onClick={() => {
                      setDeleting(true);
                    }}
                    permission={{
                      v2: {
                        resource: "sync",
                        grant: "can_delete",
                        id: sync.id,
                      },
                    }}
                  >
                    Delete
                  </PermissionedMenuItem>
                </MenuList>
              </Menu>
              {isRunning || isCancelling ? (
                <PermissionedButton
                  permission={{
                    v2: { resource: "sync", grant: "can_run", id: sync.id },
                  }}
                  isDisabled={isCancelling}
                  onClick={cancelRun}
                >
                  <Spinner size="sm" mr={2} />
                  {isCancelling ? "Canceling..." : "Cancel run"}
                </PermissionedButton>
              ) : (
                <PermissionedButton
                  permission={{
                    v2: { resource: "sync", grant: "can_run", id: sync.id },
                  }}
                  isDisabled={isRunDisabled}
                  icon={PlayIcon}
                  onClick={() => {
                    startRun();
                  }}
                >
                  Run
                </PermissionedButton>
              )}
            </Row>
          </Row>
          <RouteTabs
            tabs={[
              { path: "runs", title: "Runs" },
              {
                path: "configuration",
                title: "Configuration",
              },
              { path: "schedule", title: "Schedule" },
              { path: "model", title: "Model" },
            ]}
            depth={4}
            mx={4}
          />
          <Outlet context={{ sync, startRun }} />
        </Column>
      </PermissionProvider>

      <DeleteConfirmationModal
        isOpen={deleting}
        label="sync"
        onClose={() => {
          setDeleting(false);
        }}
        onDelete={deleteSync}
      />
    </>
  );
};
