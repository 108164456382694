import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import { Badge } from "@hightouchio/ui";

export const FlowStatus = ({
  status,
  enabled,
}: {
  status: DecisionEngineStatus;
  enabled: boolean;
}) => {
  if (!enabled && status === DecisionEngineStatus.READY) {
    return <Badge>Paused</Badge>;
  }
  return (
    <Badge
      variant={
        status === DecisionEngineStatus.TRAINING
          ? "info"
          : status === DecisionEngineStatus.READY
            ? "success"
            : "subtle"
      }
    >
      {status === DecisionEngineStatus.TRAINING
        ? "Initializing"
        : status === DecisionEngineStatus.READY
          ? "Running"
          : "Pending"}
    </Badge>
  );
};
