import { Column, Row, SectionHeading, Switch, Text } from "@hightouchio/ui";

import { ConditionGroup } from "src/pages/decision-engines/collections/collection/components/filter";
import { useOutletContext } from "src/router";
import { FlowMessageContext } from "..";
import { useUser } from "src/contexts/user-context";

export const EligibilityLegacy = ({
  showToggle,
  setUseEligibilityV2,
}: {
  showToggle: boolean;
  setUseEligibilityV2: () => void;
}) => {
  const { engine } = useOutletContext<FlowMessageContext>();
  const { user } = useUser();

  return (
    <Column gap={4}>
      <Row justifyContent="space-between">
        <Column>
          <SectionHeading>Eligibility</SectionHeading>
          <Text>
            This message will only be sent to users who meet the following
            criteria
          </Text>
        </Column>
        {user?.is_impersonating && showToggle ? (
          <Row alignItems="center" gap={2}>
            <Switch
              aria-label="Use audience-based filtering."
              onChange={setUseEligibilityV2}
              isChecked={false}
            />
            <Text fontWeight="medium">Use audience-based-filtering</Text>
          </Row>
        ) : null}
      </Row>
      <ConditionGroup
        formKey="flowMessageConfig.userFilter"
        leftModelId={engine.config.feature_model_id}
        leftTable="users"
      />
    </Column>
  );
};
