import { ChannelType } from "@hightouch/lib/resource-monitoring/types";
import { ChatIcon, MailIcon } from "@hightouchio/ui";
import { capitalize } from "lodash";
import { FC } from "react";

import { WorkspaceNotificationChannelQuery } from "src/graphql";
import { PagerDutyIcon, SlackIcon } from "src/ui/icons";
import { EmailRecipientForm } from "./recipient-forms/email";
import { PagerdutyRecipientForm } from "./recipient-forms/pagerduty";
import { SlackRecipientForm } from "./recipient-forms/slack";
import { SmsRecipientForm } from "./recipient-forms/sms";
import { WebhookRecipientForm } from "./recipient-forms/webhook";
import { WebhookIcon } from "src/ui/icons/webhook";

export type Channel = NonNullable<
  WorkspaceNotificationChannelQuery["workspace_notification_channels_by_pk"]
>;

export const ChannelDefinition: Record<
  ChannelType,
  {
    // This is annoying af but our integration icons need to be separately sized
    icon: FC<any>;
    component: FC;
    testable?: boolean;
    configurable?: boolean;
    testText?: string;
    label?: string;
  }
> = {
  [ChannelType.Slack]: {
    icon: SlackIcon,
    component: SlackRecipientForm,
    testable: true,
    configurable: true,
    testText: "This will send a test message to your selected Slack channel.",
  },
  [ChannelType.PagerDuty]: {
    icon: PagerDutyIcon,
    component: PagerdutyRecipientForm,
    configurable: true,
    label: "PagerDuty",
  },
  [ChannelType.Sms]: {
    icon: ChatIcon,
    component: SmsRecipientForm,
    label: "SMS",
  },
  [ChannelType.Email]: {
    icon: MailIcon,
    testable: true,
    component: EmailRecipientForm,
    testText: "This will send a test email to your provided email address.",
  },
  [ChannelType.Webhook]: {
    icon: WebhookIcon,
    testable: true,
    component: WebhookRecipientForm,
    testText: "This will send a test HTTP POST request to your provided URL.",
  },
};

export function channelName(channel: Channel): string {
  const name =
    channel.config.name ||
    channel.config.slackChannelId ||
    capitalize(channel.config.severity) ||
    channel.config.emailAddress ||
    capitalize(channel.channel_type);

  if (channel.channel_type === ChannelType.Slack) {
    return `#${name}`;
  }

  return name;
}
