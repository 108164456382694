import { FC, useMemo } from "react";

import { Column, Row, Select, Switch, Text } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Controller, useFormContext } from "react-hook-form";

import {
  REFRESH_OPTIONS,
  RefreshIntervals,
} from "src/components/audiences/utils";
import type { MinimalTraitDefinition } from "src/components/explore/visual/utils";
import { getTraitPropertyType } from "src/components/explore/visual/utils";
import { ColumnType, FilterableColumn } from "src/types/visual";

type Props = {
  trait: MinimalTraitDefinition;
  filterableColumns: FilterableColumn[];
};

export const TraitSuggestionConfigForm: FC<Readonly<Props>> = ({
  filterableColumns,
  trait,
}) => {
  const { traitTopKEnabled } = useFlags();
  const { control, setValue, watch } = useFormContext();

  const topKEnabled = watch("top_k_enabled");

  const isStringTrait = useMemo(() => {
    return getTraitPropertyType(trait, filterableColumns) === ColumnType.String;
  }, [filterableColumns, trait]);

  return isStringTrait && traitTopKEnabled ? (
    <Column mt={6}>
      <Row align="center" justify="space-between">
        <Text fontWeight="medium">Trait value suggestions</Text>
        <Controller
          control={control}
          name="top_k_enabled"
          render={({ field }) => (
            <Switch
              aria-label="Enable trait value suggestions."
              size="md"
              isChecked={field.value}
              onChange={(value) => {
                field.onChange(value);
                setValue("top_k_sync_interval", {
                  defaultValue: value ? RefreshIntervals.ManualOnly : null,
                });
              }}
            />
          )}
        />
      </Row>
      <Column mb={2}>
        <Text color="text.secondary">
          Suggest commonly used trait values when using the audience builder.
        </Text>
      </Column>
      <Column>
        <Text fontWeight="medium" mb={2}>
          Refresh interval
        </Text>
        <Controller
          control={control}
          name="top_k_sync_interval"
          render={({ field }) => (
            <Select
              width="sm"
              isDisabled={!topKEnabled}
              options={REFRESH_OPTIONS}
              placeholder="Select an interval..."
              value={field.value ?? undefined}
              onChange={(value) => field.onChange(value)}
            />
          )}
        />
      </Column>
    </Column>
  ) : null;
};
