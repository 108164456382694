import { FC, useEffect } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  Dialog,
  FormField,
  Textarea,
  TextInput,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import capitalize from "lodash/capitalize";
import { Controller } from "react-hook-form";
import * as Yup from "yup";

import { Form, SaveButton, useHightouchForm } from "src/components/form";

export type SaveNameAndDescriptionModalForm = {
  name: string;
  description: string | null;
};

const schema = Yup.object()
  .shape({
    name: Yup.string().trim().required("Name is required"),
    description: Yup.string().trim().nullable(),
  })
  .required();

type Props = {
  type: "chart" | "view";
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: SaveNameAndDescriptionModalForm) => Promise<void>;
};

export const SaveNameAndDescriptionModal: FC<Props> = ({
  isOpen,
  type,
  onClose,
  onSubmit,
}) => {
  const form = useHightouchForm({
    onSubmit,
    success: `${capitalize(type)} saved!`,
    error: `Error saving ${type}`,
    defaultValues: {
      name: "",
      description: "",
    },
    resolver: yupResolver(schema),
  });

  // This modal won't dismount while the chart UI is open,
  // so reset the form on open/close to prevent showing old values
  useEffect(() => {
    if (isOpen) {
      form.reset();
    }
  }, [isOpen]);

  return (
    <Form form={form}>
      <Dialog
        title={`Save ${type}`}
        variant="form"
        isOpen={isOpen}
        onClose={onClose}
        width="lg"
        actions={
          <ButtonGroup>
            <Button isDisabled={form.formState.isSubmitting} onClick={onClose}>
              Cancel
            </Button>
            <SaveButton size="md">Save {type}</SaveButton>
          </ButtonGroup>
        }
      >
        <Column gap={4}>
          <Controller
            control={form.control}
            name="name"
            render={({ field, fieldState, formState }) => (
              <FormField
                label="Name"
                error={
                  formState.isSubmitted ? fieldState.error?.message : undefined
                }
              >
                <TextInput
                  placeholder="Enter a name"
                  width="100%"
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormField>
            )}
          />
          <Controller
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormField isOptional label="Description">
                <Textarea
                  placeholder="Enter a description"
                  width="100%"
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormField>
            )}
          />
        </Column>
      </Dialog>
    </Form>
  );
};
