import { FC, ReactNode, useEffect, useMemo, useRef, useState } from "react";

import {
  AudienceIcon,
  Badge,
  Box,
  Button,
  CloseIcon,
  Column,
  DraftIcon,
  Drawer,
  DrawerHeader,
  IconButton,
  Row,
  SearchInput,
  SectionHeading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from "@hightouchio/ui";
import { subDays } from "date-fns";
import { useVirtualizer } from "@tanstack/react-virtual";
import { capitalize } from "lodash";

import { LinkButton, RouterLink } from "src/router";
import { useUser } from "src/contexts/user-context";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import {
  DraftStatus,
  Maybe,
  useDraftsForUserQuery,
  useUpdateDraftsStatusMutation,
} from "src/graphql";
import { getSearchRegExp } from "src/utils/string";
import { formatFriendlyDistanceToNow } from "src/utils/time";
import { HTImage } from "src/components/image";
import placeholderImage from "src/assets/placeholders/generic.svg";

type MinimalDraft = {
  id?: string;
  resource_type: Maybe<string>;
  resource_id: Maybe<string>;
  created_by_user: Maybe<{
    id: string;
    name: string;
  }>;
  created_at?: Maybe<string>;
  applied_at?: Maybe<string>;
  applied_by_user?: Maybe<{
    id: string;
    name: string;
  }>;
  approval_requests?: {
    user: Maybe<{
      id: string;
      name: string;
    }>;
  }[];
  model: Maybe<{
    name: string;
    query_type: Maybe<string>;
    connection: Maybe<{
      definition: {
        icon: string;
        name: string;
      };
    }>;
  }>;
  sync: Maybe<{
    segment: Maybe<{
      name: string;
      connection: Maybe<{
        definition: {
          icon: string;
          name: string;
        };
      }>;
    }>;
    destination: Maybe<{
      name: string | null;
      definition: {
        icon: string;
        name: string;
      };
    }>;
  }>;
};

type DraftApproval = {
  id?: string;
  draft: MinimalDraft | null;
};

export const Drafts = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [since] = useState(subDays(new Date(), 30).toISOString()); // Show recently approved drafts from the last 30 days
  const { user } = useUser();
  const [search, setSearch] = useState("");
  const [tabIndex, setTabIndex] = useState(0);

  const onTabChange = (index: number) => {
    setTabIndex(index);
    setSearch("");
  };

  const { data } = useDraftsForUserQuery(
    { userId: String(user?.id), since },
    {
      refetchOnMount: "always",
      select: (data) => {
        // Ensure that only drafts with resources that still exist are shown
        return {
          yourDrafts: data.yourDrafts.filter(validateDraft),
          draftApprovals: data.draftApprovals.filter(
            (approval) => approval.draft && validateDraft(approval.draft),
          ),
          recentlyApproved: data.recentlyApprovedDrafts.filter(validateDraft),
        };
      },
    },
  );

  const countCircle = useMemo(() => {
    return getCountCircle(data?.draftApprovals.length);
  }, [data?.draftApprovals.length]);

  useEffect(() => {
    // Reset state when the drawer is closed
    setSearch("");
    setTabIndex(0);
  }, [isOpen]);

  return (
    <>
      <Box
        icon={DraftIcon}
        as={Button}
        variant="secondary"
        onClick={onOpen}
        _after={countCircle}
      >
        Drafts
      </Box>
      <Drawer isOpen={isOpen} onClose={onClose} size="md">
        <DrawerHeader>
          <Row justify="space-between" align="center" flex={1}>
            <Row gap={2} align="center">
              <SectionHeading>Drafts</SectionHeading>
            </Row>
            <IconButton
              aria-label="Close"
              icon={CloseIcon}
              size="sm"
              onClick={onClose}
            />
          </Row>
        </DrawerHeader>
        <Column
          minH={0}
          as={Tabs}
          index={tabIndex}
          // @ts-expect-error - as is not typed correctly
          onChange={onTabChange}
        >
          <Box as={TabList} px={6}>
            <Tab count={data?.draftApprovals?.length}>Needs your review</Tab>
            <Tab count={data?.yourDrafts?.length}>Your drafts</Tab>
            <Tab>Recently approved</Tab>
          </Box>
          <Row gap={2} borderBottom="1px" borderColor="base.border" p={4}>
            <SearchInput
              width="100%"
              value={search}
              placeholder="Search by resource name..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </Row>
          {!data ? (
            <Spinner size="lg" mx="auto" mt={6} />
          ) : (
            <Column as={TabPanels} minH={0}>
              <Column as={TabPanel} p={0} minH={0}>
                <NeedsYourReview
                  draftApprovals={data.draftApprovals}
                  search={search}
                />
              </Column>
              <Column as={TabPanel} p={0} minH={0}>
                <YourDrafts drafts={data.yourDrafts} search={search} />
              </Column>
              <Column as={TabPanel} p={0} minH={0}>
                <RecentlyApproved
                  drafts={data.recentlyApproved}
                  search={search}
                />
              </Column>
            </Column>
          )}
        </Column>
      </Drawer>
    </>
  );
};

const filterDraft = (draft: MinimalDraft, search: string) => {
  const searchRegex = getSearchRegExp(search, "i");
  if (draft.resource_type === "model") {
    return draft.model?.name && searchRegex.test(draft.model.name);
  }
  if (draft.resource_type === "sync") {
    return (
      (draft.sync?.destination?.name &&
        searchRegex.test(draft.sync.destination.name)) ||
      (draft.sync?.destination?.definition?.name &&
        searchRegex.test(draft.sync.destination.definition.name)) ||
      (draft.sync?.segment?.name && searchRegex.test(draft.sync.segment.name))
    );
  }
  return false;
};

const resourceLabelHeight = 20;

const NeedsYourReview = ({
  draftApprovals = [],
  search,
}: {
  draftApprovals: Array<DraftApproval>;
  search: string;
}) => {
  const filteredDraftApprovals = useMemo(
    () =>
      draftApprovals.filter((approval) => filterDraft(approval.draft!, search)),
    [draftApprovals, search],
  );

  const baseHeight = 133;

  return (
    <VirtualList
      estimateSize={(index) => {
        const draftApproval = filteredDraftApprovals[index]!;
        return draftApproval.draft!.resource_type === "sync"
          ? baseHeight + resourceLabelHeight
          : baseHeight;
      }}
      items={filteredDraftApprovals}
      render={(draftApproval) => <DraftRequest draft={draftApproval.draft!} />}
      placeholder={
        search
          ? "No drafts match your search."
          : "You have no drafts awaiting your review."
      }
    />
  );
};

const YourDrafts = ({
  drafts = [],
  search,
}: {
  drafts: MinimalDraft[];
  search: string;
}) => {
  const filteredDrafts = useMemo(
    () => drafts.filter((draft) => filterDraft(draft, search)),
    [drafts, search],
  );

  const baseHeight = 97;

  return (
    <VirtualList
      items={filteredDrafts}
      estimateSize={(index) => {
        const draft = filteredDrafts[index]!;
        return draft.resource_type === "sync"
          ? baseHeight + resourceLabelHeight
          : baseHeight;
      }}
      render={(draft) => <YourDraft draft={draft} />}
      placeholder={
        search ? "No drafts match your search." : "You have no drafts."
      }
    />
  );
};

const RecentlyApproved = ({
  drafts = [],
  search,
}: {
  drafts: MinimalDraft[];
  search: string;
}) => {
  const filteredDrafts = useMemo(
    () => drafts.filter((draft) => filterDraft(draft, search)),
    [drafts, search],
  );

  const baseHeight = 97;

  return (
    <VirtualList
      items={filteredDrafts}
      estimateSize={(index) => {
        const draft = filteredDrafts[index]!;
        return draft.resource_type === "sync"
          ? baseHeight + resourceLabelHeight
          : baseHeight;
      }}
      render={(draft) => <ApprovedDraft draft={draft} />}
      placeholder={
        search
          ? "No drafts match your search."
          : "You have no recently approved drafts."
      }
    />
  );
};

function VirtualList<T>({
  items,
  render,
  placeholder,
  estimateSize,
}: {
  items: T[];
  render: (item: T) => ReactNode;
  placeholder: ReactNode;
  estimateSize: (index: number) => number;
}) {
  const containerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    getScrollElement: () => containerRef.current,
    estimateSize,
    overscan: 10,
  });

  if (!items.length) {
    return (
      <Column align="center" p={10} gap={6}>
        <HTImage src={placeholderImage} alt="Empty box." />
        <Text color="text.secondary">{placeholder}</Text>
      </Column>
    );
  }

  return (
    <Box ref={containerRef} overflow="auto" h="100%">
      <Box height={`${rowVirtualizer.getTotalSize()}px`} position="relative">
        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
          const item = items[virtualItem.index]!;
          return (
            <Box
              w="100%"
              key={virtualItem.index}
              position="absolute"
              top={0}
              left={0}
              transform={`translateY(${virtualItem.start}px)`}
            >
              {render(item)}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

function draftLink(resourceType: string, resourceId: string): string {
  switch (resourceType) {
    case "sync":
      return `/syncs/${resourceId}/draft`;
    case "model":
      return `/models/${resourceId}/draft`;
    case "audience":
      return `/audiences/${resourceId}/draft`;
    default:
      return "";
  }
}

const YourDraft: FC<{
  draft: MinimalDraft;
}> = ({ draft }) => {
  const href = draftLink(draft.resource_type!, draft.resource_id!);

  const isPending =
    draft.approval_requests && draft.approval_requests.length > 0;

  const pendingApprovers = draft.approval_requests
    ?.map(({ user }) => user?.name ?? "[Removed user]")
    .filter(Boolean)
    .join(", ");

  return (
    <Column
      as={RouterLink}
      to={href}
      borderBottom="1px"
      borderColor="base.border"
      _hover={{ bg: "gray.100" }}
      p={4}
    >
      <Row justify="space-between" align="flex-start" mb={2}>
        <Column>
          <TextWithTooltip fontWeight="medium">
            {isPending
              ? `Approval requested from ${pendingApprovers}`
              : `${capitalize(draft.resource_type!)} draft created`}
          </TextWithTooltip>
          <Text size="sm" color="text.tertiary">
            {formatFriendlyDistanceToNow(draft.created_at!)}
          </Text>
        </Column>
        {isPending ? (
          <Badge size="sm" variant="warning">
            Awaiting approval
          </Badge>
        ) : (
          <Badge size="sm">Not submitted</Badge>
        )}
      </Row>
      <ResourceLabel draft={draft} />
    </Column>
  );
};

const DraftRequest: FC<{
  draft: MinimalDraft;
}> = ({ draft }) => {
  const { toast } = useToast();
  const approveDraftMutation = useUpdateDraftsStatusMutation();

  const href = draftLink(draft.resource_type!, draft.resource_id!);

  return (
    <Column borderBottom="1px" borderColor="base.border" p={4}>
      <TextWithTooltip fontWeight="medium">
        {`${capitalize(draft.resource_type!)} draft submitted by ${draft.created_by_user?.name ?? "[Removed user]"}`}
      </TextWithTooltip>
      <Text size="sm" color="text.tertiary" mb={2}>
        {formatFriendlyDistanceToNow(draft.created_at!)}
      </Text>
      <ResourceLabel draft={draft} />

      <Row gap={2} align="center" mt={3}>
        <Button
          size="sm"
          variant="primary"
          onClick={async () => {
            try {
              await approveDraftMutation.mutateAsync({
                draftIds: [draft.id!],
                status: DraftStatus.Approved,
              });
              toast({
                id: "approve-draft",
                title: "Draft approved",
                variant: "success",
              });
            } catch {
              toast({
                id: "approve-draft",
                title: "Couldn't approve this draft",
                variant: "error",
              });
            }
          }}
          isLoading={approveDraftMutation.isLoading}
        >
          Approve
        </Button>
        <LinkButton
          // @ts-expect-error - size is not typed correctly
          size="sm"
          href={href}
          isExternal
        >
          View details
        </LinkButton>
      </Row>
    </Column>
  );
};

const ApprovedDraft: FC<{
  draft: MinimalDraft;
  approver?: string;
}> = ({ draft }) => {
  const href = draftLink(draft.resource_type!, draft.resource_id!);
  return (
    <Column
      as={RouterLink}
      to={href}
      borderBottom="1px"
      borderColor="base.border"
      _hover={{ bg: "base.background" }}
      p={4}
      gap={2}
    >
      <Row justify="space-between" align="flex-start" gap={2}>
        <Column>
          <TextWithTooltip fontWeight="medium">
            {`${capitalize(draft.resource_type!)} draft approved by
           ${draft.applied_by_user?.name ?? "[Removed user]"}`}
          </TextWithTooltip>
          <Text size="sm" color="text.tertiary">
            {formatFriendlyDistanceToNow(draft.applied_at!)}
          </Text>
        </Column>
        <Badge size="sm" variant="success">
          Approved
        </Badge>
      </Row>

      <ResourceLabel draft={draft} />
    </Column>
  );
};

const ResourceLabel: FC<{
  draft: MinimalDraft;
}> = ({ draft }) => {
  if (draft.resource_type === "model" && draft.model) {
    return (
      <Row align="center" overflow="hidden" gap={1}>
        {draft.model.query_type === "visual" ? (
          <Box as={AudienceIcon} />
        ) : draft.model.connection ? (
          <IntegrationIcon
            size={4}
            src={draft.model.connection.definition?.icon}
            name={draft.model.connection.definition?.name}
          />
        ) : null}
        <TextWithTooltip>{draft.model.name}</TextWithTooltip>
      </Row>
    );
  }
  if (draft.resource_type === "sync" && draft.sync) {
    return (
      <Column>
        <Row align="center" overflow="hidden" gap={1}>
          {draft.sync.segment && draft.sync.segment.connection && (
            <IntegrationIcon
              size={4}
              src={draft.sync.segment.connection.definition?.icon}
              name={draft.sync.segment.connection.definition?.name}
            />
          )}
          <TextWithTooltip>{draft.sync.segment?.name}</TextWithTooltip>
        </Row>
        <Row align="center" overflow="hidden" gap={1}>
          {draft.sync.destination?.definition?.icon && (
            <IntegrationIcon
              size={4}
              src={draft.sync.destination.definition.icon}
              name={draft.sync.destination.definition.name}
            />
          )}
          <TextWithTooltip>
            {draft.sync.destination?.name ??
              draft.sync.destination?.definition?.name}
          </TextWithTooltip>
        </Row>
      </Column>
    );
  }
  return null;
};

const getCountCircle = (count: number | undefined | null) => {
  if (!count) {
    return;
  }
  if (count > 99) {
    return {
      content: `"99+"`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      w: "28px",
      h: "28px",
      bg: "danger.200",
      color: "danger.700",
      borderRadius: "full",
      fontSize: "12px",
      fontWeight: "normal",
      pos: "absolute",
      right: -3,
      top: -3,
    };
  }
  return {
    content: `"${count}"`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    w: "20px",
    h: "20px",
    bg: "danger.200",
    color: "danger.700",
    pos: "absolute",
    right: -2,
    top: -2,
    borderRadius: "full",
    fontSize: "12px",
    fontWeight: "normal",
  };
};

// Make sure that the resource for the draft still exists
const validateDraft = (draft: {
  resource_type: string | null;
  resource_id: string | null;
  sync: unknown;
  model: unknown;
}) => {
  return draft.resource_type === "sync"
    ? Boolean(draft.sync)
    : draft.resource_type === "model"
      ? Boolean(draft.model)
      : false;
};
