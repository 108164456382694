import { RouteObject } from "src/router";

import { Analytics } from "src/pages/analytics";

export const routes: Array<RouteObject> = [
  {
    path: "analytics",
    element: <Analytics />,
  },
  {
    path: "analytics/:id",
    element: <Analytics />,
  },
];
