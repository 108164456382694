import * as yup from "yup";
import type { FormkitForm, FormkitNode, FormkitSection } from "../../api";
import { Input, RadioGroup, TextArea } from "../components";
import { AccordionSection, Field, Form, Section } from "../layouts";
import {
  showIfKeyEqualsValue,
  showIfKeyIsTruthy,
  switchOnStateKey,
} from "../modifiers";
import {
  BomSection,
  concurrencySection,
  CsvHeaderSection,
  DelimiterSection,
  FileModeSection,
  FilePathSection,
  FileTypeSection,
  MappingsSection,
  ParquetMappingsSection,
  RowDelimiterSection,
  SkipEmptySection,
  TimestampOffsetSection,
} from "../sections";
import * as Yup from "yup";
const liquidTemplateLanguage =
  "[Liquid template language](https://shopify.github.io/liquid/basics/introduction/)";

const batchingSection = () => {
  return [
    Section({
      heading: "Would you like to enable file batching?",
      subheading:
        "Batching may send more than 1 file to {{ destinationDefinition.name | default: 'your destination' }} based on the number of rows queried from your model. This may help avoid file size errors with {{ destinationDefinition.name | default: 'your destination' }}.",
      children: [
        RadioGroup("enableBatching", {
          options: [
            {
              label: "No",
              value: undefined,
            },
            {
              label: "Yes",
              value: true,
            },
          ],
        }),
      ],
    }),
    showIfKeyIsTruthy({
      key: "enableBatching",
      children: [
        Section({
          heading: "How many rows should be sent in each file?",
          subheading:
            "This is the maximum number of rows each output file will have. If your model has more rows than the provided number, Hightouch will split the model's query results into multiple files. Defaults to 10,000.",
          children: [
            Input("batchSize", {
              type: "number",
              validation: yup.number().positive(),
              placeholder: "10000",
            }),
          ],
        }),
        Section({
          heading:
            "How would you like to name the sequential files created via batching?",
          subheading: "This value will be added prior to the file suffix.",
          children: [
            RadioGroup("batchingFilenameDetails", {
              options: [
                {
                  label: "Batch number",
                  value: "batch",
                  description:
                    "Example: filename_1.{{ formState.fileType | default: 'csv' }} for the first batch.",
                },
                {
                  label: "Row range",
                  value: "range",
                  description:
                    "Example: `filename_1-100.{{ formState.fileType | default: 'csv' }}` for the first 100 rows.",
                },
                {
                  label: "Batch N of N",
                  value: "n_of_n",
                  description:
                    "Example: `filename_batch-1-of-500.{{ formState.fileType | default: 'csv' }}` for the first batch of 500 total batches.",
                },
              ],
              default: "batch",
            }),
          ],
        }),
      ],
    }),
  ];
};

const gzipSection = () =>
  Section({
    heading: "Would you like to enable gzip compression?",
    subheading:
      "This compresses your output file using gzip. " +
      "Adding `.gz` to your file name is recommended, though not required.",
    children: [
      RadioGroup("enableGzip", {
        options: [
          {
            label: "No",
            value: undefined,
          },
          {
            label: "Yes",
            value: true,
          },
        ],
      }),
    ],
  });

const fileIndicatorSection = () =>
  showIfKeyEqualsValue({
    key: "mode",
    value: "all",
    children: [
      showIfKeyIsTruthy({
        key: "enableBatching",
        children: [
          Section({
            heading:
              "Would you like to write an empty file after all files have been written to indicate completion?",
            subheading: `If left empty, no indicator file will be written. You can use the same template variables available for the file name. 
            An indicator file will not be written in the case of a sync failure or if the query result is empty.`,
            optional: true,
            children: [
              Input("indicatorFileName", {
                placeholder: "Enter a filename...",
                validation: Yup.string().nullable().notRequired(),
              }),
            ],
          }),
        ],
      }),
    ],
  });

const rowDelimiterSection = RowDelimiterSection();
const delimiterSection = (props) => DelimiterSection(props.delimiterOptions);
const csvHeaderSection = (props) => CsvHeaderSection(props.csvHeaderOptions);
const bomSection = BomSection();
const csvFormatOptions = (props) =>
  showIfKeyEqualsValue({
    key: "fileType",
    value: "csv",
    children: [
      Section({
        heading: "CSV format options",
        children: [
          delimiterSection(props),
          rowDelimiterSection,
          csvHeaderSection(props),
          bomSection,
        ],
      }),
    ],
  });

const advancedConfigsHiddenByDefault = [
  "enableBatching",
  "hideSkipEmptySection",
  "enableGzipSection",
  "writeIndicatorFile",
];
const advancedSection = (props: FileStorageFormProps) => {
  if (
    !Object.entries(props).some(
      ([prop, value]) =>
        advancedConfigsHiddenByDefault.includes(prop) && Boolean(value),
    ) &&
    props.disableCSVFormatOptions
  ) {
    return [];
  }
  return [
    AccordionSection({
      children: [
        ...(props.disableCSVFormatOptions ? [] : [csvFormatOptions(props)]),
        ...(props.enableBatching ? batchingSection() : []),
        ...(props.hideSkipEmptySection ? [] : [SkipEmptySection()]),
        ...(props.writeIndicatorFile ? [fileIndicatorSection()] : []),
        ...(props.enableGzipSection ? [gzipSection()] : []),
        ...(props.concurrencySection ? [concurrencySection()] : []),
      ],
    }),
  ];
};

export const FILE_TYPES = {
  csv: "CSV",
  json: "JSON",
  ndjson: "NDJSON (JSON Lines)",
  parquet: "Parquet",
  xml: "XML",
} as const;

export type FileType = keyof typeof FILE_TYPES;

type FileStorageFormProps = {
  modeOptions?: { options?: any };
  idSections?: FormkitSection[];
  /**
   * Enter the supported fileTypes.
   */
  types: [FileType, ...FileType[]];
  directorySection?: FormkitSection[];
  filePathOptions?: {
    heading?: string;
    subheading?: string;
    fieldName?: string;
    fileType?: string;
  };
  timestampOptions?: {
    default?: any;
  };
  fileHandlingSections?: FormkitSection[];
  disableCSVFormatOptions?: boolean;
  delimiterOptions?: { options?: any };
  csvHeaderOptions?: { default?: boolean };
  disableMappings?: boolean;
  /**
   * Allows user to opt-in for batching.
   * Batching will sync the file into multiple smaller batch.
   * This is automatically handled by the framework.
   */
  enableBatching?: boolean;
  enableGzipSection?: boolean;
  /**
   * If true, the form will not include the `skipEmpty` section.
   * Setting this to true also means that the syncs will by default send empty files.
   */
  hideSkipEmptySection?: boolean;

  writeIndicatorFile?: boolean;

  existingFileSection?: { key: string; children: FormkitNode[] };

  concurrencySection?: boolean;
};

/**
 * FileStorage Formkit Generator.
 * @param props
 * @returns
 */
export function FileStorageFormGenerator(
  props: FileStorageFormProps,
): FormkitForm {
  const modeSection = FileModeSection(props?.modeOptions);
  const timestampOffsetSection = TimestampOffsetSection(
    props?.timestampOptions,
  );

  const mappings = (fileType: FileType) => {
    const mappingsSectionToUse =
      fileType === "parquet"
        ? ParquetMappingsSection()
        : MappingsSection({
            allEnabled: true,
            autoSyncColumnsDefault: true,
          });
    return props?.disableMappings ? [] : [mappingsSectionToUse];
  };

  const directorySection = props?.directorySection ?? [];
  const jsonSection = [
    ...directorySection,
    FilePathSection({
      ...(props?.filePathOptions ?? {}),
      fileType: "json",
    }),
    timestampOffsetSection,
    ...(props?.fileHandlingSections ?? []),
    ...mappings("json"),
  ];
  const ndjsonSection = [
    ...directorySection,
    FilePathSection({
      ...(props?.filePathOptions ?? {}),
      fileType: "ndjson",
    }),
    timestampOffsetSection,
    ...(props?.fileHandlingSections ?? []),
    ...mappings("ndjson"),
  ];

  const csvSection = [
    ...directorySection,
    FilePathSection({
      ...(props?.filePathOptions ?? {}),
      fileType: "csv",
    }),
    timestampOffsetSection,
    ...(props?.fileHandlingSections ?? []),
    ...mappings("csv"),
  ];

  const parquetSection = [
    ...directorySection,
    FilePathSection({
      ...(props?.filePathOptions ?? {}),
      fileType: "parquet",
    }),
    timestampOffsetSection,
    ...(props?.fileHandlingSections ?? []),
    ...mappings("parquet"),
  ];
  const xmlSection = [
    ...directorySection,
    FilePathSection({
      ...(props?.filePathOptions ?? {}),
      fileType: "xml",
    }),
    timestampOffsetSection,
    ...(props?.fileHandlingSections ?? []),
    Section({
      heading: "Define your XML payload",
      subheading: `Use the text area below to construct your XML payload. Hightouch supports the ${liquidTemplateLanguage} for injecting variable, control flows, loops, and more.`,
      ...props,
      children: [
        Field({
          heading: "XML header",
          component: TextArea("xmlHeader", {
            placeholder: "<CreateCustomerList>",
            validation: Yup.string().required(),
            style: "editor",
          }),
        }),
        Field({
          heading: "XML body",
          subheading:
            "This template is rendered for each row and then combined together.",
          component: TextArea("xmlBody", {
            placeholder: `<CreateCustomer>
    <Name>{{ row.full_name }}</Name>
    <Location>{{ row.location }}</Location>
</CreateCustomer>`,
            validation: Yup.string().required(),
            style: "editor",
          }),
        }),
        Field({
          heading: "XML footer",
          component: TextArea("xmlFooter", {
            placeholder: "</CreateCustomerList>",
            validation: Yup.string().required(),
            style: "editor",
          }),
        }),
      ],
    }),
  ];

  const sections: { [key in FileType]: FormkitNode[] } = {
    json: jsonSection,
    ndjson: ndjsonSection,
    csv: csvSection,
    parquet: parquetSection,
    xml: xmlSection,
  };

  const fileTypes = props.types;
  const typeSection = FileTypeSection({
    options: fileTypes.map((type) => ({
      label: FILE_TYPES[type],
      value: type,
    })),
  });
  // Only include the FileType section if multiple file type is supported.
  const fileTypeSection = fileTypes.length > 1 ? [typeSection] : [];
  const fileBuildingSections = fileTypes.map((type) => {
    return {
      key: type,
      section: sections[type],
    };
  });

  let mainSection;
  if (fileTypes.length > 1) {
    mainSection = [
      switchOnStateKey(
        "fileType",
        fileBuildingSections.map((section) => {
          return {
            value: section.key,
            children: section.section,
          };
        }),
      ),
    ];
  } else if (fileTypes.includes("csv")) {
    mainSection = csvSection;
  } else if (fileTypes.includes("json")) {
    mainSection = jsonSection;
  } else if (fileTypes.includes("parquet")) {
    mainSection = parquetSection;
  } else if (fileTypes.includes("ndjson")) {
    mainSection = ndjsonSection;
  }

  const fileCreationSection = props?.existingFileSection?.key
    ? [
        Section({
          heading: "Would you like to sync to an existing file?",
          children: [
            RadioGroup(props.existingFileSection.key, {
              default: false,
              options: [
                {
                  label: "No",
                  value: false,
                },
                {
                  label: "Yes",
                  value: true,
                },
              ],
            }),
          ],
        }),
        switchOnStateKey(props.existingFileSection.key, [
          {
            value: true,
            children: [
              ...(props?.existingFileSection?.children || []),
              ...fileTypeSection,
            ],
          },
          { value: false, children: [...fileTypeSection, ...mainSection] },
        ]),
      ]
    : [...fileTypeSection, ...mainSection];

  return Form({
    children: [
      modeSection,
      ...(props?.idSections ?? []),
      ...fileCreationSection,
      ...advancedSection(props),
    ],
  });
}
