import { FC } from "react";

import { Badge, Box, CloseIcon, Row } from "@hightouchio/ui";

import {
  formatValue,
  getOperatorLabel,
  getPropertyNameFromColumn,
} from "src/components/explore/visual/utils";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  AndCondition,
  ConditionType,
  PropertyCondition,
} from "src/types/visual";
import { useCampaignsFilterContext } from "./hook";

function parseMetricFilter(condition: AndCondition) {
  const { predefinedMetrics, conversionMetrics } = useCampaignsFilterContext();

  const comparisonCondition = condition.conditions.find(
    (condition) =>
      condition.type === ConditionType.Property &&
      condition.property === "attribution_value",
  ) as PropertyCondition | undefined;

  const goalCondition = condition.conditions.find(
    (condition) =>
      condition.type === ConditionType.Property &&
      condition.property === "goal_id",
  ) as PropertyCondition | undefined;

  const metric =
    predefinedMetrics.find((metric) => metric.id === goalCondition?.value) ||
    conversionMetrics.find((metric) => metric.id === goalCondition?.value);

  if (!comparisonCondition || !goalCondition || !metric) {
    throw new Error("Missing attribution_value or goal_id condition");
  }

  return {
    comparisonCondition,
    metric,
  };
}

const MetricConditionText: FC<{
  condition: AndCondition;
}> = ({ condition }) => {
  const { comparisonCondition, metric } = parseMetricFilter(condition);

  return (
    <>
      {metric?.name}
      <TextWithTooltip color="text.secondary">
        {getOperatorLabel(
          comparisonCondition.operator,
          comparisonCondition.propertyType,
        )}
      </TextWithTooltip>
      {formatValue(comparisonCondition, comparisonCondition.value)}
    </>
  );
};

const PropertyConditionText: FC<{
  condition: PropertyCondition;
}> = ({ condition }) => {
  return (
    <>
      {getPropertyNameFromColumn(condition.property)}
      <TextWithTooltip color="text.secondary">
        {getOperatorLabel(condition.operator, condition.propertyType)}
      </TextWithTooltip>
      {formatValue(condition, condition.value)}
    </>
  );
};

export const FilterTag: FC<{
  condition: AndCondition | PropertyCondition;
  onRemove: () => void;
}> = ({ condition, onRemove }) => {
  return (
    <Badge>
      <Row as="span" gap={2} alignItems="center">
        <TextWithTooltip>
          <Row gap={1}>
            {condition.type === ConditionType.Property && (
              <PropertyConditionText condition={condition} />
            )}
            {condition.type === ConditionType.And && (
              <MetricConditionText condition={condition} />
            )}
          </Row>
        </TextWithTooltip>

        <Box
          as="button"
          aria-label="remove filter"
          cursor="pointer"
          textColor="text.secondary"
          fontSize="13"
          _focus={{
            outline: "none",
          }}
          _focusVisible={{
            boxShadow: "outline",
          }}
          onClick={onRemove}
        >
          <CloseIcon />
        </Box>
      </Row>
    </Badge>
  );
};
