import { useEffect } from "react";
import * as Sentry from "@sentry/react";
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useLocation, matchRoutes } from "react-router-dom";

import { jsonSlugRoutes } from "./json-slug-routes";
import { SlugRoutes } from "./slug-routes";

// Hook to set transaction name in sentry
export function useUpdateSentryTransactionNamesForWorkspaceRoutes() {
  const location = useLocation();

  const matchedRoutes = matchRoutes(
    [
      {
        path: "/:root_workspace_slug/*",
        children: jsonSlugRoutes.concat({
          path: "*",
          element: SlugRoutes(),
        }),
      },
    ],
    location.pathname,
  );

  useEffect(() => {
    // First entry is the * match for :root_workspace_slug
    // If there's a second entry, it's a more specific match
    let pathMatch = matchedRoutes?.[1]?.route.path;

    if (!pathMatch?.startsWith("/")) {
      pathMatch = `/${pathMatch}`;
    }

    if (pathMatch) {
      Sentry.getCurrentScope().setTransactionName(pathMatch);
    }
  }, [matchedRoutes, location]);
}
