import { FC, useMemo } from "react";

import {
  Alert,
  Button,
  ButtonGroup,
  ChevronLeftIcon,
  Column,
  EditableText,
  FilterMenu,
  FilterMenuButton,
  FilterMenuGroup,
  FilterMenuList,
  FilterMenuOption,
  MeetingIcon,
  Badge,
  Row,
  Select,
  SortVerticalIcon,
  TableIcon,
  Text,
} from "@hightouchio/ui";
import partition from "lodash/partition";
import pluralize from "pluralize";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { isPresent } from "ts-extras";

import { DiscardButton } from "src/components/form";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { AttributionTimeWindow } from "src/types/visual";

import { OrderByCampaignColumnType, OrderByDirection } from "src/graphql";
import { Delimiter, TimeWindowOptions } from "./constants";
import { FilterPicker } from "./filters/filter-picker";
import { FilterTag } from "./filters/filter-tag";
import { useCampaignsFilterContext } from "./filters/hook";
import { useAssetType } from "./filters/use-campaign-page";
import {
  fromConversionMetricKey,
  toConversionMetricKey,
} from "./filters/utils";
import { FilterState, RequiredAssetModelFields } from "./types";
import { AssetType } from "src/types/visual";
import { getAssetNoun } from "./utils";

const ResultsHeading: FC<{
  assetType: AssetType;
  numResults: number;
  isPolling: boolean;
}> = ({ assetType, numResults, isPolling }) => {
  if (isPolling) {
    return (
      <Text size="lg" fontWeight="semibold">
        Processing...
      </Text>
    );
  }

  return (
    <Text size="lg" fontWeight="semibold">
      {pluralize(getAssetNoun(assetType), numResults, true)}
    </Text>
  );
};

type CampaignFiltersProps = {
  assetModel: RequiredAssetModelFields;
};

export const CampaignFilters: FC<CampaignFiltersProps> = () => {
  const assetType = useAssetType();
  const {
    data,
    assetModel,
    assetModelColumns,
    isArchived,
    isCampaignsPageLoading,
    isPolling,
    isSavedView,
    isSavedViewLoading,
    openSavedViewsDrawer: onOpenSavedViewsDrawer,
    openCampaignViewModal: onSaveCampaignView,
    conversionMetrics,
    predefinedMetrics,
  } = useCampaignsFilterContext();

  const form = useFormContext<FilterState>();

  const {
    fields: assetModelFilters,
    remove: removeAssetModelFilter,
    append: appendAssetModelFilter,
  } =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - no circular types until react-hook-form v8
    useFieldArray({
      control: form.control,
      name: "assetModelFilters",
    });

  const {
    fields: metricFilters,
    remove: removeMetricFilter,
    append: appendMetricFilter,
  } = useFieldArray({
    control: form.control,
    name: "metricFilters",
  });

  const {
    selectedConversionMetrics,
    selectedPredefinedMetrics,
    selectedColumns,
    timeWindow,
  } = form.watch();

  const noSelectedConversionMetrics = selectedConversionMetrics.length === 0;
  const noSelectedPredefinedMetricsSelected =
    selectedPredefinedMetrics.length === 0;

  const showConversionMetricWarning =
    assetModel &&
    !isCampaignsPageLoading &&
    (predefinedMetrics.length > 0 || conversionMetrics.length > 0) &&
    noSelectedConversionMetrics &&
    noSelectedPredefinedMetricsSelected;

  const selectedOptionsLength =
    selectedColumns.length +
    selectedPredefinedMetrics.length +
    selectedConversionMetrics.length;

  const predefinedMetricIds = new Set(
    predefinedMetrics.map((metric) => metric.id),
  );

  const standardColumnSelections = useMemo(() => {
    return selectedColumns
      .map((col) => col.name)
      .concat(selectedPredefinedMetrics.map((metric) => metric.id));
  }, [selectedColumns, selectedPredefinedMetrics]);

  return (
    <>
      <Column
        position="sticky"
        zIndex={2}
        left={0}
        top={0}
        px={6}
        pt={4}
        gap={4}
        bg="white"
      >
        <Row justifyContent="space-between">
          <Row gap={2} alignItems="center" flexWrap="wrap">
            <FilterPicker
              onAddAssetFilter={(condition) =>
                appendAssetModelFilter(condition)
              }
              onAddMetricFilter={(condition) => appendMetricFilter(condition)}
            />
            {assetModelFilters.map((condition, index) => (
              <FilterTag
                condition={condition}
                onRemove={() => removeAssetModelFilter(index)}
                key={`${JSON.stringify(condition)}-${index}`}
              />
            ))}
            {metricFilters.map((condition, index) => (
              <FilterTag
                condition={condition}
                onRemove={() => removeMetricFilter(index)}
                key={`${JSON.stringify(condition)}-${index}`}
              />
            ))}
          </Row>
          <ButtonGroup>
            <Button
              size="md"
              onClick={onSaveCampaignView}
              isDisabled={
                form.formState.isSubmitting ||
                (isSavedView && !form.formState.isDirty)
              }
              isLoading={form.formState.isSubmitting || isSavedViewLoading}
              variant="primary"
            >
              Save view
            </Button>
            {isSavedView && <DiscardButton size="md" />}
            <Button
              directionIcon={ChevronLeftIcon}
              variant="secondary"
              onClick={onOpenSavedViewsDrawer}
            >
              Views
            </Button>
          </ButtonGroup>
        </Row>
        {showConversionMetricWarning && (
          <Alert
            type="warning"
            title="No metrics selected"
            message="At least one conversion metric must be selected. Select one in the columns menu."
          />
        )}
        <Row align="center" justifyContent="space-between">
          <Row align="center" gap={1}>
            {isArchived && <Badge mr={2}>Archived</Badge>}
            <ResultsHeading
              isPolling={isPolling}
              assetType={assetType}
              numResults={data?.paginationResults.totalCount ?? 0}
            />
            {isSavedView && !isSavedViewLoading && (
              <>
                <Text size="lg" fontWeight="semibold">
                  •
                </Text>
                <Controller
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <EditableText
                      size="lg"
                      fontWeight="semibold"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </>
            )}
          </Row>

          <Row gap="2">
            <FilterMenu>
              <FilterMenuButton icon={SortVerticalIcon}>Sort</FilterMenuButton>
              <Controller
                control={form.control}
                name="orderBy"
                render={({ field: orderByField }) => (
                  <FilterMenuList maxH="60vh" overflowY="auto">
                    <FilterMenuGroup
                      title="Standard columns"
                      type="radio"
                      value={
                        orderByField.value?.assetColumn ||
                        orderByField.value?.goalId ||
                        ""
                      }
                      onChange={(orderBy) => {
                        const isAssetColumn = Boolean(
                          assetModelColumns.find((col) => col.name === orderBy),
                        );

                        orderByField.onChange({
                          assetColumn: isAssetColumn ? orderBy : undefined,
                          goalId: !isAssetColumn ? orderBy : undefined,
                          type: isAssetColumn
                            ? OrderByCampaignColumnType.Asset
                            : OrderByCampaignColumnType.Metric,
                          direction: OrderByDirection.Desc,
                        });
                      }}
                    >
                      {assetModelColumns.map((column) => (
                        <FilterMenuOption key={column.name} value={column.name}>
                          {column.alias ?? column.name}
                        </FilterMenuOption>
                      ))}
                      {predefinedMetrics.map((predefinedMetric) => (
                        <FilterMenuOption
                          key={predefinedMetric.id}
                          value={predefinedMetric.id}
                        >
                          <TextWithTooltip>
                            <>
                              {predefinedMetric.name}
                              {predefinedMetric.description && (
                                <Text color="text.secondary">
                                  {` / ${predefinedMetric.description}`}
                                </Text>
                              )}
                            </>
                          </TextWithTooltip>
                        </FilterMenuOption>
                      ))}
                    </FilterMenuGroup>

                    <FilterMenuGroup
                      title="Conversion metrics"
                      type="radio"
                      value={
                        orderByField.value?.goalId &&
                        orderByField.value?.attributionMethodId
                          ? toConversionMetricKey({
                              goalId: orderByField.value.goalId,
                              attributionMethodId:
                                orderByField.value.attributionMethodId,
                            })
                          : ""
                      }
                      onChange={(conversionMetricKey) => {
                        const { goalId, attributionMethodId } =
                          fromConversionMetricKey(conversionMetricKey);

                        orderByField.onChange({
                          goalId,
                          type: OrderByCampaignColumnType.Metric,
                          direction: OrderByDirection.Desc,
                          attributionMethodId,
                        });
                      }}
                    >
                      {conversionMetrics.map(
                        ({ id, name, attributionMethod }) => {
                          const key = toConversionMetricKey({
                            goalId: id,
                            attributionMethodId: attributionMethod.id,
                          });

                          return (
                            <FilterMenuOption key={key} value={key}>
                              <TextWithTooltip>
                                <>
                                  {name}
                                  <Text color="text.secondary">
                                    {` / ${attributionMethod.name}`}
                                  </Text>
                                </>
                              </TextWithTooltip>
                            </FilterMenuOption>
                          );
                        },
                      )}
                    </FilterMenuGroup>
                  </FilterMenuList>
                )}
              />
            </FilterMenu>
            <FilterMenu>
              <FilterMenuButton icon={TableIcon}>
                Columns
                {selectedOptionsLength > 0 && (
                  <Badge ml={1} size="sm">
                    {selectedOptionsLength}
                  </Badge>
                )}
              </FilterMenuButton>

              <FilterMenuList maxH="60vh" overflowY="auto">
                <Controller
                  control={form.control}
                  name="selectedPredefinedMetrics"
                  render={({ field }) => (
                    <FilterMenuGroup
                      title="Standard columns"
                      type="checkbox"
                      value={standardColumnSelections}
                      onChange={(updates) => {
                        const [columnSelections, predefinedMetricSelections] =
                          partition(
                            updates,
                            (selection) => !predefinedMetricIds.has(selection),
                          );

                        const newColumnSelections = assetModelColumns.filter(
                          (column) => columnSelections.includes(column.name),
                        );

                        const newPredefinedMetricSelections =
                          predefinedMetrics.filter((metric) =>
                            predefinedMetricSelections.includes(metric.id),
                          );

                        form.setValue("selectedColumns", newColumnSelections);
                        field.onChange(newPredefinedMetricSelections);
                      }}
                    >
                      {assetModelColumns
                        .filter(
                          (column) =>
                            column.column_reference.name !==
                              assetModel?.primary_key_column &&
                            column.column_reference.name !==
                              assetModel?.primary_label_column,
                        )
                        .map((column) => (
                          <FilterMenuOption
                            key={column.name}
                            value={column.name}
                          >
                            {column.alias ?? column.name}
                          </FilterMenuOption>
                        ))}

                      {predefinedMetrics.map((predefinedMetric) => (
                        <FilterMenuOption
                          key={predefinedMetric.id}
                          value={predefinedMetric.id}
                        >
                          <TextWithTooltip>
                            <>
                              {predefinedMetric.name}
                              {predefinedMetric.description && (
                                <Text color="text.secondary">
                                  {` / ${predefinedMetric.description}`}
                                </Text>
                              )}
                            </>
                          </TextWithTooltip>
                        </FilterMenuOption>
                      ))}
                    </FilterMenuGroup>
                  )}
                />

                <Controller
                  control={form.control}
                  name="selectedConversionMetrics"
                  render={({ field }) => (
                    <FilterMenuGroup
                      title="Conversion metrics"
                      type="checkbox"
                      value={selectedConversionMetrics.map(
                        ({ id, attributionMethod }) =>
                          toConversionMetricKey({
                            goalId: id,
                            attributionMethodId: attributionMethod.id,
                          }),
                      )}
                      onChange={(updates) => {
                        const newSelections = updates
                          .map((key) => {
                            const [id, attributionMethodId] =
                              key.split(Delimiter);

                            return conversionMetrics.find(
                              (metric) =>
                                metric.id === id &&
                                metric.attributionMethod.id ===
                                  attributionMethodId,
                            );
                          })
                          .filter(isPresent);

                        field.onChange(newSelections);
                      }}
                    >
                      {conversionMetrics.map(
                        ({ id, attributionMethod, name }) => {
                          const key = toConversionMetricKey({
                            goalId: id,
                            attributionMethodId: attributionMethod.id,
                          });

                          return (
                            <FilterMenuOption key={key} value={key}>
                              <TextWithTooltip>
                                <>
                                  {name}
                                  <Text color="text.secondary">
                                    {` / ${attributionMethod.name}`}
                                  </Text>
                                </>
                              </TextWithTooltip>
                            </FilterMenuOption>
                          );
                        },
                      )}
                    </FilterMenuGroup>
                  )}
                />
              </FilterMenuList>
            </FilterMenu>

            <Controller
              control={form.control}
              name="timeWindow"
              render={({ field }) => (
                <Select
                  optionAccessory={() => ({
                    type: "icon",
                    icon: MeetingIcon,
                  })}
                  placeholder="Select a time window..."
                  options={TimeWindowOptions}
                  value={timeWindow}
                  onChange={(value) => {
                    field.onChange(value ?? AttributionTimeWindow.SevenDays);
                  }}
                  width="4xs"
                />
              )}
            />
          </Row>
        </Row>
      </Column>
    </>
  );
};
