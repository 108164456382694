import { FC, useEffect } from "react";

import { useToast } from "@hightouchio/ui";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { FormkitProvider } from "src/formkit/components/formkit-context";
import {
  useFormkitEventDestinationDefinitionQuery,
  useFormkitEventDestinationValidationQuery,
} from "src/graphql";

import { IPWhitelistMessage } from "src/components/sources/ip-whitelist-message";
import { SharedFormProps } from "src/components/sources/setup";
import { SelectedMethodForm } from "src/components/selected-method-form";
import { Form } from "src/formkit/components";

export const EventDestinationForm: FC<Readonly<SharedFormProps>> = ({
  definition,
  setConfig,
  config,
  isSetup,
  tunnelId,
  setTunnelId,
  credentialId,
  setCredentialId,
  onSubmit,
  disableAuthMethod,
  onConnectClick,
}) => {
  const client = useQueryClient();
  const { toast } = useToast();
  const { data } = useFormkitEventDestinationDefinitionQuery(
    { type: definition?.type },
    { enabled: true },
  );

  const setupMethods = data?.formkitEventDestinationDefinition;
  const context = {
    sourceDefinition: definition,
    isSetup,
    tunnel: tunnelId,
    credentialId,
    setCredentialId,
  };

  const validate = async (config, context) => {
    const response = await client.fetchQuery({
      queryFn: useFormkitEventDestinationValidationQuery.fetcher({
        type: context.sourceDefinition?.type,
        config,
        context,
      }),
      queryKey: useFormkitEventDestinationValidationQuery.getKey({
        ...config,
        ...context,
      }),
    });

    return response.formkitEventDestinationValidation;
  };

  const methods = useForm();

  const { methodKey, ...configWithoutMethodKey } = config ?? {};

  const setMethodKey = (methodKey: string) => {
    setConfig({ methodKey });
  };

  const setConfigWithoutMethodKey = (value: Record<string, unknown>) => {
    setConfig((prev) => ({ ...value, methodKey: prev?.methodKey }));
  };

  useEffect(() => {
    // XXX: Make sure that the config is not already set before we reset it.
    if (configWithoutMethodKey && Object.keys(configWithoutMethodKey).length) {
      methods.reset(configWithoutMethodKey, { keepDefaultValues: true });
    }
  }, [Boolean(config)]);

  useEffect(() => {
    // Always setup the setup method to the first one.
    const initialSetupMethod = setupMethods?.[0];

    if (initialSetupMethod && !config?.methodKey) {
      setConfig((prev) => ({ ...prev, methodKey: initialSetupMethod.key }));
    }
  }, [config, definition.type, setupMethods?.length]);

  useEffect(() => {
    // XXX: This useEffect watches fields and sets config state in the parent.
    // We use this pattern because (unlike the sync form) the parent component
    // needs to know the config to render specific UI elements (like the continue
    // button) and for repeat testing in the testing step.

    const fieldWatcher = methods.watch((value) => {
      setConfigWithoutMethodKey(value);
    });
    return () => fieldWatcher.unsubscribe();
  }, [methods.watch]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (setupMethods?.length === 0) {
      if (typeof onSubmit === "function") {
        return await onSubmit();
      }

      return;
    }

    const errors = await validate(config, context);
    if (typeof errors === "object" && Object.keys(errors).length) {
      methods.clearErrors();
      Object.entries(errors).forEach(([key, message]) => {
        methods.setError(key, { message: String(message) });
      });

      toast({
        id: "save-destination-config",
        title: "There is an error in your destination configuration",
        variant: "error",
      });
    } else {
      if (typeof onSubmit === "function") {
        await onSubmit();
      }
    }
  };

  if (!setupMethods || !Array.isArray(setupMethods)) return null;

  const unusedFormProps = {
    lightningEnabled: false,
    plannerDatabase: undefined,
    setLightningEnabled: () => {},
    setPlannerDatabase: () => {},
    sourceId: undefined,
    hasSetupLightning: false,
    setSchema: () => {},
    setLightningSchemaMode: () => {},
  };

  return (
    <FormkitProvider {...context} validate={validate}>
      <FormProvider {...methods}>
        <Form>
          <SelectedMethodForm
            {...unusedFormProps}
            config={config}
            credentialId={credentialId}
            definition={definition}
            disableAuthMethod={disableAuthMethod}
            isEventsForm={true}
            isSetup={isSetup}
            methodKey={String(methodKey)}
            setCredentialId={setCredentialId}
            setMethodKey={setMethodKey}
            setTunnelId={setTunnelId}
            setupMethods={setupMethods}
            tunnelId={tunnelId}
            onConnectClick={onConnectClick}
          />
          {definition.supportsIpFiltering && <IPWhitelistMessage />}
          <form hidden id="event-destination-form" onSubmit={handleSubmit} />
        </Form>
      </FormProvider>
    </FormkitProvider>
  );
};
