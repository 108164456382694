import { useEffect, useState } from "react";

import { Combobox, FormField, TextInput } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";

import { GitCredentialsQuery, useGithubBranchesQuery } from "src/graphql";
import { isGitHub } from "./utils";

interface GitBranchSelectorProps {
  credentials: NonNullable<GitCredentialsQuery["git_credentials"][0]>;
  description?: string;
  label?: string;
  name?: string;
}

export const GitBranchSelector = ({
  label,
  description,
  credentials,
  name = "branch",
}: GitBranchSelectorProps) => {
  const { control, watch } = useFormContext();

  const repository: string = watch("repository");
  const [error, setError] = useState<string>("");

  const getGithubUrlName = (url?: string) => {
    if (!url) {
      return "";
    }
    try {
      return new URL(repository).pathname.slice(1);
    } catch (_err) {
      return url;
    }
  };
  const { data: githubBranches, isLoading: githubBranchLoading } =
    useGithubBranchesQuery(
      {
        credentialId: String(credentials?.id),
        // parse the github URL to get the owner and repo name
        repo: getGithubUrlName(repository),
      },
      {
        select: (data) => data.getGithubBranches,
        // We only want to try and fetch branches if the customer has selected a repo rather than manually inputting one
        enabled: isGitHub(credentials) && Boolean(repository),
        onError: (error) => setError(error?.message),
      },
    );

  const githubBranchOptions = githubBranches?.map((branch) => ({
    label: branch.name,
    value: branch.name,
  }));

  useEffect(() => {
    setError("");
  }, [credentials?.type, repository]);

  return (
    <FormField
      description={description}
      error={error}
      label={label || "Branch"}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) =>
          isGitHub(credentials) ? (
            <Combobox
              {...field}
              isDisabled={githubBranchLoading || !repository}
              isLoading={githubBranchLoading}
              options={githubBranchOptions ?? []}
              placeholder="Select a branch..."
            />
          ) : (
            <TextInput {...field} />
          )
        }
      />
    </FormField>
  );
};
