import { Column, Combobox, EmptyState, FormField } from "@hightouchio/ui";
import { LinkButton, useOutletContext } from "src/router";
import { Controller } from "react-hook-form";
import { useCatalogsQuery } from "src/graphql";
import { OutletContext } from "src/pages/decision-engines";

export const CatalogSelect = () => {
  const { engine } = useOutletContext<OutletContext>();
  const { data, isLoading } = useCatalogsQuery(undefined, {
    select: (data) => data.segments,
  });

  if (!isLoading && !data) {
    return (
      <EmptyState
        title="No catalogs"
        message="Add a catalog to start using item recommendations in the decision engine."
      />
    );
  }

  return (
    <FormField label="Select a catalog">
      <Column gap={3} align="flex-start">
        <Controller
          name="catalog_id"
          render={({ field }) => (
            <Combobox
              {...field}
              optionLabel={(option) => option.name}
              optionValue={(option) => option.id}
              options={data ?? []}
            />
          )}
        />
        <LinkButton
          href={`/schema-v2/new?source=${engine.segment.connection?.id}&type=catalog`}
        >
          Add a new catalog
        </LinkButton>
      </Column>
    </FormField>
  );
};
