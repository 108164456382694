import {
  type AndOrCondition,
  type PropertyCondition,
  StringOperator,
  ColumnType,
  ConditionType,
  DecisionEngineInteractionColumnNames,
} from "../types";

// Filter used in both app and backend to exclude holdout and customer_managed from engagement metrics
export const ENGAGEMENT_OUTCOME_EXPERIMENT_FILTER: AndOrCondition<PropertyCondition>[] =
  [
    {
      type: ConditionType.And,
      conditions: [
        {
          type: ConditionType.Property,
          property: {
            type: "decision_engine_interaction",
            name: DecisionEngineInteractionColumnNames.Experiment,
          },
          propertyType: ColumnType.String,
          operator: "<>",
          value: ["holdout", "customer_managed"],
        },
      ],
    },
  ];

/**
 * Get the subconditions to filter on for the selected metric (outcome model)
 * Along with the ad hoc filters users can add to a selected metric, we also
 * want to include the outcome's filter conditions. The outcome's filter is configured
 * in the AID and is used to filter event models to define certain outcomes (e.g.
 * purchase/subscribe/etc event types). We don't want to expose this filter to the
 * the user, but we do want to include it in the metric calculation so the BE can filter
 * the underlying event model accordingly.
 */
export const getFilterSubconditionsForMetric = ({
  metricConditions,
  outcome,
  flowMessageCampaignIds,
}: {
  metricConditions: AndOrCondition<PropertyCondition>[];
  outcome?: {
    attribution?: { campaign_id_column?: string };
    filter?: { subconditions: AndOrCondition<PropertyCondition>[] };
  };
  flowMessageCampaignIds: string[];
}): AndOrCondition<PropertyCondition>[] => {
  const campaignIdColumn = outcome?.attribution?.campaign_id_column;
  const outcomeConditions = outcome?.filter?.subconditions ?? [];

  const filterConditions: AndOrCondition<PropertyCondition>[] = [
    ...(campaignIdColumn
      ? [
          {
            type: ConditionType.And,
            conditions: [
              {
                type: ConditionType.Property,
                propertyType: ColumnType.String,
                property: campaignIdColumn,
                operator: StringOperator.Equals,
                value: flowMessageCampaignIds,
              },
            ],
          } as AndOrCondition<PropertyCondition>,
        ]
      : []),
    ...outcomeConditions,
    ...metricConditions,
  ];

  return toSingleCondition(filterConditions);
};

function toSingleCondition(
  conditions: AndOrCondition<PropertyCondition>[],
): AndOrCondition<PropertyCondition>[] {
  if (conditions.length === 0) {
    return [];
  }

  if (conditions.length === 1) {
    return conditions;
  }

  return [{ type: ConditionType.And, conditions }];
}

// Reference `generateAndPullOutcomesTable`
export const getCampaignIdsFromFlowMessages = (
  flowMessages: { config: any }[],
): string[] => {
  return flowMessages
    .flatMap(({ config }) => {
      const campaignIds: string[] = [];

      if (config?.campaignId) {
        campaignIds.push(config.campaignId.toString());
      }
      if (config?.resourceId) {
        campaignIds.push(config.resourceId.toString());
      }
      if (config?.attributionCampaignIds) {
        campaignIds.push(...config.attributionCampaignIds.map(String));
      }

      return campaignIds;
    })
    .sort();
};
