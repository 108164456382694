import { useFlags } from "launchdarkly-react-client-sdk";
import {
  LinkButton,
  useNavigate,
  useSearchParams,
  useParams,
} from "src/router";
import {
  MergedSyncRequestEventStatus,
  useSyncRunPhasesQuery,
} from "src/graphql";
import syncPlaceholder from "src/assets/placeholders/sync.svg";

import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Column,
  EmptyState,
  Row,
  Skeleton,
  SkeletonBox,
  Text,
} from "@hightouchio/ui";
import { Card } from "src/components/card";
import { SyncRunStatus, syncRunStatusOrUnknownStatus } from "src/utils/syncs";

import {
  GroupedPhaseData,
  phaseFailedWithErrors,
  SyncRequest,
  type SyncRunPhase,
} from "./types";
import { PhaseDisplay } from "./phase-display";
import { PhaseError } from "./phase-error";
import {
  PHASE_DISPLAY_CONFIG,
  PHASE_GROUP_CONFIG,
  PhaseGroup,
} from "./phase-display-config";
import { newPylonMessage } from "src/lib/pylon";
import {
  formatTimestamp,
  groupPhases,
  syncRequestErrorToPhaseError,
} from "./utils";
import { isNil } from "lodash";
import { differenceInDays } from "date-fns";
import { Warning } from "src/components/warning";

const useDebugTimestamp = () => {
  const [params, _] = useSearchParams();
  const timestamp = params.get("debug");
  const parsed = timestamp ? Number(timestamp) : null;

  // Some extra handling to deal with NaN
  return parsed ? parsed : null;
};

export const SyncRunSummary = () => {
  const { run_id: runId, sync_id: syncId } = useParams<{
    run_id: string;
    sync_id: string;
  }>();
  const debugTimestamp = useDebugTimestamp();

  const { appSyncRunSummaryPageEnabled } = useFlags();
  const navigate = useNavigate();

  const { data, isLoading } = useSyncRunPhasesQuery(
    {
      syncId: syncId ?? "",
      syncRequestId: runId ?? "",
      debug: debugTimestamp ? { timestamp: debugTimestamp } : null,
    },
    {
      select: (data) => ({
        groupedPhaseData: groupPhases(data.getSyncRequestEvents),
        syncRequest: data.sync_requests_by_pk,
      }),
      refetchInterval: 5000,
    },
  );

  if (!appSyncRunSummaryPageEnabled) {
    navigate(`/syncs/${syncId}/runs/${runId}`);
  }

  if (isLoading) {
    return (
      <Skeleton isLoading>
        <Column gap={6}>
          <SkeletonBox height="120px" borderRadius="md" />
          <SkeletonBox height="120px" borderRadius="md" />
          <SkeletonBox height="120px" borderRadius="md" />
          <SkeletonBox height="120px" borderRadius="md" />
        </Column>
      </Skeleton>
    );
  }

  const groupedPhaseData = data?.groupedPhaseData;
  const syncRequest = data?.syncRequest;

  if (!groupedPhaseData || !syncRequest) {
    return <Warning title="Sync run not found" />;
  }

  // For now disable this page for sync runs older than 30 days
  // This also helps filter out earlier iterations of the data model
  // In the future we may want to adjust this or make the logic more dynamic
  if (differenceInDays(new Date(), syncRequest.created_at) > 30) {
    return (
      <Column gap={6}>
        <EmptyState
          title="Run phase details not available"
          message="Sync run details are only available for 30 days. Try selecting a more recent run to view details."
          imageUrl={syncPlaceholder}
          actions={
            <LinkButton href={`/syncs/${syncId}/runs`}>
              View all runs
            </LinkButton>
          }
        />
      </Column>
    );
  }

  // Note: we must use sync request timestamps here. Spans are not collected until the sync run is started (after being queued)
  const startTime = new Date(syncRequest.created_at).getTime();
  const endTime = syncRequest.finished_at
    ? new Date(syncRequest.finished_at).getTime()
    : undefined;

  const elapsedMillis = (endTime ?? Date.now()) - startTime;

  const runStatus = syncRunStatusOrUnknownStatus(syncRequest.status_computed);

  // Show a warning if the sync run has been enqueued for more than 1 minute
  const showQueuedSyncAlert =
    runStatus === SyncRunStatus.ENQUEUED && elapsedMillis > 60_000;

  const parentPhaseErrors = groupedPhaseData
    ? [
        ...groupedPhaseData.parentPhaseErrors,
        syncRequestErrorToPhaseError(syncRequest),
      ].filter((x) => !isNil(x))
    : [];

  const hasFailedGroupedPhases = groupedPhaseData?.groups.some((group) =>
    group.phases.some(phaseFailedWithErrors),
  );

  return (
    <Column gap={6}>
      <Alert
        type="info"
        title="PUBLIC PREVIEW"
        message="This is an early version of a feature that breaks down each sync run into individual phases. We’d love your feedback—what’s useful, what’s missing, and what could be clearer?"
      />
      {showQueuedSyncAlert && (
        <Alert
          type="subtle"
          title="This sync run is queued"
          message="This sync run will start once other syncs finish. You can increase your concurrency limits by contacting support, or adjust your sync schedules so they do not all run at the same time."
          actions={
            <ButtonGroup>
              <Button
                onClick={() =>
                  newPylonMessage(
                    "I would like to increase the sync concurrency limits for my workspace.",
                  )
                }
              >
                Contact support
              </Button>
              <LinkButton href={`/syncs/${syncId}/schedule`}>
                Edit sync schedule
              </LinkButton>
            </ButtonGroup>
          }
        />
      )}
      {/* Note: it's slightly unexpected to have parent phase errors that are not caught elsewhere,
        if we see a lot of these it probably means we need to add additional / more granular spans in the backend */}
      {parentPhaseErrors.length > 0 && !hasFailedGroupedPhases && (
        <PhaseError
          errors={parentPhaseErrors}
          syncRequest={syncRequest}
          variant="card"
        />
      )}
      <Column gap={4} borderLeft="2px" borderColor="base.divider" pl={4}>
        <Text color="text.secondary">
          Started on {formatTimestamp(startTime)}
        </Text>
        {groupedPhaseData?.groups.map(({ group, phases }, index) => {
          const nextGroups = groupedPhaseData.groups.slice(index + 1);

          return (
            <SyncRunPhaseGroup
              key={group}
              group={group}
              phases={phases}
              syncRequest={syncRequest}
              nextGroups={nextGroups}
            />
          );
        })}
        {endTime && (
          <Text color="text.secondary">
            Finished on {formatTimestamp(endTime)}
          </Text>
        )}
      </Column>
    </Column>
  );
};

type SyncRunPhaseGroupProps = {
  group: PhaseGroup;
  phases: SyncRunPhase[];
  syncRequest: SyncRequest;
  nextGroups: GroupedPhaseData["groups"];
};

const SyncRunPhaseGroup = ({
  group,
  phases,
  syncRequest,
  nextGroups,
}: SyncRunPhaseGroupProps) => {
  const { displayName, Icon } = PHASE_GROUP_CONFIG[group];

  const nextGroupsHaveProgress = nextGroups.some(({ phases }) =>
    phases.some(
      ({ status }) => status !== MergedSyncRequestEventStatus.NotStarted,
    ),
  );

  const renderablePhases: SyncRunPhase[] = phases.filter((phase) => {
    // Never render phases that are not started, if they aren't the skipped special case.
    if (
      phase.status === MergedSyncRequestEventStatus.NotStarted &&
      !phase.isSkipped
    ) {
      return false;
    }

    // Only show a skipped phase if explicitly configured to do so.
    if (
      phase.isSkipped &&
      !PHASE_DISPLAY_CONFIG[phase.span]?.displayWhenSkipped
    ) {
      return false;
    }

    return true;
  });

  return (
    <Card p={0}>
      <Column>
        <Row
          p={4}
          alignItems="center"
          justifyContent="space-between"
          _notLast={{ borderBottom: "1px", borderColor: "base.divider" }}
        >
          <Row alignItems="center" gap={1}>
            {Icon && <Icon syncRequest={syncRequest} />}
            <Text
              fontWeight="medium"
              color={phases.length === 0 ? "text.secondary" : "inherit"}
            >
              {displayName}
            </Text>
          </Row>

          {renderablePhases.length === 0 && (
            <Text size="sm" color="text.tertiary">
              {nextGroupsHaveProgress ? "Skipped" : "Not started"}
            </Text>
          )}
        </Row>
        {renderablePhases.map((phase, index) => (
          <Box
            key={index}
            _notLast={{ borderBottom: "1px", borderColor: "base.divider" }}
          >
            <PhaseDisplay phase={phase} syncRequest={syncRequest} />
          </Box>
        ))}
      </Column>
    </Card>
  );
};
