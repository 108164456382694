import {
  Box,
  Button,
  ButtonGroup,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  Row,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import pluralize from "pluralize";

import { DiscardButton, SaveButton } from "src/components/form";
import { NAV_WIDTHS_PER_BREAKPOINT } from "src/components/layout/constants";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";
import { getSelectedNodes } from "src/pages/journeys/utils";

type JourneyFooterProps = {
  isJourneyResetting: boolean;
  isJourneyDraining: boolean;
  onLeaveEditMode: () => void;
};

export const JourneyFooter = ({
  isJourneyResetting,
  isJourneyDraining,
  onLeaveEditMode,
}: JourneyFooterProps) => {
  const { journeysFullCloning } = useFlags();
  const { toast } = useToast();
  const {
    isEditMode,
    isJourneyLive,
    nodes,
    unauthorized,
    updateJourneyPermission,
    onClearNodeSelection,
    onCloneNodes,
    onToggleEditMode,
    onRemoveNode,
  } = useGraphContext();

  const selectedNodes = getSelectedNodes(nodes).map((node) => node.id);
  const areNodesSelected = selectedNodes.length > 1;

  const cloneTiles = () => {
    onCloneNodes(selectedNodes, journeysFullCloning);
  };

  const deleteTiles = () => {
    selectedNodes.map(onRemoveNode);

    onClearNodeSelection();

    toast({
      id: "delete-node",
      title: "Tiles deleted",
      variant: "success",
    });
  };

  return (
    <Row
      borderTop="1px solid"
      borderColor="base.border"
      bottom={0}
      bg="white"
      boxShadow="lg"
      position="fixed"
      gap={2}
      left={NAV_WIDTHS_PER_BREAKPOINT}
      height="72px"
      align="center"
      right={0}
      px={6}
      zIndex={1}
    >
      {isEditMode ? (
        areNodesSelected ? (
          <Row flex={1} minWidth={0} justify="center" gap={4}>
            <ButtonGroup>
              <Button
                icon={CopyIcon}
                size="lg"
                variant="primary"
                onClick={cloneTiles}
              >
                Copy {selectedNodes.length}{" "}
                {pluralize("tile", selectedNodes.length)}
              </Button>
              <Button size="lg" onClick={onClearNodeSelection}>
                Deselect
              </Button>
            </ButtonGroup>
            <Box borderRight="1px solid" borderColor="base.border" />
            <Button
              icon={DeleteIcon}
              size="lg"
              variant="warning"
              onClick={deleteTiles}
            >
              Delete {pluralize("tile", selectedNodes.length)}
            </Button>
          </Row>
        ) : (
          <ButtonGroup>
            <SaveButton permission={updateJourneyPermission} />
            <DiscardButton onClick={onLeaveEditMode} />
          </ButtonGroup>
        )
      ) : (
        <Tooltip
          isDisabled={
            !isJourneyResetting && !isJourneyLive && !isJourneyDraining
          }
          placement="right"
          message={
            isJourneyResetting
              ? "Journey may not be edited while it is resetting"
              : isJourneyDraining
                ? "Journey must be fully drained to edit"
                : "Pause or stop the journey to edit"
          }
        >
          <Button
            icon={unauthorized ? undefined : EditIcon}
            isDisabled={isJourneyLive || isJourneyResetting}
            size="lg"
            variant="primary"
            onClick={() => {
              onClearNodeSelection();
              onToggleEditMode();
            }}
          >
            {unauthorized ? "View configuration" : "Edit journey"}
          </Button>
        </Tooltip>
      )}
    </Row>
  );
};
