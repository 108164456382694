import type { SyntheticColumn } from "./analytics";
import type {
  ColumnType,
  RawColumn,
  RelatedColumn,
  TransformedColumn,
} from "./column";
import type { IntervalValue } from "./interval";
import type { NumberOperator, Operator, TimestampOperator } from "./operator";
import type { TimeRangeValue } from "./time-range";

export type Condition =
  | PropertyCondition
  | NumberOfCondition
  | EventCondition
  | ReferencedPropertyCondition
  | OrCondition
  | AndCondition
  | SegmentSetCondition;

export type RootCondition =
  | PropertyCondition
  | NumberOfCondition
  | EventCondition
  | OrCondition<RootCondition>
  | AndCondition<RootCondition>
  | SegmentSetCondition;

export enum ConditionType {
  Property = "property",
  ReferenceProperty = "reference_property",
  NumberOf = "number_of",
  Event = "event",
  Funnel = "funnel",
  Or = "or",
  And = "and",
  SegmentSet = "segment_set",
}

export enum TimeType {
  Absolute = "absolute",
  Relative = "relative",
}

export interface OrCondition<C extends Condition = Condition> {
  type: ConditionType.Or;

  conditions: C[];

  // The ID of the audience template that this condition belongs to.
  audienceTemplateId?: string;
}

export interface AndCondition<C extends Condition = Condition> {
  type: ConditionType.And;

  conditions: C[];

  // The ID of the audience template that this condition belongs to.
  audienceTemplateId?: string;
}

export type AndOrCondition<C extends Condition> =
  | C
  | AndCondition<AndOrCondition<C>>
  | OrCondition<AndOrCondition<C>>;

export interface PropertyCondition {
  type: ConditionType.Property;

  // The column in the row that we're filtering on.
  property:
    | string
    | RawColumn
    | RelatedColumn
    | TransformedColumn
    | SyntheticColumn
    | null;

  // The type of the column. This affects which operator we use.
  propertyType: ColumnType | null;

  // How the column should be compared with the `value`. E.g. should it be
  // equal? Or not equal?
  operator: Operator;

  // The specific value that the column is compared against.
  value: any | ColumnValue;

  // The time type for timestamp property conditions.
  timeType?: TimeType;

  // Contains optional fields that provide more granularity for the visual query
  propertyOptions?: PropertyOptions;

  // Contains optional fields that are controlled by the backend.
  // They should not be set by the frontend.
  conditionOptions?: ConditionOptions;

  // The ID of the audience template that this condition belongs to.
  audienceTemplateId?: string;
}

export interface ColumnValue {
  type: ValueType.Column;
  property: string | RawColumn | RelatedColumn;
  propertyType: ColumnType;
}

export enum ValueType {
  Column = "column",
}

export function isColumnTypeValue(value: any): value is ColumnValue {
  return value?.type === ValueType.Column;
}

export interface PropertyOptions {
  caseSensitive?: boolean;
  // If true, converts the right-hand value to a percentile threshold for the
  // column over the parent model.
  percentile?: boolean;
  parameterize?: boolean;
  traitType?: "trait" | "trait_template" | "inline_trait";
}

export interface ConditionOptions {
  // If true, we've already checked how to handle null value behavior
  // when the condition is evaluated.
  nullInclusionCheckApplied?: boolean;
}

export interface ReferencedPropertyCondition
  extends Omit<PropertyCondition, "type" | "value"> {
  type: ConditionType.ReferenceProperty;

  // The column from the referenced table that should be compared against.
  valueFromColumn: string | null;
}

export interface NumberOfCondition {
  type: ConditionType.NumberOf;

  // The relationship ID from the database that defines how the model being
  // filtered is related to the related model.
  relationshipId: string | null;

  // Additional filters on the matching rows in the related model.
  subconditions?: AndOrCondition<PropertyCondition>[];

  // The condition applied to the count of related rows.
  operator: NumberOperator;
  value: number;

  // The ID of the audience template that this condition belongs to.
  audienceTemplateId?: string;
}

export interface EventCondition {
  type: ConditionType.Event;

  // Relationship to the event model.
  eventModelId: string | null;
  relationshipId: string | null;

  // The condition on the number of matching events.
  operator: NumberOperator;
  value: number | null;

  // Property conditions to filter the matching events.
  subconditions?: AndOrCondition<PropertyCondition>[];

  // A time window. Events outside the window are ignored.
  window?: Window | null;

  // An additional event type that must match or not match.
  // This can be used to expressed queries like "Users that added a product to
  // their shopping cart AND didn't check out".
  funnelCondition?: FunnelCondition;

  // The ID of the audience template that this condition belongs to.
  audienceTemplateId?: string;
}

// A FunnelCondition is a lot like an Event condition, except:
// * Its subconditions can reference values from the parent event condition.
// * It automatically filters by events that happened *after* a event from the
//   parent event condition.
// * Its "window" is relative to the parent event condition.
export interface FunnelCondition
  extends Omit<
    EventCondition,
    "type" | "funnelCondition" | "subconditions" | "value" | "operator"
  > {
  type: ConditionType.Funnel;

  // Whether the condition should be true. For example, `didPerform` would be
  // `false` to express "did NOT check out".
  didPerform: boolean;

  subconditions?: AndOrCondition<
    PropertyCondition | ReferencedPropertyCondition
  >[];
}

export interface Window {
  operator: TimestampOperator;
  // We use a single type here for greater flexibility in the frontend, but note
  // that string types (absolute timestamps) are _not_ allowed if this is being
  // used for a funnel condition window.
  value: IntervalValue | TimeRangeValue | string;
  timeType?: TimeType;
}

export interface SegmentSetCondition {
  type: ConditionType.SegmentSet;

  // The id of the other segment.
  modelId: string | null;

  // Whether we should *include* or *exclude* the rows from the other segment.
  includes: boolean;

  options?: {
    // XXX: This should only be set by the backend when translating the condition to SQL.
    // This key _should not_ be included in the condition stored in the DB.
    skipPriorityListCheck?: boolean;
  };

  // The ID of the audience template that this condition belongs to.
  audienceTemplateId?: string;
}

export type SubsetConditions = {
  type: ConditionType;
  conditions: AndOrCondition<AndCondition | OrCondition>[];
};
