import {
  MergedSyncRequestEventSpan,
  MergedSyncRequestEventStatus,
  SyncRunPhasesQuery,
} from "src/graphql";
import type { ElementOf } from "ts-essentials";

import { PhaseGroup } from "./phase-display-config";

export type SyncRequest = NonNullable<
  SyncRunPhasesQuery["sync_requests_by_pk"]
>;

export type BaseSyncRunPhase = ElementOf<
  SyncRunPhasesQuery["getSyncRequestEvents"]
>;

export type SyncRunPhase = BaseSyncRunPhase & {
  isSkipped: boolean;
  isInterrupted: boolean;
  childPhases: BaseSyncRunPhase[];
};

export type GroupedPhaseData = {
  syncPhase: BaseSyncRunPhase;
  parentPhaseErrors: SyncRunPhase["errors"];
  groups: {
    group: PhaseGroup;
    phases: SyncRunPhase[];
  }[];
};

export const EMPTY_SYNC_PHASE: BaseSyncRunPhase = {
  span: MergedSyncRequestEventSpan.Sync,
  status: MergedSyncRequestEventStatus.NotStarted,
  startTime: null,
  endTime: null,
  errors: [],
  durationMillis: { self: 0, total: 0 },
};

export const phaseFailedWithErrors = (phase: BaseSyncRunPhase): boolean =>
  phase.status === MergedSyncRequestEventStatus.Failed &&
  phase.errors.length > 0;
