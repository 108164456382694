import {
  Box,
  Column,
  InformationIcon,
  Row,
  SkeletonBox,
  Text,
  Tooltip,
  TooltipProps,
} from "@hightouchio/ui";
import NumberFlow from "@number-flow/react";
import { RouterLink } from "src/router";

import { useIsRouteActive } from "src/hooks/use-is-route-active";

export const Stat = ({
  label,
  value,
  color,
  tip,
  href,
  matchParam,
  placement = "top",
}: {
  label: string;
  value: number | undefined;
  color?: string;
  tip?: string;
  href: string;
  placement?: TooltipProps["placement"];
  /** The search parameter to match against. If provided, only this parameter will be checked for active state */
  matchParam?: string;
}) => {
  const isActive = useIsRouteActive(href, matchParam);

  return (
    <SkeletonBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mx={4}
      height="80px"
      flex={1}
      transition="all 300ms ease-in-out"
      _hover={{
        bg: isActive ? "forest.100" : "gray.100",
      }}
      _focusWithin={{ boxShadow: "outline" }}
      borderRadius="md"
      border="1px solid"
      bg={isActive ? "forest.100" : undefined}
      borderColor={isActive ? "primary.border" : "transparent"}
    >
      <Column
        as={RouterLink}
        to={href}
        borderRadius="md"
        px={4}
        height="100%"
        justify="center"
        cursor={isActive ? "default" : "pointer"}
      >
        <Row align="center" gap={1}>
          <Text color="text.secondary" whiteSpace="nowrap">
            {label}
          </Text>
          {tip && (
            <Tooltip message={tip} placement={placement}>
              <Box as={InformationIcon} flexShrink={0} color="text.secondary" />
            </Tooltip>
          )}
        </Row>
        <Box
          fontSize="3xl"
          fontWeight="semibold"
          color={value === 0 ? "text.tertiary" : color}
        >
          {value && <NumberFlow value={value} />}
        </Box>
      </Column>
    </SkeletonBox>
  );
};
