import {
  FC,
  Fragment,
  ReactNode,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Box,
  Button,
  ButtonProps,
  CheckIcon,
  Column,
  ConfirmationDialog,
  Drawer,
  Paragraph,
  Row,
  SectionHeading,
  Spinner,
  Tooltip,
  Text,
  BoxProps,
} from "@hightouchio/ui";

import { noop } from "lodash";
import {
  cssVariableBottomSectionHeight,
  cssVariableTopOffset,
  cssVariableVisibleTopSectionHeight,
} from "src/components/layout/detail-page";
import { useMeasureHeightOnce } from "src/hooks/use-measured-height-once";
import { Watermark } from "src/partner/watermark";
import { Container } from "src/ui/box";
import { useNavigation } from "src/router";

export interface WizardProps {
  fullscreen?: boolean;
  title: ReactNode;
  watermark?: boolean;
  step: number;
  steps: WizardStep[];
  setStep: (step: number) => void;
  previousDisabled?: boolean;
  sx?: BoxProps["sx"];
  showOverlay?: boolean;
  onCancel: () => void;
  onSubmit: () => void | Promise<void>;
}

export type WizardStep = {
  title?: string;
  header?: ReactNode;
  render: FC;
  bg?: string;
  loading?: boolean;
  submitting?: boolean;
  onBack?: () => void;
  onContinue?: () => void | Promise<void>;
  continueLabel?: string;
  continueProps?: Pick<ButtonProps, "form" & "type">;
  continueTooltip?: string;
  continue?: ReactNode;
  actions?: ReactNode;
  disabled?: boolean;
};

const size = "medium";
const bottomBarHeight = 80;
const wizardHeaderSize = 63;

/**
 * @deprecated Use @hightouchio/ui WizardDrawer instead
 */
export const DeprecatedWizard: FC<Readonly<WizardProps>> = ({
  title,
  showOverlay = true,
  step,
  steps,
  setStep,
  onCancel,
  onSubmit,
  previousDisabled,
  fullscreen,
  watermark,
}) => {
  const navigation = useNavigation();
  const headingRef = useRef<HTMLDivElement>(null);

  const headingHeight = useMeasureHeightOnce(headingRef, wizardHeaderSize);

  const data = steps[step];
  const [submitting, setSubmitting] = useState(false);
  const [exiting, setExiting] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [step]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Esc" || event.key === "Escape") {
        setExiting(true);
      }
    };
    window.addEventListener("keydown", handleKeyDown, false);
    return () => {
      window.removeEventListener("keydown", handleKeyDown, false);
    };
  }, [setExiting]);

  const isLastStep = step === steps.length - 1;

  return (
    <>
      <Drawer isOpen onClose={noop} showOverlay={showOverlay} size="full">
        <Column
          minHeight="100vh"
          overflowY="auto"
          bg={data?.bg}
          sx={{
            [cssVariableTopOffset]: `${headingHeight}px`,
            [cssVariableVisibleTopSectionHeight]: `${headingHeight}px`,
            [cssVariableBottomSectionHeight]: `${bottomBarHeight}px`,
          }}
        >
          <Row
            ref={headingRef}
            flexShrink={0}
            height="64px"
            bg="white"
            zIndex={2}
            justifyContent="center"
            width="100%"
            borderBottom="1px"
            borderColor="base.border"
            position="sticky"
            top={0}
          >
            <Row alignItems="center" flex={1} px={8}>
              <SectionHeading>{title}</SectionHeading>
              <Row flex={1} alignItems="center" justifyContent="center" mx={8}>
                {steps.map(({ title }, i) => {
                  const visited = i < step;
                  const current = i === step;
                  return (
                    <Fragment key={i}>
                      <Row gap={2} alignItems="center">
                        <Row
                          flexShrink={0}
                          alignItems="center"
                          justifyContent="center"
                          borderColor="plum.900"
                          border={visited ? "none" : "1px solid"}
                          bg={
                            visited
                              ? "gray.base"
                              : current
                                ? "forest.700"
                                : "white"
                          }
                          width="14px"
                          height="14px"
                          fontSize="14px"
                          borderRadius="7px"
                        >
                          {i < step && <CheckIcon color="white" />}
                        </Row>
                        {title && (
                          <Box display={["none", "none", "block", "block"]}>
                            <Text
                              color={visited ? "text.secondary" : "forest.700"}
                              fontWeight="semibold"
                            >
                              {title}
                            </Text>
                          </Box>
                        )}
                      </Row>

                      {i < steps?.length - 1 && (
                        <Row
                          height="1px"
                          bg="gray.border"
                          flex={1}
                          mx={4}
                          maxWidth="48px"
                        />
                      )}
                    </Fragment>
                  );
                })}
              </Row>

              <Button variant="secondary" onClick={() => setExiting(true)}>
                Exit
              </Button>
            </Row>
          </Row>
          {data?.header && (
            <Column position="sticky" top="64px" bg="white" zIndex={2}>
              <Row
                width="100%"
                height="80px"
                bg="gray.50"
                justifyContent="center"
                position="sticky"
              >
                <Container size={size} px={24}>
                  <Row alignItems="center" flex={1}>
                    {data?.header}
                  </Row>
                </Container>
              </Row>
            </Column>
          )}

          <Container
            size={fullscreen ? "unset" : size}
            px={fullscreen ? 0 : 24}
            py={fullscreen ? 0 : 8}
          >
            <Column flex={1}>
              {data?.loading ? (
                <Spinner m="auto" size="lg" />
              ) : (
                <Suspense fallback={<Spinner m="auto" size="lg" />}>
                  {data?.render({})}
                </Suspense>
              )}
            </Column>
          </Container>
          <Row
            flexShrink={0}
            position="sticky"
            bottom={0}
            bg="white"
            height={`${bottomBarHeight}px`}
            alignItems="flex-end"
            justifyContent="center"
            zIndex={2}
          >
            <Row
              justifyContent="center"
              alignItems="center"
              height={`${bottomBarHeight}px`}
              bg="white"
              width="100%"
              borderTop="1px"
              borderColor="base.border"
            >
              <Container size={size} px={24}>
                <Row
                  alignItems="center"
                  flex={1}
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box
                    display="contents"
                    visibility={step > 0 ? "visible" : "hidden"}
                  >
                    <Button
                      icon={ArrowLeftIcon}
                      isDisabled={data?.loading || previousDisabled}
                      size="lg"
                      variant="secondary"
                      onClick={data?.onBack ?? (() => setStep(step - 1))}
                    >
                      Go back
                    </Button>
                  </Box>
                  {watermark && <Watermark />}
                  {!data?.loading && (
                    <Row alignItems="center" gap={4}>
                      {data?.actions || null}
                      {data?.continue ? (
                        <Text fontWeight="semibold" size="sm" color="gray.500">
                          {data?.continue}
                        </Text>
                      ) : (
                        <Tooltip
                          isDisabled={!data?.continueTooltip}
                          message={data?.continueTooltip || ""}
                        >
                          <Button
                            {...(data?.continueProps || {})}
                            directionIcon={
                              isLastStep ? undefined : ArrowRightIcon
                            }
                            isDisabled={data?.disabled}
                            isLoading={
                              data?.submitting ||
                              submitting ||
                              navigation.state === "loading"
                            }
                            size="lg"
                            variant="primary"
                            onClick={async () => {
                              setSubmitting(true);
                              if (isLastStep) {
                                try {
                                  await onSubmit();
                                } catch (_err) {
                                  setSubmitting(false);
                                  return;
                                }
                              }
                              if (data?.onContinue) {
                                await data?.onContinue();
                              } else if (!isLastStep) {
                                setStep(step + 1);
                              }
                              setSubmitting(false);
                            }}
                          >
                            {isLastStep
                              ? "Finish"
                              : (data?.continueLabel ?? "Continue")}
                          </Button>
                        </Tooltip>
                      )}
                    </Row>
                  )}
                </Row>
              </Container>
            </Row>
          </Row>
        </Column>
      </Drawer>
      <ConfirmationDialog
        confirmButtonText="Exit"
        isOpen={exiting}
        title="Are you sure?"
        variant="warning"
        onClose={() => setExiting(false)}
        onConfirm={() => {
          setExiting(false);
          onCancel();
        }}
      >
        <Paragraph>Your progress will be lost.</Paragraph>
      </ConfirmationDialog>
    </>
  );
};
