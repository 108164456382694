import { FC, useEffect, useMemo, useState } from "react";

import {
  Alert,
  ArrowLeftIcon,
  Button,
  Column,
  ConfirmationDialog,
  Dialog,
  IconButton,
  Paragraph,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import noop from "lodash/noop";
import { Helmet } from "react-helmet";
import { Controller } from "react-hook-form";
import { ReactFlowProvider } from "reactflow";
import { Link, Navigate, useNavigate, useParams } from "src/router";

import { Form } from "src/components/form";
import { PageHeader } from "src/components/layout";
import { PageSpinner } from "src/components/loading";
import { PermissionedEditableHeading } from "src/components/permission";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { Warning } from "src/components/warning";
import {
  JourneyQuery,
  useJourneyQuery,
  useLatestJourneyRunsQuery,
} from "src/graphql";
import { JourneyStatus } from "src/types/journeys";

import { JourneyActionButtons, JourneyStatusBadge } from "./components";
import { JourneyFooter } from "./components/journey-footer";
import { LIVE_STATES, NULL_EXIT_CRITERIA } from "./constants";
import { Graph, GraphProvider, useJourneyGraph } from "./graph";
import {
  getUpdateJourneyPermission,
  PERMISSION_UNAUTHORIZED_TOOLTIP,
} from "./permission-utils";
import { JourneyNodeRun, JourneyRun } from "./types";
import {
  getJourneyState,
  getNumberOfUniqueUsersInJourney,
  getNumberOfUsersInJourney,
  transformEdgeToReactFlowEdge,
  transformNodeToReactFlowNode,
} from "./utils";
import { getIsJourneyRunning } from "./utils/get-journey-running-state";
import { homeRoute } from "src/components/router/constants";
import { useEntitlements } from "src/hooks/use-entitlement";

type JourneyProps = {
  journey: NonNullable<JourneyQuery["journeys"][0]>;
  isRefetching?: boolean;
};

export const Journey: FC<JourneyProps> = ({ journey, isRefetching }) => {
  const { toast } = useToast();
  const { node_id } = useParams<{ node_id?: string }>();

  const [isEditMode, setIsEditMode] = useState(
    !journey.reset && journey.nodes.length === 0,
  );
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showJourneyRunErrorDialog, setShowJourneyRunErrorDialog] =
    useState(false);

  const updateJourneyPermission = getUpdateJourneyPermission(
    journey.parent_model_id,
  );

  const { isLoading, isPermitted } = useResourcePermission(
    updateJourneyPermission,
  );

  const unauthorized = isLoading ? true : !isPermitted;

  const latestJourneyRunQuery = useLatestJourneyRunsQuery(
    {
      id: journey.id,
      limit: 2,
    },
    {
      select: (data) => data.journey_runs,
    },
  );

  const formattedNodes = useMemo(
    () => journey.nodes.map(transformNodeToReactFlowNode),
    [journey],
  );

  const formattedEdges = useMemo(
    () => journey.edges.map(transformEdgeToReactFlowEdge),
    [journey],
  );

  const toggleEditMode = () => {
    if (!isEditMode && isJourneyResetting) {
      toast({
        id: "journey-update-error",
        title: "Journey is being reset",
        message:
          "Please wait for the journey to finish resetting before editing",
        variant: "error",
      });
      return;
    }

    setIsEditMode((prev) => !prev);
  };

  const isSettings = location.pathname.endsWith("/settings");
  const isMemberDetails =
    !isEditMode && location.pathname.endsWith("/member-details");
  const isDrawerOpen = Boolean(node_id) || isSettings || isMemberDetails;
  const journeyStatus = journey.status as JourneyStatus;
  const isJourneyDraining = journeyStatus === JourneyStatus.Draining;
  const numberOfUsersInJourney = getNumberOfUsersInJourney(journey.nodes);
  const numberOfUniqueUsersInJourney = getNumberOfUniqueUsersInJourney(
    journey.nodes,
  );
  const numberOfPreviousRuns = journey.runs_aggregate.aggregate?.count ?? 0;
  const parentModelId = Number(journey.parent_model_id); // This comes back as `any` type, but it's a number.
  const isJourneyResetting = Boolean(journey.reset);
  const journeyState = getJourneyState({
    reset: isJourneyResetting,
    status: journeyStatus,
    numberOfUsersInJourney,
    numberOfPreviousRuns,
  });
  const isJourneyLive = LIVE_STATES.includes(journeyState);
  const journeyRuns = latestJourneyRunQuery.data;
  const latestJourneyRun: JourneyRun | undefined = journeyRuns?.[0];
  const journeyRunError = latestJourneyRun?.error;

  const lastCompletedJourneyRun: JourneyRun | undefined = journeyRuns?.[1];
  const isJourneyRunning =
    isJourneyLive && getIsJourneyRunning(latestJourneyRun);

  const nodeRunStats: JourneyNodeRun[] =
    (isJourneyRunning && lastCompletedJourneyRun != null
      ? lastCompletedJourneyRun
      : latestJourneyRun
    )?.journey_node_runs.filter((run): run is JourneyNodeRun => run != null) ??
    [];
  const nodeRunErrors = nodeRunStats.filter((run) => run.error != null) ?? [];

  const { form, ...journeyGraphActions } = useJourneyGraph({
    id: journey.id ?? "",
    name: journey.name,
    description: journey.description,
    status: journeyStatus,
    exitCriteria:
      journey.exit_criteria == null
        ? NULL_EXIT_CRITERIA
        : journey.exit_criteria,
    schedule: journey.schedule,
    selectedNodeId: node_id,
    nodes: formattedNodes,
    edges: formattedEdges,
    onSave: () => setIsEditMode(false),
  });

  const showJourneyRunErrors = () => setShowJourneyRunErrorDialog(true);

  const cancelOrShowModal = () => {
    if (form.formState.isDirty) {
      setShowCancelConfirmation(true);
    } else {
      cancel();
    }
  };

  const cancel = () => {
    form.reset();
    setShowCancelConfirmation(false);
    setIsEditMode(false);
  };

  // Enable edit mode on mount for new, empty journeys
  useEffect(() => {
    if (
      node_id &&
      !isEditMode &&
      journeyState === "draft" &&
      !isJourneyResetting
    ) {
      setIsEditMode(true);
    }
  }, [node_id, isEditMode, journeyState, isJourneyResetting]);

  return (
    <Form form={form}>
      <GraphProvider
        versionId={journey.version_id}
        parentModelId={parentModelId}
        isEditMode={isEditMode}
        journeyState={journeyState}
        latestJourneyRun={latestJourneyRun}
        lastCompletedJourneyRun={lastCompletedJourneyRun}
        nodeRunErrors={nodeRunErrors}
        nodeRunStats={nodeRunStats}
        unauthorized={unauthorized}
        numberOfPreviousRuns={numberOfPreviousRuns}
        numberOfUsersInJourney={numberOfUsersInJourney}
        numberOfUniqueUsersInJourney={numberOfUniqueUsersInJourney}
        onToggleEditMode={toggleEditMode}
        onShowJourneyRunErrors={showJourneyRunErrors}
        updateJourneyPermission={updateJourneyPermission}
        {...journeyGraphActions}
      >
        <Column width="100%" height="100%">
          <Row
            align="center"
            gap={6}
            height="64px"
            flexShrink={0}
            boxShadow="0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)"
            justify="center"
            zIndex={1}
          >
            <Row justify="center" flex={1} minWidth={0}>
              <Row
                align="center"
                justify="space-between"
                gap={2}
                px={6}
                minWidth={0}
                flex={1}
              >
                <Row align="center" gap={2}>
                  <BackButton
                    warnNavigation={isEditMode && form.formState.isDirty}
                  />
                  {/* eslint-disable-next-line */}
                  {/* @ts-ignore Conditions type circularly references itself */}
                  <Controller
                    name="journey.name"
                    control={form.control}
                    render={({ field }) => (
                      <Text fontWeight="medium">
                        <PermissionedEditableHeading
                          isDisabled={!isEditMode}
                          unauthorizedTooltip={PERMISSION_UNAUTHORIZED_TOOLTIP}
                          permission={updateJourneyPermission}
                          width="auto"
                          size="lg"
                          // eslint-disable-next-line
                          // @ts-ignore Column circularly references itself
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </Text>
                    )}
                  />
                  <JourneyStatusBadge status={journeyState} />
                </Row>

                {isEditMode ? (
                  !isDrawerOpen && (
                    <Button onClick={cancelOrShowModal}>
                      {unauthorized ? "Exit view mode" : "Exit edit mode"}
                    </Button>
                  )
                ) : (
                  <JourneyActionButtons
                    isLoading={isRefetching}
                    numberOfPreviousRuns={numberOfPreviousRuns}
                    numberOfUsersInJourney={numberOfUsersInJourney}
                    reset={Boolean(journey.reset)}
                    status={journeyStatus}
                  />
                )}
              </Row>
            </Row>
          </Row>

          {journeyRunError && (
            <Alert
              variant="banner"
              type="error"
              justify="center"
              title="An error occurred during journey execution"
              message={null}
              actions={
                <Button onClick={showJourneyRunErrors}>View details</Button>
              }
            />
          )}

          <Row
            position="relative"
            minHeight={0}
            flex={1}
            mb={isDrawerOpen ? 0 : "72px"}
          >
            <Graph />
          </Row>
        </Column>
        {(!isEditMode || !isDrawerOpen) && (
          <JourneyFooter
            isJourneyResetting={isJourneyResetting}
            isJourneyDraining={isJourneyDraining}
            onLeaveEditMode={() => setIsEditMode(false)}
          />
        )}
      </GraphProvider>

      <ConfirmationDialog
        isOpen={showCancelConfirmation}
        title="Cancel editing"
        confirmButtonText="Discard updates"
        variant="warning"
        onCancel={() => setShowCancelConfirmation(false)}
        onClose={() => setShowCancelConfirmation(false)}
        onConfirm={cancel}
      >
        <Paragraph>
          Are you sure you want to cancel editing this journey? Your updates
          will be lost.
        </Paragraph>
      </ConfirmationDialog>

      <Dialog
        isOpen={showJourneyRunErrorDialog}
        title="Journey error details"
        variant="info"
        actions={
          <Button
            variant="primary"
            onClick={() => setShowJourneyRunErrorDialog(false)}
          >
            Close
          </Button>
        }
        onClose={() => setShowJourneyRunErrorDialog(false)}
      >
        <Column gap={2}>
          <Paragraph>{journeyRunError}</Paragraph>
        </Column>
      </Dialog>
    </Form>
  );
};

const BackButton = ({
  warnNavigation = false,
}: {
  warnNavigation?: boolean;
}) => {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);

  if (warnNavigation) {
    return (
      <>
        <IconButton
          mr={1}
          variant="secondary"
          icon={ArrowLeftIcon}
          aria-label="Back to journeys"
          onClick={() => setShowConfirmation(true)}
        />

        <ConfirmationDialog
          isOpen={showConfirmation}
          title="Unsaved changes"
          confirmButtonText="Leave"
          onConfirm={() => navigate("/journeys")}
          variant="warning"
          onClose={() => setShowConfirmation(false)}
        >
          <Paragraph>
            Are you sure you want to leave this page? Your changes will not be
            saved.
          </Paragraph>
        </ConfirmationDialog>
      </>
    );
  }

  return (
    <Link href="/journeys" mr={1}>
      <IconButton
        variant="secondary"
        icon={ArrowLeftIcon}
        aria-label="Back to journeys"
        onClick={noop}
      />
    </Link>
  );
};

const Loader = () => {
  const navigate = useNavigate();
  const { data: entitlementsData } = useEntitlements(false);
  const appEnableJourneys = entitlementsData.entitlements.journeys;

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!appEnableJourneys) {
      navigate(homeRoute);
    }
  }, [appEnableJourneys]);

  const journeyQuery = useJourneyQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      select: (data) => data.journeys[0] ?? null,
    },
  );

  const journey = journeyQuery.data;

  const header = (
    <>
      <PageHeader />

      <Helmet>
        <title>{journey?.name ?? "Journey"}</title>
      </Helmet>
    </>
  );

  if (journeyQuery.isLoading) {
    return <PageSpinner />;
  }

  if (!id || journey?.deleted) {
    return <Navigate replace to="/journeys" />;
  }

  if (!journey) {
    return (
      <>
        {header}
        <Warning
          subtitle="It may have been deleted"
          title="Journey not found"
        />
      </>
    );
  }

  return (
    <ReactFlowProvider>
      {header}
      <Journey journey={journey} isRefetching={journeyQuery.isRefetching} />
    </ReactFlowProvider>
  );
};

export default Loader;
