import { SyncIcon } from "@hightouchio/ui";

import { TileNode } from "./tile-node";
import { TileProps } from "./types";

export const SyncNode = (props: TileProps) => (
  <TileNode
    {...props}
    isTarget
    isSource
    colorScheme="cyan"
    icon={<SyncIcon />}
  />
);
