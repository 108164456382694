import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import {
  Tooltip,
  Button,
  useToast,
  useDisclosure,
  ConfirmationDialog,
  PlayIcon,
  PauseIcon,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { FC } from "react";
import {
  useDisableDecisionEngineFlowMutation,
  useEnableDecisionEngineFlowMutation,
  useUpdateDecisionEngineFlowMutation,
} from "src/graphql";

export const FlowActionButton: FC<
  Readonly<{
    id: string;
    status: DecisionEngineStatus;
    enabled: boolean;
    isValid: boolean;
    showIcon?: boolean;
  }>
> = ({ id, status, enabled, isValid, showIcon }) => {
  if (status === DecisionEngineStatus.TRAINING) {
    return (
      <Tooltip
        message="Start will be enabled once the agent is initialized"
        placement="left"
      >
        <Button icon={showIcon ? PlayIcon : undefined} isDisabled>
          Start
        </Button>
      </Tooltip>
    );
  }

  if (status === DecisionEngineStatus.READY) {
    if (enabled) {
      return <Pause id={id} showIcon={showIcon} />;
    } else {
      return <Start id={id} showIcon={showIcon} status={status} />;
    }
  }

  return <Initialize id={id} isValid={isValid} />;
};

const Start = ({
  id,
  showIcon,
  status,
}: {
  id: string;
  showIcon?: boolean;
  status: DecisionEngineStatus;
}) => {
  const { toast } = useToast();
  const updateMutation = useEnableDecisionEngineFlowMutation();

  return (
    <Button
      icon={showIcon ? PlayIcon : undefined}
      onClick={async () => {
        try {
          await updateMutation.mutateAsync({
            id,
            flowRunning: status === DecisionEngineStatus.READY,
          });
          toast({
            id: "start-flow",
            title: "Agent started",
            variant: "success",
          });
        } catch (e) {
          captureException(e);
          toast({
            id: "start-flow",
            title: "Failed to start agent",
            variant: "error",
          });
        }
      }}
    >
      Start
    </Button>
  );
};

const Pause = ({ id, showIcon }: { id: string; showIcon?: boolean }) => {
  const { toast } = useToast();
  const updateMutation = useDisableDecisionEngineFlowMutation();

  return (
    <Button
      icon={showIcon ? PauseIcon : undefined}
      onClick={async () => {
        try {
          await updateMutation.mutateAsync({
            id,
          });
          toast({
            id: "pause-flow",
            title: "Agent paused",
            variant: "success",
          });
        } catch (e) {
          captureException(e);
          toast({
            id: "pause-flow",
            title: "Failed to pause agent",
            variant: "error",
          });
        }
      }}
    >
      Pause
    </Button>
  );
};

export const Initialize = ({
  id,
  isValid,
}: {
  id: string;
  isValid: boolean;
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const updateMutation = useUpdateDecisionEngineFlowMutation();

  return (
    <>
      <Tooltip
        isDisabled={isValid}
        message="You must complete the setup tasks before initializing"
        placement="left"
      >
        <Button isDisabled={!isValid} onClick={onOpen}>
          {updateMutation.isLoading ? "Initializing..." : "Initialize"}
        </Button>
      </Tooltip>
      <ConfirmationDialog
        variant="warning"
        isOpen={isOpen}
        title="Initialize"
        confirmButtonText="Initialize"
        onClose={onClose}
        onConfirm={async () => {
          await updateMutation.mutateAsync({
            id,
            input: {
              status: DecisionEngineStatus.TRAINING,
            },
          });
        }}
      >
        Are you sure you want to initialize? Once initialization begins you will
        not be able to edit the configuration. You will still be able to edit,
        add, and remove messages.
      </ConfirmationDialog>
    </>
  );
};
