import { FC, useCallback, useEffect, useMemo, useState } from "react";

import {
  Box,
  BoxProps,
  Column,
  Paragraph,
  Row,
  Switch,
  Text,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useQueryClient } from "react-query";

import {
  GenerateVisualQueryQuery,
  useGenerateVisualQueryQuery,
  useGenerateAudienceSuggestionsQuery,
} from "src/graphql";
import { AndCondition, OrCondition } from "src/types/visual";
import { CopilotTextArea } from "src/components/copilot-text-area";
import { GCPIcon } from "src/ui/icons/logo-gcp";
import { load, save } from "src/utils/storage";

type MagicProps = BoxProps & {
  parentModelId: string;
  updateFilter: (filter: AndCondition | OrCondition) => void;
};

export const Magic: FC<Readonly<MagicProps>> = ({
  parentModelId,
  updateFilter,
  ...props
}) => {
  const [prompt, setPrompt] = useState<string>("");
  const [generating, setGenerating] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const client = useQueryClient();
  const { googleVertexAppCopyEnabled, llmClientSnowflakeFrontendCopy } =
    useFlags();

  const random = useMemo(() => {
    return Math.random();
  }, [isOpen]);

  const { data: liveSuggestions } = useGenerateAudienceSuggestionsQuery(
    { parentModelId },
    {
      enabled: Boolean(parentModelId) && isOpen,
      select: (data) =>
        data.generateAudienceSuggestions.suggestions
          ?.filter(Boolean)
          ?.sort(() => 0.5 - random)
          ?.slice(0, 3) ?? [],
    },
  );

  const suggestionsKey = `suggestions-${parentModelId}`;

  const cachedSuggestionString = load(suggestionsKey);

  useEffect(() => {
    if (liveSuggestions?.length && !cachedSuggestionString) {
      save(suggestionsKey, JSON.stringify(liveSuggestions));
    }
  }, [cachedSuggestionString]);

  const suggestions = cachedSuggestionString
    ? JSON.parse(cachedSuggestionString)
    : liveSuggestions;

  const getQuery = useCallback(
    async (parentModelId: string, userPrompt: string): Promise<void> => {
      setGenerating(true);
      setError(undefined);
      const variables = { parentModelId, userPrompt };
      const queryKey = useGenerateVisualQueryQuery.getKey(variables);
      const queryFn = useGenerateVisualQueryQuery.fetcher(variables);

      try {
        const response = await client.fetchQuery<GenerateVisualQueryQuery>(
          queryKey,
          {
            queryFn,
            cacheTime: 0,
          },
        );

        if (response.generateVisualQuery.__typename === "FailedModification") {
          setGenerating(false);
          setError("Error generating audience");
          return;
        }

        updateFilter(
          response.generateVisualQuery.generatedVisualQuery.conditions?.[0],
        );
        setPrompt("");
      } catch (_err) {
        setError("Error generating audience");
      } finally {
        setGenerating(false);
      }
    },
    [updateFilter],
  );

  return (
    <Column
      p={4}
      width="100%"
      alignItems="start"
      backgroundColor="electric.50"
      borderColor="electric.border"
      borderWidth="1px"
      borderRadius="md"
      gap={4}
      {...props}
    >
      <Column gap={1} width="100%">
        <Row
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          gap={2}
        >
          <Row
            alignItems="center"
            gap={2}
            sx={{
              "& > div > label > span[data-checked]": {
                background: "var(--chakra-colors-electric-base) !important",
              },
            }}
          >
            <Switch
              aria-label="Generate an audience with AI."
              isChecked={isOpen}
              onChange={setIsOpen}
            />
            <Text fontWeight="semibold" color="electric.base" size="lg">
              Generate an audience with AI
            </Text>
          </Row>
          {llmClientSnowflakeFrontendCopy && (
            <Text color="electric.base" size="sm">
              Powered by Snowflake Cortex
            </Text>
          )}
        </Row>
        <Paragraph color="text.secondary">
          Hightouch will help you generate an audience based on your data
          schema.
        </Paragraph>
        {googleVertexAppCopyEnabled && (
          <Row alignItems="center" gap={1.5} fontStyle="italic">
            <Paragraph color="text.secondary">
              Powered by Google Vertex AI
            </Paragraph>
            <GCPIcon width={5} />
          </Row>
        )}
      </Column>
      {isOpen && Boolean(suggestions?.length) && (
        <Column>
          <Text fontWeight="semibold" color="electric.base" mb={1}>
            Try these prompts:
          </Text>
          {suggestions?.map((s, index) => (
            <Box
              key={index}
              color="text.secondary"
              cursor="pointer"
              _hover={{ color: "electric.base" }}
              onClick={() => {
                if (!s?.title) {
                  return;
                }
                setPrompt(s.title);
                getQuery(parentModelId, s.title);
              }}
            >
              {s?.title}
            </Box>
          ))}
        </Column>
      )}

      {isOpen && (
        <Column gap={4} width="100%" maxWidth="600px">
          <CopilotTextArea
            placeholder="Describe an audience that you want to create..."
            value={prompt}
            isLoading={generating}
            onSubmit={(prompt) => {
              getQuery(parentModelId, prompt);
            }}
            clearOnSubmit={false}
          />
          {error && (
            <Text color="error.base" size="sm">
              {error}
            </Text>
          )}
        </Column>
      )}
    </Column>
  );
};
