import { FC } from "react";

import {
  Radio,
  RadioGroup,
  BadgeInput,
  Textarea,
  FormField,
  Column,
} from "@hightouchio/ui";

import { useDraft } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourceToPermission,
  useAuthorizedModelReviewersQuery,
  useAuthorizedSyncReviewersQuery,
  useMembersQuery,
  useUsersWithPermissionQuery,
} from "src/graphql";
import { omit } from "lodash";

// Human readable names that we use for analytics tracking.
export const saveAndRequestApproval = "saved with approval";
export const saveOnly = "saved";
export const publishNow = "published";

export type ApproverOptions =
  | { type: "sync"; syncId: string }
  | { type: "model"; modelId: string }
  | { type: "sync"; modelId: string; destinationId: string }
  | { type: "model"; sourceId: string };

interface Props {
  resource: ResourceToPermission;
  selectedRadioOption: string;
  setSelectedRadioOption: (option: string) => void;
  canApprove: boolean;
  comment: string;
  setComment: (comment: string) => void;
  selectedUsers: number[];
  setSelectedUsers: (users: number[]) => void;
  approverOptions: ApproverOptions;
}

export const DraftSubmissionForm: FC<Props> = ({
  selectedRadioOption,
  setSelectedRadioOption,
  comment,
  setComment,
  resource,
  canApprove,
  selectedUsers,
  setSelectedUsers,
  approverOptions,
}) => {
  const { workspace, user } = useUser();
  const permissionsV2Enabled = user?.permissions_v2_enabled;
  const { draft } = useDraft();
  const { data: v1Users } = useUsersWithPermissionQuery(
    {
      permission: {
        resource_type: resource,
        access_type: "approve",
      },
    },
    {
      enabled: !permissionsV2Enabled,
      select: (data) => new Set(data?.getUsersWithPermissions),
    },
  );

  const { data: v2ModelUsers } = useAuthorizedModelReviewersQuery(
    approverOptions.type === "model" ? omit(approverOptions, "type") : {},
    {
      enabled: Boolean(
        resource === ResourceToPermission.Model && permissionsV2Enabled,
      ),
      select: (data) => new Set(data?.authorizedDraftModelReviewers.userIds),
    },
  );

  const { data: v2SyncUsers } = useAuthorizedSyncReviewersQuery(
    approverOptions.type === "sync" ? omit(approverOptions, "type") : {},
    {
      enabled: Boolean(
        resource === ResourceToPermission.Sync && permissionsV2Enabled,
      ),
      select: (data) => new Set(data?.authorizedDraftSyncReviewers.userIds),
    },
  );

  const users =
    (permissionsV2Enabled
      ? resource === ResourceToPermission.Model
        ? v2ModelUsers
        : v2SyncUsers
      : v1Users) ?? new Set();

  const { data: memberships } = useMembersQuery(
    { workspaceId: workspace?.id },
    { select: (data) => data.all_memberships_v2 },
  );

  const authorizedReviewers =
    memberships
      ?.filter((membership) => membership.user_id !== user?.id)
      .filter((membership) => users.has(membership.user_id.toString()))
      .map((membership) => {
        // the user is defined, but becasue its a not a foreign key in hasura, it is a maybe.
        return {
          label: membership.user?.name || "",
          value: membership.user?.id || "",
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const baseRadioOptions = [
    {
      label:
        (draft?.approval_requests || []).length > 0
          ? "Resubmit approval request"
          : "Submit approval request",
      value: saveAndRequestApproval,
      description: `Notify teammates to approve and publish this ${resource}.`,
    },
    {
      label:
        (draft?.approval_requests || []).length > 0
          ? "Update draft"
          : "Save as draft",
      value: saveOnly,
      description: `Continue editing this ${resource} until you're ready to request approval.`,
    },
  ];

  let publishNowDescription = `Publish this ${resource} directly without requesting approval.`;
  if (resource === ResourceToPermission.Sync) {
    publishNowDescription = `${publishNowDescription} This will also publish any pending dependent model drafts.`;
  }

  const createOption = {
    label: "Publish now",
    value: publishNow,
    description: publishNowDescription,
  };
  const radioGroupOptions = canApprove
    ? [createOption, ...baseRadioOptions]
    : baseRadioOptions;

  return (
    <Column gap={4}>
      <RadioGroup value={selectedRadioOption} onChange={setSelectedRadioOption}>
        {radioGroupOptions.map((option) => (
          <Radio key={option.value} {...option} />
        ))}
      </RadioGroup>

      {selectedRadioOption === saveAndRequestApproval && (
        <>
          <FormField label="Select teammates to notify">
            <BadgeInput
              options={authorizedReviewers ?? []}
              placeholder="Select teammates"
              value={selectedUsers}
              onChange={(values) => {
                setSelectedUsers(values);
              }}
            />
          </FormField>
          <FormField label="Comment" isOptional>
            <Textarea
              placeholder="Leave a comment with your request (optional)"
              rows={4}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />
          </FormField>
        </>
      )}
    </Column>
  );
};
