import { useMemo } from "react";

import { Form, useHightouchForm } from "src/components/form";
import { useUpdateEventMutation } from "src/graphql";
import { SchemaFieldForm } from "src/pages/schema/types";
import { isTimestampColumn } from "src/utils/models";
import { SchemaField } from "src/pages/schema/types";
import { ColumnField } from "./column-field";

export const TimestampForm: SchemaFieldForm = ({
  object,
  hasUpdatePermission,
}) => {
  const { mutateAsync: updateEvent } = useUpdateEventMutation();

  const form = useHightouchForm({
    submitOnChange: true,
    onSubmit: async ({ timestamp_column }) => {
      await updateEvent({
        id: object.id,
        set: {
          timestamp_column,
        },
      });
    },
    values: {
      timestamp_column: object.event?.timestamp_column || "",
    },
  });

  return (
    <Form form={form}>
      <TimestampField
        object={object}
        isDisabled={!hasUpdatePermission}
        isLoading={form.formState.isSubmitting}
      />
    </Form>
  );
};

export const TimestampField: SchemaField = (props) => {
  const object: any = useMemo(
    () => ({
      ...props.object,
      columns: props.object?.columns?.filter(isTimestampColumn),
    }),
    [props.object],
  );

  return (
    <ColumnField
      name="timestamp_column"
      label="Timestamp"
      tip="Choose a column that represents when this event occurred. E.g. “created_at”"
      {...props}
      object={props.object ? object : undefined}
    />
  );
};
