import { useMemo } from "react";

import get from "lodash/get";
import merge from "lodash/merge";
import uniq from "lodash/uniq";
import { isPresent } from "ts-extras";

import { useAllFilterableColumnsQuery } from "src/graphql";

type MinimalTraitDefinition = {
  id: string;
  relationship: {
    to_model: {
      id: string;
    } | null;
  } | null;
};

/**
 * Hydrates the relationships in trait columns with their filterable audience columns.
 */
export const useHydrateTraitsWithFilterableAudienceColumns = <
  T extends MinimalTraitDefinition,
>(
  traits: T[],
) => {
  const segmentIds = useMemo(
    () =>
      uniq(
        traits
          .map((trait) => trait.relationship?.to_model?.id)
          .filter(isPresent),
      ),
    [traits],
  );

  const { data: filterableColumns, isLoading } = useAllFilterableColumnsQuery(
    {
      segmentIds,
    },
    {
      enabled: segmentIds.length > 0,
      select: (data) =>
        data.segments.reduce((dictionary, model) => {
          // Add columns by model id
          dictionary[model.id.toString()] = model.filterable_audience_columns;

          return dictionary;
        }, {}),
    },
  );

  const traitsWithFilterableColumns = useMemo(() => {
    return traits.map((trait) =>
      merge({}, trait, {
        relationship: {
          to_model: {
            filterable_audience_columns: get(
              filterableColumns,
              trait.relationship?.to_model?.id ?? "",
            ),
          },
        },
      }),
    );
  }, [traits, filterableColumns]);

  return {
    traitsWithFilterableColumns,
    isLoading,
  };
};
