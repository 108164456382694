import {
  AudienceIcon,
  Button,
  Column,
  IconButton,
  RefreshIcon,
  Row,
  SectionHeading,
  Spinner,
  Switch,
  Text,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";

import { useOutletContext } from "src/router";
import { QueryBuilder } from "src/components/explore";
import { AndCondition, ConditionType } from "src/types/visual";
import { useCalculateAudienceSize } from "src/utils/use-calculate-audience-size";
import { FlowMessageContext } from "..";
import { isAndOrCondition } from "src/components/explore/utils/type-guards";
import { commaNumber } from "src/utils/numbers";
import { useFetchQueryBuilderParentModel } from "src/components/explore/hooks";

export const Eligibility = ({
  showToggle,
  setUseEligibilityV1,
}: {
  showToggle: boolean;
  setUseEligibilityV1: () => void;
}) => {
  const { flowMessage, flow, engine } = useOutletContext<FlowMessageContext>();
  const { watch } = useFormContext();
  const parentModelId = engine.segment.id;

  const { parentModel, isLoading: isParentModelLoading } =
    useFetchQueryBuilderParentModel({ parentModelId });

  const filter = watch("flowMessageConfig.userAudienceFilter");
  const filterConditions = isAndOrCondition(filter)
    ? filter
    : ({
        type: ConditionType.And,
        conditions: [],
      } as AndCondition);

  const audienceConditions =
    flow.audience?.visual_query_filter.conditions ?? [];

  // TODO: Make this visually match the audience builder
  const {
    calculateAudienceSize,
    audienceSize,
    isLoading: isAudienceSizeLoading,
  } = useCalculateAudienceSize({
    audienceId: String(flow.audience?.id),
    parentModelId: String(parentModelId),
    sourceId: String(engine.segment.connection?.id),
    visualQueryFilter: {
      // Merge together the additional message eligibility filters with the
      // flow audience filter
      conditions: [
        {
          type: ConditionType.And,
          conditions: [filterConditions, ...audienceConditions],
        },
      ],
    },
    // Not passing subset IDs will cause the backend to fallback to the subset IDs on the audience
    subsetIds: undefined,
    isFastQuery: false,
    destinationId: String(flowMessage.message.channel.destination.id),
  });

  return (
    <Column gap={4}>
      <Row width="100%" justify="space-between">
        <Column>
          <SectionHeading>Eligibility</SectionHeading>
          <Text>
            This message will only be sent to users who meet the following
            criteria
          </Text>
        </Column>
        <Row gap={4}>
          {showToggle ? (
            <Row alignItems="center" gap={2}>
              <Switch
                aria-label="Use audience-based filtering."
                onChange={setUseEligibilityV1}
                isChecked={true}
              />
              <Text fontWeight="medium">Use audience-based-filtering</Text>
            </Row>
          ) : null}
          <Row alignItems="center" gap={4}>
            {audienceSize && (
              <Row gap={1} alignItems="center">
                <AudienceIcon />

                <Text fontWeight="semibold">
                  {commaNumber(audienceSize?.count ?? 0)}
                </Text>
              </Row>
            )}
            {audienceSize ? (
              <IconButton
                variant="secondary"
                icon={RefreshIcon}
                aria-label="Refresh audience size"
                isLoading={isAudienceSizeLoading}
                onClick={() => {
                  calculateAudienceSize();
                }}
              />
            ) : (
              <Button
                icon={AudienceIcon}
                isLoading={isAudienceSizeLoading}
                onClick={() => {
                  calculateAudienceSize();
                }}
              >
                Calculate size
              </Button>
            )}
          </Row>
        </Row>
      </Row>
      {isParentModelLoading ? (
        <Spinner />
      ) : (
        <Controller
          name="flowMessageConfig.userAudienceFilter"
          render={({ field }) => {
            return (
              <Column gap={2}>
                <QueryBuilder
                  parentModel={parentModel}
                  filter={filterConditions}
                  setConditions={field.onChange}
                />
              </Column>
            );
          }}
        />
      )}
    </Column>
  );
};
