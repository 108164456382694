import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import * as Sentry from "@sentry/react";
import { useQueryClient } from "react-query";
import type { MarkOptional } from "ts-essentials";
import { v4 as uuid } from "uuid";

import {
  BackgroundJobResult,
  QueryResponse,
  RunJourneyNodeMembersBackgroundQuery,
  RunJourneyNodeMembersBackgroundQueryVariables,
  SuccessfulQueryResponse,
  useRunJourneyNodeMembersBackgroundQuery,
  useRunSqlResultQuery,
} from "src/graphql";
import { QueryType } from "src/types/models";
import { aliasRow, ModelState } from "src/utils/models";
import type { JourneyEventType } from "src/pages/journeys/types";

function getBackgroundInnerQueryResult(
  result: RunJourneyNodeMembersBackgroundQuery,
): BackgroundJobResult {
  return Object.values(result)[0] as BackgroundJobResult;
}

type JourneyNodeMemberHookRunOptions = {
  columns?: Array<{
    name: string;
    alias: string | null;
    disable_preview: boolean;
    redacted_text: string | null;
  }>;
};

export const useJourneyNodeMemberHook = (
  model: Readonly<ModelState>,
  options?: JourneyNodeMemberHookRunOptions,
) => {
  // Store the function to cancel the current query with the query's key.
  const cancelQueryRef = useRef<() => Promise<void>>();

  const client = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [backgroundJobId, setBackgroundJobId] = useState<string>();
  const [shouldPollForResults, setShouldPollForResults] =
    useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [runId, setRunId] = useState<string | undefined>("");
  const [result, setResult] = useState<{
    data?: MarkOptional<
      SuccessfulQueryResponse,
      "rows" | "numRowsWithoutLimit" | "sql" | "rowsCount"
    >;
    id: string;
  }>({ id: "" });
  const initialColumns = useMemo(
    () => options?.columns ?? model.columns ?? [],
    [options?.columns, model?.columns],
  );

  useEffect(() => {
    if (!backgroundJobId || backgroundJobId !== runId) {
      return;
    }
    setLoading(true);
    setShouldPollForResults(true);
  }, [backgroundJobId, runId]);

  const cancelQuery = async () => {
    await cancelQueryRef.current?.();
    setRunId("");
    setLoading(false);
    setError(undefined);
    setBackgroundJobId(undefined);
    setShouldPollForResults(false);
    setResult({
      ...result,
      data: undefined,
    });

    // reset cancel function.
    cancelQueryRef.current = undefined;
  };

  const resetRunState = () => {
    setRunId("");
    setLoading(false);
    setError("");
    setResult({ id: "" });
  };

  useRunSqlResultQuery(
    {
      jobId: backgroundJobId ?? "",
      page: 0,
    },
    {
      enabled: Boolean(
        shouldPollForResults &&
          backgroundJobId &&
          model.query_type !== QueryType.Visual,
      ),
      keepPreviousData: true,
      refetchInterval: 800,
      onError: (error) => {
        Sentry.captureException(error);
        handleErrorResponse(error.message, backgroundJobId || "");
        setShouldPollForResults(false);
      },
      onSuccess: (data) => {
        if (!data?.backgroundPreviewQueryResult) {
          return;
        }
        handleSuccessResponse(
          data.backgroundPreviewQueryResult,
          backgroundJobId || "",
        );
        setShouldPollForResults(false);
      },
    },
  );

  const handleErrorResponse = (message: string, id: string) => {
    setError(message);
    setLoading(false);
    setResult({ id, data: undefined });
  };

  const handleSuccessResponse = (responseResult: QueryResponse, id: string) => {
    setLoading(false);

    if (responseResult && responseResult.__typename === "FailedQueryResponse") {
      setError(responseResult.error);
      setResult({ id, data: undefined });
    } else {
      setError(undefined);
      setResult({ id, data: responseResult as SuccessfulQueryResponse });
    }
  };

  const runQuery = useCallback(
    async (runOptions: {
      journeyId: string;
      nodeId?: string;
      direction?: string;
      eventType?: JourneyEventType;
    }): Promise<void> => {
      setLoading(true);
      const id = uuid();
      setRunId(id);

      const variables: RunJourneyNodeMembersBackgroundQueryVariables = {
        sourceId: String(model.connection!.id),
        journeyId: runOptions.journeyId,
        nodeId: runOptions.nodeId,
        direction: runOptions.direction,
        eventType: runOptions.eventType,
        disableRowCounter: true,
        limit: 100,
      };

      try {
        const queryKey =
          useRunJourneyNodeMembersBackgroundQuery.getKey(variables);
        const queryFn =
          useRunJourneyNodeMembersBackgroundQuery.fetcher(variables);

        // set ref to cancel the current query
        cancelQueryRef.current = () => client.cancelQueries(queryKey);

        const response =
          await client.fetchQuery<RunJourneyNodeMembersBackgroundQuery>(
            queryKey,
            {
              queryFn,
              cacheTime: 0,
            },
          );

        // If the cancel function has been reset then the query was cancelled.
        if (!cancelQueryRef.current) {
          return;
        }

        const responseResult = getBackgroundInnerQueryResult(response);
        setBackgroundJobId(responseResult.jobId);

        // Update the run ID here so when the response comes back, we know
        // whether it is still to the request we care about.
        setRunId(responseResult.jobId);
        setShouldPollForResults(true);
      } catch (err) {
        handleErrorResponse(err.message, id);
        Sentry.captureEvent(err);
      }
    },
    [client, model, options],
  );

  const rows = useMemo(() => {
    return result.data?.rows?.map((row) => aliasRow(row, initialColumns));
  }, [result.data?.rows]);

  return {
    runQuery,
    cancelQuery,
    resetRunState,
    loading,
    error,
    rows,
  };
};
