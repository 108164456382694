import {
  Alert,
  Box,
  ButtonGroup,
  Column,
  ExternalLinkIcon,
  Row,
  SectionHeading,
  Skeleton,
  Spinner,
  Text,
  useToast,
} from "@hightouchio/ui";
import { useMemo } from "react";
import { Link, LinkButton, useOutletContext } from "src/router";

import {
  DecisionEngineStatus,
  VariableDB,
} from "@hightouch/lib/customer-data/decision-engine/types";
import { Card } from "src/components/card";
import { PermissionedButton } from "src/components/permission";
import { useUpdateSyncMutation } from "src/graphql";
import { GroupByColumn } from "src/pages/analytics/types";
import { getChannelDefinition } from "src/pages/decision-engines/definitions";
import {
  Section,
  SectionHeader,
} from "src/pages/decision-engines/flows/flow/components/overview-sidebar";
import { SendsByBreakdownChart } from "src/pages/decision-engines/flows/flow/components/sends-by-breakdown-chart";
import { SendsOverTimeChart } from "src/pages/decision-engines/flows/flow/components/sends-over-time-chart";
import { SendsSummary } from "src/pages/decision-engines/flows/flow/components/sends-summary";
import {
  ACTION_FEATURES_COLUMN_TYPE,
  filterByMessageIdCondition,
} from "src/pages/decision-engines/flows/utils";
import { FlowMessage, FlowMessageContext } from ".";
import {
  ActionError,
  ActionStatus,
  ActionStatusBadge,
  useActionStatus,
} from "src/pages/decision-engines/flows/flow/messages/status";
import { isDraftAction } from "src/pages/decision-engines/flows/flow/messages/utils";
import { Channel } from "./components/campaign";
import {
  DestinationResourceLink,
  useDestinationResource,
} from "./components/campaign-link";
import { DraftAction } from "./components/draft-action";
import { useFlowTimestamps } from "src/pages/decision-engines/flows/flow/use-flow-timestamps";
import { AnalyticsPlaceholderImage } from "src/pages/analytics/placeholders";

const DefaultBreakdownColumnOptions: { label: string; value: GroupByColumn }[] =
  [
    {
      label: "Day of week",
      value: {
        type: ACTION_FEATURES_COLUMN_TYPE,
        name: "day_of_week",
        alias: "Day of week",
      },
    },
    {
      label: "Time of day",
      value: {
        type: ACTION_FEATURES_COLUMN_TYPE,
        name: "time_of_day",
        alias: "Time of day",
      },
    },
  ];

export const Overview = () => {
  const { flowMessage, flow } = useOutletContext<FlowMessageContext>();

  // There should only be one sync per action created by the decision engine
  const actionSync = flowMessage.model?.syncs?.[0];

  const isDraft = isDraftAction(actionSync, flowMessage.enabled);

  const { flowStartDate, lastFlowRunStartedAt, isLoading } = useFlowTimestamps({
    flow,
  });

  const breakdownColumnOptions: {
    label: string;
    value: GroupByColumn;
  }[] = useMemo(() => {
    const messageVariables: VariableDB[] = flowMessage.message.variables ?? [];
    const variableOptions: { label: string; value: GroupByColumn }[] = [];

    for (const v of messageVariables) {
      // Only want to include if there are values specified for the feature
      if (v?.name && v?.variants?.length) {
        variableOptions.push({
          label: v.name,
          value: {
            type: ACTION_FEATURES_COLUMN_TYPE,
            name: v.name,
          },
        });
      }
    }

    return [...variableOptions, ...DefaultBreakdownColumnOptions];
  }, [flowMessage.message.variables]);

  const {
    status,
    error,
    isLoading: isActionStatusLoading,
  } = useActionStatus({
    sync: actionSync,
    channel: flowMessage.message.channel,
    resourceId: flowMessage.config.campaignId ?? flowMessage.config.resourceId,
    enabled: flowMessage.enabled,
    agentEnabled: flow.enabled,
  });

  if (isLoading) {
    return (
      <Column h="100%" justify="center" align="center">
        <Spinner size="lg" />
      </Column>
    );
  }

  if (isDraft) {
    return (
      <Column flex={1} gap={6} pb={6} maxW="2xl" mx="auto">
        <DraftAction flowId={flow.id} flowMessage={flowMessage} />
      </Column>
    );
  }

  const sidebar = (
    <Sidebar
      status={status}
      config={flowMessage.config}
      syncId={actionSync?.id}
      channel={flowMessage.message.channel}
      isLoading={isActionStatusLoading}
    />
  );

  const flowIsReady =
    flow.status === DecisionEngineStatus.READY && flow.enabled;

  if (!flowStartDate || !lastFlowRunStartedAt || !flowIsReady) {
    const heading = !flowIsReady
      ? "This agent is not running"
      : "This agent has not completed a run yet";

    const message = !flowIsReady
      ? "This action will start being scheduled once the agent is running"
      : "Check back later to see detailed performance data and insights";

    return (
      <Row gap={4} justify="space-between">
        <Card flex={1} alignItems="center" justifyContent="center" gap={2}>
          <AnalyticsPlaceholderImage />
          <SectionHeading>{heading}</SectionHeading>
          <Text>{message}</Text>
        </Card>
        {sidebar}
      </Row>
    );
  }

  return (
    <Column flex={1}>
      {status === ActionStatus.ERROR && (
        <ActionErrorAlert
          error={error}
          channel={flowMessage.message.channel}
          resourceId={
            flowMessage.config.campaignId ?? flowMessage.config.resourceId
          }
          syncId={actionSync?.id}
        />
      )}
      <Row gap={4} justify="space-between">
        <Column gap={6} flex="1">
          <SendsSummary
            flowId={flow.id}
            parentModelId={flow.audience?.parent?.id}
            flowStartDate={flowStartDate}
            lastFlowRunStartedAt={lastFlowRunStartedAt}
            messageId={flowMessage.message.id}
            emptyTotalSendsAlertMessage="This action will be scheduled in the following run, after the next set of actions are determined."
          />
          <SendsOverTimeChart
            flowId={flow.id}
            parentModelId={flow.audience?.parent?.id}
            flowMessages={flow.messages}
            lastFlowRunStartedAt={lastFlowRunStartedAt}
            flowStartDate={flowStartDate}
            conditions={[filterByMessageIdCondition(flowMessage.message.id)]}
          />
          <SendsByBreakdownChart
            flowId={flow.id}
            parentModelId={flow.audience?.parent?.id}
            flowMessages={flow.messages}
            lastFlowRunStartedAt={lastFlowRunStartedAt}
            flowStartDate={flowStartDate}
            conditions={[filterByMessageIdCondition(flowMessage.message.id)]}
            breakdownOptions={breakdownColumnOptions}
            defaultBreakdownColumn={
              breakdownColumnOptions[0]?.value as GroupByColumn
            }
          />
        </Column>
        {sidebar}
      </Row>
    </Column>
  );
};

const Sidebar = ({
  config,
  syncId,
  channel,
  status,
  isLoading,
}: {
  config: FlowMessage["config"];
  syncId: string | null | undefined;
  channel: FlowMessage["message"]["channel"];
  status: ActionStatus | null;
  isLoading: boolean;
}) => {
  const destination = channel.destination;
  const channelDefinition = getChannelDefinition(destination.type);
  const resourceName = channelDefinition.getResourceName(channel.config);

  return (
    <Card gap={4} p={6} w="288px" h="min-content">
      <Section>
        <SectionHeader text="Status" />
        <Skeleton isLoading={isLoading}>
          <ActionStatusBadge status={status} />
        </Skeleton>
      </Section>
      <Section>
        <SectionHeader
          text={`${channel.destination.definition.name} ${resourceName}`}
        />
        <DestinationResourceLink
          channel={channel}
          resourceId={config.resourceId ?? config.campaignId}
          showInactiveTooltip={false}
        />
        {syncId && (
          <Row gap={1} align="center">
            <Text color="text.secondary" size="sm">
              Triggered by this
            </Text>
            <Link href={`/syncs/${syncId}`} fontSize="sm">
              Hightouch sync
            </Link>
          </Row>
        )}
      </Section>
    </Card>
  );
};

const ActionErrorAlert = ({
  error,
  channel,
  resourceId,
  syncId,
}: {
  error: ActionError | null;
  channel: Channel;
  resourceId: string;
  syncId: string | null | undefined;
}) => {
  if (!syncId) {
    return null;
  }

  const { url } = useDestinationResource({
    channel,
    resourceId,
  });

  const { mutateAsync: updateSync } = useUpdateSyncMutation();
  const { toast } = useToast();

  // Campaign errors
  if (error === ActionError.MISSING_CAMPAIGN) {
    return (
      <Alert
        variant="inline"
        type="error"
        mb={6}
        title="Can’t find Iterable campaign"
        message="The campaign that was connected to this action may have been deleted."
      />
    );
  }

  if (error === ActionError.CAMPAIGN_DEACTIVATED) {
    return (
      <Alert
        variant="inline"
        type="error"
        mb={6}
        title="Iterable campaign is deactivated"
        message="The campaign that was connected to this action is deactivated. This may be preventing 
          AI Decisioning from sending messages."
        actions={
          <Link isExternal href={url} fontSize="md">
            Go to campaign
            <Box
              as={ExternalLinkIcon}
              fontSize={20}
              /* This is to center align the icon with the preceding text while keeping the icon inline. */
              transform="translateY(-1px)"
              ml={1}
            />
          </Link>
        }
      />
    );
  }

  // Sync errors
  if (error === ActionError.SYNC_MISSING_SCHEDULE && syncId) {
    const addHourlyScheduleToSync = async () => {
      try {
        await updateSync({
          id: syncId,
          object: {
            schedule: { type: "cron", schedule: { expression: "0 * * * *" } },
            approved_draft_id: null,
          },
        });

        toast({
          id: "enable-sync-action",
          title: `Sync schedule was added`,
          variant: "success",
        });
      } catch (error) {
        toast({
          id: "enable-sync-action",
          title: `Sync schedule could not be added`,
          message: error.message,
          variant: "error",
        });
      }
    };

    return (
      <Alert
        variant="inline"
        type="error"
        mb={6}
        title="Hightouch sync is missing a schedule"
        message="The Hightouch sync tied to this action has no schedule attached to it. 
          This action gets triggered through a sync, and the sync must have a recurring schedule for 
          AI Decisioning to send messages."
        actions={
          <ButtonGroup>
            <PermissionedButton
              variant="secondary"
              onClick={addHourlyScheduleToSync}
              permission={{
                v2: {
                  resource: "sync",
                  grant: "can_update",
                  id: syncId,
                },
              }}
            >
              Add hourly schedule to sync
            </PermissionedButton>
            <LinkButton href={`/syncs/${syncId}/schedule`}>
              Go to sync
            </LinkButton>
          </ButtonGroup>
        }
      />
    );
  }

  if (error === ActionError.SYNC_DISABLED && syncId) {
    const enableSync = async () => {
      try {
        await updateSync({
          id: syncId,
          object: {
            schedule_paused: false,
          },
        });

        toast({
          id: "enable-sync-action",
          title: `Sync was enabled`,
          variant: "success",
        });
      } catch (error) {
        toast({
          id: "enable-sync-action",
          title: `Sync could not be enabled`,
          message: error.message,
          variant: "error",
        });
      }
    };

    return (
      <Alert
        variant="inline"
        type="error"
        mb={6}
        title="Hightouch sync is disabled"
        message="The Hightouch sync tied to this action is disabled. This action gets triggered through
          a sync, and the sync must be enabled for AI Decisioning to send messages."
        actions={
          <PermissionedButton
            variant="secondary"
            onClick={enableSync}
            permission={{
              v2: {
                resource: "sync",
                grant: "can_run",
                id: syncId,
              },
            }}
          >
            Enable sync
          </PermissionedButton>
        }
      />
    );
  }

  if (error === ActionError.SYNC_UNHEALTHY && syncId) {
    return (
      <Alert
        variant="inline"
        type="error"
        mb={6}
        title="Hightouch sync is unhealthy"
        message="The Hightouch sync tied to this action is unhealthy. This may be preventing 
          AI Decisioning from sending messages."
        actions={<LinkButton href={`/syncs/${syncId}`}>Go to sync</LinkButton>}
      />
    );
  }

  return null;
};
