import { FC, useCallback, useState } from "react";

import {
  ArrowRightIcon,
  Column,
  Row,
  SectionHeading,
  Text,
} from "@hightouchio/ui";
import { format, parseISO, startOfDay } from "date-fns";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
  ReferenceLine,
} from "recharts";

import { commaNumber } from "src/utils/numbers";
import { quantityTickFormatter } from "src/components/charts/chart-utils";
import { Card } from "src/components/card";

const TOOLTIP_CONSTANTS = {
  succeeded: { color: "success.base", label: "completed", order: 0 },
  warning: { color: "warning.base", label: "completed with errors", order: 1 },
  failed: { color: "danger.base", label: "failed", order: 2 },
  successful_operations: {
    color: "success.base",
    label: "successful",
    order: 0,
  },
  failed_operations: { color: "danger.base", label: "rejected", order: 1 },
};

interface Props {
  data: any[];
  title: string;
  description: string;
  emptyText: string;
  isEmpty: boolean;
  onClick: (activeIndex: number) => void;
  bars: {
    dataKey: string;
    stackId: string;
    fill: string;
    highlightedFill: string;
  }[];
}

export const SyncHealthChart: FC<Props> = ({
  data,
  bars,
  title,
  emptyText,
  isEmpty,
  onClick,
}) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleMouseMove = useCallback(
    (e) => {
      if (e && e.activeTooltipIndex !== undefined) {
        setActiveIndex(e.activeTooltipIndex);
      }
    },
    [setActiveIndex],
  );

  const handleMouseLeave = useCallback(
    () => setActiveIndex(-1),
    [setActiveIndex],
  );

  return (
    <Card minH={300} gap={4}>
      <Row justify="space-between" align="center" gap={4}>
        <SectionHeading>{title}</SectionHeading>
      </Row>
      {isEmpty && (
        <Text color="text.secondary" fontWeight="medium">
          {emptyText}
        </Text>
      )}

      <ResponsiveContainer height="100%" width="100%">
        <BarChart
          margin={{ top: 20, bottom: -20, right: 40, left: -20 }}
          data={data}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
        >
          <CartesianGrid vertical={false} stroke="#E5E9ED" />
          <XAxis
            dataKey="time_bucket"
            tickMargin={4}
            minTickGap={0}
            fontSize={12}
            tickFormatter={timeTickFormatter}
            height={50}
            ticks={generateDayStartTicks(data)}
            axisLine={{ stroke: "var(--chakra-colors-base-border)" }}
            tickLine={{ stroke: "var(--chakra-colors-base-border)" }}
          />
          <YAxis
            dataKey="total"
            tickFormatter={quantityTickFormatter}
            tickCount={4}
            axisLine={false}
            tickLine={false}
            allowDecimals={false}
            fontSize={12}
          />
          <Tooltip
            content={SyncHealthTooltip}
            offset={20}
            position={{ y: -20 }}
            animationDuration={150}
            wrapperStyle={{ outline: "none" }}
            cursor={false}
          />

          <Bar
            dataKey="total"
            shape={
              <CustomStackedBar
                dataKeys={bars.map((bar) => bar.dataKey)}
                fills={bars.map((bar) => bar.fill)}
                highlightedFills={bars.map((bar) => bar.highlightedFill)}
                activeIndex={activeIndex}
                minH={5}
              />
            }
          />
          <ReferenceLine
            x={data[activeIndex]?.time_bucket}
            stroke="var(--chakra-colors-base-divider)"
            strokeWidth="1.3%"
            opacity={0.5}
            cursor={data[activeIndex]?.total ? "pointer" : "default"}
            pointerEvents={data[activeIndex]?.total ? "auto" : "none"}
            onClick={() => {
              onClick(activeIndex);
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
};

function generateDayStartTicks(data) {
  if (!data || data.length === 0) return [];

  const uniqueDays = new Map();

  data.forEach((entry) => {
    // Parse the time_bucket (assumes ISO string like "2025-03-26T00:00:00Z")
    const date = parseISO(entry.time_bucket);
    // Get the start of the day in the browser's local timezone
    const localDayStart = startOfDay(date); // 00:00 in local timezone
    const dayKey = format(localDayStart, "yyyy-MM-dd");

    // Calculate the difference from the local 00:00 in hours
    const hoursDiff = Math.abs(
      (date.getTime() - localDayStart.getTime()) / (1000 * 60 * 60),
    );

    // Pick the bucket closest to 00:00 local time
    if (
      !uniqueDays.has(dayKey) ||
      hoursDiff <
        Math.abs(
          (parseISO(uniqueDays.get(dayKey)).getTime() -
            localDayStart.getTime()) /
            (1000 * 60 * 60),
        )
    ) {
      uniqueDays.set(dayKey, entry.time_bucket);
    }
  });

  // Return all day ticks sorted chronologically
  return Array.from(uniqueDays.values()).sort((a, b) => {
    return parseISO(a).getTime() - parseISO(b).getTime();
  });
}

function timeTickFormatter(tick: string): string {
  const parsed = parseISO(tick);
  return format(parsed, "EEEE");
}

const SyncHealthTooltip: FC<TooltipProps<number, string>> = ({ payload }) => {
  const data = payload?.[0]?.payload;

  if (!data) return null;

  const rawTime = parseISO(data.time_bucket);

  // Calculate the start and end times for the time range
  // Assuming each time bucket represents a 2-hour range
  const startTime = rawTime;
  const endTime = new Date(startTime.getTime() + 2 * 60 * 60 * 1000); // Add 2 hours

  // Format the time range
  const timeRange = `${format(startTime, "HH:mm")} - ${format(endTime, "HH:mm")}`;

  const entries = Object.entries(data)
    .filter(([key]) => key !== "time_bucket" && key !== "total")
    .sort(
      (a, b) =>
        TOOLTIP_CONSTANTS[a[0]]["order"] - TOOLTIP_CONSTANTS[b[0]]["order"],
    );

  return (
    <Column bg="gray.900" padding={2} borderRadius="md" gap={2}>
      <Text color="text.tertiary" fontWeight="medium">
        {format(startTime, "EEE")} {timeRange}
      </Text>
      {entries.map(([key, value], idx) => {
        const type = key.endsWith("operations") ? "rows" : "runs";
        return (
          <Row key={idx} gap={2} alignItems="stretch">
            <Column
              bg={TOOLTIP_CONSTANTS[key]["color"]}
              width={2}
              borderRadius="sm"
            />
            <Text fontWeight="medium" color="white" size="sm">
              {`${commaNumber(Number(value))} ${type} ${TOOLTIP_CONSTANTS[key]["label"]}`}
            </Text>
          </Row>
        );
      })}

      {data.total > 0 && (
        <Text color="text.tertiary" fontWeight="medium">
          Click to view runs <ArrowRightIcon />
        </Text>
      )}
    </Column>
  );
};

// This is a work around so that even small values relative to the maximum value are still visible with a minimum height
const CustomStackedBar = (props) => {
  const {
    x,
    y, // Top of the stack
    width,
    height, // Total height of the stack (downward in SVG)
    dataKeys, // Array of data keys (e.g., ['succeeded', 'failed', 'warning'])
    fills, // Array of base fill colors
    highlightedFills, // Array of highlighted fill colors
    activeIndex, // Index of the active bar
    payload, // Data for this stack
    minH = 5, // Minimum height per segment
    index, // Index of this bar in the data array
  } = props;

  // Determine if this bar is active
  const isActive = index === activeIndex;
  // Set fills based on whether the bar is active
  const segmentFills = dataKeys.map((_, idx) =>
    isActive ? highlightedFills[idx] : fills[idx],
  );

  // Extract values from payload, ensuring non-negative
  const values = dataKeys.map((key) => Math.max(payload[key] || 0, 0));
  const totalValue = values.reduce((sum, val) => sum + val, 0) || 1;

  // Calculate natural heights proportional to total height
  const naturalTotalHeight = height;
  const segments = values.map((value, index) => ({
    value,
    naturalHeight:
      value === 0 ? 0 : (value / totalValue) * naturalTotalHeight || 0,
    fill: segmentFills[index],
  }));

  // Adjust heights to ensure minimum height for visible segments
  const adjustedSegments = segments.map((segment) => {
    let adjustedHeight = segment.naturalHeight;
    if (segment.naturalHeight > 0 && segment.naturalHeight < minH) {
      adjustedHeight = minH * Math.max(1, segment.naturalHeight / (minH / 2));
    }
    return { ...segment, adjustedHeight };
  });

  // Stack from the bottom (x-axis) upwards
  const bottom = y + height;
  let currentY = bottom;

  const rectangles = adjustedSegments.map((segment, index) => {
    const rectHeight = segment.adjustedHeight;
    currentY -= rectHeight;
    return (
      <rect
        key={index}
        x={x}
        y={currentY}
        width={width}
        height={rectHeight}
        fill={segment.fill}
      />
    );
  });

  if (payload.total === 0) {
    return (
      <rect
        x={x}
        y={currentY - 5}
        width={width}
        height={5}
        fill="var(--chakra-colors-gray-300)"
      />
    );
  }

  return <g>{rectangles}</g>;
};
