import { useOutletContext } from "src/router";
import { OutletContext } from "..";
import {
  Column,
  Row,
  Heading,
  FormField,
  TextInput,
  Switch,
  Select,
} from "@hightouchio/ui";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { Controller } from "react-hook-form";
import {
  useUpdateDecisionEngineMutation,
  useCreateCollectionMutation,
  useCreateDecisionEngineMessageMutation,
} from "src/graphql";
import { Editor } from "src/components/editor";
import { Card } from "src/components/card";
import {
  DemoCollectionInputDefinition,
  DemoDefinition,
  DemoMessageInputDefinition,
  TEMPLATE_IDS,
} from "../demo";

export const Demo = () => {
  return (
    <Column gap={4}>
      <DemoSetup />
      <AddDemoCollection />
      <AddDemoMessage />
    </Column>
  );
};

const DemoSetup = () => {
  const { engine } = useOutletContext<OutletContext>();
  const demo = engine.config.demo as DemoDefinition | undefined;
  const mutation = useUpdateDecisionEngineMutation();
  const form = useHightouchForm({
    onSubmit: async (data) => {
      const { enabled, ...rest } = data;
      await mutation.mutateAsync({
        id: engine.id,
        input: {},
        append: {
          config: {
            demo: enabled
              ? {
                  ...demo,
                  ...rest,
                }
              : null,
          },
        },
      });
    },
    values: {
      enabled: Boolean(demo),
      logo_url: demo?.logo_url ?? "",
      from_name: demo?.from_name ?? "",
    },
  });

  return (
    <Form form={form}>
      <Card gap={4} footer={<FormActions />}>
        <Row gap={4}>
          <Heading>Demo setup</Heading>
          <Controller
            render={({ field }) => (
              <Switch
                aria-label="Enable demo setup."
                isChecked={field.value}
                onChange={field.onChange}
              />
            )}
            name="enabled"
          />
        </Row>

        <>
          <Controller
            name="logo_url"
            render={({ field }) => (
              <FormField
                label="Logo URL"
                description="Should be approximately 3:1 ratio"
              >
                <TextInput {...field} />
              </FormField>
            )}
          />
          <Controller
            name="from_name"
            render={({ field }) => (
              <FormField
                label="Company name"
                description="Appears in some footers and headers"
              >
                <TextInput {...field} />
              </FormField>
            )}
          />
        </>
      </Card>
    </Form>
  );
};

const AddDemoCollection = () => {
  const { engine } = useOutletContext<OutletContext>();
  const demo = engine.config.demo as DemoDefinition | undefined;
  const collectionMutation = useCreateCollectionMutation();
  const updateMutation = useUpdateDecisionEngineMutation();
  const form = useHightouchForm({
    onSubmit: async (data) => {
      const { name, ...rest } = JSON.parse(
        data.config,
      ) as DemoCollectionInputDefinition;
      const collectionResult = await collectionMutation.mutateAsync({
        input: {
          decision_engine_id: engine.id,
          config: {
            fallback: "none",
          },
          collection: {
            data: {
              catalog_id: data.catalog_id,
              name,
              filter: { type: "and", conditions: [] },
            },
          },
        },
      });

      const createdCollectionId =
        collectionResult.insert_decision_engine_collections_one?.id ?? "";

      await updateMutation.mutateAsync({
        id: engine.id,
        input: {},
        append: {
          config: {
            demo: {
              ...demo,
              collections: {
                ...demo?.collections,
                [createdCollectionId]: {
                  items: rest.items.map((i) => {
                    const urlObj = new URL(i.image);
                    urlObj.searchParams.set("w", "800");
                    urlObj.searchParams.set("h", "800");
                    const newUrl = urlObj.toString();
                    return {
                      name: i.name,
                      image: newUrl,
                    };
                  }),
                },
              },
            },
          },
        },
      });

      form.reset();
    },
    values: {
      catalog_id: "",
      config: JSON.stringify(
        { name: "...", items: [{ name: "...", image: "..." }] },
        null,
        2,
      ),
    },
  });

  return (
    <Form form={form}>
      <Card gap={4} footer={<FormActions />}>
        <Column>
          <Heading>Add Demo Collection</Heading>
        </Column>
        <Controller
          name="catalog_id"
          render={({ field }) => (
            <FormField label="Catalog ID">
              <TextInput {...field} />
            </FormField>
          )}
        />

        <Controller
          name="config"
          render={({ field }) => (
            <FormField label="Configuration">
              <Editor language="json" {...field} />
            </FormField>
          )}
        />
      </Card>
    </Form>
  );
};

const AddDemoMessage = () => {
  const { engine } = useOutletContext<OutletContext>();
  const demo = engine.config.demo as DemoDefinition | undefined;
  const messageMutation = useCreateDecisionEngineMessageMutation();
  const updateMutation = useUpdateDecisionEngineMutation();
  const form = useHightouchForm({
    onSubmit: async (data) => {
      const { name, ...rest } = JSON.parse(
        data.config,
      ) as DemoMessageInputDefinition;

      let collectionId = "";
      if (rest.variant === 1) {
        collectionId = rest.collection_id;
      }

      const selectedChannel = engine.channels.find(
        (c) => c.id === data.channel_id,
      );

      if (!selectedChannel) return;

      const messageResult = await messageMutation.mutateAsync({
        flowId: data.flow_id,
        message: {
          decision_engine_channel_id: data.channel_id,
          name,
          config: {
            baseMessageId:
              TEMPLATE_IDS[selectedChannel?.destination.type][rest.variant],
          },
          variables: Object.entries(rest.configurable_variables).map(
            ([k, v]) => ({ name: k, values: v }),
          ),
          collections: {
            data:
              rest.variant === 1
                ? [
                    {
                      decision_engine_collection_id: collectionId,
                      item_count: 4,
                    },
                  ]
                : [],
          },
        },
        flowMessageConfig: {},
      });

      if (
        messageResult.createDecisionEngineMessage.__typename ===
        "DecisionEngineError"
      ) {
        throw new Error(
          JSON.stringify(messageResult.createDecisionEngineMessage.error),
        );
      }

      const createdMessageId =
        (messageResult.createDecisionEngineMessage as any)?.id ?? "";

      await updateMutation.mutateAsync({
        id: engine.id,
        input: {},
        append: {
          config: {
            demo: {
              ...demo,
              messages: {
                ...demo?.messages,
                [createdMessageId]: {
                  variant: rest.variant,
                  base_variables: rest.base_variables,
                },
              },
            },
          },
        },
      });

      form.reset();
    },
    values: {
      flow_id: "",
      channel_id: "",
      config: JSON.stringify(
        {
          variant: 1,
          base_variables: {},
          configurable_variables: {},
          collection_id: "...",
        },
        null,
        2,
      ),
    },
  });

  return (
    <Form form={form}>
      <Card gap={4} footer={<FormActions />}>
        <Column>
          <Heading>Add Demo Message</Heading>
        </Column>
        <Controller
          name="flow_id"
          render={({ field }) => (
            <FormField label="Flow">
              <Select
                {...field}
                options={engine.flows.map((f) => ({
                  label: f.name,
                  value: f.id,
                }))}
              />
            </FormField>
          )}
        />
        <Controller
          name="channel_id"
          render={({ field }) => (
            <FormField label="Channel">
              <Select
                {...field}
                options={engine.channels.map((c) => ({
                  label: c.destination.name,
                  description: c.type,
                  value: c.id,
                }))}
              />
            </FormField>
          )}
        />
        <Controller
          name="config"
          render={({ field }) => (
            <FormField label="Configuration">
              <Editor language="json" {...field} />
            </FormField>
          )}
        />
      </Card>
    </Form>
  );
};
