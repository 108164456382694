import { FC, useState, useMemo, useEffect } from "react";

import {
  Box,
  Spinner,
  Switch,
  useToast,
  SectionHeading,
  Button,
  Column,
  Row,
  IconButton,
  EditIcon,
  CloseIcon,
  FormField,
  Dialog,
  TextInput,
  Combobox,
  Text,
  ButtonGroup,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { object, array, string } from "yup";

import {
  useAddRelationshipMutation,
  useDeleteRelationshipMutation,
  useDirectRelationshipModelsQuery,
  useRelationshipDependenciesQuery,
  useDeprecatedRelationshipsQuery,
  useUpdateDirectRelationshipMutation,
  useUpdateThroughRelationshipMutation,
  ObjectQuery,
  SlugResourceType,
} from "src/graphql";
import { Arrow } from "src/ui/arrow";
import { PermissionedButton } from "src/components/permission";
import { useResourceSlug } from "src/utils/slug";
import { ErrorMessage } from "src/components/explore/visual/error-message";

type Model = NonNullable<ObjectQuery["segments_by_pk"]>;
type ModelRelationship = Model["relationships"][0];

type Props = {
  model: Model;
};

export const Relationships: FC<Readonly<Props>> = ({ model }) => {
  const [addingThrough, setAddingThrough] = useState<boolean>(false);
  const [addingDirect, setAddingDirect] = useState<boolean>(false);
  const [editDirect, setEditDirect] = useState<
    boolean | ModelRelationship | undefined
  >(false);
  const [editThrough, setEditThrough] = useState<
    boolean | ModelRelationship | undefined
  >(false);
  const [deleteRelationship, setDeleteRelationship] = useState<
    ModelRelationship | undefined
  >();

  const relationships = model?.relationships;

  const directRelationships = relationships?.filter(
    ({ direct_relationships }) => direct_relationships?.length,
  );
  const throughRelationships = relationships?.filter(
    ({ through_relationships }) => through_relationships?.length,
  );

  return (
    <>
      <Row
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "small",
          pb: 2,
        }}
      >
        <SectionHeading>Direct Relationships</SectionHeading>
        <PermissionedButton
          permission={{
            v1: { resource: "audience_schema", grant: "update" },
            v2: { resource: "model", grant: "can_update", id: model.id },
          }}
          size="sm"
          onClick={() => setAddingDirect(true)}
        >
          Add direct relationship
        </PermissionedButton>
      </Row>

      <Column gap={4}>
        {directRelationships?.length ? (
          directRelationships.map((rel, index) => {
            const { id, name, direct_relationships } = rel;

            return (
              <Column
                key={id}
                sx={{ py: 4, borderTop: index > 0 ? "small" : undefined }}
              >
                <Row
                  sx={{
                    alignItems: "center",
                    mb: 2,
                    justifyContent: "space-between",
                    gap: 4,
                  }}
                >
                  <Text fontWeight="semibold">{name}</Text>
                  <Row align="center" gap={2}>
                    <IconButton
                      aria-label="Add direct relationship"
                      icon={EditIcon}
                      size="sm"
                      variant="secondary"
                      onClick={() => setEditDirect(rel)}
                    />

                    <IconButton
                      aria-label="Delete direct relationship"
                      icon={CloseIcon}
                      size="sm"
                      variant="secondary"
                      onClick={() => setDeleteRelationship(rel)}
                    />
                  </Row>
                </Row>
                <Column gap={2}>
                  {direct_relationships.map(
                    ({ from_column, to_column, to_model }, index) => (
                      <DirectRelationship
                        key={index}
                        fromColumn={from_column}
                        fromModel={model}
                        toColumn={to_column}
                        toModel={to_model}
                      />
                    ),
                  )}
                </Column>
              </Column>
            );
          })
        ) : (
          <Row sx={{ py: 4 }}>
            <Text color="text.secondary">
              This object has no direct relationships.
            </Text>
          </Row>
        )}
      </Column>

      <Row
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          pb: 2,
          borderBottom: "small",
          mt: 12,
        }}
      >
        <SectionHeading>Through Relationships</SectionHeading>
        <PermissionedButton
          permission={{
            v1: { resource: "audience_schema", grant: "update" },
            v2: { resource: "model", grant: "can_update", id: model.id },
          }}
          size="sm"
          variant="secondary"
          onClick={() => setAddingThrough(true)}
        >
          Add through relationship
        </PermissionedButton>
      </Row>

      <Column gap={4}>
        {throughRelationships?.length ? (
          throughRelationships.map((rel, index) => {
            const { id, name, to_model, through_relationships } = rel;
            const throughModel = through_relationships.find(
              ({
                relationship: {
                  to_model: { id },
                },
              }) => id !== to_model.id,
            )?.relationship.to_model;
            return (
              <Column
                key={id}
                sx={{ py: 4, borderTop: index > 0 ? "small" : undefined }}
              >
                <Row
                  sx={{
                    alignItems: "center",
                    mb: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Text mr={4} fontWeight="semibold">
                    {name}
                  </Text>
                  <Row align="center" gap={2}>
                    <IconButton
                      aria-label="Edit through relationship"
                      icon={EditIcon}
                      size="sm"
                      variant="secondary"
                      onClick={() => setEditThrough(rel)}
                    />

                    <IconButton
                      aria-label="Delete through relationship"
                      icon={CloseIcon}
                      size="sm"
                      variant="secondary"
                      onClick={() => setDeleteRelationship(rel)}
                    />
                  </Row>
                </Row>
                <ThroughRelationship
                  fromModel={model}
                  throughModel={throughModel}
                  toModel={to_model}
                />
              </Column>
            );
          })
        ) : (
          <Row sx={{ py: 4 }}>
            <Text color="text.secondary">
              This object has no through relationships.
            </Text>
          </Row>
        )}
      </Column>

      {addingDirect && (
        <DirectRelationshipForm
          model={model}
          onClose={() => setAddingDirect(false)}
        />
      )}
      {editDirect && (
        <DirectRelationshipForm
          model={model}
          relationship={typeof editDirect === "object" ? editDirect : undefined}
          onClose={() => setEditDirect(undefined)}
        />
      )}
      {addingThrough && (
        <ThroughRelationshipForm
          directRelationships={directRelationships}
          model={model}
          onClose={() => setAddingThrough(false)}
        />
      )}
      {editThrough && (
        <ThroughRelationshipForm
          directRelationships={directRelationships}
          model={model}
          relationship={
            typeof editThrough === "object" ? editThrough : undefined
          }
          onClose={() => setEditThrough(undefined)}
        />
      )}
      {deleteRelationship && (
        <DeleteRelationship
          relationship={deleteRelationship}
          onClose={() => setDeleteRelationship(undefined)}
        />
      )}
    </>
  );
};

const DirectRelationship: FC<
  Readonly<{
    toColumn: string;
    fromColumn: string;
    fromModel: any;
    toModel: any;
  }>
> = ({ fromColumn, toColumn, fromModel, toModel }) => {
  return (
    <Row sx={{ alignItems: "center" }}>
      <TextInput isDisabled value={`${fromModel?.name}.${fromColumn}`} />
      <Arrow />
      <TextInput isDisabled value={`${toModel?.name}.${toColumn}`} />
    </Row>
  );
};

interface ThroughRelationshipProps {
  fromModel: Model | undefined;
  throughModel:
    | ModelRelationship["through_relationships"][0]["relationship"]["to_model"]
    | undefined;
  toModel: ModelRelationship["to_model"] | undefined;
}

const ThroughRelationship: FC<Readonly<ThroughRelationshipProps>> = ({
  fromModel,
  throughModel,
  toModel,
}) => {
  return (
    <Row sx={{ alignItems: "center" }}>
      <TextInput isDisabled value={fromModel?.name ?? ""} />
      <Arrow />
      <TextInput isDisabled value={throughModel?.name ?? ""} />
      <Arrow />
      <TextInput isDisabled value={toModel?.name ?? ""} />
    </Row>
  );
};

interface DirectRelationshipFormValues {
  name: string;
  toModel: {
    label: string;
    value: string;
    columns: ModelRelationship["to_model"]["columns"];
  } | null;
  mappings: Array<{
    from_column: string | undefined;
    to_column: string | undefined;
  }>;
  merge_columns: NonNullable<ModelRelationship["merge_columns"]>;
}

interface DirectRelationshipFormProps {
  relationship?: ModelRelationship | null;
  model: Model | undefined;
  onClose: () => void;
}

const DirectRelationshipForm: FC<DirectRelationshipFormProps> = ({
  relationship = null,
  model,
  onClose,
}) => {
  const { getSlug } = useResourceSlug(SlugResourceType.ModelRelationships);
  const { data: modelsData } = useDirectRelationshipModelsQuery(
    { sourceId: model?.connection?.id },
    { enabled: Boolean(model) },
  );
  const relatedModels = modelsData?.segments;

  const validationSchema = object().shape({
    name: string().required("A relationship name is required"),
    toModel: object().nullable().required("A model is required"),
    mappings: array()
      .of(
        object().shape({
          to_column: string().required("A column is required"),
          from_column: string().required("A column is required"),
        }),
      )
      .required(),
  });

  const modelOptions =
    relatedModels
      ?.filter(({ id }) => id !== model?.id)
      ?.map(({ name, id, columns }) => ({ value: id, label: name, columns })) ??
    [];

  const defaultValues: DirectRelationshipFormValues = relationship
    ? {
        name: relationship.name ?? "",
        toModel:
          modelOptions.find(
            ({ value }) => relationship.to_model.id === value,
          ) ?? null,
        mappings: relationship.direct_relationships,
        merge_columns: Boolean(relationship.merge_columns),
      }
    : {
        name: "",
        toModel: null,
        mappings: [
          {
            to_column: undefined,
            from_column: undefined,
          },
        ],
        merge_columns: false,
      };

  const { control, handleSubmit, watch } =
    useForm<DirectRelationshipFormValues>({
      resolver: yupResolver(validationSchema),
      defaultValues,
    });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "mappings",
  });
  const toModel = watch("toModel");

  const { toast } = useToast();

  const fromColumnOptions =
    model?.columns?.map(({ name }) => ({
      value: name,
      label: name,
    })) ?? [];
  const toColumnOptions =
    toModel?.columns?.map(({ name }) => ({
      label: name,
      value: name,
    })) ?? [];

  const { isLoading: adding, mutateAsync: addRelationship } =
    useAddRelationshipMutation();
  const { isLoading: updating, mutateAsync: updateRelationship } =
    useUpdateDirectRelationshipMutation();

  const loading = updating || adding;

  const onSubmit = async ({
    name,
    mappings,
    merge_columns,
  }: {
    name: string;
    merge_columns: boolean;
    mappings: Array<{
      to_column: string | undefined;
      from_column: string | undefined;
    }>;
  }) => {
    const to_model_id = toModel?.value;
    const toModelSlug = relatedModels?.find(
      (model) => model?.id === to_model_id,
    )?.slug;

    const directRelationships = mappings.map(({ from_column, to_column }) => ({
      relationship_id: relationship?.id,
      from_column: from_column,
      to_column: to_column,
      from_model_id: model?.id,
      to_model_id,
    }));

    if (relationship) {
      await updateRelationship({
        id: relationship.id,
        object: {
          name,
          to_model_id,
          merge_columns,
        },
        directRelationships,
      });

      toast({
        id: "update-audience-relationship",
        title: "Direct relationship was updated",
        variant: "success",
      });

      onClose();
    } else {
      await addRelationship({
        object: {
          name,
          slug: await getSlug(
            (model?.slug || model?.id) + "-" + (toModelSlug || to_model_id),
          ),
          merge_columns,
          is_direct_relationship: true,
          from_model_id: model?.id,
          to_model_id,
          direct_relationships: {
            data: directRelationships,
          },
        },
      });

      toast({
        id: "add-audience-relationship",
        title: "Direct relationship was added",
        variant: "success",
      });

      onClose();
    }
  };

  return (
    <Dialog
      isOpen
      variant="form"
      width="xl"
      title={`${relationship ? "Edit" : "Add a"} direct relationship`}
      actions={
        <ButtonGroup>
          <Controller
            control={control}
            name="merge_columns"
            render={({ field }) => (
              <Box alignItems="center" display="flex" gap={2}>
                <Text
                  textTransform="uppercase"
                  size="sm"
                  color="text.secondary"
                  fontWeight="semibold"
                >
                  Merge columns
                </Text>

                <Switch
                  aria-label="Merge model columns."
                  isChecked={field.value}
                  onChange={field.onChange}
                />
              </Box>
            )}
          />
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="primary"
            isLoading={loading}
            onClick={handleSubmit(onSubmit, (error) => captureException(error))}
          >
            {relationship ? "Save" : "Add relationship"}
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
    >
      <Column gap={8}>
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <FormField
              label="Relationship name"
              error={fieldState.error?.message}
            >
              <TextInput {...field} isInvalid={Boolean(fieldState.error)} />
            </FormField>
          )}
        />
        <FormField label="Related model">
          <Controller
            control={control}
            name="toModel"
            render={({ field, fieldState }) => (
              <Column>
                <Combobox
                  isInvalid={Boolean(fieldState.error)}
                  options={modelOptions}
                  placeholder="Model..."
                  optionValue={(option) => option}
                  {...field}
                  value={field.value}
                />
                {fieldState.error && (
                  <ErrorMessage>{fieldState.error.message}</ErrorMessage>
                )}
              </Column>
            )}
            rules={{ required: true }}
          />
        </FormField>
        <FormField label="Mappings">
          <Column gap={4} align="flex-start">
            {fields.map((field, index) => (
              <Row key={field.id} gap={2} width="100%">
                <Controller
                  control={control}
                  defaultValue={field?.from_column}
                  name={`mappings.${index}.from_column`}
                  render={({ field, fieldState }) => (
                    <Column flex={1} minWidth={0}>
                      <Combobox
                        isInvalid={Boolean(fieldState.error)}
                        isDisabled={!toModel}
                        options={fromColumnOptions}
                        placeholder="From column..."
                        {...field}
                      />

                      {fieldState.error && (
                        <ErrorMessage>{fieldState.error.message}</ErrorMessage>
                      )}
                    </Column>
                  )}
                  rules={{ required: true }}
                />
                <Box height="16px" mt={2}>
                  <Arrow mx={0} />
                </Box>
                <Controller
                  control={control}
                  defaultValue={field?.to_column}
                  name={`mappings.${index}.to_column`}
                  render={({ field, fieldState }) => (
                    <Column flex={1} minWidth={0}>
                      <Combobox
                        isInvalid={Boolean(fieldState.error)}
                        isDisabled={!toModel}
                        options={toColumnOptions}
                        placeholder="To column..."
                        {...field}
                      />

                      {fieldState.error && (
                        <ErrorMessage>{fieldState.error.message}</ErrorMessage>
                      )}
                    </Column>
                  )}
                  rules={{ required: true }}
                />
                <IconButton
                  aria-label="remove"
                  icon={CloseIcon}
                  onClick={() => remove(index)}
                />
              </Row>
            ))}
            <Button
              size="sm"
              isDisabled={!toModel}
              onClick={() => append({ from_column: "", to_column: "" })}
            >
              Add mapping
            </Button>
          </Column>
        </FormField>
      </Column>
    </Dialog>
  );
};

interface ThroughRelationshipFormProps {
  relationship?: ModelRelationship | null;
  model: Model;
  directRelationships: ModelRelationship[];
  onClose: () => void;
}

interface Option {
  value: string;
  label: string;
  relationshipId: string;
}

const ThroughRelationshipForm: FC<ThroughRelationshipFormProps> = ({
  relationship = null,
  model,
  directRelationships,
  onClose,
}) => {
  const validationSchema = object().shape({
    name: string().required("A relationship name is required"),
    throughModel: object()
      .shape({ label: string(), value: string(), modelId: string() })
      .nullable()
      .required("A model is required"),
    toModel: object()
      .shape({ label: string(), value: string(), modelId: string() })
      .nullable()
      .required("A model is required"),
  });

  const { toast } = useToast();

  const getOption = ({ id, to_model: { name, id: modelId } }): Option => ({
    value: modelId,
    label: name,
    relationshipId: id,
  });

  const { getSlug } = useResourceSlug(SlugResourceType.ModelRelationships);

  const defaultValues = useMemo(() => {
    const currentThroughRelationship =
      relationship?.through_relationships?.find(
        ({ relationship: throughRelationship }) =>
          throughRelationship.to_model.id !== relationship.to_model.id,
      )?.relationship;

    const currentToRelationship = relationship?.through_relationships?.find(
      ({ relationship: throughRelationship }) =>
        throughRelationship.to_model.id === relationship.to_model.id,
    )?.relationship;

    return currentThroughRelationship && currentToRelationship
      ? {
          name: relationship.name ?? undefined,
          toModel: getOption(currentToRelationship),
          throughModel: getOption(currentThroughRelationship),
        }
      : {
          name: "",
          toModel: null,
          throughModel: null,
        };
  }, [relationship]);

  const { control, handleSubmit, watch, setValue } = useForm<{
    name: string;
    toModel: Option | null;
    throughModel: Option | null;
  }>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const throughModel = watch("throughModel");

  const {
    data: throughModelRelationshipsData,
    isFetching: throughModelRelationshipsLoading,
    refetch: refetchThroughModelRelationships,
  } = useDeprecatedRelationshipsQuery(
    {
      modelId: throughModel?.value ?? "",
    },
    { enabled: Boolean(throughModel?.value) },
  );

  const throughModelRelationships =
    throughModelRelationshipsData?.segments_by_pk?.relationships;

  const { isLoading: saveLoading, mutateAsync: addRelationship } =
    useAddRelationshipMutation();
  const { isLoading: updateLoading, mutateAsync: updateRelationship } =
    useUpdateThroughRelationshipMutation();

  const loading = updateLoading || saveLoading;
  watch();

  const onSubmit = async ({
    name,
    toModel,
    throughModel,
  }: {
    name: string;
    toModel: Option | null;
    throughModel: Option | null;
  }) => {
    const throughRelationships = [
      {
        relationship_id: relationship?.id,
        path_segment_id: throughModel?.relationshipId,
      },
      {
        relationship_id: relationship?.id,
        path_segment_id: toModel?.relationshipId,
      },
    ];

    if (relationship) {
      await updateRelationship({
        id: relationship.id,
        object: {
          name,
          to_model_id: toModel?.value,
        },
        throughRelationships,
      });

      toast({
        id: "update-through-relationship",
        title: "Through relationship was updated",
        variant: "success",
      });
    } else {
      await addRelationship({
        object: {
          name,
          slug: await getSlug(
            (model?.slug || model?.id) +
              "-" +
              (throughModelRelationships?.find(
                (rel) => rel?.to_model?.id === toModel?.value,
              )?.to_model?.slug || toModel?.value),
          ),
          is_direct_relationship: false,
          from_model_id: model?.id,
          to_model_id: toModel?.value,
          through_relationships: {
            data: throughRelationships,
          },
        },
      });

      toast({
        id: "add-through-relationship",
        title: "Through relationship was added",
        variant: "success",
      });
    }
    onClose();
  };

  const throughModelOptions = directRelationships?.map(getOption);
  const toModelOptions = throughModelRelationships?.map(getOption) ?? [];

  useEffect(() => {
    if (throughModel?.value !== defaultValues.throughModel?.value) {
      refetchThroughModelRelationships();
      setValue("toModel", null);
    }
  }, [defaultValues, throughModel]);

  return (
    <Dialog
      isOpen
      variant="form"
      width="xl"
      title={`${relationship ? "Edit" : "Add a"} through relationship`}
      actions={
        <ButtonGroup>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="primary"
            isLoading={loading}
            onClick={handleSubmit(onSubmit, (error) => captureException(error))}
          >
            {relationship ? "Save" : "Add relationship"}
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
    >
      <Column gap={8}>
        <Controller
          control={control}
          name="name"
          render={({ field, fieldState }) => (
            <FormField
              label="Relationship name"
              error={fieldState?.error?.message}
            >
              <TextInput {...field} isInvalid={Boolean(fieldState.error)} />
            </FormField>
          )}
        />
        <Row align="start">
          <Controller
            control={control}
            name="throughModel"
            render={({ field, fieldState }) => (
              <Column flex={1} minWidth={0}>
                <Combobox
                  isInvalid={Boolean(fieldState.error)}
                  options={throughModelOptions}
                  placeholder="Through model..."
                  optionValue={(option) => option}
                  {...field}
                  value={field.value}
                />

                {fieldState.error && (
                  <ErrorMessage>{fieldState.error.message}</ErrorMessage>
                )}
              </Column>
            )}
            rules={{ required: true }}
          />
          <Arrow mt={2} />
          <Controller
            control={control}
            name="toModel"
            render={({ field, fieldState }) => {
              return (
                <Column flex={1} minWidth={0}>
                  <Combobox
                    isInvalid={Boolean(fieldState.error)}
                    isDisabled={!throughModel}
                    emptyOptionsMessage={`${throughModel?.label} has no direct relationships`}
                    isLoading={throughModelRelationshipsLoading}
                    options={toModelOptions}
                    optionValue={(option) => option}
                    placeholder="To model..."
                    {...field}
                    value={field.value}
                  />

                  {fieldState.error && (
                    <ErrorMessage>{fieldState.error.message}</ErrorMessage>
                  )}
                </Column>
              );
            }}
            rules={{ required: true }}
          />
        </Row>
      </Column>
    </Dialog>
  );
};

const DeleteRelationship = ({ relationship, onClose }) => {
  const { toast } = useToast();

  const { isLoading: loading, mutateAsync: deleteRelationship } =
    useDeleteRelationshipMutation();

  const { data: dependenciesData, isLoading: loadingDependencies } =
    useRelationshipDependenciesQuery({
      id: String(relationship.id),
    });

  const dependencies = dependenciesData?.listRelationshipDependencies;
  const dependentModels = dependencies?.models;
  const dependentRelationships = dependencies?.relationships;

  const isDirect = relationship.direct_relationships?.length > 0;

  return (
    <Dialog
      isOpen
      title={`Delete ${isDirect ? "direct" : "through"} relationship`}
      width="xl"
      variant="form"
      actions={
        <ButtonGroup>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            isLoading={loading}
            variant="danger"
            onClick={async () => {
              await deleteRelationship({ id: relationship.id });

              toast({
                id: "delete-audience-relationship",
                title: "Relationship was deleted",
                variant: "success",
              });

              onClose();
            }}
          >
            Delete
          </Button>
        </ButtonGroup>
      }
      onClose={onClose}
    >
      {loadingDependencies ? (
        <Row
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Spinner size="lg" />
        </Row>
      ) : (
        <>
          <Text>
            Are you sure you want to delete the{" "}
            <strong>{relationship.name}</strong> relationship?{" "}
            {(dependentRelationships?.length > 0 ||
              dependentModels?.length > 0) &&
              "The following dependencies will be affected:"}
          </Text>

          {dependentModels?.length > 0 && (
            <>
              <Text mt={8} size="lg" fontWeight="semibold" mb={1}>
                Dependent audiences
              </Text>
              <Column gap={1}>
                {dependentModels.map(({ name, id }) => (
                  <Link key={id} href={`/audiences/${id}`}>
                    {name}
                  </Link>
                ))}
              </Column>
            </>
          )}

          {dependentRelationships?.length > 0 && (
            <>
              <Text mt={8} size="lg" fontWeight="semibold" mb={1}>
                Dependent relationships
              </Text>
              <Column gap={1}>
                {dependentRelationships.map(({ name, id }) => (
                  <Text key={id}>{name}</Text>
                ))}
              </Column>
            </>
          )}
        </>
      )}
    </Dialog>
  );
};
