import { useOutletContext } from "src/router";
import { Splits as SplitsV2 } from "src/components/splits-v2";

import { AudiencePageOutletState } from "../types";

export const AudienceSplits = () => {
  const { audience, parentModel } = useOutletContext<AudiencePageOutletState>();

  return <SplitsV2 audience={audience} parentModel={parentModel} />;
};
