import { useState, FC } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  ChakraModal,
  ChakraModalBody,
  ChakraModalContent,
  ChakraModalFooter,
  ChakraModalHeader,
  ChakraModalOverlay,
  ExternalLinkIcon,
  Heading,
  Badge,
  Row,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Text,
  TextInput,
  useToast,
  FormField,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { useNavigate } from "src/router";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import {
  useDeleteSourceMutation,
  useDestinationDefinitionsQuery,
  useListAllSourceDependenciesQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";

import { Table } from "src/ui/table";

type Props = {
  source: any;
  onClose: any;
};

export const DeleteSourceWarning: FC<Readonly<Props>> = ({
  onClose,
  source,
}) => {
  const [confirmation, setConfirmation] = useState<string>("");
  const { toast } = useToast();
  const navigate = useNavigate();

  const { data: sourceDependenciesData, isLoading: loadingDependencies } =
    useListAllSourceDependenciesQuery({
      ids: [source?.id],
    });

  const { data: destinationDefinitions } = useDestinationDefinitionsQuery(
    undefined,
    {
      select: (data) => data.getDestinationDefinitions,
    },
  );

  const { isLoading: deleting, mutateAsync: deleteSource } =
    useDeleteSourceMutation();

  const { audiences, models, syncs } =
    sourceDependenciesData?.listAllSourceDependencies.sources[0]
      ?.dependencies || {};

  const toSingleColumn = (resourceType) => [
    {
      name: "Name",
      cell: (resource) => {
        return (
          <Row align="center" gap={2} overflow="hidden">
            <IntegrationIcon
              name={source.definition?.name}
              src={source.definition?.icon}
            />
            <Text isTruncated fontWeight="medium">
              {resource.name}
            </Text>
            <Link href={`/${resourceType}/${resource.id}`}>
              <Box as={ExternalLinkIcon} fontSize="20px" />
            </Link>
          </Row>
        );
      },
    },
  ];

  const singleColumnTable = (resourceType, resourceData) => {
    return (
      <Table
        columns={toSingleColumn(resourceType)}
        data={resourceData}
        loading={loadingDependencies}
        placeholder={placeholderContent(resourceType)}
      />
    );
  };

  const placeholderContent = (resource) => ({
    title: `No ${resource} associated with this source were found`,
    error: `Failed to load ${resource}, please try again.`,
  });

  return (
    <ChakraModal isCentered isOpen size="xl" onClose={onClose}>
      <ChakraModalOverlay />
      <ChakraModalContent p={0} maxHeight="85%" overflowY="auto" my="auto">
        <ChakraModalHeader p={4}>
          <Heading>Delete this source?</Heading>
        </ChakraModalHeader>
        <ChakraModalBody overflowY="auto">
          <Box mb={4} fontSize="md" px={5}>
            This action will permanently delete the <i>{source.name}</i> source
            and cannot be undone. In order to properly delete this source, we
            will also need to permanently delete all of its associated
            resources:
          </Box>

          <Tabs>
            <TabList pl={4}>
              <Tab>
                Models <Badge ml={2}>{models?.length ?? 0}</Badge>
              </Tab>
              <Tab>
                Audiences <Badge ml={2}>{audiences?.length ?? 0}</Badge>
              </Tab>
              <Tab>
                Syncs <Badge ml={2}>{syncs?.length ?? 0}</Badge>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>{singleColumnTable("models", models)}</TabPanel>
              <TabPanel>{singleColumnTable("audiences", audiences)}</TabPanel>
              <TabPanel>
                <Table
                  columns={[
                    {
                      name: "Model/Audience",
                      cell: ({ modelName }) => {
                        return (
                          <Row align="center" overflow="hidden" gap={2}>
                            <IntegrationIcon
                              name={source?.name}
                              src={source?.definition?.icon}
                            />
                            <Text isTruncated fontWeight="medium">
                              {modelName ?? "Private model"}
                            </Text>
                          </Row>
                        );
                      },
                    },
                    {
                      name: "Destination",
                      cell: ({ destinationName, destinationType, syncId }) => {
                        const destinationDefinition =
                          destinationDefinitions?.find(
                            ({ type }) => type === destinationType,
                          );

                        return (
                          <Row align="center" overflow="hidden" gap={2}>
                            <IntegrationIcon
                              name={destinationDefinition?.name}
                              src={destinationDefinition?.icon}
                            />
                            <Text isTruncated fontWeight="medium">
                              {destinationName ?? "Private destination"}
                            </Text>
                            <Link href={`/syncs/${syncId}`}>
                              <Box as={ExternalLinkIcon} fontSize="20px" />
                            </Link>
                          </Row>
                        );
                      },
                    },
                  ]}
                  data={syncs as any}
                  loading={loadingDependencies}
                  placeholder={placeholderContent("syncs")}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ChakraModalBody>
        <ChakraModalFooter
          p={4}
          mt={0}
          borderTop="1px solid"
          borderColor="base.border"
          boxShadow="sm"
        >
          <Row align="end" justify="space-between" width="100%">
            <FormField label="Type your source name to confirm deletion">
              <TextInput
                placeholder={source.name}
                value={confirmation}
                onChange={(event) => setConfirmation(event.target.value)}
              />
            </FormField>
            <ButtonGroup>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                isDisabled={confirmation !== source.name}
                isLoading={deleting}
                variant="danger"
                onClick={async () => {
                  await deleteSource({ id: source?.id });

                  analytics.track("Source Deleted", {
                    source_id: source?.id,
                    source_name: source?.name,
                    source_type: source.definition?.name,
                  });

                  toast({
                    id: "delete-source",
                    title: `Source ${source?.name} was deleted`,
                    variant: "success",
                  });

                  navigate("/sources");
                }}
              >
                Delete all
              </Button>
            </ButtonGroup>
          </Row>
        </ChakraModalFooter>
      </ChakraModalContent>
    </ChakraModal>
  );
};
