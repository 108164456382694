import { useMemo } from "react";
import { useFetchQueryBuilderParentModel } from "src/components/explore/hooks";

import { useSegmentAttributionMethodsQuery } from "src/graphql";
import { FullParentModel } from "src/components/audiences/types";

export const useFetchParentModelForMetricQuery = (
  parentModelId: string | undefined,
): {
  parentModel:
    | (FullParentModel & {
        attribution_methods: { id: string; name: string }[];
      })
    | undefined;
  isLoading: boolean;
} => {
  const { parentModel, isLoading } = useFetchQueryBuilderParentModel({
    parentModelId: parentModelId ?? "",
  });

  const parentModelAttributionMethodsQuery = useSegmentAttributionMethodsQuery(
    {
      segmentId: parentModelId ?? "",
    },
    {
      enabled: Boolean(parentModelId),
      select: (data) => data.segments_by_pk,
    },
  );

  return useMemo(
    () => ({
      parentModel:
        parentModel && parentModelAttributionMethodsQuery.data
          ? {
              ...parentModel,
              attribution_methods:
                parentModelAttributionMethodsQuery.data.attribution_methods,
            }
          : undefined,
      isLoading: isLoading || parentModelAttributionMethodsQuery.isLoading,
    }),
    [
      parentModel,
      isLoading,
      parentModelAttributionMethodsQuery.data,
      parentModelAttributionMethodsQuery.isLoading,
    ],
  );
};
