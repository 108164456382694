import { FC, useEffect } from "react";

import * as analytics from "src/lib/analytics";
import { Alert } from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { useUser } from "src/contexts/user-context";
import { useFlags } from "launchdarkly-react-client-sdk";

export const SnowflakePasswordDeprecationBanner: FC = () => {
  const { workspace } = useUser();
  const { snowflakePasswordDeprecationBanner } = useFlags();

  useEffect(() => {
    analytics.track("Snowflake Password Deprecation Banner Shown", {
      workspace_id: workspace?.id,
    });
  }, []);

  // See here for LD details:
  // https://www.notion.so/hightouch/Snowflake-Password-Migration-User-Messaging-1a1ab9c473d580d697baf3ae875a8921?pvs=4#1a1ab9c473d58050a44edbd997f48fb7
  if (!snowflakePasswordDeprecationBanner) {
    return null;
  }

  return (
    <Alert
      variant="banner"
      type="warning"
      justify="center"
      title="Snowflake will start blocking single-factor password logins later this year."
      message="We recommend updating your Snowflake credentials in Hightouch to stay ahead of this change."
      actions={
        <LinkButton
          href="https://hightouch-knowledge-base.help.usepylon.com/articles/8896200340-snowflake-authentication-changes"
          onClick={() => {
            analytics.track(
              "Snowflake Password Deprecation Banner Learn More Clicked",
              {
                workspace_id: workspace?.id,
              },
            );
          }}
        >
          Learn more
        </LinkButton>
      }
    />
  );
};
