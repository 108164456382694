import { useEffect, useState } from "react";

import {
  AudienceIcon,
  Box,
  Button,
  CloseIcon,
  Column,
  Drawer,
  DrawerBody,
  DrawerHeader,
  Heading,
  IconButton,
  Badge,
  Row,
  SectionHeading,
  SkeletonBox,
  Skeleton,
  Text,
  Tooltip,
  JourneyIcon,
  RefreshIcon,
  ArrowRightIcon,
} from "@hightouchio/ui";
import { times, uniqBy } from "lodash";
import pluralize from "pluralize";

import { useUser } from "src/contexts/user-context";
import { Card } from "src/components/card";
import { usePersistedState } from "src/hooks/use-persisted-state";
import {
  RecentlyViewedQuery,
  RenderedMonitorConditionStatus,
  useRecentlyViewedQuery,
} from "src/graphql/types";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { formatFriendlyDistanceToNow } from "src/utils/time";
import { abbreviateNumber } from "src/utils/numbers";
import { getPersistedKey, Meta } from "./use-recently-viewed";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { isPresent } from "src/types/utils";
import { RouterLink } from "src/router";
import { SyncAlertsBadge } from "src/components/syncs/sync-alerts-badge";
import { useAlertsForSyncs } from "src/hooks/use-alerts-for-syncs";
import { MonitoredResourceType } from "@hightouch/lib/resource-monitoring/types";
import { SyncRunStatusBadge } from "src/components/syncs/sync-run-status-badge";

const COLUMN_SIZE = 4;
const CARD_WIDTH = "320px";
const BASE_CARD_HEIGHT = 106;
const LABEL_HEIGHT = 20;

export const RecentlyViewed = ({
  view,
}: {
  view: "marketing" | "activation";
}) => {
  // We have extra height when showing syncs from the destination label
  const cardHeight = `${
    view === "marketing" ? BASE_CARD_HEIGHT : BASE_CARD_HEIGHT + LABEL_HEIGHT
  }px`;
  const { workspace } = useUser();
  const [showAll, setShowAll] = useState(false);
  const recentlyViewed = usePersistedState<Array<Meta>>(
    getPersistedKey(workspace?.id, view),
    [],
  );

  const syncIds =
    recentlyViewed.value
      ?.filter((meta) => meta.resource === "syncs")
      .map((meta) => meta.id) ?? [];

  const { data: alerts } = useAlertsForSyncs(
    syncIds,
    MonitoredResourceType.Sync,
  );

  const { data, error, isLoading } = useRecentlyViewedQuery({
    audienceIds:
      recentlyViewed.value
        ?.filter((meta) => meta.resource === "audiences")
        .map((meta) => meta.id) ?? [],
    journeyIds:
      recentlyViewed.value
        ?.filter((meta) => meta.resource === "journeys")
        .map((meta) => meta.id) ?? [],
    syncIds,
    modelIds:
      recentlyViewed.value
        ?.filter((meta) => meta.resource === "models")
        .map((meta) => meta.id) ?? [],
  });

  useEffect(() => {
    if (data) {
      // Remove any entries for resources that no longer exist
      const validRecentlyViewed = recentlyViewed.value?.filter((meta) => {
        switch (meta.resource) {
          case "audiences":
            return data.audiences.find(
              (audience) => String(audience.id) === meta.id,
            );
          case "journeys":
            return data.journeys.find(
              (journey) => String(journey.id) === meta.id,
            );
          case "syncs":
            return data.syncs.find((sync) => String(sync.id) === meta.id);
          case "models":
            return data.models.find((model) => String(model.id) === meta.id);
        }
      });
      recentlyViewed.set(validRecentlyViewed ?? []);
    }
  }, [data]);

  if (!recentlyViewed.value || !recentlyViewed.value.length || error) {
    return null;
  }

  const visible = recentlyViewed.value.slice(0, COLUMN_SIZE);

  return (
    <Column gap={4}>
      <Row gap={4} align="center" justify="space-between">
        <Heading size="lg">Recently viewed</Heading>
        {recentlyViewed.value.length > COLUMN_SIZE && (
          <Button onClick={() => setShowAll(!showAll)}>View more</Button>
        )}
      </Row>
      <Box
        display="grid"
        gap={4}
        gridTemplateColumns={
          isLoading || visible.length === COLUMN_SIZE
            ? `repeat(auto-fit, minmax(240px, 1fr))`
            : `repeat(${visible.length}, ${CARD_WIDTH})`
        }
      >
        {data
          ? visible.map((meta) => (
              <StyledCard
                key={`${meta.resource}-${meta.id}`}
                href={getHref(meta)}
                height={cardHeight}
              >
                <Item meta={meta} data={data} alerts={alerts} />
              </StyledCard>
            ))
          : times(COLUMN_SIZE, (index) => (
              <Skeleton isLoading key={index}>
                <SkeletonBox
                  height={cardHeight}
                  minWidth={CARD_WIDTH}
                  flex={1}
                  borderRadius="md"
                />
              </Skeleton>
            ))}
      </Box>
      <Drawer isOpen={showAll} onClose={() => setShowAll(false)} size="sm">
        <DrawerHeader>
          <Row justify="space-between" align="center" flex={1}>
            <Row gap={2} align="center">
              <SectionHeading>Recently viewed</SectionHeading>
            </Row>
            <IconButton
              aria-label="Close"
              icon={CloseIcon}
              size="sm"
              onClick={() => setShowAll(false)}
            />
          </Row>
        </DrawerHeader>
        <DrawerBody p={0} display="flex" flexDirection="column" overflow="auto">
          {recentlyViewed.value.slice(COLUMN_SIZE).map((meta) => (
            <Row
              as={RouterLink}
              key={`${meta.resource}-${meta.id}`}
              transition="all 200ms ease-in-out"
              _hover={{ bg: "base.background" }}
              borderBottom="1px"
              borderColor="base.border"
              to={getHref(meta)}
              py={4}
              px={6}
              justify="space-between"
              className="group"
            >
              <Column>
                <Item meta={meta} data={data} alerts={alerts} />
              </Column>

              <Box
                as={ArrowRightIcon}
                _groupHover={{ opacity: 1 }}
                color="text.tertiary"
                boxSize={5}
                opacity={0}
                transition="200ms all ease-in-out"
              />
            </Row>
          ))}
        </DrawerBody>
      </Drawer>
    </Column>
  );
};

const Item = ({
  meta,
  data,
  alerts,
}: {
  meta: Meta;
  data: RecentlyViewedQuery | undefined;
  alerts: ReturnType<typeof useAlertsForSyncs>["data"];
}) => {
  if (!data) {
    return null;
  }
  switch (meta.resource) {
    case "audiences": {
      const audience = data.audiences.find(
        (audience) => String(audience.id) === meta.id,
      );
      if (!audience) {
        return null;
      }
      return (
        <AudienceItem
          name={audience.name}
          size={audience.last_run_size}
          updatedAt={audience.updated_at}
          syncs={audience.syncs}
        />
      );
    }
    case "journeys": {
      const journey = data.journeys.find(
        (journey) => String(journey.id) === meta.id,
      );
      if (!journey) {
        return null;
      }
      return (
        <JourneyItem
          name={journey.name}
          updatedAt={journey.updated_at}
          size={journey.runs[0]?.entry_count}
          syncs={journey.nodes
            .map((node) => node.sync_configs.map((sync) => sync.sync))
            .flat()
            .filter((sync): sync is NonNullable<typeof sync> =>
              Boolean(sync?.destination),
            )}
        />
      );
    }
    case "syncs": {
      const sync = data.syncs.find((sync) => String(sync.id) === meta.id);
      if (!sync) {
        return null;
      }
      return (
        <SyncItem
          model={sync.segment!}
          destination={sync.destination!}
          updatedAt={sync.updated_at}
          sync={sync}
          alerts={alerts?.[sync.id]?.alerts}
        />
      );
    }
    default:
      return null;
  }
};

const StyledCard = ({
  children,
  href,
  height,
}: {
  children: React.ReactNode;
  href: string;
  height?: string;
}) => (
  <Card
    flex={1}
    minW={0}
    as={RouterLink}
    href={href}
    p={4}
    h={height}
    boxShadow="md"
    transition="all 300ms ease-in-out"
    className="group"
    justifyContent="center"
    alignItems="flex-start"
    pos="relative"
    background="radial-gradient(circle at top right, #D1F5FC -20%, #FFF 20%)"
    backgroundSize="0% 0%"
    backgroundPosition="top right"
    backgroundRepeat="no-repeat"
    _hover={{
      backgroundSize: "100% 100%",
      boxShadow: "lg",
    }}
  >
    {children}
  </Card>
);

export const SyncItem = ({
  model,
  destination,
  updatedAt,
  sync,
  alerts,
}: {
  sync: {
    status: string | null;
    health: string | null;
    last_run_at: string | null;
    schedule_paused: boolean | null;
    sync_requests: {
      status_computed: string | null;
      phase_to_status: any;
    }[];
  };
  model: {
    name: string;
    last_run_size: number | null;
    connection: {
      name: string;
      type: string;
      definition: {
        name: string;
        icon: string;
      };
    } | null;
  };
  destination: {
    name: string | null;
    definition: {
      name: string;
      icon: string;
    };
  };
  updatedAt: string | null;
  alerts: RenderedMonitorConditionStatus[] | undefined;
}) => {
  const { workspace } = useUser();
  const syncRequest = sync.sync_requests?.[0];
  const request = syncRequest ? syncRequest : { status_computed: sync.status };

  return (
    <>
      <Row gap={2} align="center">
        <Tooltip message="Sync" placement="top" openSpeed="immediate">
          <Row>
            <Badge size="sm">
              <Row gap={1} align="center">
                <RefreshIcon />
                {sync.last_run_at ? (
                  <>
                    {model.last_run_size
                      ? abbreviateNumber(model.last_run_size)
                      : "Unknown"}{" "}
                    {pluralize("row", model.last_run_size)}
                  </>
                ) : (
                  "Never run"
                )}
              </Row>
            </Badge>
          </Row>
        </Tooltip>
        {workspace?.alerting_v2_enabled && alerts && (
          <SyncAlertsBadge
            hideWhenHealthy
            isLoading={false}
            alerts={alerts}
            sync={sync}
            size="sm"
          />
        )}
        {!workspace?.alerting_v2_enabled && (
          <SyncRunStatusBadge request={request} status={status} />
        )}
      </Row>

      <Row gap={1} align="center" overflow="hidden" mt={2} w="100%">
        {model.connection && (
          <IntegrationIcon
            size={4}
            src={model.connection?.definition?.icon}
            name={model.connection?.name}
          />
        )}
        <TextWithTooltip fontWeight="medium">{model.name}</TextWithTooltip>
      </Row>
      <Row gap={1} align="center" overflow="hidden" w="100%">
        <IntegrationIcon
          size={4}
          src={destination.definition.icon}
          name={destination.definition.name}
        />
        <TextWithTooltip fontWeight="medium">
          {destination.name ?? destination.definition.name}
        </TextWithTooltip>
      </Row>
      {updatedAt && (
        <Text size="sm" color="text.secondary" mt={1}>
          Updated {formatFriendlyDistanceToNow(updatedAt)}
        </Text>
      )}
    </>
  );
};

export const AudienceItem = ({
  name,
  size,
  updatedAt,
  syncs,
}: {
  name: string;
  size: number | null;
  updatedAt: string | null;
  syncs: MinimalSyncs;
}) => {
  return (
    <>
      <Row gap={2} align="center">
        <Tooltip message="Audience" placement="top" openSpeed="immediate">
          <Row>
            <Badge>
              <Row gap={1} align="center">
                <AudienceIcon />
                {size ? abbreviateNumber(size) : "Pending"}
              </Row>
            </Badge>
          </Row>
        </Tooltip>
        <DestinationIcons syncs={syncs} />
      </Row>
      <Row overflow="hidden" mt={2} w="100%">
        <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
      </Row>
      {updatedAt && (
        <Text size="sm" color="text.secondary" mt={1}>
          Updated {formatFriendlyDistanceToNow(updatedAt)}
        </Text>
      )}
    </>
  );
};

export const JourneyItem = ({
  name,
  updatedAt,
  syncs,
  size,
}: {
  name: string;
  updatedAt: string | null;
  syncs: MinimalSyncs;
  size: number;
}) => {
  return (
    <>
      <Row gap={2} align="center">
        <Tooltip message="Journey" placement="top" openSpeed="immediate">
          <Row>
            <Badge>
              <Row gap={1} align="center">
                <JourneyIcon />
                {size ? abbreviateNumber(size) : "Pending"}
              </Row>
            </Badge>
          </Row>
        </Tooltip>
        <DestinationIcons syncs={syncs} />
      </Row>
      <Row overflow="hidden" mt={2} w="100%">
        <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
      </Row>
      {updatedAt && (
        <Text size="sm" color="text.secondary" mt={2}>
          Updated {formatFriendlyDistanceToNow(updatedAt)}
        </Text>
      )}
    </>
  );
};

type MinimalSyncs = Array<{
  destination: {
    id: string;
    definition: {
      name: string;
      icon: string;
    } | null;
  } | null;
}>;

const DestinationIcons = ({ syncs }: { syncs: MinimalSyncs }) => {
  const destinations = uniqBy(
    syncs.map((sync) => sync.destination),
    "definition.name",
  );
  return (
    <Row gap={1} align="center">
      {destinations.filter(isPresent).map((destination, index) => (
        <Column
          align="center"
          justify="center"
          w="24px"
          h="24px"
          key={destination.id}
          border="1px"
          bg="white"
          borderColor="base.border"
          borderRadius="full"
          transform={`translateX(${index * -10}px)`}
        >
          <IntegrationIcon
            size={3}
            src={destination.definition?.icon}
            name={destination.definition?.name}
          />
        </Column>
      ))}
    </Row>
  );
};

const getHref = (meta: Meta) => {
  switch (meta.resource) {
    case "audiences":
      return `/audiences/${meta.id}`;
    case "journeys":
      return `/journeys/${meta.id}`;
    case "syncs":
      return `/syncs/${meta.id}`;
    case "models":
      return `/models/${meta.id}`;
  }
};
