import { Navigate, RouteObject } from "src/router";
import { CreateTraitEnrichment } from "src/components/audiences/trait-enrichments/create-trait-enrichment";
import { PermissionedRoute } from "src/components/router/permissioned-route";
import { FormErrorProvider } from "src/contexts/form-error-context";

import { AudienceDetails, AudienceLoader } from "./audience";
import { AudienceActivity } from "./audience/activity";
import { AudienceDefinition } from "./audience/definition";
import { AudienceSplits } from "./audience/splits";
import { AudienceSyncs } from "./audience/syncs";
import { AudienceTraits } from "./audience/traits";
import AudiencesLoader from "./audiences";
import { CloneAudience } from "./clone-audience";
import { CreateAudience } from "./create-audience";

export const routes: Array<RouteObject> = [
  { path: "audiences", element: <AudiencesLoader />, children: [] },
  {
    path: "audiences/:id",
    element: <AudienceLoader />,
    children: [
      {
        path: "*",
        element: <AudienceDetails />,
        children: [
          {
            index: true,
            element: <Navigate to="definition" replace />,
          },
          {
            path: "definition",
            element: <AudienceDefinition />,
          },
          {
            path: "splits",
            element: <AudienceSplits />,
          },
          {
            path: "syncs",
            element: <AudienceSyncs />,
          },
          {
            path: "traits",
            element: <AudienceTraits />,
            children: [
              {
                path: "new",
                element: <CreateTraitEnrichment />,
              },
            ],
          },
          {
            path: "activity",
            element: <AudienceActivity />,
          },
          {
            // Deprecated route, navigate to definition instead
            path: "query",
            element: <Navigate to="../definition" replace />,
          },
        ],
      },
    ],
  },
  {
    path: "audiences/new",
    element: (
      <PermissionedRoute
        permission={{
          v1: { resource: "audience", grant: "create" },
        }}
        redirect="/audiences"
      >
        <FormErrorProvider>
          <CreateAudience />
        </FormErrorProvider>
      </PermissionedRoute>
    ),
  },
  {
    path: "audiences/:id/clone",
    element: (
      <PermissionedRoute
        permission={{
          v1: { resource: "audience", grant: "create" },
        }}
        redirect="/audiences/:id"
      >
        <FormErrorProvider>
          <CloneAudience />
        </FormErrorProvider>
      </PermissionedRoute>
    ),
  },
];
