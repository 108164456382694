import { FC } from "react";

import { OutletContext } from ".";
import { useOutletContext } from "src/router";
import { SourceOverviewCharts } from "./components/source-overview-charts";
import { Column, Row } from "@hightouchio/ui";
import { AlertsForSource } from "./components/alerts-for-source";
import { useUser } from "src/contexts/user-context";

export const Overview: FC = () => {
  const { source } = useOutletContext<OutletContext>();
  const { workspace } = useUser();
  const appEventMonitoringEnabled = workspace?.alerting_v2_enabled;

  return (
    <Row gap={4} display="flex">
      <Column flex={2}>
        <SourceOverviewCharts source={source} />
      </Column>
      {appEventMonitoringEnabled && (
        <Column minW="300px">
          <AlertsForSource source={source} />
        </Column>
      )}
    </Row>
  );
};
