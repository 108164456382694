import { FC, useState } from "react";

import {
  Box,
  Button,
  ChatIcon,
  Column,
  Dialog,
  SectionHeading,
  Badge,
  Text,
} from "@hightouchio/ui";
import { format } from "numerable";

import { TextWithTooltip } from "src/components/text-with-tooltip";
import { AllMatchboostingStatsQuery } from "src/graphql";
import { Markdown } from "src/ui/markdown";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { formatDurationDisplay } from "src/utils/match-boosting";
import { formatDatetime } from "src/utils/time";
import { newPylonMessage } from "src/lib/pylon";
import { CodeWithOverflow } from "src/pages/syncs/sync/components/code-with-overflow";

type Props = {
  allStats: AllMatchboostingStatsQuery["matchboosting_stats"];
  sidebar: boolean;
};

export const RunsTable: FC<Readonly<Props>> = ({ allStats, sidebar }) => {
  const { page, limit, offset, setPage } = useTableConfig({ limit: 10 });
  const [enrichmentError, setEnrichmentError] = useState<null | string>(null);

  const runsPageData = (allStats ?? []).slice(offset, offset + limit);

  const tableColumns: TableColumn[] = [
    {
      name: "Status",
      min: "150px",
      cell: ({ status }) => {
        switch (status) {
          case "success":
            return <Badge variant="success">Successful</Badge>;
          case "failed":
            return <Badge variant="error">Failed</Badge>;
          case "cancelled":
            return <Badge>Cancelled</Badge>;
          default:
            return <Badge variant="warning">Unknown</Badge>;
        }
      },
    },
    {
      name: "Started at",
      min: "150px",
      cell: ({ last_enriched_at, enriched_duration_ms }) => {
        const finishedAt = new Date(last_enriched_at);
        const startedAt = new Date(finishedAt.getTime() - enriched_duration_ms);
        const formattedStartedAt =
          formatDatetime(startedAt.toISOString()) ?? "--";
        return (
          <TextWithTooltip message={formattedStartedAt}>
            {formattedStartedAt}
          </TextWithTooltip>
        );
      },
    },
    {
      name: "Duration",
      cell: ({ enriched_duration_ms }) => {
        const duration =
          formatDurationDisplay({ start: 0, end: enriched_duration_ms }) ||
          "1 min";
        return <TextWithTooltip message={duration}>{duration}</TextWithTooltip>;
      },
    },
    {
      name: "Run type",
      cell: ({ is_full_enrichment }) => {
        const runType = is_full_enrichment ? "Full" : "Incremental";
        return <TextWithTooltip message={runType}>{runType}</TextWithTooltip>;
      },
    },
    {
      name: "Rows queried",
      cell: ({ enriched_rows, status }) => {
        const rowsQueried = format(
          status !== "success" ? null : enriched_rows,
          {
            nullFormat: "--",
          },
        );

        return (
          <Box textAlign="right" width="100%">
            <TextWithTooltip message={rowsQueried}>
              {rowsQueried}
            </TextWithTooltip>
          </Box>
        );
      },
    },
  ];

  // Only show this column if there's at least one failed enrichment for this page
  if (runsPageData.some((stats) => stats.status === "failed")) {
    tableColumns.push({
      name: "",
      cell: ({ error, status }) => {
        if (status === "failed") {
          return (
            <Button
              size="sm"
              ml="auto"
              variant="danger"
              onClick={() => {
                setEnrichmentError(
                  error ?? "Something went wrong during enrichment.",
                );
              }}
            >
              View error
            </Button>
          );
        }

        return null;
      },
    });
  }

  return (
    <Column
      pb={sidebar ? 0 : 48}
      gap={4}
      borderTop="1px"
      borderStyle="solid"
      borderColor="base.border"
      pt={12}
    >
      <SectionHeading>Enrichment run history</SectionHeading>
      <Table columns={tableColumns} data={runsPageData} scrollable={sidebar} />
      <Box py={4}>
        <Pagination
          page={page}
          setPage={setPage}
          rowsPerPage={limit}
          count={allStats?.length}
        />
      </Box>
      <Dialog
        isOpen={Boolean(enrichmentError)}
        variant="info"
        width="lg"
        title="Enrichment run error"
        actions={
          <Button
            icon={ChatIcon}
            size="md"
            variant="secondary"
            onClick={() =>
              newPylonMessage(
                "I'm experiencing an issue with running an enrichment on my model " +
                  "and could use some assistance. The error message I'm receiving is: " +
                  `'${enrichmentError}'. Here's a link to my model: <a href="${window.location.href}">${window.location.href}</a>.`,
              )
            }
          >
            Chat with support
          </Button>
        }
        onClose={() => setEnrichmentError(null)}
      >
        <Column gap={4}>
          <CodeWithOverflow lineClampCount="5">
            <Markdown>{enrichmentError ?? "Unknown error"}</Markdown>
          </CodeWithOverflow>

          <Column>
            <Text fontWeight="medium" size="lg">
              Need more help?
            </Text>
            <Text mt={2}>
              If you feel stuck, please reach out! We want to make sure you have
              all the help you need. Our team is available via chat to help you
              troubleshoot this error.
            </Text>
          </Column>
        </Column>
      </Dialog>
    </Column>
  );
};
