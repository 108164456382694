import { Column, Row } from "@hightouchio/ui";

import { DecisionEngineStatus } from "@hightouch/lib/customer-data/decision-engine/types";
import { Outlet, useOutletContext } from "src/router";
import { FlowContext } from ".";
import { DraftFlow } from "./components/set-up";
import { Targeting } from "./components/targeting";
import { Messages } from "./messages";
import { Outcomes } from "./outcomes";
import { Timing } from "./timing";

export const FlowConfiguration = () => {
  const context = useOutletContext<FlowContext>();

  return (
    <>
      <Column gap={6}>
        <Row gap={6}>
          {context.flow.status === DecisionEngineStatus.PENDING && (
            <DraftFlow flow={context.flow} />
          )}
          <Targeting flow={context.flow} />
        </Row>
        <Messages
          messages={context.flow.messages}
          agentEnabled={context.flow.enabled}
        />
        <Outcomes outcomes={context.flow.outcomes.flatMap((o) => o.outcome)} />
        <Timing flow={context.flow} />
      </Column>
      <Outlet context={context} />
    </>
  );
};
