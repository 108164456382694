import { FC } from "react";

import { Box, ChevronRightIcon } from "@hightouchio/ui";

import { PermissionedButton } from "src/components/permission";

import { ResourcePermissionInput } from "src/components/permission/use-resource-permission";

export const ShowInsightsButton: FC<{
  isInsightsOpen: boolean;
  onClick: () => void;
  permission: ResourcePermissionInput<"model", "audience">;
  isLoading?: boolean;
}> = ({ isInsightsOpen, onClick, permission, isLoading = false }) => (
  <Box
    as={PermissionedButton}
    isLoading={isLoading}
    placement="top-end"
    tooltip="View breakdowns and overlaps"
    icon={
      isInsightsOpen
        ? ChevronRightIcon
        : () => (
            <Box
              alignItems="end"
              display="grid"
              height="15px"
              width="15px"
              gridTemplateColumns="1fr 1fr 1fr 1fr"
              mr="7px"
            >
              <Box backgroundColor="cyan.400" height="3.75px" />
              <Box backgroundColor="electric.600" height="7.5px" />
              <Box backgroundColor="cyan.400" height="11.25px" />
              <Box backgroundColor="electric.600" height="15px" />
            </Box>
          )
    }
    permission={permission}
    height={8}
    width="160px"
    onClick={onClick}
  >
    {isInsightsOpen ? "Hide insights" : "Show insights"}
  </Box>
);
