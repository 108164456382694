import { Navigate, RouteObject } from "src/router";
import { PermissionedRoute } from "src/components/router/permissioned-route";

import { CreateAudienceObject } from "src/pages/audiences/setup/create-object";
import { CreateSyncTemplate } from "src/pages/audiences/setup/create-sync-template";
import { AudienceObject } from "src/pages/audiences/setup/object";
import { AudienceObjectQuery } from "src/pages/audiences/setup/object-query";
import { SetupAudiences } from "src/pages/audiences/setup/setup-audiences";
import { SyncTemplateRouter } from "src/pages/audiences/setup/sync-template";
import { SchemaPage } from "./index";
import { ParentModels } from "src/pages/audiences/setup/parent-models";
import { RelatedModels } from "src/pages/audiences/setup/related-models";
import { EventModels } from "src/pages/audiences/setup/event-models";
import { SyncTemplates } from "src/pages/audiences/setup/sync-templates";

export const routes: Array<RouteObject> = [
  {
    path: "schema/sync-templates/new",
    element: (
      <PermissionedRoute
        permission={{
          v1: { resource: "sync_template", grant: "create" },
        }}
        redirect="/schema/sync-templates"
      >
        <CreateSyncTemplate />
      </PermissionedRoute>
    ),
  },
  {
    path: "schema/sync-templates/:sync_template_id",
    element: <SyncTemplateRouter />,
  },
  {
    path: "schema/:object/:id/query",
    element: <AudienceObjectQuery />,
  },
  {
    path: "schema/:object/new",
    element: (
      <PermissionedRoute
        permission={{
          v1: { resource: "audience_schema", grant: "create" },
        }}
        redirect="/schema/:object"
      >
        <CreateAudienceObject />
      </PermissionedRoute>
    ),
  },
  {
    path: "schema/:object/:id",
    element: <AudienceObject />,
  },
  {
    path: "schema/*",
    element: <SetupAudiences />,
    children: [
      {
        index: true,
        element: <Navigate to="parent-models" replace />,
      },
      {
        path: "parent-models",
        element: <ParentModels />,
      },
      {
        path: "related-models",
        element: <RelatedModels />,
      },
      {
        path: "events",
        element: <EventModels />,
      },
      {
        path: "sync-templates",
        element: <SyncTemplates />,
      },
    ],
  },
  {
    path: "schema",
    element: <Navigate to="/schema/parent-models" replace />,
  },
  {
    path: "schema-v2/*",
    element: <SchemaPage />,
  },
];
