import { Text } from "@hightouchio/ui";
import { useEffect, useState } from "react";
import * as time from "src/utils/time";

export const PhaseDuration = ({
  durationMillis,
  isActive,
}: {
  durationMillis: number;
  isActive: boolean;
}) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    if (!isActive) return;

    const interval = setInterval(
      () => setSeconds((seconds) => seconds + 1),
      1000,
    );

    return () => {
      setSeconds(0);
      clearInterval(interval);
    };
  }, [durationMillis, isActive]);

  // Our time.format api expects start and end time, but we only have total duration here
  // So we use 0 as the start time and the total duration as the end time
  const formattedDuration = time.formatDuration(
    {
      start: 0,
      end: durationMillis + seconds * 1000,
    },
    {
      units: "short",
      granularity: "second",
    },
  );

  return (
    <Text size="sm" color="text.tertiary">
      {formattedDuration}
    </Text>
  );
};
