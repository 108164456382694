import { FC, ReactNode, useEffect, useRef, useState } from "react";

import { Box, Button } from "@hightouchio/ui";

function checkOverflow(textContainer: HTMLSpanElement | null): boolean {
  if (textContainer) {
    return textContainer.offsetHeight < textContainer.scrollHeight;
  }
  return false;
}

export const CodeWithOverflow: FC<{
  children: ReactNode;
  lineClampCount?: string;
  maxLines?: number;
  isError?: boolean;
  buttonSize?: "sm" | "md" | "lg";
}> = ({
  children,
  maxLines,
  isError = false,
  lineClampCount = 2,
  buttonSize,
}) => {
  const [overflowActive, setOverflowActive] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const overflowingText = useRef(null);
  const overflowLabel = showMore ? `Show less...` : `Show more...`;

  useEffect(() => {
    setOverflowActive(checkOverflow(overflowingText.current));
  }, []);

  return (
    <Box>
      <Box
        bg={isError ? "danger.background" : "gray.50"}
        p={2}
        borderRadius="md"
        border="1px"
        borderColor={isError ? "transparent" : "base.border"}
      >
        <Box
          ref={overflowingText}
          display="-webkit-box"
          overflow="hidden"
          textOverflow="ellipsis"
          color={isError ? "danger.base" : undefined}
          sx={{
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: showMore ? maxLines || "none" : lineClampCount,
          }}
        >
          {children}
        </Box>
      </Box>
      {overflowActive && (
        <Button
          mt={2}
          size={buttonSize}
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          {overflowLabel}
        </Button>
      )}
    </Box>
  );
};
