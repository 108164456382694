import { HTImage } from "src/components/image";
import { FC, useCallback, useEffect, useMemo, useRef } from "react";

import {
  Alert,
  ArrowRightIcon,
  Box,
  Button,
  Column,
  DescriptionIcon,
  Row,
  Text,
  Tooltip,
} from "@hightouchio/ui";

import { Link } from "src/router";

import { AudienceSplit } from "src/types/visual";
import { DisambiguatedSync } from "src/utils/syncs";

import { ConnectionNode } from "./connection-node";
import { getSplitGroupNodeId, getSyncNodeId, sharedNodeStyles } from "./utils";

type Props = {
  assignableSplits: Array<AudienceSplit & { splitIndex: number }>;
  assignedSplits: AudienceSplit[];
  isAudienceWarehouseSync?: boolean;
  isHighlighted: boolean;
  source: {
    id: number;
    planInWarehouse: boolean | undefined;
    audienceSnapshottingEnabled: boolean | undefined;
  };
  sync: DisambiguatedSync;
  onMouseEnter: (nodeIds: string[]) => void;
  onMouseLeave: () => void;
  onNodeResize: ({
    nodeId,
    align,
  }: {
    nodeId: string;
    align: "left" | "right";
  }) => void;
  onUpdateSplit: (
    splitGroupIndex: number,
    fullSpitGroupPayload: AudienceSplit,
  ) => void;
};

const listFormat = new Intl.ListFormat("en");

export const SyncNode: FC<Readonly<Props>> = ({
  assignableSplits,
  assignedSplits,
  isAudienceWarehouseSync,
  isHighlighted,
  source,
  sync,
  onMouseEnter,
  onMouseLeave,
  onNodeResize,
  onUpdateSplit,
}) => {
  const nodeId = getSyncNodeId(sync.id);

  const elem = useRef(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      onNodeResize({ nodeId, align: "right" });
    });

    if (elem.current) {
      resizeObserver.observe(elem.current);
    }

    return () => resizeObserver.disconnect();
  }, [onNodeResize]);

  const assignedSplitNames = useMemo(
    () => assignedSplits.map((s) => `"${s.friendly_name}"`),
    [assignedSplits],
  );

  // Return ID of this sync and its connected splits
  const getConnectedNodeIds = useCallback(
    () => [
      nodeId,
      ...assignedSplits.map((split) => getSplitGroupNodeId(split)),
    ],
    [nodeId, assignedSplits],
  );

  const handleAssignToSplit = useCallback(
    (split: AudienceSplit, splitIndex: number) => {
      // Update the split with this sync ID
      const updatedSplit: AudienceSplit = {
        ...split,
        destination_instance_ids: [...split.destination_instance_ids, sync.id],
      };

      onUpdateSplit(splitIndex, updatedSplit);
    },
    [sync.id, onUpdateSplit],
  );

  // Only show the connection node if it's connected to any splits
  const showConnectionNode = assignedSplits.length > 0;

  return (
    <Column
      {...sharedNodeStyles(isHighlighted)}
      gap={2}
      p={4}
      ref={elem}
      onMouseEnter={() => onMouseEnter(getConnectedNodeIds())}
      onMouseLeave={onMouseLeave}
    >
      {showConnectionNode && (
        <Box
          id={nodeId}
          position="absolute"
          left={0}
          top="50%"
          transform="translate(-50%, -50%)"
        >
          <ConnectionNode />
        </Box>
      )}
      <Row alignItems="center" gap={2} justifyContent="space-between">
        <Row alignItems="center" gap={2}>
          <HTImage
            h={8}
            w={8}
            p={1}
            src={sync.destination?.definition.icon ?? ""}
            alt={`${sync.destination?.definition.name} logo`}
          />
          <Column>
            <Text fontWeight="medium">{sync?.name}</Text>
            {sync.sync_template?.name && (
              <Text color="text.secondary">
                Template: {sync.sync_template?.name}
              </Text>
            )}
          </Column>
        </Row>
        {sync.description && (
          <Tooltip message={sync.description}>
            <Box as={DescriptionIcon} fontSize={20} />
          </Tooltip>
        )}
      </Row>

      {assignedSplitNames.length > 1 && (
        <Text color="text.secondary">
          {listFormat.format(assignedSplitNames)} will be merged into one list.
        </Text>
      )}

      {!isAudienceWarehouseSync && assignedSplitNames.length === 0 && (
        <Column gap={2}>
          <Alert
            variant="inline"
            type="warning"
            title="No split groups assigned to this sync"
            message="No splits will be synced."
          />
          {assignableSplits.length > 0 && (
            <>
              <Text>Assign a split group:</Text>
              <Column gap={2}>
                {assignableSplits.map(({ splitIndex, ...split }, index) => (
                  <Button
                    key={index}
                    onClick={() => handleAssignToSplit(split, splitIndex)}
                  >
                    {split.friendly_name}
                  </Button>
                ))}
              </Column>
            </>
          )}
        </Column>
      )}

      {isAudienceWarehouseSync &&
        (!source.audienceSnapshottingEnabled || !source.planInWarehouse ? (
          <Alert
            variant="inline"
            type="warning"
            title="Logging disabled"
            message={
              !source.planInWarehouse
                ? `Holdout group logs require using the Lightning sync engine. Please go to the source settings and turn it on.`
                : `To view holdout group logs in your data warehouse, please enable Audience Snapshotting in the source settings.`
            }
            actions={
              <Link href={`/sources/${source.id}`}>
                <Row alignItems="center" color="inherit" gap={1}>
                  <Text color="unset">Go to source settings</Text>
                  <ArrowRightIcon />
                </Row>
              </Link>
            }
          />
        ) : (
          <Text color="text.secondary">
            The holdout group is evaluated and recorded whenever this audience
            is synced to a destination.
          </Text>
        ))}
    </Column>
  );
};
