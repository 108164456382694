import { HTImage } from "src/components/image";
import { FC } from "react";

import { BoxProps } from "@hightouchio/ui";

import { usePartner } from "./context";

export const PartnerLogo: FC<Readonly<{ size?: BoxProps["height"] }>> = ({
  size = "24px",
}) => {
  const { brand } = usePartner();

  if (brand) {
    return (
      <HTImage src={brand.logo} alt={brand.name} width="auto" height={size} />
    );
  }

  return null;
};
