import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  EvaluateCondition,
  FilterCondition,
} from "src/events/syncs/forwarding-sync/types";
import {
  Box,
  Row,
  TextInput,
  NumberInput,
  IconButton,
  DeleteIcon,
  Column,
  Button,
  Menu,
  MenuActionsButton,
  MenuList,
  MenuItem,
  DescriptionAddIcon,
  Text,
  Select,
  SectionHeading,
} from "@hightouchio/ui";
import { colors } from "src/components/explore/visual/colors";
import { AndOrToggleButton } from "src/components/explore/visual/condition-buttons";
import { ConditionType } from "@hightouch/lib/query/visual/types";

type FormState = Partial<{ filter: FilterCondition }>;

export default function Filter({
  path = "filter",
  othersExist = true,
}: {
  path?: "filter" | `filter.children.${number}`;
  // Whether there are other filters
  othersExist?: boolean;
}) {
  const { watch, setValue, getValues } = useFormContext<FormState>();
  const condition = watch(path);
  const parentPathMatch = path.match(/(.+)\.children\.([0-9]+)$/);
  const parent = parentPathMatch
    ? watch(parentPathMatch[1] as "filter")
    : undefined;
  const onDelete = () => {
    if (parent) {
      const [_, parent, index] = parentPathMatch as [
        string,
        "filter",
        `${number}`,
      ];
      const children = getValues(`${parent}.children`);
      const next = children.filter((_, i) => String(i) !== index);
      if (next.length > 1) {
        setValue(`${parent}.children`, next);
      } else {
        setValue(parent, next[0]);
      }
    } else {
      setValue(path as "filter", undefined);
    }
  };

  return (
    <Column gap={3} flex={1}>
      {!parent && (
        <Column>
          <SectionHeading>Event filter</SectionHeading>
          <Text color="text.secondary">
            How should events be matched to this event type?
          </Text>
        </Column>
      )}
      <FilterElement
        type={condition?.type ?? "unmatched"}
        othersExist={othersExist}
        path={path}
        onDelete={onDelete}
      />
      {!parent && (!condition || !["and", "or"].includes(condition.type)) && (
        <AddConditionButton
          onAdd={(newCondition) => {
            setValue(
              "filter",
              condition?.type === "evaluate"
                ? {
                    type: "or",
                    children: [condition, newCondition],
                  }
                : newCondition,
            );
          }}
        />
      )}
    </Column>
  );
}

function FilterElement({
  type,
  othersExist,
  ...args
}: {
  type: FilterCondition["type"];
  path: "filter" | `filter.children.${number}`;
  onDelete: () => void;
  othersExist: boolean;
}) {
  switch (type) {
    case "evaluate":
      return <Condition {...args} />;
    case "or":
    case "and":
      return <Combinator {...args} />;
    case "unmatched":
      return (
        <Row
          px={4}
          height="50px"
          gap={2}
          alignItems="center"
          border="1px"
          borderColor="base.border"
          borderLeft="20px solid red"
          borderRadius="md"
          fontSize={13}
        >
          Accept any event
          {othersExist && " that doesn't match another configuration"}
        </Row>
      );
    default:
      return <div />;
  }
}

function Condition({
  path,
  onDelete,
}: {
  path: "filter" | `filter.children.${number}`;
  onDelete: () => void;
}) {
  const { setValue, watch, getValues } = useFormContext<FormState>();
  const parentPathMatch = path.match(/(.+)\.children\.([0-9]+)$/);
  const parentType = parentPathMatch
    ? watch(`${parentPathMatch[1]}.type` as "filter.type")
    : undefined;
  const condition = watch(path);
  const conditionOperation = watch(`${path}.operation`);

  // Only retain the fields relevant to the current operation type.
  useEffect(() => {
    const currValue = getValues()[path];
    if (!currValue) return;
    if (conditionOperation === "within") {
      setValue(path, {
        type: "evaluate",
        operation: currValue.operation,
        field: currValue.field,
        window: currValue.window,
      });
    } else {
      setValue(path, {
        type: "evaluate",
        operation: currValue.operation,
        field: currValue.field,
        value: currValue.value,
      });
    }
  }, [conditionOperation]);

  if (condition?.type !== "evaluate") return <></>;

  return (
    <Row
      px={4}
      py={2}
      gap={2}
      alignItems="center"
      border="1px"
      borderColor="base.border"
      borderTopRightRadius="md"
      borderBottomRightRadius="md"
      borderLeft="20px solid"
      borderLeftColor={colors.base[parentType ?? "or"]}
      borderLeftRadius="md"
    >
      <Controller
        name={`${path}.field`}
        render={({ field, fieldState: { error } }) => (
          <TextInput
            {...field}
            placeholder="Field name"
            isInvalid={Boolean(error)}
            width="auto"
          />
        )}
      />
      <Controller
        name={`${path}.operation`}
        render={({ field, fieldState: { error } }) => (
          <Select
            {...field}
            options={[
              {
                label: "equals",
                value: "equals",
              },
              {
                label: "not equals",
                value: "not-equals",
              },
              {
                label: "includes",
                value: "includes",
              },
              {
                label: "within",
                value: "within",
              },
            ]}
            placeholder="Operation"
            isInvalid={Boolean(error)}
            width="auto"
          />
        )}
      />
      {conditionOperation === "within" ? (
        <>
          <Controller
            name={`${path}.window.quantity`}
            defaultValue={1}
            render={({ field, fieldState: { error } }) => (
              <NumberInput {...field} isInvalid={Boolean(error)} width="auto" />
            )}
          />
          <Controller
            name={`${path}.window.unit`}
            defaultValue="day"
            render={({ field, fieldState: { error } }) => (
              <Select
                {...field}
                options={[
                  { label: "hour(s)", value: "hour" },
                  { label: "day(s)", value: "day" },
                  { label: "minute(s)", value: "minute" },
                ]}
                isInvalid={Boolean(error)}
                width="auto"
              />
            )}
          />
        </>
      ) : (
        <Controller
          name={`${path}.value`}
          render={({ field, fieldState: { error } }) => (
            <TextInput
              {...field}
              placeholder="value"
              isInvalid={Boolean(error)}
              width="auto"
            />
          )}
        />
      )}
      <Row flex={1} justifyContent="right">
        {(path === "filter" || path.match(/filter\.children\.([0-9]+)$/)) && (
          <Menu>
            <MenuActionsButton />
            <MenuList>
              <Box
                as={MenuItem}
                color="text.secondary"
                icon={DescriptionAddIcon}
                sx={{
                  svg: {
                    height: "24px",
                    width: "24px",
                  },
                }}
                onClick={() => {
                  setValue(path, {
                    type: parentType === "and" ? "or" : "and",
                    children: [
                      condition,
                      {
                        type: "evaluate",
                        operation: "equals",
                        field: "",
                        value: "",
                      },
                    ],
                  });
                }}
              >
                <Column>
                  <Text fontWeight="medium">Add group</Text>
                  <Text color="text.secondary">
                    Create a nested group from this filter
                  </Text>
                </Column>
              </Box>
            </MenuList>
          </Menu>
        )}
        <IconButton
          variant="danger"
          icon={DeleteIcon}
          aria-label="Delete condition"
          onClick={onDelete}
        />
      </Row>
    </Row>
  );
}

function Combinator({
  path,
}: {
  path: "filter" | `filter.children.${number}`;
}) {
  const { watch, setValue, getValues } = useFormContext<FormState>();
  const children = watch(`${path}.children`);
  const type = watch(`${path}.type`) as ConditionType.Or | ConditionType.And;

  return (
    <Column
      gap={3}
      pl={path === "filter" ? 0 : 3}
      borderLeft={path === "filter" ? "none" : "20px solid"}
      borderLeftColor={colors.base[type === "or" ? "and" : "or"]}
      borderLeftRadius="md"
    >
      {children.map((_, i) => (
        <>
          {i > 0 && (
            <AndOrToggleButton
              conditionType={type}
              onClick={toggleCondition(setValue, getValues)}
            />
          )}
          <Filter
            path={`${path}.children.${i}` as `filter.children.${number}`}
          />
        </>
      ))}
      <AddConditionButton
        onAdd={(condition) => {
          setValue(`${path}.children`, [...children, condition]);
        }}
      />
    </Column>
  );
}

function toggleCondition(setValue, getValues) {
  return () => {
    const { type, children } = getValues("filter");
    const nextType = type === "or" ? "and" : "or";
    for (let j = 0; j < children.length; j++) {
      if (children[j]?.type === nextType) {
        setValue(`filter.children.${j}.type`, type);
      }
    }
    setValue(`filter.type`, nextType);
  };
}

function AddConditionButton({
  onAdd,
}: {
  onAdd: (condition: EvaluateCondition) => void;
}) {
  return (
    <Row>
      <Button
        onClick={() =>
          onAdd({
            type: "evaluate",
            operation: "equals",
            field: "",
            value: "",
          })
        }
      >
        Add condition
      </Button>
    </Row>
  );
}
