import { useCallback, useEffect, useState } from "react";
import { QueryClient, useQueries, useQueryClient } from "react-query";
import { useToast } from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import partition from "lodash/partition";

import {
  RunVisualBackgroundQuery,
  RunVisualBackgroundQueryVariables,
  VisualQueryBackgroundResultQuery,
  VisualQueryBackgroundResultQueryVariables,
  useRunVisualBackgroundQuery,
  useVisualQueryBackgroundResultQuery,
} from "src/graphql";
import { VisualQueryFilter } from "src/types/visual";
import { fetcher } from "src/utils/fetcher";

type Input = {
  audiences: {
    id: string;
    filter: VisualQueryFilter;
  }[];
  parentModelId: string;
  sourceId: string;
};

type Output = {
  startRun: () => void;
  cancelRun: () => void;
  hasRun: boolean;
  isLoading: boolean;
  results: {
    [audienceId: string]: AudienceSizeCalculationResult;
  };
};

type BackgroundJob = {
  jobId?: string;
  audience: {
    id: string;
    filter: VisualQueryFilter;
  };
  parentModelId: string;
  running?: boolean;
};

type AudienceSizeCalculationResult = {
  isLoading: boolean;
  calculatedAt?: Date;
  size?: string;
  error?: string;
};

export const useCalculateAudienceSizes = ({
  audiences,
  parentModelId,
  sourceId,
}: Input): Output => {
  // 1. Fire off a background query for each audience
  const {
    backgroundJobs,
    onJobFinished,
    results,
    startRun,
    cancelRun,
    hasRun,
  } = useRunBackgroundJobs({ audiences, parentModelId, sourceId });

  // 2. Poll for the results of each background query and update the results
  usePollBackgroundJobs({ backgroundJobs, onJobFinished });

  return {
    startRun,
    isLoading: backgroundJobs.length > 0,
    results,
    cancelRun,
    hasRun,
  };
};

const runAudienceSizeQuery = async (
  queryClient: QueryClient,
  runId: number,
  queryVariables: RunVisualBackgroundQueryVariables,
) => {
  const variables: RunVisualBackgroundQueryVariables = {
    ...queryVariables,
    countOnly: true,
  };

  const res: RunVisualBackgroundQuery = await queryClient.fetchQuery({
    queryKey: [runId, ...useRunVisualBackgroundQuery.getKey(variables)],
    queryFn: fetcher(useRunVisualBackgroundQuery.document, variables),
  });

  return res;
};

// Make up to N background job queries concurrently
const BACKGROUND_JOB_CONCURRENCY_LIMIT = 4;

const useRunBackgroundJobs = ({
  audiences,
  parentModelId,
  sourceId,
}: Input) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();
  // A simple counter to delinate runs
  const [runId, setRunId] = useState(0);
  const [backgroundJobs, setBackgroundJobs] = useState<BackgroundJob[]>([]);
  const [results, setResults] = useState<{
    [audienceId: string]: AudienceSizeCalculationResult;
  }>({});

  // Start a new audience size run with the given audiences
  const startRun = useCallback(() => {
    setBackgroundJobs(
      audiences.map(({ id, filter }) => ({
        audience: { id: id.toString(), filter },
        parentModelId,
      })),
    );
    setResults(
      audiences.reduce((acc, { id }) => {
        acc[id] = { isLoading: true };
        return acc;
      }, {}),
    );
    setRunId((prev) => prev + 1);
  }, [audiences, parentModelId]);

  // Cancel the current audience size run
  const cancelRun = useCallback(() => {
    // Stop tracking all jobs. This will also stop polling on all jobs.
    setBackgroundJobs([]);
    // Clear the results
    setResults({});
  }, []);

  // When a job finishes, we need to stop tracking it and update the results
  const onJobFinished = useCallback(
    (payload: { audienceId: string; size?: string; error?: string }) => {
      const { audienceId, size, error } = payload;

      // Stop tracking the job
      setBackgroundJobs((jobs) =>
        jobs.filter((job) => job.audience.id !== audienceId),
      );

      // Store the results for the audience
      setResults((res) => ({
        ...res,
        [audienceId]: {
          ...res[audienceId],
          isLoading: false,
          calculatedAt: new Date(),
          size,
          error,
        },
      }));
    },
    [],
  );

  useEffect(() => {
    const runJob = async (payload: {
      audienceId: string;
      filter: VisualQueryFilter;
      parentModelId: string;
      sourceId: string;
    }) => {
      try {
        const res = await runAudienceSizeQuery(queryClient, runId, payload);

        // Store the job ID for the audience, so we can poll for the results
        setBackgroundJobs((jobs) =>
          jobs.map((job) =>
            job.audience.id === payload.audienceId
              ? { ...job, jobId: res.visualQueryBackground.jobId }
              : job,
          ),
        );
      } catch (error) {
        toast({
          id: "calculate-audience-sizes-error",
          title: "Error calculating audience sizes",
          message: "Please try again",
          variant: "error",
        });

        onJobFinished({ audienceId: payload.audienceId, error: error.message });
        captureException(error);
      }
    };

    if (backgroundJobs.length === 0) {
      // Nothing to do
      return;
    }

    const [runningJobs, queuedJobs] = partition(
      backgroundJobs,
      (job) => job.running,
    );

    // There will be no jobs to run if we're at the concurrency limit
    const jobsToRun = queuedJobs.slice(
      0,
      BACKGROUND_JOB_CONCURRENCY_LIMIT - runningJobs.length,
    );

    if (jobsToRun.length > 0) {
      // Update the running status of each scheduled job
      setBackgroundJobs((jobs) =>
        jobs.map((job) =>
          jobsToRun.includes(job) ? { ...job, running: true } : job,
        ),
      );

      // Run the jobs
      for (const job of jobsToRun) {
        const { audience, parentModelId } = job;
        runJob({
          audienceId: audience.id,
          filter: audience.filter,
          parentModelId,
          sourceId,
        });
      }
    }
  }, [backgroundJobs, sourceId, parentModelId, runId, queryClient]);

  useEffect(() => {
    // Cancel the any in-progress run when the component unmounts.
    // Note that this has to be in its own effect,
    // so changes in other dependencies don't trigger this cleanup.
    return () => {
      cancelRun();
    };
  }, [cancelRun]);

  return {
    backgroundJobs,
    onJobFinished,
    results,
    startRun,
    cancelRun,
    hasRun: runId > 0,
  };
};

// Poll for the results of each background query
const usePollBackgroundJobs = ({
  backgroundJobs,
  onJobFinished,
}: {
  backgroundJobs: BackgroundJob[];
  onJobFinished: (payload: {
    audienceId: string;
    size?: string;
    error?: string;
  }) => void;
}) => {
  useQueries(
    backgroundJobs.map(({ jobId, audience, parentModelId }) => {
      const variables: VisualQueryBackgroundResultQueryVariables = {
        page: 0,
        jobId: jobId ?? "",
        parentModelId,
        filter: audience.filter,
      };

      return {
        queryKey: [
          jobId,
          ...useVisualQueryBackgroundResultQuery.getKey(variables),
        ],
        queryFn: fetcher(
          useVisualQueryBackgroundResultQuery.document,
          variables,
        ),

        // Only poll if the job has been started.
        enabled: jobId != undefined,

        // Poll for the results every 500ms.
        refetchInterval: 500,

        onSuccess: (data: VisualQueryBackgroundResultQuery) => {
          if (!data.visualQueryBackgroundResult) {
            return;
          }

          let size: string | undefined;
          let error: string | undefined;

          if (
            data.visualQueryBackgroundResult.__typename ===
            "SuccessfulQueryResponse"
          ) {
            size =
              data.visualQueryBackgroundResult.numRowsWithoutLimit ?? undefined;
          } else {
            error = data.visualQueryBackgroundResult.error;
          }

          // Now that the job has finished running (regardless of whether it was successful),
          // we can stop tracking it.
          onJobFinished({ audienceId: audience.id, size, error });
        },
      };
    }),
  );
};
