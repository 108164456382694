import { FC, useState } from "react";

import { Text, Column, Spinner } from "@hightouchio/ui";

import {
  AnalyticsGraph,
  AnalyticsGraphProps,
} from "src/components/analytics/cross-audience-graph/analytics-graph";
import analyticsPlaceholder from "src/assets/placeholders/analytics.svg";
import errorImg from "src/ui/table/error.svg";
import { HTImage } from "src/components/image";
import { AnalyticsPlaceholderImage } from "src/pages/analytics/placeholders";

export const DEFAULT_CHART_HEIGHT = "400px";

export type ChartProps = AnalyticsGraphProps & {
  isLoading?: boolean;
  isPlaceholder?: boolean;
  isError?: boolean;
};

export const Chart: FC<ChartProps> = ({
  isLoading = false,
  isPlaceholder = false,
  isError = false,
  height = DEFAULT_CHART_HEIGHT,
  ...props
}) => {
  const [hoveredLine, setHoveredLine] = useState<string>();
  if (isPlaceholder) {
    return (
      <Column align="center" justify="center" minHeight={height} pos="relative">
        <HTImage src={analyticsPlaceholder} alt="Chart placeholder." h="100%" />
      </Column>
    );
  }
  if (isLoading || !props.graphSeries) {
    return (
      <Column align="center" justify="center" minHeight={height} gap={4}>
        <Spinner size="lg" />
        <Text color="text.secondary">Loading chart...</Text>
      </Column>
    );
  }
  if (isError) {
    return (
      <Column align="center" justify="center" minHeight={height} gap={4}>
        <HTImage src={errorImg} alt="Error icon." />
        <Column gap={1} align="center">
          <Text color="danger.base" fontWeight="medium">
            Failed to load
          </Text>
          <Text color="text.secondary">
            Check that your chart is configured correctly
          </Text>
        </Column>
      </Column>
    );
  }
  if (props.graphSeries.length === 0) {
    return (
      <Column
        align="center"
        justify="center"
        minHeight={height}
        gap={4}
        textAlign="center"
      >
        <AnalyticsPlaceholderImage />
        <Text fontWeight="medium">No data to display</Text>
      </Column>
    );
  }
  return (
    <AnalyticsGraph
      {...props}
      hoveredLine={hoveredLine}
      onHoverLine={setHoveredLine}
    />
  );
};
