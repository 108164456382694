import { FC, useState, useMemo } from "react";

import {
  Row,
  Box,
  Text,
  Button,
  FormField,
  TextInput,
  ClipboardButton,
  Paragraph,
  ButtonGroup,
  useToast,
  Dialog,
  Heading,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import { sha256 } from "js-sha256";
import moment from "moment";
import pluralize from "pluralize";
import { v4 as uuidv4 } from "uuid";

import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { useUser } from "src/contexts/user-context";
import {
  useApiKeysQuery,
  useCreateApiKeyMutation,
  useDeleteApiKeysMutation,
} from "src/graphql";
import { Table } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";

export const APIKeys: FC = () => {
  const { toast } = useToast();
  const [key, setKey] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [createNew, setCreateNew] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { workspace } = useUser();

  const { data } = useApiKeysQuery(
    {
      filter: {
        workspace_id: {
          _eq: workspace?.id || "",
        },
      },
    },
    {
      enabled: !!workspace?.id,
      suspense: true,
    },
  );
  const { isLoading: createLoading, mutateAsync: createApiKey } =
    useCreateApiKeyMutation();
  const { mutateAsync: bulkDelete } = useDeleteApiKeysMutation();

  const apiKeys = data?.api_keys;

  const bulkDeleteApiKeys = async () => {
    const count = selectedRows.length;
    const pluralizedLabel = pluralize("API key", count);

    try {
      await bulkDelete({ ids: selectedRows.map(String) });

      toast({
        id: "bulk-delete-api-keys",
        title: `Deleted ${count} ${pluralizedLabel}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-delete-api-keys",
        title: `Failed to delete ${pluralizedLabel}`,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "Name",
        cell: ({ name }) => (
          <Text fontWeight="medium" isTruncated>
            {name}
          </Text>
        ),
      },
      {
        name: "Last used",
        max: "240px",
        cell: ({ last_used }) =>
          last_used ? moment(last_used)?.calendar() : "Never",
      },
      {
        name: "Created at",
        max: "240px",
        cell: ({ created_at }) => moment(created_at).calendar(),
      },
    ],
    [],
  );

  return (
    <>
      <Row justify="space-between" mb={6}>
        <Box>
          <Heading>API keys</Heading>
          <Paragraph mt={1}>
            Create API keys for programmatic access to your workspace via our{" "}
            <Link href="https://hightouch.com/docs/developer-tools/api-guide">
              API
            </Link>{" "}
            or{" "}
            <Link href="https://hightouch.com/docs/developer-tools/cli-guide">
              CLI
            </Link>
            .
          </Paragraph>
        </Box>

        <ButtonGroup size="lg">
          {selectedRows.length > 0 && (
            <Button variant="warning" onClick={() => setConfirmingDelete(true)}>
              Delete selected
            </Button>
          )}
          <Button
            variant="primary"
            onClick={() => {
              const id = uuidv4();
              setKey(id);
              setName(null);
              setCreateNew(true);
            }}
          >
            Add API key
          </Button>
        </ButtonGroup>
      </Row>

      <Table
        columns={columns}
        data={apiKeys}
        placeholder={placeholder}
        selectedRows={selectedRows}
        onSelect={onRowSelect}
      />

      <Dialog
        isOpen={createNew}
        variant="form"
        width="lg"
        title="Create API Key"
        actions={
          <ButtonGroup>
            <Button onClick={() => setCreateNew(false)}>Cancel</Button>
            <Button
              isDisabled={!name}
              isLoading={createLoading}
              variant="primary"
              onClick={async () => {
                if (key) {
                  await createApiKey({
                    object: { name, hash: sha256.create().update(key).hex() },
                  });
                }

                setCreateNew(false);
              }}
            >
              Create API key
            </Button>
          </ButtonGroup>
        }
        onClose={() => {
          setCreateNew(false);
        }}
      >
        <Box display="flex" flexDirection="column" gap={6}>
          <FormField label="Name">
            <TextInput
              placeholder="Enter a name..."
              value={name ?? ""}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </FormField>

          <FormField
            description="This key will only be displayed once, please copy it into your secrets manager."
            label="API key"
          >
            <Box display="flex" gap={3}>
              <TextInput isReadOnly value={key ?? ""} />
              <ClipboardButton text={key ?? ""} />
            </Box>
          </FormField>
        </Box>
      </Dialog>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="API key"
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeleteApiKeys}
      />
    </>
  );
};

const placeholder = {
  title: "No API keys",
  error: "API keys failed to load, please try again.",
};
