import * as Sentry from "@sentry/react";
import { QueryClient, MutationCache, DefaultOptions } from "react-query";

declare module "react-query" {
  interface QueryMeta {
    isFastEndpoint?: boolean;
    isAdmin?: boolean;
  }
}

const mutationCache = new MutationCache({});

export const queryClient = new QueryClient({ mutationCache });

const initialQueryInvalidators = ["update_workspace"];

const preservedQueryKeys = [
  "Initial",
  "PermissionsV2",
  "Entitlements",
  "Workspaces",
];

export const invalidateQueries = () => {
  // small optimization to not invalidate initial query unless required
  queryClient.invalidateQueries({
    predicate: ({ queryKey }) =>
      !preservedQueryKeys.includes(queryKey[0] as string),
  });
};

const options: DefaultOptions = {
  queries: {
    staleTime: 1000 * 60 * 5, // 5 minutes
    retry: false,
    refetchOnWindowFocus: false,
    onError: () => {
      // handle all graphql errors with global toast and reporting
    },
  },
  mutations: {
    onError: () => {
      // handle all graphql errors with global toast and reporting
    },
    onSuccess: (data) => {
      if (queryClient) {
        const mutationKey =
          data && typeof data === "object" ? Object.keys(data)[0] : undefined;

        const mutationReturn =
          mutationKey && data && typeof data === "object"
            ? data[mutationKey]
            : undefined;
        if (mutationReturn === null) {
          Sentry.captureException(
            `You do not have permission to make this change. Nothing was returned from query ${mutationKey}`,
          );
        }
        // every mutation except will invalidate all active queries
        // to override this behavior pass `onSuccess` to the mutation hook.
        if (
          mutationKey &&
          initialQueryInvalidators.some((key) => mutationKey.includes(key))
        ) {
          queryClient.invalidateQueries();
        } else {
          invalidateQueries();
        }
      }
    },
  },
};

queryClient.setDefaultOptions(options);
