import { useMemo, useState } from "react";

import {
  Button,
  ConfirmationDialog,
  FormField,
  Paragraph,
  Row,
  TextInput,
  Tooltip,
} from "@hightouchio/ui";

import { Form, useHightouchForm } from "src/components/form";
import {
  useEventRelatedJourneysQuery,
  useUpdateEventMutation,
} from "src/graphql";
import {
  SchemaField,
  SchemaFieldForm,
  SchemaObjectType,
} from "src/pages/schema/types";

import { ColumnField } from "./column-field";
import { noop } from "lodash";
import { DependenciesModal } from "src/components/modals/dependencies-modal";

export const EventPrimaryKeyForm: SchemaFieldForm = ({
  object,
  hasUpdatePermission,
}) => {
  const { mutateAsync: updateEvent } = useUpdateEventMutation();
  const [showLinkedResources, setShowLinkedResources] = useState(false);

  const eventRelatedJourneysQuery = useEventRelatedJourneysQuery(
    {
      id: object.id,
    },
    {
      select: (data) => data.journeys,
    },
  );

  const form = useHightouchForm({
    onSubmit: async ({ primary_key }) => {
      await updateEvent({
        id: object.id,
        set: {
          primary_key,
        },
      });
    },
    values: {
      primary_key: object.event?.primary_key ?? null,
    },
  });

  const primaryKey = form.watch("primary_key");
  const isUsedInAtLeastOneJourney =
    eventRelatedJourneysQuery.data &&
    eventRelatedJourneysQuery.data?.length > 0;

  if (primaryKey && isUsedInAtLeastOneJourney) {
    return (
      <>
        <FormField
          label="Primary key"
          tip="Required if you want to use this event to trigger Hightouch journeys"
        >
          <Row gap={2}>
            <Tooltip message="This event is used as an entry event in at least one journey and cannot be changed">
              <TextInput isDisabled value={primaryKey} onChange={noop} />
            </Tooltip>
            <Button onClick={() => setShowLinkedResources(true)}>
              View linked journeys
            </Button>
          </Row>
        </FormField>
        <DependenciesModal
          isOpen={showLinkedResources}
          onClose={() => setShowLinkedResources(false)}
          resourceType="journey"
          content={
            <Paragraph>
              This event is referenced in the following journeys
            </Paragraph>
          }
          dependencies={[
            {
              name: "Journeys",
              resources: eventRelatedJourneysQuery.data?.map((journey) => ({
                id: journey.id,
                name: journey.name,
                type: "journey",
                url: `/journeys/${journey.id}`,
                created_at: journey.created_at,
                updated_at: journey.updated_at,
                created_by_user: journey.created_by_user,
                updated_by_user: journey.updated_by_user,
              })),
            },
          ]}
        />
      </>
    );
  }

  return (
    <Form form={form}>
      <EventPrimaryKeyField
        object={object}
        isDisabled={!hasUpdatePermission || eventRelatedJourneysQuery.isLoading}
        isLoading={
          form.formState.isSubmitting || eventRelatedJourneysQuery.isLoading
        }
      />
      <ConfirmationDialog
        isOpen={primaryKey != object.event?.primary_key}
        title="Are you sure?"
        confirmButtonText="Confirm"
        variant="danger"
        onConfirm={async () => {
          await form.submit();
        }}
        onClose={() => {
          form.reset({
            primary_key: object.event?.primary_key ?? null,
          });
        }}
      >
        <Paragraph>
          This will update the primary key for this event. For any journeys that
          use this event for entry, this update could result in unexpected
          duplicate events in the journey and duplicate messages sent.
        </Paragraph>
      </ConfirmationDialog>
    </Form>
  );
};

export const EventPrimaryKeyField: SchemaField = (props) => {
  const object = useMemo(
    () =>
      ({
        ...props.object,
        columns: props.object?.columns,
      }) as SchemaObjectType,
    [props.object],
  );

  return (
    <ColumnField
      isOptional
      name="primary_key"
      label="Primary key"
      tip="Required if you want to use this event to trigger Hightouch journeys"
      {...props}
      object={props.object ? object : undefined}
    />
  );
};
