import { Column, Row, Text } from "@hightouchio/ui";
import pluralize from "pluralize";

import { useOutletContext } from "src/router";
import { Context } from ".";

export const SyncRunPendingRows = () => {
  const { syncRequest } = useOutletContext<Context>();

  const pendingRows = syncRequest.query_run?.pending_rows ?? 0;

  return (
    <Column height="100%">
      <Row alignItems="center" mb={2} mt={6} width="100%">
        <Text>
          {pendingRows > 0
            ? `${pendingRows} ${pluralize(
                "row",
                pendingRows,
              )} will sync after the next enrichment.`
            : "All rows have been enriched and synced in the latest run."}
        </Text>
      </Row>
    </Column>
  );
};
