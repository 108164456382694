import { Combobox, FormField, Radio, RadioGroup } from "@hightouchio/ui";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DestinationsQuery, useModelColumnsQuery } from "src/graphql";

export const IdentifierField: FC<
  Readonly<{
    prefix?: string;
    destination: DestinationsQuery["destinations"][number] | undefined;
  }>
> = ({ prefix = "", destination }) => {
  const { watch } = useFormContext();
  const modelId = watch("segment_id");

  const { data: columns, isLoading } = useModelColumnsQuery(
    {
      modelId,
    },
    { select: (data) => data.model_columns },
  );

  return (
    <>
      {destination?.type === "iterable" && (
        <Controller
          name={`${prefix}config.recipientType`}
          render={({ field, fieldState: { error } }) => {
            return (
              <FormField label="Identifier type" error={error?.message}>
                <RadioGroup {...field}>
                  <Radio value="email" label="Email" />
                  <Radio value="userId" label="User ID" />
                </RadioGroup>
              </FormField>
            );
          }}
        />
      )}

      {destination?.type === "attentive" && (
        <Controller
          name={`${prefix}config.recipientType`}
          render={({ field, fieldState: { error } }) => {
            return (
              <FormField label="Identifier type" error={error?.message}>
                <RadioGroup {...field}>
                  <Radio value="email" label="Email" />
                  <Radio value="phone" label="Phone" />
                </RadioGroup>
              </FormField>
            );
          }}
        />
      )}
      <Controller
        name={`${prefix}config.externalId`}
        render={({ field, fieldState: { error } }) => {
          return (
            <FormField
              label="Identifier"
              description="Which user column maps to the user identifier in the destination"
              error={error?.message}
            >
              <Combobox
                placeholder="Select identifier..."
                {...field}
                width="2xs"
                isLoading={isLoading}
                isInvalid={Boolean(error)}
                optionValue={(option) => option.name}
                optionLabel={(option) => option.alias || option.name}
                options={columns ?? []}
              />
            </FormField>
          );
        }}
      />
    </>
  );
};
