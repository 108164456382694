import { HTImage } from "src/components/image";
import { FC } from "react";

import {
  Box,
  Heading,
  Button,
  PlusIcon,
  Column,
  Row,
  Text,
  ArrowRightIcon,
} from "@hightouchio/ui";

import { Terms } from "src/components/auth/terms";
import { usePartner } from "src/partner/context";
import { PartnerLogo } from "src/partner/partner-logo";
import { Logo } from "src/ui/brand";

import fastIcon from "./assets/fast-icon.svg";
import scaleIcon from "./assets/scale-icon.svg";
import secureIcon from "./assets/secure-icon.svg";

const cardContainerStyles = {
  border: ["none", "none", "1px solid var(--chakra-colors-base-border)"],
  borderRadius: "md",
  gap: 10,
  boxShadow: ["none", "none", "lg"],
  maxW: "576px",
  p: [0, 0, 10],
  mt: 6,
};

export const Welcome: FC<Readonly<{ onContinue: () => void }>> = ({
  onContinue,
}) => {
  const { brand } = usePartner();

  const cardContent = (
    <>
      <Box px={{ base: 0, sm: 12 }}>
        <Heading size="xl" textAlign="center">
          {brand.name} uses Hightouch to connect to your data source
        </Heading>
      </Box>

      <Column gap={6} mx="auto">
        <Row gap={4} align="flex-start">
          <HTImage src={fastIcon} height="40px" alt="Fast integration icon" />
          <Column>
            <Text fontWeight="medium" size="lg">
              Fast and flexible
            </Text>
            <Text color="text.secondary">
              Connect to {import.meta.env.VITE_SOURCES_COUNT}+ sources,
              including data warehouses, transactional databases, and object
              storage.
            </Text>
          </Column>
        </Row>

        <Row gap={4} align="flex-start">
          <HTImage
            src={secureIcon}
            height="40px"
            alt="Secure integration icon"
          />
          <Column>
            <Text fontWeight="medium" size="lg">
              Secure by default
            </Text>
            <Text color="text.secondary">
              Data transfer is encrypted, and credentials are never shared with{" "}
              {brand.name}. Hightouch does not store your data after a sync is
              complete.
            </Text>
          </Column>
        </Row>

        <Row gap={4} align="flex-start">
          <HTImage
            src={scaleIcon}
            height="40px"
            alt="Scalable integration icon"
          />
          <Column>
            <Text fontWeight="medium" size="lg">
              Built for scale
            </Text>
            <Text color="text.secondary">
              Sync billions of records at the click of a button. Hightouch
              monitors your data and syncs records only when they change.
            </Text>
          </Column>
        </Row>
      </Column>

      <Button
        size="lg"
        variant="primary"
        directionIcon={ArrowRightIcon}
        onClick={onContinue}
      >
        Continue
      </Button>
    </>
  );

  return (
    <Column
      mx="auto"
      p={6}
      gap={6}
      align="center"
      justify="start"
      minH="100vh"
      w="100%"
      bg="base.lightBackground"
    >
      <Row gap={6} align="center" justify="center">
        <PartnerLogo />
        <Box fontSize="xl" color="text.secondary">
          <PlusIcon />
        </Box>
        <Logo variant="full" size="110px" theme="dark" />
      </Row>

      <Column
        display={["flex", "flex", "none"]}
        bgColor="base.lightBackground"
        sx={{ ...cardContainerStyles }}
      >
        {cardContent}
      </Column>

      <Column
        display={["none", "none", "flex"]}
        bgColor="white"
        sx={{ ...cardContainerStyles }}
      >
        {cardContent}
      </Column>
      <Terms variant="light" />
    </Column>
  );
};
