import { useEffect, useMemo, useState } from "react";

import { Text, Row, SearchInput, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";
import { useNavigate } from "src/router";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DetailPage } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import {
  PermissionedButton,
  PermissionedLinkButton,
} from "src/components/permission";
import {
  OrderBy,
  PriorityListsBoolExp,
  PriorityListsOrderBy,
  PriorityListsQuery,
  useDeletePriorityListMutation,
  usePriorityListsQuery,
} from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import {
  Pagination,
  Table,
  TableColumn,
  useTableConfig,
  useTableSort,
  SortOption,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useRowSelect } from "src/ui/table/use-row-select";
import { openUrl } from "src/utils/urls";

const initialSort: SortOption<keyof PriorityListsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof PriorityListsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  initialSort,
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

const columns: TableColumn[] = [
  {
    name: "Name",
    cell: ({ name }: PriorityListsQuery["priority_lists"][0]) => (
      <Text isTruncated fontWeight="medium">
        {name}
      </Text>
    ),
  },
  {
    name: "Parent model",
    cell: ({ parent_model }: PriorityListsQuery["priority_lists"][0]) => {
      return (
        <Row
          align="center"
          onClick={(event) => event.stopPropagation()}
          gap={2}
          overflow="hidden"
        >
          <IntegrationIcon
            name={parent_model.connection?.definition.name}
            src={parent_model.connection?.definition.icon}
          />
          <Text isTruncated fontWeight="medium" color="inherit">
            {parent_model?.name}
          </Text>
        </Row>
      );
    },
  },
  {
    ...LastUpdatedColumn,
    breakpoint: "sm",
  },
];

export const PriorityLists = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [search, setSearch] = useQueryState("search");

  const { selectedRows, onRowSelect } = useRowSelect();
  const { limit, offset, page, setPage } = useTableConfig<PriorityListsOrderBy>(
    {
      limit: 50,
    },
  );
  const orderBy = useTableSort<PriorityListsOrderBy>(initialSort, sortOptions);

  const deletePriorityListMutation = useDeletePriorityListMutation();

  const filters: PriorityListsBoolExp | undefined = useMemo(() => {
    const value = search?.trim();
    if (value) {
      return {
        _or: [
          {
            name: { _ilike: `%${value}%` },
          },
          {
            parent_model: { name: { _ilike: `%${value}%` } },
          },
        ],
      };
    }
    return undefined;
  }, [search]);

  const priorityListsQuery = usePriorityListsQuery({
    filters,
    limit,
    offset,
    orderBy,
  });

  const priorityLists = priorityListsQuery.data?.priority_lists ?? [];
  const priorityListsCount =
    priorityListsQuery.data?.priority_lists_aggregate.aggregate?.count;

  const onRowClick = ({ id }, event) =>
    openUrl(`/priority-lists/${id}`, navigate, event);

  const bulkDeletePriorityLists = async () => {
    const count = selectedRows.length;
    const pluralizedLabel = pluralize("priority list", count);

    try {
      const promises = selectedRows.map((id) =>
        deletePriorityListMutation.mutateAsync({ id: id.toString() }),
      );
      await Promise.all(promises);

      toast({
        id: "bulk-delete-priority-lists",
        title: `Deleted ${count} ${pluralizedLabel}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-delete-priority-lists",
        title: `Failed to delete ${pluralizedLabel}`,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    setPage(0);
  }, [filters]);

  return (
    <DetailPage
      heading="Priority lists"
      actions={
        <>
          <SearchInput
            placeholder="Search by name or parent model name..."
            value={search ?? ""}
            onChange={(event) => setSearch(event.target.value)}
          />
          {selectedRows?.length > 0 && (
            <PermissionedButton
              permission={{ v1: { resource: "audience", grant: "delete" } }}
              variant="warning"
              onClick={() => setConfirmingDelete(true)}
            >
              {pluralize("Delete priority list", selectedRows.length)}
            </PermissionedButton>
          )}
          <PermissionedButton
            permission={{ v1: { resource: "audience", grant: "create" } }}
            variant="primary"
            onClick={() => {
              analytics.track("Add Priority List Button Clicked");
              navigate("/priority-lists/new");
            }}
          >
            Add priority list
          </PermissionedButton>
        </>
      }
    >
      <Table
        columns={columns}
        data={priorityLists}
        error={Boolean(priorityListsQuery.error)}
        loading={
          priorityListsQuery.isLoading || priorityListsQuery.isRefetching
        }
        placeholder={{
          title: "No priority lists",
          body: "A priority list allows you to force mutual exclusivity on a set of audiences. Any member of multiple audiences in the set will only be included in the highest priority audience.",
          button: (
            <PermissionedLinkButton
              href="/priority-lists/new"
              permission={{ v1: { resource: "audience", grant: "create" } }}
            >
              Add priority list
            </PermissionedLinkButton>
          ),
          error: "Priority lists failed to load, please try again.",
        }}
        selectedRows={selectedRows}
        onRowClick={onRowClick}
        onSelect={onRowSelect}
      />
      <Row justify="flex-end" width="100%" px={4} mt={4}>
        <Pagination
          count={priorityListsCount}
          label="priority lists"
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Row>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="priority list"
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeletePriorityLists}
      />
    </DetailPage>
  );
};
