import {
  FormField,
  IconButton,
  RefreshIcon,
  Row,
  Combobox,
} from "@hightouchio/ui";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  useSlackChannelsQuery,
  useSlackCredentialsQuery,
  useWorkspaceNotificationChannelsQuery,
} from "src/graphql";

export const SlackRecipientForm: FC = () => {
  const { watch, setValue } = useFormContext();
  const currentConfig = watch("config");
  const currentChannelId = watch("channelId");

  const { data: slackCredentialsData, isLoading: slackCredentialsLoading } =
    useSlackCredentialsQuery();
  const slackCredential = slackCredentialsData?.slack_credentials?.[0];
  const {
    data: slackChannelsData,
    refetch: refetchSlackChannels,
    isRefetching: slackChannelsRefetching,
    isLoading: slackChannelsLoading,
    error: slackChannelsError,
  } = useSlackChannelsQuery(
    {
      id: Number(slackCredential?.id),
      currentChannelId: currentConfig?.slackChannelId ?? "",
    },
    {
      enabled: Boolean(slackCredential),
      select: (data) => data.listSlackChannelsByCredentials,
    },
  );

  const { data: workspaceChannels } = useWorkspaceNotificationChannelsQuery(
    undefined,
    {
      select: (data) => data.workspace_notification_channels,
    },
  );

  return (
    <Controller
      rules={{
        required: true,
        validate: (value) =>
          workspaceChannels?.some(
            (c) =>
              currentChannelId &&
              c.id !== currentChannelId &&
              c.config.slackChannelId === value,
          )
            ? "This channel is already in use"
            : true,
      }}
      name="config.slackChannelId"
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <FormField
            description="Select a public channel from the dropdown. If the channel you’re looking for isn’t listed, verify that the Hightouch Slack app has been added to it. For private channels, input the alphanumeric channel ID instead, as private channels won’t appear in the dropdown."
            label="Slack channel"
            error={slackChannelsError?.message || error?.message}
          >
            <Row gap={2}>
              <Combobox
                width="100%"
                isLoading={
                  slackChannelsLoading ||
                  slackCredentialsLoading ||
                  slackChannelsRefetching
                }
                options={
                  slackChannelsData
                    ?.map((channel) => ({
                      label: channel.name,
                      value: channel.id,
                    }))
                    ?.sort((a, b) => (a.label > b.label ? 1 : -1)) ?? []
                }
                value={value}
                placeholder="Select a Slack channel..."
                isDisabled={!slackCredential}
                onChange={(selected) => {
                  if (!selected) return;
                  onChange(selected);
                  setValue(
                    "config.name",
                    slackChannelsData?.find((s) => s.id === selected)?.name ||
                      selected,
                  );
                }}
                supportsCreatableOptions={true}
                onCreateOption={async (createdChannel) => {
                  setValue("config.slackChannelId", createdChannel);
                  const { data } = await refetchSlackChannels();
                  setValue(
                    "config.name",
                    data?.find((s) => s.id === createdChannel)?.name,
                    { shouldTouch: true },
                  );
                }}
              />
              <IconButton
                variant="secondary"
                isDisabled={!slackCredential}
                aria-label="refetch channels"
                icon={RefreshIcon}
                onClick={() => refetchSlackChannels()}
              />
            </Row>
          </FormField>
        );
      }}
    />
  );
};
