import { HTImage } from "src/components/image";
import { FC } from "react";

import { Box, LockIcon } from "@hightouchio/ui";

type Props = {
  name: string | undefined;
  src: string | undefined;
  size?: number;
};

export const IntegrationIcon: FC<Readonly<Props>> = ({
  name,
  src,
  size = 6,
}) => {
  return src ? (
    <HTImage
      alt={name ?? ""}
      src={src ?? ""}
      objectFit="contain"
      width={size}
      height={size}
      flexShrink={0}
    />
  ) : (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={size}
      height={size}
      borderRadius="50%"
      bg="gray.100"
      flexShrink={0}
      title="Private"
    >
      <LockIcon color="var(--chakra-colors-gray-500)" />
    </Box>
  );
};
