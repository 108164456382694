import { FC, useState } from "react";

import {
  ArrowRightIcon,
  Box,
  BoxProps,
  ChakraAccordion,
  ChakraAccordionButton,
  ChakraAccordionItem,
  ChakraAccordionPanel,
  ChevronRightIcon,
  Column,
  EmptyState,
  Badge,
  Row,
  SectionHeading,
  SkeletonBox,
  StatsItemTitle,
  Text,
} from "@hightouchio/ui";
import { times } from "lodash";
import { UnreachableCaseError } from "ts-essentials";

import { RouterLink } from "src/router";
import syncPlaceholder from "src/assets/placeholders/sync.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { useSyncHealthTableQuery } from "src/graphql";
import { commaNumber } from "src/utils/numbers";
import { Card } from "src/components/card";
import { LinkWithTooltip } from "src/components/link-with-tooltip";

interface ResourceOperationsItem {
  name: string;
  type: string;
  friendlyType: string;
  icon: string;
  succeeded: number;
  warning: number;
  failed: number;
  inactive: number;
}

type Variant = "success" | "warning" | "failed" | "inactive";

type CellStyleConfig = {
  bgColor: string;
  hoverBgColor: string;
  textColor: string;
};

const gridTemplateColumns = "minmax(300px, 1fr) 180px 180px 180px 180px";

// TODO: Remove once alerting v2 is true for all workspaces
export const SyncHealthTableLegacy: FC = () => {
  const { data: tableData, isLoading: isTableLoading } =
    useSyncHealthTableQuery(undefined, {
      select: transformDestinationData,
      suspense: true,
    });

  const resources = tableData ?? {};
  const hasResources = Object.keys(resources).length > 0;

  if (!isTableLoading && !hasResources) {
    return (
      <Card sx={{ "& > div": { p: 0, pt: 4, border: "none" } }}>
        <SectionHeading mb={4}>Sync status by destination</SectionHeading>
        <EmptyState
          title="No active syncs"
          message="A sync defines how and when your data will be sent to a destination. This table only shows syncs that are enabled."
          imageUrl={syncPlaceholder}
          m={4}
        />
      </Card>
    );
  }

  return (
    <Card overflow="hidden">
      <SectionHeading mb={4}>Sync status by destination</SectionHeading>
      <Box display="grid" gridTemplateColumns={gridTemplateColumns}>
        <Cell height="48px">
          <StatsItemTitle>Destination</StatsItemTitle>
        </Cell>
        <Cell justifyContent="center" height="48px">
          <StatsItemTitle>Healthy</StatsItemTitle>
        </Cell>
        <Cell justifyContent="center" height="48px">
          <StatsItemTitle>Warning</StatsItemTitle>
        </Cell>
        <Cell justifyContent="center" height="48px">
          <StatsItemTitle>Failing</StatsItemTitle>
        </Cell>
        <Cell justifyContent="center" height="48px">
          <StatsItemTitle>Inactive</StatsItemTitle>
        </Cell>
      </Box>
      <Column overflow="auto">
        {hasResources
          ? Object.entries(resources).map(([, resources], index) => (
              <DestinationRow key={index} resourceItems={resources} />
            ))
          : times(10, (idx) => <SyncHealthSkeleton key={idx} />)}
      </Column>
    </Card>
  );
};

const SyncsCountCell: FC<{
  count: number;
  variant: Variant;
  url: string;
}> = ({ count, variant, url }) => {
  const [hover, setHover] = useState(false);

  const { bgColor, hoverBgColor, textColor } = getCellStyleConfig(
    variant,
    !count,
  );

  const formattedSyncs = commaNumber(count);

  const isLink = url && count > 0;
  const href = `${url}&status=${variant}`;

  return (
    <Cell
      as={isLink ? RouterLink : "div"}
      {...(isLink ? { to: href } : {})}
      bg={bgColor}
      pos="relative"
      transition="all 150ms ease"
      _groupHover={{ bg: hoverBgColor }}
      justifyContent="center"
      alignItems="center"
      fontWeight="medium"
      color={textColor}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && isLink ? (
        <Box>
          View syncs <ArrowRightIcon />
        </Box>
      ) : (
        formattedSyncs
      )}
    </Cell>
  );
};

const SyncRow: FC<{
  resource: ResourceOperationsItem;
  resourceId: string;
  nested?: boolean;
}> = ({ resource, resourceId, nested = false }) => {
  const syncUrl = `/syncs?destination=${resourceId}`;

  return (
    <Box
      position="relative"
      display="grid"
      gridTemplateColumns={gridTemplateColumns}
      className="group"
    >
      <Cell
        transition="background-color 150ms ease"
        _groupHover={{ bg: "gray.100" }}
        pl={nested ? 12 : undefined}
        gap={2}
        alignItems="center"
      >
        <IntegrationIcon src={resource.icon} name={resource.name} />
        <LinkWithTooltip href={syncUrl} fontWeight="medium" color="inherit">
          {resource.name}
        </LinkWithTooltip>
      </Cell>
      <SyncsCountCell
        count={resource.succeeded}
        variant="success"
        url={syncUrl}
      />
      <SyncsCountCell
        count={resource.warning}
        variant="warning"
        url={syncUrl}
      />
      <SyncsCountCell count={resource.failed} variant="failed" url={syncUrl} />
      <SyncsCountCell
        count={resource.inactive}
        variant="inactive"
        url={syncUrl}
      />
    </Box>
  );
};

const DestinationRow: FC<{
  resourceItems: Record<string, ResourceOperationsItem>;
}> = ({ resourceItems }) => {
  const [showExpandIcon, setShowExpandIcon] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  const entries = Object.entries(resourceItems);

  if (entries.length === 1) {
    const [resourceId, resource] = entries[0] || [null, null];
    if (!resource) return null;
    return <SyncRow resource={resource} resourceId={resourceId} />;
  }

  const resourceIds = Object.keys(resourceItems);
  const syncUrl = `/syncs?destination=${resourceIds.join(",")}`;
  const firstResource = entries[0]?.[1];

  const successTotal = entries.reduce(
    (acc, [, resource]) => acc + resource.succeeded,
    0,
  );
  const warningTotal = entries.reduce(
    (acc, [, resource]) => acc + resource.warning,
    0,
  );
  const failedTotal = entries.reduce(
    (acc, [, resource]) => acc + resource.failed,
    0,
  );
  const inactiveTotal = entries.reduce(
    (acc, [, resource]) => acc + resource.inactive,
    0,
  );

  return (
    <ChakraAccordion
      allowToggle
      index={expanded ? [0] : []}
      onChange={(e) => setExpanded(e === 0)}
    >
      <ChakraAccordionItem border="none">
        <Box
          display="grid"
          gridTemplateColumns={gridTemplateColumns}
          className="group"
        >
          <Cell
            cursor="pointer"
            alignItems="center"
            transition="background-color 150ms ease"
            _groupHover={{ bg: "gray.100" }}
            gap={2}
            onClick={() => setExpanded(!expanded)}
            onMouseEnter={() => setShowExpandIcon(true)}
            onMouseLeave={() => setShowExpandIcon(false)}
          >
            {firstResource && (
              <>
                <IntegrationIcon
                  name={firstResource.name}
                  src={firstResource.icon}
                />
                <Text fontWeight="medium" isTruncated>
                  {firstResource.friendlyType}
                </Text>
                <Badge>{entries.length}</Badge>

                <ChakraAccordionButton
                  outline="none !important"
                  p={1}
                  borderRadius="sm"
                  width="fit-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Box
                    as={ChevronRightIcon}
                    opacity={showExpandIcon || expanded ? 1 : 0}
                    fontSize="18px"
                    transform={expanded ? "rotate(90deg)" : ""}
                    transition="all 150ms ease"
                  />
                </ChakraAccordionButton>
              </>
            )}
          </Cell>
          <SyncsCountCell
            count={successTotal}
            variant="success"
            url={syncUrl}
          />
          <SyncsCountCell
            count={warningTotal}
            variant="warning"
            url={syncUrl}
          />
          <SyncsCountCell count={failedTotal} variant="failed" url={syncUrl} />
          <SyncsCountCell
            count={inactiveTotal}
            variant="inactive"
            url={syncUrl}
          />
        </Box>

        <ChakraAccordionPanel p={0}>
          {entries.map(([id, resource]) => (
            <SyncRow nested key={id} resource={resource} resourceId={id} />
          ))}
        </ChakraAccordionPanel>
      </ChakraAccordionItem>
    </ChakraAccordion>
  );
};

const SyncHealthSkeleton = () => {
  return (
    <Box display="grid" gridTemplateColumns={gridTemplateColumns}>
      <Cell>
        <Row alignItems="center" gap={2}>
          <SkeletonBox borderRadius="full" width="24px" height="24px" />
          <SkeletonBox width="200px" height="16px" />
        </Row>
      </Cell>
      <Cell justifyContent="center" alignItems="center">
        <SkeletonBox width="50px" height="16px" />
      </Cell>
      <Cell justifyContent="center" alignItems="center">
        <SkeletonBox width="50px" height="16px" />
      </Cell>
      <Cell justifyContent="center" alignItems="center">
        <SkeletonBox width="50px" height="16px" />
      </Cell>
    </Box>
  );
};

// Group by destination type and then destination id
const transformDestinationData = (
  data: any,
): Record<string, Record<string, ResourceOperationsItem>> => {
  if (!data?.destinations?.length) return {};

  const resourcesByType: Record<
    string,
    Record<string, ResourceOperationsItem>
  > = {};

  for (const destination of data.destinations) {
    const destinationType = destination.type;
    const destinationId = destination.id;
    const destinationName = destination.name || "Unknown";
    const destinationIcon = destination.definition?.icon || "";
    const destinationFriendlyType =
      destination.definition?.name || destinationType;

    // Skip if destination doesn't have any syncs
    if (!destination.syncs?.length) continue;

    // Initialize the destination type bucket if it doesn't exist
    if (!resourcesByType[destinationType]) {
      resourcesByType[destinationType] = {};
    }

    const activeSyncs = destination.syncs.filter(
      (sync: any) => !sync.schedule_paused && sync.last_run_at,
    );

    const inactiveSyncs = destination.syncs.length - activeSyncs.length;

    const succeeded = activeSyncs.filter(
      (sync: any) => sync.status === "success",
    ).length;
    const warning = activeSyncs.filter(
      (sync: any) => sync.status === "warning",
    ).length;
    const failed = activeSyncs.filter(
      (sync: any) => sync.status === "failed",
    ).length;

    // Create the resource operations item
    resourcesByType[destinationType][destinationId] = {
      name: destinationName,
      type: destinationType,
      friendlyType: destinationFriendlyType,
      icon: destinationIcon,
      succeeded,
      warning,
      failed,
      inactive: inactiveSyncs,
    };
  }

  return resourcesByType;
};

export const Cell: FC<BoxProps> = ({ children, ...props }) => {
  return (
    <Row
      px={6}
      height="64px"
      alignItems="center"
      borderBottom="1px"
      borderColor="base.border"
      flexShrink={0}
      {...props}
    >
      {children}
    </Row>
  );
};

const getCellStyleConfig = (
  variant: Variant,
  noSyncs: boolean,
): CellStyleConfig => {
  if (noSyncs) {
    return {
      bgColor: "base.lightBackground",
      hoverBgColor: "gray.100",
      textColor: "text.tertiary",
    };
  }

  switch (variant) {
    case "warning":
      return {
        bgColor: "warning.background",
        hoverBgColor: "warning.200",
        textColor: "warning.800",
      };
    case "failed":
      return {
        bgColor: "danger.background",
        hoverBgColor: "danger.200",
        textColor: "danger.dark",
      };
    case "success":
      return {
        bgColor: "success.background",
        hoverBgColor: "grass.200",
        textColor: "grass.800",
      };
    case "inactive":
      return {
        bgColor: "base.lightBackground",
        hoverBgColor: "gray.100",
        textColor: "text.tertiary",
      };
    default:
      throw new UnreachableCaseError(variant);
  }
};
