import { useState } from "react";

import { subDays } from "date-fns";

import { useUser } from "src/contexts/user-context";
import { useHomeAudienceSummaryQuery } from "src/graphql/types";
import { AudienceSummaryFilter } from "./audience-summary";
import { Stat, StatBorderLine, StatsRow } from "src/components/filters";

export const AudienceStats = ({
  filter,
}: {
  filter: AudienceSummaryFilter;
}) => {
  const { user } = useUser();
  const [since] = useState(subDays(new Date(), 30).toISOString());

  const personalQuery = useHomeAudienceSummaryQuery({
    filter: {
      created_by_user: { id: { _eq: user?.id?.toString() } },
    },
    since,
  });

  const allQuery = useHomeAudienceSummaryQuery({
    filter: {},
    since,
  });

  const isLoading =
    filter === "personal" ? personalQuery.isLoading : allQuery.isLoading;

  const data = filter === "personal" ? personalQuery.data : allQuery.data;

  return (
    <StatsRow isLoading={isLoading}>
      <Stat
        href={
          filter === "personal"
            ? `/audiences?created=${user?.id}`
            : "/audiences"
        }
        label={filter === "personal" ? "My audiences" : "All audiences"}
        value={data?.allCount.aggregate?.count}
        tip={
          filter === "personal"
            ? "All audiences that have been created by you."
            : "All audiences that have been created in this workspace."
        }
      />
      <StatBorderLine />
      <Stat
        href={
          filter === "personal"
            ? `/audiences?sort=created_at&dir=desc&created=${user?.id}`
            : "/audiences?sort=created_at&dir=desc"
        }
        label="Recently created"
        value={data?.recentlyCreatedCount.aggregate?.count}
        color="electric.base"
        tip="Audiences that were created in the past month."
      />
      <StatBorderLine />
      <Stat
        href={
          filter === "personal"
            ? `/audiences?recently_synced=30d&created=${user?.id}`
            : "/audiences?recently_synced=30d"
        }
        label="Recently synced"
        value={data?.recentlySyncedCount.aggregate?.count}
        color="success.base"
        tip="Audiences synced to one or more destinations in the past month."
      />
      <StatBorderLine />
      <Stat
        href={
          filter === "personal"
            ? `/audiences?unhealthy_syncs=true&created=${user?.id}`
            : "/audiences?unhealthy_syncs=true"
        }
        label="With unhealthy syncs"
        value={data?.unhealthyCount.aggregate?.count}
        color="danger.600"
        tip="Audiences connected to one or more syncs with critical alerts or fatal errors."
      />
      <StatBorderLine />
      <Stat
        href={
          filter === "personal"
            ? `/audiences?inactive_syncs=true&created=${user?.id}`
            : "/audiences?inactive_syncs=true"
        }
        label="Inactive"
        value={data?.inactiveCount.aggregate?.count}
        color="text.tertiary"
        tip="Audiences that are not connected to any syncs (or are connected only to draft or disabled syncs)."
      />
    </StatsRow>
  );
};
