import { useEffect } from "react";

import { datadogRum } from "@datadog/browser-rum";
import { reactPlugin } from "@datadog/browser-rum-react";

import { useUser } from "src/contexts/user-context";

const excludedPatterns = [
  /launchdarkly\.com/,
  /sentry\.io/,
  /hightouch-events\.com/,
  /logrocket\.com/,
  /getkoala\.com/,
  /usepylon\.com/,
  /status\.hightouch\.io/,
];

const includedResourceTypes = ["fetch", "xhr"];

if (import.meta.env.PROD) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: "datadoghq.com",
    service: "app",
    env: "production",
    version: import.meta.env.COMMIT,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [/https:\/\/.*\.hightouch\.com/],
    excludedActivityUrls: excludedPatterns,
    trackUserInteractions: false,
    // @ts-expect-error : types between packages are not aligned
    plugins: [reactPlugin({ router: true })],
    beforeSend: (event) => {
      if (event.type === "resource") {
        const url = event.resource.url.toLowerCase();
        const isExcluded = excludedPatterns.some((pattern) =>
          pattern.test(url),
        );
        return (
          includedResourceTypes.includes(event.resource.type) && !isExcluded
        );
      }
      return true;
    },
  });
}

export const useDatadog = () => {
  const { user } = useUser();

  useEffect(() => {
    if (user && import.meta.env.PROD) {
      datadogRum.setUser({
        id: user.id.toString(),
        workspace_id: user.current_workspace_id,
        organization_id: user.organization?.id,
      });
    }
  }, [user]);
};
