import { MonitorStatus } from "@hightouch/lib/resource-monitoring/types";
import {
  Badge,
  BadgeProps,
  Box,
  Column,
  Row,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { capitalize } from "lodash";
import pluralize from "pluralize";
import { RenderedMonitorConditionStatus } from "src/graphql";
import {
  syncHealthOrUnknown,
  SyncHealthStatus,
  UnknownSyncHealth,
} from "src/utils/syncs";
import { SyncAlertCard } from "./sync-alert-card";

const badgeVariants = {
  [SyncHealthStatus.Unhealthy]: "error",
  [SyncHealthStatus.Healthy]: "success",
  [SyncHealthStatus.Warning]: "warning",
  [SyncHealthStatus.Disabled]: "subtle",
  [SyncHealthStatus.Pending]: "subtle",
  [UnknownSyncHealth]: "subtle",
} as const;

type Sync = {
  health: string | null;
  last_run_at: string | null;
  schedule_paused: boolean | null;
};

export type SyncAlertBadgeProps = {
  alerts: Array<RenderedMonitorConditionStatus> | undefined;
  isLoading: boolean;
  sync: Sync;
  disableTooltip?: boolean;
  hideWhenHealthy?: boolean;
  size?: BadgeProps["size"];
};

export const SyncAlertsBadge = ({
  alerts,
  sync,
  isLoading,
  size = "md",
  disableTooltip = false,
  hideWhenHealthy = false,
}: SyncAlertBadgeProps) => {
  const healthStatus = getSyncHealthStatus(sync, alerts ?? []);
  const everRun = Boolean(sync.last_run_at);

  if (healthStatus === SyncHealthStatus.Disabled)
    return <Badge size={size}>Disabled</Badge>;

  if (!everRun) {
    return <Badge size={size}>Never run</Badge>;
  }

  if (healthStatus === SyncHealthStatus.Healthy) {
    if (hideWhenHealthy) return null;
    return (
      <Badge variant="success" size={size}>
        No alerts
      </Badge>
    );
  }

  if (isLoading)
    return (
      <Badge variant={badgeVariants[healthStatus]} size={size}>
        <Box w={10} />
      </Badge>
    );

  if (!alerts || alerts.length === 0) {
    return (
      <Badge
        variant={
          healthStatus === SyncHealthStatus.Warning ? "warning" : "error"
        }
        size={size}
      >
        {capitalize(healthStatus)}
      </Badge>
    );
  }

  const maxAlerts = 3;

  return (
    <Tooltip
      placement="right"
      isDisabled={disableTooltip}
      message={
        <>
          <Row w="full" color="gray.400" textAlign="left">
            {pluralize("active alert", alerts.length, true)}
          </Row>
          <Column gap={2}>
            {alerts
              ?.slice(0, maxAlerts)
              .map((a) => <SyncAlertCard alert={a} key={a.id} />)}
            {alerts.length > maxAlerts && (
              <Text size="sm" fontWeight="medium">
                +{alerts.length - maxAlerts} more
              </Text>
            )}
          </Column>
        </>
      }
    >
      <Badge
        variant={healthStatus === MonitorStatus.Unhealthy ? "error" : "warning"}
        size={size}
      >
        {pluralize("alert", alerts.length, true)}
      </Badge>
    </Tooltip>
  );
};

export const getSyncHealthStatus = (
  sync: Sync,
  alerts: Array<RenderedMonitorConditionStatus>,
): SyncHealthStatus => {
  const health = syncHealthOrUnknown(sync.health);
  const everRun = Boolean(sync.last_run_at);
  const syncEnabled = !sync.schedule_paused;

  if (!syncEnabled) return SyncHealthStatus.Disabled;

  if (!everRun) {
    return SyncHealthStatus.Healthy;
  }

  const isUnhealthy =
    health === SyncHealthStatus.Unhealthy ||
    health === SyncHealthStatus.Warning;

  if (!isUnhealthy) return SyncHealthStatus.Healthy;

  if (!alerts || (isUnhealthy && alerts.length === 0)) {
    return health;
  }

  const highestAlertLevel = alerts.some(
    (alert) => alert.status === MonitorStatus.Unhealthy,
  )
    ? SyncHealthStatus.Unhealthy
    : SyncHealthStatus.Warning;

  return highestAlertLevel;
};
