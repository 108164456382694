import { useMemo } from "react";
import {
  useAudienceExploreDependenciesQuery,
  useParentModelForQueryBuilderQuery,
} from "src/graphql";
import {
  DestinationRules,
  FullParentModel,
} from "src/components/audiences/types";

export const useFetchQueryBuilderParentModel = ({
  parentModelId,
}: {
  parentModelId: string | undefined;
}): {
  parentModel: (FullParentModel & DestinationRules) | null;
  isLoading: boolean;
} => {
  const { data: parentModelFields, isLoading: isParentModelFieldsLoading } =
    useParentModelForQueryBuilderQuery(
      { parentModelId: parentModelId?.toString() ?? "" },
      {
        enabled: Boolean(parentModelId),
        select: (data) => data.segments_by_pk,
      },
    );

  const {
    data: parentModelDependencies,
    isLoading: isParentModelDependenciesLoading,
  } = useAudienceExploreDependenciesQuery(
    { parentModelId: parentModelId?.toString() ?? "" },
    { enabled: Boolean(parentModelId), select: (data) => data.segments_by_pk },
  );

  const parentModel = useMemo(() => {
    if (!parentModelFields || !parentModelDependencies) {
      return null;
    }

    return {
      ...parentModelFields,
      ...parentModelDependencies,
    };
  }, [parentModelFields, parentModelDependencies]);

  return {
    parentModel,
    isLoading: isParentModelFieldsLoading || isParentModelDependenciesLoading,
  };
};
