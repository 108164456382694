import { addDays, format, isSameDay } from "date-fns";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import {
  MetricResultMaybeFromCache,
  useMetricSeries,
} from "src/pages/analytics/hooks/use-metric-series";
import { SECONDS_IN_ONE_DAY } from "src/pages/analytics/state/constants";
import { AnalyticsFrequency } from "src/types/visual";
import {
  getDateRangeForMetricSeries,
  getMetricSeriesArgsForInteractions,
  MESSAGE_GROUP_BY_COLUMN,
} from "src/pages/decision-engines/flows/utils";

export type SendsSummaryData = {
  totalSends: number | null;
  scheduledToday: number | null;
  scheduledTomorrow: number | null;
};

const sendsSummaryGroupByColumns = [MESSAGE_GROUP_BY_COLUMN];

/**
 * Custom hook that wraps useMetricSeries in a couple of queries to get the data
 * for the sends summary in the flow or message overview page.
 *
 * We are caching the data for the sends summary with breakdowns by messages
 * to limit # of queries. For the flow overview page, we will combine all the
 * results from the message breakdowns to get the total across all messages.
 * If a message ID is provided, we will only return the data for that message.
 */
export const useSendsSummary = ({
  flowId,
  messageId,
  parentModelId,
  flowStartDate,
  lastFlowRunStartedAt,
}: {
  flowId: string;
  messageId?: string;
  parentModelId?: string | number | null;
  flowStartDate: string;
  lastFlowRunStartedAt: string;
}): { summary: SendsSummaryData; isLoading: boolean } => {
  const totalSendsDateRange = getDateRangeForMetricSeries(
    lastFlowRunStartedAt,
    {
      type: "custom",
      startDate: flowStartDate,
    },
  );

  const nextSendsCustomDateRange = useMemo(() => {
    const nextSendsEndDate = new Date(
      new Date(lastFlowRunStartedAt).getTime() + 2 * SECONDS_IN_ONE_DAY * 1000,
    );

    return getDateRangeForMetricSeries(nextSendsEndDate.toISOString(), {
      type: "custom",
      startDate: lastFlowRunStartedAt,
    });
  }, [lastFlowRunStartedAt]);

  const {
    data: totalSendsData,
    isPolling: totalSendsIsPolling,
    pollingError: totalSendsPollingError,
    errors: totalSendsErrors,
  } = useMetricSeries(
    getMetricSeriesArgsForInteractions({
      enabled: true,
      flowId,
      parentModelId,
      groupByColumns: sendsSummaryGroupByColumns,
      customDateRange: totalSendsDateRange,
      frequency: AnalyticsFrequency.All,
      conditions: [],
      flowMessages: [],
    }),
  );

  const {
    data: nextSendsData,
    isPolling: nextSendsIsPolling,
    pollingError: nextSendsPollingError,
    errors: nextSendsErrors,
  } = useMetricSeries(
    getMetricSeriesArgsForInteractions({
      enabled: true,
      flowId,
      parentModelId,
      groupByColumns: sendsSummaryGroupByColumns,
      customDateRange: nextSendsCustomDateRange,
      frequency: AnalyticsFrequency.Daily,
      conditions: [],
      flowMessages: [],
    }),
  );

  const totalSends = useMemo(
    () => calculateTotalSends(totalSendsData, messageId),
    [totalSendsData, messageId],
  );

  const scheduledSends = useMemo(
    () => calculateScheduledSends(nextSendsData, messageId),
    [nextSendsData, messageId],
  );

  // If any error, just return null
  if (
    totalSendsPollingError ||
    !isEmpty(totalSendsErrors) ||
    nextSendsPollingError ||
    !isEmpty(nextSendsErrors)
  ) {
    return {
      summary: {
        totalSends: null,
        scheduledToday: null,
        scheduledTomorrow: null,
      },
      isLoading: false,
    };
  }

  return {
    summary: {
      totalSends,
      scheduledToday: scheduledSends.today,
      scheduledTomorrow: scheduledSends.tomorrow,
    },
    isLoading: totalSendsIsPolling || nextSendsIsPolling,
  };
};

export const calculateTotalSends = (
  data: MetricResultMaybeFromCache[],
  messageId: string | undefined,
): number | null => {
  if (!data || data.length === 0) {
    return null;
  }

  let totalSends = 0;
  data.forEach((series) => {
    if ("result" in series && "data" in series.result) {
      // Each series data represents a message group
      const seriesData = series.result.data;
      for (const { data, groupBy } of seriesData) {
        // If message ID is provided, skip if it doesn't match
        if (messageId && groupBy?.find((gb) => gb.value !== messageId)) {
          continue;
        }

        totalSends += data.reduce((acc, curr) => acc + Number(curr.value), 0);
      }
    }
  });

  return totalSends;
};

export const calculateScheduledSends = (
  data: MetricResultMaybeFromCache[],
  messageId: string | undefined,
): { today: number | null; tomorrow: number | null } => {
  // Default to `null` bc we want the 0 to be explicitly set for these values
  const summary: { today: number | null; tomorrow: number | null } = {
    today: null,
    tomorrow: null,
  };

  if (!data || data.length === 0) {
    return summary;
  }

  const today = new Date();
  const tomorrow = addDays(today, 1);

  const todayString = format(today, "yyyy-MM-dd");
  const tomorrowString = format(tomorrow, "yyyy-MM-dd");

  data.forEach((series) => {
    if ("result" in series && "data" in series.result) {
      // Each series data represents a message group
      const seriesData = series.result.data;
      for (const { data, groupBy } of seriesData) {
        // If message ID is provided, skip if it doesn't match
        if (messageId && groupBy?.find((gb) => gb.value !== messageId)) {
          continue;
        }

        for (const { timestamp, value } of data) {
          // We want to ignore the timezone from the timestamp and compare the date only
          const timestampString = timestamp.split("T")[0] ?? timestamp;

          if (isSameDay(timestampString, todayString)) {
            summary.today = (summary.today ?? 0) + Number(value);
          } else if (isSameDay(timestampString, tomorrowString)) {
            summary.tomorrow = (summary.tomorrow ?? 0) + Number(value);
          }
        }
      }
    }
  });

  return summary;
};
