import { Box, Badge, Text } from "@hightouchio/ui";
import { format as formatNumber } from "numerable";
import { ColumnType } from "src/types/visual";
import { ClickToCopy } from "src/components/click-to-copy";
import { NumericFontStyles } from "../constants";
import { TextWithTooltip } from "src/components/text-with-tooltip";

export const DataRow: React.FC<{
  value: any;
  type: ColumnType;
  copyable?: boolean;
}> = ({ value, type, copyable = true }) => {
  if (value === undefined || value === null) {
    return (
      <Badge>
        <Box px={1}>NULL</Box>
      </Badge>
    );
  }

  if (type === ColumnType.Boolean) {
    return (
      <Box as={Text} sx={NumericFontStyles}>
        {String(value).toUpperCase()}
      </Box>
    );
  }
  const TextComponent = copyable ? ClickToCopy : TextWithTooltip;

  if (type === ColumnType.Number) {
    return (
      <TextComponent sx={NumericFontStyles} value={value} message={value}>
        {formatNumber(value)}
      </TextComponent>
    );
  }

  if (type === ColumnType.String) {
    if (value === "") {
      return <Badge>EMPTY</Badge>;
    }
    return (
      <TextComponent value={value} message={value}>
        {value}
      </TextComponent>
    );
  }

  return (
    <TextComponent value={value} message={value}>
      {String(value)}
    </TextComponent>
  );
};
