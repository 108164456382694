import { usePermissionsV2Query } from "src/graphql";
import {
  PermissionProps as V1PermissionProps,
  ResourceToPermission as V1ResourceToPermission,
} from "./types";
import { V2PermissionProps, V2ResourceToPermission } from "./types-v2";

export type ResourcePermissionInput<
  V2Resource extends V2ResourceToPermission,
  V1Resource extends V1ResourceToPermission,
> =
  | {
      v2: V2PermissionProps<V2Resource>;
      v1: V1PermissionProps<V1Resource>;
    }
  | {
      v2: V2PermissionProps<V2Resource>;
    }
  | {
      v1: V1PermissionProps<V1Resource>;
    };

/*
 * This hook is used to check if the user has permission to perform an action
 * It supports taking in both V1 and V2 permissions, but it currently only calls V1 permissions
 * - If V1 is provided, it will call the V1 permission
 * - If V2 is provided, it will convert the V2 permission to a V1 permission and call that
 */
export function useResourcePermission<
  V2Resource extends V2ResourceToPermission,
  V1Resource extends V1ResourceToPermission,
>(
  permission: ResourcePermissionInput<V2Resource, V1Resource> | undefined,
  options?: { enabled?: boolean; suspense: boolean },
): { isPermitted: boolean; isLoading: boolean } {
  // Currently, this just continues calling the V1 permissions, but with the V2PermissionProps
  // After this no-op change is released, we will move the convertV2Permission logic to the backend
  // and switch to useV2ResourcePermission (which will check on user_ug in the backend)

  const v2 =
    permission && "v2" in permission
      ? {
          ...permission.v2,
          // GraphQL does not support union types like string or number, so we explicitly cast it to string
          id:
            "id" in permission.v2 && permission.v2.id
              ? permission.v2.id.toString()
              : undefined,
        }
      : null;

  const v1 =
    permission && "v1" in permission
      ? { ...permission.v1, id: permission.v1.id?.toString() }
      : null;

  const { data: isPermitted, isLoading } = usePermissionsV2Query(
    { v2, v1 },
    {
      enabled: permission && options?.enabled,
      suspense: options?.suspense,
      select: (data) => data.isResourceAuthorized.isAuthorized ?? false,
      meta: { isFastEndpoint: true },
    },
  );

  return { isPermitted: isPermitted ?? false, isLoading };
}
