import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Column,
  Combobox,
  DeleteIcon,
  Dialog,
  FormField,
  IconButton,
  Paragraph,
  Radio,
  RadioGroup,
  Text,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { orderBy } from "lodash";
import pluralize from "pluralize";
import React, { FC } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { Form, useHightouchForm } from "src/components/form";
import {
  DecisionEngineDestinationBaseResource,
  useCreateDecisionEngineMessageMutation,
  useDecisionEngineDestinationResourcesQuery,
} from "src/graphql";
import { OutletContext } from "src/pages/decision-engines";
import {
  CampaignSetupType,
  getChannelDefinition,
} from "src/pages/decision-engines/definitions";
import { ChannelOptionsMap } from "src/pages/decision-engines/utils";
import { useNavigate, useOutletContext, useParams } from "src/router";
import { isPresent } from "ts-extras";

const IdentifierInput: FC<{
  name: string;
  onUpdate: (value: string) => void;
}> = ({ name, onUpdate }) => {
  return (
    <Controller
      name={name}
      defaultValue=""
      render={({ field }) => (
        <TextInput
          width="100%"
          {...field}
          onBlur={(e) => {
            onUpdate(e.target.value);
          }}
          placeholder="Identifier..."
        />
      )}
    />
  );
};

const ResourceInput: React.FC<{
  name: string;
  onUpdate: (value: string | undefined) => void;
  options: DecisionEngineDestinationBaseResource[] | undefined;
  isLoading: boolean;
}> = ({ name, onUpdate, options, isLoading }) => {
  return (
    <Controller
      name={name}
      defaultValue=""
      render={({ field }) => (
        <Combobox
          width="100%"
          value={field.value?.toString()}
          onChange={(value) => {
            field.onChange(value ?? "");
            onUpdate(value);
          }}
          placeholder="Select a template..."
          isLoading={isLoading}
          options={(options ?? []).filter(isPresent)}
          optionValue={(o) => String(o?.id)}
          optionLabel={(o) => String(o?.name)}
          optionDescription={(o) => String(o?.id)}
        />
      )}
    />
  );
};

const SetupMapping: Record<
  CampaignSetupType,
  {
    label: (resourceName: string, destinationName: string) => string;
    description: (resourceName: string, destinationName: string) => string;
    input:
      | FC<{
          name: string;
          onUpdate: (value: string | undefined) => void;
          options: DecisionEngineDestinationBaseResource[] | undefined;
          isLoading: boolean;
        }>
      | FC<{
          name: string;
          onUpdate: (value: string) => void;
        }>;
  }
> = {
  template: {
    label: (_resourceName, destinationName) => `${destinationName} template`,
    description: (_resourceName, destinationName) =>
      `Choose a template that you’ve created in ${destinationName}.`,
    input: ResourceInput,
  },
  resource: {
    label: (resourceName, destinationName) =>
      `${destinationName} ${resourceName}`,
    description: (resourceName, destinationName) =>
      `Choose a ${resourceName} that you’ve created in ${destinationName}.`,
    input: ResourceInput,
  },
  freeform: {
    label: () => "Identifier",
    description: () => `A unique identifier used to reference this message.`,
    input: IdentifierInput,
  },
  "event-triggered": {
    label: () => "Event identifier",
    description: () =>
      `A unique identifier used to reference the event that triggerss this message.`,
    input: IdentifierInput,
  },
};

const isValidMessage = (message: {
  name: string;
  config: { baseMessageId: string };
}) => {
  return (
    message.name.trim() !== "" && message.config.baseMessageId.trim() !== ""
  );
};

export const AddMessage = () => {
  const { flowId } = useParams<{ flowId: string }>();
  const { engine } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const navigate = useNavigate();

  // Filter the enabled channels that use the "template" campaign setup.
  const enabledChannels = orderBy(
    engine.channels.filter((channel) => channel.enabled),
    ["type"],
    ["asc"],
  );
  const createMessage = useCreateDecisionEngineMessageMutation();

  const form = useHightouchForm({
    defaultValues: {
      decision_engine_channel_id: enabledChannels?.[0]?.id,
      // Each row now has its own template (inside config.baseMessageId) and action name.
      messages: [
        {
          name: "",
          config: { baseMessageId: "" },
        },
      ],
    },
    onSubmit: async (data) => {
      const selectedChannel =
        enabledChannels.find(
          (ch) => ch.id === data.decision_engine_channel_id,
        ) || enabledChannels[0];

      // Filter out rows that do not have a template value.
      const messagesToSubmit = data.messages.filter(isValidMessage);

      const resp = await Promise.allSettled(
        messagesToSubmit.map(async (msg) => {
          const response = await createMessage.mutateAsync({
            flowId: flowId!,
            message: {
              decision_engine_channel_id: selectedChannel?.id,
              name: msg.name,
              config: msg.config,
              variables: [],
              tags: {},
            },
            flowMessageConfig: {},
          });
          if (
            response.createDecisionEngineMessage.__typename ===
            "DecisionEngineError"
          ) {
            throw new Error(response.createDecisionEngineMessage.error);
          }

          return response;
        }),
      );

      // If all requests failed, throw an error
      if (resp.every((r) => r.status === "rejected")) {
        throw new Error("Failed to create actions");
      }

      if (resp.every((r) => r.status === "fulfilled")) {
        toast({
          id: "create-actions",
          title: "Actions created successfully",
          variant: "success",
        });
      } else {
        toast({
          id: "create-actions",
          title: "Some actions were not created successfully",
          variant: "warning",
          message: `${resp.filter((r) => r.status === "fulfilled").length} of ${resp.length} actions created successfully`,
        });
      }

      if (
        resp.length === 1 &&
        resp[0]!.status === "fulfilled" &&
        resp[0]!.value.createDecisionEngineMessage.__typename ===
          "CreateDecisionEngineMessageSuccess"
      ) {
        const messageId = resp[0]!.value.createDecisionEngineMessage.id;
        navigate(`/ai/flows/${flowId}/configuration/messages/${messageId}`);
      } else {
        navigate(`/ai/flows/${flowId}/configuration`);
      }
    },
    success: false,
  });

  // Watch the selected channel.
  const { watch, control, setValue, resetField, getValues } = form;
  const channelId = watch("decision_engine_channel_id");
  const selectedChannel =
    enabledChannels.find((ch) => ch.id === channelId) || enabledChannels[0];

  // Get dynamic template options for the selected channel.
  const channelDefinition = getChannelDefinition(
    selectedChannel!.destination.type,
  );
  const campaignSetup = channelDefinition.campaignSetup(
    selectedChannel!.config,
  );
  const templateType = channelDefinition.getTemplateResourceType(
    selectedChannel!,
  );

  const {
    data: resourceData,
    error: resourceError,
    isLoading: isResourceLoading,
  } = useDecisionEngineDestinationResourcesQuery(
    {
      destinationId: selectedChannel!.destination.id,
      type: templateType,
    },
    {
      enabled: Boolean(templateType),
      select: (data) => data.getDecisionEngineDestinationResources,
    },
  );

  // Setup a dynamic field array for the list of actions.
  const { fields, append, remove } = useFieldArray({
    control,
    name: "messages",
  });

  const messages = watch("messages");
  const validMessages = messages.filter(isValidMessage);

  const onClose = () => {
    navigate(`/ai/flows/${flowId}/configuration`);
  };

  // Common handler for updating fields
  const handleResourceUpdate = (index, value) => {
    // Check if we want to add a new row
    const lastMessage = messages[messages.length - 1];
    if (value && lastMessage) {
      if (lastMessage.config.baseMessageId) {
        append({
          name: "",
          config: { baseMessageId: "" },
        });
      }
    }

    // Check if we want to set the default of the action name
    const actionName = getValues(`messages.${index}.name`);
    if (value && !actionName) {
      const name = resourceData?.find((t) => t.id === value)?.name ?? value;
      setValue(`messages.${index}.name`, name);
    }
  };

  const SetupComponent = SetupMapping[campaignSetup].input;
  const resourceName =
    campaignSetup === "template"
      ? "template"
      : channelDefinition.getResourceName(selectedChannel!.config);
  const setupLabel = SetupMapping[campaignSetup].label(
    resourceName,
    selectedChannel!.destination.definition.name,
  );
  const setupDescription = SetupMapping[campaignSetup].description(
    resourceName,
    selectedChannel!.destination.definition.name,
  );

  return (
    <Dialog
      maxHeight="80vh"
      width="4xl"
      isOpen={true}
      onClose={onClose}
      variant="form"
      title="Create actions"
      actions={
        <ButtonGroup>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={form.submit}
            isLoading={createMessage.isLoading}
            isDisabled={!selectedChannel}
          >
            Create {pluralize("action", validMessages.length)}
          </Button>
        </ButtonGroup>
      }
    >
      <Form form={form}>
        <Column gap={6}>
          {enabledChannels.length > 1 && (
            <Controller
              name="decision_engine_channel_id"
              control={control}
              render={({ field }) => (
                <FormField label="Channel">
                  <RadioGroup
                    {...field}
                    onChange={(value) => {
                      field.onChange(value);
                      resetField("messages");
                    }}
                    orientation="vertical"
                  >
                    {enabledChannels.map((channel) => (
                      <Radio
                        key={channel.id}
                        value={channel.id}
                        label={ChannelOptionsMap[channel.type]?.label ?? ""}
                        description={
                          getChannelDefinition(
                            channel.destination.type,
                          ).getChannelDescription(channel.config) ||
                          channel.destination.definition.name
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormField>
              )}
            />
          )}

          {resourceError && (
            <Alert
              variant="inline"
              type="error"
              title="Error"
              message={resourceError.message}
            />
          )}
          {selectedChannel ? (
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr min-content"
              columnGap={4}
              rowGap={2}
            >
              <Column>
                <Text>{setupLabel}</Text>
                <Paragraph color="text.secondary">{setupDescription}</Paragraph>
              </Column>
              <Column>
                <Text>Action name</Text>
                <Paragraph color="text.secondary">
                  Enter a name to describe the action in Hightouch
                </Paragraph>
              </Column>
              <Box></Box>

              {fields.map((field, index) => (
                <React.Fragment key={field.id}>
                  <SetupComponent
                    name={`messages.${index}.config.baseMessageId`}
                    onUpdate={(value) => handleResourceUpdate(index, value)}
                    options={resourceData}
                    isLoading={isResourceLoading}
                  />

                  <Controller
                    name={`messages.${index}.name`}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextInput
                        width="100%"
                        {...field}
                        placeholder="Enter action name..."
                      />
                    )}
                  />
                  {index !== fields.length - 1 && (
                    <IconButton
                      aria-label="Remove action."
                      variant="danger"
                      icon={DeleteIcon}
                      onClick={() => remove(index)}
                    />
                  )}
                </React.Fragment>
              ))}
            </Box>
          ) : (
            <Alert variant="inline" type="info" title="No channels available" />
          )}
        </Column>
      </Form>
    </Dialog>
  );
};
