import {
  CloseIcon,
  Column,
  EditableHeading,
  IconButton,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  PauseIcon,
  PlayIcon,
  Row,
  Text,
  useToast,
  ModelIcon,
  Spinner,
  DrawerBody,
} from "@hightouchio/ui";
import { useMemo } from "react";
import { captureException } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { OutletContext } from "src/pages/decision-engines";
import {
  useNavigate,
  useOutletContext,
  useParams,
  Outlet,
  Navigate,
} from "src/router";
import { Drawer } from "src/components/drawer";
import {
  DecisionEngineMessageQuery,
  useDecisionEngineMessageQuery,
  useDisableDecisionEngineFlowMessageMutation,
  useEnableDecisionEngineFlowMessageMutation,
  useUpdateDecisionEngineMessageMutation,
} from "src/graphql";
import { Channel } from "src/pages/decision-engines";
import { FlowContext } from "src/pages/decision-engines/flows/flow";
import { RouteTabs } from "src/components/route-tabs";

export type Message = Omit<
  NonNullable<
    DecisionEngineMessageQuery["decision_engine_flow_messages_by_pk"]
  >,
  "channel"
> & {
  channel: Channel;
};

export type FlowMessage = FlowContext["flow"]["messages"][0];

export type FlowMessageContext = FlowContext & {
  flowMessage: FlowMessage;
};

export const FlowMessage = () => {
  const { messageId: id } = useParams<{
    messageId: string;
  }>();
  const flowContext = useOutletContext<FlowContext & OutletContext>();
  const { decisionEngineDemo } = useFlags();

  const { data: flowMessage, isLoading } = useDecisionEngineMessageQuery(
    {
      flowId: flowContext.flow.id,
      messageId: id!,
    },
    {
      select: (data) => data.decision_engine_flow_messages_by_pk,
    },
  );

  const context = useMemo(
    () => ({
      ...flowContext,
      flowMessage,
    }),
    [flowContext, flowMessage],
  );

  const navigate = useNavigate();

  const onClose = () => {
    navigate(`/ai/flows/${flowContext.flow.id}/configuration`);
  };

  if (isLoading) {
    return (
      <Drawer isOpen onClose={onClose} size="2xl">
        <Spinner size="lg" m="auto" />
      </Drawer>
    );
  }

  if (!flowMessage) {
    return <Navigate to=".." />;
  }

  const aiTabName =
    flowContext.engine.flows?.[0]?.audience?.connection?.type === "bigquery"
      ? "Vertex"
      : "Cortex";

  return (
    <Drawer isOpen onClose={onClose} size="2xl">
      <Column p={6}>
        <Row gap={2} align="center" justify="space-between">
          <Heading message={flowMessage.message} />

          <Row align="center" gap={4}>
            <ActionMenu
              flowMessage={flowMessage}
              flowId={flowContext.flow.id}
            />
            <IconButton aria-label="Close" icon={CloseIcon} onClick={onClose} />
          </Row>
        </Row>
      </Column>

      <RouteTabs
        px={6}
        depth={8}
        tabs={[
          {
            path: "overview",
            title: "Overview",
          },
          {
            path: "configuration",
            title: "Configuration",
          },
          {
            path: "guardrails",
            title: "Guardrails",
          },
          {
            path: "integration",
            title: "Integration",
            isHidden: flowMessage.message.channel.type === "raw",
          },
          {
            path: "preview",
            title: "Preview",
            isHidden: flowMessage.message.channel.type === "raw",
          },
          {
            path: "tags",
            title: aiTabName,
            isHidden: !decisionEngineDemo,
          },
        ]}
      />
      <DrawerBody bg="gray.50">
        <Outlet context={context} />
      </DrawerBody>
    </Drawer>
  );
};

const ActionMenu = ({
  flowId,
  flowMessage,
}: {
  flowId: string;
  flowMessage: FlowMessageContext["flowMessage"];
}) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const isInitialized = Boolean(flowMessage.model);
  const enableMutation = useEnableDecisionEngineFlowMessageMutation();
  const disableMutation = useDisableDecisionEngineFlowMessageMutation();

  if (!isInitialized) {
    return null;
  }

  return (
    <Menu>
      <MenuActionsButton />
      <MenuList>
        {flowMessage.model && (
          <MenuItem
            onClick={() => {
              navigate(`/models/${flowMessage.model!.id}`);
            }}
            icon={ModelIcon}
          >
            View underlying model
          </MenuItem>
        )}
        {flowMessage.enabled ? (
          <MenuItem
            onClick={async () => {
              try {
                const response = await disableMutation.mutateAsync({
                  flowId,
                  messageId: flowMessage.message.id,
                });
                if (
                  response.disableDecisionEngineFlowMessage.__typename ===
                  "DecisionEngineSuccess"
                ) {
                  toast({
                    id: "disable-message",
                    title: "Message disabled",
                    variant: "success",
                  });
                } else {
                  toast({
                    id: "disable-message",
                    title: "Error disabling message",
                    variant: "error",
                    message:
                      response.disableDecisionEngineFlowMessage.error ?? "",
                  });
                }
              } catch (e) {
                toast({
                  id: "disable-message",
                  title: "Error disabling message",
                  variant: "error",
                  message: e.message,
                });
              }
            }}
            icon={PauseIcon}
          >
            <Column>
              <Text>Disable</Text>
              <Text color="text.secondary" size="sm">
                Message will not be sent
              </Text>
            </Column>
          </MenuItem>
        ) : (
          <MenuItem
            onClick={async () => {
              try {
                const response = await enableMutation.mutateAsync({
                  flowId,
                  messageId: flowMessage.message.id,
                });
                if (
                  response.enableDecisionEngineFlowMessage.__typename ===
                  "DecisionEngineSuccess"
                ) {
                  toast({
                    id: "enable-message",
                    title: "Message enabled",
                    variant: "success",
                  });
                } else {
                  toast({
                    id: "enable-message",
                    title: "Error enabling message",
                    variant: "error",
                    message: response.enableDecisionEngineFlowMessage.error,
                  });
                }
              } catch (e) {
                toast({
                  id: "enable-message",
                  title: "Error enabling message",
                  variant: "error",
                  message: e.message,
                });
              }
            }}
            icon={PlayIcon}
          >
            <Column>
              <Text>Enable</Text>
              <Text color="text.secondary" size="sm">
                Action will be eligible for sending once agent is running
              </Text>
            </Column>
          </MenuItem>
        )}
        {/*
         TODO: Fix Hightouch UI dialog in drawers
      <DeleteButton
        label="message"
        variant="menu"
        onSuccess={onClose}
        onDelete={async () =>
          deleteMutation.mutateAsync({ id: flowMessage.message.id })
        }
      /> */}
      </MenuList>
    </Menu>
  );
};

const Heading = ({ message }) => {
  const { toast } = useToast();
  const updateMutation = useUpdateDecisionEngineMessageMutation();

  const updateName = async (name: string) => {
    try {
      await updateMutation.mutateAsync({
        id: message?.id ?? "",
        input: {
          name,
        },
      });
      toast({
        id: "name-updated",
        title: "Message name saved",
        variant: "success",
      });
    } catch (e) {
      captureException(e);
      toast({
        id: "name-updated",
        title: "Failed to save message name",
        variant: "error",
      });
    }
  };

  return <EditableHeading value={message?.name ?? ""} onChange={updateName} />;
};
