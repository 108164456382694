import { EventSyncsQuery } from "src/graphql";
import { ElementOf } from "ts-essentials";
import {
  eventWarehouseDestinationDefinitions,
  EventWarehouseDestinationType,
} from "./types";
import { enumOrFallback } from "src/types/utils";

export const destinationMetadataFromWarehouseSync = (
  sync: ElementOf<EventSyncsQuery["event_warehouse_syncs"]>,
): { name: string; icon: string; type?: EventWarehouseDestinationType } => {
  return {
    name:
      sync.event_warehouse_destination.name ||
      eventWarehouseDestinationDefinitions[
        sync.event_warehouse_destination.type
      ].name,
    icon: eventWarehouseDestinationDefinitions[
      sync.event_warehouse_destination.type
    ].icon,
    type: enumOrFallback(
      EventWarehouseDestinationType,
      undefined,
      false,
    )(sync.event_warehouse_destination.type),
  };
};

export const destinationMetadataFromForwardingSync = (
  sync: ElementOf<EventSyncsQuery["event_forwarding_syncs"]>,
): { name: string; icon: string; type?: string } => {
  return {
    name: sync.destination.name || sync.destination.definition.name,
    icon: sync.destination.definition.icon,
    type: sync.destination.type,
  };
};
