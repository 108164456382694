import {
  Column,
  EmptyState,
  FormField,
  Heading,
  Badge,
  Row,
  Select,
  Text,
} from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { Card } from "src/components/card";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import {
  useGenerateBreakdownsMutation,
  useUpdateDecisionEngineFlowMutation,
} from "src/graphql";
import { useOutletContext } from "src/router";
import { DecisionEngine, OutletContext } from "..";
import { Editor } from "src/components/editor/editor";

export const Insights = () => {
  return (
    <Column gap={4}>
      <RunBreakdowns />
      <Settings />
    </Column>
  );
};

const RunBreakdowns = () => {
  const { engine } = useOutletContext<OutletContext>();
  const generateBreakdownsMutation = useGenerateBreakdownsMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await generateBreakdownsMutation.mutateAsync({
        flowId: data.flowId,
      });
    },
  });

  return (
    <Form form={form}>
      <Card
        gap={4}
        heading="Generate Breakdowns"
        footer={<SaveButton>Generate Breakdowns</SaveButton>}
      >
        <Column gap={4}>
          <Controller
            name="flowId"
            render={({ field }) => (
              <FormField label="Flow">
                <Select
                  {...field}
                  options={engine?.flows.map((f) => ({
                    label: f.name,
                    value: f.id,
                  }))}
                />
              </FormField>
            )}
          />
        </Column>
      </Card>
    </Form>
  );
};

const Settings = () => {
  const { engine } = useOutletContext<OutletContext>();

  if (!engine.flows.length) {
    return <EmptyState title="No flows" message="" />;
  }

  return (
    <Column gap={6}>
      {engine.flows.sort().map((flow) => (
        <FlowSettings key={flow.id} flow={flow} />
      ))}
    </Column>
  );
};

const DEFAULT_BREAKDOWN_CONFIG = {
  number_top_charts_to_cache: 10,
  min_conversions: 20,
  credible_interval: 0.89,
  bucketed_group_by_num_bins: 4,
  ideal_breakdown_points: 4,
  variance_discount_power: 0.5,
  user_features_to_exclude: [],
  action_features_to_exclude: [],
  max_charts_per_breakdown: 2,
  history_days: -1,
};

const BREAKDOWN_CONFIG_DESCRIPTIONS: Record<
  keyof typeof DEFAULT_BREAKDOWN_CONFIG,
  string
> = {
  number_top_charts_to_cache: "The number of charts to cache",
  min_conversions:
    "The number of conversions needed for the breakdown to be included",
  credible_interval: "The credible interval level to use for the breakdown",
  bucketed_group_by_num_bins: "The number of bins to use for the breakdown",
  ideal_breakdown_points:
    "The ideal number of breakdown points. If fewer than this number of points, the chart will be ranked lower",
  variance_discount_power:
    "The variance discount power to use for the breakdown. Higher values will discount more",
  user_features_to_exclude: "The user features to exclude generating",
  action_features_to_exclude: "The action features to exclude from generating",
  max_charts_per_breakdown:
    "The maximum number of charts to generate per breakdown type/value combo to introduce variety into the cached top charts",
  history_days:
    "The number of days to lookback for history. -1 to look back forever.",
};

const FlowSettings = ({ flow }: { flow: DecisionEngine["flows"][0] }) => {
  const updateFlow = useUpdateDecisionEngineFlowMutation();

  const form = useHightouchForm({
    values: {
      breakdownConfig: JSON.stringify(DEFAULT_BREAKDOWN_CONFIG, null, 2),
      outcomeId: flow.config?.breakdown_config?.outcome_id ?? undefined,
    },
    onSubmit: async (data) => {
      await updateFlow.mutateAsync({
        id: flow.id,
        input: {
          config: {
            ...flow.config,
            breakdown_config: {
              ...JSON.parse(data.breakdownConfig),
              outcome_id: data.outcomeId,
            },
          },
        },
      });
    },
  });

  const allOutcomes = flow.outcomes.map((o) => o.outcome);

  return (
    <Form form={form}>
      <Card gap={4} footer={<SaveButton>Save</SaveButton>}>
        <Column gap={2}>
          <Heading>{flow.name} Settings</Heading>
          <Text size="sm" color="text.secondary">
            {flow.id}
          </Text>
        </Column>
        <Column gap={4}>
          <Controller
            name="breakdownConfig"
            render={({ field }) => (
              <FormField label="Breakdown config">
                <Column overflow="hidden" flex={1} maxHeight="400px">
                  <Editor language="json" bg="base.background" {...field} />
                </Column>
              </FormField>
            )}
          />
          {Object.entries(BREAKDOWN_CONFIG_DESCRIPTIONS).map(
            ([key, description]) => (
              <Row key={key} gap={2}>
                <Badge>{key}</Badge>
                <Text>{description}</Text>
              </Row>
            ),
          )}
          <Controller
            name="outcomeId"
            render={({ field }) => (
              <FormField label="Override Outcome ID">
                <Select
                  {...field}
                  options={allOutcomes.map((o) => ({
                    label: o.name,
                    value: o.id,
                  }))}
                  isClearable
                />
              </FormField>
            )}
          />
        </Column>
      </Card>
    </Form>
  );
};
