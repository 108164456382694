import { Column, Row, Text, SectionHeading } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { useCreateWorkspaceNotificationChannelMutation } from "src/graphql";
import {
  MonitoredResourceType,
  SupportedConditionsForResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import { CreateDestinationWizardFormData } from "./types";
import { MonitorConditionForm } from "src/components/resource-alert-triggers/monitor-condition-forms/monitor-condition-form";
import { Recipients } from "src/components/resource-alert-triggers/recipients";
import { ChannelData } from "src/components/resource-alert-triggers/create-recipient-modal";

export const AlertingConfigurationStep = () => {
  const form = useFormContext<CreateDestinationWizardFormData>();

  const { mutateAsync: createWorkspaceNotificationChannel } =
    useCreateWorkspaceNotificationChannelMutation();

  const createRecipient = async ({ config, channelType }: ChannelData) => {
    const res = await createWorkspaceNotificationChannel({
      object: {
        channel_type: channelType,
        config,
      },
    });

    const channelId = res?.insert_workspace_notification_channels_one?.id;

    if (!channelId) {
      throw new Error("Failed to create channel");
    }

    return channelId;
  };

  return (
    <Column gap={6}>
      <Controller
        control={form.control}
        name="alerts.channels"
        render={({ field }) => (
          <Recipients
            channels={field.value}
            showSuccessToast={false}
            onAdd={(channel) => field.onChange([...field.value, channel])}
            onRemove={(channel) =>
              field.onChange(
                field.value.filter((recipient) => recipient.id !== channel.id),
              )
            }
            onCreate={async (channelData) => {
              const id = await createRecipient(channelData);

              field.onChange([
                ...field.value,
                {
                  id,
                  channel_type: channelData.channelType,
                  config: channelData.config,
                },
              ]);
            }}
          />
        )}
      />
      <Column gap={2}>
        <Row gap={4} align="center" justify="space-between">
          <Column>
            <SectionHeading>Triggers</SectionHeading>
            <Text color="text.secondary">Send alerts when:</Text>
          </Column>
        </Row>
        {SupportedConditionsForResourceTypes[MonitoredResourceType.Sync].map(
          (conditionType) => (
            <Controller
              key={conditionType}
              control={form.control}
              name={`alerts.conditions.${conditionType}`}
              render={({ field }) => (
                <MonitorConditionForm
                  conditionType={conditionType}
                  conditions={field.value ? [field.value] : []}
                  onSubmit={({ condition }) =>
                    Promise.resolve(field.onChange(condition))
                  }
                  showSuccessToast={false}
                />
              )}
            />
          ),
        )}
      </Column>
    </Column>
  );
};
