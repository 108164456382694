import { useMemo, useState } from "react";

import {
  Row,
  Box,
  Column,
  Text,
  GroupedCombobox,
  Switch,
  Tooltip,
  InformationIcon,
  StarIcon,
  AudienceIcon,
  Badge,
  ExternalLinkIcon,
} from "@hightouchio/ui";
import { useFormContext } from "react-hook-form";
import { subDays } from "date-fns";
import pluralize from "pluralize";

import chartPlaceholder from "src/pages/home/assets/placeholder-chart.svg";
import { Card } from "src/components/card";
import { Form } from "src/components/form";
import { useMetricSeries } from "src/pages/analytics/hooks/use-metric-series";
import { useChartState } from "src/pages/analytics/state/use-chart-state";
import { GraphType, TimeOptions } from "src/pages/analytics/types";
import { AnalyticsFrequency } from "src/types/visual";
import { useAudiencesWithSizeQuery, useSavedChartsQuery } from "src/graphql";
import { AnalyticsFormStateProvider } from "src/pages/analytics/state/analytics-context";
import { transformMetricDataForGraph } from "src/components/analytics/cross-audience-graph/utils";
import { isPresent } from "src/types/utils";
import { useAnalyticsContext } from "src/pages/analytics/state/use-analytics-context";
import { Chart, DEFAULT_CHART_HEIGHT } from "src/components/charts/chart";
import { useUser } from "src/contexts/user-context";
import { usePersonalization } from "src/hooks/use-personalization";
import { usePersistedState } from "src/hooks/use-persisted-state";
import { AudienceSizeChart } from "./audience-size-chart";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { GraphTypeIcons } from "src/pages/analytics/common";
import { transformSavedChartGraphType } from "src/pages/analytics/utils";
import { RouterLink } from "src/router";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import { HTImage } from "src/components/image";

export const CIMetrics = () => {
  const { user, workspace } = useUser();
  const personalizedChart = usePersonalization<string | null>({
    key: "home.chart",
    initialValue: null,
    useWorkspace: true,
  });
  const { isPermitted: isWorkspaceAdmin } = useResourcePermission({
    v2: { resource: "workspace", grant: "can_update" },
  });
  const audienceId = usePersistedState<string | null>(
    `${workspace?.id}.home.audienceId`,
    null,
  );

  const isAudienceSizeChart =
    !personalizedChart.value && audienceId.value !== null;

  const { data: savedCharts, isLoading: isSavedChartsLoading } =
    useSavedChartsQuery(
      {
        filter: { chart_type: { _nin: ["table", "funnel"] } },
      },
      {
        select: (data) => data.saved_analytics_charts,
      },
    );

  const [since] = useState(subDays(new Date(), 90).toISOString());

  const { data: audiences, isLoading: isAudiencesLoading } =
    useAudiencesWithSizeQuery(
      {
        since,
      },
      {
        select: (data) => {
          // order so segments most recently created_by this user are first
          return data.segments.sort((a, b) => {
            if (a.created_by === user?.id) return -1;
            if (b.created_by === user?.id) return 1;
            return 0;
          });
        },
      },
    );

  const selectedChart = useMemo(() => {
    return savedCharts?.find((c) => c.id === personalizedChart.value);
  }, [savedCharts, personalizedChart.value]);

  const selectedAudience = useMemo(() => {
    return audiences?.find((a) => a.id === audienceId.value);
  }, [audiences, audienceId.value]);

  const { form } = useChartState({
    useUrlState: false,
  });

  const hasSelection =
    audienceId.value !== null || personalizedChart.value !== null;
  const canSetWorkspaceChart = isWorkspaceAdmin || user?.is_impersonating;

  if (personalizedChart.isWorkspaceValue && !selectedChart) {
    // Clean up if persisted chart id is no longer in the saved charts
    personalizedChart.clear();
  }

  const onSelectionChange = (option: {
    type: "audience" | "chart";
    value: string | undefined;
  }) => {
    if (personalizedChart.isWorkspaceValue && canSetWorkspaceChart) {
      // Clear workspace personalization if we're changing the chart
      personalizedChart.clearWorkspacePersonalization();
    }
    if (option.type === "audience") {
      audienceId.set(option.value ?? null);
      personalizedChart.setLocal(null);
    } else {
      audienceId.clear();
      personalizedChart.setLocal(option.value ?? null);
    }
  };

  const isLoading = isSavedChartsLoading || isAudiencesLoading;

  return (
    <Column gap={4}>
      {hasSelection ? (
        <Form form={form}>
          <Card boxShadow="md" minHeight={DEFAULT_CHART_HEIGHT}>
            <Row
              gap={4}
              align="flex-end"
              justify="space-between"
              w="100%"
              overflow="hidden"
            >
              {(selectedChart || selectedAudience) && (
                <>
                  {selectedChart ? (
                    <TextWithTooltip fontWeight="medium" size="lg">
                      {selectedChart.name}
                    </TextWithTooltip>
                  ) : (
                    <RouterLink to={`/audiences/${selectedAudience?.id}`}>
                      <Row
                        gap={2}
                        align="center"
                        _hover={{ color: "link.default" }}
                      >
                        <TextWithTooltip
                          fontWeight="medium"
                          size="lg"
                          color="inherit"
                        >
                          {`${selectedAudience?.name} size`}
                        </TextWithTooltip>
                        <Box as={ExternalLinkIcon} boxSize={4} />
                      </Row>
                    </RouterLink>
                  )}
                </>
              )}
              <Row gap={4} align="center" ml="auto">
                {canSetWorkspaceChart && !isAudienceSizeChart && (
                  <Row align="center" whiteSpace="nowrap">
                    <Switch
                      aria-label="Pin this chart"
                      size="sm"
                      isDisabled={isAudienceSizeChart}
                      isChecked={personalizedChart.isWorkspaceValue}
                      onChange={(checked) => {
                        if (checked) {
                          personalizedChart.save(personalizedChart.value);
                        } else {
                          personalizedChart.clearWorkspacePersonalization();
                        }
                      }}
                    />
                    <Text ml={2} mr={1}>
                      Pin this chart
                    </Text>
                    <Tooltip message="As an admin, you can pin this chart to appear on the homepage for everyone in the workspace. Recommended only for charts that are widely relevant.">
                      <InformationIcon />
                    </Tooltip>
                  </Row>
                )}
                {!canSetWorkspaceChart &&
                  personalizedChart.isWorkspaceValue && (
                    <Tooltip message="This is a featured chart selected by your workspace admin.">
                      <Badge variant="warning" size="sm">
                        <StarIcon />
                        Featured chart
                      </Badge>
                    </Tooltip>
                  )}
                {(canSetWorkspaceChart ||
                  !personalizedChart.isWorkspaceValue) && (
                  <ChartSelect
                    isLoading={isLoading}
                    savedCharts={savedCharts ?? []}
                    audiences={audiences ?? []}
                    value={personalizedChart.value ?? audienceId.value}
                    onChange={onSelectionChange}
                  />
                )}
              </Row>
            </Row>
            {personalizedChart.value ? (
              <ChartWrapper chartId={personalizedChart.value!} />
            ) : (
              <AudienceSizeChart
                audienceId={audienceId.value!}
                lookbackWindow={TimeOptions.NinetyDays}
              />
            )}
          </Card>
        </Form>
      ) : (
        <Card
          height={DEFAULT_CHART_HEIGHT}
          boxShadow="md"
          pos="relative"
          alignItems="center"
        >
          <Box fontWeight="medium" fontSize="xl" mb={1} pt={6}>
            Choose a chart
          </Box>
          <Text mb={4}>
            Pick from your saved charts or choose any audience to view changes
            in size over time.
          </Text>
          <ChartSelect
            savedCharts={savedCharts ?? []}
            audiences={audiences ?? []}
            isLoading={isLoading}
            onChange={onSelectionChange}
            value={personalizedChart.value ?? audienceId.value}
          />
          <HTImage
            pos="absolute"
            left={6}
            right={6}
            bottom={6}
            src={chartPlaceholder}
            alt="Chart illustration."
            pointerEvents="none"
          />
        </Card>
      )}
    </Column>
  );
};

const ChartSelect = ({
  isLoading,
  savedCharts,
  audiences,
  onChange,
  value,
}: {
  isLoading: boolean;
  savedCharts: Array<{ id: string; name: string; chart_type: string }>;
  audiences: Array<{ id: string; name: string; last_run_size: number | null }>;
  onChange: (option: {
    type: "audience" | "chart";
    value: string | undefined;
  }) => void;
  value: string | undefined | null;
}) => {
  return (
    <GroupedCombobox
      isLoading={isLoading}
      width="xs"
      optionGroups={[
        {
          label: "Saved charts",
          options:
            savedCharts?.map((c) => ({
              label: c.name,
              value: c.id,
              type: c.chart_type,
              size: 0,
            })) ?? [],
        },
        ...(audiences?.length
          ? [
              {
                label: "Audiences with historical size",
                options:
                  audiences?.map((a) => ({
                    label: a.name,
                    value: a.id,
                    type: "audience",
                    size: a.last_run_size,
                  })) ?? [],
              },
            ]
          : []),
      ]}
      optionAccessory={(option) => ({
        type: "icon",
        icon:
          option.type === "audience"
            ? AudienceIcon
            : () =>
                GraphTypeIcons[
                  transformSavedChartGraphType(option.type) ?? GraphType.Line
                ],
      })}
      optionDescription={(option) => {
        if (option.type === "audience") {
          return `${option.size} ${pluralize("member", option.size)}`;
        }
        return "";
      }}
      onChange={(value) => {
        const isAudience = audiences?.find(
          (a) => String(a.id) === String(value),
        );
        if (isAudience) {
          onChange({ type: "audience", value });
        } else {
          onChange({ type: "chart", value });
        }
      }}
      value={value ?? undefined}
      placeholder="Select a chart..."
    />
  );
};

const ChartWrapper = ({ chartId }: { chartId: string }) => {
  return (
    <AnalyticsFormStateProvider chartId={chartId} useUrlState={false}>
      <SavedChart chartId={chartId} />
    </AnalyticsFormStateProvider>
  );
};

const SavedChart = ({ chartId }: { chartId: string }) => {
  const { audiences, events, metrics, parent } = useAnalyticsContext();
  const form = useFormContext();

  const selectedDates = form
    .watch("selectedDates")
    .map((date) => new Date(date));
  const groupByColumns = form.watch("groupByColumns").filter(isPresent);
  const graphType = form.watch("graphType");
  const selectedAudiences = form.watch("selectedAudiences");
  const rollupFrequency = form.watch("rollupFrequency");
  const metricSelection = form.watch("metricSelection");
  const timeValue = form.watch("timeValue");
  const cumulative = form.watch("cumulative");
  const parentModelId = form.watch("parentModelId");
  const measuringMode = form.watch("measuringMode");
  const measuringSelection = form.watch("measuringSelection");

  const { data, isPolling, pollingError, errors } = useMetricSeries({
    enabled: true,
    audiences: selectedAudiences ?? [],
    customDateRange: selectedDates,
    frequency:
      graphType === GraphType.Bar || graphType === GraphType.Table
        ? AnalyticsFrequency.All
        : rollupFrequency,
    groupByColumns,
    metricSelection: metricSelection,
    metrics: metrics,
    parentModelId,
    timeValue: timeValue,
    cumulative,
    measuringMode,
    measuringSelection,
  });

  const graph = useMemo(() => {
    return transformMetricDataForGraph({
      audiences: selectedAudiences ?? [],
      cumulative: cumulative,
      events: events,
      groupByColumns,
      metricResults: data,
      metrics,
      parent,
      transformForPerformance: graphType === GraphType.Line,
    });
  }, [audiences, cumulative, events, data, graphType, metrics]);

  return (
    <Chart
      type={graphType}
      isError={Boolean(pollingError || Object.keys(errors).length > 0)}
      graphSeries={graph.series}
      isPlaceholder={!chartId}
      isLoading={isPolling || !graph.series}
      groupByColumns={groupByColumns}
    />
  );
};
