import { FC, useMemo, useState } from "react";

import { Column, Heading, Row, Skeleton, SkeletonBox } from "@hightouchio/ui";

import {
  DashboardSyncMetricGranularity,
  SyncHealthQuery,
  useSyncHealthQuery,
} from "src/graphql";
import { SyncHealthChart } from "./sync-health-chart";
import { SyncRequestModal } from "./sync-request-modal";
import {
  EnrichedSyncMetric,
  SyncHealthFilter,
  transformSyncAttempts,
  transformSyncRequests,
} from "./transformations";

const SUCCESS_BAR_COLOR = "var(--chakra-colors-grass-500)";
const SUCCESS_BAR_COLOR_HIGHLIGHT = "var(--chakra-colors-grass-400)";
const WARNING_BAR_COLOR = "var(--chakra-colors-warning-500)";
const WARNING_BAR_COLOR_HIGHLIGHT = "var(--chakra-colors-warning-400)";
const FAILED_BAR_COLOR = "var(--chakra-colors-danger-500)";
const FAILED_BAR_COLOR_HIGHLIGHT = "var(--chakra-colors-danger-400)";

export interface SyncHealthChartData {
  dashboardSyncRequests: EnrichedSyncMetric<
    NonNullable<SyncHealthQuery["dashboardSyncRequests"]>[0]
  >[];
  dashboardSyncAttempts: EnrichedSyncMetric<
    NonNullable<SyncHealthQuery["dashboardSyncAttempts"]>[0]
  >[];
}

export interface TargetSyncs {
  destinationInstanceIds: string[];
  startPeriod: string;
  endPeriod: string | null;
}

const defaultFilter: SyncHealthFilter = {
  sourceIds: [],
  destinationIds: [],
  sourceTypes: [],
  destinationTypes: [],
};

export const SyncHealthCharts: FC = () => {
  const [targetSyncs, setTargetSyncs] = useState<TargetSyncs>({
    destinationInstanceIds: [],
    startPeriod: "",
    endPeriod: null,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [defaultFilters, setDefaultFilters] =
    useState<Record<string, string[]>>();

  const { data: chartData, isLoading } = useSyncHealthQuery(
    {
      granularity: DashboardSyncMetricGranularity.OneWeek,
      sourceIds: [],
      destinationIds: [],
    },
    {
      meta: { isFastEndpoint: true },
      select: (data) => {
        // We add source and destination types and names to the sync requests
        const sourceLookup: Record<
          string,
          { friendlyType: string; type: string; name: string; icon?: string }
        > = {};
        const destinationLookup: Record<
          string,
          { friendlyType: string; type: string; name: string; icon?: string }
        > = {};
        data.connections.forEach((c) => {
          const sourceDefinition = data.getSourceDefinitions.find(
            (sd) => sd.type === c.type,
          );
          sourceLookup[c.id.toString()] = {
            type: c.type,
            name: c.name || sourceDefinition?.name || c.type,
            friendlyType: sourceDefinition?.name || c.type,
            icon: sourceDefinition?.icon,
          };
        });
        data.destinations.forEach((d) => {
          const destinationDefinition = data.getDestinationDefinitions.find(
            (dd) => dd.type === d.type,
          );
          destinationLookup[d.id.toString()] = {
            type: d.type,
            name: d.name || destinationDefinition?.name || d.type,
            friendlyType: destinationDefinition?.name || d.type,
            icon: destinationDefinition?.icon,
          };
        });
        const enrichedSyncRequests = data?.dashboardSyncRequests?.map((sr) => {
          if (!sr.destination_id || !sr.source_id) {
            return {
              ...sr,
              source_type: undefined,
              sourceName: undefined,
              destinationType: undefined,
              destinationName: undefined,
              destinationIcon: undefined,
              sourceIcon: undefined,
              destinationFriendlyType: undefined,
              sourceFriendlyType: undefined,
            };
          }
          const source = sourceLookup[sr.source_id.toString()];
          const destination = destinationLookup[sr.destination_id.toString()];
          return {
            ...sr,
            source_type: source?.type,
            sourceName: source?.name || source?.type,
            destinationType: destination?.type,
            destinationName: destination?.name || destination?.type,
            destinationIcon: destination?.icon,
            sourceIcon: source?.icon,
            destinationFriendlyType: destination?.friendlyType,
            sourceFriendlyType: source?.friendlyType,
          };
        });

        const enrichedSyncAttempts = data?.dashboardSyncAttempts?.map((sa) => {
          if (!sa.destination_id || !sa.source_id) {
            return {
              ...sa,
              source_type: undefined,
              sourceName: undefined,
              destinationType: undefined,
              destinationName: undefined,
              destinationIcon: undefined,
              sourceIcon: undefined,
              sourceFriendlyType: undefined,
              destinationFriendlyType: undefined,
            };
          }
          const source = sourceLookup[sa.source_id.toString()];
          const destination = destinationLookup[sa.destination_id.toString()];
          return {
            ...sa,
            source_type: source?.type,
            sourceName: source?.name || source?.type,
            destinationType: destination?.type,
            destinationName: destination?.name || destination?.type,
            destinationIcon: destination?.icon,
            sourceIcon: source?.icon,
            sourceFriendlyType: source?.friendlyType,
            destinationFriendlyType: destination?.friendlyType,
          };
        });

        return {
          dashboardSyncRequests: enrichedSyncRequests || [],
          dashboardSyncAttempts: enrichedSyncAttempts || [],
        };
      },
    },
  );

  // We allow filters here so we only show the syncs that are in the time window and don't bother querying syncs with
  // an irrelevant status
  function setTargetSyncsFromChart(activeIndex: number) {
    const timeBucketTargetStart =
      transformedOperations?.[activeIndex]?.time_bucket;
    const timeBucketTargetEnd =
      transformedOperations?.[activeIndex + 1]?.time_bucket ?? null;

    const matchingRequests = chartData?.dashboardSyncRequests.filter(
      (cd) => cd.time_bucket === timeBucketTargetStart,
    );

    if (!timeBucketTargetStart || !matchingRequests?.length) {
      return;
    }
    const rawSyncsInTimeWindow: string[] = [];
    matchingRequests.forEach((r) => {
      rawSyncsInTimeWindow.push(...r.counts.failed);
      rawSyncsInTimeWindow.push(...r.counts.succeeded);
      rawSyncsInTimeWindow.push(...r.counts.warning);
    });
    const syncsInTimeWindow = new Set<string>(rawSyncsInTimeWindow);

    setTargetSyncs({
      destinationInstanceIds: Array.from(syncsInTimeWindow),
      startPeriod: timeBucketTargetStart,
      endPeriod: timeBucketTargetEnd,
    });
    setModalOpen(true);
  }

  const transformedSyncRequests = useMemo(() => {
    if (!chartData) return [];
    return transformSyncRequests(
      chartData.dashboardSyncRequests,
      defaultFilter,
    ).slice(1);
  }, [chartData?.dashboardSyncRequests]);

  const transformedOperations = useMemo(() => {
    if (!chartData) return [];
    return transformSyncAttempts(
      chartData.dashboardSyncAttempts,
      defaultFilter,
    ).slice(1);
  }, [chartData?.dashboardSyncAttempts]);

  const noSyncRequests = !transformedSyncRequests.filter(
    (r) => r.succeeded || r.warning || r.failed,
  ).length;

  return (
    <>
      <Column gap={4}>
        <Row gap={4} align="center">
          <Heading size="lg">Sync summary</Heading>
        </Row>

        {isLoading ? (
          <Skeleton isLoading={true}>
            <SkeletonBox minH={300} borderRadius="md" />
          </Skeleton>
        ) : (
          <SyncHealthChart
            description="Number of sync runs triggered manually or via recurring schedule"
            onClick={(index) => {
              setDefaultFilters({});
              setTargetSyncsFromChart(index);
            }}
            bars={[
              {
                stackId: "status",
                dataKey: "failed",
                fill: FAILED_BAR_COLOR,
                highlightedFill: FAILED_BAR_COLOR_HIGHLIGHT,
              },
              {
                stackId: "status",
                dataKey: "warning",
                fill: WARNING_BAR_COLOR,
                highlightedFill: WARNING_BAR_COLOR_HIGHLIGHT,
              },
              {
                stackId: "status",
                dataKey: "succeeded",
                fill: SUCCESS_BAR_COLOR,
                highlightedFill: SUCCESS_BAR_COLOR_HIGHLIGHT,
              },
            ]}
            data={transformedSyncRequests}
            title="Sync runs over time"
            emptyText="No sync runs"
            isEmpty={!!noSyncRequests}
          />
        )}
      </Column>
      <SyncRequestModal
        targetSyncs={targetSyncs}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        defaultFilters={defaultFilters}
      />
    </>
  );
};
