import { FC, useEffect, useMemo } from "react";

import {
  Box,
  Column,
  EditIcon,
  GroupedCombobox,
  Text,
  TraitIcon,
} from "@hightouchio/ui";
import immutableUpdate, { Spec } from "immutability-helper";
import uniq from "lodash/uniq";
import { isPresent } from "ts-extras";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import { useFilterableColumnsQuery } from "src/graphql";
import {
  isRelatedColumn,
  DefaultOperators,
  isColumnReference,
  PropertyCondition,
  isTraitCondition,
  isInlineAggregatedTraitCondition,
  isJourneyEventColumn,
  ColumnReference,
  isRelatedJourneyEventColumn,
  isInlineTraitColumn,
} from "src/types/visual";

import { useQueryBuilderContext } from "src/components/explore/context/hooks";
import { FilterPopover } from "src/components/explore/filter-popover";
import { FilterProps } from "./condition";
import { HStack } from "./shared";
import { DetailButton, XButton } from "./condition-buttons";
import { ConditionTextWrapper } from "./condition-text-wrapper";
import { validatePropertyCondition } from "./condition-validation";
import { ErrorMessage } from "./error-message";
import { InlineTrait } from "./inline-trait";
import { PropertyConditions } from "./property-conditions";
import { PropertyOperatorValueFilter } from "./property-operator-value-filter";
import { TraitFilter } from "./trait-filter";
import {
  getColumnFromValue,
  getColumnOptions,
  getModelIdFromColumn,
  getModelIdFromTrait,
  getPropertyNameFromColumn,
  getPropertyNameFromTrait,
  getTraitOptions,
} from "./utils";
import { GroupedComboboxFilter } from "./combobox-filter";
import { useFetchSuggestions } from "src/hooks/use-column-suggestions";
import { useFetchFilterableAudienceColumns } from "src/hooks/use-fetch-filterable-audience-columns";

export type PropertyFilterProps = FilterProps<PropertyCondition> & {
  prefix?: boolean;
  showSecondaryFilter?: boolean;
  showParameterizeCheckbox?: boolean;
  allowPropertyComparison?: boolean;
  isNested?: boolean;
};

export const usePropertyFilterValidation = (condition: PropertyCondition) => {
  const filterId = useMemo<string>(uuidv4, []);
  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const propertyError = filterErrors?.property;
  const operatorError = filterErrors?.operator;
  const valueError = filterErrors?.value;

  useEffect(() => {
    setFieldError(filterId, validatePropertyCondition(condition));

    return () => {
      removeErrors([filterId]);
    };
  }, [
    condition.property,
    condition.operator,
    condition.value,
    filterId,
    condition.propertyOptions,
  ]);

  return {
    filterErrors,
    propertyError,
    operatorError,
    valueError,
  };
};

export const PropertyFilter: FC<Readonly<PropertyFilterProps>> = ({
  showSecondaryFilter = false,
  showParameterizeCheckbox = false,
  isNested = false,
  allowPropertyComparison = false,
  ...props
}) => {
  const {
    columns: columnsOverride,
    traits: traitsOverride,
    condition,
    onChange,
    onRemove,
    audience,
    parent,
  } = props;

  const {
    columns: allColumns,
    traits: allTraits,
    events,
  } = useQueryBuilderContext();
  const columns = columnsOverride ?? allColumns;
  const traits = traitsOverride ?? allTraits;

  const { propertyError, operatorError, valueError } =
    usePropertyFilterValidation(condition);

  const isDeprecatedPropertyCondition = !isColumnReference(condition.property);

  const isTrait = isTraitCondition(condition);
  const eventModelId = isRelatedJourneyEventColumn(condition.property)
    ? condition.property.column.modelId
    : null;
  const journeyEventColumnConditionSelected = Boolean(eventModelId);

  const modelIdsFromTraits = uniq(
    traits
      .map((trait) => trait.relationship?.to_model.id.toString())
      .filter(Boolean),
  );

  // Fetch columns for the selected event, if there is one
  const filterableColumnsQuery = useFilterableColumnsQuery(
    {
      segmentId: eventModelId?.toString() ?? "",
    },
    {
      // Only used for journey event columns
      enabled:
        Boolean(eventModelId) &&
        isRelatedJourneyEventColumn(condition.property),
      select: (data) => data.segments_by_pk?.filterable_audience_columns ?? [],
    },
  );
  const eventColumns = filterableColumnsQuery.data ?? [];

  // Need the filterable columns from the traits' models
  // to show the correct types for the trait options
  const {
    filterableColumns: filterableColumnsByModelId,
    isLoading: filterableColumnsLoading,
  } = useFetchFilterableAudienceColumns(modelIdsFromTraits);

  const trait = isTrait
    ? traits.find(
        ({ id }) => id === condition.property.column.traitDefinitionId,
      )
    : null;

  const modelId = isColumnReference(condition.property)
    ? isTrait
      ? getModelIdFromTrait(trait)
      : isRelatedColumn(condition.property) &&
          isInlineTraitColumn(condition.property.column)
        ? events.find(
            (event) =>
              isRelatedColumn(condition.property) &&
              isInlineTraitColumn(condition.property.column) &&
              event.id.toString() ===
                condition.property.column?.relationshipId.toString(),
          )?.to_model.id
        : getModelIdFromColumn(condition.property)
    : parent?.id;

  const columnName = isColumnReference(condition.property)
    ? isTrait
      ? getPropertyNameFromTrait(trait)
      : getPropertyNameFromColumn(condition.property)
    : condition.property;

  const shouldFetchSuggestions = useMemo(() => {
    if (isTrait && isPresent(trait?.id)) return true;
    const validInputs = isPresent(modelId) && isPresent(columnName);
    return validInputs;
  }, [modelId, columnName, isTrait]);
  const { suggestions, loadingSuggestions, onSearchUpdate } =
    useFetchSuggestions(
      {
        modelId,
        columnName: columnName ?? undefined,
        traitId: isTrait ? trait?.id : undefined,
      },
      { enabled: shouldFetchSuggestions },
    );

  const mergedModels = uniq([
    ...(columns?.map(({ model_name }) => model_name) || []),
    ...(traits?.map((trait) => trait.relationship?.to_model.name) || []),
  ]);

  const propertyOptions =
    mergedModels.length > 1
      ? mergedModels.map((model) => {
          const modelColumns = columns?.filter(
            ({ model_name }) => model_name === model,
          );
          // Filter all traits that belong to the merged model
          const traitsForModel = traits?.filter(
            (trait) => trait.relationship?.to_model.name === model,
          );
          // Get the trait options for the merged model
          const traitOptions = getTraitOptions(
            condition.property,
            traitsForModel.map((trait) => ({
              ...trait,
              filterable_audience_columns:
                filterableColumnsByModelId[trait.relationship?.to_model.id] ??
                [],
            })),
          );

          return {
            label:
              model === parent?.name ? "Properties" : (model ?? "No model"),
            options: [
              ...traitOptions,
              ...getColumnOptions(
                modelColumns ?? [],
                isDeprecatedPropertyCondition,
              ),
            ],
          };
        })
      : [
          {
            label: "Properties",
            options: [
              ...getTraitOptions(
                condition.property,
                traits.map((trait) => ({
                  ...trait,
                  filterable_audience_columns:
                    filterableColumnsByModelId[
                      trait.relationship?.to_model.id
                    ] ?? [],
                })),
              ),
              ...getColumnOptions(columns ?? [], isDeprecatedPropertyCondition),
            ],
          },
        ];

  // Convert condition's property from a TraitColumn to an InlineColumn
  const onEditTrait = () => {
    if (!trait) return;

    onChange(
      immutableUpdate(condition, {
        property: {
          type: { $set: "related" },
          column: {
            $set: {
              type: "inline_trait",
              traitType: trait?.type,
              traitConfig: trait?.config,
              conditions: trait?.config.conditions ?? [],
              relationshipId: trait?.relationship?.id,
            },
          },
        },
        propertyOptions: {
          $merge: {
            traitType: "inline_trait",
          },
        },
      } as Spec<PropertyCondition>),
    );
  };

  return (
    <>
      <HStack gap={2} sx={{ alignItems: "flex-start" }}>
        {/* Show filter popover at beginning of filter */}
        {journeyEventColumnConditionSelected && (
          <FilterPopover
            {...props}
            condition={condition}
            isDisabled={loadingSuggestions || props.isDisabled}
            onChange={onChange}
          />
        )}
        {!journeyEventColumnConditionSelected && showSecondaryFilter ? (
          <Column>
            <GroupedComboboxFilter
              isLoading={
                props.isLoading ||
                filterableColumnsLoading ||
                filterableColumnsQuery.isLoading
              }
              isDisabled={props.isDisabled}
              isInvalid={Boolean(propertyError)}
              optionGroups={propertyOptions.filter(
                ({ options }) => options.length > 0,
              )}
              optionDescription={(option) => option.description || ""}
              placeholder="Select a property"
              popoverWidth={isNested ? "xs" : undefined}
              width="2xs"
              value={condition.property ?? undefined}
              onChange={(value) => {
                const column = getColumnFromValue(
                  propertyOptions.flatMap(({ options }) => options),
                  value,
                );

                if (!column) {
                  return;
                }

                onChange({
                  propertyType: column.type,
                  property: value,
                  propertyOptions: {
                    caseSensitive:
                      "case_sensitive" in column
                        ? (column.case_sensitive ?? undefined)
                        : undefined,
                  },
                  operator: column.type ? DefaultOperators[column.type] : null,
                  value: null,
                });
              }}
            />
            {propertyError && <ErrorMessage>{propertyError}</ErrorMessage>}
          </Column>
        ) : (
          <>
            {condition?.propertyOptions?.traitType === "inline_trait" ? (
              <>
                {isRelatedColumn(condition?.property) &&
                  !isJourneyEventColumn(condition.property.column) && (
                    <Column>
                      <ConditionTextWrapper>
                        <Text color="text.secondary">Has</Text>
                        <Box
                          as={TraitIcon}
                          bg="white"
                          color="warning.border"
                          borderRadius="sm"
                          borderColor="warning.border"
                          borderWidth={1}
                          height={5}
                          width={5}
                          p={0.5}
                        />
                        <Text color="text.secondary" fontWeight="medium">
                          Trait calculation
                        </Text>
                      </ConditionTextWrapper>

                      {parent &&
                        isRelatedColumn(condition.property) &&
                        isInlineAggregatedTraitCondition(condition) && (
                          <InlineTrait
                            modelId={modelId}
                            isLoading={props.isLoading}
                            isDisabled={props.isDisabled}
                            audience={audience}
                            condition={condition}
                            loadingSuggestions={loadingSuggestions}
                            operatorError={operatorError}
                            parent={parent}
                            propertyError={propertyError}
                            suggestions={suggestions}
                            valueError={valueError}
                            onChange={onChange}
                            onSearchUpdate={onSearchUpdate}
                          />
                        )}
                    </Column>
                  )}
              </>
            ) : (
              <>
                <ConditionTextWrapper>
                  <Text color="text.secondary">
                    {isTrait
                      ? "Has custom trait"
                      : journeyEventColumnConditionSelected
                        ? "has property"
                        : "Has property"}
                  </Text>
                </ConditionTextWrapper>
                {/* Show here if not an event property */}
                {!journeyEventColumnConditionSelected && (
                  <FilterPopover
                    {...props}
                    condition={condition}
                    isDisabled={loadingSuggestions || props.isDisabled}
                    hasError={Boolean(propertyError)}
                    onChange={onChange}
                  />
                )}
                {isRelatedJourneyEventColumn(condition.property) && (
                  <Column>
                    <GroupedCombobox
                      isDisabled={props.isDisabled}
                      isInvalid={Boolean(propertyError)}
                      isLoading={
                        props.isLoading || filterableColumnsQuery.isLoading
                      }
                      optionGroups={[
                        {
                          label: "Event properties",
                          options: eventColumns,
                        },
                      ]}
                      optionLabel={(option) =>
                        option.alias ?? option.name ?? ""
                      }
                      optionDescription={(option) => option.description || ""}
                      optionValue={(option) => option}
                      placeholder="Select a property"
                      popoverWidth={isNested ? "xs" : undefined}
                      width="4xs"
                      value={eventColumns.find(
                        (column) =>
                          isRelatedJourneyEventColumn(condition.property) &&
                          getPropertyNameFromColumn(column.column_reference) ===
                            condition.property.column.name,
                      )}
                      onChange={(column) => {
                        // column.column_reference is `any` type
                        const columnReference =
                          column?.column_reference as ColumnReference;

                        const columnName =
                          getPropertyNameFromColumn(columnReference);

                        onChange(
                          immutableUpdate(condition, {
                            property: {
                              column: {
                                $set: {
                                  name: columnName,
                                  modelId: eventModelId,
                                  type: "journey_event",
                                },
                              },
                            },
                            propertyOptions: {
                              $set: {
                                caseSensitive:
                                  column?.case_sensitive ?? undefined,
                              },
                            },
                            propertyType: { $set: column?.type },
                            operator: {
                              $set: column?.type
                                ? DefaultOperators[column.type]
                                : null,
                            },
                            value: { $set: null },
                          } as Spec<PropertyCondition>),
                        );
                      }}
                    />
                    {propertyError && (
                      <ErrorMessage>{propertyError}</ErrorMessage>
                    )}
                  </Column>
                )}
              </>
            )}
          </>
        )}

        {condition.property &&
          condition?.propertyOptions?.traitType !== "inline_trait" && (
            <PropertyOperatorValueFilter
              isDisabled={props.isDisabled}
              allowPropertyComparison={allowPropertyComparison}
              condition={condition}
              loadingSuggestions={loadingSuggestions}
              operatorError={operatorError}
              parent={parent}
              showParameterizeCheckbox={showParameterizeCheckbox}
              suggestions={suggestions}
              valueError={valueError}
              onChange={onChange}
              onSearchUpdate={onSearchUpdate}
              columns={columns}
            />
          )}

        {onRemove && (
          <XButton isDisabled={props.isDisabled} onRemove={onRemove} />
        )}
      </HStack>
      {isTrait && (
        <Column pl={10} gap={2}>
          {condition.propertyOptions?.traitType === "trait" ? (
            <>
              <PropertyConditions
                conditions={condition.property.column.conditions ?? []}
              />
              <DetailButton icon={EditIcon} size="sm" onClick={onEditTrait}>
                Edit trait
              </DetailButton>
            </>
          ) : (
            <TraitFilter {...props} condition={condition} />
          )}
        </Column>
      )}
    </>
  );
};
