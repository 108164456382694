import { Syncs } from "src/graphql";

/**
 * A draft action is one that has no syncs and is disabled
 */
export const isDraftAction = (
  sync: Pick<Syncs, "id"> | undefined | null, // At minimum, need id
  enabled: boolean | null,
) => {
  return !sync && !enabled;
};
