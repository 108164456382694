import { FC, useMemo } from "react";

import { Column, Spinner, useToast } from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { useOutletContext } from "src/router";

import { DestinationForm } from "src/components/destinations/sync-form";
import { PermissionProvider } from "src/components/permission/permission-context";
import {
  useExternalSegmentsQuery,
  useSyncableColumnsOnSyncQuery,
  useUpdateSyncMutation,
} from "src/graphql";
import { track } from "src/lib/analytics";

import { OutletContext } from ".";

export const Configuration: FC = () => {
  const { sync: syncWithoutSyncableColumns } =
    useOutletContext<OutletContext>();
  const { toast } = useToast();

  const syncableColumnsOnSyncQuery = useSyncableColumnsOnSyncQuery(
    {
      id: syncWithoutSyncableColumns.id ?? "",
    },
    {
      enabled: Boolean(syncWithoutSyncableColumns.id),
      select: (data) => data.syncs[0]?.segment?.syncable_columns,
    },
  );

  const sync = useMemo(() => {
    return {
      ...syncWithoutSyncableColumns,
      segment: syncWithoutSyncableColumns.segment
        ? {
            ...syncWithoutSyncableColumns.segment,
            syncable_columns: syncableColumnsOnSyncQuery.data ?? [],
          }
        : null,
    };
  }, [syncWithoutSyncableColumns, syncableColumnsOnSyncQuery.data]);

  const { mutateAsync: updateSync } = useUpdateSyncMutation();

  const { data: externalSegmentsData } = useExternalSegmentsQuery(
    { syncId: sync?.id },
    { enabled: !!sync },
  );
  // We used to support multiple external segments per sync, where only one
  // would be valid at a time. When there is more than one, we only care about
  // the most recent, active one.
  const externalSegment = externalSegmentsData?.external_segments?.sort(
    (a, b) => {
      return a.created_at > b.created_at ? -1 : 1;
    },
  )?.[0];

  const updateConfig = async (config) => {
    try {
      await updateSync({
        id: sync.id,
        object: {
          config: { ...config, configVersion: sync?.config?.configVersion },
        },
      });
      toast({
        id: "update-sync",
        title: "Sync configuration updated",
        variant: "success",
      });
      track("Sync Configuration Updated", {
        sync_id: sync.id,
        destination_type: sync.destination?.definition?.name,
        schedule_type: sync.schedule?.type,
        source_type: sync.segment?.connection?.type,
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "update-sync",
        title: "Couldn't update sync configuration",
        variant: "error",
      });
    }
  };

  if (syncableColumnsOnSyncQuery.isLoading) {
    return (
      <Column justify="center" align="center" flex={1} minHeight={0}>
        <Spinner size="lg" />
      </Column>
    );
  }

  return (
    <PermissionProvider
      fallback={<Spinner m="auto" size="lg" />}
      permission={{
        v2: {
          resource: "sync",
          grant: "can_update",
          id: sync.id,
        },
      }}
    >
      <Column
        p={4}
        pb={10}
        overflow="auto"
        flex={1}
        minH={0}
        bg="base.lightBackground"
      >
        {sync.segment && sync.destination && (
          <DestinationForm
            permission={{
              v2: {
                resource: "sync",
                grant: "can_update",
                id: sync.id,
              },
            }}
            testPermission={{
              v2: {
                resource: "sync",
                grant: "can_test",
                id: sync.id ?? "",
              },
            }}
            destination={sync.destination}
            destinationDefinition={sync.destination.definition}
            externalSegment={externalSegment}
            model={sync.segment}
            slug={sync.destination.definition?.type}
            sourceDefinition={sync.segment.connection?.definition}
            sync={sync}
            onSubmit={updateConfig}
          />
        )}
      </Column>
    </PermissionProvider>
  );
};
