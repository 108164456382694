import { Navigate } from "src/router";
import { SyncNotificationOverrides } from "src/components/notification-channels/default-notification-overrides";
import { Recipients } from "./recipients";
import { Alerts } from "./alerts";
import { Layout } from "./layout";
import { PagerDutyForm } from "./recipients/components/pagerduty-form";
import { AdvancedAlertingSplashPage } from "src/components/resource-alert-triggers/splash-page";

export const routes = [
  {
    path: "alerting",
    element: <Layout />,
    children: [
      { index: true, element: <Navigate to="alerts" /> },
      {
        path: "recipients",
        element: <Recipients />,
        children: [
          {
            path: "pagerduty/manage",
            element: <PagerDutyForm />,
          },
        ],
      },
      { path: "overrides", element: <SyncNotificationOverrides /> },
      {
        path: "alerts",
        element: <Alerts />,
      },
    ],
  },
  { path: "alerting/enable", element: <AdvancedAlertingSplashPage /> },
];
