import { FC, useEffect, useState } from "react";

import {
  Text,
  Row,
  Column,
  Alert,
  FormField,
  MultiSelect,
  TextInput,
  Button,
  useToast,
  Tooltip,
  ConfirmationDialog,
  Paragraph,
  Heading,
  CloseIcon,
  IconButton,
  Box,
} from "@hightouchio/ui";
import * as diff from "diff";
import { useFlags } from "launchdarkly-react-client-sdk";
import { sortBy } from "lodash";
import { useLocation, useSearchParams } from "src/router";
import { isPresent } from "ts-extras";

import { Diff } from "src/components/diff";
import { FeaturePreview } from "src/components/feature-gates";
import { PermissionProvider } from "src/components/permission/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ChangelogQuery,
  useChangelogQuery,
  useCreateAuditLogExportMutation,
  useMembersQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { formatDatetime } from "src/utils/time";
import { Drawer } from "src/components/drawer";
import { TextWithTooltip } from "src/components/text-with-tooltip";

export const AuditLog: FC = () => {
  return (
    <Column gap={4}>
      <PermissionProvider
        permission={{
          v2: {
            resource: "workspace",
            grant: "can_read",
          },
        }}
      >
        <General />
      </PermissionProvider>
    </Column>
  );
};

type ChangelogItem = ChangelogQuery["auditLog"]["items"][0];

const General: FC = () => {
  const { user, workspace } = useUser();
  const { auditLogs } = useFlags();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryUsers = searchParams.get("users");
  const queryResources = searchParams.get("resources");
  const queryResourceName = searchParams.get("resource_name");
  const queryStartDate = searchParams.get("start_date");
  const queryEndDate = searchParams.get("end_date");
  const queryResourceId = searchParams.get("resource_id");

  let queryParamFilteredUsers: string[] = [];

  if (queryUsers !== null) {
    try {
      queryParamFilteredUsers = queryUsers?.split(",") || [];
    } catch (_err) {
      queryParamFilteredUsers = [];
    }
  }

  let queryParamFilteredResources;
  if (queryResources !== null) {
    try {
      queryParamFilteredResources = queryResources?.split(",") || [];
    } catch (_err) {
      queryParamFilteredResources = [];
    }
  }

  const { offset, limit, page, setPage } = useTableConfig({ limit: 10 });
  const [resourceName, setResourceName] = useState<string | null>(
    queryResourceName,
  );
  const [startDate, setStartDate] = useState<string | null>(queryStartDate);
  const [endDate, setEndDate] = useState<string | null>(queryEndDate);
  const [selectedChangelogItem, setSelectedChangelogItem] =
    useState<ChangelogItem | null>();
  const [filteredResources, setFilteredResources] = useState<string[]>(
    queryParamFilteredResources || [],
  );
  const [filteredUsers, setFilteredUsers] = useState<string[]>(
    queryParamFilteredUsers || [],
  );

  const { data: memberships } = useMembersQuery(
    { workspaceId: workspace?.id },
    { select: (data) => data.all_memberships_v2 },
  );

  const { data, isFetching } = useChangelogQuery(
    {
      filters: {
        resource_name: resourceName,
        start_date: startDate,
        end_date: endDate,
        user_ids: filteredUsers,
        filtered_resources: filteredResources,
        resource_id: queryResourceId,
        offset,
      },
    },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
      suspense: true,
    },
  );

  useEffect(() => {
    setSelectedChangelogItem(null);
  }, [data?.auditLog]);

  useEffect(() => {
    setPage(0);
    if (filteredResources?.length > 0) {
      searchParams.set("resources", filteredResources.join(","));
    } else {
      searchParams.delete("resources");
    }

    if (filteredUsers?.length > 0) {
      searchParams.set("users", filteredUsers.join(","));
    } else {
      searchParams.delete("users");
    }

    if (resourceName) {
      searchParams.set("resource_name", resourceName);
    } else {
      searchParams.delete("resource_name");
    }

    if (startDate) {
      searchParams.set("start_date", startDate);
    } else {
      searchParams.delete("start_date");
    }

    if (endDate) {
      searchParams.set("end_date", endDate);
    } else {
      searchParams.delete("end_date");
    }

    setSearchParams(searchParams);
  }, [filteredResources, startDate, resourceName, endDate, filteredUsers]);

  const sortedResources = sortBy(data?.auditLog.resources);

  const hasAuditLogs =
    workspace?.organization?.plan?.sku === "business_tier" || auditLogs;

  const fullAccess = hasAuditLogs || user?.is_admin;

  const users = memberships?.filter((m) => m.user).map((m) => m.user) ?? [];
  const sortedUsers = sortBy(users, "name").filter(isPresent);

  return (
    <>
      <FeaturePreview
        enabled={hasAuditLogs}
        featureDetails={{
          pitch:
            "A full audit log of all user activity in your Hightouch workspace",
          description:
            "Hightouch records all user activity — such as user sessions and sync configuration updates — to a centralized audit log. Admins can use the log to respond to incidents and monitor activity.",
          bullets: [
            "Track all changes to your Hightouch workspace",
            "Search and filter by user, date, resource type, and resource ID",
            "Monitor usage history and pinpoint anomalous activity",
          ],
          image: {
            src: "https://cdn.sanity.io/images/pwmfmi47/production/bb14c8cd809dbab729e7b70e5210e9615cebd73a-1002x576.png",
            alt: "Screenshot showing a log of member names, actions such as 'destination updated', and time of update",
          },
        }}
        featureName="audit logs"
        variant="limited"
      />
      {!hasAuditLogs && user?.is_admin && (
        <Alert
          message="Only Hightouch employees can see the full audit log. Workspace members can only see the most recent page of changes."
          title="Internal notice: audit logs are not enabled for this workspace"
          type="warning"
          variant="inline"
        />
      )}
      <Column gap={8}>
        <Row gap={4} justify="space-between" align="center">
          <Heading>Audit log</Heading>
          <AuditLogExportButton hasPermission={fullAccess} />
        </Row>
        <Row gap={4} flexWrap="wrap" sx={{ div: { width: "200px" } }}>
          <FormField label="Workspace member">
            <MultiSelect
              isDisabled={!fullAccess}
              optionLabel={(user) => user?.name ?? user?.email ?? ""}
              optionValue={(user) => String(user?.id ?? "")}
              options={sortedUsers}
              placeholder="Filter members..."
              value={filteredUsers}
              onChange={setFilteredUsers}
            />
          </FormField>
          <FormField label="Resource type">
            <MultiSelect
              isDisabled={!fullAccess}
              optionLabel={(resource) => resource}
              optionValue={(resource) => resource}
              options={sortedResources}
              placeholder="Filter resources..."
              value={filteredResources}
              onChange={setFilteredResources}
            />
          </FormField>
          <FormField label="Resource name">
            <TextInput
              isDisabled={!fullAccess}
              placeholder="Search..."
              value={resourceName || ""}
              onChange={(e) => setResourceName(e.target.value)}
            />
          </FormField>
          <FormField label="Start date">
            <TextInput
              isDisabled={!fullAccess}
              type="date"
              value={startDate || ""}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </FormField>
          <FormField label="End date">
            <TextInput
              isDisabled={!fullAccess}
              placeholder="End date"
              type="date"
              value={endDate || ""}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </FormField>
        </Row>
      </Column>
      <Row gap={4} align="flex-start">
        <Column flex={1} minWidth={0}>
          <Table
            scrollable
            columns={[
              {
                name: "Date",
                max: "min-content",
                cell: ({ created_at }) => (
                  <TextWithTooltip>
                    {formatDatetime(created_at) ?? "--"}
                  </TextWithTooltip>
                ),
              },
              {
                name: "Member",
                max: "min-content",
                cell: ({ user_name }) => (
                  <TextWithTooltip>{user_name}</TextWithTooltip>
                ),
              },
              {
                name: "Action",
                max: "min-content",
                cell: ({ action }) => (
                  <TextWithTooltip>{action}</TextWithTooltip>
                ),
              },
              {
                name: "Resource type",
                max: "min-content",
                cell: ({ resource }) => (
                  <TextWithTooltip>{resource}</TextWithTooltip>
                ),
              },
              {
                name: "Resource name",
                cell: ({ resource_name }) => (
                  <TextWithTooltip>{resource_name}</TextWithTooltip>
                ),
              },
            ]}
            data={data?.auditLog.items}
            highlight={selectedChangelogItem?.created_at}
            loading={isFetching}
            placeholder={{
              title: "No items in the audit log for your workspace",
              body: "Try changing your filters to see more items",
              error: "Failed to load audit log, please try again",
            }}
            primaryKey="id"
            onRowClick={(row) => {
              setSelectedChangelogItem(row);
            }}
          />
          <Row mt={4} justify="flex-end">
            <Pagination
              alwaysShow
              compact
              count={
                fullAccess
                  ? data?.auditLog.total
                  : Math.min(data?.auditLog.total || 0, limit)
              }
              disabledTooltip={
                !fullAccess && (data?.auditLog.total || 0) > limit
                  ? "Your plan only allows you to preview the first page of the audit log."
                  : undefined
              }
              label="entries"
              page={page}
              rowsPerPage={limit}
              setPage={setPage}
            />
          </Row>
        </Column>
      </Row>

      <Drawer
        blockBackgroundInteraction={false}
        isOpen={Boolean(selectedChangelogItem)}
        size="lg"
        onClose={() => setSelectedChangelogItem(null)}
      >
        {selectedChangelogItem && (
          <Column gap={6} px={4} py={6} overflowY="auto">
            <Row align="center" justify="space-between">
              <Heading>Action details</Heading>
              <IconButton
                aria-label="Close drawer"
                icon={CloseIcon}
                onClick={() => setSelectedChangelogItem(null)}
              />
            </Row>
            <Box
              display="grid"
              gridTemplateColumns="min-content 1fr"
              rowGap={2}
              columnGap={4}
            >
              <Text fontWeight="medium">Date:</Text>
              <Text>{formatDatetime(selectedChangelogItem.created_at)}</Text>
              <Text fontWeight="medium">User:</Text>
              <Text>{selectedChangelogItem.user_name}</Text>
              <Text fontWeight="medium">Action:</Text>
              <Text>{selectedChangelogItem.action}</Text>
              <Text whiteSpace="nowrap" fontWeight="medium">
                Resource Type:
              </Text>
              <Text>{selectedChangelogItem.resource}</Text>
              <Text whiteSpace="nowrap" fontWeight="medium">
                Resource Name:
              </Text>
              <Text>{selectedChangelogItem.resource_name}</Text>
            </Box>
            {selectedChangelogItem && (
              <ChangelogItemDiff
                key={selectedChangelogItem.id}
                item={selectedChangelogItem}
              />
            )}
          </Column>
        )}
      </Drawer>
    </>
  );
};

const ChangelogItemDiff = ({ item }: { item: ChangelogItem }) => {
  if (item.new === null) {
    const diffs = diff.diffJson(item.old, "");
    return <Diff diffs={diffs} />;
  }

  const diffs = diff.diffJson(item.old || "", item.new);
  return <Diff diffs={diffs} />;
};

const AuditLogExportButton: FC<{ hasPermission: boolean }> = ({
  hasPermission,
}) => {
  const { toast } = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const { auditLogExport } = useFlags();

  const { workspace } = useUser();
  const location = useLocation();
  const { mutateAsync: createAuditLogExport, isLoading: isExporting } =
    useCreateAuditLogExportMutation();

  const exportAuditLog = async () => {
    try {
      await createAuditLogExport({});

      toast({
        id: "audit-log-export",
        title: "Exporting your audit log",
        message:
          "This may take several minutes. You will be emailed a link to download a CSV when it has completed.",
        variant: "success",
      });

      analytics.track("Audit Log Exported", {
        workspace_id: workspace?.id,
        workspace_slug: workspace?.slug,
        origin_page: location.pathname,
      });
    } catch (_err) {
      toast({
        id: "audit-log-export",
        title: "Export failed",
        message:
          "We were unable to export your audit log, please try again later.",
        variant: "error",
      });
    }
  };

  if (!auditLogExport) {
    return null;
  }

  return (
    <>
      <Tooltip
        message="You do not have permission to perform this action"
        isDisabled={hasPermission}
      >
        <Button
          isDisabled={!hasPermission}
          isLoading={isExporting}
          onClick={() => setIsOpen(true)}
        >
          Export
        </Button>
      </Tooltip>
      <ConfirmationDialog
        isOpen={isOpen}
        title="Export audit log"
        confirmButtonText="Export"
        variant="warning"
        onClose={() => setIsOpen(false)}
        onConfirm={exportAuditLog}
      >
        <Paragraph>
          This will export your <b>entire</b> available audit log as a CSV.
          Exporting with filters is not yet supported.
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};
