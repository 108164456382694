import { Column, FormField, TextInput } from "@hightouchio/ui";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Form, useHightouchForm } from "src/components/form";
import { Controller } from "react-hook-form";
import { FormBar } from "src/components/form/form-bar";

const schema = yup.object({
  name: yup.string().required(),
  email: yup.string().email().required(),
  password: yup.string().required(),
});

// If a form state type is required infer it from a schema
export type FormSchema = yup.InferType<typeof schema>;

export const FormExample = () => {
  const form = useHightouchForm({
    values: {
      name: "",
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
    onSubmit: async (data) => {
      // Make mutation call here
      // Error handling and toast notifications are handled automatically
      console.log(data);
    },
  });

  return (
    <Form form={form}>
      <Column gap={6}>
        <Controller
          name="name"
          render={({ field, fieldState: { error } }) => (
            <FormField label="Name" {...field} error={error?.message}>
              <TextInput {...field} />
            </FormField>
          )}
        />
        <Controller
          name="email"
          render={({ field, fieldState: { error } }) => (
            <FormField label="Email" {...field} error={error?.message}>
              <TextInput {...field} />
            </FormField>
          )}
        />
        <Controller
          name="password"
          render={({ field, fieldState: { error } }) => (
            <FormField label="Password" {...field} error={error?.message}>
              <TextInput {...field} />
            </FormField>
          )}
        />
      </Column>
      <FormBar
        confirmation={{
          title: "Are you sure?",
          message: "Updating these values will trigger an action",
        }}
      />
    </Form>
  );
};
