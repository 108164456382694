import { Badge, Tooltip } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Syncs } from "src/graphql";
import { exhaustiveCheck } from "src/types/visual";
import { SyncHealthStatus } from "src/utils/syncs";
import { Channel } from "./message/components/campaign";
import { isDraftAction } from "./utils";

export enum ActionStatus {
  DRAFT = "draft",
  ENABLED = "enabled",
  DISABLED = "disabled",
  ERROR = "error",
}

export enum ActionError {
  CAMPAIGN_DEACTIVATED = "campaign_deactivated",
  MISSING_CAMPAIGN = "missing_campaign",
  SYNC_UNHEALTHY = "sync_unhealthy",
  SYNC_MISSING_SCHEDULE = "sync_missing_schedule",
  SYNC_DISABLED = "sync_disabled",
}

type UseActionStatusArgs = {
  sync:
    | Pick<Syncs, "id" | "health" | "schedule" | "schedule_paused">
    | null
    | undefined;
  channel: Channel;
  resourceId: string | null | undefined;
  enabled: boolean | null;
  agentEnabled: boolean | null;
};

type UseActionStatusResult =
  | {
      isLoading: true;
      status: null;
      error: null;
    }
  | {
      isLoading: false;
      status: ActionStatus.DRAFT | ActionStatus.ENABLED | ActionStatus.DISABLED;
      error: null;
    }
  | {
      isLoading: false;
      status: ActionStatus.ERROR;
      error: ActionError;
    };

export const useActionStatus = ({
  sync,
  enabled,
  agentEnabled,
}: UseActionStatusArgs): UseActionStatusResult => {
  const { decisionEngineDemo } = useFlags();

  if (isDraftAction(sync, enabled)) {
    // Don't need to wait for the resource for draft status
    return { status: ActionStatus.DRAFT, isLoading: false, error: null };
  }

  if (enabled) {
    // Ignore errors in demo workspaces since we are intentionally not syncing these campaigns
    if (decisionEngineDemo) {
      return {
        status: ActionStatus.ENABLED,
        isLoading: false,
        error: null,
      };
    }

    /*
    if (isResourceLoading) {
      return {
        isLoading: true,
        status: null,
        error: null,
      };
    }

  
    if (!resource?.id) {
      return {
        status: ActionStatus.ERROR,
        error: ActionError.MISSING_CAMPAIGN,
        isLoading: isResourceLoading,
      };
    }

    
    const definition = getChannelDefinition(channelDestinationType);
    if (definition.isInactive(resource)) {
      return {
        status: ActionStatus.ERROR,
        error: ActionError.CAMPAIGN_DEACTIVATED,
        isLoading: isResourceLoading,
      };
    }
    */

    // Don't show this status if the agent is disabled
    if (agentEnabled && sync?.schedule_paused) {
      return {
        status: ActionStatus.ERROR,
        error: ActionError.SYNC_DISABLED,
        isLoading: false,
      };
    }

    if (sync?.schedule === null) {
      return {
        status: ActionStatus.ERROR,
        error: ActionError.SYNC_MISSING_SCHEDULE,
        isLoading: false,
      };
    }

    if (sync?.health === SyncHealthStatus.Unhealthy) {
      return {
        status: ActionStatus.ERROR,
        error: ActionError.SYNC_UNHEALTHY,
        isLoading: false,
      };
    }

    return {
      status: ActionStatus.ENABLED,
      isLoading: false,
      error: null,
    };
  } else {
    // Don't need to wait for the resource for disabled status
    return { status: ActionStatus.DISABLED, isLoading: false, error: null };
  }
};

export const ActionStatusBadge = ({
  status,
  error,
  channelLabel,
  resourceType,
}: {
  status: ActionStatus | null;
  error?: ActionError | null;
  channelLabel?: string;
  resourceType?: string | null;
}) => {
  if (status === null) {
    return <Badge>Unknown</Badge>;
  }

  switch (status) {
    case ActionStatus.ENABLED:
      return <Badge variant="success">Enabled</Badge>;
    case ActionStatus.DRAFT:
      return <Badge>Draft</Badge>;
    case ActionStatus.DISABLED:
      return <Badge>Disabled</Badge>;
    case ActionStatus.ERROR:
      return (
        <Badge variant="error">
          <Tooltip
            isDisabled={!error}
            message={
              error
                ? errorTooltipMsg({ error, channelLabel, resourceType })
                : undefined
            }
          >
            Error
          </Tooltip>
        </Badge>
      );
    default:
      exhaustiveCheck(status);
  }
};

const errorTooltipMsg = ({
  error,
  channelLabel,
  resourceType,
}: {
  error: ActionError;
  channelLabel: string | undefined;
  resourceType: string | undefined | null;
}) => {
  const cta = "Click this action for more details.";
  switch (error) {
    case ActionError.CAMPAIGN_DEACTIVATED:
      return `${channelLabel} ${resourceType ?? "destination resource"} is inactive. ${cta}`;
    case ActionError.MISSING_CAMPAIGN:
      return `Can't find ${channelLabel} ${resourceType ?? "destination resource"}. ${cta}`;
    case ActionError.SYNC_UNHEALTHY:
      return `Hightouch sync is unhealthy. ${cta}`;
    case ActionError.SYNC_MISSING_SCHEDULE:
      return `Hightouch sync is missing a schedule. ${cta}`;
    case ActionError.SYNC_DISABLED:
      return `Hightouch sync is disabled. ${cta}`;
    default:
      exhaustiveCheck(error);
  }
};
