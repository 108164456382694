import { ReactNode, FC } from "react";

import {
  Box,
  BoxProps,
  Button,
  ChakraPopover,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Portal,
  Column,
  useDisclosure,
  useOutsideClick,
} from "@hightouchio/ui";

import { useManualPortal } from "src/hooks/use-manual-portal";

import { HStack } from "./shared";
import { ErrorMessage } from "./error-message";

const MAX_FILTER_WIDTH = 380;

type Props = {
  children: ReactNode;
  content: ReactNode;
  error?: ReactNode | string;
  isDisabled?: boolean;
  isEmpty: boolean;
} & BoxProps;

export const Filter: FC<Readonly<Props>> = ({
  content,
  children,
  error,
  isDisabled = false,
  isEmpty,
  ...props
}) => {
  // Need to attach the content to the DOM manually
  // because chakra Portal's `containerRef` renders the
  // content inside the main tree.
  const containerRef = useManualPortal();
  const { isOpen, onToggle, onClose } = useDisclosure();

  const applyEmptyFieldStyles = isEmpty && !isOpen && !error;

  useOutsideClick({
    enabled: isOpen,
    ref: containerRef,
    handler: (event) => {
      // React-select sets up its own anchor. If a react-select
      // is rendered as a child in the filter then clicking inside of the
      // portal will be seen as clicking outside of the filter.
      const reactSelectPortal = document.getElementById("portalAnchor");
      if (reactSelectPortal?.contains(event.target as Node)) {
        return;
      }

      onClose();
    },
  });

  if (!containerRef.current) return null;

  return (
    <Column align="flex-start" {...props}>
      <ChakraPopover
        isLazy
        closeOnBlur={false}
        isOpen={isOpen}
        onClose={onClose}
        placement="bottom"
      >
        <ChakraPopoverTrigger>
          <Box
            as={Button}
            isDisabled={isDisabled}
            sx={{
              border: "none",
              p: 0,
              _disabled: {
                div: {
                  bg: "gray.200",
                  color: "text.disabled",
                  "*": {
                    color: "text.disabled",
                  },
                  _hover: {
                    borderColor: "base.border",
                  },
                },
              },
            }}
            onClick={onToggle}
          >
            <HStack
              _hover={{
                borderColor: error
                  ? "danger.base"
                  : isOpen
                    ? "primary.base"
                    : "gray.border",
                bg: "base.lightBackground",
              }}
              _active={{
                border: "1px solid",
                borderColor: error ? "danger.base" : "primary.base",
                boxShadow: error ? "outlineRed" : "outline",
              }}
              px={2}
              gap={1}
              alignItems="center"
              height="32px"
              fontWeight="normal"
              border="1px solid"
              boxShadow={error ? "outlineRed" : isOpen ? "outline" : "none"}
              borderRadius="6px"
              maxWidth={`${MAX_FILTER_WIDTH}px`}
              overflow="hidden"
              transition="all 120ms ease-in"
              whiteSpace="nowrap"
              {...(applyEmptyFieldStyles
                ? {
                    borderStyle: "dashed",
                    borderColor: "forest.border",
                    bg: "forest.background",
                  }
                : {
                    bg: "base.lightBackground",
                    borderColor: error
                      ? "danger.base"
                      : isOpen
                        ? "primary.base"
                        : "base.border",
                  })}
              sx={
                applyEmptyFieldStyles
                  ? {
                      span: {
                        color: "primary.base",
                        fontWeight: "medium",
                      },
                    }
                  : {}
              }
            >
              {children}
            </HStack>
          </Box>
        </ChakraPopoverTrigger>

        <Portal containerRef={containerRef}>
          {/* `containerRef` overrides the containerRef from hightouch ui. This hack is needed until the z-index problems are addressed. */}
          <Box
            sx={{
              "& .chakra-popover__popper": {
                zIndex: "popover",
              },
            }}
          >
            <ChakraPopoverContent width="max-content">
              <Column
                align="flex-start"
                flex={1}
                maxWidth="fit-content"
                minWidth={0}
                p={2}
              >
                <HStack gap={2} sx={{ overflowX: "auto" }}>
                  {content}
                </HStack>
              </Column>
            </ChakraPopoverContent>
          </Box>
        </Portal>
      </ChakraPopover>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Column>
  );
};
