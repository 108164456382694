import { Row, ProgressCircle, Text, TextProps } from "@hightouchio/ui";
import { ActiveSyncStatus, SyncRunStatus } from "src/utils/syncs";

const queryingCompletionPercent = 15;
const reportingCompletionPercent = 95;

export const ActiveSyncProgressIndicator = ({
  status,
  completionRatio,
  labelProps = {},
}: {
  status: ActiveSyncStatus;
  completionRatio: number | null;
  labelProps?: Pick<TextProps, "size" | "fontWeight">;
}) => {
  const completionPercent = Math.round((completionRatio || 0) * 100);

  const activeRunDescription = getActiveRunDescription(
    status,
    completionPercent,
  );

  return (
    <Row align="center" gap={2}>
      <ProgressCircle
        value={activeRunDescription.completionPercent}
        size="sm"
      />
      <Text {...labelProps}>{activeRunDescription.label}</Text>
    </Row>
  );
};

const getActiveRunDescription = (
  status: ActiveSyncStatus,
  completionPercent: number,
) => {
  switch (status) {
    case SyncRunStatus.ENQUEUED:
      return {
        label: "Queued...",
        completionPercent: 0,
      };
    case SyncRunStatus.QUERYING:
      return {
        label: "Querying...",
        completionPercent: queryingCompletionPercent,
      };
    case SyncRunStatus.PROCESSING:
      return {
        label:
          completionPercent === 0
            ? "Processing..."
            : `Processing... (${completionPercent}%)`,
        completionPercent,
      };
    case SyncRunStatus.REPORTING:
      return {
        label: "Finishing...",
        completionPercent: reportingCompletionPercent,
      };
  }
};
