import { FC } from "react";
import { useParams, Navigate } from "src/router";
import { createEventPath } from "src/events/contracts/utils";
import { PageSpinner } from "src/components/loading";
import { useEventSchemaByIdQuery } from "src/graphql";
import { useUser } from "src/contexts/user-context";

/**
 * Redirects a user from a link containing only the ID of a schema, to the actual schema page.
 * The schema page requires you to have the contract ID, the version, and the event type, which
 * may not be available when the link is being generated.
 */
export const SchemaIdRedirect: FC = () => {
  const { event_schema_id } = useParams();
  const { workspace } = useUser();

  const { data, isLoading, error } = useEventSchemaByIdQuery(
    {
      id: event_schema_id!,
      workspace_id: workspace?.id,
    },
    {
      enabled: Boolean(event_schema_id && workspace?.id),
    },
  );

  // In case of oddities, redirect to the main events page.

  if (!workspace?.id) {
    return <Navigate to="/events" />;
  }

  if (isLoading) {
    return <PageSpinner />;
  }

  if (error || !data?.event_schemas_by_pk) {
    return <Navigate to="/events" />;
  }

  const schema = data.event_schemas_by_pk;
  const path = createEventPath({
    eventName: schema.event_name,
    eventType: schema.event_type,
    eventVersion: schema.event_version,
  });

  return (
    <Navigate
      to={`/events/contracts/${schema.event_plan_id}/${path}`}
      replace
    />
  );
};
