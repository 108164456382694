import { ChangeEventHandler, useEffect, useMemo, useState } from "react";

import sortBy from "lodash/sortBy";
import uniq from "lodash/uniq";

import {
  ColumnType,
  Condition,
  ConditionType,
  getInitialTraitColumn,
  isRelatedColumn,
  isTraitColumn,
  RawColumn,
  isTransformedColumn,
  TraitConfig,
  TraitType,
} from "src/types/visual";
import { useQueryBuilderContext } from "src/components/explore/context/hooks";
import { getTraitPropertyType } from "src/components/explore/visual/utils";

import { FilterOption } from "./constants";
import {
  EventFilterOption,
  EventPropertyFilterOption,
  FilterColumnOption,
  InlineTraitFilterOption,
  NumberOfFilterOption,
  PropertyFilterOption,
  SegmentSetFilterOption,
  TraitFilterOption,
} from "./types";
import {
  getSelectedColumn,
  buildConditionFromColumn,
  getRelationshipLabel,
  countRelationsByModel,
} from "./utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFetchFilterableAudienceColumns } from "src/hooks/use-fetch-filterable-audience-columns";

export interface UseConditionSelect<TCondition> {
  condition?: TCondition;
  initialTab?: FilterOption;
}

const customTraitOption: InlineTraitFilterOption = {
  type: FilterOption.InlineTrait,
  label: "Create a custom trait",
  // This is not a real filter option, it acts more like a button in the UI
  // so these keys are intentionally set to null/undefined
  value: null,
  description: undefined,
  usageRatio: undefined,
};

export const useConditionSelect = <TCondition extends Condition>({
  condition,
  initialTab = FilterOption.All,
}: UseConditionSelect<TCondition>) => {
  const { restrictMergedColumns } = useFlags();
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState<FilterOption>(initialTab);
  const [refreshSelectedTab, setRefreshSelectedTab] = useState(true);

  const {
    audienceReferenceStats,
    columns,
    events,
    relationships,
    traits,
    relatedAudiences,
    selectedEvent,
  } = useQueryBuilderContext();

  const segmentIds = useMemo(() => {
    return uniq(relationships.map(({ to_model }) => to_model.id));
  }, [relationships]);
  const {
    filterableColumns: filterableColumnsByModelId,
    isLoading: filterableColumnsLoading,
  } = useFetchFilterableAudienceColumns(segmentIds);

  const updateSearch: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearch(event.target.value);
  };

  const changeFilter = (filterType: FilterOption) => {
    setFilter(filterType);
  };

  const reset = () => {
    setFilter(FilterOption.All);
    setSearch("");
  };

  const propertyOptions: PropertyFilterOption[] = useMemo(() => {
    const filteredColumns = restrictMergedColumns
      ? columns.filter((column) => column.column_reference.type === "raw")
      : columns;

    return sortBy(
      filteredColumns.map(
        ({
          alias,
          name,
          column_reference,
          custom_type,
          description,
          type,
          model_id,
          model_name,
          case_sensitive,
          top_k_enabled,
        }) => ({
          case_sensitive,
          description,
          label: alias || name,
          modelName: model_name,
          propertyType: (custom_type ?? type) as ColumnType,
          type: FilterOption.Property,
          value: column_reference as RawColumn,
          suggestionsEnabled: Boolean(top_k_enabled),
          usageRatio: audienceReferenceStats?.modelColumns.find(
            (c) => c.modelId === model_id.toString() && c.name === name,
          )?.ratio,
        }),
      ),
      (column) => column.label.toLowerCase(),
    );
  }, [columns, audienceReferenceStats?.modelColumns, restrictMergedColumns]);

  const traitOptions: TraitFilterOption[] = useMemo(() => {
    const options: TraitFilterOption[] = traits
      .filter((trait) => trait.type)
      .map((trait) => {
        const traitFilterableColumns =
          filterableColumnsByModelId[trait.relationship?.to_model?.id] ?? [];

        return {
          label: trait.name,
          value:
            condition?.type === ConditionType.Property &&
            (isRelatedColumn(condition.property) ||
              isTransformedColumn(condition.property)) &&
            isTraitColumn(condition.property.column) &&
            condition?.property?.column.traitDefinitionId === trait.id
              ? condition.property
              : getInitialTraitColumn(trait),
          description: trait.description,
          propertyType:
            getTraitPropertyType(trait, traitFilterableColumns) ??
            ColumnType.Unknown,
          type: trait.is_template
            ? FilterOption.TraitTemplate
            : FilterOption.Trait,
          traitType: trait.type as TraitType,
          traitConfig: trait.config as TraitConfig,
          parentModel: trait.parentModel,
          relatedModel: trait.relationship?.to_model
            ? {
                ...trait.relationship.to_model,
                filterable_audience_columns: traitFilterableColumns,
              }
            : undefined,
          traitId: trait.id,
          suggestionsEnabled: Boolean(trait.top_k_enabled),
          usageRatio: audienceReferenceStats?.traits.find(
            (t) => t.traitDefinitionId === trait.id,
          )?.ratio,
        };
      });

    return sortBy(options, (option) => option.label.toLowerCase());
  }, [traits, audienceReferenceStats?.traits, filterableColumnsByModelId]);

  const eventOptions: (EventFilterOption | EventPropertyFilterOption)[] =
    useMemo(() => {
      const grouped = countRelationsByModel(events);
      return sortBy(
        events?.flatMap(
          ({
            id: relationshipId,
            name: relationshipName,
            to_model: { id: eventModelId, name: eventModelName, description },
          }) => {
            const eventOption: EventFilterOption = {
              label:
                (grouped[eventModelName] || 0) >= 2
                  ? getRelationshipLabel(relationshipName, eventModelName)
                  : eventModelName,
              description,
              value: { eventModelId, relationshipId },
              type: FilterOption.Event,
              examplePropertyNames: (
                filterableColumnsByModelId[eventModelId] ?? []
              )
                .slice(0, 5)
                .map((c) => c.alias ?? c.name),
              // Note: We can use the relationship or event model to calculate the usage ratio
              // since we track both for Event Filters.
              // It shouldn't make a difference.
              usageRatio: audienceReferenceStats?.modelRelationships.find(
                (r) => r.modelRelationshipId === relationshipId.toString(),
              )?.ratio,
            };

            // If selectedEvent is provided, then add the event as a selectable property (EventProperty)
            if (
              eventModelId === selectedEvent?.eventModelId &&
              relationshipId === selectedEvent?.relationshipId
            ) {
              return [
                {
                  label:
                    (grouped[eventModelName] || 0) >= 2
                      ? getRelationshipLabel(relationshipName, eventModelName)
                      : eventModelName,
                  description: "The specific event that started this journey",
                  value: { modelId: eventModelId },
                  invertIconColors: true,
                  type: FilterOption.EventProperty,
                  examplePropertyNames: (
                    filterableColumnsByModelId[eventModelId] ?? []
                  )
                    .slice(0, 5)
                    .map((c) => c.alias ?? c.name),
                  // Note: We can use the relationship or event model to calculate the usage ratio
                  // since we track both for Event Filters.
                  // It shouldn't make a difference.
                  usageRatio: audienceReferenceStats?.modelRelationships.find(
                    (r) => r.modelRelationshipId === relationshipId.toString(),
                  )?.ratio,
                } satisfies EventPropertyFilterOption,
                eventOption,
              ];
            }

            return [eventOption];
          },
        ),
        (column) => column.type !== FilterOption.EventProperty,
        (column) => column.label.toLowerCase(),
      );
    }, [
      events,
      audienceReferenceStats?.modelRelationships,
      filterableColumnsByModelId,
    ]);

  // Don't show related events under the options. Users should use the
  // EventConditionFilter for creating queries based on events.
  const relatedModelOptions: NumberOfFilterOption[] = useMemo(() => {
    const grouped = countRelationsByModel(relationships);

    return sortBy(
      relationships
        ?.filter(({ to_model: { event } }) => !event)
        ?.map(
          ({
            id,
            name: relationshipName,
            to_model: { id: toModelId, name: relatedModelName, description },
          }) => ({
            value: { relationshipId: id },
            label:
              (grouped[relatedModelName] || 0) >= 2
                ? getRelationshipLabel(relationshipName, relatedModelName)
                : relatedModelName,
            description,
            toModelId,
            type: FilterOption.Relation,
            examplePropertyNames: (filterableColumnsByModelId[toModelId] ?? [])
              .slice(0, 5)
              .map((c) => c.alias ?? c.name),
            usageRatio: audienceReferenceStats?.modelRelationships.find(
              (r) => r.modelRelationshipId === id.toString(),
            )?.ratio,
          }),
        ),
      (column) => column.label.toLowerCase(),
    );
  }, [
    relationships,
    audienceReferenceStats?.modelRelationships,
    filterableColumnsByModelId,
  ]);

  const audienceOptions: SegmentSetFilterOption[] = useMemo(
    () =>
      sortBy(
        relatedAudiences?.map(({ id, name, ...data }) => ({
          ...data,
          label: name,
          value: { modelId: id },
          type: FilterOption.Audience,
          usageRatio: audienceReferenceStats?.segments.find(
            (r) => r.segmentId === id.toString(),
          )?.ratio,
        })),
        (column) => column.label.toLowerCase(),
      ),
    [relatedAudiences, audienceReferenceStats?.segments],
  );

  const allColumns = useMemo(
    () =>
      sortBy(
        [
          ...propertyOptions,
          ...traitOptions,
          ...eventOptions,
          ...relatedModelOptions,
          ...audienceOptions,
        ],
        (column) => column.type !== FilterOption.EventProperty,
        (columns) => columns.label.toLowerCase(),
      ),
    [
      propertyOptions,
      traitOptions,
      eventOptions,
      relatedModelOptions,
      audienceOptions,
    ],
  );

  const optionsByFilter = useMemo(
    () => ({
      [FilterOption.All]: allColumns,
      [FilterOption.Property]: propertyOptions,
      [FilterOption.Event]: eventOptions,
      [FilterOption.Relation]: relatedModelOptions,
      [FilterOption.Audience]: audienceOptions,
      [FilterOption.Trait]: [customTraitOption, ...traitOptions],
      [FilterOption.TraitTemplate]: [customTraitOption, ...traitOptions],
    }),
    [
      allColumns,
      propertyOptions,
      eventOptions,
      relatedModelOptions,
      audienceOptions,
      traitOptions,
    ],
  );

  const popoverOptions: FilterColumnOption[] = useMemo(() => {
    const visibleFilter =
      filter === FilterOption.EventProperty ? FilterOption.Event : filter;
    const columns = optionsByFilter[visibleFilter].filter(
      ({ label, description }) =>
        label?.trim().toLowerCase().includes(search.trim().toLowerCase()) ||
        description?.toLowerCase().includes(search.trim().toLowerCase()),
    );

    return columns;
  }, [optionsByFilter, filter, search]);

  const selectedColumn = useMemo(() => {
    if (!condition) {
      return undefined;
    }

    return getSelectedColumn(condition, allColumns);
  }, [condition, allColumns]);

  // Popover stays mounted, so may need to be triggered externally.
  useEffect(() => {
    if (refreshSelectedTab) {
      const newFilter = getSelectedColumn(condition, allColumns)?.type;

      if (newFilter) {
        setFilter(newFilter);
      }

      setRefreshSelectedTab(false);
    }
  }, [allColumns, condition, refreshSelectedTab]);

  return {
    columns: popoverOptions,
    filter,
    search,
    selectedColumn,
    filterableColumnsLoading,
    onResetSelectedTab: () => setRefreshSelectedTab(true),
    onFilterChange: changeFilter,
    onReset: reset,
    onSearch: updateSearch,
    onSelectColumn: (column: FilterColumnOption) =>
      buildConditionFromColumn(column),
  };
};
