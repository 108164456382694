import { HTImage } from "src/components/image";
import {
  Box,
  Column,
  PlusIcon,
  Row,
  SectionHeading,
  Text,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { FC } from "react";
import { useNavigate } from "src/router";

import { Card } from "src/components/card";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  DecisionEngineFlowQuery,
  useDeleteDecisionEngineOutcomeMutation,
} from "src/graphql";
import eventIcon from "src/pages/schema/assets/event.svg";
import { Table } from "src/ui/table";
import { DeleteButton } from "src/components/delete-button";
import { OutcomeClassification } from "./components/outcome-classification";
import { sortOutcomes } from "src/pages/decision-engines/utils";
import { DecisionEngineOutcomeReward } from "@hightouch/lib/customer-data/decision-engine/types";

type BaseOutcome = NonNullable<
  DecisionEngineFlowQuery["decision_engine_flows_by_pk"]
>["outcomes"][0]["outcome"];

export type Outcome = Omit<BaseOutcome, "weight"> & {
  weight: DecisionEngineOutcomeReward;
};

export const Outcomes: FC<
  Readonly<{
    outcomes: Array<Outcome>;
  }>
> = ({ outcomes }) => {
  const navigate = useNavigate();
  const deleteMutation = useDeleteDecisionEngineOutcomeMutation();

  return (
    <Card gap={4} p={6}>
      <Row justify="space-between" gap={4}>
        <Column>
          <SectionHeading>Outcomes</SectionHeading>
          <Text>Define positive and negative outcomes</Text>
        </Column>
        <LinkButton href="outcomes/add" icon={PlusIcon}>
          Add outcome
        </LinkButton>
      </Row>
      <Table
        columns={[
          {
            name: "Name",
            cell: ({ name }) => (
              <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
            ),
          },
          {
            name: "Model",
            cell: ({ segment }) => {
              return (
                <Row align="center" gap={2} overflow="hidden">
                  <HTImage src={eventIcon} width="20px" decorative />
                  <TextWithTooltip fontWeight="medium">
                    {segment?.name}
                  </TextWithTooltip>
                </Row>
              );
            },
          },
          {
            name: "Classification",
            max: "max-content",
            cell: ({ weight }) => {
              return <OutcomeClassification weight={weight} showLabel />;
            },
          },
          {
            name: "",
            max: "max-content",
            cell: ({ id }) => {
              return (
                <Box onClick={(event) => event.stopPropagation()}>
                  <DeleteButton
                    variant="icon"
                    label="outcome"
                    onDelete={async () => {
                      await deleteMutation.mutateAsync({ id });
                    }}
                  />
                </Box>
              );
            },
          },
        ]}
        placeholder={{
          title: "No outcomes",
        }}
        onRowClick={({ id }) => navigate(`outcomes/${id}`)}
        data={outcomes.sort((a, b) => sortOutcomes(a.weight, b.weight))}
      />
    </Card>
  );
};
