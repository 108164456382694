import {
  Column,
  Heading,
  Row,
  ToggleButton,
  ToggleButtonGroup,
} from "@hightouchio/ui";
import { useState } from "react";

import { AudienceStats } from "./stats";

export type AudienceSummaryFilter = "personal" | "all";

export const AudienceSummary = () => {
  const [filter, setFilter] = useState<AudienceSummaryFilter>("all");

  return (
    <Column gap={4}>
      <Row justify="space-between" align="center">
        <Heading size="lg">Audience summary</Heading>
        <ToggleButtonGroup
          value={filter}
          onChange={(value) => setFilter(value as AudienceSummaryFilter)}
        >
          <ToggleButton value="personal" label="Created by you" />
          <ToggleButton value="all" label="All" />
        </ToggleButtonGroup>
      </Row>
      <AudienceStats filter={filter} />
    </Column>
  );
};
