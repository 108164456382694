import { FC } from "react";

import { Column, Heading, Spinner } from "@hightouchio/ui";
import uniq from "lodash/uniq";
import { FormProvider } from "react-hook-form";
import { useNavigate, useOutletContext } from "src/router";

import { useHightouchForm } from "src/components/form";
import { Warning } from "src/components/warning";
import { DeprecatedWizard, WizardStep } from "src/components/wizard";
import {
  useCreateIdentityResolutionModelMutation,
  useIdrModelQuery,
} from "src/graphql";
import {
  GraphVersion,
  modelConfigValidationResolver,
} from "src/pages/identity-resolution/utils";
import {
  ModelConfigv1,
  ModelConfigv2,
  ModelSelect,
} from "src/pages/identity-resolution/forms";
import {
  getDefaultIdentifiers,
  ModelState,
} from "src/pages/identity-resolution/types";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

import { OutletContext } from ".";

export const CreateIdentityResolutionModel: FC = () => {
  const { graph, identifiers } = useOutletContext<OutletContext>();
  const [step, setStep] = useWizardStepper(0);
  const navigate = useNavigate();

  const createMutation = useCreateIdentityResolutionModelMutation();

  const isIDRv2 = graph.version === GraphVersion.V2;
  const identifierOptions = uniq([
    ...getDefaultIdentifiers(isIDRv2),
    ...identifiers,
  ]).filter(Boolean);

  const values: ModelState = {
    type: isIDRv2 ? "event" : "profile",
    model: { id: "", name: "" },
    order_by: "",
    mappings: [{ column: "", identifier: "" }],
  };

  const form = useHightouchForm({
    onSubmit: async (modelConfiguration) => {
      if (!model) {
        return;
      }

      const res = await createMutation.mutateAsync({
        input: {
          graphId: graph.id,
          models: [
            {
              type: modelConfiguration.type,
              orderBy:
                modelConfiguration.type === "event"
                  ? { column: modelConfiguration.order_by, order: "asc" }
                  : null,
              mappings: modelConfiguration.mappings.map((mapping) => ({
                column: mapping.column,
                identifier: mapping.identifier,
              })),
              rank: graph.models.length + 1,
              segmentId: String(modelConfiguration.model.id),
            },
          ],
        },
      });

      if (res.createIDRModel.__typename === "CreateIDRModelErrorResponse") {
        throw new Error(res.createIDRModel.error);
      }

      navigate(-1);
    },
    success: "Identity model created",
    values,
    resolver: (data, context, options) =>
      modelConfigValidationResolver(data, isIDRv2, context, options),
  });

  const modelId = form.watch("model.id");

  const idrModelQuery = useIdrModelQuery(
    {
      id: modelId ?? "",
    },
    {
      enabled: Boolean(modelId),
      select: (data) => data.segments_by_pk,
    },
  );

  const model = idrModelQuery.data;

  const steps: WizardStep[] = [
    {
      title: "Select a model",
      header: <Heading>Select a model</Heading>,
      continue: "Click on a model to continue",
      render: () => (
        <ModelSelect
          isMultiSelect={false}
          graphVersion={isIDRv2 ? GraphVersion.V2 : GraphVersion.V1}
          modelsToOmit={graph.models.map(({ model }) => model.id)}
          sourceId={graph.source.id}
          onSelectModel={(selection) => {
            form.reset({
              type: isIDRv2 ? "event" : "profile",
              model: { id: selection.id, name: selection.name },
              order_by: "",
              mappings: [{ column: "", identifier: "" }],
            });
            setStep(1);
          }}
        />
      ),
    },
    {
      title: "Configure mappings",
      header: <Heading>Configure model mappings</Heading>,
      render: () => {
        if (idrModelQuery.isLoading) {
          return (
            <Column align="center" height="100%">
              <Spinner m="auto" size="lg" />
            </Column>
          );
        }

        if (!model) {
          return (
            <Warning
              subtitle="It may have been deleted"
              title="Model not found"
            />
          );
        }

        return isIDRv2 ? (
          <ModelConfigv2
            model={model}
            sourceId={graph.source.id}
            identifierOptions={identifierOptions}
          />
        ) : (
          <ModelConfigv1 model={model} identifierOptions={identifierOptions} />
        );
      },
    },
  ];

  return (
    <FormProvider {...form}>
      <DeprecatedWizard
        title="New input model"
        step={step}
        steps={steps}
        setStep={setStep}
        onCancel={() => {
          navigate(-1);
        }}
        onSubmit={form.submit}
      />
    </FormProvider>
  );
};
