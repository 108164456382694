import { Box, JourneyIcon, Row, SparkleIcon, Text } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FC } from "react";
import { FolderType } from "src/components/folders/types";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { isPresent } from "src/types/utils";
import { useEntitlements } from "src/hooks/use-entitlement";

const Folder: FC<
  Readonly<{
    name: string;
    count: number | undefined;
    icon: React.ElementType;
    isSelected?: boolean;
    onClick: () => unknown;
  }>
> = ({ name, isSelected, count, onClick, icon: Icon }) => {
  return (
    <Row
      align="center"
      borderRadius="md"
      border="1px"
      borderColor={isSelected ? "primary.border" : "transparent"}
      bg={isSelected ? "forest.background" : undefined}
      cursor="pointer"
      gap={1.5}
      pr={3}
      height="30px"
      transition="border-color 0.1s, background-color 0.1s, box-shadow 0.1s"
      width="100%"
      onClick={onClick}
    >
      <Box
        as={Icon}
        ml={5}
        color={isSelected ? "primary.pressed" : "gray.500"}
        fontSize="16px"
      />
      <Row flex={1} minWidth={0} justify="space-between">
        <TextWithTooltip
          message={name}
          placement="top"
          fontWeight="medium"
          color={isSelected ? "primary.pressed" : "text.primary"}
          size="sm"
        >
          {name}
        </TextWithTooltip>
        {isPresent(count) && (
          <Row
            as={Text}
            justifySelf="flex-end"
            color={isSelected ? "primary.pressed" : "text.secondary"}
            className="count"
            flexShrink={0}
            size="sm"
          >
            {count}
          </Row>
        )}
      </Row>
    </Row>
  );
};

export const JourneysFolder: FC<
  Readonly<{
    setSelectedFolder: (folder: string | null) => unknown;
    setSelectedFolderType: (folderType: FolderType) => unknown;
    isSelected: boolean;
    count: number | undefined;
  }>
> = ({ setSelectedFolder, setSelectedFolderType, ...props }) => {
  const { data: entitlementsData } = useEntitlements(false);

  if (!entitlementsData.entitlements.journeys) {
    return null;
  }
  return (
    <Folder
      {...props}
      name="Journey syncs"
      icon={JourneyIcon}
      onClick={() => {
        setSelectedFolderType("journeys");
        setSelectedFolder(null);
      }}
    />
  );
};

export const DecisionEnginesFolder: FC<
  Readonly<{
    setSelectedFolder: (folder: string | null) => unknown;
    setSelectedFolderType: (folderType: FolderType) => unknown;
    isSelected: boolean;
    count: number | undefined;
  }>
> = ({ setSelectedFolder, setSelectedFolderType, ...props }) => {
  const { decisionEngineEnabled } = useFlags();

  if (!decisionEngineEnabled) {
    return null;
  }

  return (
    <Folder
      {...props}
      name="AI decisioning syncs"
      icon={SparkleIcon}
      onClick={() => {
        setSelectedFolderType("decisioning");
        setSelectedFolder(null);
      }}
    />
  );
};
